import React, {Component} from "react";
import {Col, Form, Icon, Input, InputNumber, Modal, Row, Select, Switch} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {queryTeamsBusiness} from "../../../modules/team/action";
import {getBrand} from "../../../modules/training/action";
import Button from "antd/es/button/button";

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 20, offset: 4},
    },
};
const Option = Select.Option;
const FormItem = Form.Item;

class TrainingPreconditionModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandIds: [],
            ytc: false,
            club: false,
            clubType: null,
            valuation: 0
        }

    }

    componentWillMount() {
        let {precondition, getBrand} = this.props
        console.log(precondition)
        getBrand({page: 1, size: 100000});
        if (precondition) {
            let preconditionValue = JSON.parse(precondition);
            this.setState({
                brandIds: preconditionValue.brandIds ? preconditionValue.brandIds : [],
                ytc: preconditionValue.ytc,
                club: preconditionValue.club,
                clubType: preconditionValue.clubType,
                valuation: preconditionValue.valuation
            })
        }
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                if (!values.valuation) {
                    values.valuation = 0
                }
                if (!values.club) {
                    values.clubType = null
                }
                // eslint-disable-next-line no-unused-expressions
                console.log(JSON.stringify(values))
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange = (checked) => {
        this.setState({
            club: checked
        })
    }

    addItem = () => {
        const {brandIds} = this.state;
        brandIds.push({})
        this.setState({
            brandIds: brandIds
        })
    };
    removeItem = index => {
        const {resetFields} = this.props.form;
        resetFields();
        const {brandIds} = this.state;
        console.log(brandIds)
        brandIds.splice(index, 1)
        console.log(brandIds)
        this.setState({
            brandIds: brandIds
        })

    };

    onChangeParamsKey = (index, value) => {
        const {brandIds} = this.state;
        brandIds[index].brandId = value;
        this.setState({
            brandIds: brandIds
        })
    }

    onChangeParamsValue = (index, value) => {
        const {brandIds} = this.state;
        brandIds[index].num = value
        this.setState({
            brandIds: brandIds
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {brandData, games, visible, precondition} = this.props
        const {brandIds, ytc, club, clubType, valuation} = this.state
        const keys = brandIds;
        const formItems = keys.map((k, index) => {
            return (<div>
                <FormItem
                    {...(formItemLayout)}
                    label={index === 0 ? '前置赛事' + (index+1) : index+1}
                >
                    <div>
                        <Form.Item
                            required={false}
                            style={{display: 'inline-block', width: 'calc(60%)', marginRight: 8}}
                        >
                            {getFieldDecorator(`brandIds[${index}].brandId`, {
                                initialValue: k.brandId,
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        message: "请选择品牌赛事.",
                                    },
                                ],
                            })(
                                <Select onChange={(e) => this.onChangeParamsKey(index, e)}>
                                    {brandData.data && brandData.data.map(item => {
                                        return (
                                            <Option
                                                value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            required={false}
                            style={{display: 'inline-block', width: 'calc(20%)', marginRight: 8}}
                        >
                            {getFieldDecorator(`brandIds[${index}].num`, {
                                initialValue: k.num,
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入参与次数.",
                                    },
                                ],
                            })(
                                <InputNumber min={0} precision={0} placeholder="参与次数"  onChange={(e) => this.onChangeParamsValue(index, e)}/>)}
                        </Form.Item>

                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.removeItem(index)}
                        />

                    </div>
                </FormItem>
            </div>)
        });


        return (
            <div>
                <Modal
                    visible={visible}
                    title="前置条件"
                    onCancel={this.props.onClose}
                    cancelText="取消"
                    onOk={this.onSubmit}
                    okText={"确认"}
                    width={'40%'}
                >
                    <Form>
                        {formItems}
                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={() => this.addItem()} style={{width: '60%'}}>
                                <Icon type="plus"/> 添加前置赛事
                            </Button>
                        </Form.Item>
                        <FormItem
                            label="是否需要YTC"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('ytc', {
                                initialValue: ytc,
                            })(
                                <Switch defaultChecked={ytc}/>
                            )}
                        </FormItem>
                        <FormItem
                            label="是否需要俱乐部"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('club', {
                                initialValue: club,
                            })(
                                <Switch defaultChecked={club} onChange={this.onChange}/>
                            )}
                        </FormItem>
                        <FormItem
                            label="俱乐部类型"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('clubType', {
                                initialValue: clubType,
                            })(
                                <Select disabled={!club} style={{width: '150px'}}>
                                    <Option value='COMMON'>普通</Option>
                                    <Option value='PEL'>PEL</Option>
                                    <Option value='PEN'>PEN</Option>
                                    <Option value='PEC'>PEC</Option>
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            label="商业估值"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('valuation', {
                                initialValue: valuation,
                            })(
                                <InputNumber min={0} max={Number.MAX_SAFE_INTEGER} precision={0}/>,
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getBrand,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingPreconditionModel)));