
export const ADD_PARTNER_ORDER = 'ADD_PARTNER_ORDER';// 新增订单

export const GET_PARTNER_ORDER_LIST = 'GET_PARTNER_ORDER_LIST';// 查询订单列表

export const GET_PARTNER_ORDER_DETAIL = 'GET_PARTNER_ORDER_DETAIL';// 查询订单详细信息

export const DEAL_WITH_ORDER = 'DEAL_WITH_ORDER';// 处理订单


