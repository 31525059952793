import React, {Component} from "react";
import {Form, Button, Select, Input} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;


class GiftOrderFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onCommit } = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="礼品名">
                    {getFieldDecorator('giftName', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="用户ID">
                    {getFieldDecorator('userId', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="礼品类型">
                    {getFieldDecorator('giftType', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: '200px'}}>
                            <Option value={null}>全部</Option>
                            <Option value="IN_KIND_GIFT">实物周边</Option>
                            <Option value="VIP">VIP会员（虚拟礼品）</Option>
                            <Option value="ENERGY">能量值（虚拟礼品）</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发货状态">
                    {getFieldDecorator('shippingStatus', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: 150}}>
                            <Option value={null}>全部</Option>
                            <Option value={true}>已发货</Option>
                            <Option value={false}>未发货</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}} onClick={()=>{
                        let { onExport } = this.props;
                        onExport && onExport();
                    }}>
                        导出礼品订单
                    </Button>
                </FormItem>
            </Form>
        );
    }
}


export default Form.create()(GiftOrderFilter);
