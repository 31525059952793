export const GET_CLUB = 'GET_CLUB';// 获取俱乐部列表
export const GET_CLUB_DETAIL = 'GET_CLUB_DETAIL'; //获取俱乐部详情
export const ADD_CLUB = 'ADD_CLUB';// 新增俱乐部
export const DELETE_CLUB = 'DELETE_CLUB';// 删除俱乐部
export const DELETE_TRAIN = 'DELETE_TRAIN';// 删除俱乐部试训
export const REPLY_TRAIN = 'REPLY_TRAIN';// 恢复俱乐部试训
export const DELETE_EVENT = 'DELETE_EVENT';// 删除俱乐部赛事
export const PUT_CLUB = 'PUT_CLUB'; //修改俱乐部

export const GET_TEAM = 'GET_TEAM'; //获取所属战队列表

export const GET_EVENT = 'GET_EVENT'; //获取赛事记录列表

export const ADD_EVENT = 'ADD_EVENT'; //添加赛事记录
export const UPDATE_EVENT = 'UPDATE_EVENT'; //修改赛事记录
export const BIND_CLUB = 'BIND_CLUB'; //战队绑定俱乐部
export const UNTYING_CLUB = 'UNTYING_CLUB'; //战队解绑俱乐部

export const GET_TRAILS = 'GET_TRAILS'; //试训列表
export const OPT_TRAILS = 'OPT_TRAILS'; //操作试训-取消
export const PUBLISH_TRAIL_PLAN = 'PUBLISH_TRAIL_PLAN'; //发布试训计划
export const GET_TRAIL_PLAN = 'GET_TRAIL_PLAN'; //查询试训计划详情
export const GET_TRAIL_PLAN_BRAND = 'GET_TRAIL_PLAN_BRAND'; //试训品牌训练赛限制配置列表
export const DELETE_TRAIL_PLAN = 'DELETE_TRAIL_PLAN'; //删除试训计划详情
export const REPLY_TRAIL_PLAN = 'REPLY_TRAIL_PLAN'; //恢复试训计划详情


export const APPROVE_CLUB = 'APPROVE_CLUB';//审批俱乐部

export const GET_GAME = 'GET_GAME';//获取所有游戏类型

export const CHANGE_RECOMMEND="CHANGE_RECOMMEND";

export const GET_SIGNBOARD = "GET_SIGNBOARD";//获取自荐列表
export const ADD_SIGNBOARD = "ADD_SIGNBOARD";//新增自荐
export const DEL_SIGNBOARD = "DEL_SIGNBOARD";//删除自荐
export const CHECK_SIGNBOARD = "CHECK_SIGNBOARD";//审核自荐
export const SET_SIGNBOARD_PRIORITY = "SET_SIGNBOARD_PRIORITY";//设置自荐权重
export const SET_SIGNBOARD_TOP = "SET_SIGNBOARD_TOP";//自荐是否置顶 

export const GET_TRIALPLAN_NEW = "GET_TRIALPLAN_NEW";//获取试训计划 
export const SET_TRIALPLAN_NEW_HOMEPAGE = "SET_TRIALPLAN_NEW_HOMEPAGE";//是否推荐至首页


