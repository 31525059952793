import createReducer from 'utils/createReducer';
import {GET_PAYMENTS,GET_MONEY} from "../pay/actionType";

const defaultState = () => ({
    payments: [],
    paymentsTotal: 0,
    paymentsLoading: false,
    paymentsInfo: {},
    adminRoles: [], // 用户所拥有的角色
    money:[]
});


const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        payments:payload

    }

};

const ongetMoney = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        money:payload

    }

};

export default createReducer(defaultState, {
    [`${GET_PAYMENTS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_MONEY}_SUCCESS`]: ongetMoney,


});