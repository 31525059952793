import api from 'utils/api';
import {ADD_Prediction} from "../modules/schedule/actionTypes";

class ScheduleRequest {
  /**
     * 查询行程
     * @param {Object} params 请求参数
     */
  static async getSchedule(parmas) {
    return api.get(`/admin/schedule/findByPage`, parmas);
  }

    /**
     * 查询
     * @param {Object} params 请求参数
     */
    static async getGuess(parmas) {
        return api.get(`/admin/guess/guessLogs`, parmas);
    }

  /**
   * 获取行程详情
   * @param id
   */
  static async getScheduleDetail(id) {
    return api.get(`/admin/schedule/${id}`);
  }

  static async getPredictionDetail(id) {
     return api.get(`/admin/guess/detail/${id}`);
  }

  /**
   * 新增行程详情
   * @param {Object} params 请求参数
   */
   static async addSchedule(params) {
    return api.post(`/admin/schedule`,params);
  }

  /**
   * 编辑行程详情
   * @param {Object} params 请求参数
   */
   static async editSchedule(params) {
    return api.put(`/admin/schedule`,params);
  }


    static async addPrediction(params) {
        return api.post(`/admin/guess/create`,params);
    }

    static async editPrediction(params) {
       let id = params.id;
        delete params.id;
        return api.put(`/admin/guess/edit/${id}`,params);
    }

    static async editPredictionResult(params) {
        return api.put(`/admin/guess/result`,params);
    }

  /**
   * 删除行程
   * @param id
   */
   static async delSchedule(id) {
    return api.delete(`/admin/schedule/${id}`);
  }

  /**
   * 置顶行程
   * @param {Object} params 请求参数
   */
   static async recommendSchedule(params) {
    return api.post(`/admin/schedule/index`,params);
  }

}
export default ScheduleRequest;
