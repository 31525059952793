import Http from 'request/AccountRequest';
import createAsyncAction from 'utils/createAsyncAction';
import {
  GET_ACCOUNT,OPERATE_ACCOUNT_COIN,GET_ACCOUNT_LOGS,GET_SPEND_LOGS
} from './actionTypes';


const getAccount = (userId) => (
    createAsyncAction(GET_ACCOUNT, () => (Http.getAccount(userId)))
);

const operateAccountCoin = (userId, type, coinAmount, remark) => (
    createAsyncAction(OPERATE_ACCOUNT_COIN, () => (Http.operateAccountCoin(userId, type, coinAmount, remark)))
);

const getAccountLogs = (userId,page,size) => (
    createAsyncAction(GET_ACCOUNT_LOGS, () => (Http.getAccountLogs(userId,page,size)))
);

const getSpendLogs = (userId,page,size) => (
    createAsyncAction(GET_SPEND_LOGS, () => (Http.getSpendLogs(userId,page,size)))
);

export {
  getAccount,operateAccountCoin,getAccountLogs,getSpendLogs
};
