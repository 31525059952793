import React, {Component} from "react";
import {Form, Button, Select,Input} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;


class ReportFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;

                console.log(values)

                onCommit && onCommit(values);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;

                console.log(values)

                onCommit && onCommit(values);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="举报者ID">
                    {getFieldDecorator('prosecutorExId', {
                            initialValue: null
                        }
                    )(
                        <Input></Input>
                    )}
                </FormItem>
                <FormItem label="被举报者ID">
                    {getFieldDecorator('violatorExId', {
                            initialValue: null
                        }
                    )(
                        <Input></Input>
                    )}
                </FormItem>
                <FormItem label="举报类型">
                    {getFieldDecorator('violateType', {
                            initialValue: null
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value={null}>全部</Option>
                            <Option value="欺诈">欺诈</Option>
                            <Option value="色情低俗">色情低俗</Option>
                            <Option value="诱导行为">诱导行为</Option>
                            <Option value="不实信息">不实信息</Option>
                            <Option value="违法犯罪">违法犯罪</Option>
                            <Option value="骚扰">骚扰</Option>
                            <Option value="抄袭">抄袭</Option>
                            <Option value="侵权">侵权</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="处理结果">
                    {getFieldDecorator('processResult', {
                            initialValue: null
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value={null}>全部</Option>
                            <Option value="INIT">未处理</Option>
                            <Option value="CLOSE">不予处理</Option>
                            <Option value="COMPLETE">已处理</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



export default Form.create()(ReportFilter);