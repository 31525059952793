import React, {Component} from "react";
import {Form, Button, Select, Input} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;


class PlayerPhotoOrderFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if(!err) {
                let { onCommit } = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="选手主页名">
                    {getFieldDecorator('playerName', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear />
                    )}
                </FormItem>

                <FormItem label="用户ID">
                    {getFieldDecorator('userId', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear />
                    )}
                </FormItem>

                <Form.Item label='发货状态'>
                    {
                        getFieldDecorator('status', {initialValue: ''})(
                            <Select style={{width: '80px'}}>
                                <Select.Option value="">所有</Select.Option>
                                <Select.Option value="NOT_SHIPPED">未发货</Select.Option>
                                <Select.Option value="SHIPPED">已发货</Select.Option>
                            </Select>
                        )
                    }
                </Form.Item>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(PlayerPhotoOrderFilter);
