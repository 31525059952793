import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl-context';
import {connect} from 'react-redux';
import {Button, Icon, Input} from 'antd';
import {
    createAccessToken,
    getAuthenticationInfo,
    getUserViewPermissionTree,
    setPageButtons,
} from '../../modules/authentication/action';
import sock from 'utils/sock';
import logo from 'assets/logo.svg';
import Cookie from 'js-cookie';
import crypto from 'crypto';
import {
    ACCESS_TOKEN,
    AUTHENTICATION_INFO,
    USER_PAGE_BUTTONS,
    USER_VIEW_PERMISSION_TREE,
} from '../../modules/authentication/constants';
import './index.scss';
import utils from 'utils/utils';

const filterButtons = (viewPermissions) => {
    let result = [];
    const getButtons = (items, basePath = '') => {
        if (items && items.length != 0) {
            let buttons = [];
            items.forEach((item) => {
                let path = `${basePath}/${item.route}`;
                if (item.type == 'BUTTON') buttons.push(item);
                if (item.children && item.children != 0) getButtons(item.children, path);
            });
            if (buttons.length > 0) result.push({path: basePath, buttons});
        }
    };
    getButtons(viewPermissions);
    return result;
};

const propTypes = {
    prefixCls: PropTypes.string,
    intl: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createAccessToken: PropTypes.func.isRequired,
};

const defaultProps = {
    prefixCls: 'view-login',
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    onInputChange = (e, key) => {
        this.setState({[key]: e.target.value});
    }
    handleLogin = async () => {
        const {
            createAccessToken, getAuthenticationInfo,
            getUserViewPermissionTree, history, setPageButtons,
        } = this.props;
        const {username, password} = this.state;
        let md5 = crypto.createHash("md5");
        md5.update(password);
        let newPassword = md5.digest("hex");
        const accessToken = await createAccessToken(username, newPassword);
        console.log(newPassword);
        Cookie.set(ACCESS_TOKEN, JSON.stringify(accessToken));
        const authenticationInfo = await getAuthenticationInfo();
        const userViewPermissionTree = await getUserViewPermissionTree();
        const userButton = filterButtons(utils.mutableCopy(userViewPermissionTree));
        sessionStorage.setItem(USER_PAGE_BUTTONS, JSON.stringify(userButton));
        sessionStorage.setItem(AUTHENTICATION_INFO, JSON.stringify(authenticationInfo));
        sessionStorage.setItem(USER_VIEW_PERMISSION_TREE, JSON.stringify(userViewPermissionTree));
        setPageButtons(userButton);
        // 登录成功
        console.log(userViewPermissionTree)
        history.push('/welcome');
        sock.connect(accessToken.token);
    }

    updateLocale = (locale) => {
        const {intl} = this.props;
        intl.updateLocale(locale);
    }

    renderLoginPanel = () => {
        const {prefixCls, intl} = this.props;
        const {username, password} = this.state;
        return (
            <div className={`${prefixCls}-loginPanel`}>
                <div className={`${prefixCls}-appInfo`}>
                    <img className={`${prefixCls}-appLogo`} src={logo} alt="logo"/>
                    <span className={`${prefixCls}-appName`}>
            {intl.formatMessage({id: 'appName'})}
          </span>
                </div>
                <div className={`${prefixCls}-appDesc`}>
                    {intl.formatMessage({id: 'login_appDesc'})}
                </div>
                <Input
                    className={`${prefixCls}-loginInput`}
                    style={{height: 40, marginBottom: 24}}
                    placeholder={intl.formatMessage({id: 'login_usernameInput_placeholder'})}
                    type="text"
                    prefix={<Icon type="user" style={{color: 'rgba(0, 0, 0, .25)'}}/>}
                    value={username}
                    onChange={(e) => this.onInputChange(e, 'username')}
                    onPressEnter={this.handleLogin}
                />
                <Input
                    className={`${prefixCls}-loginInput`}
                    placeholder={intl.formatMessage({id: 'login_passwordInput_placeholder'})}
                    type="password"
                    prefix={<Icon type="lock" style={{color: 'rgba(0, 0, 0, .25)'}}/>}
                    value={password}
                    onChange={(e) => this.onInputChange(e, 'password')}
                    onPressEnter={this.handleLogin}
                />
                <Button
                    className={`${prefixCls}-loginBtn`}
                    type="primary"
                    onClick={this.handleLogin}
                >
                    {intl.formatMessage({id: 'login_login_btn'})}
                </Button>
            </div>
        );
    }

    renderIntlSwitch = () => {
        const {prefixCls, intl} = this.props;

        return (
            <div className={`${prefixCls}-intlSwitch`}>
        <span
            className={classnames({
                [`${prefixCls}-intlItem`]: true,
                [`${prefixCls}-intlItem-active`]: intl.locale === 'en-us',
            })}
            onClick={() => this.updateLocale('en-us')}
            role="presentation"
        >
          English
        </span>
                <span className={`${prefixCls}-intlSwitchSeparator`}>
          |
        </span>
                <span
                    className={classnames({
                        [`${prefixCls}-intlItem`]: true,
                        [`${prefixCls}-intlItem-active`]: intl.locale === 'zh-cn',
                    })}
                    onClick={() => this.updateLocale('zh-cn')}
                    role="presentation"
                >
          中文
        </span>
            </div>
        );
    }

    render() {
        const {prefixCls} = this.props;
        return (
            <div className={prefixCls}>
                {this.renderLoginPanel()}
                {this.renderIntlSwitch()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.authentication.token,
    refreshToken: state.authentication.refreshToken,
});


const mapDispatchToProps = {
    createAccessToken,
    getAuthenticationInfo,
    getUserViewPermissionTree,
    setPageButtons,
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(Login)));
