
import createAsyncAction from 'utils/createAsyncAction';
import RoleRequest from 'request/RoleRequest';
import {
  GET_VIEW_ROLE_INFO,
  GET_VIEW_ROLE_LIST,
  CREATE_VIEW_ROLE,
  UPDATE_VIEW_ROLE,
  DELETE_VIEW_ROLE,
  GET_VIEW_ROLE_PERMISSION,
  UPDATE_VIEW_ROLE_PERMISSION,
  GET_FUNCION_PERMISSION,
} from './actionTypes';


export const getViewRoleInfo = (id) => (createAsyncAction(GET_VIEW_ROLE_INFO, () => (RoleRequest.getRole(id))));

export const getViewRoleList = (params) => (createAsyncAction(GET_VIEW_ROLE_LIST, () => (RoleRequest.getRoles(params))));

export const createViewRole = (params) => (createAsyncAction(CREATE_VIEW_ROLE, () => (RoleRequest.addRole(params))));

export const updateViewRole = (params, id) => (createAsyncAction(UPDATE_VIEW_ROLE, () => (RoleRequest.updateRole(params, id))));

export const deleteViewRole = (id) => (createAsyncAction(DELETE_VIEW_ROLE, () => (RoleRequest.deleteRole(id))));

export const getRolePermissionTree = (id) => (createAsyncAction(GET_VIEW_ROLE_PERMISSION, () => (RoleRequest.getRolePermissionTree(id))));

export const updateViewRolePermission = (params, id) => (createAsyncAction(UPDATE_VIEW_ROLE_PERMISSION, () => (RoleRequest.setRolePermissions(params, id))));

export const getFuncionPermission = () => (createAsyncAction(GET_FUNCION_PERMISSION, () => (RoleRequest.getFuncionPermission())));
