import React from 'react';
import {Button, Form, Input, Modal, DatePicker, Select, notification, Image, Icon, Radio} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class MajiaImportInfoCommentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            radioValue:0,
        };
    }

    onSubmit = () => {
        let {selectIds} = this.props;
        let {radioValue} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let val = {
                    ...values,
                }

                let dynamicIdListStr = values.dynamicIdList;
                let dynamicIdList = dynamicIdListStr.split(',');
                if(dynamicIdList.length == 1) {
                    dynamicIdList = dynamicIdListStr.split('，');
                }
                val.dynamicIdList = dynamicIdList;
                val.commentVestType = radioValue == 0 ? 'TEMPLATE' : 'CUSTOM';
               onSuccess && onSuccess(val);
            }
        });
    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            radioValue: e.target.value,
        });
    };


    onOk(value) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    componentDidMount() {
        let {visible,type,data,gameList,typeList} = this.props
        if(type != 'ADD' && data != null) {
            this.setState({needSignUp:data.needSignUp})
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,selectIds,type,data,gameList,typeList} = this.props
        let{radioValue,isSignIn,isShowMap,needSignUp} = this.state;
        return (
            <Modal
                width={'60%'}
                visible={visible}
                title="马甲注入评论"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form>

                    <FormItem
                        label="评论生成时间"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('hours', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入评论生成时间'}],
                        })(
                            <Input placeholder="请输入评论生成时间"/>
                        )}<span>发布后小时内,设置评论生成的时间，在内容发布时间后一段时间内随机生成评论。</span>
                    </FormItem>
                    <FormItem
                        label="评论数"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('commentNum', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入评论数'}],
                        })(
                            <Input placeholder="请输入评论数"/>
                        )}<span>每条选中的内容对应注入的评论数，建议不要设置过多重复评论。</span>
                    </FormItem>

                    <FormItem
                        label="资讯ID（多个,隔开）"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('dynamicIdList', {
                            initialValue:'',
                            rules: [{required:true, message: '请输入资讯ID'}],
                        })(
                            <TextArea rows={3} placeholder="请输入资讯ID"/>,
                        )}
                    </FormItem>


                    <Radio.Group style={{marginBottom:20,marginLeft:20}} onChange={this.onChange} value={radioValue}>
                        <Radio value={0}>随机选取评论模板</Radio>
                        <Radio value={1}>自定义内容</Radio>
                    </Radio.Group>


                    {radioValue == 1 &&
                        <FormItem
                            label="自定义内容"
                            {...formItemLayout}

                        >
                            {getFieldDecorator('content', {
                                initialValue:'',
                                rules: [{required: radioValue == 1 ?true:false, message: '请输入自定义内容'}],
                            })(
                                <TextArea rows={6} placeholder="请输入自定义内容"/>,
                            )}
                        </FormItem>
                    }


                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
MajiaImportInfoCommentModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(MajiaImportInfoCommentModal)));
