import React from 'react';
import {Button, Form, Input, InputNumber, Modal, Switch,Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../components/upload";
import {getUser,updateUserCertification} from "../../modules/certification/action"
const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class UpdateCretificationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            userId : ""
        };
    }

    componentDidMount() {
        const {getUser} = this.props;
        console.log(this.state.userId)
        this.setState({
            userId : this.props.userId
        })
        getUser(this.props.userId);

    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmit } = this.props;
                onSubmit && onSubmit(this.props.userId,values);
            }
        });
    }

    onChange =(checked) => {
        console.log(`switch to ${checked}`);
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        // let {playerId,gameYear,position,qq,playerName,currentLevel,currentKda,highestKda,highestLevel,imageUrl} = this.props.userCertification.length!==0 ? this.props.userCertification[0] : this.props.userCertification;

        return (
                <Form>
                    <FormItem
                        label="游戏ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerId', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].playerId : '',
                            rules: [{required: true, message: '请填写游戏ID'},{pattern: /^[123456789]{6,13}$/, message: '游戏ID有误，请重填'}],
                        })(
                            <Input placeholder="请填写游戏ID"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="游戏年限"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('gameYear', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].gameYear : '',
                            rules: [{required: true, message: '请填写游戏年限'},{pattern: /^[0123456789]$/, message: '游戏年限限制只填数字，请重填'}],
                        })(
                            <Input  placeholder="请填写游戏年限"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="队伍位置"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('position', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].position : '',

                            rules: [{required: true, message: '请填写队伍位置'},{maxLength: 14, message: '不能超过14个字符'}],
                        })(
                            <Input placeholder="请填写队伍位置,不能超过14个字符"  maxLength={14}/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="参赛QQ"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('qq', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].qq : '',

                            rules: [{required: true, message: '请填写参赛QQ'}],
                        })(
                            <Input placeholder="请填写参赛QQ"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="参赛昵称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerName', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].playerName : '',
                            rules: [{required: true, message: '请填写参赛昵称'}],

                        })(
                            <Input placeholder="请填写参赛昵称"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="当前段位"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('currentLevel', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].currentLevel : '',
                            rules: [{required: true, message: '请填写当前段位'}],


                        })(
                            <Select style={{width: '100px'}}>
                                <Select.Option value="青铜">青铜</Select.Option>
                                <Select.Option value='白银'>白银</Select.Option>
                                <Select.Option value='黄金'>黄金</Select.Option>
                                <Select.Option value='铂金'>铂金</Select.Option>
                                <Select.Option value='星钻'>星钻</Select.Option>
                                <Select.Option value='皇冠'>皇冠</Select.Option>
                                <Select.Option value='超级王牌'>超级王牌</Select.Option>
                                <Select.Option value='无敌战神'>无敌战神</Select.Option>


                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="当前KDA"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('currentKda', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].currentKda : '',
                            rules: [{required: true, message: '请填写当前KDA'},{
                                required:false,
                                pattern: /^([1-9]\d?(\.\d{1,2})?|0\.\d{1,2}|100)$/,
                                message: '请输入正确的KDA'
                            }],

                        })(
                            <Input placeholder="请填写当前KDA"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高段位"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('highestLevel', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].highestLevel : '',
                            rules: [{required: true, message: '请填写最高段位'}],

                        })(
                            <Select style={{width: '100px'}}>
                                <Select.Option value="青铜">青铜</Select.Option>
                                <Select.Option value='白银'>白银</Select.Option>
                                <Select.Option value='黄金'>黄金</Select.Option>
                                <Select.Option value='铂金'>铂金</Select.Option>
                                <Select.Option value='星钻'>星钻</Select.Option>
                                <Select.Option value='皇冠'>皇冠</Select.Option>
                                <Select.Option value='超级王牌'>超级王牌</Select.Option>
                                <Select.Option value='无敌战神'>无敌战神</Select.Option>


                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高KDA"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('highestKda', {
                            initialValue : this.props.userCertification[0] ? this.props.userCertification[0].highestKda : '',
                            rules: [{required: true, message: '请填写最高KDA'},{
                                required:false,
                                pattern: /^([1-9]\d?(\.\d{1,2})?|0\.\d{1,2}|100)$/,
                                message: '请输入正确的KDA'
                            }],

                        })(
                            <Input placeholder="请填写最高KDA"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高段位截图"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue:  this.props.userCertification[0] ? this.props.userCertification[0].imageUrl : '',
                            rules: [{required: true, message: '请上传截图'}],

                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem>
                    <Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </Form.Item>

                </Form>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification,
    }
};
const mapDispatchToProps = ({
    getUser,updateUserCertification
});
UpdateCretificationModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdateCretificationModal)));
