import api from '../utils/api';


class CoinRequest {

    /**
     * 获取浪币套餐列表
     * @returns {*}
     */
    static getCoins() {
        return api.get(`/admin/recharge/packages/all`, );
    }

    /**
     * 添加浪币套餐
     * @returns {*}
     */
    static addCoins({amount,coin,packageDetail,packageName,type,applePayId}) {
        console.log(amount,coin,packageDetail,packageName,type)
        return api.post('/admin/recharge/packages', {amount,coin,packageDetail,packageName,type,applePayId} );
    }

    /**
     * 删除浪币套餐
     * @param packageId
     * @returns {*}
     */
    static deleteCoins(packageId) {
        return api.delete(`/admin/recharge/packages/${packageId}`);
    }

    /**
     * 更新浪币套餐
     * @param packageId
     * @returns {*}
     */
    static updateCoins(packageId,{amount,coin,type,packageDetail,packageName,applePayId}) {
        console.log(amount,coin,type,packageDetail,packageName,applePayId)
        return api.put(`/admin/recharge/packages/${packageId}`,  {amount,coin,type,packageDetail,packageName,applePayId} );
    }

}
export default CoinRequest;