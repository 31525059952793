import React from 'react';
import {Form, Input, Modal,Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddListingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props
        return (
            <Modal
                visible={visible}
                title="新增自荐计划"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form {...formItemLayout}>
                    <FormItem
                        label="自荐用户ID:"
                        
                    >
                        {getFieldDecorator('exhibitionId', {
                            rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'},{required: true, message: '请输入自荐用户ID'}],
                        })(
                            <Input placeholder="请输入自荐用户ID"/>
                        )}
                    </FormItem>
                    <FormItem label="选择自荐位置"
                    >
                        {getFieldDecorator('floorPosition', {
                            initialValue:"",
                            rules: [{required: true, message: '请选择场上位置'}],
                            }
                        )(
                            <Select >
                                <Option value="">请选择</Option>
                                <Option value="指挥官">指挥官</Option>
                                <Option value="自由人">自由人</Option>
                                <Option value="突击手">突击手</Option>
                                <Option value="狙击手">狙击手</Option>
                                <Option value="全位置">全位置</Option>
                            </Select>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
AddListingModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddListingModal)));
