import createAsyncAction from 'utils/createAsyncAction';
import BannerRequest from '../../request/BannerRequest' ;
import {
    GET_BANNERS,
    CREATE_BANNER,
    GET_BANNER,
    UPDATE_BANNER,
    DELETE_BANNER,
    CHANGE_BANNER_STATUS,
    GET_INNER_JUMP_TYPE,
    GET_VALUATION_IMAGE,
    ADD_VALUATION_IMAGE,
    UPDATE_VALUATION_IMAGE
} from '../banner/actionTypes'

const getBanners = (pageNo,pageSize,pageType) => (
    createAsyncAction(GET_BANNERS,() => (BannerRequest.getBanners(pageNo,pageSize,pageType)))
);

const createBanner = ({imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside}) => (
    createAsyncAction(CREATE_BANNER,() => (BannerRequest.addBanner({imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside})))
)

const getBanner = (id) => (
    createAsyncAction(GET_BANNER,() => (BannerRequest.getBanner(id)))
);

const updateBanner = (id,{imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside}) => (
    createAsyncAction(UPDATE_BANNER,() => (BannerRequest.updateBanner(id,{imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside})))
);

const deleteBanner = (id) => (
    createAsyncAction(DELETE_BANNER,() => (BannerRequest.deleteBanner(id)))
);

const changeStatus = (id,enable) => (
    createAsyncAction(CHANGE_BANNER_STATUS,() => (BannerRequest.changeStatus(id,enable)))
)

const getInnerJumpType = () =>{
    createAsyncAction(GET_INNER_JUMP_TYPE,() => (BannerRequest.getInnerJumpType()))
}

const getValuation = (imageUrl,enable,pageType) =>(createAsyncAction(ADD_VALUATION_IMAGE,() => (BannerRequest.getValuation(imageUrl,enable,pageType))))

const updateValuation = (imageUrl,enable,pageType) =>(createAsyncAction(UPDATE_VALUATION_IMAGE,() => (BannerRequest.updateValuation(imageUrl,enable,pageType))))



const getImage = (pageType) =>(createAsyncAction(GET_VALUATION_IMAGE,() => (BannerRequest.getImage(pageType))))


export {
    getBanner,getBanners,createBanner,updateBanner,deleteBanner,changeStatus,getInnerJumpType,getValuation,getImage,updateValuation
}
