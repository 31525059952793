import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import moment from "moment";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class CommentFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {commentUserExhibitionId,commentUserName,keyword,gameType,commentStatus,dateRange} = values;
                onCommit && onCommit(commentUserExhibitionId,commentUserName,keyword,commentStatus,dateRange,null,gameType);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {commentUserExhibitionId,commentUserName,keyword,gameType,commentStatus,dateRange} = values;
                onCommit && onCommit(commentUserExhibitionId,commentUserName,keyword,commentStatus,dateRange,null,gameType);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource,gameList} = this.props;
        const {commentUserExhibitionId,commentUserName,keyword} = dataSource;
        console.log("gameList",gameList);
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="用户展示ID">
                    {getFieldDecorator('commentUserExhibitionId', {
                            initialValue: commentUserExhibitionId

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户名">
                    {getFieldDecorator('commentUserName', {
                            initialValue: commentUserName
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="关键词">
                    {getFieldDecorator('keyword', {
                            initialValue: keyword
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="游戏类型">
                    {getFieldDecorator('gameType', {
                            initialValue: ''
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value="">全部</Option>
                                {
                                    gameList.length>0 && gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="评论状态">
                    {getFieldDecorator('commentStatus', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value=" ">全部</Option>
                            <Option value="NORMAL">正常</Option>
                            <Option value="SHIELD">已屏蔽</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发布时间区间：">
                    {getFieldDecorator('dateRange', {
                        initialValue: ""
                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Comments

    }

};

const mapDispatchToProps = ({
    mapStateToProps
});


export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CommentFilter)));