import React from 'react';
import {Button, Card, Cascader, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    getBrand,
    getTraining,
    getTrainingTemplate,
    getTrainingTemplateDictionaries
} from "../../../modules/training/action";
import moment from "moment";
import {getGameList} from "../../../modules/team/action";
import TrainingTemplateFilter from "./TrainingTemplateFilter";
import VideoConfigModal from "./VideoConfigModal";
import TrainingPreconditionModel from "./TrainingPreconditionModel";
import ForbidTeamModel from "../../team-management/components/ForbidTeamModel";
import TeamMembersModel from "../../team-management/components/TeamMembersModel";

const dateFormat = 'YYYY/MM/DD HH:mm:00';
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 7},
    wrapperCol: {span: 16},
};
const {TextArea} = Input;
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            gameNo: 1,
            preconditionValue: null,
            isShowPreconditionModal : false,
        };
    }


    componentWillMount() {
        this.getGameList();
        const {getTrainingTemplateDictionaries, data} = this.props;
        getTrainingTemplateDictionaries();
        this.setState({
            preconditionValue: data.precondition ? data.precondition : null
        })
    }

    getGameList = () => {
        let {getGameList} = this.props;
        getGameList();
    }

    onSubmit = () => {
        let {data} = this.props
        let {preconditionValue} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (data.id) {
                    values["trainingId"] = data.id
                }
                if(preconditionValue){
                    values["precondition"] = preconditionValue
                }

                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                console.log(values)
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

        console.log(value)
    }


    onOk(value) {

        console.log(value)
    }

    onTrainingTemplateFilterEvent = async (game, brandId, type) => {
        this.props.form.resetFields();
        console.log("vauew111",game, brandId, type)
        const {getTrainingTemplate} = this.props;
        await getTrainingTemplate(game, brandId, type)
        this.setState({})
    }

    //前置条件
    onPreconditionConfig = async (params) => {
        this.setState({
            preconditionValue: JSON.stringify(params),
            isShowPreconditionModal: false
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, brandData, data, games, trainingTemplate, trainingTemplateDictionaries} = this.props

        let {gameNo, preconditionValue, isShowPreconditionModal} = this.state;

        return (
            <div>
                <Modal
                    visible={visible}
                    title="训练赛信息"
                    onCancel={this.props.onClose}
                    cancelText="取消"
                    onOk={this.onSubmit}
                    okText={data.gameTheme ? "确认" : "确认"}
                    width={'57%'}
                >
                    {data.gameTheme ? null : <Card style={{marginBottom: 30}}>
                        <TrainingTemplateFilter options={trainingTemplateDictionaries} onCommit={this.onTrainingTemplateFilterEvent}/>
                    </Card>}
                    {/*<Button style={{marginLeft: "10px", marginRight: "10px", marginBottom: "10px"}} type="primary" onClick={() => {this.setState({*/}
                        {/*isShowPreconditionModal: !isShowPreconditionModal})}}*/}
                            {/*color="blue">前置条件</Button>  <span>{preconditionValue ? "已配置" :  "未配置" }</span>*/}
                    <Form>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="赛事名"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameTheme', {
                                        initialValue: data.gameTheme ? data.gameTheme : trainingTemplate.trainingTheme ? trainingTemplate.trainingTheme : "",
                                        rules: [{required: true, message: '请填写赛事名!'}],
                                    })(
                                        <Input placeholder="请填写赛事名"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="赛事类型"  {...formItemLayout}>
                                    {getFieldDecorator('brandId', {
                                            initialValue: data.brandId ? data.brandId : trainingTemplate.brandId ? trainingTemplate.brandId : brandData.data && brandData.data[0].id,
                                            rules: [{required: true, message: '请填写赛事类型!'}],
                                        }
                                    )(
                                        <Select>
                                            {brandData.data && brandData.data.map(item => {
                                                return (
                                                    <Option
                                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="局数"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameNum', {
                                        initialValue: data.gameNum ? data.gameNum : trainingTemplate.gameNum ? trainingTemplate.gameNum : 1,
                                        rules: [{required: true, message: '请填写正确格式'}],
                                    })(
                                        <InputNumber min={1} precision={0} placeholder="请填写局数" onChange={e => {
                                            this.setState({
                                                gameNo: e
                                            });
                                        }}
                                        />,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="最大报名战队数"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('teamTotal', {
                                        initialValue: data.teamTotal ? data.teamTotal : trainingTemplate.teamTotal ? trainingTemplate.teamTotal : 1,
                                        rules: [{required: true, message: '请填写正确格式'}],
                                    })(
                                        <InputNumber min={1} precision={0} placeholder="请填写最大报名人数"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="直播平台"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('livePlatform', {
                                        initialValue: data.livePlatform ? data.livePlatform : "",
                                        rules: [{required: false, message: '请填写直播平台'}],
                                    })(
                                        <Input placeholder="请填写直播平台"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="直播路径"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('livePathUrl', {
                                        initialValue: data.livePathUrl ? data.livePathUrl : "",
                                        rules: [{required: false, message: '请填写直播路径'}],
                                    })(
                                        <Input placeholder="请填写直播路径" type="url"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label="用户是否可报名"  {...formItemLayout}>
                                    {getFieldDecorator('singUpQualifications', {
                                            initialValue: data.singUpQualifications !== undefined ? data.singUpQualifications : "InTime_SIGNUP",
                                            rules: [{required: true, message: '请选择!'}],
                                        }
                                    )(
                                        <Select>
                                            <Option value={"InTime_SIGNUP"}>是</Option>
                                            <Option value={"INVITATION"}>否</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="是否显示"  {...formItemLayout}>
                                    {getFieldDecorator('isShow', {
                                            initialValue: data.isShow !== undefined ? data.isShow : true,
                                            rules: [{required: true, message: '请选择!'}],
                                        }
                                    )(
                                        <Select>
                                            <Option value={true}>是</Option>
                                            <Option value={false}>否</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="是否完成"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('isEnd', {
                                        initialValue: data.isEnd !== undefined ? data.isEnd : false,
                                        rules: [{required: true, message: '请选择'}],
                                    })(
                                        <Select>
                                            <Option value={true}>是</Option>
                                            <Option value={false}>否</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="比赛开始时间"  {...formItemLayout}>
                                    {getFieldDecorator('gameStartTime', {
                                            initialValue: data.gameStartTime ? moment(data.gameStartTime, dateFormat) : trainingTemplate.gameStartTime ? moment(trainingTemplate.gameStartTime, dateFormat) : moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择比赛开始时间!'}],
                                        }
                                    )(
                                        <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label="报名开始时间"  {...formItemLayout}>
                                    {getFieldDecorator('signUpStartTime', {
                                            initialValue: data.signUpStartTime ? moment(data.signUpStartTime, dateFormat) : trainingTemplate.signUpStartTime ? moment(trainingTemplate.signUpStartTime, dateFormat) : moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择报名开始时间!'}],
                                        }
                                    )(
                                        <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}
                                                    defaultValue={moment('00', 'ss')}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="报名结束时间"  {...formItemLayout}>
                                    {getFieldDecorator('signUpEndTime', {
                                            initialValue: data.signUpEndTime ? moment(data.signUpEndTime, dateFormat) : trainingTemplate.signUpEndTime ? moment(trainingTemplate.signUpEndTime, dateFormat) : moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择报名结束时间!'}],

                                        }
                                    )(
                                        <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="当前场次"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('currentGameNo', {
                                        initialValue: data.currentGameNo ? data.currentGameNo : 1,
                                        rules: [{required: false, message: '请选择当前场次'}],
                                    })(
                                        <Select>
                                            {[...new Array(gameNo !== 1 ? gameNo : data.gameNum ? data.gameNum : 1).keys()].map(v => {
                                                return (
                                                    <Option value={v + 1}>{v + 1}</Option>
                                                )
                                            })}
                                        </Select>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="房间密码"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gamePwd', {
                                        initialValue: data.gamePwd ? data.gamePwd : Array.from({length: 6}, (v, k) => Math.floor(Math.random() * (10 - 0)) + 0).join(""),
                                        rules: [{
                                            required: false
                                            , message: '请填写密码'
                                        }],
                                    })(
                                        <Input placeholder="请输入密码"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="联系方式"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('communication', {
                                        initialValue: data.communication ? data.communication : trainingTemplate.communication ? trainingTemplate.communication : "",
                                        rules: [{
                                            required: false
                                            , message: '请填写联系方式'
                                        }],
                                    })(
                                        <Input placeholder="请输入联系方式"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="房间号"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('roomNo', {
                                        initialValue: data.roomNo ? data.roomNo : trainingTemplate.roomNo ? trainingTemplate.roomNo : "",
                                        rules: [{required: false, message: '请填写房间号'}],
                                    })(
                                        <Input placeholder="请输入房间号"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="比赛规则"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameRule', {
                                        initialValue: data.gameRule ? data.gameRule : trainingTemplate.gameRule ? trainingTemplate.gameRule : "",
                                        rules: [{required: true, message: '请填写比赛规则!'}],
                                    })(
                                        <TextArea rows={6} placeholder="请输入比赛规则"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="直播是否跳转外部"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('jumpOutside', {
                                        initialValue: data.jumpOutside !== undefined ? data.jumpOutside : false,
                                        rules: [{required: false, message: '请选择'}],
                                    })(
                                        <Select>
                                            <Option value={true}>是</Option>
                                            <Option value={false}>否</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                {isShowPreconditionModal ? <TrainingPreconditionModel
                    visible={isShowPreconditionModal}
                    precondition={preconditionValue}
                    onSuccess={this.onPreconditionConfig}
                    onClose={() => {
                        this.setState({
                            isShowPreconditionModal: false,
                        });
                    }}
                /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTraining,
    getGameList,
    getBrand,
    getTrainingTemplate,
    getTrainingTemplateDictionaries
});
TrainingInfoModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingInfoModal)));
