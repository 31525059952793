import React from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form, Input,
    InputNumber,
    message,
    Modal, notification,
    Popconfirm,
    Popover,
    Row, Select,
    Table,
    Tabs,
    Tag
} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getStatics} from "../../modules/information/action";
import {getGame} from "../../modules/game/action";
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";
import {map} from 'lodash/fp';
import moment from "moment";
import api from "../../utils/api";
import { getPostLikeConfig } from '../../modules/topic/action';
import TopicRequest from "../../request/TopicRequest";
import Switch from "antd/lib/switch";
const {confirm} = Modal;
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 12},
    wrapperCol: {span: 12},
};

/**
 * 点赞配置
*/
class LikeConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowActivityModel:false,//新增窗口
      modelType:'',//展示弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modelData:{},
      pageNo:1,
      pageSize: 10,
      params:{
          clubId:'',
      },
      id:'',
      configData:{}

    };
  }

  componentWillMount() {
    this.getConfig()
  }

    getConfig() {
        TopicRequest.getPostLikeConfig().then(res=>{
            this.config = res;
            let confValue = res.confValue;
            let config = JSON.parse(confValue);
            this.setState({configData:config});
        })
    }

  componentWillReceiveProps(nextProps, nextContext) {
  }



  onGetData = async () => {
      const {pageNo, pageSize,params} = this.state;
      let {getStatics} = this.props;
      console.log("state", this.state)
      // await getStatics({page:pageNo, size:pageSize,...params});
  }





  getAuthorizationHeader = (headers) => {
      try {
          let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
          const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
          if (headers && accessToken) {
              headers.Authorization = accessToken.token;
          }
          headers.ContentType = 'application/json';
          return headers;
      } catch (error) {
          return '';
      }
  };


    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let config = {multiple:[values.start,values.end],time:values.time,status:values.status,remark:values.remark};
                let configValue = JSON.stringify(config);
                this.config.confValue = configValue;
                TopicRequest.updatePostLikeConfig(this.config).then(res=>{
                    notification.success({
                        message: '提示',
                        description: '操作处理成功!',
                    });
                }).catch(err=>{
                    let { response } = err;
                    const data = response.data || {};
                    let {msg} = data;
                    notification.error({
                        message: '提示',
                        description: msg && msg,
                    });
                });
            }
        });
    }


  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.setState({isShowActivityModel: true,modelType:'ADD'})}>新增</Button>
        </Col>
    </Row>
  )


  render() {
    let{statics,gameList} = this.props;
    let {configData} = this.state;
      const {getFieldDecorator} = this.props.form;
    return (
      <div>
          <Card style={{marginBottom: 30}}>
              <Form
              >


                  <div>
                      配置值：
                  </div>
                  <Row>
                      <Col span={3} style={{marginLeft:10}}>
                          <FormItem
                              label="最小倍数"
                              {...formItemLayout}
                          >
                              {getFieldDecorator('start', {
                                  rules: [{required: true, message: '请填写最小倍数'}],
                                  initialValue: configData && configData.multiple ? configData.multiple[0] : "",
                              })(
                                  <Input style={{width:150}}  placeholder="请填写最小倍数"/>,
                              )}
                          </FormItem>
                      </Col>
                      <Col span={5} >
                          <FormItem
                              label="最大倍数"
                              {...formItemLayout}
                          >
                              {getFieldDecorator('end', {
                                  rules: [{required: true, message: '请填写最大倍数'}],
                                  initialValue: configData && configData.multiple ? configData.multiple[1] : "",
                              })(
                                  <Input style={{width:150}}  placeholder="请填写最大倍数"/>,
                              )}
                          </FormItem>
                      </Col>
                      <Col span={5}>
                      </Col>
                      <Col span={5}>
                      </Col>
                  </Row>

                  <Row>
                      <Col span={5} style={{marginLeft:20}}>
                          <FormItem
                              label="发帖后生效时间(分钟)"
                              {...formItemLayout}
                          >
                              {getFieldDecorator('time', {
                                  rules: [{required: true, message: '请填写发帖后生效时间'}],
                                  initialValue: configData && configData.time ? configData.time : "",
                              })(
                                  // <div style={{width:250,display:'flex',flexDirection:'row'}}>
                                      <Input style={{width:180}}  placeholder="请填写发帖后生效时间"/>
                                  // </div>

                              )}
                          </FormItem>
                      </Col>

                      <Col span={5}>
                      </Col>
                      <Col span={5}>
                      </Col>
                  </Row>

                  <Row>
                      <Col span={3}>
                          <FormItem
                              label="是否开启"
                              {...formItemLayout}
                          >
                              {getFieldDecorator('status', {
                                  rules: [{required: true, message: '是否开启'}],
                                  valuePropName:'checked',
                                  initialValue: configData && configData.status ? configData.status : "",
                              })(
                                  <Switch></Switch>
                              )}
                          </FormItem>

                      </Col>

                      <Col span={18}>

                      </Col>
                  </Row>

                  <Row style={{marginLeft:30}}>
                      <Col span={18}>
                          说明：配置20-35被后，点赞数按照这个区间，随机增加点赞数据
                      </Col>
                      <Col span={18}>
                      </Col>
                  </Row>
                  <Row style={{marginLeft:30}}>
                      <Col span={18}>
                          说明:新贴发布20分钟后，再增加点赞数据
                      </Col>
                      <Col span={18}>
                      </Col>
                  </Row>

                  <Row style={{marginTop:30}}>
                      <Col offset={100} span={2}>
                          <FormItem
                              label="备注"
                              {...formItemLayout}
                          >
                              {getFieldDecorator('remark', {
                                  initialValue: configData && configData.remark ? configData.remark : "",
                              })(
                                      <Input style={{width:500}}  placeholder="请填写备注"/>
                              )}
                          </FormItem>

                      </Col>

                      <Col span={18}>

                      </Col>
                   </Row>

                  <FormItem>
                      <Button onClick={()=>{
                         this.onSubmit();
                      }} type="primary" htmlType="submit" style={{width: 100,marginLeft:30}}>
                          提交
                      </Button>
                  </FormItem>
              </Form>



          </Card>
      </div>
    );
  }

//翻页
onPageChangeEvent = (current, pageSize) => {
  const {getActivity} = this.props;
  const { params} = this.state;
  this.setState({
      pageNo: current,
      pageSize: pageSize,
  }, () => {
    getActivity({page: current, size: pageSize, ...params})
  });
}

}

const mapStateToProps = (state) => {
  return {
    ...state.configData,
  }
};

const mapDispatchToProps = ({
    getPostLikeConfig,
});

const WrappedFormLikeConfig = Form.create({ name: 'normal_login' })(LikeConfig);


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(WrappedFormLikeConfig));
