import React from 'react';
import {Button, Card, Col, message, Modal, Row, Table, Tabs,} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/certification/enums';
import {getGameList} from "../../modules/team/action";
import {
    addTeamCertifiacation,
    getTeamCertificationInfo,
    getTeamCertificationList, updateTeamCertifiacation,
    verifyCertifiacation
} from "../../modules/certification/action";
import CertificationInfoModel from "./components/CertificationInfoModel";
import CertificationFilter from "./components/CertificationFilter";
import AddCertificationModel from "./components/AddCertificationModel";
import UpdateCertificationModel from "./components/UpdateCertificationModel";

const {confirm} = Modal;
const {TabPane} = Tabs;

class Certification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            previewVisible: false,
            previewImage: '',
            operationType: operationTypeValue.INCREASE,
            isShowCertificationInfoModel: false, // 战队信息
            isShowAddCertificationModel: false,
            isShowUpdateCertificationInfoModel: false,
            seletionValue: null,
            page: 1,
            size: 10,
            brandId: '',
            game: '',
            teamShowId: '',
            status: 'REVIEW',
            teamName: '',
            leaderQQ: ''
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.certifications !== this.props.certifications) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getTeamCertificationList, getGameList} = this.props;
        const {page, size, status} = this.state;
        this.setState({
            loading: true
        })
        getTeamCertificationList({
            page: page, size: size,
            brandId: this.state.brandId ? this.state.brandId : null,
            game: this.state.game ? this.state.game : null,
            teamShowId: this.state.teamShowId ? this.state.teamShowId : null,
            teamName: this.state.teamName ? this.state.teamName : null,
            leaderQQ: this.state.leaderQQ ? this.state.leaderQQ : null,
            status: this.state.status ? this.state.status : null
        });
        getGameList();
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getTeamCertificationList} = this.props;
        getTeamCertificationList({
            page: current, size: pageSize,
            brandId: this.state.brandId ? this.state.brandId : null,
            game: this.state.game ? this.state.game : null,
            teamShowId: this.state.teamShowId ? this.state.teamShowId : null,
            teamName: this.state.teamName ? this.state.teamName : null,
            leaderQQ: this.state.leaderQQ ? this.state.leaderQQ : null,
            status: this.state.status ? this.state.status : null
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onFormSubmitConfirm = (value) => {
        let _this = this;
        confirm({
            content: '确认' + (value.state === 'ADOPT' ? '通过' : '拒绝') + '该参赛认证吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onFormSubmit(value)
            },
            onCancel() {
            },

        });
    }

    onAddFormSubmitConfirm = (value) => {
        let _this = this;
        confirm({
            content: '确认要添加该参赛认证吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onAddFormSubmit(value);
            },
            onCancel() {
            },

        });
    }

    onUpdateFormSubmitConfirm = (value) => {
        let _this = this;
        confirm({
            content: '确认要修改该参赛认证信息吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUpdateFormSubmit(value);
            },
            onCancel() {
            },

        });
    }


    onAddFormSubmit = async (values) => {
        const {isShowAddCertificationModel} = this.state
        const {addTeamCertifiacation} = this.props
        await addTeamCertifiacation(values);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('添加成功');
        this.setState({isShowAddCertificationModel: !isShowAddCertificationModel});
    }


    onUpdateFormSubmit = async (values) => {
        console.log(values)
        const {isShowUpdateCertificationInfoModel} = this.state
        const {updateTeamCertifiacation} = this.props
        await updateTeamCertifiacation(values);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('修改成功');
        this.setState({
            isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
        });
    }


    onFormSubmit = async (values) => {
        const {isShowCertificationInfoModel, seletionValue} = this.state
        const {verifyCertifiacation} = this.props
        const params = values;
        params["teamId"] = seletionValue.teamId;
        params["brandId"] = seletionValue.brandId;
        await verifyCertifiacation(params);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('审核成功');
        this.setState({isShowCertificationInfoModel: !isShowCertificationInfoModel});
    }


    //条件查找动态方法
    onFilterCertificationEvent = async (brandId, game, teamShowId, teamName, leaderQQ) => {
        const {size, status} = this.state;
        const {getTeamCertificationList} = this.props;
        this.setState({
            loading: true
        })
        await getTeamCertificationList({
            page: 1, size: size,
            brandId: brandId ? brandId : null,
            game: game ? game : null,
            teamShowId: teamShowId ? teamShowId : null,
            teamName: teamName ? teamName : null,
            leaderQQ: leaderQQ ? leaderQQ : null,
            status: status
        });
        this.setState({
            brandId: brandId ? brandId : null,
            game: game ? game : null,
            teamShowId: teamShowId ? teamShowId : null,
            teamName: teamName ? teamName : null,
            leaderQQ: leaderQQ ? leaderQQ : null,
            page: 1
        })
    }

    changeTabPane = async (key) => {
        const {size,} = this.state;
        const {getTeamCertificationList} = this.props;
        this.setState({
            status: key ? key : null,
            page: 1,
            brandId: null,
            game: null,
            teamShowId: null,
            teamName: null,
            leaderQQ: null,
            loading: true
        })
        await getTeamCertificationList({
            page: 1, size: size,
            status: key ? key : null
        });
    }

    renderTableTitle = () => {
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button type="primary"
                            onClick={() => this.setState({isShowAddCertificationModel: true})}>新增</Button>
                </Col>
            </Row>
        )
    }

    renderTabPane = () => {
        const {
            certifications, games
        } = this.props;
        const {
            isShowCertificationInfoModel, isShowUpdateCertificationInfoModel, isShowAddCertificationModel, seletionValue, page, size, status, loading, previewVisible, previewImage,
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CertificationFilter value={status} onCommit={this.onFilterCertificationEvent}/>
                </Card>

                <Card>
                    <Table
                        dataSource={certifications ? certifications.data : []}
                        columns={this.getTableColumns(games)}
                        title={this.state.status === "SUCCESS" ? this.renderTableTitle : null}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: certifications.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowCertificationInfoModel ? <CertificationInfoModel
                        value={seletionValue}
                        onSuccess={this.onFormSubmitConfirm}
                        visible={isShowCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowCertificationInfoModel: !isShowCertificationInfoModel,
                            });
                        }}
                    /> : null}
                    {isShowAddCertificationModel ? <AddCertificationModel
                        onSuccess={this.onAddFormSubmitConfirm}
                        visible={isShowAddCertificationModel}
                        onClose={() => {
                            this.setState({
                                isShowAddCertificationModel: !isShowAddCertificationModel,
                            });
                        }}
                    /> : null}
                    {isShowUpdateCertificationInfoModel ? <UpdateCertificationModel
                        value={seletionValue}
                        onSuccess={this.onUpdateFormSubmitConfirm}
                        visible={isShowUpdateCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
                            });
                        }}
                    /> : null}
                </Card>

                {previewVisible ?
                    <Modal width={'80%'} visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <img alt="example" src={previewImage}/>
                        </div>
                    </Modal> : null}
            </div>
        )
    }

    render() {
        return (
            <div>
                <Tabs onChange={this.changeTabPane} type="card">
                    <TabPane tab="参赛认证列表(审核中)" key="REVIEW">
                        {this.state.status === "REVIEW" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="参赛认证列表(通过)" key="SUCCESS">
                        {this.state.status === "SUCCESS" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="参赛认证列表(拒绝)" key="FAIL">
                        {this.state.status === "FAIL" ? this.renderTabPane() : null}
                    </TabPane>
                </Tabs>
            </div>
        );
    }

    onGetCertificationInfoEvent = async (value) => {
        const {isShowCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowCertificationInfoModel: !isShowCertificationInfoModel,
        });
    }

    onUpdateCertificationInfoEvent = async (value) => {
        const {isShowUpdateCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
        });
    }


    handleCancel = () => this.setState({previewVisible: false});
    showPreviewImage = (value) => {
        this.setState({previewImage: value, previewVisible: true});
    }

    getTableColumns = (games) => [{
        title: '品牌赛事名',
        dataIndex: 'brandName',
        align: 'center',
        key: 'brandName',
    },
    //     {
    //     title: '游戏类型',
    //     dataIndex: 'game',
    //     align: 'center',
    //     key: 'game',
    //     render: (row, text) => (
    //         <span>{games.filter((item) => item.code === text.game).length > 0 ? games.filter((item) => item.code === text.game)[0].gameName : ""}</span>
    //     ),
    // }, {
    //     title: '品牌赛事LOGO',
    //     dataIndex: 'brandLogo',
    //     align: 'center',
    //     key: 'brandLogo',
    //     render: (row, text) => (
    //         <div>
    //             <img id="myImage" alt={""}
    //                  src={text.brandLogo ? text.brandLogo + '?x-oss-process=image/resize,h_100' : ""} height="100"
    //                  onClick={() => this.showPreviewImage(text.brandLogo ? text.brandLogo + '?x-oss-process=image/resize,w_690' : "")}/>
    //         </div>
    //     ),
    // },
        {
        title: '战队名称',
        dataIndex: 'teamName',
        align: 'center',
        key: 'teamName',
    }, {
        title: '战队ID',
        dataIndex: 'teamShowId',
        align: 'center',
        key: 'teamShowId',
    }, {
        title: '战队LOGO',
        dataIndex: 'teamLogo',
        align: 'center',
        key: 'teamLogo',
        render: (row, text) => (
            <div>
                <img id="myImage" alt={""}
                     src={text.teamLogo ? text.teamLogo + '?x-oss-process=image/resize,h_100' : ""} height="100"
                     onClick={() => this.showPreviewImage(text.teamLogo ? text.teamLogo : "")}/>
            </div>
        ),
    }, {
        title: '队长QQ',
        dataIndex: 'leaderQQ',
        align: 'center',
        key: 'leaderQQ',
    }, {
        title: '状态',
        dataIndex: 'state',
        align: 'center',
        key: 'state',
        render: (row, text) => (
            <span>{text.state == "SUCCESS" ? "通过" : text.state == "FAIL" ? "拒绝" : text.state == "REVIEW" ? "审核中" : "审核中"}</span>
        ),
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        align: 'center',
        key: 'createTime',
    }, {
        title: '操作',
        dataIndex: 'details',
        key: 'details',
        align: 'center',
        render: (row, text) => (
            <div>
                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                        onClick={() => this.onGetCertificationInfoEvent(text)}>{text.state == "SUCCESS" ? "重新审核" : "审核"}</Button>

                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                        onClick={() => this.onUpdateCertificationInfoEvent(text)}>{"编辑"}</Button>
            </div>
        ),
    }
    ]
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        ...state.Team,
        ...state.Certification
    }
};

const mapDispatchToProps = ({
    getGameList,
    getTeamCertificationList, addTeamCertifiacation,
    getTeamCertificationInfo,
    verifyCertifiacation, updateTeamCertifiacation

});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Certification));
