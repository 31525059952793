export const operateType = {
    COIN_DEDUCTION: {
        label: '浪币扣除',
        value: 'COIN_DEDUCTION',
    },
    COIN_CONSUME: {
        label: '浪币消费',
        value: 'COIN_CONSUME'
    },
    COIN_RECHARGE: {
        label: '浪币充值',
        value: 'COIN_RECHARGE'
    },
    COIN_REWARD: {
        label: '浪币奖励',
        value: 'COIN_REWARD'
    },
    CASH_OUT_AGREE: {
        label: '同意提现订单',
        value: 'CASH_OUT_AGREE'
    },
    CASH_OUT_DENY: {
        label: '拒绝提现订单',
        value: 'CASH_OUT_DENY'
    },
    VIP_OPEN: {
        label: '开VIP天数',
        value: 'VIP_OPEN'
    },
    USER_UNBIND_QQ: {
        label: '解绑用户QQ',
        value: 'USER_UNBIND_QQ'
    },
    USER_UNBIND_WX: {
        label: '解绑用户微信',
        value: 'USER_UNBIND_WX'
    },
    UPDATE_USER: {
        label: '修改用户信息',
        value: 'UPDATE_USER'
    },
    UPDATE_CREDENTIAL: {
        label: '修改用户认证资料',
        value: 'UPDATE_CREDENTIAL'
    },
    CREATE_USER: {
        label: '创建新用户',
        value: 'CREATE_USER'
    },
    CREATE_PARTNER_TEACHER: {
        label: '创建新导师',
        value: 'CREATE_PARTNER_TEACHER'
    },
    UPDATE_PARTNER_TEACHER: {
        label: '编辑导师',
        value: 'UPDATE_PARTNER_TEACHER'
    },
    UNBAN_PARTNER_TEACHER: {
        label: '解禁导师',
        value: 'UNBAN_PARTNER_TEACHER'
    },
    BAN_PARTNER_TEACHER: {
        label: '封禁导师',
        value: 'BAN_PARTNER_TEACHER'
    },
    CREATE_PARTNER_ORDER: {
        label: '新增订单',
        value: 'CREATE_PARTNER_ORDER'
    },
    TEAM_LIFT_GROUP: {
        label: '战队升降级',
        value: 'TEAM_LIFT_GROUP'
    }

};

