import React from 'react';
import {Form, Input, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddMemberModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values.exhibitionId);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props
        return (
            <Modal
                visible={visible}
                title="添加成员"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form>
                    <FormItem
                        label="请输入成员展示ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('exhibitionId', {
                            rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'},{required: true, message: '请输入成员展示ID'}],
                        })(
                            <Input placeholder="请输入成员展示ID"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        // ...state.viewTraining,
    }
};
const mapDispatchToProps = ({});
AddMemberModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddMemberModel)));
