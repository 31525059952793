import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class LineupFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
  }

  handleSubmit(e) {
    e.preventDefault();
    let _self = this;
    this.props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        console.log(values)
        let {onCommit} = _self.props;
        let {gameType, teamType} = values;
        onCommit && onCommit(gameType, teamType);
      }
    });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div>
          <Form onSubmit={this.handleSubmit} layout="inline">
              <FormItem label="团队类型">
                {getFieldDecorator('teamType', {
                    initialValue: ""
                  }
                )(
                  <Select style={{width: 200}}>
                      <Option value="">全部</Option>
                      <Option value="MANAGEMENT_TEAM">管理团队</Option>
                      <Option value="HOME_TEAM_PLAYER">主队选手</Option>
                      <Option value="YOUTH_PLAYERS">青训选手</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem label="游戏类型">
                {getFieldDecorator('gameType', {
                    initialValue: ""
                  }
                )(
                    <Input style={{width:200}} allowClear/>
                )}
              </FormItem>
              
              <FormItem>
                  <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                      查询
                  </Button>
              </FormItem>
          </Form>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Lineup,
  }
};
const mapDispatchToProps = ({
});
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(LineupFilter)));