import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import {getBrandList} from "../../../modules/brand/action";

const FormItem = Form.Item;
const Option = Select.Option;

class SearchCertificationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onShowTeamIdChange = this.onShowTeamIdChange.bind(this);

    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    onShowTeamIdChange() {
        let {onShowTeamIdChange} = this.props;
        onShowTeamIdChange && onShowTeamIdChange();
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {games, brands} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline" style={{width: '100%'}}>
                <FormItem label="赛事类型">
                    {getFieldDecorator('brandId', {
                            initialValue: brands.data.length > 0 ? brands.data[0].id : '',
                            rules: [{required: true, message: '请填写赛事类型!'}],
                        }
                    )(
                        <Select style={{width: '200px'}}>
                            {brands.data && brands.data.map(item => {
                                return (
                                    <Option
                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="战队展示ID"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                >
                    {getFieldDecorator('teamShowId', {
                        initialValue: "",
                        rules: [{required: true, message: '请输入战队展示ID!'}],
                    })(
                        <Input style={{width: '150px'}} allowClear onChange={this.onShowTeamIdChange()}/>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit">
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,getBrandList
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SearchCertificationForm)));