import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import MedalEditForm from './MedalEditForm';
import MedalFilter from "./MedalFilter";
import SubMedal from './SubMedal';
import { operationTypeValue } from "../../modules/medal/medalEnum";
import { deleteGift } from '../../modules/gift/action';
import { getMedalThemeList, getMedalThemeData, updateMedalThemeData, addMedalThemeData, updateMedalThemeShelf } from '../../modules/medal/action';
import MedalAddForm from "./MedalAddForm";
const {confirm} = Modal;

class MedalDataManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            medalName: '',
            medalType: '',
            showEditMedalThemeModal: false,
            showAddMedalThemeModal: false,
            themeData: {}, //获取一条勋章主题数据
            medalId: '',
            medalThemeType: '',
            showLevelModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getMedalThemeList } = this.props;
        getMedalThemeList({page, size});
    }

    onPageChangeEvent = (current, size) => {
        let { getMedalThemeList } = this.props;
        let { medalName, medalType } = this.state;
        this.setState({
            page: current,
            size
        });
        getMedalThemeList({page: current, size, medalName, medalType});
    }

    onFilterMedalThemeEvent = (medalName, medalType) => {
        const { getMedalThemeList } = this.props;
        const { size } = this.state;
        this.setState({
            medalName,
            medalType,
            page: 1
        });
        getMedalThemeList({page: 1, size, medalName, medalType});
    }

    onOpenEditMedalThemeEvent = (text, type) => {
        const { getMedalThemeData } = this.props;
        let medalEnum = text.medalEnum;

        if (type === operationTypeValue.UPDATE) {
            getMedalThemeData(text.id).then((json) => {
                this.setState({
                    themeData: { ...json, medalEnum, type }
                });
            }).catch((err) => {
                this.setState({
                    themeData: { medalEnum, type }
                });
            });
        } else {
            this.setState({
                themeData: { type }
            });
        }

        this.setState({
            operationType: type,
            showEditMedalThemeModal: true
        });
    }

    onCloseEditMedalThemeEvent = () => {
        this.setState({
            showEditMedalThemeModal: false
        });
    }

    onCloseAddMedalThemeEvent = () => {
        this.setState({
            showAddMedalThemeModal: false
        });
    }

    // 编辑勋章
    onEditMedalThemeEvent = async (params) => {
        const {page, size, themeData} = this.state;
        const {updateMedalThemeData, getMedalThemeList} = this.props;
        try {
            await updateMedalThemeData(themeData.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditMedalThemeModal: false,
            });
            getMedalThemeList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 添加勋章
    onAddMedalThemeEvent = async (params) => {
        const {page, size, themeData} = this.state;
        const {addMedalThemeData, getMedalThemeList} = this.props;
        try {
            await addMedalThemeData(themeData.id, params);
            notification.success({message: '新增成功'});
            this.setState({
                showAddMedalThemeModal: false,
            });
            getMedalThemeList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onAddEvent = async (params,type) => {
        this.setState({
            operationType:type,
            showAddMedalThemeModal: true,
        });
    }

    //勋章主题上下架-二次确认
    onConfirmMedalThemeShelf = (themeId, shelfStatus) => {
        let _this = this;
        let tipText = shelfStatus ? '上架' : '下架';

        confirm({
            content: '确认要' + tipText + '该勋章主题吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onMedalThemeShelf(themeId, shelfStatus, tipText);
            },
            onCancel() {
            }
        });
    }

    //勋章主题上下架
    onMedalThemeShelf = async (themeId, shelfStatus, tipText) => {
        const { updateMedalThemeShelf, getMedalThemeList } = this.props;
        const { page, size } = this.state;

        try {
            await updateMedalThemeShelf(themeId, { shelfStatus });
            notification.success({message: tipText + '成功'});
            getMedalThemeList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onOpenLevelModal = (medalId, medalThemeType, type) => {
        this.setState({
            medalId,
            medalThemeType
        }, () => {
            this.setState({
                operationType: type,
                showLevelModal: true
            });
        });
    }

    onCloseLevelModal = () => {
        this.setState({
            showLevelModal: false,
            operationType: operationTypeValue.UPDATE
        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onAddEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    render() {
        const {
            medalThemeList, loading
        } = this.props;
        const {
            showEditMedalThemeModal,showAddMedalThemeModal, page, size, operationType, themeData, showLevelModal, medalId, medalThemeType
        } = this.state;

        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <MedalFilter dataSource={{}} onCommit={this.onFilterMedalThemeEvent}></MedalFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '勋章主题ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '勋章主题名',
                                    dataIndex: 'medalName',
                                    key: 'medalName',
                                    align: 'center',
                                },
                                {
                                    title: '勋章类型',
                                    dataIndex: 'medalType',
                                    key: 'medalType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.medalType === 'GROWTH' ? '成长勋章' : ''}
                                            {text.medalType === 'SPECIAL' ? '特殊勋章' : ''}
                                            {text.medalType === 'CIRCLE' ? '圈子勋章' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '勋章级数',
                                    dataIndex: 'medalLevel',
                                    key: 'medalLevel',
                                    align: 'center',
                                },
                                {
                                    title: '展示序列',
                                    dataIndex: 'sort',
                                    key: 'sort',
                                    align: 'center',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },

                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditMedalThemeEvent(text, operationTypeValue.UPDATE)}
                                                color="blue">编辑</Button>
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenLevelModal(text.id, text.medalType, operationTypeValue.SELECT)}
                                                color="blue">子勋章列表</Button>
                                            {text.shelfStatus ? <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onConfirmMedalThemeShelf(text.id,false)}
                                                    color="blue">下架</Button> :
                                            <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onConfirmMedalThemeShelf(text.id,true)}>上架</Button>
                                            }
                                        </div>
                                    }
                                },
                            ]
                        }
                        title={this.renderTableTitle}
                        dataSource={medalThemeList.data ? medalThemeList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: medalThemeList ? medalThemeList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                {operationType === operationTypeValue.UPDATE &&
                    <MedalEditForm
                        visible={showEditMedalThemeModal}
                        {...themeData}
                        onSubmitEvent={this.onEditMedalThemeEvent}
                        onCancel={this.onCloseEditMedalThemeEvent}
                    />
                }
                {operationType === operationTypeValue.INCREASE &&
                <MedalAddForm
                    visible={showAddMedalThemeModal}
                    {...themeData}
                    onSubmitEvent={this.onAddMedalThemeEvent}
                    onCancel={this.onCloseAddMedalThemeEvent}
                />
                }
                {operationType === operationTypeValue.SELECT && <SubMedal
                    visible={showLevelModal}
                    medalThemeId={medalId}
                    medalType={medalThemeType}
                    onClose={this.onCloseLevelModal}
                />}
            </div>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.Medal
        }
    }
);

const mapDispatchToProps = {
    getMedalThemeList,
    getMedalThemeData,
    updateMedalThemeData,
    addMedalThemeData,
    updateMedalThemeShelf,
    deleteGift,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(MedalDataManagement)));
