import React, {Component} from "react";
import {Button, Cascader, Col, Form, Input, Row, Select} from "antd";
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};

class TrainingTemplateFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        const {getGameList} = props;
        getGameList();
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                console.log(values)
                let {onCommit} = _self.props;
                let {teamShowId, clubId, teamName, game, stopGame} = values;

                console.log(values)

                onCommit && onCommit(teamShowId, clubId, teamName, game, stopGame);
            }
        });
    }

    onChangeTrainingTemplate = (value) => {
        let {onCommit} = this.props;
        if(value && value.length>0){
            console.log("value", value, value.length)
            console.log("vauew111", value[0], value[1], value[2])
            onCommit && onCommit( value[0], value[1], value[2]);
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {options} = this.props;
        return (
            <div>
                <Form layout="inline">
                    <FormItem
                        label="训练赛模版:"
                    >
                        {getFieldDecorator('address', {
                            rules: [{ message: '请选择模版', pattern: /^\S+$/}],
                            initialValue: '',
                        })(
                            <Cascader
                                options={options}
                                onChange={this.onChangeTrainingTemplate}
                                placeholder="请选择模版"/>
                        )}
                    </FormItem>
                </Form>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingTemplateFilter)));