import createReducer from 'utils/createReducer';

import {
    GET_PLAYERS,
    GET_TRANSFERS,
    GET_HONORS,
    GET_CAREERS
} from './actionTypes';

const defaultState = () => ({
    playerList:[],
    transferList:[],
    honorList:[],
    careerList:[]
});

const onGetPlayerDetailRequest = (state,{payload}) => {
    return {
        ...state,
        playerList:payload,
    }
};

const onGetTransferDetailRequest = (state,{payload}) => {
    return {
        ...state,
        transferList:payload,
    }
};

const onGetHonorDetailRequest = (state,{payload}) => {
    return {
        ...state,
        honorList:payload,
    }
};

const onGetCareerDetailRequest = (state,{payload}) => {
    return {
        ...state,
        careerList:payload,
    }
};

export default createReducer(defaultState, {
    [`${GET_PLAYERS}_SUCCESS`]: onGetPlayerDetailRequest,
    [`${GET_TRANSFERS}_SUCCESS`]: onGetTransferDetailRequest,
    [`${GET_HONORS}_SUCCESS`]: onGetHonorDetailRequest,
    [`${GET_CAREERS}_SUCCESS`]: onGetCareerDetailRequest, 
});
