import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, DatePicker
} from 'antd';
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};
const dateFormat = "YYYY-MM-DD HH:mm:00";


class ShipForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypeList: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            innerJumpType: this.props.innerJumpType,
        };
    }

    componentWillMount(){

    }

    changeType = (value) => {
        this.setState({
            isOuter: value === "OUTER" ? true : false
        });
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent, type, createTime } = this.props;
                let { logisticsCompany, shipmentNumber } = values;
                onSubmitEvent && onSubmitEvent({logisticsCompany, shipmentNumber});
            }
        });
    };

    render() {
        let {
            orderId, userId, logisticsCompany, shipmentNumber
        } = this.props;
        const {getFieldDecorator} = this.props.form;

        return (
            <Form>
                <FormItem
                    label="订单编号"
                    {...formItemLayout}
                >
                    {getFieldDecorator('orderId', {
                        initialValue: orderId,
                    })(
                        <Input disabled={true} />
                    )}
                </FormItem>
                <FormItem
                    label="用户ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('userId', {
                        initialValue: userId,
                    })(
                        <Input disabled={true} />
                    )}
                </FormItem>
                <FormItem
                    label="物流公司"
                    {...formItemLayout}
                >
                    {getFieldDecorator('logisticsCompany', {
                        rules: [{required: true, message: '请输入物流公司名称', pattern: /^\S+$/}],
                        initialValue: logisticsCompany,
                    })(
                        <Input placeholder="请输入物流公司名称"/>
                    )}
                </FormItem>
                <FormItem
                    label="物流单号"
                    {...formItemLayout}
                >
                    {getFieldDecorator('shipmentNumber', {
                        rules: [{required: true, message: '请输入物流单号', pattern: /^\S+$/}],
                        initialValue: shipmentNumber,
                    })(
                        <Input placeholder="请输入物流单号"/>
                    )}
                </FormItem>

                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(ShipForm);
