import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";

const {TextArea} = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 12},
};

const defaultProps = {
  value: {},
  visible: false,
  onClose: () => {
  },
};

class AddLineupModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentWillMount() {
    this.onInitData();
  }

  onInitData = (props = this.props) => {
    const {getGameList} = props;
    getGameList();
  }

  onSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {onSuccess} = this.props;
        onSuccess && onSuccess(values);
      }
    });
  }

  onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    let {visible, value} = this.props;
    return (
      <Modal
        visible={visible}
        title={this.props.title}
        onCancel={this.props.onClose}
        cancelText="取消"
        onOk={this.onSubmit}
        okText="确认"
      >
          <Form>
              <FormItem
                label="团队类型"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('teamType', {
                  rules: [{required: true, message: '请选择团队类型!'}],
                  initialValue: value && value.teamType ? value.teamType : "",
                })(
                  <Select placeholder="请选择团队类型">
                      <Select.Option value="MANAGEMENT_TEAM">管理团队</Select.Option>
                      <Select.Option value="HOME_TEAM_PLAYER">主队选手</Select.Option>
                      <Select.Option value="YOUTH_PLAYERS">青训选手</Select.Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem
                label="游戏类型"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('gameType', {
                  initialValue: value && value.gameType ? value.gameType : "",
                  rules: [{required: true, message: '请输入游戏类型!'}],
                })(
                    <Input placeholder="请输入游戏类型"/>,
                )}
              </FormItem>
              <FormItem
                label="展示顺序"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('sort', {
                  initialValue: value && value.sort ? value.sort : "",
                //   rules: [{required: true, message: '请输入展示顺序!'}],
                  rules: [{required: true, pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                })(
                    <Input placeholder="请输入展示顺序"/>,
                )}
              </FormItem>
          </Form>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Lineup,
  }
};
const mapDispatchToProps = ({
  getGameList,
});
AddLineupModel.defaultProps = defaultProps;
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddLineupModel)));