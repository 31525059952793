import React from 'react';
import {Form, Input, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class QrcodeInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentWillMount() {
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, systemType} = this.props;
        return (
            <Modal
                visible={visible}
                title="app版本下载二维码配置"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="版本下载URL"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('url', {
                            initialValue: "",
                            rules: [{required: true, message: '请填写版本下载URL!'}],
                        })(
                            <Input placeholder="请填写版本下载URL"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="系统类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('systemType', {
                            initialValue: systemType,
                        })(
                            <Input disabled={true}/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="版本号"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('appVersionNum', {
                            initialValue: '',
                        })(
                            <Input placeholder="请填写版本号"/>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Qrcode,
    }
};
const mapDispatchToProps = ({
});
QrcodeInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(QrcodeInfoModel)));
