import api from '../utils/api';


class CircleRequest {


    /**
     * 获取话题列表
     * @param pageNo
     * @param pageSize
     * @param topicName
     * @returns {*}
     */
    static getTopics(page, size, topicName) {
        return api.get('/admin/topic/findByPage', { page, size, topicName });
    }

    /**
     * 修改话题
     * @param params 请求参数
     */
    static updateTopic(id,params) {
        return api.put(`/admin/topic/editTopic`, {id, ...params} );
    }

    /**
     * 置顶话题
     * @param id
     * @returns {*}
     */
    static setTop(id,type) {
        return api.post(`/admin/topic/setTop/${id}`,{type});
    }

    /**
     * 删除圈子
     * @param id
     * @returns {*}
     */
    static deleteTopic(id) {
        return api.delete(`/admin/topic/delete/${id}`);
    }


    /**
     * 增加话题
     * @param {Object} params 请求参数
     */
    static createTopic(params) {
        return api.post('/admin/topic/createTopic', params);
    }

    /**
     * 获取帖子列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
     static getPostList(page, size, exhibitionId, username, keyword, topicName, shield, startTime, endTime) {
        return api.get('/admin/topic/dynamic', { page, size, exhibitionId, username, keyword, topicName, shield, startTime, endTime });
    }

    /**
     * 获取表情包帖子列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
     static getEmojiPostList(page, size, userId, username, playerName, block, publishTimeStart, publishTimeEnd) {
        return api.get('/admin/emoji', { page, size, userId, username, playerName, block, publishTimeStart, publishTimeEnd });
    }

    /**
     * 获取帖子详情信息
     * @param id
     * @returns {*}
     */
     static getPostDetail(id) {
        return api.get(`/admin/topic/getPostDetail/${id}`);
    }

    /**
     * 获取表情包帖子详情信息
     * @param id
     * @returns {*}
     */
     static getEmojiPostDetail(id) {
        return api.get(`/admin/emoji/${id}`);
    }

    /**
     * 编辑帖子
     * @param id
     * @param authorId
     * @param content
     * @param imageUrlList
     * @param topicId
     * @returns {*}
     */
     static updatePost(id, param) {
        return api.put(`/admin/topic/postDynamic/edit/${id}`, param);
    }

    /**
     * 编辑帖子点赞
     * @param id
     * @param authorId
     * @param content
     * @param imageUrlList
     * @param topicId
     * @returns {*}
     */
    static updatePostLike(param) {
        return api.put(`/admin/topic/likeNum`, param);
    }

    /**
     * 编辑帖子热度
     * @param id
     * @param authorId
     * @param content
     * @param imageUrlList
     * @param topicId
     * @returns {*}
     */
    static updatePostHot(param) {
        return api.put(`/admin/topic/hotNum`, param);
    }

    static importPostComment(param) {
        return api.post(`/admin/vest/comment`, param);
    }

    /**
     * 马甲发帖
     * @param id
     * @param authorId
     * @param content
     * @param imageUrlList
     * @param topicId
     * @returns {*}
     */
    static majiaCreatePost(param) {
        return api.post(`/admin/vest/dynamic`, param);
    }

    /**
     * 管理帖子
     * @param id
     * @param type [ TOP, CANCEL_TOP, SHIELD, CANCEL_SHIELD, BOTTOM, CANCEL_BOTTOM, QUINTESSENCE, CANCEL_QUINTESSENCE ]
     * @returns {*}
     */
     static updatePostSetting(id, param) {
        return api.post(`/admin/topic/postDynamic/${id}`, param);
    }


    /**
     * 管理表情包帖子
     * @param id
     * @param type [ block屏蔽表情包帖子 unblock解除屏蔽表情包帖子 edit编辑表情包帖子 lock锁定表情包帖子 unlock解除锁定表情包帖子 setAsTop置顶表情包帖子 unsetAsTop取消置顶表情包帖子]
     * @returns {*}
     */
     static updateEmojiPostSetting(id, type, param) {
        return api.put(`/admin/emoji/${id}/${type}`, param);
    }

    /**
     * 编辑表情包帖子
     * @param emojiId
     * @param emojiUrl
     * @returns {*}
     */
     static updateEmojiPost(id, param) {
        return api.put(`/admin/emoji/${id}/edit`, param);
    }

    /**
     * 获取点赞配置
     * @returns {*}
     */
    static getPostLikeConfig() {
        return api.get(`/admin/configuration/AUTO_THUMB_NUM`);
    }
    /**
     * 修改点赞配置
     * @returns {*}
     */
    static updatePostLikeConfig(params) {
        return api.put(`/admin/configuration/AUTO_THUMB_NUM`,params);
    }
}

export default CircleRequest;
