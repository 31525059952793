import createReducer from 'utils/createReducer';

import {
    GET_MSG_NOTICES,GET_MSG_NOTICE
} from './actionTypes';

const defaultState = () => ({
    msgNotices: [],
    msgNoticesTotal: 0,
    msgNoticesLoading: false,
    msgNoticeInfo: {},
    adminRoles: [], // 用户所拥有的角色
});


const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        msgNotices:payload

    }

};


const ongetAppNoticesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        msgNoticeInfo: payload,
    }

};


export default createReducer(defaultState, {
    [`${GET_MSG_NOTICES}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_MSG_NOTICE}_SUCCESS`]: ongetAppNoticesuccess,
});
