import React from 'react';
import {Button, Card, Form, Input, Modal, Table} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    addTeamCertifiacation,
    getCertificationTeamMembers,
    getTeamCertificationInfo,
} from "../../../modules/certification/action";
import {getGameList} from "../../../modules/team/action";
import {getBrandList} from "../../../modules/brand/action";

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class UpdateCertificationModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateCertificationData: new Map(),
            dataForm: [],
            isShowDataForm: false,
            loading: false
        }
    }


    componentWillMount() {
        const {value} = this.props;
        this.onInitData(value);
        this.setState({
            loading: true
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.certificationInfo !== this.props.certificationInfo) {
            this.setState({
                loading: false
            })
        }
    }

    onInitData = async (value) => {
        this.getCertificationTeamMembers(value);
    }


    onSubmit = async () => {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess, certificationInfo} = _self.props;
                const {updateCertificationData, brandId} = _self.state;
                let params = {};
                params["teamId"] = certificationInfo ? certificationInfo.teamId : null;
                params["brandId"] = brandId;
                params["items"] = [];
                updateCertificationData.forEach(function (value, key, map) {
                    params["items"].push(value);
                })
                onSuccess && onSuccess(params);
            }
        });
    }

    changeShowTeamId = () => {
        this.setState({
            dataForm: [],
            isShowDataForm: false,
            updateCertificationData: new Map(),
            brandId: null
        })
    }

    getCertificationTeamMembers = async (values) => {
        const {getTeamCertificationInfo} = this.props;
        await getTeamCertificationInfo(values.brandId, values.teamId);
        const {certificationInfo} = this.props;
        const {updateCertificationData} = this.state
        if (certificationInfo && certificationInfo.items.length>0) {
            certificationInfo.items.map(function (value, index, array) {
                updateCertificationData.set(value.gamerId, {
                    gamerId: value.gamerId,
                    gamerName: value.gamerName,
                    jobType: value.memberType,
                    playerId: value.thirdGameId,
                    playerName: value.inGameName,
                    qq: value.qqNum,
                    imageUrl: value.certificationImg,
                })
                return null;
            })
        }
        this.setState({
            updateCertificationData: updateCertificationData,
            brandId: values.brandId,
        })
        await this.onInitCertificationData();
    }

    onInitCertificationData = async () => {
        const {certificationInfo} = this.props;
        this.setState({
            dataForm: certificationInfo.items,
            isShowDataForm: true
        })
    }

    onChangeTable = (key, gamerId, value) => {
        const {updateCertificationData} = this.state;
        let obj = updateCertificationData.get(gamerId);
        obj[key] = value;
        updateCertificationData.set(gamerId, obj);
        this.setState({
            updateCertificationData: updateCertificationData
        })
        console.log(updateCertificationData)
    }

    render() {
        const {visible} = this.props;
        const {loading, dataForm, isShowDataForm} = this.state;
        return (
            <Modal
                width={'80%'}
                visible={visible}
                title={"添加参赛认证"}
                onCancel={this.props.onClose}
                footer={<div><Button onClick={this.props.onClose}>取消</Button> <Button disabled={!isShowDataForm}
                                                                                      onClick={this.onSubmit}>确定</Button>
                </div>}
            >
                <Card style={{marginBottom: 30}}>
                    <Table
                        dataSource={isShowDataForm ? (dataForm.length > 0 ? dataForm : []) : []}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={false}
                        loading={loading}
                    />
                </Card>
            </Modal>

        );
    }

    getTableColumns = () => {
        const {getFieldDecorator} = this.props.form;
        return ([{
            title: '玩家ID',
            dataIndex: 'showGamerId',
            align: 'center',
            key: 'showGamerId',
            render: (row, text) => (
                <span>{text.gamerShowId ? text.gamerShowId : text.gamerId}</span>
            ),
        }, {
            title: '玩家名称',
            dataIndex: 'gamerName',
            align: 'center',
            key: 'gamerName',
        },
                {
                title: '参赛昵称',
                dataIndex: 'playerName',
                align: 'center',
                key: 'inGameName',
                render: (row, text) => {
                    return (
                        <div>
                            {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('playerName' + text.gamerId, {
                                    initialValue: text.inGameName,
                                    rules: [
                                        {
                                            required: true,
                                            message: `参赛昵称不能为空.`,
                                        },
                                    ],
                                })(<Input
                                    onChange={(e) => this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>)}
                            </Form.Item>
                        </div>
                    )
                },
            },
            {
                title: '成员类型',
                dataIndex: 'jobType',
                align: 'center',
                key: 'memberType',
                render: (row, text) => (
                    <span>{text.memberType == "LEADER" ? "队长" : text.memberType == "FORMAL" ? "正式成员" : text.memberType == "ALTERNATE" ? "替补成员" : ""}</span>
                ),
            },
                {
                title: '第三方游戏id',
                dataIndex: 'playerId',
                align: 'center',
                key: 'thirdGameId',
                // render: (row, text) => (
                //     <input required onChange={(e) => this.onChangeTable('playerId', text.gamerId, e.currentTarget.value)}/>
                // ),
                render: (row, text) => {
                    return (
                        <div>
                            {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('playerId' + text.gamerId, {
                                    initialValue: text.thirdGameId,
                                    rules: [
                                        {
                                            required: true,
                                            message: `第三方游戏id不能为空.`,
                                        },
                                    ],
                                })(<Input
                                    onChange={(e) => this.onChangeTable('playerId', text.gamerId, e.currentTarget.value)}/>)}
                            </Form.Item>
                        </div>
                    )
                },
            }, {
                title: '参赛QQ号',
                dataIndex: 'qq',
                align: 'center',
                key: 'qqNum',
                // render: (row, text) => (
                //     <input required onChange={(e) => this.onChangeTable('qq', text.gamerId, e.currentTarget.value)}/>
                // ),
                render: (row, text) => {
                    return (
                        <div>
                            {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('qq' + text.gamerId, {
                                    initialValue: text.qqNum,
                                    rules: [
                                        {
                                            required: true,
                                            message: `参赛QQ不能为空.`,
                                        },
                                    ],
                                })(<Input
                                    onChange={(e) => this.onChangeTable('qq', text.gamerId, e.currentTarget.value)}/>)}
                            </Form.Item>
                        </div>
                    )
                },
            }
            // , {
            //     title: '游戏截图',
            //     dataIndex: 'certificationImg',
            //     align: 'center',
            //     key: 'certificationImg',
            //     render: (row, text) => {
            //         return (
            //             <div>
            //                 {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
            //                 <Form.Item
            //                     // eslint-disable-next-line react/jsx-props-no-spreading
            //                     style={{margin: 0}}
            //                 >
            //                     {getFieldDecorator('certificationImg' + text.gamerId, {
            //                         initialValue: text.certificationImg ? text.certificationImg : "",
            //                     })(
            //                         <UpLoad
            //                             double={false}
            //                             width={690}
            //                             height={300}
            //                         />
            //                     )}
            //                 </Form.Item>
            //             </div>
            //         )
            //     },
            // }
        ])
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Brand,
        ...state.Certification,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTeamCertificationInfo,
    getCertificationTeamMembers, addTeamCertifiacation,
    getGameList,
    getBrandList,
});
UpdateCertificationModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdateCertificationModel)));
