import createReducer from 'utils/createReducer';
import {
  GET_ACCOUNT,GET_ACCOUNT_LOGS,GET_SPEND_LOGS
} from './actionTypes';

const defaultState = () => ({
  accountInfo: {},
  accountLogs:[],
  accountLogsLoading:false,
  accountLogsTotal:0,
  spendLogs:[],
  spendLogsLoading:false,
  spendLogsTotal:0
});

const onGetAccountSuccess = (state, { payload }) => ({
  ...state,
  accountInfo: payload
});

const onGetAccountLogsRequest = (state, { payload }) => ({
  ...state,
  accountLogsLoading:true
});

const onGetAccountLogsSuccess = (state, { payload: {data,total} }) => ({
  ...state,
  accountLogsLoading:false,
  accountLogs:data,
  accountLogsTotal:total
});

const onGetAccountLogsError = (state, { payload }) => ({
  ...state,
  accountLogsLoading:false,
});

const onGetSpendLogsRequest = (state, { payload }) => ({
  ...state,
  spendLogsLoading:true
});

const onGetSpendLogsSuccess = (state, { payload: {data,total} }) => ({
  ...state,
  spendLogsLoading:false,
  spendLogs:data,
  spendLogsTotal:total
});

const onGetSpendLogsError = (state, { payload }) => ({
  ...state,
  spendLogsLoading:false,
});

export default createReducer(defaultState, {
  [`${GET_ACCOUNT}_SUCCESS`]: onGetAccountSuccess,
  [`${GET_ACCOUNT_LOGS}_REQUEST`]: onGetAccountLogsRequest,
  [`${GET_ACCOUNT_LOGS}_SUCCESS`]: onGetAccountLogsSuccess,
  [`${GET_ACCOUNT_LOGS}_ERROR`]: onGetAccountLogsError,
  [`${GET_SPEND_LOGS}_REQUEST`]: onGetSpendLogsRequest,
  [`${GET_SPEND_LOGS}_SUCCESS`]: onGetSpendLogsSuccess,
  [`${GET_SPEND_LOGS}_ERROR`]: onGetSpendLogsError,
});
