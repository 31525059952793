import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import SearchTeamByUserIdFilter from "./SearchTeamByUserIdFilter";

const FormItem = Form.Item;
const Option = Select.Option;

class TeamFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentWillMount() {
    this.onInitData();
  }

  onInitData = (props = this.props) => {
    const {getGameList} = props;
    getGameList();
  }

  handleSubmit(e) {
    e.preventDefault();
    let _self = this;
    this.props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        console.log(values)
        let {onCommit} = _self.props;
        let {teamShowId, clubId, aliasName,teamName, game, stopGame} = values;

        console.log(values)

        onCommit && onCommit(teamShowId, clubId, aliasName,teamName, game, stopGame);
      }
    });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {games} = this.props;
    return (
      <div>
          <Form onSubmit={this.handleSubmit} layout="inline">

              <FormItem label="战队展示ID">
                {getFieldDecorator('teamShowId', {
                    rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                  }
                )(
                  <Input style={{width: '150px'}} allowClear/>
                )}
              </FormItem>
            {/*<FormItem label="俱乐部ID">*/}
            {/*{getFieldDecorator('clubId', {*/}
            {/*rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],*/}
            {/*}*/}
            {/*)(*/}
            {/*<Input style={{width: '150px'}} allowClear/>*/}
            {/*)}*/}
            {/*</FormItem>*/}
              <FormItem label="战队名称">
                {getFieldDecorator('aliasName', {}
                )(
                  <Input style={{width: '150px'}} allowClear/>
                )}
              </FormItem>
              <FormItem label="战队标签">
                {getFieldDecorator('teamName', {}
                )(
                  <Input style={{width: '150px'}} allowClear/>
                )}
              </FormItem>
              <FormItem label="游戏类型">
                {getFieldDecorator('game', {
                    initialValue: ""
                  }
                )(
                  <Select style={{width: 120}}>
                      <Option value="">全部</Option>
                    {games.map((item) => <Option value={item.code}
                                                 key={item.code}>{item.gameName}</Option>)}
                  </Select>,
                )}
              </FormItem>
              <FormItem label="是否禁赛">
                {getFieldDecorator('stopGame', {
                    initialValue: ""
                  }
                )(
                  <Select style={{width: 120}}>
                      <Option value="">全部</Option>
                      <Option value="1">禁赛</Option>
                      <Option value="0">正常</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem>
                  <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                      查询
                  </Button>
              </FormItem>
          </Form>
          <SearchTeamByUserIdFilter/>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Team,
  }
};
const mapDispatchToProps = ({
  getGameList,
});
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TeamFilter)));