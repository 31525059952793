import React, { Component } from 'react';
import {
    Button, Table, Card, notification,
} from 'antd';
import { getComment,shieldComment,updateCommentStatus,getDynamic,updateDynamicStatus,deleteDynamic} from "../../modules/dynamic/action";
import {getComments} from "../../modules/comment/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";

class RepostDetailForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
        };
    }
    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getComment,dynamicId,getDynamic,userId,showType,getComments} = this.props;
        console.log(showType)

        let commentType = "dynamic";
        if(showType==="评论内容违规"){
            getComments(pageNo, pageSize,"",userId,"","","")
        }else {
            getDynamic(pageNo, pageSize,"",userId,"","","");
        }

    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getComment,dynamicId,getDynamic,userId,showType,getComments} = this.props;
        console.log(showType)
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        if (showType==="评论内容违规"){
            getComments(current, pageSize,"",userId,"","","")
        }else {
            getDynamic(current, pageSize,"",userId,"","","");
        }

    }

    //删除动态的方法
    onDeleteDynamicEvent = async (adminId) => {
        const { deleteDynamic, getComment,dynamicId ,userId,getDynamic} = this.props;
        const { page, size } = this.state;
        console.log(adminId)
        try {
            await deleteDynamic(adminId);
            notification.success({ message: '屏蔽成功' });
            let commentType = "dynamic";

            getDynamic(page, size,"",userId,"","","");
        } catch (e) {
            console.log(e);
        }
    };

    //恢复动态的方法
    onRestoreDynamicEvent = async (adminId) => {
        const { updateDynamicStatus, getComment,dynamicId,userId,getDynamic } = this.props;
        const { page, size } = this.state;
        try {
            await updateDynamicStatus(adminId);
            notification.success({ message: '恢复成功' });
            let commentType = "dynamic";

            getDynamic(page, size,"",userId,"","","");
        } catch (e) {
            console.log(e);
        }
    };

    //删除评论的方法
    onDeleteCommentEvent = async (adminId) => {
        const { shieldComment, getComments ,userId} = this.props;
        const { pageNo, pageSize } = this.state;
        console.log(adminId)
        try {
            await shieldComment(adminId);
            notification.success({ message: '屏蔽成功' });

            getComments(pageNo, pageSize,"",userId,"","","");
        } catch (e) {
            console.log(e);
        }
    };

    //恢复评论的方法
    onCommentEvent = async (adminId) => {
        const { updateCommentStatus, getComments ,userId} = this.props;
        const { pageNo, pageSize } = this.state;
        try {
            await updateCommentStatus(adminId);
            notification.success({ message: '恢复成功' });

            getComments(pageNo, pageSize,"",userId,"","","");
        } catch (e) {
            console.log(e);
        }
    };

    render() {


        console.log(this.props)
        const {
            dynamics, loading,showType,comments
        } = this.props;
        const {pageSize,pageNo} = this.state;

        return (
            <Card>
                {showType ==="评论内容违规" ? <Table
                    columns={
                        [

                            {
                                title: '内容',
                                dataIndex: 'content',
                                key: 'content',
                                align: 'center',
                                width: '50%'
                            },
                            {
                                title: '发布时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                                width: '30%'
                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                width: '20%',
                                render: (id, text) => {

                                    console.log(text.commentStatus)
                                    console.log(text)
                                    return(<div>
                                        {text.commentStatus === "0" ? <Button type="danger" onClick={() =>  this.onDeleteCommentEvent(text.id)}>屏蔽</Button> : <Button type="primary" onClick={() => this.onCommentEvent(text.id)}>恢复</Button>}

                                    </div>)

                                },
                            },
                        ]
                    }
                    dataSource={comments.data ? comments.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: comments ? comments.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                /> : <Table

                    columns={
                        [

                            {
                                title: '内容',
                                dataIndex: 'dynamicContent',
                                key: 'dynamicContent',
                                align: 'center',
                                width: '50%'

                            },
                            {
                                title: '发布时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                                width: '30%'

                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                width: '20%',

                                render: (id, text) => {

                                    console.log(text.commentStatus)
                                    console.log(text)
                                    return(<div>
                                        {text.dynamicStatus === "0" ? <Button type="danger" onClick={() =>  this.onDeleteDynamicEvent(text.id)}>屏蔽</Button> : <Button type="primary" onClick={() => this.onRestoreDynamicEvent(text.id)}>恢复</Button>}

                                    </div>)

                                },
                            },
                        ]
                    }
                    dataSource={dynamics.data ? dynamics.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: dynamics ? dynamics.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />}

            </Card>
        );
    }
}

const mapStateToProps = (state => {
        console.log(state.viewDynamic)
        return {
            ...state.viewDynamic,...state.comments
        }

    }

);

const mapDispatchToProps = {
    getComment,shieldComment,updateCommentStatus,getDynamic,deleteDynamic,updateDynamicStatus,getComments
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(RepostDetailForm)));
