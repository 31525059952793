import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, notification, Popconfirm, Popover, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../modules/team/action";
import PartnerTrainingTeacherModel from "./components/PartnerTrainingTeacherModel";
import PartnerTrainingTeacherFilter from "./components/PartnerTrainingTeacherFilter";
import {getBrand} from "../../modules/training/action";
import buttonType from "../user-management/buttonType";
import {genderType, stateType} from '../../modules/partner-training/enums';

import {
    createPartnerTeacher,
    deletePartnerTeacher,
    getPartnerTeacher,
    queryPartnerTeachers, updatePartnerTeacher,
    updatePartnerTeacherStatus
} from "../../modules/partner-training/action";
import BanPartnerTeacherModel from "./components/BanPartnerTeacherModel";

const {confirm} = Modal;

class PartnerTrainingTeacherManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            operationType: operationTypeValue.INCREASE,
            isShowPartnerTrainingTeacherModel: false,
            isShowBanPartnerTeacherModel: false,
            seletionValue: null,
            page: 1,
            size: 10,
            exhibitionId: null,
            username: null,
            telephone: null,
            state: null,
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.partnerTeachers !== this.props.partnerTeachers) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {queryPartnerTeachers, getGameList} = this.props;
        const {page, size, exhibitionId, username, telephone, state} = this.state;
        this.setState({
            loading: true
        })
        queryPartnerTeachers({page, size, exhibitionId, username, telephone, state});
        getGameList();
    }

    onPageChangeEvent = (current, pageSize) => {
        const {queryPartnerTeachers} = this.props;
        queryPartnerTeachers({
            page: current, size: pageSize,
            exhibitionId: this.state.exhibitionId ? this.state.exhibitionId : null,
            username: this.state.username ? this.state.username : null,
            telephone: this.state.telephone ? this.state.telephone : null,
            state: this.state.state ? this.state.state : null
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowPartnerTrainingTeacherModel: true,
            seletionValue: seletionValue,
        });
    }


    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreatePartnerTeacherEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdatePartnerTeacherEvent(values);
                break;
            default:
        }
    }


    onCreatePartnerTeacherEvent = async (values) => {
        const {isShowPartnerTrainingTeacherModel} = this.state;
        const {createPartnerTeacher} = this.props;
        await createPartnerTeacher(values);
        await this.onGetTableData();
        message.success('编辑成功');
        this.setState({isShowPartnerTrainingTeacherModel: !isShowPartnerTrainingTeacherModel});
    }

    onUpdatePartnerTeacherEvent = async (values) => {
        const {seletionValue, isShowPartnerTrainingTeacherModel} = this.state;
        const {updatePartnerTeacher} = this.props;
        await updatePartnerTeacher(seletionValue.id, values);
        await this.onGetTableData();
        message.success('修改成功');
        this.setState({isShowPartnerTrainingTeacherModel: !isShowPartnerTrainingTeacherModel});
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterBrandEvent = async (exhibitionId, username, telephone, state) => {
        const {size} = this.state;
        const {queryPartnerTeachers} = this.props;
        this.setState({
            exhibitionId: exhibitionId ? exhibitionId : null,
            username: username ? username : null,
            telephone: telephone ? telephone : null,
            state: state ? state : null,
            page: 1,
            loading: true
        })
        await queryPartnerTeachers({
            page: 1, size: size,
            exhibitionId: exhibitionId ? exhibitionId : null,
            username: username ? username : null,
            telephone: telephone ? telephone : null,
            state: state ? state : null,
        });
    }

    render() {
        const {
            partnerTeachers, games
        } = this.props;
        const {
            operationType, isShowPartnerTrainingTeacherModel, seletionValue, page, size, loading, isShowBanPartnerTeacherModel, currentUserName
        } = this.state;
        const defaultValue = operationType === operationTypeValue.UPDATE && seletionValue;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PartnerTrainingTeacherFilter dataSource={{}} onCommit={this.onFilterBrandEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={partnerTeachers ? partnerTeachers.data : []}
                        title={this.renderTableTitle}
                        columns={this.getTableColumns(games)}
                        bordered
                        pagination={{
                            total: partnerTeachers.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowPartnerTrainingTeacherModel ? <PartnerTrainingTeacherModel
                        value={defaultValue}
                        operationType={operationType}
                        onSuccess={this.onFormSubmit}
                        visible={isShowPartnerTrainingTeacherModel}
                        onClose={() => {
                            this.setState({
                                isShowPartnerTrainingTeacherModel: false,
                            });
                        }}
                    /> : null}

                    {isShowBanPartnerTeacherModel ? <BanPartnerTeacherModel
                        onSuccess={this.onBanPartnerTeacher}
                        visible={isShowBanPartnerTeacherModel}
                        userName={currentUserName}
                        onClose={() => {
                            this.setState({
                                isShowBanPartnerTeacherModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>
        )
    }

    onGetPartnerTrainingTeacherEvent = async (value, type) => {
        const {isShowPartnerTrainingTeacherModel} = this.state;
        this.setState({
            operationType: type,
            seletionValue: value,
            isShowPartnerTrainingTeacherModel: !isShowPartnerTrainingTeacherModel,
        });
    }

    /**
     * 封禁用户事件监听
     * @param userId
     */
    onBanPartnerTeacher = async (params) => {
        console.log(params)
        params["state"] = stateType.BANNED.value
        const {updatePartnerTeacherStatus} = this.props;
        const {currentUserId, isShowBanPartnerTeacherModel} = this.state;
        console.log(params)
        await updatePartnerTeacherStatus(currentUserId, params);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({
            isShowBanPartnerTeacherModel: !isShowBanPartnerTeacherModel,
        })
        this.onGetTableData()
    }

    onBanPartnerTeacherStatusEvent = async (id, username) => {
        const {isShowBanPartnerTeacherModel} = this.state;
        this.setState({
            currentUserId: id,
            currentUserName: username,
            isShowBanPartnerTeacherModel: !isShowBanPartnerTeacherModel,
        });
    }

    confirmUnBanPartnerTeacher = (id) => {
        let _this = this;
        confirm({
            content: '确认解除封禁吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUnBanPartnerTeacher(id)
            },
            onCancel() {
            },

        });
    }
    /**
     * 解封用户事件监听
     * @param userId
     */
    onUnBanPartnerTeacher = async (userId) => {
        const {updatePartnerTeacherStatus} = this.props;
        let params = {}
        params["state"] = stateType.NORMAL.value
        await updatePartnerTeacherStatus(userId, params);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.onGetTableData()
    }

    getTableColumns = (games, indexVisible) => [
        {
            title: '导师ID',
            dataIndex: 'exhibitionId',
            key: 'exhibitionId',
            align: 'center',
        },
        {
            title: '导师名',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: '头像',
            dataIndex: 'imageUrl',
            align: 'center',
            key: 'imageUrl',
            render: (text) => (
                <img src={text ? text + '?x-oss-process=image/resize,w_32' : '/avatar.png'} alt={""}
                     style={{width: '2rem', height: '2rem', borderRadius: '50%'}}/>
            ),
        },
        {
            title: '手机号码',
            dataIndex: 'telephone',
            align: 'center',
            key: 'telephone',
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            key: 'gender',
            render: (text) => (
                text ? genderType[text].label : null
            )
        },
        {
            title: '游戏名称',
            dataIndex: 'game',
            align: 'center',
            key: 'game',
            render: (row, text) => (
                <span>{games.filter((item) => item.code === text.game).length > 0 ? games.filter((item) => item.code === text.game)[0].gameName : ""}</span>
            ),
        }, {
            title: '游戏段位',
            dataIndex: 'position',
            align: 'center',
            key: 'position',
        }, {
            title: 'KD实力',
            dataIndex: 'kda',
            align: 'center',
            key: 'kda',
        }, {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            render: (text) => (
                text ? stateType[text].label : null
            )
        },
        {
            title: '解禁时间',
            dataIndex: 'releaseTime',
            key: 'releaseTime',
            align: 'center',
        },
        {
            title: '封禁剩余天数',
            dataIndex: 'days',
            key: 'days',
            align: 'center',
            render: (text) => (
                text == -1 ? "永久" : `${text}天`
            )
        },
        {
            title: '封禁备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (remark, record) => {
                return (
                    remark ? <div>
                        <Popover content={remark}>
                            <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}>查看</Button>
                        </Popover>
                    </div> : null
                )
            }
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (row, text) => {
                return (
                    <div>
                        {

                            text.state === stateType.BANNED.value ?
                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                        onClick={() => this.confirmUnBanPartnerTeacher(text.id)}
                                        color="blue">{buttonType.UN_BAN_USER.label}</Button> :
                                text.state === stateType.NORMAL.value ?
                                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                            onClick={() => this.onBanPartnerTeacherStatusEvent(text.id, text.username)}>{buttonType.BAN_USER.label}</Button> : null
                        }

                        &nbsp;
                        <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                onClick={() => this.onGetPartnerTrainingTeacherEvent(text, operationTypeValue.UPDATE)}>编辑</Button>
                    </div>
                )
            },
        }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
    }
};

const mapDispatchToProps = ({
    createPartnerTeacher,
    updatePartnerTeacher,
    deletePartnerTeacher,
    updatePartnerTeacherStatus,
    getPartnerTeacher,
    queryPartnerTeachers,
    getGameList,
    getBrand
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(PartnerTrainingTeacherManagement));
