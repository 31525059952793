import createAsyncAction from 'utils/createAsyncAction';
import AppStartRequest from '../../request/AppStartRequest' ;
import {
    GET_START_IMAGE,UPDATE_START_IMAGE,ADD_START_IMAGE,DELETE_START_IMAGE

} from './actionTypes'

const getAppStartImage = () => (
    createAsyncAction(GET_START_IMAGE,() => (AppStartRequest.getAppStartImage()))
);
const addStartImage = (configurationEnum,imageUrl) => (
    createAsyncAction(ADD_START_IMAGE,() => (AppStartRequest.addStartImage(configurationEnum,imageUrl)))
);
const delAppStartImage = (id) => (
    createAsyncAction(DELETE_START_IMAGE,() => (AppStartRequest.delAppStartImage(id)))
);
const updateStartImage = (configurationEnum,imageUrl) => (
    createAsyncAction(UPDATE_START_IMAGE,() => (AppStartRequest.updateStartImage(configurationEnum,imageUrl)))
);


export {
    getAppStartImage,addStartImage,delAppStartImage,updateStartImage
}
