import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification
} from 'antd';
import UpLoad from "../../components/upload";
import api from "../../utils/api";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 17},
};


class BannerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypeList: [],
            jumpDescription: '',
            isOuter: this.props.isOuter || false,
            jumpOutside: this.props.jumpOutside || false,
            innerJumpType: this.props.innerJumpType || '',
        };
    }

    componentWillMount(){
        this.requestJumpType().then(json => {
            if(json && json.length > 0) {
                this.setState({
                    innerJumpTypeList: json
                }, this.changeDescription);
            } else {
                this.setState({
                    innerJumpTypeList: [],
                    jumpDescription: ''
                });
            }
        }).catch(err => {
            this.setState({
                innerJumpTypeList: [],
                jumpDescription: ''
            });
        });
    }

    requestJumpType = () => {
        return api.get(`/admins/banners/inner_jump_type`);
    }

    onChangeJumpType = (value) => {
        this.setState({
            isOuter: value === 'OUTER' ? true : false
        });
    }

    onChangeJumpOutside = (value) => {
        this.setState({
            jumpOutside: value
        });
    }
    
    changeDescription = (value) => {
        let { innerJumpTypeList, innerJumpType } = this.state;
        value = value || (innerJumpType || innerJumpTypeList[0].value);
        let des = "";
        innerJumpTypeList.map((item) => {
            if (item.value === value) {
                des = item.description;
            }
        });
        this.setState({
            jumpDescription: des,
            innerJumpType: value
        });
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if(!err) {
                let {onSubmitEvent} = this.props;
                if(values && values.imageUrl){
                    values.imageUrl = values.imageUrl.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() {
        let {
            imageUrl,
            title,
            description,
            outerUrl,
            enable,
            jumpType,
            attach,
            ordinal,
            pageType
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        const {TextArea} = Input;
        let { isOuter, jumpOutside, innerJumpTypeList, innerJumpType, jumpDescription } = this.state;
        return (
            <Form>
                <FormItem
                    label="标题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('title', {
                        rules: [{required: true, message: '请输入标题', pattern: /^\S+$/}],
                        initialValue: title,
                    })(
                        <Input placeholder="请输入标题"/>,
                    )}
                </FormItem>
                <FormItem
                    label="图片(690*240)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrl', {
                        rules: [{required: true, message: '请选择图片'}],
                        initialValue: imageUrl ? imageUrl+'?x-oss-process=image/resize,w_86' : '',
                    })(
                        (<UpLoad
                            double={false}
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={501}
                        />),
                    )}
                </FormItem>
                <FormItem
                    label="序号"
                    {...formItemLayout}>
                    {getFieldDecorator('ordinal', {
                        rules: [{required: true, message: '请选择序号', pattern: /^\S+$/}],
                        initialValue: ordinal,
                    })(
                        <Select style={{width: '150px'}}>
                            <Option key={1} value='1'>1</Option>
                            <Option key={2} value='2'>2</Option>
                            <Option key={3} value='3'>3</Option>
                            <Option key={4} value='4'>4</Option>
                            <Option key={5} value='5'>5</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                label="所属页面"
                {...formItemLayout}>
                    {getFieldDecorator('pageType', {
                        rules: [{required: true, message: '请选择所属页面', pattern: /^\S+$/}],
                        initialValue: pageType,
                    })(
                        <Select style={{width: '150px'}}>
                            <Option key={1} value="INDEX">首页</Option>
                            <Option key={2} value="OFFICIAL_ACTIVITY">官方活动</Option>
                        </Select>
                    )}
                </FormItem>
                {/* <FormItem label ="说明："{...formItemLayout} >
                    若选择圈子页面则只显示序号最前的第一张图片
                </FormItem> */}
                <FormItem
                    label="链接类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('jumpType', {
                        rules: [{required: true, message: '请选择链接类型', pattern: /^\S+$/}],
                        initialValue: jumpType || 'INNER'
                    })(
                        (
                            <Select style={{width: '150px'}} onChange={this.onChangeJumpType}>
                                <Option value='INNER'>内链</Option>
                                <Option value='OUTER'>外链</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem
                    label="外链链接"
                    {...formItemLayout}
                >
                    {getFieldDecorator('outerUrl', {
                        rules: [{required: isOuter, message: '请输入外链'}],
                        initialValue: outerUrl,
                    })(
                        <Input disabled={!isOuter} placeholder="请输入外链"/>,
                    )}
                </FormItem>
                <FormItem
                    label="外链跳外部"
                    {...formItemLayout}
                >
                    {getFieldDecorator('jumpOutside', {
                        rules: [{required: isOuter, message: '请选择是否外链跳外部'}],
                        initialValue: jumpOutside,
                    })(
                        <Select style={{width: '150px'}} onChange={this.onChangeJumpOutside} disabled={!isOuter}>
                            <Option value={true}>是</Option>
                            <Option value={false}>否</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="内链跳转类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('innerJumpType', {
                        rules: [{required: !isOuter, message: '请选择内链跳转类型'}],
                        initialValue: innerJumpType
                    })(
                        (
                            <Select style={{width: '300px'}} disabled={isOuter} onChange={this.changeDescription}>
                                {innerJumpTypeList.length > 0 && innerJumpTypeList.map((item, index) => {
                                    return (
                                        <Option key={item.name} value={item.value}>{item.name}</Option>
                                    )
                                })
                                }
                            </Select>
                        )
                    )}
                </FormItem>
                <FormItem
                    label="说明"
                    {...formItemLayout}>
                    {jumpDescription}
                </FormItem>
                <FormItem
                    label="Attach"
                    {...formItemLayout}
                >
                    {getFieldDecorator('attach', {
                        rules: [{required: !isOuter, message: '请输入Attach'}],
                        initialValue: attach,
                    })(
                        <TextArea row={3} disabled={isOuter}/>
                    )}
                </FormItem>
                <FormItem
                    label="描述"
                    {...formItemLayout}
                >
                    {getFieldDecorator('description', {
                        initialValue: description,
                    })(
                        <Input placeholder="请输入描述（选填）"/>,
                    )}
                </FormItem>
                <FormItem
                    label="是否启用"
                    {...formItemLayout}
                >
                    {getFieldDecorator('enable', {
                        rules: [{required: true, message: '请选择是否启用', pattern: /^\S+$/}],
                        initialValue: enable,
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(BannerForm);
