import api from 'utils/api';


class SmallProgramRequest {

    /**
     * 查询机型裂变
     * @param {Object} params 请求参数
     */
    static async getModels(page,pageSize,brand,brandName,model,modelName,planId) {
        return api.get('/admin/miniapp/adminModel', {page,pageSize,brand,brandName,model,modelName,planId});
    }

    /**
     * 查询机型所配的方案
     * @param {Object} params 请求参数
     */
    static async getExclusivePlan(planId) {
        return api.get(`/admin/miniapp/sensitivity/plan/${planId}`);
    }

    /**
     * 查询所有方案
     * @param {Object} params 请求参数
     */
    static async getAllPlan() {
        return api.get(`/admin/miniapp/getAllPlans`);
    }

    /**
     * 保存修改机型的方案
     * @param {Object} params 请求参数
     */
    static async updatePlan({planId,modelId}) {
        return api.post(`/admin/miniapp/configurationPlan`,{planId,modelId});
    }
}
export default SmallProgramRequest;