
import api from 'utils/api';

class TeamRequest {
  /**
     * 查询视图战队列表
     * @param {Object} params 请求参数
     */
  static async getTeams(parmas) {
    return api.get('/admin/teams', parmas);
  }

  /**
     * 创建战队
     * @param {Object} params 请求参数
     */
  static async createTeam(params) {
    return api.post(`/admin/teams`, params);
  }

  /**
     * 更新战队信息
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
  static async updateTeam(params, id) {
    return api.put(`/admin/teams/${id}`, params);
  }

  /**
     * 获取详情
     * @param {Number} id
     */
  static async getTeam(id) {
    return api.get(`/admin/teams/${id}`);
  }

    /**
     * 解禁战队
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
  static async updateTeamGameStatus(id, params){
    return api.put(`/admin/teams/${id}/game-state`, params)
  }

    /**
     * 查看战队成员列表
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
  static async getTeamMembers(id, params){
    return api.get(`/admin/teams/${id}/members`, params)
  }

    /**
     * 添加战队成员
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
  static async addTeamMembers(id, params){
    return api.post(`/admin/teams/${id}/members`, params)
  }

    /**
     * 踢出战队
     * @param teamId
     * @param memberId
     * @returns {Promise<*>}
     */
  static async deleteTeamMembers(teamId, memberId){
    return api.delete(`/admin/training/${teamId}/members/${memberId}`)
  }

    /**
     * 修改成员职务
     * @param memberId
     * @param params
     * @returns {Promise<*>}
     */
  static async modifyMembersType(memberId, params){
    return api.put(`/admin/training/members/${memberId}?memberType=`+params.jobType)
  }

    /**
     * 修改战队队长
     * @param memberId
     * @returns {Promise<*>}
     */
  static async modifyTeamLeader(memberId){
    return api.put(`/admin/teams/members/${memberId}/leader`)
  }

  static async disbandTeam(id){
    return api.delete(`/admin/teams/${id}`)
  }

    /**
     * 获取战队商业价值
     * @param params
     * @returns {Promise<*>}
     */
  static async queryTeamsBusiness(id){
    return api.get(`/admin/teams/${id}/social/business`)
  }


    /**
     * 查看用户战队列表ID
     * @param params
     * @returns {Promise<*>}
     */
  static async getUserTeamList(id){
    return api.get(`/admin/teams/users/showId/${id}`)
  }

    /**
     * 获取所有游戏
     * @param {Number} id
     */
    static async getGames() {
        return api.get(`/admin/games`);
    }
    
    /**
     * 设置场上位置
     * @param params
     * @returns {Promise<*>}
     * 
     */
  static async updateTeamMemberPosition(params){
    return api.put(`/admin/teams/members/floor-position?memberId=`+params.memberId+`&floorPosition=`+params.floorPosition)
  }
}

export default TeamRequest;
