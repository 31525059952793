import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, Table, Row, Col, message} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getTopics,createTopic,deleteTopic,updateTopic,setTop} from "../../modules/topic/action";
import {getInfomationPosts,createInfomationPost,updateInfomationPost,deleteInfomationPost} from "../../modules/information/action";
import InfomationPostFilter from './InfomationPostFilter';
import {operationTypeValue} from 'modules/topic/topicEnum';
import AddInfoPostsModel from "./components/AddInfoPostsModel";

const {confirm} = Modal;

class InfomationPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            topicName: "",
            loading: false,
            seletionValue: null,
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            isShowAddInfoPostsModel: false,
        };
    }

    componentWillMount(){
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const { getInfomationPosts} = props;
        const {pageNo, pageSize} = this.state;
        this.setState({
            loading: true
          })
        await getInfomationPosts(
            pageNo, pageSize,
        );
    }

    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getInfomationPosts} = this.props;
        let { topicName} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getInfomationPosts(current, pageSize, topicName);
    }

    //条件查找动态方法
    onFilterCommentEvent = async (thirdInformationId,dynamicId) => {
        let {getInfomationPosts} = this.props;
        const {pageSize,} = this.state;
        this.setState({
            thirdInformationId: thirdInformationId,
            dynamicId:dynamicId,
            pageNo: 1
        });
        getInfomationPosts({page:1,pageSize,thirdInformationId,dynamicId});
    };

    confirm = (data) => {
        let _this = this;
        confirm({
            content: '确认删除吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.deleteTopicEvent(data.id)
            },
            onCancel() {
            },

        });
    }

    onBtnClick = (type, value) => {
        console.log("text",value);
        this.setState({
          operationType: type,
          isShowAddInfoPostsModel: true,
          seletionValue: value,
        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE,null)}>新增</Button>
            </Col>
        </Row>
    )

    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onAddEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdateEvent(values);
                break;
            default:
        }
    }

    // 创建
    onAddEvent = async (params) => {
        const {isShowAddInfoPostsModel} = this.state;
        const {createInfomationPost} = this.props;
        console.log("params",params);
        await createInfomationPost(params);
        await this.onInitData();
        message.success('新增成功');
        this.setState({isShowAddInfoPostsModel: !isShowAddInfoPostsModel});
    }

    // 修改
    onUpdateEvent = async (params) => {
        const {isShowAddInfoPostsModel,seletionValue} = this.state;
        const {updateInfomationPost} = this.props;
        await updateInfomationPost(seletionValue.id ,params);
        await this.onInitData();
        message.success('修改成功');
        this.setState({isShowAddInfoPostsModel: !isShowAddInfoPostsModel});
    }

    top = async (id,type) => {
        let {setTop} = this.props;
        await setTop(id,type)
        message.success('配置成功');
        await this.onInitData();
    }

    deleteTopicEvent = async (id) => {
        let {deleteInfomationPost} = this.props;
        this.setState({
            loading: true
        })
        await deleteInfomationPost(id)
        message.success('删除成功');
        await this.onInitData();
    }


    render() {
        const {infomationPosts, loading} = this.props;
        const {pageNo, pageSize,isShowAddInfoPostsModel, operationType, seletionValue} = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <InfomationPostFilter dataSource={{}} onCommit={this.onFilterCommentEvent}/>
                </Card>

                <Card style={{width: 'auto'}}>
                    <Table

                        columns={
                            [
                                {
                                    title: '关联ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '资讯ID',
                                    dataIndex: 'thirdInformationId',
                                    key: 'thirdInformationId',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '帖子/赛程ID',
                                    dataIndex: 'relationId',
                                    key: 'relationId',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '关联类型',
                                    dataIndex: 'infoJumpType',
                                    key: 'infoJumpType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.infoJumpType === 'MATCH_ACTIVITY' ? '赛事' : ''}
                                            {text.infoJumpType === 'TOPIC_DYNAMIC' ? '帖子' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                            this.onBtnClick(operationTypeValue.UPDATE, text)
                                        }}>编辑</Button>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="danger"  onClick={() => {
                                            this.confirm(text)
                                        }}>删除</Button>
                                        </div>)

                                    },
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={infomationPosts.data ? infomationPosts.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: infomationPosts ? infomationPosts.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    {isShowAddInfoPostsModel && <AddInfoPostsModel
                        onSuccess={this.onFormSubmit}
                        visible={isShowAddInfoPostsModel}
                        operationType={operationType}
                        data={seletionValue}
                        onClose={() => {
                            this.setState({
                            isShowAddInfoPostsModel: false,
                            });
                        }}
                    />}
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.viewInformation
        }
    }
);

const mapDispatchToProps = {
    getInfomationPosts,
    deleteTopic,
    updateTopic,
    createInfomationPost,
    updateInfomationPost,
    deleteInfomationPost,
    setTop
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(InfomationPost)));
