export const GET_COMMEMORATIVE_CARD_LIST = 'GET_COMMEMORATIVE_CARD_LIST';

export const GET_THEME_SETTING_LIST = 'GET_THEME_SETTING_LIST';

export const CREATE_COMMEMORATIVE_CARD = 'CREATE_COMMEMORATIVE_CARD';

export const UPDATE_COMMEMORATIVE_CARD = 'UPDATE_COMMEMORATIVE_CARD';

export const DELETE_COMMEMORATIVE_CARD = 'DELETE_COMMEMORATIVE_CARD';

export const UPDATE_THEME_SETTING = 'UPDATE_THEME_SETTING';

export const GET_CARD_PIC_LIST = 'GET_CARD_PIC_LIST';

export const UPDATE_CARD_PIC_LIST = 'UPDATE_CARD_PIC_LIST';

export const DELETE_THEMESETTING = 'DELETE_THEMESETTING';

export const ONANDOFF_THESHELFTHEME = 'ONANDOFF_THESHELFTHEME';

