import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import ArticleForm from './ArticleForm'
import {
    getArticles,
    getArticle,
    updateArticle,
    deleteArticle,
    createArticle,
    changeStatus,
    getGames
} from "../../modules/article/action";
import {operationTypeValue} from "../../modules/article/articleEnum";


class ArticleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            type: null,
            game: null,
            isShowEditAdminModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
            isShowDetailModal: false,
            text: "",
            detail: "",
        };
    }

    handleOk = e => {
        this.setState({
            isShowDetailModal: false,
        })
    };

    handleCancel = e => {
        this.setState({
            isShowDetailModal: false,
        })
    }

    componentDidMount() {
        const {pageNo, pageSize, type} = this.state;
        let {getArticles, getGames} = this.props;
        getArticles(pageNo, pageSize, type);
        getGames();
    }


    onCloseEditArticleModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    showDetailModal = (detail) => {
        console.log(detail);
        this.setState({
            isShowDetailModal: true,
            text: detail
        })
    }


    onOpenEditArticleModalEvent = async (ArticleId, type) => {
        const {getArticle} = this.props;
        if (type === operationTypeValue.UPDATE) {
            await getArticle(ArticleId);
            this.setState({
                operationType: operationTypeValue.UPDATE,
                detail : this.props.articleInfo.detail
            })

        }
        if (type === operationTypeValue.INCREASE) {
            this.setState({
                operationType: operationTypeValue.INCREASE
            })
        }
        console.log(this.state.operationType)
        this.setState({
            isShowEditAdminModal: true,
        });
    }
    onCloseEditArticleModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    //修改文章的方法
    onEditArticleEvent = async ({title, author, imageUrl, detail, remark, type, enable, top, tag}) => {
        const {pageNo, pageSize} = this.state;
        const {updateArticle, getArticles, articleInfo} = this.props;
        const {id} = articleInfo;
        try {
            await updateArticle(id, {title, author, imageUrl, detail, remark, type, enable, top, tag});
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditAdminModal: false,
            });
            getArticles(pageNo, pageSize, type);
        } catch (e) {
            console.log(e);
        }
    }

    //添加文章的方法
    onAddArticleEvent = async ({title, author, creator, imageUrl, detail, remark, type, enable, top, tag}) => {
        const {pageNo, pageSize} = this.state;
        const {createArticle, getArticles} = this.props;
        try {
            await createArticle({title, author, creator, imageUrl, detail, remark, type, enable, top, tag});
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getArticles(pageNo, pageSize, type);
        } catch (e) {
            console.log(e);
        }
    }

    changeStatus = async (id, enable) => {
        const {pageNo, pageSize, type} = this.state;
        try {
            const {changeStatus, getArticles} = this.props;
            await changeStatus(id, enable);
            if (enable == true) {
                notification.success({message: '启用成功'});
            }
            if (enable == false) {
                notification.success({message: '禁用成功'});
            }
            getArticles(pageNo, pageSize, type);
        } catch (e) {
            console.log(e);
        }
    }

    //删除文章的方法
    onDeleteArticleEvent = async (adminId) => {
        const {deleteArticle, getArticles} = this.props;
        const {pageNo, pageSize, type} = this.state;
        try {
            await deleteArticle(adminId);
            notification.success({message: '删除成功'});

            getArticles(pageNo, pageSize, type);
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getArticles} = this.props;
        console.log(current);
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getArticles(current, pageSize);
        console.log(current);
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary"
                         onClick={() => this.onOpenEditArticleModalEvent(null, operationTypeValue.INCREASE)}>新增</Button></Col>
        </Row>
    )

    render() {
        const {
            articles, articleInfo, loading
        } = this.props;
        const {
            isShowEditAdminModal, pageNo, pageSize, operationType
        } = this.state;
        return (
            <div>
                <Card>
                    <Table

                        columns={
                            [
                                {
                                    id: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '标题',
                                    dataIndex: 'title',
                                    key: 'title',
                                    align: 'center',
                                },
                                {
                                    title: '作者',
                                    dataIndex: 'author',
                                    key: 'author',
                                    align: 'center',
                                },
                                {
                                    title: '类型',
                                    key: 'typeInt',
                                    dataIndex: 'typeInt',
                                    align: 'center',
                                    render: (typeInt) => {
                                        switch (typeInt) {
                                            case 0:
                                                return <Tag color={"blue"}>官方公告</Tag>;
                                            case 1:
                                                return <Tag color={"gold"}>本周精选</Tag>;
                                            default:
                                                return null;
                                        }
                                    }
                                },
                                {
                                    title: '标签',
                                    dataIndex: 'tag',
                                    key: 'tag',
                                    align: 'center',
                                    render: (tag) => {
                                        return <Tag color={"purple"}>{tag}</Tag>
                                    }
                                },
                                {
                                    title: '备注',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                    align: 'center',
                                },
                                {
                                    title: '是否置顶',
                                    dataIndex: 'top',
                                    key: 'top',
                                    align: 'center',
                                    render: (top) => {
                                        switch (top) {
                                            case "TOP":
                                                return <Tag color={"magenta"}>置顶</Tag>
                                            case "NOT_TOP":
                                                return <Tag>非置顶</Tag>
                                            default:
                                        }
                                    }
                                },
                                {
                                    title: '是否启用',
                                    dataIndex: 'enable',
                                    align: 'center',
                                    render: (enable) => {
                                        switch (enable) {
                                            case true:
                                                return <Tag color={"green"}>启用</Tag>
                                            case false:
                                                return <Tag>禁用</Tag>
                                            default:
                                                return null;
                                        }
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, record) => {
                                        let desc = '';
                                        switch (record.enable) {
                                            case true:
                                                desc =
                                                    <div><Popconfirm title={`确定要下架该文章吗?`}
                                                                     onConfirm={() => this.changeStatus(record.id, false)}
                                                                     okText="确认" cancelText="取消">
                                                        <Button style={{
                                                            marginLeft:10,
                                                            marginBottom:10,
                                                            backgroundColor: '#ff4d4f',
                                                            color: '#fff'
                                                        }}>禁用</Button>
                                                    </Popconfirm>
                                                        <Button type="primary"
                                                                style={{marginLeft:10,marginBottom:10}}
                                                                onClick={() => this.onOpenEditArticleModalEvent(record.id, operationTypeValue.UPDATE)}
                                                                color="blue">修改</Button><br/>
                                                        <Popconfirm title={`确定要删除该文章吗?`}
                                                                    onConfirm={() => this.onDeleteArticleEvent(record.id)}
                                                                    okText="确认" cancelText="取消"><Button type="danger"
                                                                                                        style={{marginLeft:10,marginBottom:10}}
                                                                                                        color="red">删除</Button></Popconfirm>
                                                    </div>;
                                                break;
                                            case false:
                                                desc =
                                                    <div><Popconfirm title={`确定要启用该文章吗?`}
                                                                     onConfirm={() => this.changeStatus(record.id, true)}
                                                                     okText="确认" cancelText="取消">
                                                        <Button style={{
                                                            marginLeft:10,
                                                            marginBottom:10,
                                                            backgroundColor: '#87d068',
                                                            color: '#fff'
                                                        }}>启用</Button>
                                                    </Popconfirm>
                                                        &nbsp;&nbsp;
                                                        <Button type="primary"
                                                                style={{marginLeft:10,marginBottom:10}}
                                                                onClick={() => this.onOpenEditArticleModalEvent(record.id, operationTypeValue.UPDATE)}
                                                                color="blue">修改</Button><br/>
                                                        <Popconfirm title={`确定要删除该文章吗?`}
                                                                    onConfirm={() => this.onDeleteArticleEvent(record.id)}
                                                                    okText="确认" cancelText="取消"><Button type="danger"
                                                                                                        style={{marginLeft:10,marginBottom:10}}
                                                                                                        color="red">删除</Button></Popconfirm>
                                                    </div>;
                                                break;
                                            default:
                                        }
                                        return desc;
                                    }

                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={articles.data ? articles.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: articles ? articles.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑文章" : "添加文章"}
                        visible={isShowEditAdminModal}
                        footer={null}
                        destroyOnClose
                        width={900}
                        onCancel={this.onCloseEditArticleModalEvent}
                    >
                        {operationType === operationTypeValue.UPDATE ?
                            <ArticleForm {...articleInfo} {...this.props} onSubmitEvent={this.onEditArticleEvent}/> :
                            <ArticleForm onSubmitEvent={this.onAddArticleEvent}/>}
                    </Modal>
                </Card>
            </div>
        )
            ;

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Article)
        return {
            ...state.Article
        }

    }

);

const mapDispatchToProps = {
    getArticles,
    updateArticle,
    getArticle,
    deleteArticle,
    createArticle,
    changeStatus,
    getGames,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ArticleManagement)));