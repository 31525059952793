import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, Modal, DatePicker
} from 'antd';
import UpLoad from "../../components/upload";
import TimePicker from "antd/lib/time-picker";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;
const dateFormat = "YYYY-MM-DD HH:mm:00";
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class MedalAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenType:'GOLD'
        };
    }

    componentWillMount(){

    }

    // 是否不允许修改级数
    isMedalBan = (medalEnum) => {
        let enums = ['LEVEL_TEN', 'LEVEL_TWENTY', 'LEVEL_THIRTY', 'SIGN_FOR_LOVE', 'PERFECT'];

        for(let i = 0; i < enums.length; i++) {
            if(medalEnum === enums[i]) return true;
        }

        return false;
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent, medalLevel, medalType, medalEnum } = this.props;

                if(values.medalLevel != medalLevel && (medalType === 'SPECIAL' || this.isMedalBan(medalEnum))) {
                    notification.error({message: '该勋章主题不可修改勋章级数'});
                    return;
                }

                if(values.medalLevel < medalLevel) {
                    notification.error({message: '勋章级数只可增加，不可减少'});
                    return;
                }

                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            visible, onCancel, onSubmitEvent,gameList,data,
            id, medalName, medalPic, medalFunction, medalType, medalLevel, medalDesc, sort, medalEnum, type
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        let title = type == 'add' ? "新增票据信息" : '编辑票据信息';
        let {tokenType} = this.state;

        return (
            <Modal
                title={title}
                visible={visible}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"60%"}
            >
                <Form>
                    <FormItem
                        label="票券名称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('activityName', {
                            initialValue:type == 'add' ? '' : data.activityName,
                            rules: [{required: true, message: '请输入票券名称'}],
                        })(
                            <Input placeholder="请输入票券名称" />,
                        )}
                    </FormItem>

                    <FormItem label="游戏项目:"
                              {...formItemLayout}

                    >
                        {getFieldDecorator('gameType', {
                            initialValue:type == 'add' ? '' : data.gameType,
                            rules: [{required: true, message: '请选择游戏项目'}],
                        })(
                            <Select style={{width: 120}}>
                                {
                                    gameList && gameList.length > 0 && gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                            </Select>,
                        )}
                    </FormItem>

                    <FormItem
                        label="活动类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('activityType', {
                            rules: [{required: true, message: '请选择勋章类型', pattern: /^\S+$/}],
                            initialValue:type == 'add' ? 'WATCHING_ACTIVITIES' : data.activityType,
                        })(
                            <Select style={{width: '200px'}}>
                                <Option value="WATCHING_ACTIVITIES">线下观赛</Option>
                                <Option value="MEETING ">见面会</Option>
                                <Option value="BUSINESS_ACTIVITIES ">商务活动</Option>
                                <Option value="OTHER ">其他</Option>
                            </Select>
                        )}
                    </FormItem>

                    <FormItem
                        label="兑换类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('tokenType', {
                            rules: [{required: true, message: '请选择兑换类型', pattern: /^\S+$/}],
                            initialValue:type == 'add' ? 'GOLD' : data.tokenType,
                        })(
                            <Select onChange={(value)=>{
                                this.setState({tokenType:value});
                            }} style={{width: '200px'}}>
                                <Option value="GOLD">紫金</Option>
                                <Option value="ENERGY">能量</Option>
                            </Select>
                        )}
                    </FormItem>
                    {tokenType == 'ENERGY' &&
                        <FormItem
                            label="兑换所需能量值"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('price', {
                                initialValue:type == 'add' ? '' : data.price,
                                rules: [{required: true, message: '请输入兑换所需能量值，只能是数字', pattern: /^[0-9]*$/}],
                            })(
                                <Input placeholder={'请输入兑换所需能量值'} />,
                            )}
                        </FormItem>
                    }
                    {tokenType == 'GOLD' &&
                        <FormItem
                            visible={tokenType == 'GOLD'}
                            label="兑换所需紫金"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('price', {
                                initialValue:type == 'add' ? '' : data.price,
                                rules: [{required: true, message: '请输入兑换所需紫金，只能是数字', pattern: /^[0-9]*$/}],
                            })(
                                <Input placeholder={'请输入兑换所需紫金'} />,
                            )}
                        </FormItem>
                    }

                    <FormItem
                        label="普通会员兑换折扣"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('normalVipDiscount', {
                            initialValue:type == 'add' ? '' : data.normalVipDiscount,
                            rules: [{required: true, message: '请输入普通会员兑换折扣，只能是数字', pattern: /^[0-9]*$/}],
                        })(
                            <Input placeholder={'请输入普通会员兑换折扣'} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="年费会员兑换折扣"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('annulVipDiscount', {
                            initialValue:type == 'add' ? '' : data.annulVipDiscount,
                            rules: [{required: true, message: '请输入年费会员兑换折扣，只能是数字', pattern: /^[0-9]*$/}],
                        })(
                            <Input placeholder={'请输入年费会员兑换折扣'} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="券票图片"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('promotionalImage', {
                            initialValue:type == 'add' ? '' : data.promotionalImage,
                            rules: [{required: true, message: '请选择券票图片'}],

                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >
                            </UpLoad>
                        )}
                    </FormItem>

                    <FormItem
                        label="券票使用地址"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('activityAddress', {
                            initialValue:type == 'add' ? '' : data.activityAddress,
                            rules: [{required: true, message: '请输入券票使用地址'}],
                        })(
                            <Input   placeholder="请输入券票使用地址"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="券票使用说明"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('useIllustrate', {
                            initialValue:type == 'add' ? '' : data.useIllustrate,
                            rules: [{required: true, message: '请输入券票使用说明'}],
                        })(
                            <Input   placeholder="请输入券票使用说明"/>,
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="VIP会员可兑换时间">
                        {getFieldDecorator('vipExchangeStartTime', {
                            rules: [{required: true, message: '请输入VIP会员可兑换时间'}],
                            initialValue:type == 'add'?'' : moment(data.vipExchangeStartTime,dateFormat)
                            }
                        )(
                            <DatePicker showTime />
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="普通会员可兑换时间">
                        {getFieldDecorator('exchangeStartTime', {
                            rules: [{required: true, message: '请输入普通会员可兑换时间'}],
                            initialValue:type == 'add'?'' : moment(data.exchangeStartTime,dateFormat)
                            }
                        )(
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"
                            />
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="券票过期时间">
                        {getFieldDecorator('exchangeEndTime', {
                            rules: [{required: true, message: '请输入券票过期时间'}],
                            initialValue:type == 'add'?'': moment(data.exchangeEndTime,dateFormat)
                            }
                        )(
                            <DatePicker showTime
                            />
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="活动开始时间">
                        {getFieldDecorator('activityTime', {
                                rules: [{required: true, message: '请输入活动开始时间'}],
                                initialValue:type == 'add'?'': moment(data.activityTime,dateFormat)
                            }
                        )(
                            <DatePicker showTime
                            />
                        )}
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        label="活动结束时间">
                        {getFieldDecorator('activityEndTime', {
                                rules: [{required: true, message: '请输入活动结束时间'}],
                                initialValue:type == 'add'?'' : moment(data.activityEndTime,dateFormat)
                            }
                        )(
                            <DatePicker showTime
                            />
                        )}
                    </FormItem>

                    <FormItem
                        label="券票数量"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('total', {
                            initialValue:type == 'add' ? '' : data.total,
                            rules: [{required: true, message: '请输入券票数量，只能是数字', pattern: /^[0-9]*$/}],
                        })(
                            <Input placeholder={'请输入券票数量'} />,
                        )}
                    </FormItem>
                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(MedalAddForm);
