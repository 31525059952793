import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import {getVipActivity,deleteVipActivity} from '../../modules/vip/action';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {operationTypeValue} from "../../modules/coin/coinEnum";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';

class VipManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            isShowEditVipModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
            vipInfo:[]
        };
    }

    componentDidMount() {
        const {getVipActivity} = this.props;
        getVipActivity(1,10);
    }

    onCloseEditVipModalEvent = () => {
        this.setState({
            isShowEditVipModal: false,
        });
    }

    //删除Vip包的方法
    onDeleteVipActivity = async (vipActivityId) => {
        console.log(vipActivityId)
        const { deleteVipActivity, getVipActivity } = this.props;
        const {pageNo,pageSize} = this.state;
        try {
            await deleteVipActivity(vipActivityId);
            notification.success({ message: '删除成功' });

            getVipActivity(pageNo,pageSize);
        } catch (e) {
            console.log(e);
        }
    }
    onPageChangeEvent = (current, pageSize) => {
        let {getVipActivity} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getVipActivity(current, pageSize);
    }

    // onOpenEditVipModalEvent = (text,type) => {
    //     const {getVipPackages} = this.props;
    //     if (type===operationTypeValue.UPDATE){
    //         getVipPackages();
    //         this.setState({
    //             operationType : operationTypeValue.UPDATE,
    //             vipInfo : text
    //         })
    //
    //     }
    //     if (type===operationTypeValue.INCREASE){
    //         this.setState({
    //             operationType : type
    //         })
    //     }
    //     this.setState({
    //         isShowEditVipModal: true,
    //     });
    // };

    // renderTableTitle = () => (
    //     <Row type="flex" justify="end">
    //         <Col>
    //             {
    //                 whetherButtonPermission(buttonType.CREATE_VIP_PACKAGE.value, this.props.pageButtons)
    //                 &&  <Button type="primary" onClick={() => this.onOpenEditVipModalEvent(null,operationTypeValue.INCREASE)}>
    //                     {buttonType.CREATE_VIP_PACKAGE.label}
    //                 </Button>
    //             }
    //         </Col>
    //     </Row>
    // )

    render() {
        const {
            activityPackages, loading, pageButtons
        } = this.props;
        console.log(activityPackages)
        const {
            operationType,isShowEditVipModal,pageNo,pageSize
        } = this.state;
        return (
            <Card>
                <Table
                    columns={
                        [
                            {
                                title: '用户展示ID',
                                dataIndex: 'exhibitionId',
                                key: 'exhibitionId',
                                align: 'center',
                            },
                            {
                                title: '姓名',
                                dataIndex: 'realName',
                                key: 'realName',
                                align: 'center',
                            },
                            {
                                title: '地址',
                                dataIndex: 'realAddress',
                                key: 'realAddress',
                                align: 'center',
                            },
                            {
                                title: '手机号',
                                dataIndex: 'phone',
                                key: 'phone',
                                align: 'center',
                            },
                            {
                                title: '尺寸',
                                dataIndex: 'size',
                                key: 'size',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (id,text) => {
                                    console.log(text,id)
                                    return (
                                        <div>
                                            <Popconfirm title={`确定要删除该记录吗？`}
                                                        onConfirm={() => this.onDeleteVipActivity(text.id)}
                                                        okText="确认" cancelText="取消">
                                                <Button style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                            </Popconfirm>
                                        </div>
                                    )
                                },
                            },
                        ]
                    }

                    title={
                        this.renderTableTitle
                    }
                    dataSource={activityPackages.data ? activityPackages.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: activityPackages ? activityPackages.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                    }}
                />
                {/*<Modal*/}
                {/*    title = {operationType === operationTypeValue.UPDATE ? "编辑VIP包" : "添加VIP包"}*/}
                {/*    visible={isShowEditVipModal}*/}
                {/*    footer={null}*/}
                {/*    destroyOnClose*/}
                {/*    onCancel={this.onCloseEditVipModalEvent}*/}
                {/*>*/}
                {/*    {operationType === operationTypeValue.UPDATE ? <VipForm {...this.state.vipInfo} onSubmitEvent={this.onEditVipEvent}/> : <VipForm onSubmitEvent={this.onAddVIPEvent}/>}*/}
                {/*</Modal>*/}
            </Card>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.vip)
        return {
            ...state.vip,
            pageButtons:state.authentication.pageButtons
        }

    }

);

const mapDispatchToProps = {
    getVipActivity,deleteVipActivity
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(VipManagement)));
