import createAsyncAction from 'utils/createAsyncAction';
import {GET_GAME, GET_TRAIL_PLAN, GET_TRAIL_PLANS, UPDATE_TRAIL_PLANS} from "../trail-plan/actionType";
import TrailPlanRequest from "../../request/TrailPlanRequest";

const getTrailPlans = (page, size, trailPlanPageType, game) => (
    createAsyncAction(GET_TRAIL_PLANS, () => (TrailPlanRequest.getTrailPlans(page, size, trailPlanPageType, game)))
);

const updateTrailPlans = (key, {priority, points}) => (
    createAsyncAction(UPDATE_TRAIL_PLANS, () => (TrailPlanRequest.updateTrailPlan(key, {priority, points})))
);

const getTrailPlan = (key) => (
    createAsyncAction(GET_TRAIL_PLAN, () => (TrailPlanRequest.getTrailPlan(key)))
);

//游戏
const getGame = () => (createAsyncAction(GET_GAME, () => (TrailPlanRequest.getGame())));

export {
    getGame, getTrailPlans, updateTrailPlans, getTrailPlan
};