import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Card, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getMsgNotices
} from "../../modules/msgNotice/action";
import {
    sendStatus
} from "../../modules/msgNotice/enums";
import MsgNoticeFilter from "./MsgNoticeFilter";

class MsgNoticeManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            telephone: null,
            noticeGroupType: null,
            sendStatus:null,
        };
    }

    componentDidMount() {
        const {pageNo, pageSize,telephone,noticeGroupType,sendStatus} = this.state;
        let {getMsgNotices} = this.props;
        getMsgNotices(telephone, noticeGroupType,sendStatus, pageNo, pageSize);
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getMsgNotices} = this.props;
        let {telephone, noticeGroupType, sendStatus} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize
        });
        getMsgNotices(telephone, noticeGroupType,sendStatus, current, pageSize);
    }

    onFilterMsgNoticeEvent = (telephone, noticeGroupType,sendStatus) => {
        const {getMsgNotices} = this.props;
        const {pageSize} = this.state;
        this.setState({
            pageNo: 1,
            telephone: telephone,
            noticeGroupType: noticeGroupType,
            sendStatus
        })
        getMsgNotices(telephone, noticeGroupType,sendStatus,1, pageSize)
    }

    render() {
        const {
            msgNotices, loading
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <MsgNoticeFilter dataSource={{}} onCommit={this.onFilterMsgNoticeEvent}></MsgNoticeFilter>
                </Card>
                <Card>
                    <Table

                        columns={
                            [
                                {
                                    title: '通知分类',
                                    dataIndex: 'noticeGroupTypeStr',
                                    key: 'noticeGroupTypeStr',
                                    align: 'center',
                                    width:'100px',
                                },
                                {
                                    title: '手机号',
                                    dataIndex: 'telephone',
                                    key: 'telephone',
                                    align: 'center',
                                },
                                {
                                    title: '内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                },
                                {
                                    title: "发送时间",
                                    dataIndex: 'sendTime',
                                    key: 'sendTime',
                                    align: 'center'
                                },
                                {
                                    title: "发送状态",
                                    dataIndex: 'sendStatus',
                                    key: 'sendStatus',
                                    align: 'center',
                                    render:(text) => (text ? sendStatus[text].label : null)
                                },
                                {
                                    title: "失败原因",
                                    dataIndex: 'failMsg',
                                    key: 'failMsg',
                                    align: 'center',
                                }
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={msgNotices.data ? msgNotices.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: msgNotices ? msgNotices.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.MsgNotice
        }

    }

);

const mapDispatchToProps = {
    getMsgNotices
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(MsgNoticeManagement)));
