import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import BannerForm from './BannerForm'
import BannerFilter from "./BannerFilter";
import {
    getBanners,
    createBanner,
    getBanner,
    updateBanner,
    deleteBanner,
    changeStatus,
    getInnerJumpType,
    getValuation,
    getImage,
    updateValuation
} from "../../modules/banner/action";
import {operationTypeValue} from "../../modules/banner/bannerEnum";
import api from "../../utils/api";
import ValuationForm from "./ValuationForm";
import UpdateForm from "./UpdateForm";

class BannerManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            url: "",
            isShowEditAdminModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            isShowUrlModal: false,
            currentAdminId: null,
            innerJumpTypes: [],
            isOuter: true,
            bannerType: "",
            isShowAddValuationModal : false,
            isShowUpdateImageModal : false,
            isOpenModal : false,
            state : "",
            isShowTraining:false
        };
    }

    handleOk = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false,
            isShowTraining : false
        })
    };

    handleCancel = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false,
            isShowTraining : false
        })
    }

    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getBanners} = this.props;
        getBanners(pageNo, pageSize);
    }

    showUrlModal = (record) => {
        console.log(record.jumpType);
        let url = "";
        switch (record.jumpType) {
            case "INNER":
                url = `内链${record.attach}`
                break;
            case "OUTER":
                url = record.outerUrl;
                break;
            default:
        }
        this.setState({
            isShowUrlModal: true,
            url: url
        })
    }


    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenEditBannerModalEvent = (text, BannerId, type) => {
        console.log(text, BannerId)

        const {getBanner} = this.props;
        if (type === operationTypeValue.UPDATE) {
            this.setState({
                bannerType: text.innerJumpType
            })
            getBanner(BannerId);

            this.setState({
                operationType: operationTypeValue.UPDATE
            })

        }
        if (type === operationTypeValue.INCREASE) {
            this.setState({
                operationType: operationTypeValue.INCREASE
            })
        }
        console.log(this.state.operationType)
        this.setState({
            isShowEditAdminModal: true,
        });
        if (null != text) {
                this.setState({
                isOuter:  text.jumpType === "OUTER" ? true : false
            });              
        } else {
            this.setState({
                isOuter: false
            });
        }
    }

    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenValuation = () =>{
        this.setState({
            isOpenModal: true,
        });
    }

    onOpenTraining = () =>{
        this.setState({
            isShowTraining: true,
        });
    }

    //上传身价配置和官方公告图
    onAddValuation = async ({imageUrl,enable,pageType}) => {
        console.log(pageType)
        const {getValuation} = this.props;
        try {
            await getValuation(imageUrl,enable,pageType);
            notification.success({message: '添加成功'});

            this.setState({
                isShowAddValuationModal : false
            })

        }catch (e) {
            console.log(e);

        }
    }

    //修改身价配置和官方公告图
    onUpdateValuation = async ({imageUrl,enable,pageType}) => {
        console.log(pageType)
        const {updateValuation} = this.props;
        try {
            await updateValuation(imageUrl,enable,pageType);
            notification.success({message: '修改成功'});

            this.setState({
                isShowUpdateValuationModal : false
            })

        }catch (e) {
            console.log(e);

        }
    }



    //修改轮播图的方法
    onEditBannerEvent = async ({imageUrl, title, description, enable, ordinal, jumpType, outerUrl, innerJumpType, attach, pageType,jumpOutside}) => {
        const {pageNo, pageSize} = this.state;
        const {updateBanner, getBanners, bannerInfo} = this.props;
        const {id} = bannerInfo;
        try {
            await updateBanner(id, {
                imageUrl, title, description, enable, ordinal, jumpType, outerUrl, innerJumpType, attach, pageType,jumpOutside
            });
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getBanners(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    openAddEvent = () => {
        this.setState({
            isShowAddValuationModal : true,
            isOpenModal : false,
            state : "add"
        })
    }

    openUpdateEvent = () => {

        this.setState({
            isShowUpdateValuationModal : true,
            isOpenModal : false,
            state :"update"
        })

    }

    //添加轮播图的方法
    onAddBannerEvent = async ({imageUrl, title, description, enable, ordinal, jumpType, outerUrl, innerJumpType, attach, pageType,jumpOutside}) => {
        const {pageNo, pageSize} = this.state;
        const {createBanner, getBanners} = this.props;
        try {
            await createBanner({
                imageUrl,
                title,
                description,
                enable,
                ordinal,
                jumpType,
                outerUrl,
                innerJumpType,
                attach,
                pageType,
                jumpOutside
            });
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getBanners(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    changeStatus = async (id, enable) => {
        const {pageNo, pageSize} = this.state;
        try {
            const {changeStatus, getBanners} = this.props;
            await changeStatus(id, enable);
            if (enable == true) {
                notification.success({message: '启用成功'});
            }
            if (enable == false) {
                notification.success({message: '禁用成功'});
            }
            getBanners(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    //删除轮播图的方法
    onDeleteBannerEvent = async (adminId) => {
        const {deleteBanner, getBanners} = this.props;
        const {pageNo, pageSize} = this.state;
        try {
            await deleteBanner(adminId);
            notification.success({message: '删除成功'});
            console.log(pageNo)

            getBanners(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getBanners} = this.props;
        let {pageType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getBanners(current, pageSize, pageType);
    }

    onFilterBannerEvent = (pageType) => {
        const {getBanners} = this.props;
        const {pageSize} = this.state;
        this.setState({
            pageType: pageType
        });
        getBanners(1, pageSize, pageType);
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary"
                         onClick={() => this.onOpenEditBannerModalEvent(null, null, operationTypeValue.INCREASE)}>新增</Button></Col>
            {/* &nbsp;
            <Col><Button type="primary"
                         onClick={() => this.onOpenValuation(true)}>配置图</Button></Col> */}
        </Row>

    )

    render() {
        console.log(this.props.images)
        const {
            banners, bannerInfo, loading
        } = this.props;
        const {
            isShowEditAdminModal, pageNo, pageSize, operationType
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <BannerFilter dataSource={{}} onCommit={this.onFilterBannerEvent}></BannerFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '序号',
                                    dataIndex: 'ordinal',
                                    key: 'ordinal',
                                    align: 'center',
                                },
                                {
                                    title: '标题',
                                    dataIndex: 'title',
                                    key: 'title',
                                    align: 'center',
                                },
                                {
                                    title: '所属页面',
                                    dataIndex: 'pageType',
                                    key: 'pageType',
                                    align: 'center',
                                    render: (pageType) => {
                                        switch (pageType) {
                                            case "INDEX":
                                                return <Tag color={"blue"}>首页</Tag>;
                                            case "OFFICIAL_ACTIVITY":
                                                return <Tag color={"green"}>官方活动</Tag>;                                            
                                            default:
                                                break;
                                        }
                                    }
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    render: (imgUrl, record, index) => {
                                        return <img src={imgUrl ? imgUrl+'?x-oss-process=image/resize,w_200' : ''} alt={""} style={{width: 200, height: 100}}/>;
                                    }
                                },
                                {
                                    title: '链接',
                                    dataIndex: 'url',
                                    key: 'url',
                                    align: 'center',
                                    render: (url, record) => {
                                        return <Button color="blue" type="primary"
                                                       onClick={() => this.showUrlModal(record)}>查看</Button>
                                    }
                                },
                                {
                                    title: '链接类型',
                                    key: 'type',
                                    dataIndex: 'type',
                                    align: 'center',
                                    render: (type) => {
                                        switch (type) {
                                            case 0:
                                                return <div>内链</div>;
                                            case 1:
                                                return <div>外链</div>;
                                            default:
                                                return null;
                                        }
                                    }
                                },

                                {
                                    title: '描述',
                                    dataIndex: 'description',
                                    key: 'description',
                                    align: 'center',
                                },
                                {
                                    title: '是否启用',
                                    dataIndex: 'enable',
                                    key: 'enable',
                                    align: 'center',
                                    render: (enable, record, index) => {
                                        let desc = '';
                                        switch (enable) {
                                            case true:
                                                desc = <Tag color={"green"}>是</Tag>
                                                break;
                                            case false:
                                                desc = <Tag color={"red"}>否</Tag>
                                                break;
                                            default:
                                        }
                                        return desc;
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        if (text.enable == true) {
                                            return <div>
                                                <Popconfirm title={`确定要禁用该图片吗?`}
                                                            onConfirm={() => this.changeStatus(text.id, false)}
                                                            okText="确认" cancelText="取消">
                                                    <Button style={{
                                                        marginLeft:10,
                                                        marginBottom:10,
                                                        backgroundColor: '#ff4d4f',
                                                        color: '#fff'
                                                    }}>禁用</Button>
                                                </Popconfirm>
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditBannerModalEvent(text, text.id, operationTypeValue.UPDATE)}
                                                        color="blue">修改</Button>
                                                <Popconfirm title={`确定要删除该轮播图吗？`}
                                                            onConfirm={() => this.onDeleteBannerEvent(text.id)}
                                                            okText="确认" cancelText="取消"><br/>
                                                    <Button type="danger" style={{
                                                        marginLeft:10,
                                                        marginBottom:10,
                                                        backgroundColor: '#ff4d4f',
                                                        color: '#fff'
                                                    }}>删除</Button>
                                                </Popconfirm>
                                            </div>
                                        } else if (text.enable == false) {
                                            return <div>
                                                <Popconfirm title={`确定要启用该图片吗?`}
                                                            onConfirm={() => this.changeStatus(text.id, true)}
                                                            okText="确认" cancelText="取消">
                                                    <Button style={{
                                                        backgroundColor: '#87d068',
                                                        color: '#fff'
                                                    }}>启用</Button>
                                                </Popconfirm>
                                                &nbsp;&nbsp;
                                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditBannerModalEvent(text, text.id, operationTypeValue.UPDATE)}
                                                        color="blue">修改</Button><br/>
                                                <Popconfirm title={`确定要删除该轮播图吗？`}
                                                            onConfirm={() => this.onDeleteBannerEvent(text.id)}
                                                            okText="确认" cancelText="取消">
                                                    <Button type="danger" style={{
                                                        marginLeft:10,
                                                        marginBottom:10,
                                                        backgroundColor: '#ff4d4f',
                                                        color: '#fff'
                                                    }}>删除</Button>
                                                </Popconfirm>
                                            </div>
                                        }
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={banners.data ? banners.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: banners ? banners.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑轮播图" : "添加轮播图"}
                        visible={isShowEditAdminModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditBannerModalEvent}
                    >
                        {operationType === operationTypeValue.UPDATE ?
                            <BannerForm {...this.props} {...this.state} {...bannerInfo}
                                        onSubmitEvent={this.onEditBannerEvent}/> :
                            <BannerForm onSubmitEvent={this.onAddBannerEvent}/>}
                    </Modal>
                </Card>
                <Modal
                    title="链接查看"
                    visible={this.state.isShowUrlModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {this.state.url}
                </Modal>
                <Modal
                    title="配置"
                    visible={this.state.isOpenModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Button onClick={this.openAddEvent}>添加配置图</Button>
                    &nbsp;
                    <Button onClick={this.openUpdateEvent}>修改配置图</Button>

                </Modal>
                <Modal
                    title="添加配置图"
                    visible={this.state.isShowAddValuationModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose
                >
                    <ValuationForm onSubmitEvent={this.onAddValuation}/>
                </Modal>
                <Modal
                    title="修改配置图"
                    visible={this.state.isShowUpdateValuationModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose
                >
                    <UpdateForm {...this.state}  onSubmitEvent={this.onUpdateValuation} />
                </Modal>

            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Banner,
            ...state.innerJumpList,
            ...state.images
        }

    }

);

const mapDispatchToProps = {
    getBanners,
    updateBanner,
    getBanner,
    deleteBanner,
    createBanner,
    changeStatus,
    getInnerJumpType,
    getValuation,
    getImage,
    updateValuation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(BannerManagement)));