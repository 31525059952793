import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, message, Modal, notification, Row, Table,} from 'antd';
import {connect} from 'react-redux';
import {addConfigs, deleteConfigs, getConfig, getConfigs, updateConfigs} from '../../modules/config/action';
import {withRouter} from 'react-router-dom';
import {operationTypeValue} from "../../modules/config/configEnum";
import ConfigForm from "../config-management/ConfigForm";

const {confirm} = Modal;

class ConfigManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            isShowEditConfigModal: false,
            operationType: null,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
            selectionValue: null,
            configInfo: {}
        };
    }

    componentDidMount() {
        const {getConfigs} = this.props;
        const {page, size} = this.state;
        getConfigs(page, size);
    }

    onCloseEditConfigModalEvent = () => {
        this.setState({
            isShowEditConfigModal: false,
        });
    }

    //添加Config包的方法
    onAddConfigEvent = async (confKey, confValueType, confValue, confDesc, security) => {
        const {addConfigs} = this.props;
        try {
            await addConfigs({confKey, confValueType, confValue, confDesc, security});
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditConfigModal: false,
            });
            this.onGetData();
        } catch (e) {
            console.log(e);
        }
    }

    //修改Config的方法
    onEditConfigEvent = async (confKey, confValueType, confValue, confDesc, security) => {
        const {updateConfigs} = this.props;
        console.log(11111)
        console.log(security)
        try {
            await updateConfigs(confKey, {confValueType, confValue, confDesc, security});
            notification.success({message: '更新成功'});
            this.setState({

                isShowEditConfigModal: false,
            });
            this.onGetData();
        } catch (e) {
            console.log(e);
        }
    }

    //删除Config包的方法
    onDeleteConfigEvent = async (key) => {
        const {deleteConfigs} = this.props;
        confirm({
            title: '删除?',
            content: '是否删除该配置',
            okType: 'danger',
            onOk: async () => {
                await deleteConfigs(key);
                message.success('删除成功');
                this.onGetData();
            },
        });
    }

    //修改模态框
    onOpenEditConfigModalEvent = (type, text) => {
        if (type === operationTypeValue.UPDATE) {
            this.setState({
                operationType: type,
                configInfo: text
            })
        }
        if (type === operationTypeValue.INCREASE) {
            this.setState({
                operationType: type,
                configInfo: {security: false}
            })
        }
        this.setState({
            isShowEditConfigModal: true,
        });
    };

    //翻页
    onPageChangeEvent = (current, pageSize) => {
        const {getConfigs} = this.props;
        this.setState({
            page: current,
            size: pageSize,
        }, () => {
            getConfigs(current, pageSize);
        });
    }

    onGetData = () => {
        const {page, size} = this.state;
        let {getConfigs} = this.props;
        getConfigs(page, size);
    }

    onPageOnShowSizeChange = (current, pageSize) => {
        const {getConfig} = this.props;
        getConfig(current, pageSize);
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary"
                         onClick={() => this.onOpenEditConfigModalEvent( operationTypeValue.INCREASE, null)}>新增</Button></Col>
        </Row>
    )

    render() {
        const {
            configs, loading
        } = this.props;
        const {
            page, size, operationType, isShowEditConfigModal, selectionValue
        } = this.state;
        const defaultValue = selectionValue;
        return (
            <Card>
                <Table
                    columns={
                        [
                            {
                                title: '配置名',
                                dataIndex: 'confKey',
                                key: 'confKey',
                                align: 'center',
                                width: "15%"
                            },
                            {
                                title: '配置值类型',
                                dataIndex: 'confValueType',
                                key: 'confValueType',
                                align: 'center',
                                width: "15%"
                            },
                            {
                                title: '配置值',
                                dataIndex: 'confValue',
                                key: 'confValue',
                                align: 'center',
                                width: "50%"
                            },
                            {
                                title: '备注',
                                dataIndex: 'confDesc',
                                key: 'confDesc',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (row, text) => (
                                    <div>
                                        <Button type="primary"
                                                onClick={() => this.onOpenEditConfigModalEvent(operationTypeValue.UPDATE, text)}>修改</Button>&nbsp;
                                        <Button type="danger"
                                                onClick={() => this.onDeleteConfigEvent(text.confKey)}>删除</Button>
                                    </div>
                                ),
                            },
                        ]
                    }

                    title={
                        this.renderTableTitle
                    }
                    dataSource={configs.data ? configs.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: configs ? configs.total : 0,
                        current: page,
                        size: size,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
                <Modal
                    title={operationType === operationTypeValue.UPDATE ? "编辑配置" : "添加配置"}
                    defaultValue={defaultValue ? defaultValue : []}
                    visible={isShowEditConfigModal}
                    footer={null}
                    destroyOnClose
                    onCancel={this.onCloseEditConfigModalEvent}
                >
                    {operationType === operationTypeValue.UPDATE ?
                        <ConfigForm {...this.state} onSubmitEvent={this.onEditConfigEvent}/> :
                        <ConfigForm {...this.state} onSubmitEvent={this.onAddConfigEvent}/>}
                </Modal>
            </Card>
        );
    }
}

const mapStateToProps = (state => {
        console.log(state.config)
        return {
            ...state.config
        }
    }
);

const mapDispatchToProps = {
    getConfig,
    getConfigs,
    addConfigs,
    updateConfigs,
    deleteConfigs
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ConfigManagement)));
