import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl-context';
import {
  Row, Col, Card, Form, Button, message, Modal,
} from 'antd';
import { connect } from 'react-redux';
import './index.scss';
import Tree from 'components/tree';
import {
  getViewPermissionTree,
  getViewPermissionList,
  createViewPermission,
  deleteViewPermission,
  getViewPermissionInfo,
  updateViewPermission,
  updateViewPermissionTree,

} from 'modules/view-permission/action';
import { operationTypeValue } from 'modules/role/enums';
import InfoModel from './components/InfoModel';

const ButtonGroup = Button.Group;
const propTypes = {
  intl: PropTypes.object.isRequired,
};
const { confirm } = Modal;
const format = {
  label: 'name',
  key: 'id',
  children: 'children',
};
// 获取树每一行图标
const getTreeRowIcon = (item) => {
  switch (item.type) {
    case 'MENU':
      return 'appstore';
    default:
      return 'setting';
  }
};
// 获取操作框标题
const getModelTitle = (type) => {
  switch (type) {
    case operationTypeValue.INCREASE:
      return '增加菜单';
    case operationTypeValue.UPDATE:
      return '编辑菜单';
    default:
  }
};
class MenuManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletionValue: null,
      operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
    };
  }

  // ============================================页面渲染函数========================================================
  renderUserMenu = () => {
    const { permissionTree } = this.props;
    return (
      <Col span={7}>
        <Card>
          {
                permissionTree.length > 0
                  ? (
                    <Tree
                      data={permissionTree}
                      icon
                      iconFun={getTreeRowIcon}
                      format={format}
                    //   mobile = {true}
                      drop
                      onSelect={this.onSelectTreeEvent}
                      submit={this.onUpdatePermissionEvent}
                    >
                      <div className="tree-box-btn-tool">
                        <ButtonGroup>
                          <Button onClick={() => this.onBtnClick(operationTypeValue.INCREASE)} type="primary">增加</Button>
                          <Button onClick={() => this.onBtnClick(operationTypeValue.DELETE)} type="danger">删除</Button>
                          <Button onClick={() => this.onBtnClick(operationTypeValue.UPDATE)}>编辑</Button>
                        </ButtonGroup>
                      </div>
                    </Tree>
                  ) : null
              }
        </Card>
      </Col>
    );
  }

  renderMenuManage = () => {
    const { operationType } = this.state;
    const { permissionInfo, permissionList } = this.props;
    const defaultValue = operationType == operationTypeValue.UPDATE && permissionInfo;
    return (
      <Col span={16} offset={1}>
        <Card
          title={getModelTitle(operationType)}
          className="Menu_manage_content"
        >

          <InfoModel
            value={defaultValue}
            onSuccess={this.onFormSubmit}
            TreeList={permissionList}
          />
        </Card>
      </Col>
    );
  }

  onBtnClick = (type) => {
    switch (type) {
      case operationTypeValue.UPDATE:
        this.onGetMenuInfo();
        break;
      case operationTypeValue.DELETE:
        this.onDeleteMenuEvent();
        break;
      default:
        break;
    }
    this.setState({
      operationType: type,
    });
  }

  onGetMenuInfo = async () => {
    const { seletionValue } = this.state;
    const { getViewPermissionInfo } = this.props;
    if (!seletionValue) return message.warning('至少选择一条信息进行编辑');
    getViewPermissionInfo(seletionValue);
  }

  onDeleteMenuEvent = () => {
    const { seletionValue } = this.state;
    const { deleteViewPermission } = this.props;
    if (!seletionValue) return message.warning('至少选择一条信息进行删除');
    confirm({
      title: '删除?',
      content: '是否删除该条数据',
      okType: 'danger',
      onOk: async () => {
        await deleteViewPermission(seletionValue);
        this.onInitData();
        message.success('删除成功');
      },
    });
  }

  // =======================================数据处理函数=====================================================
  onInitData = async () => {
    const {
      getViewPermissionTree,
      getViewPermissionList,
    } = this.props;
    return Promise.all([
      getViewPermissionTree(),
      getViewPermissionList(),
    ]);
  }

  // 增加菜单信息
  onCreateMenuEvent = async (params) => {
    const { createViewPermission } = this.props;
    await createViewPermission(params);
    this.onInitData();
    message.success('新增菜单成功');
  }

  // 编辑菜单
  onUpdateMenuEvent = async (params) => {
    const { seletionValue } = this.state;
    const { updateViewPermission } = this.props;
    if (!seletionValue) return message.warning('至少选择一条信息进行删除');
    await updateViewPermission(params, seletionValue);
    this.onInitData();
    message.success('编辑成功');
  }

  // ========================================事件处理=============================================================
  onFormSubmit = (values) => {
    const { operationType } = this.state;
    switch (operationType) {
      case operationTypeValue.INCREASE:
        this.onCreateMenuEvent(values);
        break;
      case operationTypeValue.UPDATE:
        this.onUpdateMenuEvent(values);
        break;
      default:
        break;
    }
  }

  onUpdatePermissionEvent = async (data) => {
    await this.props.updateViewPermissionTree(data);
    this.onInitData();
    message.success('更新成功');
  }

  onSelectTreeEvent = (id) => {
    this.setState({
      seletionValue: id,
    });
  }

  // =======================================组件状态==============================================================
  render() {
    return (
      <div>
        <Row>
          {this.renderUserMenu()}
          {this.renderMenuManage()}
        </Row>
      </div>
    );
  }

  componentDidMount() {
    this.onInitData();
  }
}


const mapStateToProps = (state) => ({
  permissionTree: state.viewPermission.permissionTree,
  permissionList: state.viewPermission.permissionList,
  permissionInfo: state.viewPermission.permissionInfo,
});

const mapDispatchToProps = ({
  getViewPermissionTree,
  getViewPermissionList,
  createViewPermission,
  deleteViewPermission,
  getViewPermissionInfo,
  updateViewPermission,
  updateViewPermissionTree,
});

MenuManagement.propTypes = propTypes;
export default connect(
  mapStateToProps, mapDispatchToProps,
)(Form.create()(injectIntl(MenuManagement)));
