import {} from "../msgNotice/actionTypes";
import MsgNoticeRequest from "../../request/MsgNoticeRequest";
import {GET_MSG_NOTICES} from "../msgNotice/actionTypes";
import createAsyncAction from 'utils/createAsyncAction';

 const getMsgNotices = (receiverAccid,noticeGroupType,sendStatus,pageNo, pageSize) => (
    createAsyncAction(GET_MSG_NOTICES,() => (MsgNoticeRequest.getMsgNotices(receiverAccid,noticeGroupType,sendStatus,pageNo,pageSize)))
);

 export {
     getMsgNotices
 }
