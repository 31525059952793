import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class TeamMemberFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {exhibitionId,username,memberType} = values;
                onCommit && onCommit(exhibitionId,username,memberType);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="用户展示ID">
                    {getFieldDecorator('exhibitionId', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户名">
                    {getFieldDecorator('username', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="成员类型">
                    {getFieldDecorator('memberType', {
                        initialValue:""
                        }
                    )(
                        <Select  style={{width: 120}}>
                            <Option value="">全部</Option>
                            <Option value={"FORMAL"}  key={"FORMAL"}>{"正式成员"}</Option>
                            <Option value={"ALTERNATE"}  key={"ALTERNATE"}>{"替补成员"}</Option>
                        </Select>,
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TeamMemberFilter)));