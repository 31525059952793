export const GET_VIP_PACKAGES = 'GET_VIP_PACKAGES';// 获取VIP包

export const ADD_VIP_PACKAGE = 'ADD_VIPS';// 添加VIP包

export const UPDATE_VIP_PACKAGE = 'UPDATE_VIPS';// 更新VIP包

export const DELETE_VIP_PACKAGE = 'DELETE_VIPS';// 删除VIP包

export const GET_VIP = 'GET_VIP';// 获取VIP详情

export const RECHARGE_VIP_DAYS = 'RECHARGE_VIP_DAYS';// 充值VIP天数

export const GET＿VIP＿STATE = 'GET＿VIP＿STATE';// 判断用户是否VIP

export const GET_VIP_ACTIVITY = 'GET_VIP_ACTIVITY';// 充值VIP天数

export const DELETE_VIP_ACTIVITY = 'DELETE_VIP_ACTIVITY';// 充值VIP天数

export const FIND_HISTORY_LOGS = 'FIND_HISTORY_LOGS';// 紫金充值记录




