
export const operationTypeValue = {
    AGREE:'AGREE',
    REJECT:'REJECT',
};
export const operationType = {
    DETAIL:'DETAIL',
    ADD:'ADD',
    EDIT:'EDIT',
    TEAM:'TEAM'
};
export const clubTeamType = {
    FirstTeam: {
        label: '主力队(一队)',
        value: 'FirstTeam',
    },
    SecondTeam: {
        label: '二队',
        value: 'SecondTeam'
    },
    YouthTeam: {
        label: '青训队',
        value: 'YouthTeam'
    },
    Other: {
        label: '其他',
        value: 'Other'
    }
};