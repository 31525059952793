import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { notification } from 'antd';

let client = null;
const openNotification = (data) => {
  notification.open({
    message: data.content,
    description: data.noticeResourceTypeStr,
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

const connect = (accessToken) => {
  let socket = new SockJS('/api/ws');
  client = Stomp.over(socket);
  client.connect({
    token: accessToken,
  }, () => {
    client.subscribe('/user/topic/default', (res) => {
      alert(`个人消息：${res}`);
    });

    client.subscribe('/topic/broadcast/default', (res) => {
      alert(`广播消息：${res}`);
    });

    client.subscribe('/user/topic/notice', (res) => {
      // alert(`通用消息：${res}`);
      let data = JSON.parse(res.body);
      console.log(data);
      openNotification(data);
    });
  }, (error) => {
    if (error) {
      client.disconnect();
    }
    // showMessage('disconnected');
  });
};

const disconnected = () => {
  client.disconnect();
};

export default {
  connect,
  disconnected,
};
