import React, { Component } from 'react';
import {
    Button, Table, notification, message, Popconfirm,
} from 'antd';
import {getCareerManageList,addCareerRecord,updateCareerRecord,deleteCareerRecord} from "../../../modules/player/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import AddCareerModal from "./AddCareerModal";

class CareerRecordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            showAdd:false,
            selectValue:null,
            addFlag:false
        };
    }
    componentDidMount() {
        const {pageNo, pageSize, selectValue} = this.state;
        let {getCareerManageList,userId} = this.props;
        getCareerManageList(userId,pageNo, pageSize);
    }

    // componentWillReceiveProps(nextProps,nextState) {
    //     const {pageNo, pageSize} = this.state;
    //     let {getComment,clubId} = this.props;
    //     console.log(clubId)
    //     if(nextProps.dynamicId!==this.props.dynamicId){
    //         getComment(pageNo, pageSize,nextProps.dynamicId);
    //     }
    // }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getCareerManageList,userId} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getCareerManageList(userId,current, pageSize);
    }

    onShowBind =()=>{
        this.setState({
            showAdd:true,
            addFlag:true,
            selectValue:null
        })
    }

    onUpdate =(value)=>{
        this.setState({
            showAdd:true,
            selectValue:value,
            addFlag:false
        })

    }

    renderTitle=()=>{
        return(
            <Button style={{marginBottom:16}} onClick={this.onShowBind}>添加职业生涯</Button>
        )
    }
    handleCancel = e => {
        this.setState({
            showAdd: false,


        });
    };

    // 增加职业生涯
    onAddSaiEvent = async (joinDate,pelLeagueTypes,quirDate,teamShowId) => {
        const {addCareerRecord,userId,getCareerManageList, updateCareerRecord} = this.props;
        const {showAdd, selectValue} = this.state;
        console.log('selectValue',selectValue);
        if(selectValue==null){
            await addCareerRecord(userId,joinDate,pelLeagueTypes,quirDate,teamShowId);
            message.success('添加职业生涯成功');
        }else{
            await updateCareerRecord(selectValue.id,joinDate,pelLeagueTypes,quirDate,teamShowId);
            message.success('修改职业生涯成功');
        }
        await getCareerManageList(userId,1,10);
        // message.success('新增职业生涯成功');
        this.setState({showAdd: !showAdd,pageNo:1, selectValue: null});
    }

    //删除职业生涯的方法
    onDeleteEvent = async (id) => {
        const { deleteCareerRecord, getCareerManageList,userId } = this.props;
        const { page, size } = this.state;
        try {
            await deleteCareerRecord(id);
            notification.success({ message: '删除成功' });
            getCareerManageList(userId,page, size);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const {
            careerList, loading,pelLeagueType
        } = this.props;
        const {pageSize,pageNo,showAdd, selectValue,addFlag} = this.state;
        console.log('selectValueselectValue',selectValue);
        return (
            <div>
                <Table
                    columns={
                        [
                            {
                                title: '战队ID',
                                dataIndex: 'teamShowId',
                                key: 'teamShowId',
                                align: 'center',
                            },
                            {
                                title: '入会时间',
                                dataIndex: 'joinDate',
                                key: 'joinDate',
                                align: 'center',
                            },
                            {
                                title: '离开时间',
                                dataIndex: 'quitDate',
                                key: 'quitDate',
                                align: 'center',
                                render:(row)=>{
                                    let cnt = row?row:'未离开';
                                    return cnt
                                }
                            },
                            {
                                title: '服役赛季',
                                dataIndex: 'pelLeague',
                                key: 'pelLeague',
                                align: 'center',
                                render:(row)=>{
                                    return (row.join())
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                render: (row, text) => (
                                    <div>
                                        <Button type="primary" style={{
                                            marginRight:10,
                                            marginBottom:10
                                        }}  onClick={()=>this.onUpdate(text)} >修改</Button>
                                        <Popconfirm title={`确定要删除这条转会纪录吗?`}
                                                    onConfirm={() => this.onDeleteEvent(text.id)}
                                                    okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                            <Button type="danger" >删除</Button>
                                        </Popconfirm>
                                    </div>
                                ),
                            },
                        ]
                    }
                    title={
                        this.renderTitle
                    }
                    dataSource={careerList.data ? careerList.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: careerList ? careerList.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
                <AddCareerModal 
                    addFlag={addFlag}
                    values={selectValue} 
                    visible={showAdd}
                    onSubmitEvent={this.onAddSaiEvent}
                    onCancel={this.handleCancel}
                    pelLeagueType = {pelLeagueType}
                />
            </div>

        );
    }
}

const mapStateToProps = (state => {
        console.log('mapStateToPropsstate',state);
        return {
            ...state.ProfessionalPlayer
        }
    }

);

const mapDispatchToProps = {
    getCareerManageList,addCareerRecord,deleteCareerRecord, updateCareerRecord
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CareerRecordModal)));
