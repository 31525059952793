import api from '../utils/api';

class MsgNoticeRequest {

    static getMsgNotices(telephone,noticeGroupType,sendStatus,page,size){
        return api.get(`/admin/msg-notices`,{page,size,telephone,noticeGroupType,sendStatus});
    }
}

export default MsgNoticeRequest;
