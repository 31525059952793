
import api from 'utils/api';

class PermissionsRequest {
  /**
     * 查询视图权限列表
     * @param {Object} params 请求参数
     */
  static async getPermissions(parmas) {
    return api.get('/admin/view_permissions', parmas);
  }

  /**
     * 增加菜单 默认增加在最后一个
     * @param {Object} params 请求参数
     */
  static async addPermission(params) {
    return api.post('/admin/view_permissions', params);
  }

  /**
     * 更新菜单信息
     * @param {Object} params 请求参数
     * @param {Number} id 菜单id
     */
  static async updatePermission(params, id) {
    return api.put(`/admin/view_permissions/${id}`, params);
  }

  /**
     * 删除权限树
     * @param {Number} id
     */
  static async deletePermission(id) {
    return api.delete(`/admin/view_permissions/${id}`);
  }

  /**
     * 查询菜单树
     */
  static async getPermissionTree() {
    return api.get('/admin/view_permissions/tree');
  }

  /**
     * 获取详情
     * @param {Number} id
     */
  static async getPermission(id) {
    return api.get(`/admin/view_permissions/${id}`);
  }

  /**
     * 更新树信息
     * @param {Object} params
     */
  static async updatePermissionTree(params) {
    return api.put('/admin/view_permissions/tree', params);
  }
}

export default PermissionsRequest;
