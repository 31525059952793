import api from 'utils/api';

class BrandRequest {
    /**
     * 查询品牌赛事列表
     * @param {Object} params 请求参数
     */
    static async getBrands(parmas) {
        return api.get('/admin/brand/admin', parmas);
    }

    /**
     * 查询品牌赛事信息
     * @param id
     * @returns {Promise<*>}
     */
    static async getBrand(id) {
        return api.get(`/admin/brand/${id}`)
    }

    /**
     * 创建品牌赛事
     * @param params
     * @returns {Promise<*>}
     */
    static async createBrand(params){
        return api.post(`/admin/brand`, params);
    }

    /**
     * 修改品牌赛事
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
    static async updateBrand(id, params){
        return api.put(`/admin/brand/${id}`, params)
    }

    /**
     * 授权品牌赛事给管理员
     * @param id
     * @param adminIds
     */
    static authorizationBrand(id, adminIds){
        return api.post(`/admin/brand/${id}/authorize`,{adminIds})
    }

    /**
     * 个人训练赛-获取品牌赛事列表
     * @param {Object} params 请求参数
     */
     static async getPersonBrandList(parmas) {
        return api.get('/admin/personal/brand/admin', parmas);
    }

    /**
     * 个人训练赛-创建品牌赛事
     * @param params
     * @returns {Promise<*>}
     */
     static async createPersonBrand(params){
        return api.post(`/admin/personal/brand`, params);
    }

    /**
     * 个人训练赛-修改品牌赛事
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
    static async updatePersonBrand(brandId, params){
        return api.put(`/admin/personal/brand/${brandId}`, params)
    }

    /**
     * 个人训练赛-授权品牌赛事
     * @param id
     * @param adminIds
     */
     static authorizationPersonBrand(brandId, adminIds){
        return api.post(`/admin/personal/brand/${brandId}/authorize`, {adminIds})
    }
}

export default BrandRequest;
