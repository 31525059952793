import React, {Component} from "react";
import {Button, DatePicker, Form, Modal, Select} from "antd";
import {getGame} from '../../../../src/modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import {clubTeamType} from "../../../modules/club/clubEnum";


const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const {RangePicker} = DatePicker;

class UpdateClubTeamTypeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
                <Form onSubmit={this.handleSubmit} layout="inline">
                    <FormItem
                        label="战队级别"
                    >
                        {getFieldDecorator('clubTeamType', {
                            initialValue: '',
                        })(
                            <Select placeholder="请选择战队级别">
                                {
                                    Object.keys(clubTeamType).map(key => (
                                        <Select.Option
                                            value={clubTeamType[key].value}>{clubTeamType[key].label}</Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            修改
                        </Button>
                    </FormItem>
                </Form>
        );
    }
}


const mapStateToProps = (state => {
        return {
            ...state.viewClub,

        }
    }
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(UpdateClubTeamTypeModal))));

