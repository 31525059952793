import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";

const {TextArea} = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 12},
};

const defaultProps = {
  value: {},
  visible: false,
  onClose: () => {
  },
};

class TeamInfoModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentWillMount() {
    this.onInitData();
  }

  onInitData = (props = this.props) => {
    const {getGameList} = props;
    getGameList();
  }

  onSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {onSuccess} = this.props;
        // eslint-disable-next-line no-unused-expressions
        if (values && values.whiteLogo) {
          values.whiteLogo = values.whiteLogo.split("?")[0]
        }
        if (values && values.blackLogo) {
          values.blackLogo = values.blackLogo.split("?")[0]
        }
        onSuccess && onSuccess(values);
      }
    });
  }

  onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    let {visible, value, games} = this.props;
    return (
      <Modal
        visible={visible}
        title="战队信息"
        onCancel={this.props.onClose}
        cancelText="取消"
        onOk={this.onSubmit}
        okText="确认"
      >
          <Form>
              <FormItem
                label="名称"
                {...formItemLayout}
              >
                {getFieldDecorator('aliasName', {
                  // rules: [{ required: true, message: '请填写角色code值', pattern: /^\S+$/ }],
                  initialValue: value.aliasName ? value.aliasName : "",
                  /*rules: [{required: true, message: '请填写战队名!'}],*/
                })(
                  <Input placeholder="请填写战队名"/>,
                )}
              </FormItem>
              <FormItem
                label="标签"
                {...formItemLayout}
              >
                {getFieldDecorator('name', {
                  // rules: [{ required: true, message: '请填写角色code值', pattern: /^\S+$/ }],
                  initialValue: value.name ? value.name : "",
                  /*rules: [{required: true, message: '请填写战队标签!'}],*/
                })(
                  <Input placeholder="请填写战队标签"/>,
                )}
              </FormItem>

              <FormItem
                label="白底LOGO(200*200)"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('whiteLogo', {
                  initialValue: value.whiteLogo ? value.whiteLogo + '?x-oss-process=image/resize,w_86' : "",
                  rules: [{required: true, message: '白底LOGO未上传!'}],
                })(
                  <UpLoad
                    double={false}
                    //图片格式限制
                    // width={200}
                    // height={200}
                    size={51}
                  />
                )}
              </FormItem>
              <FormItem
                label="黑底LOGO(200*200)"
                {...formItemLayout}
              >
                {getFieldDecorator('blackLogo', {
                  initialValue: value.blackLogo ? value.blackLogo + '?x-oss-process=image/resize,w_86' : "",
                  rules: [{required: true, message: '黑底LOGO未上传!'}],
                })(
                  <UpLoad
                    double={false}
                    //图片格式限制
                    // width={200}
                    // height={200}
                    size={51}
                  />
                )}
              </FormItem>
              <FormItem
                label="游戏类型"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('game', {
                  initialValue: games.filter((item) => item.code == value.game).length > 0 ? games.filter((item) => item.code == value.game)[0].code : "",
                  rules: [{required: true, message: '请选择游戏类型!'}],
                })(
                  <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                    {games.map((item) => <Select.Option value={item.code}
                                                        key={item.code}>{item.gameName}</Select.Option>)}
                  </Select>,
                )}
              </FormItem>
              <FormItem
                label="战队宣言"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('declaration', {
                  initialValue: value.declaration ? value.declaration : "",
                  rules: [{max: 200, message: '战队宣言不得超过200个字符!'}],
                })(
                  <TextArea placeholder="请填写战队宣言"/>,
                )}
              </FormItem>
          </Form>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Team,
  }
};
const mapDispatchToProps = ({
  getGameList,
});
TeamInfoModel.defaultProps = defaultProps;
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TeamInfoModel)));