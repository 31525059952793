
export const GET_TEAM_INFO = 'GET_TEAM_INFO'; // 获取战队详情

export const GET_TEAM_LIST = 'GET_TEAM_LIST'; // 获取战队列表

export const GET_TEAM_BUSINESS = 'GET_TEAM_BUSINESS'; // 获取战队商业价值

export const UPDATE_TEAM = 'UPDATE_TEAM';// 更新战队信息

export const CREATE_TEAM = 'CREATE_TEAM';// 创建战队

export const UPDATE_TEAM_GAME_STATUS = 'UPDATE_TEAM_GAME_STATUS';// 解/禁战队

export const GET_TEAM_MEMBER_LIST = 'GET_TEAM_MEMBER_LIST';//获取战队成员列表

export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';//添加战队成员

export const QUITE_TEAM_MEMBER = 'QUITE_TEAM_MEMBER';//踢出战队

export const DISBAND_TEAM = 'DISBAND_TEAM'; //解散战队

export const UPDATE_TEAM_MEMBER_TYPE = 'UPDATE_TEAM_MEMBER_TYPE';//修改成员职务

export const UPDATE_TEAM_LEADER = 'UPDATE_TEAM_LEADER';//修改队长

export const GET_GAME_LIST = 'GET_GAME_LIST';// 获取所有游戏

export const GET_USER_TEAM_LIST = 'GET_USER_TEAM_LIST';// 查看用户战队列表ID

export const UPDATE_TEAM_MEMBER_POSITION = 'UPDATE_TEAM_MEMBER_POSITION'; // 设置场上位置

