import api from 'utils/api';

class LinupRequest {
    /**
     * 查询阵容列表
     * @param {Object} params 请求参数
     */
    static async getLineups(parmas) {
        return api.get('/admin/lineup/findByPage', parmas);
    }
    
    /**
     * 添加团队阵容
     * @param {Object} params 请求参数
     */
    static async createLineup(params) {
        return api.post(`/admin/lineup/addTeamLineup`, params);
    }

    /**
     * 修改团队阵容
     * @param {Object} params 请求参数
     */
    static async editTeamLineup(params) {
        return api.put(`/admin/lineup/editTeamLineup`, params);
    }    

    /**
     * 查询阵容成员列表
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
    static async getMemberList(params) {
        return api.get(`/admin/lineup/findMembersByPage`, params)
    }

    /**
     * 新增成员
     * @param params
     * @returns {Promise<*>}
     */
    static async addMember(params) {
        return api.post(`/admin/lineup/addMember`, params)
    }

    /**
     * 踢出成员
     * @param id
     * @returns {Promise<*>}
     */
    static async quiteMember(id) {
        return api.delete(`/admin/lineup/deleteMember/${id}`)
    }

    /**
     * 设置展示顺序
     * @param params
     * @returns {Promise<*>}
     * 
     */
    static async updateMemberSort(params) {
        return api.put(`/admin/lineup/setMemberSort`, params)
    }
    /**
     * 设置成员简介
     * @param params
     * @returns {Promise<*>}
     * 
     */
    static async updateMemberIntroduction(params) {
        return api.put(`/admin/lineup/setMemberIntroduction`, params)
    }

    /**
     * 删除团队阵容
     * @param id
     * @returns {Promise<*>}
     * 
     */
    static async deleteTeamLineup(id) {
        return api.delete(`admin/lineup/deleteTeamLineup/${id}`)
    }
}

export default LinupRequest;