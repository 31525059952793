import React from 'react';
import {Button, Card, Descriptions, Form, message, Modal, notification, Select, Table} from 'antd';
import {dealWithComplaint, getOrderList} from "../../modules/pratner-order/action"
import {connect} from "react-redux";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../modules/team/action";
import {getUsers} from "../../modules/user/action";
import Zmage from "react-zmage";
import api from "../../utils/api";


const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};
const { confirm } = Modal;
const ComplaintState = {
    INIT: {
        label: '未处理',
        value: 'INIT'
    },
    COMPLAINT_SUCCESS: {
        label: '申诉成功',
        value: 'COMPLAINT_SUCCESS'
    },
    COMPLAINT_FAIL: {
        label: '申诉失败',
        value: 'COMPLAINT_FAIL'
    },
}

class PartnerOrderModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            reasonCommands: [],
            state: ""

        };
    }

    componentDidMount() {
        console.log(this.props.partnerOrder)
        if (this.props.partnerOrder && this.props.partnerOrder.orderDTO) {
            console.log(this.props.partnerOrder.orderDTO.orderState)
            this.setState({
                state: this.props.partnerOrder.orderDTO.orderState
            })
        }
    }
    onSubmitEventConfirm = () => {
        confirm({
            content: '确定申诉成功吗？',
            okType: 'danger',
            okText: "确认",
            cancelText : "取消",
            onOk: async () => {
                this.onSubmitEvent();
            },
        });
    }
    onSubmitEvent = async () => {

        const {orderId, dealWithComplaint} = this.props;
        await dealWithComplaint(ComplaintState.COMPLAINT_SUCCESS.value, orderId);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(ComplaintState.COMPLAINT_SUCCESS);
            }
        });
    }

    onCommitEventConfirm = () => {
        confirm({
            content: '确定申诉失败吗？',
            okType: 'danger',
            okText: "确认",
            cancelText : "取消",
            onOk: async () => {
                this.onCommitEvent();
            },
        });
    }
    onCommitEvent = async () => {

        const {orderId, dealWithComplaint} = this.props;
        await dealWithComplaint(ComplaintState.COMPLAINT_FAIL.value, orderId);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommitEvent} = this.props;
                onCommitEvent && onCommitEvent(ComplaintState.COMPLAINT_FAIL);
            }
        });
    }

    getGameText(data){
        if (data.game == "HPJY"){
            return "和平精英"
        }
        if (data.game == "CYHX"){
            return "穿越火线手游"
        }
        if (data.game == "WZRY"){
            return "王者荣耀"
        }
        if (data.game == "QQFC"){
            return "QQ飞车手游"
        }
        if (data.game == "YXLM"){
            return "英雄联盟手游"
        }
        if (data.game == "SMZH"){
            return "使命召唤手游"
        }
        return ''
    }
    render() {

        const {getFieldDecorator} = this.props.form;
        const {partnerOrder, orderId} = this.props;
        const {reasonCommands, state} = this.state;
        console.log(partnerOrder)
        console.log(partnerOrder.orderDTO)
        console.log(this.props)
        console.log(partnerOrder.orderBeginImage)

        return (
            <Form>
                <FormItem {...formItemLayout}>
                    陪练订单ID : {orderId}
                </FormItem>
                <FormItem>
                    <Card title="订单跟踪">

                        <Table
                            columns={
                                [
                                    {
                                        title: '处理时间',
                                        dataIndex: 'dealTime',
                                        key: 'dealTime',
                                        align: 'center',
                                    },
                                    {
                                        title: '处理信息',
                                        dataIndex: 'dealRemark',
                                        key: 'dealRemark',
                                        align: 'center',
                                    },
                                    {
                                        title: '订单状态',
                                        dataIndex: 'orderState',
                                        key: 'orderState',
                                        align: 'center',
                                        render(index, data) {
                                            if (data.orderState == "RELEASE_SIGN_UP") {
                                                return partnerOrder.orderDTO.adminCreateId ? "发布报名" : "发布报名"
                                            }
                                            if (data.orderState == "TEACHER_ALREADY_SIGN_UP") {
                                                return partnerOrder.orderDTO.adminCreateId ? "导师已报名" : "导师已报名"
                                            }
                                            
                                            if (data.orderState == "TEACHING") {
                                                return partnerOrder.orderDTO.adminCreateId ? "教学进行中" : "教学进行中"
                                            }
                                            if (data.orderState == "WAIT_TO_ACCOUNT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "等待到账" : "等待到账"
                                            }
                                            if (data.orderState == "COMPLETE_TEACHER") {
                                                return partnerOrder.orderDTO.adminCreateId ? "完成教学" : "完成教学"
                                            }
                                            if (data.orderState == "RELEASE_TIMEOUT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "报名超时" : "报名超时"
                                            }
                                            if (data.orderState == "CHOOSE_TEACHER_TIMEOUT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "超时未选择导师" : "超时未选择导师"
                                            }
                                            if (data.orderState == "UNFINISHED_TEACHER") {
                                                return partnerOrder.orderDTO.adminCreateId ? "未完成教学" : "未完成教学"
                                            }
                                            if (data.orderState == "NO_CHOOSE_COMPLETE") {
                                                return partnerOrder.orderDTO.adminCreateId ? "导师超时未选择完成教学" : "导师超时未选择完成教学"
                                            }
                                            if (data.orderState == "FIRST_COMPLAINT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "申诉退款待导师处理" : "申诉退款待导师处理"
                                            }
                                            if (data.orderState == "TEACHER_AGREE_REFUND") {
                                                return partnerOrder.orderDTO.adminCreateId ? "导师同意退款" : "导师同意退款"
                                            }
                                            if (data.orderState == "TEACHER_TIMEOUT_REFUND") {
                                                return partnerOrder.orderDTO.adminCreateId ? "导师超时未处理申诉" : "导师超时未处理申诉"
                                            }
                                            if (data.orderState == "SECOND_COMPLAINT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "客服处理申诉" : "客服处理申诉"
                                            }
                                            if (data.orderState == "SERVICE_AGREE_COMPLAINT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "客服同意申诉" : "客服同意申诉"
                                            }
                                            if (data.orderState == "SERVICE_REFUSED_COMPLAINT") {
                                                return partnerOrder.orderDTO.adminCreateId ? "客服拒绝申诉" : "客服拒绝申诉"
                                            }
                                        }
                                    },
                                ]
                            }
                            dataSource={partnerOrder.processList ? partnerOrder.processList : []}
                            pagination={false}

                        />
                    </Card>
                </FormItem>

                <FormItem>
                    <Card title="订单信息">
                        <Descriptions>
                            <Descriptions.Item
                                label="学员ID">{partnerOrder.orderDTO ? partnerOrder.orderDTO.userDetail.exhibitionId : ""}</Descriptions.Item>
                            <Descriptions.Item
                                label="学员名">{partnerOrder.orderDTO ? partnerOrder.orderDTO.userDetail.username : ""}</Descriptions.Item>
                            <Descriptions.Item
                                label="导师ID">{partnerOrder.orderDTO ? partnerOrder.orderDTO.teacherExhibitionId : ""}</Descriptions.Item>
                            <Descriptions.Item
                                label="导师名">{partnerOrder.orderDTO ? partnerOrder.orderDTO.teacherUsername : ""}</Descriptions.Item>
                            <Descriptions.Item label="游戏名字">{ this.getGameText(partnerOrder.orderDTO || {})}</Descriptions.Item>
                            <Descriptions.Item
                                label="游戏区服">{partnerOrder.orderDTO ? partnerOrder.orderDTO.area : ""}</Descriptions.Item>
                            <Descriptions.Item
                                label="指导模式">{partnerOrder.orderDTO ? partnerOrder.orderDTO.type : ""}</Descriptions.Item>
                            <Descriptions.Item
                                label="性别要求">{partnerOrder.orderDTO ? partnerOrder.orderDTO.gender?partnerOrder.orderDTO.gender !== "UNKNOWN" ? partnerOrder.orderDTO.gender === "MALE" ? "GG" : "MM" : "无要求" : "":''}</Descriptions.Item>
                            <Descriptions.Item
                                label="指导时长">{partnerOrder.orderDTO ? partnerOrder.orderDTO.gameTime : ""}小时</Descriptions.Item>
                            <Descriptions.Item
                                label="指导价格">{partnerOrder.orderDTO ? partnerOrder.orderDTO.rancherNumber : ""}浪币</Descriptions.Item>
                        </Descriptions>

                    </Card>

                    {partnerOrder.orderBeginImage || partnerOrder.orderEndImage ? <Card title="订单截图">
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Card title="游戏开始截图" style={{width: 300}}>
                                <Zmage style={{height: 200}}
                                       src={partnerOrder.orderBeginImage ? partnerOrder.orderBeginImage : ''}/>
                            </Card>
                            &nbsp;
                            <Card title="游戏结束截图" style={{width: 300}}>
                                <Zmage style={{height: 200}}
                                       src={partnerOrder.orderEndImage ? partnerOrder.orderEndImage : ''}/>
                            </Card>
                        </div>
                    </Card> : ""}
                </FormItem>
                {partnerOrder.reasonCommands ? partnerOrder.reasonCommands.length !== 0 ? <FormItem>
                    <Card title="申诉原因">
                        <div>
                            <Descriptions column={1}>
                                <Descriptions.Item
                                    label="申诉退款主要原因">{partnerOrder.reasonCommands ? partnerOrder.reasonCommands.length !== 0 ? partnerOrder.reasonCommands[0].mainReason : "" : ""}</Descriptions.Item>
                                <br/>
                                <Descriptions.Item
                                    label="申诉退款具体描述">{partnerOrder.reasonCommands ? partnerOrder.reasonCommands.length !== 0 ? partnerOrder.reasonCommands[0].detailReason : "" : ""}</Descriptions.Item>
                            </Descriptions>
                            <Card title="申诉退款举证截图" style={{width: 300}}>
                                {partnerOrder.appealReasonImages.map((value, key) => {
                                    return <Card>
                                        <Zmage style={{width: 200, height: 200}}
                                               src={value ? value : ""}/>
                                    </Card>;
                                })}

                            </Card>
                        </div>
                    </Card>

                </FormItem> : "" : ""}


                {partnerOrder.reasonCommands ? partnerOrder.reasonCommands.length !== 0 ? <FormItem>

                    {this.props.partnerOrder.orderDTO ? this.props.partnerOrder.orderDTO.orderState === "SERVICE_REFUSED_COMPLAINT" || this.props.partnerOrder.orderDTO.orderState === "SERVICE_AGREE_COMPLAINT" || this.props.partnerOrder.complaintLevel === "FIRST_LEVEL" ?
                        <div style={{textAlign: 'center'}}>
                            <Button type="primary" disabled={true}>申诉成功</Button>
                            &nbsp;&nbsp;
                            <Button type="danger" disabled={true}>申诉失败</Button>
                        </div> :
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEventConfirm} type="primary">申诉成功</Button>
                            &nbsp;&nbsp;
                            <Button onClick={this.onCommitEventConfirm} type="danger">申诉失败</Button>
                        </div> : ""}


                </FormItem> : "" : ""}

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};
const mapDispatchToProps = ({
    getGameList,
    getUsers,
    dealWithComplaint,
    getOrderList
});
PartnerOrderModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PartnerOrderModal)));