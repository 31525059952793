import React from 'react';
import {Col, Descriptions, Form, Modal, Row} from 'antd';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import Button from "antd/lib/button";
import Zmage from 'react-zmage'

import {approveClub, getClub, getClubDetail} from '../../../modules/club/action';
import {operationTypeValue} from "../../../modules/club/clubEnum";
import ApproveClubModel from "./ApproveClubModel";

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

const defaultProps = {
    visible: false,
};

class ClubDetailModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            selectionValue: null,
            isShowEditAdminModal: false,
            params: {
                teamId: null,
                clubId: null
            }
        };
    }


    componentDidMount() {
    }


    renderTitle = (data) => {
        if (data.state === 'REJECT') {
            return (
                <Row type="flex" justify="space-between">
                    <Col>
                        俱乐部审批
                    </Col>
                    <Col>
                        <Button type="primary">已驳回</Button>
                    </Col>
                </Row>
            )
        } else if (data.state === 'APPROVED') {
            return (
                <Row type="flex" justify="space-between">
                    <Col>
                        俱乐部详情
                    </Col>
                    <Col>

                    </Col>
                </Row>
            )
        } else if (data.state == 'SUBMIT') {
            return (

                <Row type="flex" justify="space-between">
                    <Col>
                        俱乐部审批
                    </Col>
                    <Col>
                        <Button type="primary" onClick={() => {
                            this.onOptEvent(data.id, operationTypeValue.AGREE)
                        }} style={{marginRight: 20}}>通过</Button>
                        <Button type="primary" onClick={() => {
                            this.onOptEvent(data.id, operationTypeValue.REJECT)
                        }}>驳回</Button>
                    </Col>
                </Row>
            )
        }

    }

    onOptEvent = async (id, type) => {
        const {approveClub, getClubDetail, getClub} = this.props;
        let params = this.state;
        if (type === operationTypeValue.AGREE) {
            this.setState({
                isShowEditAdminModal: true,
                selectionValue: id
            });
        } else if (type === operationTypeValue.REJECT) {
            params.operation = operationTypeValue.REJECT
            params.clubType = 'COMMON'
            this.setState({
                    params
                }
            )
            await approveClub(id, {operation: params.operation, clubType: params.clubType});
            getClubDetail(id);
            getClub({page: 1, size: 10})
        }
    }

    onAgreeEvent = async (clubType) => {
        const {approveClub, getClubDetail, getClub} = this.props;
        const {selectionValue} = this.state;
        await approveClub(selectionValue, Object.assign(clubType, {
            operation: operationTypeValue.AGREE
        }));
        getClubDetail(selectionValue);
        getClub({page: 1, size: 10})
        this.setState({
            isShowEditAdminModal: false,
        });
    }


    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    renderClubType = (text) => {
        if (text === 'COMMON') {
            return (
                <div color="blue">普通</div>
            )
        }
        if (text === '' || text === null) {
            return (
                <div color="blue">未审核</div>
            )
        } else {
            return (
                <div color="blue">{text}</div>
            )
        }
    }

    renderGameType = (type) => {
        switch (type) {
            case 'HPJY':
                return (
                    <div>和平精英</div>
                )
            case 'WZRY':
                return (
                    <div>王者荣耀</div>
                )
            default:
        }
    }

    render() {
        const {visible, clubDetail} = this.props;
        const logoBlack = clubDetail.logoBlack ? clubDetail.logoBlack : '';
        const logoWhite = clubDetail.logoWhite ? clubDetail.logoWhite : '';
        const authorizationSource = clubDetail.authorization ? clubDetail.authorization : '';
        const licenseSource = clubDetail.license ? clubDetail.license : '';
        const {isShowEditAdminModal} = this.state;

        return (
            <Modal
                visible={visible}
                title="俱乐部"
                onOk={this.props.onClose}
                onCancel={this.props.onClose}
                footer={[<Button onClick={this.props.onClose}>关闭</Button>]}
                okText={"关闭"}
                width={800}
            >
                <Descriptions bordered column={2} title={this.renderTitle(clubDetail)} size={'default'}>
                    <Descriptions.Item label="俱乐部名称" span={2}>{clubDetail.name}</Descriptions.Item>
                    <Descriptions.Item
                        label="管理员展示ID">{clubDetail.exhibitionId ? clubDetail.exhibitionId : '未指定'}</Descriptions.Item>
                    <Descriptions.Item label="俱乐部简称">{clubDetail.abbreviation}</Descriptions.Item>
                    <Descriptions.Item label="游戏类型">{this.renderGameType(clubDetail.game)}</Descriptions.Item>
                    <Descriptions.Item label="是否为官方">{clubDetail.official ? '是' : '否'}</Descriptions.Item>
                    <Descriptions.Item label="自定义标签">{clubDetail.label ? clubDetail.label : "无"}</Descriptions.Item>
                    <Descriptions.Item label="试训推荐优先级">{clubDetail.priority}</Descriptions.Item>
                    <Descriptions.Item label="俱乐部点赞数">{clubDetail.points}</Descriptions.Item>
                    <Descriptions.Item label="俱乐部类型">{this.renderClubType(clubDetail.clubType)}</Descriptions.Item>
                    <Descriptions.Item label="俱乐部地址" span={2}>
                        {clubDetail.address ? clubDetail.address.province + clubDetail.address.city + clubDetail.detailAddress : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="俱乐部详细地址" span={2}>
                        {clubDetail.detailAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="俱乐部介绍" span={2}>{clubDetail.introduction}</Descriptions.Item>
                    <Descriptions.Item label="俱乐部白底LOGO" span={2}>
                        <img src={logoWhite ? logoWhite+'?x-oss-process=image/resize,w_80' : "" } alt={""} style={{width: 80, height: 80}}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="俱乐部黑底LOGO" span={2}>
                        <img src={logoBlack ? logoBlack+'?x-oss-process=image/resize,w_80' : "" } alt={""} style={{width: 80, height: 80}}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="俱乐部营业执照" span={2}>
                        <div style={{width: 80, height: 80}}>
                            {
                                clubDetail.license ?
                                    <Zmage style={{width: 80, height: 80}} src={licenseSource ? licenseSource+'?x-oss-process=image/resize,w_690' : ""}/>
                                    :
                                    ''
                            }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="俱乐部管理授权函" span={2}>
                        <div style={{width: 80, height: 80}}>
                            {
                                clubDetail.authorization ?
                                    <Zmage style={{width: 80, height: 80}} src={authorizationSource ? authorizationSource+'?x-oss-process=image/resize,w_690' : ""}/>
                                    :
                                    ''
                            }
                        </div>
                    </Descriptions.Item>
                </Descriptions>
                <Modal
                    title={"请选择俱乐部类型"}
                    visible={isShowEditAdminModal}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={this.onCloseEditBannerModalEvent}
                >
                    <ApproveClubModel onSubmitEvent={this.onAgreeEvent}/>
                </Modal>
            </Modal>

        );
    }
}

ClubDetailModel.propTypes = propTypes;
ClubDetailModel.defaultProps = defaultProps;

const mapStateToProps = (state => {
        return {
            ...state.viewClub,

        }
    }
);


const mapDispatchToProps = {
    getClubDetail, approveClub, getClub
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(ClubDetailModel))));