import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, DatePicker
} from 'antd';
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};
const dateFormat = "YYYY-MM-DD HH:mm:00";


class GiftForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypeList: [],
            jumpDescription: "",
            selectedType: this.props.giftType || 'IN_KIND_GIFT',
        };
    }

    componentWillMount(){
        
    }

    changeType = (value) => {
        this.setState({
            selectedType: value
        });
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent, type, createTime } = this.props;
                let diff;

                if(type === 'UPDATE') {
                    diff = moment(values.giftExpirationTime).diff(moment(createTime));
                } else {
                    let time = new Date();
                    diff = moment(values.giftExpirationTime).diff(moment(time));
                }
                if(diff < 0){
                    notification.error({message: '过期时间不得早于创建时间，请重新选择过期时间'});
                    return;
                }

                let val = {
                    ...values,
                    giftExpirationTime: values.giftExpirationTime ? moment(values.giftExpirationTime).format('YYYY-MM-DD HH:mm:00'):'',                    
                }
                onSubmitEvent && onSubmitEvent(val);
            }
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() {
        let {
            giftName, giftPurpose, copies, giftQuantity, giftExpirationTime, type
        } = this.props;
        let { selectedType } = this.state;        
        const {getFieldDecorator} = this.props.form;
        let giftUnit = selectedType === 'IN_KIND_GIFT' ? '（单位：份）' : selectedType === 'VIP' ? '（单位：天）' : selectedType === 'ENERGY' ? '（单位：点）' : '';
        
        return (
            <Form>
                <FormItem
                    label="礼品名"
                    {...formItemLayout}
                >
                    {getFieldDecorator('giftName', {
                        rules: [{required: true, message: '请输入礼品名', pattern: /^\S+$/}],
                        initialValue: giftName,
                    })(
                        <Input placeholder="请输入礼品名"/>,
                    )}
                </FormItem>
                <FormItem
                    label="礼品类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('giftType', {
                        rules: [{required: true, message: '请选择礼品类型', pattern: /^\S+$/}],
                        initialValue: selectedType,
                    })(
                        <Select style={{width: '200px'}} disabled={type === 'UPDATE'}  onChange={this.changeType}>
                            <Option value="IN_KIND_GIFT">实物周边</Option>
                            <Option value="VIP">VIP会员（虚拟礼品）</Option>
                            <Option value="ENERGY">能量值（虚拟礼品）</Option>                           
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="礼品用途"
                    {...formItemLayout}
                >
                    {getFieldDecorator('giftPurpose', {
                        rules: [{required: true, message: '请选择礼品用途', pattern: /^\S+$/}],
                        initialValue: giftPurpose || 'CDK'
                    })(
                        <Select style={{width: '200px'}} disabled={type === 'UPDATE'}>
                            <Option value="CDK">CDK</Option>
                            <Option value="COMMEMORATIVE_CARD">纪念卡</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="礼品份数"
                    {...formItemLayout}
                >
                    {getFieldDecorator('copies', {
                        rules: [{required: true, message: '请输入礼品份数', pattern: /^[0-9]*$/}],
                        initialValue: copies ? copies : selectedType === 'IN_KIND_GIFT' ? 1 : null
                    })(
                        <Input placeholder={'请输入礼品份数' + giftUnit} />,               
                    )}
                </FormItem>
                <FormItem
                    label="礼品数量"
                    {...formItemLayout}
                >
                    {getFieldDecorator('giftQuantity', {
                        rules: [{required: true, message: '请输入礼品数量', pattern: /^[0-9]*$/}],
                        initialValue: giftQuantity,
                    })(
                        <Input placeholder="请输入礼品数量"/>
                    )}
                </FormItem>
                <FormItem label="过期时间"
                    {...formItemLayout}
                >
                    {getFieldDecorator('giftExpirationTime', {
                            rules: [{required: true, message: '请选择过期时间'}],
                            initialValue: type === 'UPDATE' ? moment(giftExpirationTime, dateFormat) : moment()                                                    
                        }
                    )(
                        <DatePicker style={{width: '200px'}} showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00" />
                        
                    )}
                </FormItem>

                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(GiftForm);
