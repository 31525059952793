
import createAsyncAction from 'utils/createAsyncAction';
import {
    ADD_APP_CONTACT,
    UPDATE_APP_CONTACT,
    GET_APP_CONTACT,
    DELETE_APP_CONTACT,
} from './actionTypes';
import AppContactRequest from "../../request/AppContactRequest";


//添加联系信息
export const addAppContact = (params) => (createAsyncAction(ADD_APP_CONTACT, () => (AppContactRequest.createAppContact(params))));
//修改联系信息
export const updateAppContact = (id, params) => (createAsyncAction(UPDATE_APP_CONTACT, () => (AppContactRequest.updateAppContact(id, params))));
//获取联系信息
export const getAppContact = () => (createAsyncAction(GET_APP_CONTACT, () => (AppContactRequest.getAppContact())));
//删除联系信息
export const deleteAppContact = (id) => (createAsyncAction(DELETE_APP_CONTACT, () => (AppContactRequest.deleteAppContact(id))));
