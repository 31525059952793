import React from 'react';
import {Button, Form, Input, message, Modal, Table, Card, notification, Popconfirm, Row, Col} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";
import SubMedalForm from "./SubMedalEditForm";
import SubMedalLog from './SubMedalLog';
import SubMedalSendOrRecycle from './SubMedalSendOrRecycle';
import { operationTypeValue } from "../../modules/medal/medalEnum";
import { getSubMedalList, getSubMedalData, updateSubMedalData,addSubMedalData, updateSubMedalSend, updateSubMedalRecycle } from '../../modules/medal/action';
import SubMedalAddForm from "./SubMedalAddForm";

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class SubMedal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            loading: false,
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            showEditSubMedalModal: false,
            showAddSubMedalModal: false,
            subMedalData: {}, //选中的子勋章数据
            showLogModal: false,
            childMedalId: null,
            childMedalName: '',
            showSendOrRecycleModal: false,
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        const { getSubMedalList, medalThemeId } = this.props;
        const {page, size} = this.state;
        getSubMedalList(medalThemeId, {page, size});
        this.setState({
            loading: false
        });
    }

    //分页方法
    onPageChangeEvent = (current, size) => {
        let { getSubMedalList, medalThemeId } = this.props;
        this.setState({
            page: current,
            size: size,
        });
        getSubMedalList(medalThemeId, {page: current, size});
    }

    onOpenEditSubMedalEvent = (childMedalId, type) => {
        const { getSubMedalData, medalType } = this.props;

        if (type === operationTypeValue.UPDATE_SUB) {
            getSubMedalData(childMedalId).then((json) => {
                this.setState({
                    subMedalData: { ...json, medalType, type }
                });
            }).catch((err) => {
                this.setState({
                    subMedalData: { type }
                });
            });
        } else {
            this.setState({
                subMedalData: { type }
            });
        }

        this.setState({
            operationType: type,
            showEditSubMedalModal: true
        });
    }

    onCloseEditSubMedalEvent = () => {
        this.setState({
            showEditSubMedalModal: false
        });
    }

    onCloseAddSubMedalEvent = () => {
        this.setState({
            showAddSubMedalModal: false
        });
    }

    // 编辑子勋章
    onEditSubMedalEvent = async (params) => {
        const {updateSubMedalData, getSubMedalList, medalThemeId} = this.props;
        const {page, size, subMedalData} = this.state;
        try {
            await updateSubMedalData(subMedalData.childMedalId, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditSubMedalModal: false
            });
            getSubMedalList(medalThemeId, {page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑子勋章
    onAddSubMedalEvent = async (params) => {
        const {addSubMedalData, getSubMedalList, medalThemeId,medalType} = this.props;
        const {page, size, subMedalData} = this.state;
        try {
            params.themeMedalId = medalThemeId;
            params.medalType = medalType;
            params.shelfStatus = false;
            await addSubMedalData(params);
            notification.success({message: '新增成功'});
            this.setState({
                showAddSubMedalModal: false
            });
            getSubMedalList(medalThemeId, {page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onAddEvent = async (params,type) => {
        this.setState({
            operationType:type,
            showAddSubMedalModal: true,
        });
    }

    // 查看子勋章的获得/回收记录
    onOpenLogModal = (childMedalId, childMedalName, type) => {
        this.setState({
            childMedalId,
            childMedalName
        }, () => {
            this.setState({
                operationType: type,
                showLogModal: true
            });
        });
    }

    onCloseLogModal = () => {
        this.setState({
            showLogModal: false,
            operationType: operationTypeValue.UPDATE_SUB
        });
    }

    onOpenSendOrRecycleModal = (childMedalId, type) => {
        const { getSubMedalData, medalType } = this.props;

        getSubMedalData(childMedalId).then((json) => {
            this.setState({
                subMedalData: { ...json, type }
            });
        }).catch((err) => {
            this.setState({
                subMedalData: { type }
            });
        });

        this.setState({
            operationType: type,
            showSendOrRecycleModal: true
        });
    }

    onCloseSendOrRecycleModal = () => {
        this.setState({
            showSendOrRecycleModal: false
        });
    }

    // 子勋章赠送/回收
    onSubMedalSendOrRecycleEvent = async (params) => {
        const {updateSubMedalSend, updateSubMedalRecycle, getSubMedalList, medalThemeId} = this.props;
        const {page, size, subMedalData, operationType} = this.state;
        try {
            if(operationType === operationTypeValue.SEND_SUB) {
                // 赠送
                await updateSubMedalSend(subMedalData.childMedalId, params);
                notification.success({message: '赠送成功'});
            } else if(operationType === operationTypeValue.RECYCLE_SUB) {
                // 回收
                await updateSubMedalRecycle(subMedalData.childMedalId, params);
                notification.success({message: '回收成功'});
            }
            this.setState({
                showSendOrRecycleModal: false
            });
            getSubMedalList(medalThemeId, {page, size});
        } catch (e) {
            console.log(e);
        }
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onAddEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    render() {
        const { visible, subMedalList, onClose } = this.props;
        const { page, size, loading, operationType, showEditSubMedalModal,showAddSubMedalModal, subMedalData,
            showLogModal, childMedalId, childMedalName, showSendOrRecycleModal
        } = this.state;
        return (
            <Modal
                visible={visible}
                title={"查看子勋章"}
                onOk={() => {}}
                onCancel={onClose}
                width={"60%"}
            >
                <div>
                    <Card style={{width: 'auto'}}>
                        <Table
                            columns={
                                [
                                    {
                                        title: '勋章ID',
                                        dataIndex: 'childMedalId',
                                        key: 'childMedalId',
                                        align: 'center',
                                    },
                                    {
                                        title: '勋章名',
                                        dataIndex: 'childMedalName',
                                        key: 'childMedalName',
                                        align: 'center',
                                    },
                                    {
                                        title: '勋章类型',
                                        dataIndex: 'medalType',
                                        key: 'medalType',
                                        align: 'center',
                                        render: (row, text) => (
                                            <div>
                                                {text.medalType === 'GROWTH' ? '成长勋章' : ''}
                                                {text.medalType === 'SPECIAL' ? '特殊勋章' : ''}
                                                {text.medalType === 'CIRCLE' ? '圈子勋章' : ''}
                                            </div>
                                        )
                                    },
                                    {
                                        title: '获得人数',
                                        dataIndex: 'holders',
                                        key: 'holders',
                                        align: 'center',
                                    },
                                    {
                                        title: '勋章等级',
                                        dataIndex: 'level',
                                        key: 'level',
                                        align: 'center',
                                    },
                                    {
                                        title: '创建时间',
                                        dataIndex: 'createTime',
                                        key: 'createTime',
                                        align: 'center',
                                        width: '20%'
                                    },

                                    {
                                        title: '操作',
                                        dataIndex: 'operate',
                                        key: 'operate',
                                        align: 'center',
                                        width: '20%',
                                        render: (id, text) => {
                                            return <div>
                                                <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditSubMedalEvent(text.childMedalId, operationTypeValue.UPDATE_SUB)}
                                                    color="blue">编辑</Button>
                                                <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenLogModal(text.childMedalId, text.childMedalName, operationTypeValue.SELECT_SUB)}
                                                    color="blue">获得记录</Button>
                                                <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenSendOrRecycleModal(text.childMedalId, operationTypeValue.SEND_SUB)}
                                                    color="blue">赠送</Button>
                                                <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenSendOrRecycleModal(text.childMedalId, operationTypeValue.RECYCLE_SUB)}
                                                    color="blue">回收</Button>
                                            </div>
                                        }
                                    },
                                ]
                            }
                            title={this.renderTableTitle}
                            dataSource={subMedalList.data ? subMedalList.data : []}
                            bordered
                            loading={loading}
                            scroll={{
                                scrollToFirstRowOnChange: true,
                                y: '450px'
                            }}
                            pagination={{
                                total: subMedalList ? subMedalList.total : 0,
                                current: page,
                                size: size,
                                onChange: this.onPageChangeEvent,
                                showTotal:(total)=>{
                                    return `总计 ${total}条数据`;
                                }
                            }}
                        />
                    </Card>
                    {operationType === operationTypeValue.UPDATE_SUB &&
                        <SubMedalForm
                            visible={showEditSubMedalModal}
                            {...subMedalData}
                            onSubmitEvent={this.onEditSubMedalEvent}
                            onCancel={this.onCloseEditSubMedalEvent}
                        />
                    }
                    {operationType === operationTypeValue.INCREASE &&
                    <SubMedalAddForm
                        visible={showAddSubMedalModal}
                        {...subMedalData}
                        onSubmitEvent={this.onAddSubMedalEvent}
                        onCancel={this.onCloseAddSubMedalEvent}
                    />
                    }
                    {operationType === operationTypeValue.SELECT_SUB &&
                        <SubMedalLog
                            visible={showLogModal}
                            childMedalId={childMedalId}
                            childMedalName={childMedalName}
                            onClose={this.onCloseLogModal}
                        />
                    }
                    {(operationType === operationTypeValue.SEND_SUB || operationType === operationTypeValue.RECYCLE_SUB) &&
                        <SubMedalSendOrRecycle
                            visible={showSendOrRecycleModal}
                            {...subMedalData}
                            onSubmitEvent={this.onSubMedalSendOrRecycleEvent}
                            onCancel={this.onCloseSendOrRecycleModal}
                        />
                    }
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Medal
    }
};
const mapDispatchToProps = ({
    getSubMedalList,
    getSubMedalData,
    updateSubMedalData,
    addSubMedalData,
    updateSubMedalSend,
    updateSubMedalRecycle
});
SubMedal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SubMedal)));
