import React from 'react';
import {Button,Form, Input, Modal,DatePicker,Select, notification,Image ,Icon} from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import Map from "./Map"
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class ActivityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activityType:this.props.data.activityType,
            isSignIn:this.props.data.needSignIn,
            isShowMap:false,
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let activityAddress = '';
                const diff1 = moment(values.endTime).diff(moment(values.startTime))
                if(diff1<0){
                    notification.error({message:'结束时间不能早于开始时间，请重新选择时间'})
                    return;
                }
                if(values.activityType==='OFFLINE'){
                    activityAddress = values.activityAddress
                }
                let val = {
                    ...values,
                    startTime:values.startTime?moment(values.startTime).format('YYYY-MM-DD HH:mm:00'):'',
                    endTime:values.endTime?moment(values.endTime).format('YYYY-MM-DD HH:mm:00'):'',
                    userShowTime:values.userShowTime?moment(values.userShowTime).format('YYYY-MM-DD HH:mm:00'):'',
                    vipShowTime:values.vipShowTime?moment(values.vipShowTime).format('YYYY-MM-DD HH:mm:00'):'',
                    activityAddress
                }
                
               onSuccess && onSuccess(val,this.props.type);
            }
        });
    }
    
    onChange(value, dateString) {

    }


    onOk(value) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,type,data,gameList,typeList} = this.props
        let{activityType,isSignIn,isShowMap} = this.state;
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title="添加活动"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form>
                    <FormItem label="活动开始时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('startTime', {
                                rules: [{required: true, message: '请输入活动开始时间'}],
                                initialValue:type!=='ADD'?moment(data.startTime,dateFormat):moment()
                                
                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                            
                        )}
                    </FormItem>
                    <FormItem label="活动结束时间"
                        {...formItemLayout}
                        >
                        {getFieldDecorator('endTime', {
                                rules: [{required: true, message: '请输入活动结束时间'}],
                                initialValue:type!=='ADD'?moment(data.endTime,dateFormat):moment()
                            }
                        )(
                            <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                        )}
                    </FormItem>
                    <FormItem
                        label="活动标题"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('activityTitle', {
                            rules: [{required: true, message: '请输入活动标题'}],
                            initialValue:type!=='ADD'?data.activityTitle:''
                        })(
                            <Input placeholder="请输入活动标题"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="活动负责人展示ID"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('principalExhibitionId', {
                            initialValue:type!=='ADD'?data.principalExhibitionId:''
                        })(
                            <Input placeholder="请输入活动负责人展示ID"/>
                        )}
                    </FormItem>
                    <FormItem label="活动类型:"
                        {...formItemLayout}
                        
                    >
                         {getFieldDecorator('activityType', {
                            initialValue:type!=='ADD'?data.activityType:'',
                            rules: [{required: true, message: '请选择活动类型'}],
                        })(
                            <Select style={{width: 120}} onChange = {(v)=>{
                                    this.setState({activityType:v})
                                
                            }}>
                                {
                                    typeList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.description}</Option>
                                        )
                                    })
                                }
                            </Select>,
                        )}
                    </FormItem>
                    {
                        activityType==='OFFLINE'&&
                        <FormItem
                            label="活动地点"
                            {...formItemLayout}
                        
                        >
                            {getFieldDecorator('activityAddress', {
                                rules: [{required: true, message: '请输入活动地点'}],
                                initialValue:type!=='ADD'?data.activityAddress:''
                            })(
                                <Input placeholder="请输入活动地点"/>
                            )}
                        </FormItem>
                    }
                    {
                        activityType==='OFFLINE'&& <FormItem 
                            label="是否需要签到打卡:"
                            {...formItemLayout}
                            style={{display:"flex"}}
                        >
                            {getFieldDecorator('needSignIn', {
                                initialValue:type!=='ADD'?data.needSignIn:false,
                                rules: [{required: true, message: '请选择是否需要签到打卡'}],
                            })(
                                <Select style={{width: 120}} onChange={this.onChangeJumpType}>
                                    <Option value={true} >是</Option>
                                    <Option value={false} >否</Option>
                                </Select>
                            )}
                            <Button disabled={!isSignIn} type="primary" onClick={()=>{this.setState({ isShowMap : true });}} >
                                <Icon type="environment" />
                                设置活动地点经纬度
                            </Button>
                        </FormItem>
                    }
                    {
                        activityType==='OFFLINE'&& <FormItem
                            label="活动地点经度"
                            {...formItemLayout}
                        
                        >
                            {getFieldDecorator('longitude', {
                                rules: [{required: isSignIn, message: '请设置活动地点经度'}],
                                initialValue:type!=='ADD'?data.longitude:''
                            })(
                                <Input disabled placeholder="活动地点经度"/>
                            )}
                        </FormItem>
                    }
                    {
                        activityType==='OFFLINE'&& <FormItem
                             label="活动地点纬度"
                            {...formItemLayout}
                        
                        >
                            {getFieldDecorator('latitude', {
                                rules: [{required: isSignIn, message: '请设置活动地点纬度'}],
                                initialValue:type!=='ADD'?data.latitude:''
                            })(
                                <Input disabled placeholder="活动地点纬度"/>
                            )}
                        </FormItem>
                    }
                     {
                        activityType==='OFFLINE'&& <FormItem
                            label="签到打卡范围"
                            {...formItemLayout}
                        
                        >
                            {getFieldDecorator('distance', {
                                rules: [{required: isSignIn, message: '请设置允许用户定位打卡的距离范围(单位：米)'}],
                                initialValue:type!=='ADD'?data.distance:''
                            })(
                                <Input disabled={!isSignIn} placeholder="允许用户定位打卡的距离范围(单位：米)"/>
                            )}
                        </FormItem>
                    }
                    <FormItem label="游戏类型:"
                        {...formItemLayout}
                        
                    >
                         {getFieldDecorator('gameType', {
                            initialValue:type!=='ADD'?data.gameType:'',
                            rules: [{required: true, message: '请选择游戏类型'}],
                        })(
                            <Select style={{width: 120}}>
                                {
                                    gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="活动配图670*380"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: (type!=='ADD'&&data.imageUrl)?data.imageUrl:'',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >
                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="活动介绍"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('introduction', {
                            initialValue:type!=='ADD'?data.introduction:'',
                            rules: [{required: true, message: '请填写活动介绍!'}],
                        })(
                            <TextArea rows={6} placeholder="请填写活动介绍"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="活动链接"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('link', {
                            initialValue:type!=='ADD'?data.link:''
                        })(
                            <Input placeholder="请输入活动链接"/>
                        )}
                    </FormItem>
                    <FormItem label="是否需要报名:"
                        {...formItemLayout}
                        
                    >
                         {getFieldDecorator('needSignUp', {
                            initialValue:type!=='ADD'?data.needSignUp:false,
                            rules: [{required: true, message: '请选择是否需要报名'}],
                        })(
                            <Select style={{width: 120}}>
                                <Option value={true} >是</Option>
                                <Option value={false} >否</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="普通用户查看时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userShowTime', {
                                rules: [{required: true, message: '请输入普通用户查看时间'}],
                                initialValue:type!=='ADD'?moment(data.userShowTime,dateFormat):moment()
                                
                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                            
                        )}
                    </FormItem>
                    <FormItem label="VIP查看时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('vipShowTime', {
                                rules: [{required: true, message: '请输入VIP查看时间'}],
                                initialValue:type!=='ADD'?moment(data.vipShowTime,dateFormat):moment()
                                
                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                            
                        )}
                    </FormItem>
                </Form>
                <Map 
                    onSuccess={this.onSetLatAndLng}
                    visible={isShowMap}
                    onClose={() => {
                        this.setState({
                          isShowMap: false,
                        });
                    }}
                    lng={type!=='ADD'&&this.props.data.longitude}
                    lat={type!=='ADD'&&this.props.data.latitude}
                ></Map>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
ActivityModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(ActivityModal)));
