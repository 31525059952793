import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import AddressForm from './AddressForm';
import ShipForm from './ShipForm';
import GiftOrderFilter from "./GiftOrderFilter";
import { operationTypeValue } from "../../modules/gift/giftEnum";
import { getGiftOrderList, updateGiftOrderAddress, updateGiftOrderShip } from '../../modules/gift/action';
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";
import qs from "qs";

class GiftManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            modalTitle: '',
            showEditOrderModal: false,
        };
    }

    componentDidMount() {
        this.requestGiftOrderList();
    }

    requestGiftOrderList = () => {
        const {page, size} = this.state;
        let { getGiftOrderList } = this.props;
        getGiftOrderList({page, size});
    }

    onPageChangeEvent = (current, size) => {
        let { getGiftOrderList } = this.props;
        let { giftName, userId, giftType, shippingStatus } = this.state;
        this.setState({
            page: current,
            size
        });
        getGiftOrderList({page: current, size, giftName, userId, giftType, shippingStatus});
    }

    onFilterGiftOrderEvent = (params) => {
        const { getGiftOrderList } = this.props;
        const { size } = this.state;
        this.setState({
            ...params,
            page: 1
        });
        getGiftOrderList({page: 1, size, ...params});
    }

    onOpenEditOrderModalEvent = (text, type) => {
        let title = '';
        if(type === operationTypeValue.UPDATE_ADDRESS) {
            title = '编辑收货地址';
        } else if(type === operationTypeValue.UPDATE_SHIP) {
            title = text.shippingStatus ? '编辑物流信息' : '发货';
        }             
        this.setState({
            modalTitle: title,
            orderInfo: { ...text, orderId: text.id, type }
        }, () => {
            this.setState({
                operationType: type,
                showEditOrderModal: true,
            });
        });
    }

    onCloseEditOrderModalEvent = () => {
        this.setState({
            showEditOrderModal: false
        });
    }
    
    // 编辑物流信息
    onEditShipEvent = async (params) => {
        const {orderInfo} = this.state;
        const {updateGiftOrderShip} = this.props;
        try {
            await updateGiftOrderShip(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false
            });
            this.requestGiftOrderList();
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑收货地址
    onEditAddressEvent = async (params) => {
        const {orderInfo} = this.state;
        const {updateGiftOrderAddress} = this.props;
        try {
            await updateGiftOrderAddress(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false,
            });            
            this.requestGiftOrderList();
        } catch (e) {
            console.log(e);
        }
    }
    
    downFile = (res, type) => {
        console.log(res)
        var blob = new Blob([res], {type: 'application/ms-excel    application/x-excel;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = '礼品订单列表.xlsx'; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    };

    // 导出礼品订单列表
    onExport = () =>{
        let {giftName, userId, giftType, shippingStatus} = this.state;
        let param={giftName, userId, giftType, shippingStatus};
        axios({ // 用axios发送post请求
            method: 'get',
            url: '/api/admin/gift/order/list/export?'+qs.stringify(param), // 请求地址
            data: {}, // 参数
            responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
            headers: this.getAuthorizationHeader({})
        }).then(r => {
            this.downFile(r.data)
        }).catch(e=>{
            console.log(e)
        })
    }

    getAuthorizationHeader = (headers) => {
        try {
            let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
            const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
            if (headers && accessToken) {
                headers.Authorization = accessToken.token;
            }
            headers.ContentType = 'application/json';
            return headers;
        } catch (error) {
            return '';
        }
    };
    

    render() {
        const {
            giftOrderList, loading
        } = this.props;
        const {
            showEditOrderModal, page, size, operationType, orderInfo, modalTitle
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <GiftOrderFilter dataSource={{}} onCommit={this.onFilterGiftOrderEvent} onExport={this.onExport}></GiftOrderFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '订单编号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '礼品名称',
                                    dataIndex: 'giftName',
                                    key: 'giftName',
                                    align: 'center',
                                },
                                {
                                    title: '礼品类型',
                                    dataIndex: 'giftType',
                                    key: 'giftType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.giftType === 'IN_KIND_GIFT' ? '实物周边' : ''}
                                            {text.giftType === 'VIP' ? 'VIP会员（虚拟礼品）' : ''}
                                            {text.giftType === 'ENERGY' ? '能量值（虚拟礼品）' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '礼品用途',
                                    dataIndex: 'giftPurpose',
                                    key: 'giftPurpose',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.giftPurpose === 'COMMEMORATIVE_CARD' ? '纪念卡' : 'CDK'}</div>
                                    )
                                },
                                {
                                    title: 'CDK',
                                    dataIndex: 'giftCdk',
                                    key: 'giftCdk',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.giftPurpose === 'COMMEMORATIVE_CARD' ? '纪念卡礼品' : text.giftCdk}</div>
                                    )
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                    
                                },
                                {
                                    title: '兑换时间',
                                    dataIndex: 'exchangeTime',
                                    key: 'exchangeTime',
                                    align: 'center',
                                },
                                {
                                    title: '发货状态',
                                    dataIndex: 'shippingStatus',
                                    key: 'shippingStatus',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.shippingStatus ? '已发货' : '未发货'}</div>
                                    )
                                },
                                                                                        
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        if(text.giftType === 'IN_KIND_GIFT') {
                                            return <div>                                          
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_ADDRESS)}
                                                        color="blue">编辑收货地址</Button>
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_SHIP)}
                                                        color="blue">{text.shippingStatus ? '编辑物流信息' : '发货'}</Button>                                   
                                            </div>
                                        }
                                    }
                                },
                            ]
                        }
                        
                        dataSource={giftOrderList.data ? giftOrderList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: giftOrderList ? giftOrderList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={modalTitle}
                        visible={showEditOrderModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditOrderModalEvent}
                        width={"30%"}
                    >
                        {operationType === operationTypeValue.UPDATE_ADDRESS ?
                            <AddressForm {...orderInfo}
                                        onSubmitEvent={this.onEditAddressEvent}/>
                            : operationType === operationTypeValue.UPDATE_SHIP && <ShipForm {...orderInfo} onSubmitEvent={this.onEditShipEvent}/>}                    
                    </Modal>            
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Gift
        }

    }

);

const mapDispatchToProps = {
    getGiftOrderList, 
    updateGiftOrderAddress,
    updateGiftOrderShip,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(GiftManagement)));
