import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Card, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getAdminOperationLogs
} from "../../modules/admin-operation-log/action";
import AdminOperationLogFilter from "./AdminOperationLogFilter";
import {operateType} from "../../modules/admin-operation-log/enums";
import utils from 'utils/utils';

class AdminOperationLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            adminName: null,
            userName:null,
            userExhibitionId:null,
            type: null,
            startDate: null,
            endDate: null,
        };
    }

    componentDidMount() {
        const {pageNo, pageSize,adminName,userName,userExhibitionId,type,startDate,endDate} = this.state;
        let {getAdminOperationLogs} = this.props;
        getAdminOperationLogs(adminName,userName,userExhibitionId,type,startDate,endDate, pageNo, pageSize);
    }

    onPageChangeEvent = (current, pageSize) => {
        const {adminName,userName,userExhibitionId,type,startDate,endDate} = this.state;
        let {getAdminOperationLogs} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getAdminOperationLogs(adminName,userName,userExhibitionId,type,startDate,endDate, current, pageSize);
    }

    onSearch = ({adminName,userName,userExhibitionId,type,operateTime}) => {
        const {getAdminOperationLogs} = this.props;
        let startDate = null;
        let endDate = null;
        if(operateTime){
            startDate = utils.formatMomentToStr(operateTime[0]);
            endDate = utils.formatMomentToStr(operateTime[1]);
        }
        const {pageSize} = this.state;
        this.setState({
            pageNo: 1,
            startDate,
            endDate,
            adminName,
            userName,
            userExhibitionId,
            type
        })
        getAdminOperationLogs(adminName, userName,userExhibitionId, type,startDate,endDate,1, pageSize)
    }

    render() {
        const {
            adminOperationLogs,
            adminOperationLogsLoading,
            adminOperationLogsTotal
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <AdminOperationLogFilter onSearch={this.onSearch}></AdminOperationLogFilter>
                </Card>
                <Card>
                    <Table

                        columns={
                            [
                                {
                                    title: '管理员名',
                                    dataIndex: 'adminName',
                                    key: 'adminName',
                                    align: 'center',
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                    width:'100px',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'userExhibitionId',
                                    key: 'userExhibitionId',
                                    align: 'center',
                                    width:'100px',
                                },
                                {
                                    title: '操作类型',
                                    key: 'type',
                                    dataIndex: 'type',
                                    align: 'center',
                                    width:'100px',
                                    render: (text) => text ? operateType[text].label : null
                                },
                                {
                                    title: '操作详情',
                                    dataIndex: 'detail',
                                    key: 'detail',
                                    align: 'center',
                                },
                                {
                                    title: "操作时间",
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center'
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={adminOperationLogs}
                        bordered
                        loading={adminOperationLogsLoading}
                        pagination={{
                            total: adminOperationLogsTotal,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (({adminOperationLog:{adminOperationLogs,
        adminOperationLogsLoading,adminOperationLogsTotal}}) => {
        return {
            adminOperationLogs,
            adminOperationLogsLoading,
            adminOperationLogsTotal
        }

    }

);

const mapDispatchToProps = {
    getAdminOperationLogs
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(AdminOperationLog)));
