import React from 'react';
import {
  Form, Input, Button, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { is, fromJS } from 'immutable';
import {
  menuTypes, menuTypeValue,
} from 'modules/view-permission/enums';

const getViewPermissionOptions = (items) => items.map((item) => (
  <Option value={item.id} key={item.id}>{item.name}</Option>
));
const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};

const propTypes = {
  onSuccess: PropTypes.func,
  value: PropTypes.any,
  TreeList: PropTypes.array,
};

const defaultProps = {
  value: {},
  TreeList: [],
};
class InfoModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      defaultValue: null,
    };
  }

    onSubmitEvent = () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let { onSuccess } = this.props;
          onSuccess && onSuccess(values);
        }
      });
    }

    componentWillReceiveProps(nextProps) {
      if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
        this.props.form.resetFields();
        this.onInitData(nextProps);
      }
    }

    onInitData = (props = this.props) => {
      const { value } = props;
      const defaultValue = value || {};
      this.setState({
        type: defaultValue.type,
        defaultValue,
      });
    }

    componentWillMount() {
      this.onInitData();
    }

    renderMenuType = () => {
      const { getFieldDecorator } = this.props.form;
      const { type, defaultValue } = this.state;
      if (type === menuTypeValue.MENU) {
        return (
          <div>
            <FormItem
              label="图标"
              {...formItemLayout}
            >
              {getFieldDecorator('icon', {
                // rules: [{ required: true, message: '请填写图标',pattern:/^\S+$/}],
                initialValue: defaultValue.icon,
              })(
                <Input placeholder="请填写图标" />,
              )}
            </FormItem>
            <FormItem
              label="路由"
              {...formItemLayout}
            >
              {getFieldDecorator('route', {
                rules: [{ required: true, message: '请填写路由名称', pattern: /^\S+$/ }],
                initialValue: defaultValue.route,
              })(
                <Input placeholder="请填写路由" />,
              )}
            </FormItem>
          </div>
        );
      }
    }

    renderButtonType = () => {
      const { getFieldDecorator } = this.props.form;
      const { type, defaultValue } = this.state;
      if (type === menuTypeValue.BUTTON) {
        return (
          <FormItem
            label="code"
            {...formItemLayout}
          >
            {getFieldDecorator('code', {
              rules: [{ required: true, message: '请填写code', pattern: /^\S+$/ }],
              initialValue: defaultValue.code,
            })(
              <Input placeholder="请填写code" />,
            )}
          </FormItem>
        );
      }
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const { value, TreeList } = this.props;
      const defaultValue = value || {};
      return (
        <Form>
          <FormItem
            label="父节点"
            {...formItemLayout}
          >
            {getFieldDecorator('pid', {
              rules: [{ required: true }],
              initialValue: defaultValue.pid,
            })(
              <Select placeholder="请选择所属节点">
                <Option value={0}>根节点</Option>
                {
                        getViewPermissionOptions(TreeList)
                    }
              </Select>,
            )}
          </FormItem>
          <FormItem
            label="类型"
            {...formItemLayout}
          >
            {getFieldDecorator('type', {
              rules: [{ required: true }],
              initialValue: defaultValue.type,
            })(
              <Select
                placeholder="请选择菜单类型"
                onChange={(value) => {
                  this.setState({
                    type: value,
                  });
                }}
              >
                {
                            menuTypes.map((item) => <Option value={item.value} key={item.value}>{item.label}</Option>)
                        }
              </Select>,
            )}
          </FormItem>
          <FormItem
            label="名称"
            {...formItemLayout}
          >
            {getFieldDecorator('name', {
              rules: [{ required: true, message: '请填写菜单名称', pattern: /^\S+$/ }],
              initialValue: defaultValue.name,
            })(
              <Input placeholder="请填写菜单名称" />,
            )}
          </FormItem>
          {this.renderMenuType()}
          {this.renderButtonType()}


          <FormItem>
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
            </div>
          </FormItem>
        </Form>
      );
    }
}

InfoModel.propTypes = propTypes;
InfoModel.defaultProps = defaultProps;
export default Form.create()(InfoModel);
