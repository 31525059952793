import React from 'react';
import {Form, Input, Modal,DatePicker,Select, Button,notification } from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import {operationTypeValue} from '../../../modules/topic/topicEnum';
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddTopicModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                if (values && values.topicImage) {
                    values.topicImage = values.topicImage.split("?")[0];
                }
                if (values && values.topicHostList) {
                    values.topicHostList = values.topicHostList.split(",");
                }
                if (values && values.topicLittleHostList) {
                    values.topicLittleHostList = values.topicLittleHostList.split(",");
                }
                if (values && !values.topicLittleHostList) {
                    values.topicLittleHostList = null;
                }
                if (values && !values.topicHostList) {
                    values.topicHostList = null;
                }
                console.log("values",values);
                onSuccess && onSuccess(values);
            }
        });
    }
    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,data,operationType} = this.props;
        return (
            <Modal
                width={'30%'}
                visible={visible}
                title = {operationType === operationTypeValue.UPDATE ? "编辑话题" : "添加话题"}
                onCancel={this.props.onClose}
                footer={ 
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]
                
            }
            >
                <Form>
                    <FormItem
                        label="话题名字"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('topicName', {
                            rules: [{max: 50, message: '话题名字不得超过50个字符!'},{required: true, message: '请输入话题名字'}],
                            initialValue:operationType!==operationTypeValue.INCREASE?data.topicName:''
                        })(
                            <Input placeholder="请输入话题名字"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="话题头像"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('topicImage', {
                            initialValue: operationType!==operationTypeValue.INCREASE?data.topicIcon:'',
                            rules: [{required: true, message: '请上传话题头像'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >
                                
                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="话题TOP图"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('topicTopImage', {
                            initialValue: operationType!==operationTypeValue.INCREASE?data.topicTopImage:'',
                            rules: [{required: true, message: '请上传话题TOP图'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={1001}
                                maxCount={1}
                            >
                                
                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="话题简介"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('introduction', {
                            initialValue:operationType!==operationTypeValue.INCREASE?data.introduction:'',
                            rules: [{max: 500, message: '话题简介不得超过500个字符!'},{required: true, message: '请填写话题简介'}],
                        })(
                            <TextArea rows={6} placeholder="请填写话题简介"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="主持人展示ID"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('topicHostList', {
                            rules: [{ message: '请输入主持人展示ID'}],
                            initialValue:operationType!==operationTypeValue.INCREASE?data.topicHost:null
                        })(
                            <Input placeholder="请输入主持人展示ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="小主持人展示ID"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('topicLittleHostList', {
                            initialValue:operationType!==operationTypeValue.INCREASE?data.topicLittleHost:null
                        })(
                            <Input placeholder="请输入小主持人展示ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="推荐权重"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('weight', {
                            initialValue:operationType!==operationTypeValue.INCREASE?data.weight:null
                        })(
                            <Input placeholder="请输入推荐权重" type="number"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
AddTopicModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddTopicModel)));
