import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, Modal
} from 'antd';
import UpLoad from "../../components/upload";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class PlayerPostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillMount(){

    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if(!err) {
                let { onSubmitEvent, id, relatedTopicId } = this.props;

                values['dynamicType'] = 'GRAPHIC';
                values['playerId'] = id;
                values['topicId'] = relatedTopicId;
                delete values.playerUserId;
                delete values.relatedTopicName;

                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            title, visible, onCancel, playerUserId, relatedTopicName
        } = this.props;
        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                title={title || ''}
                visible={visible}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"35%"}
            >
                <Form>
                    <FormItem
                        label="选手ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerUserId', {
                            initialValue: ''
                        })(
                            <div>{playerUserId || ''}</div>
                        )}
                    </FormItem>
                    <FormItem
                        label="所属话题"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('relatedTopicName', {
                            initialValue: ''
                        })(
                            <div>{relatedTopicName || ''}</div>
                        )}
                    </FormItem>
                    <FormItem
                        label="图片(355*177)"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrlList', {
                            initialValue: []
                        })(
                            (<UpLoad
                                double={true}
                                // value={imageUrlList}
                                //图片格式限制
                                // width = {690}
                                // height = {240}
                                size={501}
                            />),
                        )}
                    </FormItem>
                    <FormItem
                        label="帖子内容"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('content', {
                            initialValue: '',
                            rules: [{max: 500, message: '帖子内容不得超过500个字符'},{required: true, message: '请输入帖子内容'}],
                        })(
                            <TextArea rows={6} placeholder="请输入帖子内容" />,
                        )}
                    </FormItem>

                    <FormItem
                    label="仅VIP会员查询"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('seeStatus', {
                            initialValue: false,
                            rules: [{required: false, message: '请选择是否仅VIP会员查询', pattern: /^\S+$/}],
                        })(
                        <Select placeholder="请选择是否仅VIP会员查询" style={{width: 100}}>
                            <Option value={true}>是</Option>
                            <Option value={false}>否</Option>
                         </Select>
                        )}
                    </FormItem>

                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(PlayerPostForm);
