import React, {Component} from 'react';
import { Form, Input, Select,Modal} from 'antd';
import PropTypes from 'prop-types';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

class AddHonorModal extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(values.awardDate, values.name, values.grade);
            }
        });
    }

    renderTitle = () => {
        const {addFlag} = this.props;
        console.log('this.propsthis.propsthis.props',this.props);
        if (addFlag) {
            return (
                <div>添加选手荣誉纪录</div>
            )
        } else return (
            <div>修改选手荣誉纪录</div>
        )
    }

    render() {
        const {visible, values,awardDate, name, grade} = this.props;
        console.log("values" + values)
        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                onCancel={this.props.onCancel}
                onOk={this.onSubmitEvent}
                okText={"确认"}
                cancelText={"取消"}
                destroyOnClose={true}
                width={"30%"}
            >
                <Form>
                    <FormItem
                        label="比赛时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('awardDate', {
                            rules: [{required: true, message: '请填写比赛时间', pattern: /^\S+$/}],
                            initialValue: values && values.awardDate ? values.awardDate : "",
                        })(
                            <Input placeholder="请填写比赛时间 (时间格式例子：2017-12-13)"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="赛事名称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('name', {
                            rules: [{required: true, message: '请填写赛事名称', pattern: /^\S+$/}],
                            initialValue: values && values.name ? values.name : "",
                        })(
                            <Input placeholder="请填写赛事名称"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="获奖名次"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('grade', {
                            rules: [{required: true, message: '请填写获奖名次', pattern: /^\S+$/}],
                            initialValue: values && values.grade ? values.grade : "",
                        })(
                            <Select style={{width: '100px'}}>
                                <Select.Option value="冠军">冠军</Select.Option>
                                <Select.Option value='亚军'>亚军</Select.Option>
                                <Select.Option value='季军'>季军</Select.Option>
                            </Select>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

AddHonorModal.propTypes = propTypes;

export default Form.create()(AddHonorModal);
