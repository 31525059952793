import createReducer from 'utils/createReducer';

import {
    GET_GAME
} from './actionTypes'

const defaultState = () => ({
    gameList:{
    }//所有游戏
});

const getGame = (state,{payload}) => {
    return {
        ...state,
        gameList:eval(payload.confValue),
    }

};


export default createReducer(defaultState, {
    [`${GET_GAME}_SUCCESS`]: getGame,



});
