
import createAsyncAction from 'utils/createAsyncAction';
import ViewPermissionsRequest from 'request/ViewPermissionsRequest';
import {
  GET_VIEW_PERMISSION_TREE,
  UPDATE_VIEW_PERMISSION_TREE,
  GET_VIEW_PERMISSION_INFO,
  GET_VIEW_PERMISSION_LIST,
  CREATE_VIEW_PERMISSION,
  UPDATE_VIEW_PERMISSION,
  DELETE_VIEW_PERMISSION,
} from './actionTypes';

export const getViewPermissionTree = () => (createAsyncAction(GET_VIEW_PERMISSION_TREE, () => (ViewPermissionsRequest.getPermissionTree())));

export const updateViewPermissionTree = (tree) => (createAsyncAction(UPDATE_VIEW_PERMISSION_TREE, () => (ViewPermissionsRequest.updatePermissionTree(tree))));

export const getViewPermissionInfo = (id) => (createAsyncAction(GET_VIEW_PERMISSION_INFO, () => (ViewPermissionsRequest.getPermission(id))));

export const getViewPermissionList = (viewPermissionType) => (createAsyncAction(GET_VIEW_PERMISSION_LIST, () => (ViewPermissionsRequest.getPermissions(viewPermissionType))));

export const createViewPermission = (params) => (createAsyncAction(CREATE_VIEW_PERMISSION, () => (ViewPermissionsRequest.addPermission(params))));

export const updateViewPermission = (params, id) => (createAsyncAction(UPDATE_VIEW_PERMISSION, () => (ViewPermissionsRequest.updatePermission(params, id))));

export const deleteViewPermission = (id) => (createAsyncAction(DELETE_VIEW_PERMISSION, () => (ViewPermissionsRequest.deletePermission(id))));
