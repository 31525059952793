import React, { Component } from 'react';
import {
  Form, Button, Select,
} from 'antd';


const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};


class AssignRoleForm extends Component {

  onSubmitEvent = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { onSubmitEvent } = this.props;
        onSubmitEvent && onSubmitEvent(values);
      }
    });
  }

  render() {
    let { roles, adminRoles } = this.props;
    const { getFieldDecorator } = this.props.form;
    console.log(adminRoles)
    return (
      <Form layout="horizontal">
        <Form.Item label="角色" {...formItemLayout}>
          {getFieldDecorator('roleCodes', {
            initialValue: adminRoles ? adminRoles.map(({ code }) => (code)) : [],
            rules: [
              {
                required: true, message: '角色不能为空',
              },
            ],
          })(
            <Select mode="multiple">
              {roles.data.map(({ code, name }) => <Option key={code} value={code} >{name}</Option>)}
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(AssignRoleForm);
