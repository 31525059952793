import authentication from 'modules/authentication';
import viewPermission from 'modules/view-permission';
import admin from 'modules/admin';
import viewRole from 'modules/role';
import viewClub from "modules/club"
import viewTraining from "../../modules/training/index"
import Banner from "modules/banner"
import Article from "modules/article"
import viewDynamic from "../../modules/dynamic";
import Comments from "../../modules/comment";
import Topics from "../../modules/topic";
import Lottery from "../../modules/lottery";
import vip from "../../modules/vip";
import viewCoin from "../../modules/coin";
import Payments from "../../modules/pay";
import viewCircle from "../../modules/circle";
import user from "modules/user";
import account from "modules/account";
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Team from 'modules/team';
import Lineup from 'modules/lineup';
import Qrcode from 'modules/qrcode';
import AppContact from '../../modules/app-contact';
import Brand from 'modules/brand';
import Certification from 'modules/certification';
import config from "../../modules/config";
import Report from "../../modules/report"
import ReportNew from "../../modules/report-new"
import viewTeam from 'modules/team';
import sensitivityPlan from '../../modules/small-plan';
import AppStart from '../../modules/startConfig'
import viewBrand from 'modules/brand';
import withdraw from '../../modules/withdraw';
import resume from 'modules/resume';
import models from '../../modules/small'
import AppNotice from '../../modules/appNotice'
import MsgNotice from '../../modules/msgNotice'
import trailPlan from '../../modules/trail-plan'
import adminOperationLog from '../../modules/admin-operation-log'
import PartnerTraining from '../../modules/partner-training'
import PartnerOrder from "../../modules/pratner-order";
import ProfessionalPlayer from "modules/player"


import viewSchedule from "modules/schedule"//行程
import viewGame from "modules/game"//游戏
import viewActivity from "modules/activity"//活动
import viewInformation from "modules/information"//活动
import Gift from "modules/gift";//礼品
import Commemorativecard from "modules/commemorative-card";//纪念卡
import Medal from "modules/medal"; //勋章
import PlayerDetail from 'modules/playerDetail';
import Ticket from "modules/ticket"; //票卷
import Live from "modules/live"; //直播

export default (history) => combineReducers({
    router: connectRouter(history),
    authentication,
    viewRole,
    viewPermission,
    admin,
    viewCircle,
    Banner,
    Article,
    viewDynamic,
    viewClub,
    viewTeam,
    viewBrand,
    Report,
    config,
    withdraw,
    Team,
    Lineup,
    Brand,
    vip,
    viewCoin,
    Certification,
    user,
    AppNotice,
    Payments,
    account,
    resume,
    trailPlan,
    viewTraining,
    MsgNotice,
    Comments,
    Topics,
    Lottery,
    adminOperationLog,
    Qrcode,
    sensitivityPlan,
    models,
    AppContact,
    AppStart,
    PartnerTraining,
    PartnerOrder,
    ProfessionalPlayer,

    viewSchedule,//行程
    viewGame,//游戏
    viewActivity,//活动
    viewInformation,//资讯
    ReportNew,
    Gift, //礼品
    Commemorativecard, //纪念卡
    Medal, //勋章
    PlayerDetail, //选手主页
    Ticket,
    Live, //直播
});
