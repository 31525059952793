import React, { Component } from 'react';
import {
  Form, Button, Select, Input,
} from 'antd';


const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};


class AssignBrandForm extends Component {

  onSubmit = () => {
    const {brand:{id} } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { onSubmit } = this.props;
        onSubmit && onSubmit(id,values.authorizedAdminIds);
      }
    });
  }

  render() {
    const { admins, brand:{name,authorizedAdminIds} } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="horizontal">
        <Form.Item
            label="品牌赛事名"
            {...formItemLayout}
        >
          {getFieldDecorator('name', {
            initialValue: name,
          })(
              <Input disabled />,
          )}
        </Form.Item>
        <Form.Item label="授权管理员" {...formItemLayout}>
          {getFieldDecorator('authorizedAdminIds', {
            initialValue: authorizedAdminIds,
          })(
            <Select mode="multiple">
              { admins.map(({ id, username }) => <Option key={id} value={id} >{username}</Option>) }
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={this.onSubmit} type="primary">提交</Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(AssignBrandForm);
