
/**
 * 商品类型
 */
export enum TeamMemberType {
    MANAGER = '经理',
    TRAINING_DIRECTOR= '赛训总监',
    LEADER = "教练",
    CHIEF_LEADER = "领队",
    FORMAL = '正式成员',
    ALTERNATE = '替补成员',
}


/**
 * 枚举转数组
 * @param enums
 */
export const enumToArray = function (objs: any) {
    try {
        let arr = [];
        for (let value in objs) {
            arr.push({
                value,
                name: objs[value]
            });
        }
        return arr;
    } catch (e) {
        return [];
    }
};