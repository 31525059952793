export const GET_TOPICS = 'GET_TOPICS';

export const SET_TOP = 'SET_TOP';

export const UPDATE_TOPIC = 'UPDATE_TOPIC';

export const DELETE_TOPIC = 'DELETE_TOPIC';

export const CREATE_TOPIC = 'CREATE_TOPIC';

export const GET_POST_LIST = 'GET_POST_LIST';

export const GET_POST_DETAIL = 'GET_POST_DETAIL';

export const GET_EMOJI_POST_DETAIL = 'GET_EMOJI_POST_DETAIL';

export const UPDATE_POST = 'UPDATE_POST';

export const UPDATE_POST_SETTING = 'UPDATE_POST_SETTING';

export const GET_EMOJI_POST_LIST = 'GET_EMOJI_POST_LIST';

export const UPDATE_EMOJI_POST_SETTING = 'UPDATE_EMOJI_POST_SETTING';

export const UPDATE_EMOJI_POST = 'UPDATE_EMOJI_POST';

export const UPDATE_POST_LIKE = 'UPDATE_POST_LIKE';

export const UPDATE_POST_HOT = 'UPDATE_POST_HOT';

export const GET_POST_LIKE_CONFIG = 'GET_POST_LIKE_CONFIG';

export const MAJIA_CREATE_POST = 'MAJIA_CREATE_POST';

export const IMPORT_POST_COMMENT = 'IMPORT_POST_COMMENT';
