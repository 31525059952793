import React, { Component } from 'react';
import {
    Form, Input, Button,
} from 'antd';
import md5 from "md5";
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class AdminForm extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                let password = md5(values.password)
                onSubmitEvent && onSubmitEvent(values.username,password,values.telephone,values.mailbox);
            }
        });
    }

    render() {
        let {
            username, telephone, mailbox, password,
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <FormItem
                    label="用户名"
                    {...formItemLayout}
                >
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: '请填写用户名', pattern: /^\S+$/ }],
                        initialValue: username,
                    })(
                        <Input placeholder="请填写用户名" disabled={!!username} />,
                    )}
                </FormItem>
                <FormItem
                    label={ !!username ? "密码(已加密)" : "密码" }
                    {...formItemLayout}
                >
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请填写密码', pattern: /^\S+$/ }],
                        initialValue: password,
                    })(
                        <Input placeholder="请填写密码" disabled={!!username} />,
                    )}
                </FormItem>
                <FormItem
                    label="手机号"
                    {...formItemLayout}
                >
                    {getFieldDecorator('telephone', {
                        rules: [{ required: true, message: '请填写手机号', pattern: /^\S+$/ }],
                        initialValue: telephone,
                    })(
                        <Input placeholder="请填写手机号" />,
                    )}
                </FormItem>
                <FormItem
                    label="邮箱"
                    {...formItemLayout}
                >
                    {getFieldDecorator('mailbox', {
                        rules: [{ required: true, message: '请填写邮箱', pattern: /^\S+$/ }],
                        initialValue: mailbox,
                    })(
                        <Input placeholder="请填写邮箱" />,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(AdminForm);
