import React from 'react';
import {Button, Card, Col, message, notification, Popconfirm, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from '../../modules/app-contact/enums';
import {addAppContact, deleteAppContact, getAppContact, updateAppContact} from "../../modules/app-contact/action";
import AppContactInfoModel from "./components/AppContactInfoModel";
import Zmage from "react-zmage";

class AppContactManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowAppContactInfoModel: false,
            selectValue: null,
            operationType: null,
        };
    }

    componentWillMount() {
        this.onGetAppContactInfoData();
    }

    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onAddAppContactEvent(values);
                break;
            case operationTypeValue.UPDATE:
                    this.onUpdateAppContactEvent(values);
                    break;
            default:
        }
    }

    // 创建
    onAddAppContactEvent = async (params) => {
        const {isShowAppContactInfoModel} = this.state;
        const {addAppContact} = this.props;
        await addAppContact(params);
        await this.onGetAppContactInfoData();
        message.success('新增成功');
        this.setState({isShowAppContactInfoModel: !isShowAppContactInfoModel});
    }

    // 修改
    onUpdateAppContactEvent = async (params) => {
        const {isShowAppContactInfoModel} = this.state;
        const {updateAppContact, appContactInfo} = this.props;
        await updateAppContact(appContactInfo.id, params);
        await this.onGetAppContactInfoData();
        message.success('修改成功');
        this.setState({isShowAppContactInfoModel: !isShowAppContactInfoModel});
    }

    //刷新界面数据
    onGetAppContactInfoData = async () => {
        const {getAppContact} = this.props;
        await getAppContact();
    }

    onBtnClick = (type, value) => {
        console.log(value)
        this.setState({
            operationType: type,
            isShowAppContactInfoModel: true,
            selectValue: value
        });
    }


    //删除
    onDeleteEvent = async (id) => {
        const {deleteAppContact} = this.props;
        try {
            await deleteAppContact(id);
            notification.success({message: '删除成功'});
            this.onGetAppContactInfoData();
        } catch (e) {
            console.log(e);
        }
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE, null)}>新增</Button>
            </Col>
        </Row>
    )

    renderTabPane = () => {
        const {
            appContactInfo,
        } = this.props;
        console.log(appContactInfo)
        let arr = appContactInfo ? [{appContactInfo}] : []
        console.log(arr)
        const {
            isShowAppContactInfoModel, loading, selectValue
        } = this.state;
        return (
            <div>
                <Card style={{width: 'auto'}}>
                    <Table
                        dataSource={arr}
                        title={appContactInfo ? '' : this.renderTableTitle}
                        columns={this.getTableColumns()}
                        bordered
                        loading={loading}
                    />
                    {isShowAppContactInfoModel ? <AppContactInfoModel
                        onSuccess={this.onFormSubmit}
                        visible={isShowAppContactInfoModel}
                        value={selectValue}
                        onClose={() => {
                            this.setState({
                                isShowAppContactInfoModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderTabPane()}
            </div>
        );
    }

    getTableColumns = () => [{
        title: '图标',
        dataIndex: 'appContactInfo.imageUrl',
        key: 'imageUrl',
        align: 'center',
        render: (row, text) => (

                <Zmage id="myImage" src={text.appContactInfo.imageUrl ? text.appContactInfo.imageUrl+'?x-oss-process=image/resize,w_690' : ""} width="auto" height="30%" />

        ),
    }, {
        title: '公众号',
        dataIndex: 'appContactInfo.official',
        align: 'center',
        key: 'official',
    }, {
        title: '官网',
        dataIndex: 'appContactInfo.website',
        align: 'center',
        key: 'website',
    },
        {
            title: '客服QQ',
            dataIndex: 'appContactInfo.serviceQq',
            align: 'center',
            key: 'serviceQq',
        },
        {
            title: '官方邮箱',
            dataIndex: 'appContactInfo.email',
            align: 'center',
            key: 'email',
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            width: 200,
            // fixed: 'right',
            align: 'center',
            render: (row, text) => (
                <div>
                    <Button style={{marginRight: '10px'}} type="primary" onClick={() => this.onBtnClick(operationTypeValue.UPDATE, text.appContactInfo)}>编辑</Button>
                    <Popconfirm title={`确定要删除该联系信息吗?`}
                                onConfirm={() => this.onDeleteEvent(text.appContactInfo.id)}
                                okText="确认" onCancel={this.onEvent} cancelText="取消"
                                style={{backgroundColor: '#ff0000', color: '#fff'}}>
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.AppContact,
    }
};

const mapDispatchToProps = ({
    addAppContact,
    updateAppContact,
    getAppContact,
    deleteAppContact
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(AppContactManagement));
