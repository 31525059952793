import React, { Component } from 'react';
import {
    Form, Input, Button,Select,message,
} from 'antd';

const FormItem = Form.Item;
const { Option } = Select;


const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};


class VipForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecommend:props.recommend||false
        };
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                let { onSubmitEvent } = this.props;
                if (values.amount){
                    if (Math.round(values.amount) !=values.amount){
                        message.error("支付金额只能是整数！")
                        return;
                    }
                }
                if (values.days){
                    if (Math.round(values.days) !=values.days){
                        message.error("获取时长只能是整数！")
                        return;
                    }
                }
                onSubmitEvent && onSubmitEvent(values.packageName,values.packageDetail,values.iosAmount,values.androidAmount,values.days,values.recommend,values.applePayId,values.recommendRemark,values.packageRemark,values.androidDiscountAmount,values.iosDiscountAmount,values.wxLiteAmount,values.wxLiteDiscountAmount,values.isActivity);
            }
        });
    }

    render() {
        let {packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity} = this.props;
        let {isRecommend}= this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <FormItem
                    label="充值包名"
                    {...formItemLayout}
                >
                    {getFieldDecorator('packageName', {
                        rules: [{ required: true, message: '请填写充值包名（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: packageName,
                    })(
                        <Input placeholder="请填写充值包名" />,
                    )}
                </FormItem>
                <FormItem
                    label="充值包详情"
                    {...formItemLayout}
                >
                    {getFieldDecorator('packageDetail', {
                        rules: [{ required: true, message: '请填写充值包详情（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: packageDetail,
                    })(
                        <Input placeholder="请填写充值包详情" />,
                    )}
                </FormItem>
                <FormItem
                    label="苹果支付金额(原价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('iosAmount', {
                        rules: [{ required: true, message: '请填写苹果渠道支付金额（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: iosAmount,
                    })(
                        <Input placeholder="请填写苹果渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="苹果支付金额(优惠价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('iosDiscountAmount', {
                        initialValue: iosDiscountAmount,
                    })(
                        <Input placeholder="请填写苹果渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="安卓支付金额(原价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('androidAmount', {
                        rules: [{ required: true, message: '请填写安卓渠道支付金额（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: androidAmount,
                    })(
                        <Input placeholder="请填写安卓渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="安卓支付金额(优惠价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('androidDiscountAmount', {
                        initialValue: androidDiscountAmount,
                    })(
                        <Input placeholder="请填写安卓渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="小程序支付金额(原价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('wxLiteAmount', {
                        rules: [{ required: true, message: '请填写小程序渠道支付金额（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: wxLiteAmount,
                    })(
                        <Input placeholder="请填写小程序渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="小程序支付金额(优惠价/元)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('wxLiteDiscountAmount', {
                        initialValue: wxLiteDiscountAmount,
                    })(
                        <Input placeholder="请填写小程序渠道支付金额" />,
                    )}
                </FormItem>
                <FormItem
                    label="获得时长(天)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('days', {
                        rules: [{ required: true, message: '请填写获得时长（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: days,
                    })(
                        <Input placeholder="请填写获得时长" />,
                    )}
                </FormItem>
                <FormItem
                    label="是否推荐"
                    {...formItemLayout}
                >
                    {getFieldDecorator('recommend', {
                        rules: [{ required: true, message: '请选择是否推荐', pattern: /^\S+$/ }],
                        initialValue: recommend,
                    })(
                        <Select placeholder="请选择是否推荐" onChange = {(e)=>{
                            this.setState({
                                isRecommend:e
                            })
                        }}>
                            <Option value={true}>推荐</Option>
                            <Option value={false}>不推荐</Option>
                        </Select>
                        ,
                    )}
                </FormItem>
                {isRecommend?<FormItem
                    label="推荐头部标题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('recommendRemark', {
                         rules: [{ required: true, message: '请填写推荐头部标题' }],
                        initialValue: recommendRemark,
                    })(
                        <Input placeholder="请填写推荐头部标题(长度不大于5个字符)" maxLength = {5}/>,
                    )}
                </FormItem>:null}
                <FormItem
                    label="苹果支付套餐ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('applePayId', {
                        rules: [{ required: true, message: '请填写苹果支付套餐ID（不允许空格）', pattern: /^\S+$/ }],
                        initialValue: applePayId,
                    })(
                        <Input placeholder="请填写苹果支付套餐ID" />,

                    )}
                </FormItem>
                <FormItem
                    label="备注"
                    {...formItemLayout}
                >
                    {getFieldDecorator('packageRemark', {
                        initialValue: packageRemark,
                    })(
                        <Input placeholder="请填写备注"  />,
                    )}
                </FormItem>

                <FormItem
                    label="是否添加活动"
                    {...formItemLayout}
                >
                    {getFieldDecorator('isActivity', {
                        initialValue: isActivity,
                    })(
                        <Select placeholder="请选择是否添加活动">
                            <Option value={true}>添加</Option>
                            <Option value={false}>不添加</Option>
                        </Select>,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(VipForm);
