import React, {Component} from "react";
import {Form} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getVip} from "../../../modules/vip/action";
import {vipStateType} from "../../../modules/vip/enums";


class GetVIPModel extends Component {

    constructor(props) {
        super(props);
        this.state = {isYTC: '未开通'}

    }

    componentWillMount() {
        this.onInitData(this.props.userId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.userId !== this.props.userId) {
            this.onInitData(nextProps.userId);
        }
    }

    onInitData = (userId) => {
        this.onGetVip(userId);
    }

    onGetVip = async(userShowId) =>{
        const {getVip} =this.props;
        console.log(userShowId)
        if(userShowId){
            await getVip(userShowId);
            await this.setIsYTC();
        }else{
           this.setState({
               isYTC: '未开通'
           })
        }
    }

    setIsYTC = () =>{
        const {vip} = this.props;
        this.setState({
            isYTC: vip.vipState ? vipStateType[vip.vipState].label : '未开通'
        })
    }

    render() {
        const {isYTC} = this.state;
        return (
            <span>{isYTC}</span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.vip,
    }
};
const mapDispatchToProps = ({
    getVip,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(GetVIPModel)));