import api from '../utils/api';

class PELPlayerRequest {

    /**
     * 获取职业选手列表
     * @returns {*}
     */
    static getPELPlayers(params) {
        return api.get('/admin/user/pel-player/home', params);
    }

    /**
     * 新增职业选手
     * @returns {*}
     */
    static addPELPlayer(params) {
        return api.post('/admin/user/pel-player/home', params);
    }

    /**
     * 删除职业选手
     * @param id
     * @returns {*}
     */
    static deletePELPlayer(id) {
        return api.delete(`/admin/user/pel-player/home/${id}`);
    }

    /**
     * 修改职业选手
     * @returns {*}
     */
    static editPELPlaye(id, params) {
        return api.put(`/admin/user/pel-player/home/${id}`,params);
    }

    /**
     * 查询选手转会记录列表
     * @returns {*}
     */
    static getEvent(userId,page, size) {
        return api.get(`/admin/user/pel-player/${userId}/change-club`, { page, size });
    }

    /**
     * 添加选手转会记录
     * @returns {*}
     */
     static addEvent(userId,changeDate,changeFee,resourcesClubId,targetClubId) {
        let params = {changeDate,changeFee,resourcesClubId,targetClubId};
        return api.post(`/admin/user/pel-player/${userId}/change-club`,params);   
    }

    /**
     * 修改选手转会记录
     * @returns {*}
     */
    static updateEvent(id,changeDate,changeFee,resourcesClubId,targetClubId) {
        let params = {changeDate,changeFee,resourcesClubId,targetClubId};
        return api.put(`/admin/user/pel-player/change-club/${id}`,params);   
    }

    /**
     * 删除选手转会记录
     * @returns {*}
     */
    static deleteEvent(id) {
        return api.delete(`/admin/user/pel-player/change-club/${id}`);
    }
    
    /**
     * 查询选手荣誉记录列表
     * @returns {*}
     */
     static getHonorManageList(userId,page, size) {
        return api.get(`/admin/user/pel-player/${userId}/race-courses`, { page, size });
    }

    /**
     * 添加选手荣誉记录
     * @returns {*}
     */
     static addHonorRecord(userId,awardDate,name,grade) {
         console.log(userId,awardDate,name,grade);
        let params = {awardDate,name,grade};
        return api.post(`/admin/user/pel-player/${userId}/race-courses`,params);
    }

    /**
     * 修改选手荣誉记录
     * @returns {*}
     */
    static updateHonorRecord(id,awardDate,name,grade) {
        console.log(id,awardDate,name,grade)
        let params = {awardDate,name,grade};
        return api.put(`/admin/user/pel-player/race-courses/${id}`,params);   
    }

    /**
     * 删除选手荣誉记录
     * @returns {*}
     */
    static deleteHonorRecord(raceCourseId) {
        return api.delete(`/admin/user/pel-player/race-courses/${raceCourseId}`);
    }

    /**
     * 查询选手职业生涯列表
     * @returns {*}
     */
     static getCareerManageList(userId,page, size) {
        return api.get(`/admin/user/pel-player/${userId}/career`, { page, size });
    }

    /**
     * 添加选手职业生涯
     * @returns {*}
     */
     static addCareerRecord(userId,joinDate,pelLeagueTypes,quirDate,teamShowId) {
         console.log(userId,joinDate,pelLeagueTypes,quirDate,teamShowId);
        let params = {joinDate,pelLeagueTypes,quirDate,teamShowId};
        return api.post(`/admin/user/pel-player/${userId}/career`,params);
    }

    /**
     * 修改选手职业生涯
     * @returns {*}
     */
    static updateCareerRecord(id,joinDate,pelLeagueTypes,quirDate,teamShowId) {
        console.log(id,joinDate,pelLeagueTypes,quirDate,teamShowId)
        let params = {joinDate,pelLeagueTypes,quirDate,teamShowId};
        return api.put(`/admin/user/pel-player/career/${id}`,params);   
    }

    /**
     * 删除选手职业生涯
     * @returns {*}
     */
    static deleteCareerRecord(id) {
        return api.delete(`/admin/user/pel-player/career/${id}`);
    }
}

export default PELPlayerRequest;