import React from 'react';
import {Form, Modal, Table, Card} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import SubMedalLogFilter from './SubMedalLogFilter';
import { getSubMedalLogList } from '../../modules/medal/action';

const defaultProps = {
    visible: false,
    onClose: () => {
    },
};

class subMedal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            loading: false,
            userId: '',
            username: '',
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        const { getSubMedalLogList, childMedalId } = this.props;
        const {page, size} = this.state;
        getSubMedalLogList({page, size, childMedalId});
        this.setState({
            loading: false
        });
    }

    // 分页方法
    onPageChangeEvent = (current, size) => {
        let { getSubMedalLogList, childMedalId } = this.props;
        this.setState({
            page: current,
            size: size,
        });
        getSubMedalLogList({page: current, size, childMedalId});
    }

    // 条件筛选
    onFilterLogEvent = ({userId, username}) => {
        const { getSubMedalLogList, childMedalId } = this.props;
        const { size } = this.state;
        this.setState({
            page: 1,
            userId,
            username
        });
        getSubMedalLogList({page: 1, size, childMedalId, userId, username});
    }

    render() {
        const { visible, childMedalName, subMedalLogList, onClose } = this.props;
        const { page, size, loading } = this.state;
        return (
            <Modal
                visible={visible}
                title={"“" + childMedalName + "勋章”获得记录"}
                onOk={() => {}}
                onCancel={onClose}
                width={"50%"}               
            >
                <div>
                    <Card style={{marginBottom: 10}}>
                        <SubMedalLogFilter dataSource={{}} onCommit={this.onFilterLogEvent}></SubMedalLogFilter>
                    </Card>                                      
                    <Card style={{width: 'auto'}}>
                        <Table                           
                            columns={
                                [
                                    {
                                        title: 'ID',
                                        dataIndex: 'id',
                                        key: 'id',
                                        align: 'center',
                                    },
                                    {
                                        title: '用户ID',
                                        dataIndex: 'userId',
                                        key: 'userId',
                                        align: 'center',
                                    },
                                    {
                                        title: '用户名称',
                                        dataIndex: 'username',
                                        key: 'username',
                                        align: 'center',
                                    },
                                    {
                                        title: '操作时间',
                                        dataIndex: 'operationTime',
                                        key: 'operationTime',
                                        align: 'center',
                                        width: '20%'
                                    },
                                    {
                                        title: '操作类型',
                                        dataIndex: 'operationType',
                                        key: 'operationType',
                                        align: 'center',
                                        render: (row, text) => (
                                            <div>
                                                {text.operationType === 'SEND' ? '管理员赠送' : ''}
                                                {text.operationType === 'RECYCLE' ? '管理员回收' : ''}
                                                {text.operationType === 'SYSTEM' ? '系统发放' : ''}
                                            </div>
                                        )
                                    }                                                                    
                                ]
                            }

                            dataSource={subMedalLogList.data ? subMedalLogList.data : []}
                            bordered
                            loading={loading}
                            scroll={{
                                scrollToFirstRowOnChange: true,
                                y: '450px'
                            }}
                            pagination={{
                                total: subMedalLogList ? subMedalLogList.total : 0,
                                current: page,
                                size: size,
                                onChange: this.onPageChangeEvent,
                                showTotal:(total)=>{
                                    return `总计 ${total}条数据`;
                                }
                            }}
                        />                                            
                    </Card>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Medal
    }
};
const mapDispatchToProps = ({
    getSubMedalLogList
});
subMedal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(subMedal)));
