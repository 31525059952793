
export const CREATE_PARTNER_TEACHER = 'CREATE_PARTNER_TEACHER'; // 新增导师

export const UPDATE_PARTNER_TEACHER = 'UPDATE_PARTNER_TEACHER'; // 修改导师

export const DELETE_PARTNER_TEACHER = 'DELETE_PARTNER_TEACHER'; // 删除导师

export const UPDATE_PARTNER_TEACHER_STATUS = 'UPDATE_PARTNER_TEACHER_STATUS';// 更新导师状态

export const GET_PARTNER_TEACHER = 'GET_PARTNER_TEACHER';// 获取详情

export const GET_PARTNER_TEACHER_LIST = 'GET_PARTNER_TEACHER_LIST';// 查询导师列表

export const CREATE_PARTNER_GOODS = 'CREATE_PARTNER_GOODS'; // 新增陪练商品

export const UPDATE_PARTNER_GOODS = 'UPDATE_PARTNER_GOODS'; // 修改陪练商品

export const DELETE_PARTNER_GOODS = 'DELETE_PARTNER_GOODS'; // 删除陪练商品

export const UPDATE_PARTNER_GOODS_STATUS = 'UPDATE_PARTNER_GOODS_STATUS';// 更新陪练商品状态

export const GET_PARTNER_GOODS = 'GET_PARTNER_GOODS';// 获取商品详情

export const GET_PARTNER_GOODS_LIST = 'GET_PARTNER_GOODS_LIST';// 查询陪练商品列表
