import history from 'app/init/historyInstance';

/**
 * 判断当前路由是否拥有按钮权限
 * @param {*} buttonCode 按钮code值
 * @param {*} pageButtons 权限列表
 */
export default (buttonCode,pageButtons) => {
    const path = history.location.pathname;
    let currentPageButton = pageButtons.find((item) => item.path == path);
    if (currentPageButton) {
        return currentPageButton.buttons.some((button) => button.code == buttonCode);
    }
    return false;
}

