import React from 'react';
import {Button, Card, Col, Form, Input, message, Modal, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {
  getPelLeagueDictionaries,
  getPelTeamRankIntegral,
  getPelTeamRankWeeklyIntegral,
  updatePelTeamRankIntegral, updatePelTeamRankWeeklyIntegral
} from "../../modules/training/action";
import PelDataIntegralFilter from "./components/PelDataIntegralFilter";

const {confirm} = Modal;

class PelDataIntegralManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      size: 10,
      pelLeagueType: null,
      pelLeagueStage: null,
      integralType: 'All',
      edition: false,
      updatePelTeamRankIntegralDate: null,
      updatePelTeamWeeklyRankIntegralDate: null,
    };
  }

  componentWillMount() {
    this.onInitData();
  }

  onInitData = async (props = this.props) => {
    const {getPelLeagueDictionaries, getPelTeamRankIntegral} = props;
    await getPelLeagueDictionaries().then((data) => {
      if(data && data.length == 0)return;
      getPelTeamRankIntegral({page: 1, size: 10, pelLeagueType: data[0].value, pelLeagueStage: data[0].children[0].value})
    }).catch(error => {
    });
  }

  //刷新界面数据
  onGetTableData = () => {
    const {getPelTeamRankIntegral, getPelTeamRankWeeklyIntegral, leagueDicList} = this.props;
    const {integralType, pelLeagueType, pelLeagueStage} = this.state;
    this.setState({
      loading: true,
      updatePelTeamRankIntegralDate: null,
      updatePelTeamWeeklyRankIntegralDate: null,
    })
    if (integralType === 'All') {
      getPelTeamRankIntegral({
        page: this.state.page, size: this.state.pageSize,
        pelLeagueType: pelLeagueType ? pelLeagueType : leagueDicList[0].value,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : leagueDicList[0].children[0].value,
      });
    } else if (this.state.integralType === 'Weekly') {
      getPelTeamRankWeeklyIntegral({
        page: this.state.page, size: this.state.pageSize,
        pelLeagueType: pelLeagueType ? pelLeagueType : leagueDicList[0].value,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : leagueDicList[0].children[0].value,
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.pelTeamRankIntegralList !== this.props.pelTeamRankIntegralList || nextProps.pelTeamRankWeeklyIntegralList !== this.props.pelTeamRankWeeklyIntegralList) {
      this.setState({
        loading: false
      })
    }
  }

  onPageChangeEvent = (current, pageSize) => {
    const {getPelTeamRankIntegral, getPelTeamRankWeeklyIntegral, leagueDicList} = this.props;
    const {integralType, pelLeagueType, pelLeagueStage} = this.state;
    if (integralType === 'All') {
      getPelTeamRankIntegral({
        page: current, size: pageSize,
        pelLeagueType: pelLeagueType ? pelLeagueType : leagueDicList[0].value,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : leagueDicList[0].children[0].value,
      });
    } else if (this.state.integralType === 'Weekly') {
      getPelTeamRankWeeklyIntegral({
        page: current, size: pageSize,
        pelLeagueType: pelLeagueType ? pelLeagueType : leagueDicList[0].value,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : leagueDicList[0].children[0].value,
      });
    }
    this.setState({
      page: current,
      size: pageSize,
      loading: true,
      edition: false,
      updatePelTeamRankIntegralDate: null,
      updatePelTeamWeeklyRankIntegralDate: null,
    });
  }

  onBtnClick = (type) => {
    if (type === true) {
      this.setState({
        edition: type,
      });
    } else {
      let _this = this;
      confirm({
        content: '确认要保存本次修改吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          _this.onSavePelData(type)
        },
        onCancel() {
        },

      });
    }
  }

  onSavePelData = async (type) => {
    const {updatePelTeamRankIntegralDate, updatePelTeamWeeklyRankIntegralDate, integralType} = this.state;
    const {updatePelTeamRankIntegral,updatePelTeamRankWeeklyIntegral} = this.props;
    if(!updatePelTeamRankIntegralDate && !updatePelTeamWeeklyRankIntegralDate)return;
    if (integralType === 'All') {
      await updatePelTeamRankIntegral(updatePelTeamRankIntegralDate).then((data) => {
      }).catch(error => {
      })
    } else if (integralType === 'Weekly') {
      await updatePelTeamRankWeeklyIntegral(updatePelTeamWeeklyRankIntegralDate).then((data) => {
      }).catch(error => {
      })
    }
    await this.onGetTableData()
    await this.setState({
      edition: type,
    });
  }

  onBtnCancelClick = (type) => {
    let _this = this;
    confirm({
      content: '确认要取消编辑吗?',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        _this.setState({
          edition: type,
        });
      },
      onCancel() {
      },

    });
  }

  renderTableTitle = () => (
    <Row type="flex" justify="end">
      <Col>
        <Button type="primary"
                onClick={() => this.onBtnClick(!this.state.edition)}>{this.state.edition ? `保存` : `编辑`}</Button>
        {this.state.edition ? <Button type="primary" style={{marginLeft: 20}}
                                      onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> : null}
      </Col>
    </Row>
  )

  //条件查找动态方法
  onFilterPelDataIntegralEvent = async (pelLeagueType, pelLeagueStage, integralType) => {
    const {size} = this.state;
    const {getPelTeamRankIntegral, getPelTeamRankWeeklyIntegral} = this.props;
    if (integralType === 'All') {
      await getPelTeamRankIntegral({
        page: 1, size: size,
        pelLeagueType: pelLeagueType ? pelLeagueType : null,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : null,
      });
    } else if (integralType === 'Weekly') {
      await getPelTeamRankWeeklyIntegral({
        page: 1, size: size,
        pelLeagueType: pelLeagueType ? pelLeagueType : null,
        pelLeagueStage: pelLeagueStage ? pelLeagueStage : null,
      });
    }
    await this.setState({
      pelLeagueType: pelLeagueType ? pelLeagueType : null,
      pelLeagueStage: pelLeagueStage ? pelLeagueStage : null,
      integralType: integralType ? integralType : null,
      page: 1,
      edition: false,
      updatePelTeamRankIntegralDate: null,
      updatePelTeamWeeklyRankIntegralDate: null,
    })
  }

  render() {
    const {
      pelTeamRankIntegralList, pelTeamRankWeeklyIntegralList
    } = this.props;
    const {
      page, size, loading, integralType
    } = this.state;
    return (
      <div>
        <Card style={{marginBottom: 30}}>
          <PelDataIntegralFilter dataSource={{}} onCommit={this.onFilterPelDataIntegralEvent}/>
        </Card>
        {
          integralType === "All" ? <Card>
            <Table
              dataSource={pelTeamRankIntegralList ? pelTeamRankIntegralList.data : []}
              title={this.renderTableTitle}
              columns={this.getAllTableColumns()}
              bordered
              pagination={{
                total: pelTeamRankIntegralList ? pelTeamRankIntegralList.total : 0,
                current: page,
                pageSize: size,
                onChange: this.onPageChangeEvent,
                showTotal: (total) => {
                  return `总计 ${total}条数据`;
                }
              }}
              loading={loading}
            />
          </Card> : null
        }
        {
          integralType === "Weekly" ? <Card>
            <Table
              dataSource={pelTeamRankWeeklyIntegralList ? pelTeamRankWeeklyIntegralList.data : []}
              title={this.renderTableTitle}
              columns={this.getWeeklyTableColumns(pelTeamRankWeeklyIntegralList.data)}
              bordered
              pagination={{
                total: pelTeamRankWeeklyIntegralList ? pelTeamRankWeeklyIntegralList.total : 0,
                current: page,
                pageSize: size,
                onChange: this.onPageChangeEvent,
                showTotal: (total) => {
                  return `总计 ${total}条数据`;
                }
              }}
              loading={loading}
            />
          </Card> : null
        }
      </div>)
  }


  onChangeTable = (key, row, value) => {
    const {pelTeamRankIntegralList, pelTeamRankWeeklyIntegralList} = this.props;
    let {updatePelTeamRankIntegralDate, integralType, updatePelTeamWeeklyRankIntegralDate} = this.state;
    if (integralType === "All") {
      if (!updatePelTeamRankIntegralDate) {
        updatePelTeamRankIntegralDate = JSON.parse(JSON.stringify(pelTeamRankIntegralList.data));
      }
      updatePelTeamRankIntegralDate[row][key] = value.target.value;
      this.setState({updatePelTeamRankIntegralDate: updatePelTeamRankIntegralDate})
    } else if (integralType === 'Weekly') {
      if (!updatePelTeamWeeklyRankIntegralDate) {
        updatePelTeamWeeklyRankIntegralDate = JSON.parse(JSON.stringify(pelTeamRankWeeklyIntegralList.data));
      }
      updatePelTeamWeeklyRankIntegralDate[row][key] = value.target.value;
      this.setState({updatePelTeamWeeklyRankIntegralDate: updatePelTeamWeeklyRankIntegralDate})
    }
  }

  onChangeWeekScoreList = (key, row, index, value) => {
    const {pelTeamRankWeeklyIntegralList} = this.props;
    let {updatePelTeamWeeklyRankIntegralDate} = this.state;
    if (!updatePelTeamWeeklyRankIntegralDate) {
      updatePelTeamWeeklyRankIntegralDate = JSON.parse(JSON.stringify(pelTeamRankWeeklyIntegralList.data));
    }
    updatePelTeamWeeklyRankIntegralDate[row][key][index] = value.target.value;
    this.setState({updatePelTeamWeeklyRankIntegralDate: updatePelTeamWeeklyRankIntegralDate})
  }

  getAllTableColumns = () => {
    const {getFieldDecorator} = this.props.form;
    return ([
      {
        title: '排名',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
      },
      {
        title: '战队展示ID',
        dataIndex: 'teamShowId',
        key: 'teamShowId',
        align: 'center',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('teamShowId' + row, {
                  initialValue: text.teamShowId ? text.teamShowId : null,
                })(<Input style={{width: 120}}
                                onChange={(e) => this.onChangeTable('teamShowId', row, e)}/>)}
              </Form.Item>
            </div> : text.teamShowId)
        },
      },
      {
        title: '队伍',
        dataIndex: 'teamName',
        align: 'center',
        key: 'teamName',
        render: (row, text) => {
          return (<div><img width={33} height={33} src={text.teamIcon}/><span>{text.teamName}</span></div>)
        }
      },
      {
        title: '总淘汰人数',
        dataIndex: 'generalKillNum',
        align: 'center',
        key: 'generalKillNum',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('generalKillNum' + row, {
                  initialValue: text.generalKillNum,
                  rules: [
                    {
                      required: true,
                      message: `总淘汰人数不能为空.`,
                    },
                  ],
                })(<Input
                  onChange={(e) => this.onChangeTable('generalKillNum', row, e)}/>)}
              </Form.Item>
            </div> : text.generalKillNum)
        },
      },
      {
        title: '吃蛋糕次数',
        dataIndex: 'generalWinCount',
        align: 'center',
        key: 'generalWinCount',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('generalWinCount' + row, {
                  initialValue: text.generalWinCount,
                  rules: [
                    {
                      required: true,
                      message: `吃蛋糕次数不能为空.`,
                    },
                  ],
                })(<Input
                  onChange={(e) => this.onChangeTable('generalWinCount', row, e)}/>)}
              </Form.Item>
            </div> : text.generalWinCount)
        },
      }, {
        title: '吃蛋糕率',
        dataIndex: 'avgWinCount',
        align: 'center',
        key: 'avgWinCount',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('avgWinCount' + row, {
                  initialValue: text.avgWinCount,
                  rules: [
                    {
                      required: true,
                      message: `总积分不能为空.`,
                    },
                  ],
                })(<Input
                  onChange={(e) => this.onChangeTable('avgWinCount', row, e)}/>)}
              </Form.Item>
            </div> : (text.avgWinCount ? (text.avgWinCount * 100).toFixed(2) + '%' : '0%'))
        },
      }, {
        title: '排名分',
        dataIndex: 'generalRankScore',
        align: 'center',
        key: 'generalRankScore',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('generalRankScore' + row, {
                  initialValue: text.generalRankScore,
                  rules: [
                    {
                      required: true,
                      message: `排名分不能为空.`,
                    },
                  ],
                })(<Input
                  onChange={(e) => this.onChangeTable('generalRankScore', row, e)}/>)}
              </Form.Item>
            </div> : text.generalRankScore)
        },
      },
      {
        title: '总积分',
        dataIndex: 'generalScore',
        key: 'generalScore',
        align: 'center',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('generalScore' + row, {
                  initialValue: text.generalScore,
                  rules: [
                    {
                      required: true,
                      message: `总积分不能为空.`,
                    },
                  ],
                })(<Input
                  onChange={(e) => this.onChangeTable('generalScore', row, e)}/>)}
              </Form.Item>
            </div> : text.generalScore)
        },
      }
    ])
  }

  getWeeklyTableColumns = (data) => {
    const {getFieldDecorator} = this.props.form;
    const {integralType} = this.state;
    let colums = [];
    colums = [{
      title: '排名',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
    },
      {
        title: '战队展示ID',
        dataIndex: 'teamShowId',
        key: 'teamShowId',
        align: 'center',
        render: (index, text, row) => {
          return (this.state.edition ?
            <div>
              <Form.Item style={{margin: 0}}>
                {getFieldDecorator('teamShowId' + row, {
                  initialValue: text.teamShowId ? text.teamShowId : null,
                })(<Input style={{width: 120}}
                                onChange={(e) => this.onChangeTable('teamShowId', row, e)}/>)}
              </Form.Item>
            </div> : text.teamShowId)
        },
      },
      {
        title: '队伍',
        dataIndex: 'teamName',
        align: 'center',
        key: 'teamName',
        render: (row, text) => {
          return (<div><img width={33} height={33} src={text.teamIcon}/><span>{text.teamName}</span></div>)
        }
      }];
    if (integralType === 'Weekly' && data.length > 0) {
      data[0].weekScoreList.map((element, e_index) => (
        colums.push({
          title: `第${e_index + 1}周`,
          dataIndex: `weekScoreList${e_index}`,
          key: `weekScoreList${e_index}`,
          align: 'center',
          render: (index, text, row) => {
            return (this.state.edition ?
              <div>
                <Form.Item style={{margin: 0}}>
                  {getFieldDecorator(`weekScoreList${row}${e_index}`, {
                    initialValue: text.weekScoreList[e_index] ? text.weekScoreList[e_index] : 0,
                    rules: [
                      {
                        required: true,
                        message: `总积分不能为空.`,
                      },
                    ],
                  })(<Input
                    onChange={(e) => this.onChangeWeekScoreList('weekScoreList', row, e_index, e)}/>)}
                </Form.Item>
              </div> : text.weekScoreList[e_index] ? text.weekScoreList[e_index] : 0)
          },
        })
      ));
    }
    colums.push({
      title: '总积分',
      dataIndex: 'totalWeekScore',
      key: 'totalWeekScore',
      align: 'center',
      render: (index, text, row) => {
        return (this.state.edition ?
          <div>
            <Form.Item style={{margin: 0}}>
              {getFieldDecorator('totalWeekScore' + row, {
                initialValue: text.totalWeekScore,
                rules: [
                  {
                    required: true,
                    message: `总积分不能为空.`,
                  },
                ],
              })(<Input
                onChange={(e) => this.onChangeTable('totalWeekScore', row, e)}/>)}
            </Form.Item>
          </div> : text.totalWeekScore)
      },
    })
    return (colums)
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.PartnerTraining,
    ...state.Team,
    ...state.Brand,
    ...state.viewTraining,
  }
};

const mapDispatchToProps = ({
  getPelTeamRankIntegral,
  getPelTeamRankWeeklyIntegral,
  updatePelTeamRankIntegral,
  updatePelTeamRankWeeklyIntegral,
  getPelLeagueDictionaries
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PelDataIntegralManagement)));
