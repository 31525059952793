import React, { Component } from 'react';
import {
  Tree, Button, Row, Col, Icon,
} from 'antd';
import PropTypes from 'prop-types';
import { is, fromJS } from 'immutable';

const { TreeNode } = Tree;
const ButtonGroup = Button.Group;
const format = {
  label: 'label',
  key: 'id',
  children: 'children',
};
const state = {
  up: 'up',
  down: 'down',
};

const propTypes = {
  data: PropTypes.array.isRequired, // 默认值
  onSelect: PropTypes.func, // 选中时回调函数
  onChange: PropTypes.func, // 数据改变回调函数
  mobile: PropTypes.bool, // 是否能上下移动
  drop: PropTypes.bool, // 是否能拖拽
  Icon: PropTypes.bool, // 时候需要图标
  IconValue: PropTypes.any, // 默认图标样式
  iconFun: PropTypes.func, // 返回值为图标样式
  fontSize: PropTypes.number, // 字体大小
  minHeight: PropTypes.number, // 最小高度
};
const Style = {
  operation: {
    marginLeft: '10px',
  },
  title: {
    fontSize: '16px',
    fontWidth: 'bold',
  },
  treeBoxBtn: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },

};
const defaultProps = {
  mobile: false,
  drop: false,
  Icon: false,
  IconValue: 'setting',
  fontSize: 16,
  // eslint-disable-next-line react/default-props-match-prop-types
  format,
  minHeight: 550,
};
class MyTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isShowLoading: false,
      checkNode: null,
      TreeData: this.props.data || [], // {key,id}
      format: this.props.format || format,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!is(fromJS(this.props.data), fromJS(nextProps.data))) {
      this.setState({
        TreeData: nextProps.data,
      });
    }
  }

    onSelect = (selectedKeys, info) => {
      const { onSelect } = this.props;
      this.setState({
          checkNode: selectedKeys
      })
      onSelect && onSelect(selectedKeys, info);
    }

    _handleTreeNode = (type) => {
      const { TreeData } = this.state;
      this._handleTreeMobile(TreeData, type);
    }


    _handleTreeMobile = (data, type) => {
      const { checkNode, TreeData, format } = this.state;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of data.entries()) {
        if (value[format.key] == checkNode) {
          // 当移动值未第一个的时候
          const len = type == state.up ? 0 : data.length - 1;// 第一个或者最后一个移动
          const i = type == state.up ? -1 : 1;
          if (key != len) {
            const middle = data[key];
            data[key] = data[key + i];
            data[key + i] = middle;
            this.setState({
              TreeData,
            }, () => {
              const { onChange } = this.props;
              onChange && onChange(data);
            });
            return;
          }
        }
      }
      data.map((item) => {
        if (item[format.children] && item[format.children].length > 0) {
          this._handleTreeMobile(item[format.children], type);
        }
        return null
      });
    }

      onDrop = (info) => {
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const { format } = this.state;

        const loop = (data, key, callback) => {
          // eslint-disable-next-line consistent-return
          data.forEach((item, index, arr) => {
            if (item.id == key) {
              return callback(item, index, arr);
            }
            if (item[format.children]) {
              return loop(item[format.children], key, callback);
            }
          });
        };
        const data = [...this.state.TreeData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
          arr.splice(index, 1);
          dragObj = item;
        });
        if (!info.dropToGap) {
          // Drop on the content
          loop(data, dropKey, (item) => {
            item[format.children] = item[format.children] || [];
            // where to insert 示例添加到尾部，可以是随意位置
            item[format.children].push(dragObj);
          });
        } else if (
          (info.node.props[format.children] || []).length > 0 // Has[format.children]
          && info.node.props.expanded // Is expanded
          && dropPosition === 1 // On the bottom gap
        ) {
          loop(data, dropKey, (item) => {
            item[format.children] = item[format.children] || [];
            // where to insert 示例添加到尾部，可以是随意位置
            item[format.children].unshift(dragObj);
          });
        } else {
          let ar;
          let i;
          // console.log(data,'data')
          loop(data, dropKey, (item, index, arr) => {
            ar = arr;
            i = index;
          });
          if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
          } else {
            ar.splice(i + 1, 0, dragObj);
          }
        }
        // console.log(data,'dayta')
        const { onChange } = this.props;
        onChange && onChange(data);
        this.setState({
          TreeData: data,
        });
      }

      _renderTreeNode = (data, format) => {
        if (data.length == 0) {
          return;
        }
        return (
          data.map((item) => {
            if (item[format.children] && item[format.children].length > 0) {
              return (
                <TreeNode
                  title={this.getNodeTitle(item)}
                  key={item[format.key]}
                >
                  {this._renderTreeNode(item[format.children], format)}
                </TreeNode>
              );
            }
            // console.log(item[format.label],'lab',format.label)
            return (
              <TreeNode
                title={this.getNodeTitle(item)}
                key={item[format.key]}
              />
            );
          })
        );
      }

    getNodeTitle = (item) => {
      const { format } = this.state;
      const { icon, IconValue, iconFun } = this.props;
      const type = iconFun ? iconFun(item) : IconValue;
      return (
        <div>
          {icon ? (
            <span>
              <Icon type={type} />
&nbsp;
            </span>
          ) : null}
          {item[format.label]}
        </div>
      );
    }

    render() {
      const {
        drop, mobile, fontSize, minHeight,
      } = this.props;
      const { format, TreeData } = this.state;
      // console.log(TreeData,'TreeData')
      return (
        <div>
          <Row>
            <Col span={24} style={{ position: 'relative' }}>
              <div className="tree-box" style={{ minHeight }}>
                {TreeData.length > 0 ? (
                  <Tree
                    defaultExpandAll
                    onSelect={this.onSelect}
                    onCheck={this.onCheck}
                    style={{
                      fontSize,
                    }}
                    draggable={drop}
                    blockNode
                    onDrop={this.onDrop}
                  >
                    {this._renderTreeNode(TreeData, format)}
                  </Tree>
                ) : null}
                <div style={Style.treeBoxBtn}>
                  <ButtonGroup>
                    {mobile && <Button onClick={() => this._handleTreeNode('up')}>上移</Button>}
                    {(mobile || drop) && (
                    <Button
                      onClick={
                                            () => {
                                              this.props.submit && this.props.submit(TreeData);
                                            }
                                        }
                      type="primary"
                    >
保存

                    </Button>
                    )}
                    {mobile && <Button onClick={() => this._handleTreeNode('down')}>下移</Button>}
                  </ButtonGroup>
                </div>
                {this.props.children}
              </div>
              {(mobile || drop) && <div style={{ color: 'red', marginTop: '20px' }}>备注：上下移动和拖拽操作修改后必须点击右上角保存，否则无效</div>}
            </Col>
          </Row>

          {/* </Card> */}

        </div>
      );
    }
}


MyTree.defaultProps = defaultProps;
MyTree.propTypes = propTypes;
export default MyTree;
