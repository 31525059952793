import createAsyncAction from 'utils/createAsyncAction';
import {GET_PARTNER_ORDER_LIST, GET_PARTNER_ORDER_DETAIL, DEAL_WITH_ORDER, ADD_PARTNER_ORDER} from './actionTypes';
import PartnerOrderRequest from "../../request/PartnerOrderRequest";


//新增订单
export const addPartnerOrder = (param) => (createAsyncAction(ADD_PARTNER_ORDER,
    () => (PartnerOrderRequest.addPartnerOrder(param))));

//查询订单列表
export const getOrderList = (page,size,userId,userName,teacherId,teacherName,orderState, adminCreate) => (createAsyncAction(GET_PARTNER_ORDER_LIST,
    () => (PartnerOrderRequest.getPartnerOrder(page,size,userId,userName,teacherId,teacherName,orderState, adminCreate))));

//查询订单列表
export const getOrderDetail = (orderId) => (createAsyncAction(GET_PARTNER_ORDER_DETAIL,
    () => (PartnerOrderRequest.getOrderDetail(orderId))));

//处理订单
export const dealWithComplaint = (state,orderId) => (createAsyncAction(DEAL_WITH_ORDER,
    () => (PartnerOrderRequest.dealWithComplaint(state,orderId))));

