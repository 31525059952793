import React, {Component} from 'react';
import {
    Form, Input, Button, Col, Row, notification
} from 'antd';
import UpLoad from "../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const defaultProps = {
    visible: false,
    onClose: () => {
    },
};

class CardPicForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardPicList:null,
        };
    }

    componentWillMount(){
        const { cardPicList } = this.props;
        this.setState({
            cardPicList: cardPicList,
        })
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            let param = [];
            if (!err) {
                let { onSubmitEvent } = this.props;
                let isOver = false;

                Object.keys(values).map(item => {
                    if(values && values[item]['specialNumbers']) {
                        isOver = values[item]['specialNumbers'].split(',').length > 5;
                    }

                    if(values && values[item]["commemorativeCardPic"]){
                        values[item]["commemorativeCardPic"]=values[item]["commemorativeCardPic"].split("?")[0]
                    }

                    if (values[item]) {
                        param.push(values[item]);
                    }
                    return null;
                });

                if(isOver) {
                    notification.error({message: '每张卡片可添加的特殊编号至多5个'}); //默认包含520
                    return;
                }

                onSubmitEvent && onSubmitEvent(param);
            }
        });
    };

    onChangeParamsValue = (index,key, value) => {
        const {cardPicList} = this.state;
        cardPicList[index][key] = value
        this.setState({
            cardPicList: cardPicList
        })
    }

    render() {
        let {cardPicList} = this.state;
        const {getFieldDecorator} = this.props.form;
        const keys = cardPicList;
        const formItems = keys.map((k, index) => {
            return (
                <FormItem
                    style={{display: 'inline-block', width: 'calc(20%)'}}>
                    <Row>
                        <Col>
                            <FormItem
                                label=""
                                {...formItemLayout}
                            >
                                {getFieldDecorator(`${index}.commemorativeCardPic`, {
                                    rules:[{required: true,message: '请上传卡片'}],
                                    initialValue: (k.commemorativeCardPic ? k.commemorativeCardPic+'?x-oss-process=image/resize,w_86' : ""),
                                })(
                                    (<UpLoad
                                        size={501}                          
                                    />),    
                                )}
                            </FormItem>
                            <FormItem
                                label=""
                                {...formItemLayout}
                            >
                                {getFieldDecorator(`${index}.commemorativeCardName`, {
                                    rules: [{ required: true, message: '请输入卡片名称', pattern: /^\S+$/}], //required: true,
                                    initialValue: k.commemorativeCardName,
                                })(
                                    <Input
                                        style={{width: '100px'}} 
                                        onChange={(e) => this.onChangeParamsValue(index, "commemorativeCardName", e.currentTarget.value)}
                                        placeholder="卡片名称"/>,
                                )}
                            </FormItem>
                            <FormItem
                                label=""
                                {...formItemLayout}                                
                            >
                                {getFieldDecorator(`${index}.specialNumbers`, {
                                    rules: [{ message: '编号范围1-9999，以英文逗号分隔', pattern: /^(\d+,?)+$/g}],
                                    initialValue: k.specialNumbers,
                                })(
                                    <Input
                                        style={{width: '150px'}} 
                                        onChange={(e) => this.onChangeParamsValue(index, "specialNumbers", e.currentTarget.value)}
                                        placeholder="特殊编号"/>,
                                )}
                            </FormItem>
                        </Col>
                        {/* <Col span={12}>
                            
                        </Col> */}
                        <FormItem
                                label=""
                                {...formItemLayout}
                                style={{display:"none"}}
                        >
                            {getFieldDecorator(`${index}.id`, {
                                initialValue: k.id,
                            })(
                                <Input 
                                    placeholder="id"/>,
                            )}
                        </FormItem>
                     </Row> 
                </FormItem>
            )
        });
        return (
            <Form>
                {formItems}
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Commemorativecard
    }
};
const mapDispatchToProps = ({

});
CardPicForm.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CardPicForm)));

