
export const accountCoinOperateType = {
  CONSUME: {
    label: '消费',
    value:'CONSUME',
  },
  DEDUCTION: {
    label: '扣除',
    value:'DEDUCTION'
  },
  RECHARGE: {
    label: '充值',
    value:'RECHARGE'
  },
  REWARD: {
    label: '奖励',
    value:'REWARD'
  },

};

export const spendType = {
  VIP: {
    label: '充值VIP',
    value:'VIP',
  },
  RECHARGE: {
    label: '充值浪币',
    value:'RECHARGE'
  },
};

