import createReducer from 'utils/createReducer';
import {GET_PARTNER_ORDER_LIST,GET_PARTNER_ORDER_DETAIL} from './actionTypes';

const defaultState = () => ({
    partnerOrders: {
        data: [],
        total: 0,
    }, // 导师列表
    partnerOrder: {}, // 导师信息
});

const onGetPartnerOrderSuccess = (state, {payload}) => ({
    ...state,
    partnerOrders: payload,
});

const onQueryPartnerDetailSuccess = (state, {payload}) => {
    console.log(payload)
    return{
        ...state,
        partnerOrder: payload,
    }

};


export default createReducer(defaultState, {
    [`${GET_PARTNER_ORDER_LIST}_SUCCESS`]: onGetPartnerOrderSuccess,
    [`${GET_PARTNER_ORDER_DETAIL}_SUCCESS`]: onQueryPartnerDetailSuccess,

});
