import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class OrderFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    // componentDidMount() {
    //     let _self = this;
    //     this.props.form.validateFields((err, values) => {
    //         if (!err) {
    //             let {onCommit} = _self.props;
    //             let {userId,status,dateRange} = values;
    //
    //             console.log(values)
    //
    //             onCommit && onCommit(userId,status,dateRange);
    //         }
    //     });
    // }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId,userName,teacherId,teacherName,orderState} = values;

                console.log(values)

                onCommit && onCommit(values);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource} = this.props;
        const {userId} = dataSource;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline">

                    <FormItem label="学员展示ID">
                        {getFieldDecorator('userId', {
                                initialValue: userId

                            }
                        )(
                            <Input style={{width: '150px'}} allowClear  />
                        )}
                    </FormItem>
                    <FormItem label="学员名">
                        {getFieldDecorator('userName', {
                                initialValue: null

                            }
                        )(
                            <Input style={{width: '150px'}} allowClear  />
                        )}
                    </FormItem>
                    <FormItem label="导师展示ID">
                        {getFieldDecorator('teacherId', {
                                initialValue: ""

                            }
                        )(
                            <Input style={{width: '150px'}} allowClear  />
                        )}
                    </FormItem>
                    <FormItem label="导师名">
                        {getFieldDecorator('teacherName', {
                                initialValue: null

                            }
                        )(
                            <Input style={{width: '150px'}} allowClear  />
                        )}
                    </FormItem>
                    <FormItem label="订单状态">
                        {getFieldDecorator('orderState', {
                                initialValue: ""
                            }
                        )(
                            <Select style={{width: 200}}>
                                <Option value="">全部</Option>
                                <Option value="RELEASE_SIGN_UP">发布报名</Option>
                                <Option value="TEACHING">教学进行中</Option>
                                <Option value="WAIT_TO_ACCOUNT">等待到账</Option>
                                <Option value="COMPLETE_TEACHER">完成教学</Option>
                                <Option value="RELEASE_TIMEOUT">报名超时</Option>
                                <Option value="CHOOSE_TEACHER_TIMEOUT">超时未选择导师</Option>
                                <Option value="UNFINISHED_TEACHER">未完成教学</Option>
                                <Option value="NO_CHOOSE_COMPLETE">导师超时未选择完成教学</Option>
                                <Option value="FIRST_COMPLAINT">申诉退款待导师处理</Option>
                                <Option value="TEACHER_AGREE_REFUND">导师同意退款</Option>
                                <Option value="TEACHER_TIMEOUT_REFUND">导师超时未处理申诉</Option>
                                <Option value="SECOND_COMPLAINT">客服处理申诉</Option>
                                <Option value="SERVICE_AGREE_COMPLAINT">客服同意申诉</Option>
                                <Option value="SERVICE_REFUSED_COMPLAINT">客服拒绝申诉</Option>
                            </Select>
                        )}
                    </FormItem>

                    {/* <FormItem label="马甲订单">
                        {getFieldDecorator('adminCreate', {
                                initialValue: ""
                            }
                        )(
                            <Select style={{width: 70}}>
                                <Option value="">全部</Option>
                                <Option value="true">是</Option>
                                <Option value="false">否</Option>
                            </Select>
                        )}
                    </FormItem> */}
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>

                </Form>
            </div>
        );
    }
}



export default Form.create()(OrderFilter);