
export const vipStateType = {
  EFFECT: {
    label: '生效中',
    value:'EFFECT',
  },
  EXPIRED: {
    label: '已过期',
    value:'EXPIRED'
  },
  NOT_OPEN:{
    label: '未开通',
    value:'NOT_OPEN'
  }
};

export const codeType = {

  VIP_NOT_EXIST:{
    label:"会员账户不存在",
    value:"VIP_NOT_EXIST",
  }

}

export const tradeType = {

  GET:{
    label:"获得",
    value:"GET",
  },
  CONSUME:{
    label:"消费",
    value:"CONSUME",
  },
  ALL:{
    label:"所有",
    value:"ALL",
  },
}
