
import createAsyncAction from 'utils/createAsyncAction';
import LinupRequest from "../../request/LinupRequest";
import {
    GET_LINEUP_LIST,
    CREATE_LINEUP,
    EDIT_LINEUP,
    GET_MEMBER_LIST,
    QUITE_MEMBER,
    ADD_MEMBER,
    UPDATE_MEMBER_SORT,
    UPDATE_MEMBER_INTRODUCTION,
    DELETE_LINEUP
} from './actionTypes';


//添加团队阵容
export const createLineup = (params) => (createAsyncAction(CREATE_LINEUP, () => (LinupRequest.createLineup(params))));
//修改团队阵容
export const editTeamLineup = (params) => (createAsyncAction(EDIT_LINEUP, () => (LinupRequest.editTeamLineup(params))));
//分页查看阵容
export const getLineupList = (params) => (createAsyncAction(GET_LINEUP_LIST, () => (LinupRequest.getLineups(params))));
//分页查询成员
export const getMemberList = (params) => (createAsyncAction(GET_MEMBER_LIST, () => (LinupRequest.getMemberList(params))));
//新增成员
export const addMember = (params) => (createAsyncAction(ADD_MEMBER, () => (LinupRequest.addMember(params))));
//删除成员
export const quiteMember = (id) => (createAsyncAction(QUITE_MEMBER, () => (LinupRequest.quiteMember(id))));
//设置展示顺序
export const updateMemberSort = (params) => (createAsyncAction(UPDATE_MEMBER_SORT, () => (LinupRequest.updateMemberSort(params))));
//设置成员简介
export const updateMemberIntroduction = (params) => (createAsyncAction(UPDATE_MEMBER_INTRODUCTION, () => (LinupRequest.updateMemberIntroduction(params))));
//删除团队阵容
export const deleteTeamLineup = (id) => (createAsyncAction(DELETE_LINEUP, () => (LinupRequest.deleteTeamLineup(id))));
