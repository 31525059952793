
import api from 'utils/api';

class RoleRequest {
  /**
     * 查询视图角色列表
     * @param {Object} params 请求参数
     */
  static async getRoles(parmas) {
    return api.get('/admin/roles', parmas);
  }

  /**
     * 增加角色 默认增加在最后一个
     * @param {Object} params 请求参数
     */
  static async addRole(params) {
    return api.post('/admin/roles', params);
  }

  /**
     * 更新角色信息
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
  static async updateRole(params, id) {
    return api.put(`/admin/roles/${id}`, params);
  }

  /**
     * 删除
     * @param {Number} id
     */
  static async deleteRole(id) {
    return api.delete(`/admin/roles/${id}`);
  }

  /**
     * 获取详情
     * @param {Number} id
     */
  static async getRole(id) {
    return api.get(`/admin/roles/${id}`);
  }

  /**
     * 获取角色对应的权限
     * @param {Number} code
     */
  static async getRolePermissionTree(id) {
    return api.get(`/admin/roles/${id}/view_permission_tree`);
  }

  /**
     * 设置角色对应的权限
     * @param {Number} code
     */
  static async setRolePermissions(params, id) {
    return api.post(`/admin/roles/${id}/role_view_permission_relations`, params);
  }

  /**
     * 设置功能集合
     */
  static async getFuncionPermission() {
    return api.get('/admin/function_permissions');
  }
}

export default RoleRequest;
