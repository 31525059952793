import React, {Component} from "react";
import {Form, Button, Select} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;


class BannerFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {pageType} = values;

                console.log(values)

                onCommit && onCommit(pageType);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {pageType} = values;

                console.log(values)

                onCommit && onCommit(pageType);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="页面">
                    {getFieldDecorator('pageType', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: 150}}>
                            <Option value={null}>全部</Option>
                            <Option value="INDEX">首页</Option>
                            <Option value="OFFICIAL_ACTIVITY">官方活动</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}


export default Form.create()(BannerFilter);
