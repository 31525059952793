import createReducer from 'utils/createReducer';

import {
    GET_GIFT_LIST,
    GET_GIFT_CDK_LIST,
    GET_GIFT_ORDER_LIST
} from './actionTypes';

const defaultState = () => ({
    giftList: [],
    giftCDKList: [],
    giftOrderList: [],
});

const onGetGiftListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            giftList: payload
        }

};

const onGetGiftCDKListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            giftCDKList: payload
        }

};

const onGetGiftOrderListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            giftOrderList: payload
        }

};

export default createReducer(defaultState, {
    [`${GET_GIFT_LIST}_SUCCESS`]: onGetGiftListRequest,
    [`${GET_GIFT_CDK_LIST}_SUCCESS`]: onGetGiftCDKListRequest,
    [`${GET_GIFT_ORDER_LIST}_SUCCESS`]: onGetGiftOrderListRequest,
});
