import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import MedalEditForm from './MedalEditForm';
import MedalFilter from "./MedalFilter";
import SubMedal from './SubMedal';
import { operationTypeValue } from "../../modules/medal/medalEnum";
import { deleteGift } from '../../modules/gift/action';
import { getTicketList, getMedalThemeData, updateMedalThemeData, addTicketData, updateMedalThemeShelf } from '../../modules/ticket/action';
import MedalAddForm from "./MedalAddForm";
import {getGame} from "../../modules/game/action";
const {confirm} = Modal;


class TicketDataManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            activityName: '',
            gameType: '',
            showEditMedalThemeModal: false,
            showAddMedalThemeModal: false,
            themeData: {}, //获取一条勋章主题数据
            medalId: '',
            medalThemeType: '',
            showLevelModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getTicketList } = this.props;
        this.props.getGame();
        getTicketList({page, size});

    }

    onPageChangeEvent = (current, size) => {
        let { getTicketList } = this.props;
        let { activityName, gameType } = this.state;
        this.setState({
            page: current,
            size
        });
        getTicketList({page: current, size, activityName, gameType});
    }

    onFilterMedalThemeEvent = (activityName, gameType) => {
        const { getTicketList } = this.props;
        const { size } = this.state;
        this.setState({
            activityName,
            gameType,
            page: 1
        });
        getTicketList({page: 1, size, activityName, gameType});
    }

    onOpenEditMedalThemeEvent = (text, type) => {
        const { getMedalThemeData } = this.props;
        let medalEnum = text.medalEnum;

        if (type === operationTypeValue.UPDATE) {
            getMedalThemeData(text.id).then((json) => {
                this.setState({
                    themeData: { ...json, medalEnum, type }
                });
            }).catch((err) => {
                this.setState({
                    themeData: { medalEnum, type }
                });
            });
        } else {
            this.setState({
                themeData: { type }
            });
        }

        this.setState({
            operationType: type,
            showEditMedalThemeModal: true
        });
    }

    onCloseEditMedalThemeEvent = () => {
        this.setState({
            showEditMedalThemeModal: false
        });
    }

    onCloseAddMedalThemeEvent = () => {
        this.setState({
            showAddMedalThemeModal: false
        });
    }

    // 编辑勋章
    onEditMedalThemeEvent = async (params) => {
        const {page, size, themeData} = this.state;
        const {updateMedalThemeData, getTicketList} = this.props;
        try {
            await updateMedalThemeData(themeData.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditMedalThemeModal: false,
            });
            getTicketList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 添加券票
    onAddMedalThemeEvent = async (params) => {
        const {page, size, themeData} = this.state;
        const {addTicketData, getTicketList} = this.props;
        try {
            await addTicketData(params);
            notification.success({message: '新增成功'});
            this.setState({
                showAddMedalThemeModal: false,
            });
            getTicketList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onAddEvent = async (params,type) => {
        this.setState({
            operationType:type,
            showAddMedalThemeModal: true,
        });
    }

    //勋章主题上下架-二次确认
    onConfirmMedalThemeShelf = (themeId, shelfStatus) => {
        let _this = this;
        let tipText = shelfStatus ? '上架' : '下架';

        confirm({
            content: '确认要' + tipText + '该勋章主题吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onMedalThemeShelf(themeId, shelfStatus, tipText);
            },
            onCancel() {
            }
        });
    }

    //勋章主题上下架
    onMedalThemeShelf = async (themeId, shelfStatus, tipText) => {
        const { updateMedalThemeShelf, getTicketList } = this.props;
        const { page, size } = this.state;

        try {
            await updateMedalThemeShelf(themeId, { shelveFlag:shelfStatus });
            notification.success({message: tipText + '成功'});
            getTicketList({page, size});
        } catch (e) {
            console.log(e);
        }
    }
    //转换成游戏名字
    changeGameName = (v)=>{
        let {gameList} = this.props;
        let gameName;
        for(let i = 0;i < gameList.length;i++){
            if(gameList[i].code===v){
                gameName = gameList[i].gameName;
                break
            }
        }
        return gameName;
    }

    changeGameStatus =(v)=>{
        if(v == true) {
            return '已上架';
        }
        return "已下架";
    }

    onOpenLevelModal = (medalId, medalThemeType, type) => {
        this.setState({
            medalId,
            medalThemeType
        }, () => {
            this.setState({
                operationType: type,
                showLevelModal: true
            });
        });
    }

    onCloseLevelModal = () => {
        this.setState({
            showLevelModal: false,
            operationType: operationTypeValue.UPDATE
        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onAddEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    render() {
        const {
            medalThemeList, loading,gameList
        } = this.props;
        const {
            showEditMedalThemeModal,showAddMedalThemeModal, page, size, operationType, themeData, showLevelModal, medalId, medalThemeType
        } = this.state;

        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <MedalFilter   gameList={gameList} dataSource={{}} onCommit={this.onFilterMedalThemeEvent}></MedalFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '票券ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '票券名称',
                                    dataIndex: 'activityName',
                                    key: 'activityName',
                                    align: 'center',
                                },
                                {
                                    title: '游戏项目',
                                    dataIndex: 'gameType',
                                    key: 'gameType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <span>{this.changeGameName(text.gameType)}</span>
                                    )
                                },
                                {
                                    title: '票券数量',
                                    dataIndex: 'total',
                                    key: 'total',
                                    align: 'center',
                                },
                                {
                                    title: '剩余数量',
                                    dataIndex: 'remainingNum',
                                    key: 'remainingNum',
                                    align: 'center',
                                },
                                {
                                    title: '过期时间',
                                    dataIndex: 'activityEndTime',
                                    key: 'activityEndTime',
                                    align: 'center',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },

                                {
                                    title: '宝贝状态',
                                    dataIndex: 'shelves',
                                    key: 'shelves',
                                    align: 'center',
                                    render: (row, item) => {
                                        return <span>{this.changeGameStatus(item.shelves)}</span>;
                                    }

                                },

                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditMedalThemeEvent(text, operationTypeValue.UPDATE)}
                                                color="blue">编辑</Button>
                                            {text.shelves == true ? <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onConfirmMedalThemeShelf(text.id,false)}
                                                    color="blue">下架</Button> :
                                            <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onConfirmMedalThemeShelf(text.id,true)}>上架</Button>
                                            }
                                        </div>
                                    }
                                },
                            ]
                        }
                        title={this.renderTableTitle}
                        dataSource={medalThemeList.data ? medalThemeList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: medalThemeList ? medalThemeList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                {operationType === operationTypeValue.UPDATE &&
                    <MedalAddForm
                        visible={showEditMedalThemeModal}
                        data={themeData}
                        type="update"
                        gameList={gameList}
                        onSubmitEvent={this.onEditMedalThemeEvent}
                        onCancel={this.onCloseEditMedalThemeEvent}
                    />
                }
                {operationType === operationTypeValue.INCREASE &&
                <MedalAddForm
                    visible={showAddMedalThemeModal}
                    type="add"
                    gameList={gameList}
                    onSubmitEvent={this.onAddMedalThemeEvent}
                    onCancel={this.onCloseAddMedalThemeEvent}
                />
                }
                {operationType === operationTypeValue.SELECT && <SubMedal
                    visible={showLevelModal}
                    medalThemeId={medalId}
                    medalType={medalThemeType}
                    onClose={this.onCloseLevelModal}
                />}
            </div>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.Ticket,
            ...state.viewGame
        }
    }
);

const mapDispatchToProps = {
    getTicketList,
    getMedalThemeData,
    updateMedalThemeData,
    addTicketData,
    updateMedalThemeShelf,
    deleteGift,
    getGame
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(TicketDataManagement)));
