import React from 'react';
import {Button, Form, Input, message, Modal, Table} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getJoinTeam, trainingNotice} from "../../../modules/training/action";

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const {confirm} = Modal;

class TestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [], // Check here to configure the default column
            loading: false,
            pushContent: '',
            page: 1,
            size: 25,
        };
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    };

    onInputChange = (e) =>{
        console.log(e.currentTarget.value)
        this.setState({
            pushContent: e.currentTarget.value
        })
    }

    onBtnAllPush = async () =>{
        let _this = this
        const {pushContent} = this.state;
        if(!pushContent || !pushContent.trim()){
            message.error('请输入要推送的内容');
            document.getElementById("inputPushContent").focus();
        }else{
            confirm({
                content: '确认要向所有战队推送消息吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    const {data} = _this.props;
                    let teamIds =  data.map(v => v.id.split('_')[0])
                    console.log(teamIds)
                    _this.doTrainingNotice(pushContent, teamIds);
                },
                onCancel() {
                },

            });
        }

    }
    doTrainingNotice= async (pushContent, teamIds) => {
        const {trainingNotice} = this.props;
        let {trainingId} = this.props.match.params;
        message.info('消息推送成功')
        trainingNotice({notictTitle: pushContent, noticeContent: pushContent, trainingId: window.atob(trainingId), teamIds: teamIds})
    }

    onBtnSelectPush = async () =>{
        let _this = this;
        const {pushContent,selectedRowKeys} = this.state;
        console.log(selectedRowKeys)
        if(selectedRowKeys && selectedRowKeys.length < 1 ){
            message.error("未选中推送队伍")
        }else if(!pushContent || !pushContent.trim()){
            message.error('请输入要推送的内容');
            document.getElementById("inputPushContent").focus();
        }else{
            const {teamInfo} = this.props;
            console.log(teamInfo)
            let teamIds =  teamInfo.data.filter((v, i) => selectedRowKeys.includes(i)).map(v=>v.id.split('_')[0]);
            console.log(teamIds)
            confirm({
                content: '确认要向已选战队推送消息吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.doTrainingNotice(pushContent,teamIds);
                },
                onCancel() {
                },

            });
        }
    }


    onPageChangeEvent = async (current, pageSize) => {
        this.state.page = current;
        let {size} = this.state;
        let {getAddTeams} = this.props;
        let {trainingId} = this.props.match.params;
        await getAddTeams({trainingId: window.atob(trainingId),state:"PASS", size:size, page:current});
        this.setState({
            page: current,
            size: pageSize,
            selectedRowKeys: []
        });
    }


    getTableColumns = () => {
        return (
            [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    key: 'id',
                }, {
                title: '战队ID',
                dataIndex: 'showId',
                align: 'center',
                key: 'showId',

            }, {
                title: '战队名',
                dataIndex: 'name',
                align: 'center',
                key: 'name',
            }]
        )
    }



    render() {
        let { visible, joinTeam} = this.props
        const {page, size, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <Modal
                visible={visible}
                title="推送"
                onCancel={this.props.onClose}
                footer={null}
            >
                <div>
                    <card>
                        <Input id={'inputPushContent'} placeholder="请输入要推送的内容" style={{widths: 'auto'}}
                               onChange={(e) => this.onInputChange(e)}/>
                    </card>
                    <card>
                        <div>
                            <div style={{marginBottom: 16, marginTop: 16}}>
                                <Button onClick={() => this.onBtnAllPush()} style={{marginRight: 16}}>
                                    全部推送
                                </Button>
                                <Button type="primary" onClick={() => this.onBtnSelectPush()}>
                                    根据下方选择的队伍推送
                                </Button>
                            </div>
                            <Table rowSelection={rowSelection} columns={this.getTableColumns()}
                                   dataSource={joinTeam.data ? joinTeam.data : []}
                                   pagination={{
                                       total: joinTeam.total ? joinTeam.total : 0,
                                       current: page,
                                       pageSize: size,
                                       onChange: this.onPageChangeEvent,
                                       showTotal:(total)=>{
                                           return `总计 ${total}条数据`;
                                       }
                                   }}
                            />
                        </div>
                    </card>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getJoinTeam, trainingNotice
});
TestModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TestModal)));
