import React from 'react';
import {Button, Card, Col, Form, InputNumber, Modal, Radio, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {
    getPelLeagueDictionaries,
    getPelTeamRankIntegral,
    getPelTeamRankWeeklyIntegral,
    getRankDataConfigBrands,
    getSortFieldDictionaries,
    getTrainingTeamRankList,
    updatePelTeamRankIntegral,
    updatePelTeamRankWeeklyIntegral,
    updateTrainingTeamRankIntegral
} from "../../modules/training/action";
import TrainingDataFilter from "../training-data-player-management/components/TrainingDataFilter";
import moment, {now} from "moment";

const {confirm} = Modal;

class TrainingDataTeamManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            cycle: null,
            yqlBrandType: null,
            dataType: null,
            edition: false,
            updateTrainingTeamRankDate: null,
            sortField: null,
            currentWeek: null,
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const {getPelLeagueDictionaries, getRankDataConfigBrands, getTrainingTeamRankList, getSortFieldDictionaries} = props;
        const startDate = moment(now()).day(1).format('YYYYMMDD'); // 周一日期
        const endDate = moment(now()).day(7).format('YYYYMMDD'); // 周日日期
        await getRankDataConfigBrands().then((data1) => {
            if(data1 && data1.length == 0)return;
            getSortFieldDictionaries({
                leagueBrandType: 'YQL',
                rankType: 'TEAM',
                rankDataType: 'TOTAL',
                brandId: data1[0].id
            }).then((data) => {
                getTrainingTeamRankList({
                    page: 1,
                    size: 10,
                    yqlBrandType: data1[0].id,
                    cycle: `${startDate}-${endDate}`,
                    sort: data[0].value
                })
                this.setState({
                    cycle: `${startDate}-${endDate}`,
                    currentWeek: `${startDate}-${endDate}`,
                    sortField: {key: data[0].key, value: data[0].value},
                    dataType: 'TOTAL',
                    updateTrainingTeamRankDate: null,
                    yqlBrandType: data1[0].id
                })
            }).catch(error => {
            });
        }).catch(error => {
        });
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getTrainingTeamRankList} = this.props;
        const {sortField, cycle, yqlBrandType, page, size} = this.state;
        this.setState({
            loading: true,
            updateTrainingTeamRankDate: null
        })
        getTrainingTeamRankList({
            page: page, size: size, yqlBrandType: yqlBrandType, cycle: cycle, sort: sortField.value
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.trainingTeamRankList !== this.props.trainingTeamRankList) {
            this.setState({
                loading: false
            })
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getTrainingTeamRankList} = this.props;
        const {yqlBrandType, cycle, sortField} = this.state;
        getTrainingTeamRankList({
            page: current,
            size: pageSize,
            yqlBrandType: yqlBrandType,
            cycle: cycle,
            sort: sortField.value
        })
        this.setState({
            page: current,
            size: pageSize,
            loading: true,
            edition: false,
            updateTrainingTeamRankDate: null
        });
    }

    onBtnClick = (type) => {
        if (type === true) {
            this.setState({
                edition: type,
            });
        } else {
            let _this = this;
            confirm({
                content: '确认要保存本次修改吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.onSavePelData(type)
                },
                onCancel() {
                },

            });
        }
    }

    onSavePelData = async (type) => {
        const {updateTrainingTeamRankDate} = this.state;
        const {updateTrainingTeamRankIntegral} = this.props;
        await updateTrainingTeamRankIntegral(updateTrainingTeamRankDate).then((data) => {
        }).catch(error => {
        })
        await this.onGetTableData()
        await this.setState({
            edition: type,
        });
    }

    onBtnCancelClick = (type) => {
        let _this = this;
        confirm({
            content: '确认要取消编辑吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.setState({
                    edition: type,
                });
            },
            onCancel() {
            },

        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary"
                        onClick={() => this.onBtnClick(!this.state.edition)}>{this.state.edition ? `保存` : `编辑`}</Button>
                {this.state.edition ? <Button type="primary" style={{marginLeft: 20}}
                                              onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> : null}
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterTrainingDataIntegralEvent = async (cycle, brandType, dataType) => {
        const {size} = this.state;
        const {getSortFieldDictionaries, getTrainingTeamRankList} = this.props;
        getSortFieldDictionaries({
            leagueBrandType: 'YQL',
            rankType: 'TEAM',
            rankDataType: dataType,
            brandId: brandType
        }).then((data) => {
            getTrainingTeamRankList({
                page: 1, size: size, cycle: cycle, yqlBrandType: brandType, sort: data[0].value
            })
            this.setState({
                yqlBrandType: brandType,
                cycle: cycle,
                sortField: data[0],
                dataType: dataType,
                page: 1,
                edition: false,
                updateTrainingTeamRankDate: null
            })
        })
    }

    onChangeRadio = e => {
        const {sortFieldDicList, getTrainingTeamRankList} = this.props;
        let field = sortFieldDicList.filter(item => item.value === e.target.value);
        getTrainingTeamRankList({
            page: 1,
            size: this.state.size,
            cycle: this.state.cycle, yqlBrandType: this.state.yqlBrandType,
            sort: e.target.value,
            updateTrainingTeamRankDate: null
        })
        this.setState({
            sortField: field.length > 0 ? field[0] : this.state.sortField,
            page: 1
        });
    };

    render() {
        const {
            trainingTeamRankList, sortFieldDicList
        } = this.props;
        const {
            page, size, loading, sortField, cycle, currentWeek
        } = this.state;
        const radioStyle = {
            display: 'block',
            marginBottom: '5px',
            height: '40px',
            width: '180px',
            lineHeight: '40px',
            textAlign: 'center'
        };
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrainingDataFilter dataSource={{...this.props}} onCommit={this.onFilterTrainingDataIntegralEvent}/>
                </Card>
                <Card>
                    <Row>
                        <Col span={3}>
                            <Radio.Group onChange={this.onChangeRadio}
                                         value={sortField ? sortField.value : sortFieldDicList ? sortFieldDicList[0].value : null}
                                         buttonStyle="solid">
                                {sortFieldDicList && sortFieldDicList.map((item) => <Radio.Button style={radioStyle}
                                                                                                  value={item.value}>{item.key}</Radio.Button>)}
                            </Radio.Group>
                        </Col>
                        <Col span={21}>
                            <Table
                                dataSource={trainingTeamRankList ? trainingTeamRankList.data : []}
                                title={cycle === currentWeek ? null : this.renderTableTitle}
                                columns={this.getAllTableColumns()}
                                bordered
                                pagination={{
                                    total: trainingTeamRankList ? trainingTeamRankList.total : 0,
                                    current: page,
                                    pageSize: size,
                                    onChange: this.onPageChangeEvent,
                                    showTotal: (total) => {
                                        return `总计 ${total}条数据`;
                                    }
                                }}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>)
    }


    onChangeTable = (key, row, value) => {
        const {trainingTeamRankList} = this.props;
        let {updateTrainingTeamRankDate} = this.state;
        console.log()
        if (!updateTrainingTeamRankDate) {
            console.log("updateTrainingTeamRankDate")
            updateTrainingTeamRankDate = JSON.parse(JSON.stringify(trainingTeamRankList.data));
        }
        updateTrainingTeamRankDate[row][key] = value;
        this.setState({updateTrainingTeamRankDate: updateTrainingTeamRankDate})
    }

    getAllTableColumns = () => {
        const {getFieldDecorator} = this.props.form;
        const {sortField, dataType} = this.state;
        console.log(sortField)
        let columns = [
            {
                title: '排名',
                dataIndex: 'rank',
                key: 'rank',
                align: 'center',
            },
            {
                title: '战队展示ID',
                dataIndex: 'teamShowId',
                key: 'teamShowId',
                align: 'center',
                // render: (index, text, row) => {
                //     return (this.state.edition ?
                //         <div>
                //             <Form.Item style={{margin: 0}}>
                //                 {getFieldDecorator('teamShowId' + row, {
                //                     initialValue: text.teamShowId ? text.teamShowId : null,
                //                 })(<InputNumber style={{width: 120}}
                //                                 onChange={(e) => this.onChangeTable('teamShowId', row, e)}/>)}
                //             </Form.Item>
                //         </div> : text.teamShowId)
                // },
            },
            {
                title: '战队',
                dataIndex: 'teamName',
                align: 'center',
                key: 'teamName',
                // render: (row, text) => {
                //     return (<div><img width={33} height={33} src={text.teamIcon}/><span>{text.teamName}</span></div>)
                // }
            }];
        if (sortField) {
            columns.push({
                title: `${sortField.key}`,
                dataIndex: `${sortField.value}`,
                key: `${sortField.value}`,
                align: 'center',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator(`${sortField.value}` + row, {
                                    initialValue: text[`${sortField.value}`],
                                    rules: [
                                        {
                                            required: true,
                                            message: `总积分不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable(`${sortField.value}`, row, e)}/>)}
                            </Form.Item>
                            {/*</div> : text[`${sortField.value}`])*/}
                        </div> : dataType === 'AVG' ? (sortField.value === 'avgWinCount' || sortField.value === 'avgTop5Count' || sortField.value === 'avgHighHurtRatio' || sortField.value === 'avgHitRatio' ?
                            text[`${sortField.value}`] ? (text[`${sortField.value}`] * 100).toFixed(2) + '%' : '0%' :
                            text[`${sortField.value}`] ? (text[`${sortField.value}`]).toFixed(2) : 0)
                            : text[`${sortField.value}`])
                },
            })
        }
        return (columns)
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
    }
};

const mapDispatchToProps = ({
    getPelTeamRankIntegral,
    getPelTeamRankWeeklyIntegral,
    updatePelTeamRankIntegral,
    updatePelTeamRankWeeklyIntegral,
    getPelLeagueDictionaries,
    getSortFieldDictionaries,
    getTrainingTeamRankList,
    updateTrainingTeamRankIntegral,
    getRankDataConfigBrands
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingDataTeamManagement)));
