import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, notification, Popconfirm, Table,} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getComments} from "../../modules/comment/action";
import {shieldComment} from "../../modules/dynamic/action";
import CommentFilter from '../activity-comment-management/CommentFilter'
import {getGame} from "../../modules/game/action";

class CommentManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            commentThemeType:"OFFICIAL_ACTIVITY",
            commentUserExhibitionId: "",
            commentUserName:"",
            keyword:"",
            commentStatus: "",
            dateRange: "",
            themeName:"",
            gameType:"",
        };
    }

    componentWillMount(){
        this.props.getGame();
    }

    //转换成游戏名字
    changeGameName = (v)=>{
        let {gameList} = this.props;
        let gameName;
        for(let i = 0;i < gameList.length;i++){
            if(gameList[i].code===v){
                gameName = gameList[i].gameName;
                break
            }
        }
        return gameName;
    }    

    /**
     * 刷新用户列表事件
     */
     onRefreshUsers = () => {
        const {pageNo, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, gameType} = this.state
        const {getComments} = this.props;
        getComments(pageNo, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, gameType);
    }    
    
    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getComments} = this.props;
        let { commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, gameType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getComments(current, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, gameType);    
    }

    //条件查找动态方法
    onFilterCommentEvent = async (commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, gameType) => {
        let {getComments} = this.props;
        const {pageSize,commentThemeType} = this.state;
        this.setState({
            commentUserExhibitionId: commentUserExhibitionId,
            commentUserName:commentUserName,
            keyword:keyword,
            commentStatus: commentStatus,
            dateRange: dateRange,
            themeName:themeName,
            gameType:gameType,
            pageNo: 1
        });
        getComments(1, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, '', false, gameType);    
    };

    //屏蔽/解除屏蔽评论的方法
    onDeleteCommentEvent = async (text) => {
        const { shieldComment } = this.props;
        try {
            await shieldComment({
                commentId:text.id,
                operationType:text.commentStatus=="NORMAL"? "SHIELD":"NORMAL"
            });
            notification.success({ message: `${text.commentStatus === "NORMAL" ? "屏蔽" : "恢复"}成功` });

            this.onRefreshUsers();
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const {
            comments, loading, gameList
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CommentFilter dataSource={{}} onCommit={this.onFilterCommentEvent} gameList={gameList}/>
                </Card>

                <Card>
                    <Table

                        columns={
                            [
                                {
                                    title: '评论ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '标题',
                                    dataIndex: 'themName',
                                    key: 'themName',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '评论内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'gameType',
                                    key: 'gameType',
                                    align: 'center',
                                    width: "20%",
                                    render: (row, text) => (
                                        <span>{this.changeGameName(text.gameType)}</span>
                                    )
                                },
                                {
                                    title: '评论状态',
                                    dataIndex: '',
                                    key: '',
                                    align: 'center',
                                    width: "10%",
                                    render(index, data) {
                                        if (data.commentStatus == "NORMAL") {
                                            return "正常";
                                        } else if(data.commentStatus == "SHIELD"){
                                            return "屏蔽";
                                        }
                                    }
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'commentTime',
                                    key: 'commentTime',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                            {text.commentStatus === "NORMAL" ? 
                                                <Popconfirm title={`确定要屏蔽这条评论吗?`}
                                                    onConfirm={() => this.onDeleteCommentEvent(text)}
                                                    okText="确认"
                                                    cancelText="取消" style={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#fff'
                                                }}>
                                                    <Button type="danger">{text.commentStatus === "NORMAL" ? "屏蔽" : "取消屏蔽"}</Button>
                                                </Popconfirm> :
                                                <Popconfirm title={`确定要恢复这条评论吗?`}
                                                    onConfirm={() => this.onDeleteCommentEvent(text)}
                                                    okText="确认"
                                                    cancelText="取消" style={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#fff'
                                                }}>
                                                    <Button type="primary">{text.commentStatus === "NORMAL" ? "屏蔽" : "取消屏蔽"}</Button>
                                                </Popconfirm> 
                                               }
                                        </div>)

                                    },
                                },
                            ]
                        }
                        dataSource={comments.data ? comments.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: comments ? comments.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.Comments,
            ...state.viewGame
        }
    }
);

const mapDispatchToProps = {
    getComments,
    shieldComment,
    getGame
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CommentManagement)));