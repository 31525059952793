import React, { Component } from 'react';
import { injectIntl } from 'react-intl-context';
import {
  Table, Modal, Button, notification, Card, Row, Col, Tag, Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import './index.scss';
import { withRouter } from 'react-router-dom';
import {
  getAdmins, getAdmin, createAdmin, updateAdmin, deleteAdmin,
  getAdminRoles, assignAdminRoles, unLockAdmin,
} from '../../modules/admin/action';
import {statusType} from 'modules/admin/enums';
import { getViewRoleList } from 'modules/role/action';
import AdminForm from './AdminForm';
import AssignRoleForm from './AssignRoleForm';
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';
import md5 from "md5";
import crypto from 'crypto';
class AdminManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 10,
      isShowEditAdminModal: false,
      isShowCreateAdminModal: false,
      isShowAssignAdminRoleModal: false,
      currentAdminId: null,
    };
  }

  componentDidMount() {
    const { page, size } = this.state;
    const { getAdmins } = this.props;
    getAdmins(page, size);
  }

  renderTableTitle = () => {
    const { pageButtons} = this.props;
    return (
        <Row type="flex" justify="end">
          {whetherButtonPermission(buttonType.CREATE_ADMIN.value, pageButtons) &&
          <Col>
            <Button type="primary" onClick={this.onOpenCreateUserModalEvent}>{buttonType.CREATE_ADMIN.label}</Button>
          </Col>
          }
        </Row>
    );
  }
  // --------页面事件开始---------

  onAssignUserRoleEvent = async ({ roleCodes }) => {
    const { assignAdminRoles } = this.props;
    const { currentAdminId } = this.state;
    try {
      await assignAdminRoles(currentAdminId, roleCodes);
      notification.success({ message: '授权角色成功' });
      this.setState({
        isShowAssignAdminRoleModal: false,
        currentAdminId: null,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onOpenAssignUserRoleModalEvent = async (userId) => {
    const { getViewRoleList, getAdminRoles } = this.props;

    await getViewRoleList();
    await getAdminRoles(userId);

    this.setState({
      isShowAssignAdminRoleModal: true,
      currentAdminId: userId,
    });
  }

  onCloseAssignUserRoleModalEvent = () => {
    this.setState({
      isShowAssignAdminRoleModal: false,
      currentAdminId: null,
    });
  }

  onPageChangeEvent = (current, pageSize) => {
    const {getAdmins} = this.props;
    this.setState({
      page: current,
      size: pageSize,
    });
    getAdmins(current, pageSize);
  }

  onOpenEditUserModalEvent = (userId) => {
    const { getAdmin } = this.props;
    getAdmin(userId);
    this.setState({
      currentAdminId:userId,
      isShowEditAdminModal: true,
    });
  }

  onCloseEditUserModalEvent = () => {
    this.setState({
      isShowEditAdminModal: false,
      currentAdminId:null
    });
  }

  onOpenCreateUserModalEvent = () => {
    this.setState({
      isShowCreateAdminModal: true,
    });
  }

  onCloseCreateUserModalEvent = () => {
    this.setState({
      isShowCreateAdminModal: false,
    });
  }

  onDeleteUserEvent = async (userId) => {
    const { deleteAdmin, getAdmins } = this.props;
    const { page, size } = this.state;
    try {
      await deleteAdmin(userId);
      notification.success({ message: '删除成功' });

      getAdmins(page, size);
    } catch (e) {
      console.log(e);
    }
  }

  onEditUserEvent = async ({ mailbox, telephone }) => {
    const { page, size, currentAdminId } = this.state;
    const { updateAdmin, getAdmins, info } = this.props;
    const { imageUrl } = info;
    try {
      await updateAdmin(currentAdminId, {
        mailbox,
        telephone,
        imageUrl
      });
      notification.success({ message: '更新成功' });
      this.setState({
        isShowEditAdminModal: false,
      });

      getAdmins(page, size);
    } catch (e) {
      console.log(e);
    }
  }

  onCreateUserEvent = async (username,password,telephone,mailbox) => {
    const { page, size } = this.state;
    const { createAdmin, getAdmins } = this.props;
    console.log(password)
    //let newPassword = password !=null ? md5(password) : "";
    let md5 = crypto.createHash("md5");
    md5.update(password);
    let newPassword = md5.digest("hex");
    console.log(newPassword)
    try {
      await createAdmin({username,password,telephone,mailbox});
      notification.success({ message: '创建成功' });
      this.setState({
        isShowCreateAdminModal: false,
      });

      getAdmins(page, size);
    } catch (e) {
      console.log(e);
    }
  }

  async onUnlockAdminListener(id) {
    const { unLockAdmin, getAdmins } = this.props;
    const { page, size } = this.state;
    await unLockAdmin(id);
    notification.success({ message: '解除锁定成功' });

    getAdmins(page, size);
  }

  render() {
    const {
      admins, info, loading, total, roles, adminRoles,
    } = this.props;
    const {
      isShowEditAdminModal, isShowCreateAdminModal, isShowAssignAdminRoleModal, page, size,
    } = this.state;
    return (
        <Card>
          <Table

              columns={
                [
                  {
                    title: '用户名',
                    dataIndex: 'username',
                    key: 'username',
                    align: 'center',
                  },
                  {
                    title: '手机号',
                    dataIndex: 'telephone',
                    key: 'telephone',
                    align: 'center',
                  },
                  {
                    title: '邮箱',
                    dataIndex: 'mailbox',
                    key: 'mailbox',
                    align: 'center',
                  },
                  {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align: 'center',
                    render:(text) => (text ? statusType[text].label : null)
                  },
                  {
                    title: '操作',
                    dataIndex: 'operate',
                    key: 'operate',
                    align: 'center',
                    render: (text, { id,status }) => (
                        <div>
                          {
                            status == statusType.LOCK.value ?
                                whetherButtonPermission(buttonType.UNLOCK_ADMIN.value, this.props.pageButtons) &&
                                <Tag onClick={() => this.onUnlockAdminListener(id)} color="blue">{buttonType.UNLOCK_ADMIN.label}</Tag> : null
                          }
                          <Tag onClick={() => this.onOpenAssignUserRoleModalEvent(id)} color="blue">授权角色</Tag>
                          <Tag onClick={() => this.onOpenEditUserModalEvent(id)} color="blue">修改</Tag>
                          <span style={{display:"inline-block"}}>
                        <Popconfirm  title={`确定要删除该管理员吗？`}
                                     onConfirm={() => this.onDeleteUserEvent(id)}
                                     okText="确认" cancelText="取消"><br/>
                          <Tag color="red">删除</Tag>
                        </Popconfirm>
                      </span>
                        </div>
                    ),
                  },
                ]
              }

              title={
                this.renderTableTitle
              }
              dataSource={admins}
              bordered
              loading={loading}
              pagination={{
                total,
                current: page,
                pageSize: size,
                onChange: this.onPageChangeEvent,
                showTotal:(total)=>{
                  return `总计 ${total}条数据`;
                }
              }}
          />
          <Modal
              title="编辑用户"
              visible={isShowEditAdminModal}
              footer={null}
              destroyOnClose
              onCancel={this.onCloseEditUserModalEvent}
          >
            <AdminForm {...info} onSubmitEvent={this.onEditUserEvent} />
          </Modal>
          <Modal
              title="创建用户"
              visible={isShowCreateAdminModal}
              footer={null}
              destroyOnClose
              onCancel={this.onCloseCreateUserModalEvent}
          >
            <AdminForm onSubmitEvent={this.onCreateUserEvent} />
          </Modal>
          <Modal
              title="授权角色"
              visible={isShowAssignAdminRoleModal}
              footer={null}
              destroyOnClose
              onCancel={this.onCloseAssignUserRoleModalEvent}
          >
            <AssignRoleForm {...{ roles, adminRoles }} onSubmitEvent={this.onAssignUserRoleEvent} />
          </Modal>
        </Card>
    );
  }

  // --------页面事件结束---------

}

const mapStateToProps = ({
                           admin: {
                             admins, adminInfo, adminsLoading, adminsTotal, adminRoles,
                           }, viewRole: { roles }, authentication: { pageButtons },
                         }) => ({
  roles, // 角色列表
  adminRoles, // 用户所拥有的角色
  admins,
  loading: adminsLoading,
  total: adminsTotal,
  info: adminInfo,
  pageButtons,
});

const mapDispatchToProps = {
  getAdmins,
  getAdmin,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  getViewRoleList,
  getAdminRoles,
  assignAdminRoles,
  unLockAdmin
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(AdminManagement)));
