import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class TopicFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
  }

  handleSubmit(e) {
    let {onCommit} = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
        if (!err) {
            onCommit(values.topicName);
        }
    });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div>
          <Form onSubmit={this.handleSubmit} layout="inline">
              <FormItem label="话题名">
                {getFieldDecorator('topicName')(
                    <Input style={{width:200}} allowClear/>
                )}
              </FormItem>
              
              <FormItem>
                  <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                      查询
                  </Button>
              </FormItem>
          </Form>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Lineup,
  }
};
const mapDispatchToProps = ({
});
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TopicFilter)));