import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {map} from 'lodash/fp';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={{textAlign: 'center', position: 'relative', top: '50%', marginTop: '-18px'}}>
                <h1 style={{color: '#1890ff', fontSize: '35px', fontWeight: '500'}}>欢迎使用</h1>
            </div>
        );
    }

}

const mapStateToProps = (state => {
    }
);

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(Welcome)));
