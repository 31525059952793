
export const GET_ADMINS = 'GET_ADMINS';// 获取用户信息列表

export const GET_ADMIN = 'GET_ADMIN';// 获取用户信息

export const GET_ADMIN_AVATAR = 'GET_ADMIN_AVATAR';// 获取用户头像

export const GET_ADMIN_ROLES = 'GET_ADMIN_ROLES';

export const CREATE_ADMIN = 'CREATE_ADMIN';

export const UPDATE_ADMIN = 'UPDATE_ADMIN';

export const UPDATE_ADMIN_AVATAR = 'UPDATE_ADMIN_AVATAR';

export const DELETE_ADMIN = 'DELETE_ADMIN';

export const UNLOCK_ADMIN = 'UNLOCK_ADMIN';

export const ASSIGN_ADMIN_ROLES = 'ASSIGN_ADMIN_ROLES';
