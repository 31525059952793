import React, {Component} from "react";
import {Form, Button, Select, Input} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;


class PlayerFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if(!err) {
                let { onCommit } = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="选手主页名">
                    {getFieldDecorator('playerName', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear />
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(PlayerFilter);
