import createReducer from 'utils/createReducer';
import {
  GET_VIEW_PERMISSION_TREE,
  GET_VIEW_PERMISSION_INFO,
  GET_VIEW_PERMISSION_LIST,
} from './actionTypes';

const defaultState = () => ({
  permissionTree: [],
  permissionList: [],
  permissionInfo: {},
});


const onGetViewPermissionTreeSuccess = (state, { payload }) => ({
  ...state,
  permissionTree: payload,
});

const onGetViewPermissionInfoSuccess = (state, { payload }) => ({
  ...state,
  permissionInfo: payload,
});

const onGetViewPermissionListSuccess = (state, { payload }) => ({
  ...state,
  permissionList: payload,
});


export default createReducer(defaultState, {
  [`${GET_VIEW_PERMISSION_TREE}_SUCCESS`]: onGetViewPermissionTreeSuccess,
  [`${GET_VIEW_PERMISSION_INFO}_SUCCESS`]: onGetViewPermissionInfoSuccess,
  [`${GET_VIEW_PERMISSION_LIST}_SUCCESS`]: onGetViewPermissionListSuccess,
});
