import createAsyncAction from 'utils/createAsyncAction';
import ReportRequest from '../../request/ReportRequestNew' ;
import {
    GET_TOPIC_REPORTS,
    TOPIC_ASSIGN,
    GET_COMMENT_REPORTS,
    COMMENT_ASSIGN,
    GET_USER_REPORTS,
    USER_ASSIGN
} from '../report-new/actionTypes'

const getTopicReports = (params) => (
    createAsyncAction(GET_TOPIC_REPORTS,() => (ReportRequest.getTopicReports(params)))
);

const topicAssign = (id,result) => (
    createAsyncAction(TOPIC_ASSIGN,() => (ReportRequest.topicAssign(id,result)))
)
const getCommentReports = (params) => (
    createAsyncAction(GET_COMMENT_REPORTS,() => (ReportRequest.getCommentReports(params)))
);

const CommentAssign = (id,result) => (
    createAsyncAction(COMMENT_ASSIGN,() => (ReportRequest.commentAssign(id,result)))
)

const getUserReports = (params) => (
    createAsyncAction(GET_USER_REPORTS,() => (ReportRequest.getUserReports(params)))
);

const userAssign = (id,result) => (
    createAsyncAction(USER_ASSIGN,() => (ReportRequest.userAssign(id,result)))
)

export {
    getTopicReports,topicAssign,getCommentReports,CommentAssign,getUserReports,userAssign
}
