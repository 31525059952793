import createReducer from 'utils/createReducer';

import {
    GET_DYNAMICS,GET_COMMENTS
} from './actionTypes';

const defaultState = () => ({
    dynamics: {
        userDetail:{}
    },
    comments : [],
    dynamicsTotal: 0,
    dynamicsLoading: false,
    dynamicsInfo: {},
    adminRoles: [], // 用户所拥有的角色
});




const ongetDynamicRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        dynamics:payload

    }

};

const ongetCommentRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        comments:payload

    }

};


export default createReducer(defaultState, {
    [`${GET_DYNAMICS}_SUCCESS`]: ongetDynamicRequest,
    [`${GET_COMMENTS}_SUCCESS`]: ongetCommentRequest,
});
