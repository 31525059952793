import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, DatePicker, InputNumber
} from 'antd';
import UpLoad from "../../components/upload";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};
const dateFormat = "YYYY-MM-DD HH:mm:00";


class CardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount(){
        
    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                if(values && values.commemorativeCardThemeCoverPic){
                    values.commemorativeCardThemeCoverPic=values.commemorativeCardThemeCoverPic.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            commemorativeCardQuantity, commemorativeCardThemeCoverPic, commemorativeCardThemeIntroduce, commemorativeCardThemeName, difficultyLevel, energyReward, gameType, lotteryStatus, sort, gameList ,isUpdate} = this.props;        
        const {getFieldDecorator} = this.props.form;
        
        return (
            <Form>
                <FormItem
                    label="纪念卡主题名"
                    {...formItemLayout}
                >
                    {getFieldDecorator('commemorativeCardThemeName', {
                        rules: [{required: true, message: '请输入纪念卡主题名', pattern: /^\S+$/}],
                        initialValue: commemorativeCardThemeName,
                    })(
                        <Input placeholder="请输入纪念卡主题名"/>,
                    )}
                </FormItem>
                <FormItem
                    label="游戏类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('gameType', {
                        initialValue: gameList.filter((item) => item.code == gameType).length > 0 ? gameList.filter((item) => item.code == gameType)[0].code : "",
                        rules: [{required: true, message: '请选择游戏类型!'}],
                    })(
                        <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                            {gameList.map((item) => <Select.Option value={item.code}
                                                                key={item.code}>{item.gameName}</Select.Option>)}
                        </Select>,
                    )}
                </FormItem>
                <FormItem
                    label="纪念卡数量"
                    {...formItemLayout}
                >
                    {getFieldDecorator('commemorativeCardQuantity', {
                        rules: [{required: true, message: '请输入纪念卡数量', pattern: /^\S+$/}],
                        initialValue: commemorativeCardQuantity,
                    })(
                        <Input disabled={isUpdate} placeholder="请输入纪念卡数量"/>,
                    )}
                </FormItem>
                <FormItem label="收集难度级别"
                    {...formItemLayout}
                >
                    {getFieldDecorator('difficultyLevel', {
                            rules: [{required: true, message: '请输入收集难度级别'}],
                            initialValue: difficultyLevel
                        }
                    )(
                        <InputNumber max={5} min={0}/>
                    )}
                </FormItem>
                <FormItem label="集齐能量奖励"
                    {...formItemLayout}
                >
                    {getFieldDecorator('energyReward', {
                            rules: [{required: true, message: '请输入集齐能量奖励'}],
                            initialValue: energyReward
                        }
                    )(
                        <Input type='number'placeholder="请输入集齐能量奖励" min={0}/>
                    )}
                </FormItem>
                <FormItem
                    label="是否抽奖"
                    {...formItemLayout}
                >
                    {getFieldDecorator('lotteryStatus', {
                        rules: [{required: true, message: '请输入是否抽奖'}],
                        initialValue: lotteryStatus,
                    })(
                        <Select placeholder="请选择是否抽奖" onChange={this.onChange}>
                            <Option value={true}>是</Option>
                            <Option value={false}>否</Option>
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="纪念卡片主题封面图片"
                    {...formItemLayout}
                >
                    {getFieldDecorator('commemorativeCardThemeCoverPic', {
                            rules: [{required: true, message: '请上传纪念卡片主题封面图片'}],
                            initialValue: commemorativeCardThemeCoverPic ? commemorativeCardThemeCoverPic+'?x-oss-process=image/resize,w_86' : "",
                        }
                    )(
                        <UpLoad
                        double={false}
                        //图片格式限制
                        size={501}
                        maxCount={1}
                    >
                    </UpLoad>
                    )}
                </FormItem>
                <FormItem
                        label="纪念卡介绍"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('commemorativeCardThemeIntroduce', {
                            rule: [{required: true, maxLength: 500, message: '纪念卡介绍不能超过500个字符'}],
                            initialValue: commemorativeCardThemeIntroduce
                        })(
                            <TextArea rows={6} placeholder="纪念卡介绍不能超过500个字符" maxLength={500} />,
                        )}
                    </FormItem>
                <FormItem label="展示序列"
                    {...formItemLayout}
                >
                    {getFieldDecorator('sort', {
                            rules: [{required: true, message: '请输入展示序列'}],
                            initialValue: sort
                        }
                    )(
                        <Input type='number'placeholder="请输入展示序列" min={0}/>
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(CardForm);
