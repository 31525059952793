import api from '../utils/api';


class PlayerDetailRequest {

    /**
     * 获取选手主页列表
     * @param page
     * @param size
     * @param playerName
     * @returns {*}
     */
    static getPlayerDetailList(params) {
        return api.get(`/admin/player`, params);
    }

    /**
     * 获取选手打印照片列表
     * @param page
     * @param size
     * @param playerName
     * @returns {*}
     */
    static getPlayerPhotoOrderList(params) {
        return api.get(`/admin/photo/querylist`, params);
    }

    /**
     * 新增选手主页
     * @param {Object} params 请求参数
     */
     static createPlayerDetail(params) {
        return api.post(`/admin/player`, params);
    }

    /**
     * 编辑选手主页
     * @param {Object} params 请求参数
     */
    static updatePlayerDetailData(id, params) {
        return api.put(`/admin/player/${id}`, params);
    }

    /**
     * 删除选手主页
     * @param id
     * @returns {*}
     */
     static deletePlayerDetail(id) {
        return api.delete(`/admin/player/${id}`);
    }

    /**
     * 发布选手说
     * @param {Object} params 请求参数
     */
     static createPlayerPost(params) {
        return api.post(`/admin/player/postDynamic`, params);
    }

    /**
     * 编辑礼品订单收货地址
     * @param {Object} params 请求参数
     *
     *
     */
    static updatePhotoOrderAddress(orderId, params) {
        return api.put(`admin/photo/editShippmentAddress`, {orderId, ...params});
    }


    /**
     * 礼品订单发货
     * @param {Object} params 请求参数
     */
    static updatePhotoOrderShip(orderId, params) {
        return api.put(`/admin/photo/ship`, {orderId, ...params});
    }

}

export default PlayerDetailRequest;
