import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import {map} from 'lodash/fp';
import moment from "moment";
import {getBrand, getGameMaps} from "../../../modules/training/action";

const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;

class TrainingVideoFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {
            gameMap: '',
            brandId: '',
            startTime: '',
            endTime: '',
            dateRange:[]
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.getBrand();
        this.getGameList();
        this.getGameMaps();
    }

    getBrand = () => {
        let {getBrand} = this.props;
        getBrand({page: 1, size: 100000});
    }

    getGameList = () => {
        let {getGameList} = this.props;
        getGameList();
    }

    getGameMaps = () => {
        const {getGameMaps} = this.props;
        getGameMaps();
    }

    handleSubmit(e) {
        let {onCommit} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {gameMap, brandId, dateRange, videoTheme} = values;
                let gameStartTime, gameEndTime
                if (dateRange && dateRange.length > 0) {
                    let dateRange = map(date => date.format('YYYY-MM-DD'))(values.dateRange);
                    gameStartTime = dateRange[0] ? dateRange[0]+" 00:00:00" : '';
                    gameEndTime = dateRange[1] ? dateRange[1]+" 23:59:59" : '';
                }
                onCommit(gameMap, brandId, gameStartTime, gameEndTime, videoTheme);
            }
        });
    }

    getCurrentYear = () => {
        var date = new Date();

        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        return y + '/' + m;
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {brandData, games, gameMaps} = this.props;

        return (
            <Form onSubmit={this.handleSubmit} layout="inline" style={{width: '100%'}}>

                <FormItem
                    label="视频名称"
                >
                    {getFieldDecorator('videoTheme', {
                        initialValue: '',
                    })(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    label="地图类型"
                >
                    {getFieldDecorator('gameMap', {
                        initialValue: '',
                    })(
                        <Select style={{minWidth: 100}} onChange={e => {
                            this.setState({gameMap: e})
                        }}>
                            <Option value="">全部</Option>
                            {gameMaps && gameMaps.map((item) => <Select.Option value={item.code}
                                                               key={item.code}>{item.mapName}</Select.Option>)}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="赛事类型">
                    {getFieldDecorator('brandId', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{minWidth: 250}} onChange={e => {
                            console.log(e)
                            this.setState({brandId: e})
                        }}>
                            <Option value="">全部</Option>
                            {brandData.data && brandData.data.map(item => {
                                return (
                                    <Option
                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="开始时间">

                    {getFieldDecorator('dateRange', {
                            // initialValue: [moment().subtract('7','day'), moment().subtract('0','day')]
                        }
                    )(
                        <RangePicker
                            onChange={(value, dateString)=>{
                                this.setState({dateRange:value})
                            }}
                            defaultValue={[moment(this.getCurrentYear() + '/01', dateFormat)]}
                            format={dateFormat}
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
    getGameMaps,
    getBrand
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingVideoFilter)));