
export const operationTypeValue = {
    INCREASE: 'INCREASE',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    SELECT: 'SELECT',
    UPDATE_SUB: 'UPDATE_SUB',
    SELECT_SUB: 'SELECT_SUB',
    SEND_SUB: 'SEND_SUB',
    RECYCLE_SUB: 'RECYCLE_SUB'
};
