import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import {getVipPackages,addVipPackage,updateVipPackage,deleteVipPackage} from '../../modules/vip/action';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {operationTypeValue} from "../../modules/coin/coinEnum";
import VipForm from "./VipForm";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';

class VipManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            isShowEditVipModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
            vipInfo:[]
        };
    }

    componentDidMount() {
        const {getVipPackages} = this.props;
        getVipPackages();
    }

    onCloseEditVipModalEvent = () => {
        this.setState({
            isShowEditVipModal: false,
        });
    }

    //添加VIP包的方法
    onAddVIPEvent = async (packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity) => {
        console.log(packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,isActivity)
        const {addVipPackage, getVipPackages} = this.props;
        let patten = /^([0-9]*)?$/;
        if(patten.test(iosAmount).valueOf() === false || patten.test(androidAmount)===false){
            notification.error({message:"金额不能输入小数"});return;
        }
        try {
            await addVipPackage(packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity);
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditVipModal: false,
            });

            getVipPackages();
        } catch (e) {
            console.log(e);
        }
    }

    //修改VIP包的方法
    onEditVipEvent = async (packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity) => {
        const {vipInfo} = this.state;
        const {updateVipPackage, getVipPackages} = this.props;
        let patten = /^([0-9]*)?$/;
        console.log(patten.test(iosAmount) === false)
        if(patten.test(iosAmount).valueOf() === false || patten.test(androidAmount)===false){
            notification.error({message:"金额不能输入小数"});return;
        }
        try {
            await updateVipPackage(vipInfo.id, {
                packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity
            });
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditVipModal: false,
            });

            getVipPackages();
        } catch (e) {
            console.log(e);
        }
    }

    //删除Vip包的方法
    onDeleteVipEvent = async (VipId) => {
        const { deleteVipPackage, getVipPackages } = this.props;
        try {
            await deleteVipPackage(VipId);
            notification.success({ message: '删除成功' });

            getVipPackages();
        } catch (e) {
            console.log(e);
        }
    }

    onOpenEditVipModalEvent = (text,type) => {
        const {getVipPackages} = this.props;
        if (type===operationTypeValue.UPDATE){
            getVipPackages();
            this.setState({
                operationType : operationTypeValue.UPDATE,
                vipInfo : text
            })

        }
        if (type===operationTypeValue.INCREASE){
            this.setState({
                operationType : type
            })
        }
        this.setState({
            isShowEditVipModal: true,
        });
    };

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                {
                    whetherButtonPermission(buttonType.CREATE_VIP_PACKAGE.value, this.props.pageButtons)
                        &&  <Button type="primary" onClick={() => this.onOpenEditVipModalEvent(null,operationTypeValue.INCREASE)}>
                        {buttonType.CREATE_VIP_PACKAGE.label}
                    </Button>
                }
            </Col>
        </Row>
    )

    render() {
        const {
            vipPackages, loading, pageButtons
        } = this.props;
        const {
            operationType,isShowEditVipModal
        } = this.state;
        return (
            <Card>
                <Table
                    columns={
                        [
                            {
                                title: 'ID',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                            },
                            {
                                title: '充值包名',
                                dataIndex: 'packageName',
                                key: 'packageName',
                                align: 'center',
                            },
                            {
                                title: '充值包详情',
                                dataIndex: 'packageDetail',
                                key: 'packageDetail',
                                align: 'center',
                            },
                            {
                                title: `苹果支付(元)(优惠价/原价)`,
                                dataIndex: 'iosAmount',
                                key: 'iosAmount',
                                align: 'center',
                                width: 150,
                                render(index, data) {
                                    return data.iosDiscountAmount+"/"+data.iosAmount
                                }
                            },
                            {
                                title: '安卓支付(元)(优惠价/原价)',
                                dataIndex: 'androidAmount',
                                key: 'androidAmount',
                                align: 'center',
                                width: 150,
                                render(index, data) {
                                    return data.androidDiscountAmount+"/"+data.androidAmount
                                }
                            },
                            {
                                title: '小程序支付(元)(优惠价/原价)',
                                dataIndex: 'wxLiteAmount',
                                key: 'wxLiteAmount',
                                align: 'center',
                                width: 150,
                                render(index, data) {
                                    return data.wxLiteDiscountAmount+"/"+data.wxLiteAmount
                                }
                            },
                            {
                                title: '获得时长（天）',
                                dataIndex: 'days',
                                key: 'days',
                                align: 'center',
                            },
                            {
                                title:'苹果支付套餐ID',
                                dataIndex: 'applePayId',
                                key: 'applePayId',
                                align: 'center'
                            },
                            {
                                title: '是否推荐',
                                dataIndex: 'recommend',
                                key: 'recommend',
                                align: 'center',
                                render(index, data) {
                                    if (data.recommend===true){
                                        return "推荐";
                                    }else {
                                        return "不推荐";
                                    }
                                }
                            },
                            {
                                title: '推荐备注',
                                dataIndex: 'recommendRemark',
                                key: 'recommendRemark',
                                align: 'center',
                            },
                            {
                                title:'是否活动',
                                dataIndex: 'isActivity',
                                key: 'isActivity',
                                align: 'center',
                                render(index, data) {
                                    if (data.isActivity===true){
                                        return "有";
                                    }else {
                                        return "没有";
                                    }
                                }
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (id,text) => {
                                    console.log(text,id)
                                   return (
                                       <div>
                                           {
                                               whetherButtonPermission(buttonType.MODIFY_VIP_PACKAGE.value, pageButtons) &&
                                               <Button type="primary" onClick={() => this.onOpenEditVipModalEvent(text,operationTypeValue.UPDATE)} color="blue">{
                                                   buttonType.MODIFY_VIP_PACKAGE.label
                                               }</Button>
                                           }
                                           &nbsp;
                                           {
                                               whetherButtonPermission(buttonType.DELETE_VIP_PACKAGE.value, pageButtons) &&
                                               <Popconfirm title={`确定要删除这个vip包吗?`}
                                                           onConfirm={(event) => this.onDeleteVipEvent(text.id)}
                                                           okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                   <Button type="danger"  color="red">{buttonType.DELETE_VIP_PACKAGE.label}</Button>
                                               </Popconfirm>
                                           }
                                       </div>
                                    )
                                },
                            },
                        ]
                    }

                    title={
                        this.renderTableTitle
                    }
                    dataSource={vipPackages ? vipPackages : []}
                    bordered
                    loading={loading}
                    // pagination={{
                    //     total: vips ? vips.total : 0,
                    //     current: pageNo,
                    //     pageSize: pageSize,
                    //     onChange: this.onPageChangeEvent,
                    // }}
                />
                <Modal
                    title = {operationType === operationTypeValue.UPDATE ? "编辑VIP包" : "添加VIP包"}
                    visible={isShowEditVipModal}
                    footer={null}
                    destroyOnClose
                    onCancel={this.onCloseEditVipModalEvent}
                >
                    {operationType === operationTypeValue.UPDATE ? <VipForm {...this.state.vipInfo} onSubmitEvent={this.onEditVipEvent}/> : <VipForm onSubmitEvent={this.onAddVIPEvent}/>}
                </Modal>
            </Card>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.vip,
            pageButtons:state.authentication.pageButtons
        }

    }

);

const mapDispatchToProps = {
    getVipPackages,
    addVipPackage,
    updateVipPackage,
    deleteVipPackage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(VipManagement)));
