import createReducer from 'utils/createReducer';
import {
  GET_ADMIN,
  GET_ADMIN_ROLES,
  GET_ADMINS,
} from './actionTypes';

const defaultState = () => ({
  admins: [],
  adminsTotal: 0,
  adminsLoading: false,
  adminInfo: {},
  adminRoles: [], // 用户所拥有的角色
});


const onGetAdminsSuccess = (state, { payload: { data, total } }) => ({
  ...state,
  admins: data,
  adminsTotal: total,
  adminsLoading: false,
});

const onGetAdminsRequest = (state) => ({
  ...state,
  adminsLoading: true,
});

const onGetAdminsError = (state) => ({
  ...state,
  adminsLoading: false,
});

const onGetAdminsuccess = (state, { payload }) => ({
  ...state,
  adminInfo: payload,
});

const onGetAdminRolesSuccess = (state, { payload }) => ({
  ...state,
  adminRoles: payload,
});

export default createReducer(defaultState, {
  [`${GET_ADMINS}_REQUEST`]: onGetAdminsRequest,
  [`${GET_ADMINS}_SUCCESS`]: onGetAdminsSuccess,
  [`${GET_ADMINS}_ERROR`]: onGetAdminsError,
  [`${GET_ADMIN}_SUCCESS`]: onGetAdminsuccess,
  [`${GET_ADMIN_ROLES}_SUCCESS`]: onGetAdminRolesSuccess,
});
