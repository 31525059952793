import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Row, Table,message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    addSensitivityPlan,
    getSensitivityPlanInfo,
    getSensitivityPlanList,
    updateSensitivityPlan
} from "../../modules/small-plan/action";
import SmallPlanFilter from "./components/SmallPlanFilter";
import {operationTypeValue} from 'modules/team/enums';

import Zmage from 'react-zmage'
import SensitivityPlansInfoModel from "./components/SensitivityPlansInfoModel";


class SmallPlanManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            operationType: operationTypeValue.INCREASE,
            isShowPlanInfoModel: false,
            name: null,
            page: 1,
            size: 5,
            seletionValue: null
        };
    }

    componentDidMount() {
        this.onGetPlanData();
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.sensitivityPlans !== this.props.sensitivityPlans) {
            this.setState({
                loading: false
            })
        }
    }


    //刷新界面数据
    onGetPlanData = async () => {
        const {getSensitivityPlanList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        await getSensitivityPlanList({
            page, size,
            name: this.state.name ? this.state.name : null,
        });
    }

    //分页方法
    onPageChangeEvent = async (current, pageSize) => {
        const {getSensitivityPlanList} = this.props;
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        })
        await getSensitivityPlanList({
            page: current, size: pageSize,
            name: this.state.name ? this.state.name : null,
        });

    }

    //条件查找动态方法
    onFilterTeamEvent = async (name) => {
        let {getSensitivityPlanList} = this.props;
        const {size} = this.state;
        this.setState({
            loading: true,
            page: 1,
            name: name
        })
        await getSensitivityPlanList({
            page: 1,
            size: size,
            name: name,
        });
    }

    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowPlanInfoModel: true,
            seletionValue: seletionValue,
        });
    }

    onFormSubmit = (values) => {
        console.log(values)
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreateSensitivityPlanEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdateSensitivityPlanEvent(values);
                break;
            default:
        }
    }


    // 新增方案
    onCreateSensitivityPlanEvent = async (params) => {
        const {isShowPlanInfoModel} = this.state;
        const {addSensitivityPlan} = this.props;
        await addSensitivityPlan(params);
        await this.onGetPlanData();
        message.success('新增成功');
        this.setState({isShowPlanInfoModel: !isShowPlanInfoModel});
    }

    // 编辑方案信息
    onUpdateSensitivityPlanEvent = async (params) => {
        const {seletionValue, isShowPlanInfoModel} = this.state;
        const {updateSensitivityPlan} = this.props;
        await updateSensitivityPlan(seletionValue.id, params);
        await this.onGetPlanData();
        message.success('编辑成功');
        this.setState({isShowPlanInfoModel: !isShowPlanInfoModel});
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>
    )

    render() {

        const {
            sensitivityPlans
        } = this.props;
        const {
            operationType, isShowPlanInfoModel, page, size, loading, seletionValue
        } = this.state;
        const defaultValue = operationType == operationTypeValue.UPDATE && seletionValue;
        return (
            <div>
                <Card style={{marginBottom: 30, width: 'auto'}}>
                    <SmallPlanFilter dataSource={{}} onCommit={this.onFilterTeamEvent}/>
                </Card>
                <Card style={{width: 'auto'}}>
                    <Table
                        dataSource={sensitivityPlans ? sensitivityPlans.data : []}
                        title={this.renderTableTitle}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: sensitivityPlans.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowPlanInfoModel ? <SensitivityPlansInfoModel
                        value={defaultValue}
                        operationType={operationType}
                        onSuccess={this.onFormSubmit}
                        visible={isShowPlanInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowPlanInfoModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>

        )
    }



    getTableColumns = () => [{
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    }, {
        title: '图片',
        dataIndex: 'imageUrl',
        key: 'imageUrl',
        align: 'center',
        render: (row, text) => (
            <div>
                <Zmage id="myImage" src={text.imageUrl+'?x-oss-process=image/resize,w_690'} width="100" height="100"/>
            </div>
        ),
    }, {
        title: '操作',
        dataIndex: 'details',
        key: 'details',
        // fixed: 'right',
        align: 'center',
        render: (row, text) => {
            return (
                <div>
                    <Button style={{marginLeft: 10, marginBottom: 10}} type="primary"
                            onClick={() => this.onGetSensitivityPlanInfoEvent(text, operationTypeValue.UPDATE)}
                            color="blue">编辑</Button>
                </div>
            )
        },
    },
    ]



    onGetSensitivityPlanInfoEvent = async (value, type) => {
        const {isShowPlanInfoModel} = this.state;
        const {getSensitivityPlanInfo} = this.props;
        await getSensitivityPlanInfo(value.id);
        this.setState({
            operationType: type,
            seletionValue: value,
            isShowPlanInfoModel: !isShowPlanInfoModel,
        });
    }
}

const mapStateToProps = (state => {
        return {
            ...state.sensitivityPlan
        }

    }

);

const mapDispatchToProps = {
    getSensitivityPlanList,
    addSensitivityPlan,
    getSensitivityPlanInfo,
    updateSensitivityPlan

};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(SmallPlanManagement)));