import React from 'react';
import {Cascader, DatePicker, Form, Input, Modal, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../components/upload";
import moment from "moment";
import AreaSelect from '../../components/area';

const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddUserModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOfficialCert: false,
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                confirm({
                    content: '确认要新增用户吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        Object.keys(values).map(item => {
                            if (values[item] != undefined) {
                                if (item === "createTime") {
                                    values[item] = values[item].format('YYYY-MM-DDTHH:mm:00');
                                    return null;
                                }
                            }
                            return null;
                        });
                        onSuccess && onSuccess(values)
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    onChangeOfficialCert = (value) => {
        this.setState({
            isOfficialCert: value
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props;
        let {isOfficialCert} = this.state;
        return (
            <Modal
                visible={visible}
                title="新增用户"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="用户名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('username', {
                             rules: [{required: true, message: '请填写用户名'}, {max: 20, message: '用户名最多20个字'}],
                        })(
                            <Input placeholder="请填写用户名"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="头像"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {})(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                // width={200}
                                // height={200}
                                size={101}
                            />
                        )}
                    </FormItem>
                    <FormItem label="性别"
                              {...formItemLayout}>
                        {getFieldDecorator('gender', {
                                initialValue: '',
                                rules: [{required: true, message: '性别不能为空!'}],
                            }
                        )(
                            <Select placeholder="请选择性别" style={{width: 150}}>
                                <Select.Option value={'MALE'}>男</Select.Option>
                                <Select.Option value={'FEMALE'}>女</Select.Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="生日"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('birthday', {
                            rules: [{required: true, message: '生日不能为空!'}],
                            initialValue: moment().subtract('0', 'day'),
                        })(
                            <DatePicker format="YYYY-MM-DD"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="城市:"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('address', {
                            rules: [{required: true, message: '请选择地区', pattern: /^\S+$/}],
                            // initialValue: this.renderAddress(),
                        })(
                            <AreaSelect
                                // onChange={this.onChange}
                                placeholder="请选择地区"/>
                        )}
                    </FormItem>
                    <FormItem label="注册时间"  {...formItemLayout}>
                        {getFieldDecorator('createTime', {
                                initialValue: moment().subtract('0', 'day'),
                                rules: [{required: true, message: '注册时间!'}],
                            }
                        )(
                            <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        onOk={this.onOk}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="手机号"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('telephone', {
                            rules: [{required: true, message: '请填写手机号'},
                                // {pattern: /^1[3456789]\d{9}$/, message: '手机号码有误，请重填'}
                                {pattern: /^\d{11}$/, message: '手机号码有误，请重填'}
                                ],
                        })(
                            <Input placeholder="请填写手机号"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="个人签名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('signature', {
                            initialValue: "",
                            rule: [{maxLength: 50, message: '签名不能超过50个字符'}]
                        })(
                            <TextArea disabled={isOfficialCert} placeholder="签名不能超过50个字符" maxLength={50}/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="是否官方认证"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('officialCertification', {
                            initialValue: false,
                            rules: [{required: false, message: '请选择是否为官方认证用户', pattern: /^\S+$/}],
                        })(
                            <Select placeholder="请选择是否为官方认证用户" style={{width: 100}} onChange={this.onChangeOfficialCert}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>,
                        )}
                    </FormItem>

                    <FormItem
                        label="官方认证说明"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('officialIntroduce', {
                            initialValue: "",
                            rule: [
                                {maxLength: 100, message: '签名不能超过100个字符'}
                                ]
                        })(
                            <TextArea disabled={!isOfficialCert} placeholder="请输入官方认证说明介绍" maxLength={100}/>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.users,
    }
};
const mapDispatchToProps = ({});
AddUserModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddUserModel)));
