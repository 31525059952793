import React from 'react';
import {Col, Form, Input, message, Modal, Row, Select, Table, Tag} from 'antd';
import PropTypes from 'prop-types';
import {bindClub, getTeam, untyingClub} from '../../../../src/modules/club/action';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import Button from "antd/lib/button";
import api from "../../../utils/api";
import {operateType} from "../../../modules/admin-operation-log/enums";
import {clubTeamType} from "../../../modules/club/clubEnum";
import UpdateClubTeamTypeModal from "./UpdateClubTeamTypeModal";

const {confirm} = Modal;

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TeamModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            isShowClubTeamTypeModal: false,
            params: {
                teamId: null,
                clubId: null
            }
        };
    }


    componentWillMount() {
    }

    onUnBindEvent = (id, clubId) => {
        const {getTeam} = this.props
        const {params} = this.state
        params.clubId = clubId;
        params.teamId = id
        this.setState({
            params
        }, () => {
            confirm({
                title: '解绑?',
                content: '是否解绑该战队',
                okType: 'danger',
                okText: "确认",
                cancelText: "取消",
                onOk: async () => {
                    await api.put('/admin/teams/clubs', params).then((data) => {
                        message.success('解绑成功');
                        getTeam({clubId: clubId})
                    }).catch(error => {
                        message.error(error.response.data.msg);
                    });
                },
            });
        })

    }
    onBindEvent = () => {

    }
    renderTitle = () => {
        return (
            <Button style={{marginBottom: 16}} onClick={this.onShowBind}>绑定战队</Button>
        )
    }
    onShowBind = () => {
        this.setState({
            isShow: !this.state.isShow
        })

    }

    bindClub = (params) => {
        const {clubId} = this.props;
        params.teamId = parseInt(params.teamId);
        params.clubId = clubId
        return api.post('/admin/teams/clubs', params).then((data) => {
            message.success('绑定俱乐部成功');
        }).catch(error => {
            message.error(error.response.data.msg);
        });
    }

    updateClubTeamType = (params) => {
        const {clubId} = this.props;
        params.teamId = parseInt(this.state.currentTeamId);
        params.clubId = clubId
        return api.put('/admin/teams/clubs/team_type', params).then((data) => {
            message.success('绑定俱乐部成功');
        }).catch(error => {
            message.error(error.response.data.msg);
        });
    }
    //绑定战队
    onBindTeamEvent = async (params) => {
        const {getTeam, clubId} = this.props;
        await this.bindClub(params);

        getTeam({clubId: clubId})
    }

    updateClubTeamTypeEvent = async (params) =>{
        const {getTeam, clubId} = this.props;
        await this.updateClubTeamType(params);
        getTeam({clubId: clubId})
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.onBindTeamEvent(values);
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, teamList, clubId} = this.props;
        const {isShow, isShowClubTeamTypeModal} = this.state
        return (
            <Modal
                visible={visible}
                title="战队列表"
                onCancel={this.props.onClose}
                onOk={this.props.onClose}
                okText={"确认"}
                cancelText={"取消"}
                destroyOnClose={true}
                width={"50%"}
            >
                {this.renderTitle()}
                <div style={isShow ? {"display": " "} : {"display": "none"}}>
                    <Form style={{flexDirection: 'row'}}>
                        <Row>
                            <Col span={11}>
                                <FormItem
                                    label="战队展示Id"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('teamId', {
                                        rules: [{required: true, message: '请填写战队展示IdId', pattern: /^\S+$/}],
                                        initialValue: '',
                                    })(
                                        <Input placeholder="请填写战队展示IdId"/>,
                                    )}
                                </FormItem>
                                <FormItem
                                    label="战队级别"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('clubTeamType', {
                                        initialValue: '',
                                    })(
                                        <Select placeholder="请选择战队级别">
                                            {
                                                Object.keys(clubTeamType).map(key => (
                                                    <Select.Option
                                                        value={clubTeamType[key].value}>{clubTeamType[key].label}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>

                            <Col span={4}>
                                <FormItem {...formItemLayout}>
                                    <div style={{textAlign: 'center', marginLeft: 10}}>
                                        <Button onClick={this.onSubmit} type='primary'>提交</Button>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Table

                    columns={
                        [
                            {
                                title: '名称',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                            },
                            {
                                title: '战队级别',
                                dataIndex: 'clubTeamType',
                                key: 'clubTeamType',
                                align: 'center',
                                render: (text) => text ? clubTeamType[text].label : clubTeamType["Other"].label
                            },
                            {
                                title: '创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (text, {id}) => (
                                    <div>
                                        <Tag onClick={() => this.onUnBindEvent(id, clubId)} color="red">解绑</Tag>
                                        <Tag onClick={() => {this.setState({isShowClubTeamTypeModal: true, currentTeamId: id})}} color="red">修改战队级别</Tag>
                                    </div>
                                ),
                            },
                        ]
                    }

                    title={this.renderTableTitle}
                    dataSource={teamList.data ? teamList.data : []}
                    bordered
                    //loading={loading}
                    pagination={{
                        total: teamList ? teamList.total : 0,
                        //current: pageNo,
                        //pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal: (total) => {
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
                {isShowClubTeamTypeModal ? <UpdateClubTeamTypeModal onCommit={this.updateClubTeamTypeEvent}></UpdateClubTeamTypeModal> : null}
            </Modal>
        );
    }
}

TeamModel.propTypes = propTypes;
TeamModel.defaultProps = defaultProps;

const mapStateToProps = (state => {
        return {
            ...state.viewClub,

        }
    }
);


const mapDispatchToProps = {
    untyingClub,
    bindClub,
    getTeam
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(TeamModel))));
