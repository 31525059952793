import createReducer from 'utils/createReducer';

import {
    GET_TOPICS,
    GET_POST_LIST,
    GET_POST_DETAIL,
    GET_EMOJI_POST_DETAIL,
    GET_EMOJI_POST_LIST, GET_POST_LIKE_CONFIG
} from './actionTypes';

const defaultState = () => ({
    topcis: [],
    postList: [],
    postData: null,
    emojiPostData:null,
    emojiPostList:[],
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
        topcis:payload

        }

};

const onGetPostListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            postList: payload

        }

};

const onGetEmojiPostListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            emojiPostList: payload

        }

};

const onGetPostDetailRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            postData: payload

        }

};


const onGetPostLikeConfigRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        configData: payload

    }

};

const onGetEmojiPostDetailRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            emojiPostData: payload

        }

};

export default createReducer(defaultState, {
    [`${GET_TOPICS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_POST_LIST}_SUCCESS`]: onGetPostListRequest,
    [`${GET_POST_DETAIL}_SUCCESS`]: onGetPostDetailRequest,
    [`${GET_EMOJI_POST_DETAIL}_SUCCESS`]: onGetEmojiPostDetailRequest,
    [`${GET_EMOJI_POST_LIST}_SUCCESS`]: onGetEmojiPostListRequest,
    [`${GET_POST_LIKE_CONFIG}_SUCCESS`]: onGetPostLikeConfigRequest,
});
