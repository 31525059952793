import createAsyncAction from 'utils/createAsyncAction';
import PayRequest from "../../request/PayRequest";
import {GET_PAYMENTS,GET_MONEY} from "./actionType";


const getPayments = (pageNo, pageSize,userId,status,dateRange,attachType,orderId,payChannel) => (
    createAsyncAction(GET_PAYMENTS, () => (PayRequest.getPayment(pageNo, pageSize,userId,status,dateRange,attachType,orderId,payChannel)))
);
const getMoney = (dateRange) => (
    createAsyncAction(GET_MONEY, () => (PayRequest.getMoney(dateRange)))
);

export {
    getPayments,getMoney
};