import createReducer from 'utils/createReducer';

import {
    GET_PLAYER_DETAIL_LIST,
    GET_PLAYER_ORDER_LIST_LIST,
} from './actionTypes';

const defaultState = () => ({
    playerDetailList: [],
});

const onGetPlayerDetailRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            playerDetailList: payload
        }

};

export default createReducer(defaultState, {
    [`${GET_PLAYER_DETAIL_LIST}_SUCCESS`]: onGetPlayerDetailRequest,
    [`${GET_PLAYER_ORDER_LIST_LIST}_SUCCESS`]: onGetPlayerDetailRequest,
});
