import Http from 'request/UserRequest';
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_USERS,
    GET_USER,
    UPDATE_USER_STATE,
    UPDATE_ACCOUNT,
    UNBAN_USER_CLAIM,
    ADD_USER,
    UPDATE_USER,
    GET_USER_ADDRESS_LIST,
    IMPORT_MAJIA
} from './actionTypes';

const getUsers = (exhibitionId, username, telephone, state, registerBeginTime, registerEndTime, page, size, userId,vestStatus) => (
  createAsyncAction(GET_USERS, () => (Http.getUsers(exhibitionId, username, telephone,
      state, registerBeginTime, registerEndTime, page, size, userId,vestStatus)))
);

const getUser = (userId) => (
    createAsyncAction(GET_USER, () => (Http.getUser(userId)))
);


const updateAccount = (userId,cashOut) => (
    createAsyncAction(UPDATE_ACCOUNT, () => (Http.updateAccount(userId,cashOut)))
);

const updateUserState = (userId,params) => (
    createAsyncAction(UPDATE_USER_STATE, () => (
        Http.updateUserStatus(userId,params)
    ))
)

const updateUser = (userId,params) => (
    createAsyncAction(UPDATE_USER, () => (
        Http.updateUser(userId,params)
    ))
)

const addUser = (params) => (
    createAsyncAction(ADD_USER, () => (
        Http.addUser(params)
    ))
)
const unBanUserClaim = (userId,type) => (
    createAsyncAction(UNBAN_USER_CLAIM, () => (
        Http.unBanUserClaim(userId,type)
    ))
)

const getUserAddressList = (userId) => (
    createAsyncAction(GET_USER_ADDRESS_LIST, () => (
        Http.getUserAddressList(userId)
    ))
);

const importMajia = (params) => (
    createAsyncAction(IMPORT_MAJIA, () => (
        Http.importMajia(params)
    ))
)

export {
  getUsers,getUser,updateUserState,updateAccount,unBanUserClaim,addUser,updateUser,getUserAddressList,importMajia
};
