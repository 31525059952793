import React, {Component} from "react";
import {Button, Form, Select} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import {getGame} from "../../modules/trail-plan/action";

const {Option} = Select;
const FormItem = Form.Item;

class TrailPlanFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        let {getGame} = this.props
        getGame()
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {game} = values;
                onCommit && onCommit(game);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {gameList,} = this.props;
        const gameSelect = gameList ? gameList : []
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="游戏类型">
                    {getFieldDecorator('game', {}
                    )(
                        <Select style={{width: '150px'}} placeholder="请选择游戏类型">
                            <Option value="">全部</Option>
                            {gameSelect.map((item) =>

                                <Option value={item.code} key={item.code}>

                                    {item.gameName}
                                </Option>)}
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}


const mapStateToProps = (state => {
        return {
            ...state.trailPlan,

        }
    }
);

const mapDispatchToProps = {
    getGame
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(TrailPlanFilter))));

