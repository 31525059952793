import createAsyncAction from 'utils/createAsyncAction';
import {ADD_CONFIGS, DELETE_CONFIGS, GET_CONFIG, GET_CONFIGS, UPDATE_CONFIGS} from "../config/actionType";
import ConfigRequest from "../../request/ConfigRequest";


const getConfigs = (page, size) => (
    createAsyncAction(GET_CONFIGS, () => (ConfigRequest.getConfigs(page, size)))
);

const addConfigs = ({confKey, confValueType, confValue, confDesc, security}) => (
    createAsyncAction(ADD_CONFIGS, () => (ConfigRequest.addConfig({confKey, confValueType, confValue, confDesc, security})))
);

const updateConfigs = (key, {confValueType, confValue, confDesc, security}) => (
    createAsyncAction(UPDATE_CONFIGS, () => (ConfigRequest.updateConfig(key, {confValueType, confValue, confDesc, security})))
);

const deleteConfigs = (key) => (
    createAsyncAction(DELETE_CONFIGS, () => (ConfigRequest.deleteConfig(key)))
);

const getConfig = (key) => (
    createAsyncAction(GET_CONFIG, () => (ConfigRequest.getConfig(key)))
);

export {
    getConfigs, addConfigs, updateConfigs, deleteConfigs, getConfig
};