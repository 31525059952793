export const GET_LINEUP_LIST = 'GET_LINEUP_LIST'; // 分页查看阵容

export const CREATE_LINEUP = 'CREATE_LINEUP';// 添加团队阵容

export const EDIT_LINEUP = 'EDIT_LINEUP';// 修改团队阵容

export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';//分页查询成员

export const ADD_MEMBER = 'ADD_MEMBER';//新增成员

export const QUITE_MEMBER = 'QUITE_MEMBER';//删除成员

export const UPDATE_MEMBER_SORT = 'UPDATE_MEMBER_SORT'; // 设置展示顺序

export const UPDATE_MEMBER_INTRODUCTION = 'UPDATE_MEMBER_INTRODUCTION'; // 设置成员简介

export const DELETE_LINEUP = 'DELETE_LINEUP'; // 删除团队阵容
