import createReducer from 'utils/createReducer';


import {GET_VIP_PACKAGES, GET_VIP, GET_VIP_ACTIVITY, FIND_HISTORY_LOGS} from "./actionType";

const defaultState = () => ({
    vipPackages: [],
    vip: null,
    activityPackages:[]
});


const onGetVipPackagesSuccess = (state,{payload}) => ({
    ...state,
    vipPackages:payload
});

const onGetVipSuccess = (state,{payload}) => ({
    ...state,
    vip:payload
})

const onGetActivitySuccess = (state,{payload}) => ({
    ...state,
    activityPackages:payload
})

const onFindHistoryLogsSuccess = (state,{payload}) => ({
    ...state,
    zhijinHistoryLogs:payload
});

export default createReducer(defaultState, {
    [`${GET_VIP_PACKAGES}_SUCCESS`]: onGetVipPackagesSuccess,
    [`${GET_VIP}_SUCCESS`]: onGetVipSuccess,
    [`${GET_VIP_ACTIVITY}_SUCCESS`]: onGetActivitySuccess,
    [`${FIND_HISTORY_LOGS}_SUCCESS`]: onFindHistoryLogsSuccess,


});
