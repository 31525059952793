import api from '../utils/api';


class TicketRequest {

    /**
     * 分页查看票券列表
     * @param page
     * @param size
     * @param medalName
     * @param medalType
     * @returns {*}
     */
    static getTicketList(params) {
        return api.get('/admin/ticket-center/queryByPage', params);
    }

    static getTicketOrderList(params) {
        return api.get('/admin/ticket-center/queryTicketOrder', params);
    }

    /**
     * 根据主题勋章ID查看
     * @param themeMedalId
     */
     static getMedalThemeData(themeMedalId) {
        return api.get(`/admin/ticket-center/queryDetail/${themeMedalId}`, {themeMedalId});
    }

    /**
     * 更新
     * @param themeMedalId
     * @param {Object} params 请求参数
     */
    static updateMedalThemeData(themeMedalId, params) {
        return api.put(`/admin/ticket-center/edit/${themeMedalId}`, {themeMedalId, ...params});
    }

    /**
     * 添加主题勋章信息
     * @param themeMedalId
     * @param {Object} params 请求参数
     */
    static addTicketData(params) {
        params.shelves = false;
        return api.post(`/admin/ticket-center/add`,  params);
    }

    /**
     * 主题勋章上下架
     * @param themeMedalId
     * @param {Object} params 请求参数
     */
     static updateMedalThemeShelf(themeMedalId, params) {
        return api.post(`/admin/ticket-center/shelve/${themeMedalId}`, {themeMedalId, ...params});
    }

    /**
     * 分页查看子勋章列表
     * @param page
     * @param size
     * @param themeMedalId
     * @returns {*}
     */
     static getSubMedalList(themeMedalId, params) {
        return api.get(`/admin/medal/${themeMedalId}/child`, {themeMedalId, ...params});
    }

    /**
     * 根据子勋章ID获取子勋章信息
     * @param themeMedalId
     */
     static getSubMedalData(childMedalId) {
        return api.get(`/admin/medal/child/${childMedalId}`, {childMedalId});
    }

    /**
     * 更新子勋章信息
     * @param themeMedalId
     * @param {Object} params 请求参数
     */
     static updateSubMedalData(childMedalId, params) {
        return api.put(`/admin/medal/child/${childMedalId}`, {childMedalId, ...params});
    }

    /**
     * 添加子勋章信息
     * @param themeMedalId
     * @param {Object} params 请求参数
     */
    static addSubMedalData(params) {
        console.log('-----------------------params:',params);
        return api.post(`/admin/medal/createChildMedal`, params);
    }

    /**
     * 查看子勋章获得/回收记录
     * @param page
     * @param size
     * @param childMedalId
     * @param userId
     * @param username
     * @param operationType
     * @returns {*}
     */
     static getSubMedalLogList(params) {
        return api.get(`/admin/medal/log`, params);
    }

    /**
     * 赠送子勋章
     * @param childMedalId
     * @param {Object} params 请求参数
     */
     static updateSubMedalSend(childMedalId, params) {
        return api.post(`/admin/medal/child/${childMedalId}/send`, {childMedalId, ...params});
    }

    /**
     * 回收子勋章
     * @param childMedalId
     * @param {Object} params 请求参数
     */
     static updateSubMedalRecycle(childMedalId, params) {
        return api.post(`/admin/medal/child/${childMedalId}/recycle`, {childMedalId, ...params});
    }
}

export default TicketRequest;
