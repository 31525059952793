import api from 'utils/api';

class ActivityRequest {
  /**
     * 查询活动
     * @param {Object} params 请求参数
     */
  static async getActivity(parmas) {
    return api.get(`/admin/officialActivity/findByPage`, parmas);
  }

  /**
   * 获取活动详情
   * @param id 
   */
  static async getActivityDetail(id) {
    return api.get(`/admin/officialActivity/getDetail/${id}`);
  }

  /**
   * 获取活动类型
   */
   static async getActivityType() {
    return api.get(`/admin/officialActivity`);
  }

  /**
   * 新增活动详情
   * @param {Object} params 请求参数
   */
   static async addActivity(params) {
    return api.post(`/admin/officialActivity`,params);
  }

  /**
   * 编辑活动详情
   * @param id 活动id
   * @param {Object} params 请求参数
   */
   static async editActivity(id,params) {
    console.log(id,params)
    return api.put(`/admin/officialActivity/edit/${id}`,params);
  }

  /**
   * 删除活动
   * @param id 
   */
   static async delActivity(id) {
    return api.delete(`/admin/officialActivity/delete/${id}`);
  }

  /**
   * 置顶活动
   * @param {Object} params 请求参数 
   */
   static async recommendActivity(params) {
    return api.post(`/admin/officialActivity/recommend`,params);
  }


}
export default ActivityRequest;