import axios from 'axios';
import { buildConfig } from 'app/config/buildConfig';
import Cookie from 'js-cookie';
import { ACCESS_TOKEN } from 'modules/authentication/constants';
import historyInstance from 'app/init/historyInstance';


const defaultHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};


const instance = axios.create({
  baseURL: buildConfig.apiDomain,
  timeout: 20000,
  headers: defaultHeader,
  withCredentials: true,
});

const returnJson = (response) => response.data;

/**
 * 当axios拿到的超出2xx时，会抛出一个异常
 * @param error
 * @returns {Promise<never>}
 */
const standardCatchFunc = function (error) {
  if (error.response.status == 401) {
    historyInstance.push('/login');
  }
  return Promise.reject(error);
};

const api = () => {
  let opt = {
    instance,
  };

  let getAuthorizationHeader = (headers) => {
    try {
      let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
      const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
      if (headers && accessToken) {
        headers.Authorization = accessToken.token;
      }
      return headers;
    } catch (error) {
      return '';
    }
  };

  return {
    setOptions: (options) => {
      opt = {
        ...opt,
        ...options,
      };
    },
    get: (url, query, skipAuthorization = false,header) => (
      opt.instance.get(url, {
        params: query, headers: skipAuthorization ? {} : getAuthorizationHeader(header?header:{}),
      }).then(returnJson).catch(standardCatchFunc)
    ),
    post: (url, data, skipAuthorization = false) => (
      opt.instance.post(url, data, { headers: skipAuthorization ? {} : getAuthorizationHeader({}) })
        .then(returnJson).catch(standardCatchFunc)
    ),
    put: (url, data, skipAuthorization = false) => (
      opt.instance.put(url, data, { headers: skipAuthorization ? {} : getAuthorizationHeader({}) })
        .then(returnJson).catch(standardCatchFunc)
    ),
    patch: (url, data, skipAuthorization = false) => (
      opt.instance.patch(url, data, { headers: skipAuthorization ? {} : getAuthorizationHeader({}) })
        .then(returnJson).catch(standardCatchFunc)
    ),
    delete: (url, skipAuthorization = false) => (
      opt.instance.delete(url, { headers: skipAuthorization ? {} : getAuthorizationHeader({}) })
        .then(returnJson).catch(standardCatchFunc)
    ),
  };
};

export default api();
