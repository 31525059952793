import api from 'utils/api';

class AppStartRequest {
    /**
     *
     * @returns {*}
     */
    static updateStartImage(configurationEnum,imageUrl){
        console.log(configurationEnum,imageUrl)
        return api.post(`/admin/app/config/updateStartImage`,{configurationEnum,imageUrl});
    }
    static addStartImage(configurationEnum,imageUrl){
        console.log(configurationEnum,imageUrl)
        return api.post(`/admin/app/config/addStartImage`,{configurationEnum,imageUrl});
    }

    static async getAppStartImage(){
        return api.get(`/admin/app/config/start`);
    }
    static delAppStartImage(id){
        return api.delete(`/admin/app/config/start/${id}`);
    }
}

export default AppStartRequest;
