
export const stateType = {
  NORMAL: {
    label: '正常',
    value:'NORMAL',
  },
  BAN: {
    label: '封禁',
    value:'BAN'
  },
  CANCEL: {
    label: '注销',
    value:'CANCEL'
  },
};

export const genderType = {
  UNKNOWN: {
    label: '未知',
    value: 'UNKNOWN'
  },
  MALE: {
    label: '男',
    value: 'MALE'
  },
  FEMALE: {
    label: '女',
    value: 'FEMALE'
  },
}

export const vestState = {
  NORMAL: {
    label: '正常用户',
    value:false,
  },
  MAJIA: {
    label: '马甲用户',
    value:true
  },
};
