import createReducer from 'utils/createReducer';
import {GET_TEAM_INFO, GET_TEAM_LIST, GET_GAME_LIST,GET_TEAM_MEMBER_LIST,GET_TEAM_BUSINESS,GET_USER_TEAM_LIST} from './actionTypes';

const defaultState = () => ({
    teams: {
        data: [],
        total: 0,
    }, // 战队列表
    teamInfo: {}, // 战队信息
    games:  [],
    teamBusiness: [],
    teamMembers: {
        data: [],
        total: 0,
    },
    userTeams:[]

});

const onGetTeamInfoSuccess = (state, {payload}) => ({
    ...state,
    teamInfo: payload,
});


const onGetTeamListSuccess = (state, {payload}) => ({
    ...state,
    teams: payload,
});

const onGetGameListSuccess = (state, {payload}) => ({
    ...state,
    games: payload,
});

const onGetTeamMemberListSuccess = (state, {payload}) => ({
    ...state,
    teamMembers: payload,
});

const onQueryTeamsBusinessSuccess = (state, {payload}) => ({
    ...state,
    teamBusiness: payload
});

const onGetUserTeamListSuccess = (state, {payload}) => ({
    ...state,
    userTeams: payload,
});


export default createReducer(defaultState, {
    [`${GET_TEAM_INFO}_SUCCESS`]: onGetTeamInfoSuccess,
    [`${GET_TEAM_LIST}_SUCCESS`]: onGetTeamListSuccess,
    [`${GET_GAME_LIST}_SUCCESS`]: onGetGameListSuccess,
    [`${GET_TEAM_MEMBER_LIST}_SUCCESS`]: onGetTeamMemberListSuccess,
    [`${GET_TEAM_BUSINESS}_SUCCESS`]: onQueryTeamsBusinessSuccess,
    [`${GET_USER_TEAM_LIST}_SUCCESS`]: onGetUserTeamListSuccess,
});
