
export const statusType = {
  NORMAL: {
    label: '正常',
    value:'NORMAL',
  },
  LOCK: {
    label: '锁定',
    value:'LOCK'
  },
};

