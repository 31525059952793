
import api from 'utils/api';

class PartnerTeacherRequest {

  /**
     * 新增导师
     * @param {Object} params 请求参数
     */
  static async createPartnerTeacher(params) {
    return api.post(`/admin/partner-training/teachers`, params);
  }

  /**
     * 修改导师
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
  static async updatePartnerTeacher(id, params) {
    return api.put(`/admin/partner-training/teachers/${id}`, params);
  }

  /**
     * 删除导师
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
  static async deletePartnerTeacher(id) {
    return api.delete(`/admin/partner-training/teachers/${id}`);
  }


    /**
     * 更新导师状态
     */
    static updatePartnerTeacherStatus(id,params){
        return api.put(`/admin/partner-training/teachers/${id}/state`,params);
    }

    /**
     * 查询导师列表
     * @param {Object} params 请求参数
     */
    static async queryPartnerTeachers(params) {
        return api.get('/admin/partner-training/teachers', params);
    }


    /**
     * 获取详情
     * @param {Number} id
     */
    static async getPartnerTeacher(id) {
        return api.get(`/admin/partner-training/teachers/${id}`);
    }

}

export default PartnerTeacherRequest;
