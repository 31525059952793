
export default class utils {
  /**
     * 深拷贝
     * @param value
     * @returns {*}
     */
  static mutableCopy(value) {
    try {
      return JSON.parse(JSON.stringify(value));
    } catch (e) {
      return {};
    }
  }

  /**
   * moment=>moment str
   * @param moment
   * @param format
   * @returns {*}
   */
  static formatMomentToStr(moment, format='YYYY-MM-DD'){
    try {
      if(moment){
        return moment.format(format);
      }else{
        return null
      }
    }catch (e) {
      return null;
    }
  }

    /**
     * moment=>moment str
     * @param moment
     * @param format
     * @returns {*}
     */
    static formatMomentToTimeStr(moment, format='HH:mm:ss'){
        try {
            if(moment){
                return moment.format(format);
            }else{
                return null
            }
        }catch (e) {
            return null;
        }
    }


  static requestUrl = "http://10.35.19.49:32271"
// "http://127.0.0.1:8080";
}
