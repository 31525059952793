import createReducer from 'utils/createReducer';

import {
    GET_ACTIVITY, GET_ACTIVITY_DETAIL,GET_ACTIVITY_TYPE
} from './actionTypes'

const defaultState = () => ({
    activityList:{},//所有活动
    activityDetail:{},//活动详情
    activityType:[]//活动类型
});

const onGetActivity = (state,{payload}) => {
    return {
        ...state,
        activityList:payload,

    }

};
const onGetActivityDetail = (state,{payload}) => {
    return {
        ...state,
        activityDetail:payload,
        
    }

};

const onGetActivityType = (state,{payload}) => {
    return {
        ...state,
        activityType:payload,
        
    }

};



export default createReducer(defaultState, {
    [`${GET_ACTIVITY}_SUCCESS`]: onGetActivity,
    [`${GET_ACTIVITY_DETAIL}_SUCCESS`]: onGetActivityDetail,
    [`${GET_ACTIVITY_TYPE}_SUCCESS`]: onGetActivityType,

});
