import React from 'react';
import './BasicLayout.scss';
import {
  Menu, Icon, Dropdown, Avatar,
} from 'antd';
import Cookie from 'js-cookie';
import {
  ACCESS_TOKEN, AUTHENTICATION_INFO, USER_VIEW_PERMISSION_TREE, USER_PAGE_BUTTONS,
} from 'modules/authentication/constants';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getUserMenu = () => {
    const {
      logout,
      prefixCls,
      intl,
      history,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    return (
      <Menu>
        <Menu.Item className={`${prefixCls}-userMenuItem`}>
          <div onClick={() => {
            history.push('/system/personal_message');
          }}
          >
            <Icon type="user" className={`${prefixCls}-userMenuIcon`} />
            <span>{intl.formatMessage({ id: 'basicLayout_profile' })}</span>
          </div>
        </Menu.Item>
        <Menu.Item className={`${prefixCls}-userMenuItem`}>
          <div onClick={() => {
            history.push('/system/personal_set');
          }}
          >
            <Icon type="setting" className={`${prefixCls}-userMenuIcon`} />
            <span>{intl.formatMessage({ id: 'basicLayout_setting' })}</span>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className={`${prefixCls}-userMenuItem`}>
          <div
            onClick={() => {
              logout();
              Cookie.remove(ACCESS_TOKEN);
              sessionStorage.removeItem(AUTHENTICATION_INFO);
              sessionStorage.removeItem(USER_VIEW_PERMISSION_TREE);
              sessionStorage.removeItem(USER_PAGE_BUTTONS);
            }}
            role="presentation"
          >
            <Icon type="logout" className={`${prefixCls}-userMenuIcon`} />
            <span>{intl.formatMessage({ id: 'basicLayout_logout' })}</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  getAvatar = () => {
    const { prefixCls, user, getFirstChar } = this.props;
    if (user.imageUrl) {
      return (
        <Avatar src={`/api${user.imageUrl}`} className={`${prefixCls}-avatar`} />
      );
    }
    return (
      <Avatar className={`${prefixCls}-avatar`}>
        {getFirstChar(user.username)}
      </Avatar>
    );
  }

  render() {
    const { prefixCls } = this.props;
    return (
      <div>
        <div className={`${prefixCls}-header`}>
          <Dropdown overlay={this.getUserMenu()} placement="bottomRight">
            <div className={`${prefixCls}-avatarContainer`}>
              {this.getAvatar()}
            </div>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default Header;
