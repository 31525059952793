
import api from 'utils/api';

class SamllPlanRequest {
  /**
     * 查询方案列表
     * @param {Object} params 请求参数
     */
  static async getSensitivityPlanList(parmas) {
    return api.get('/admin/miniapp/sensitivity/plan', parmas);
  }
  /**
     * 查询方案
     * @param {Object} params 请求参数
     */
  static async getSensitivityPlanInfo(id) {
    return api.get(`/admin/miniapp/sensitivity/plan/${id}`);
  }

  /**
     * 创建方案
     * @param {Object} params 请求参数
     */
  static async createSensitivityPlan(params) {
    return api.post(`/admin/miniapp/sensitivity/plan`, params);
  }
  /**
     * 修改方案
     * @param {Object} params 请求参数
     */
  static async updateSensitivityPlan(id, params) {
    return api.put(`/admin/miniapp/sensitivity/plan/${id}`, params);
  }

}

export default SamllPlanRequest;
