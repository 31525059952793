import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, notification, Popconfirm, Table, Modal} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getComments} from "../../modules/comment/action";
import {shieldComment} from "../../modules/dynamic/action";
import CommentFilter from '../post-comment-management/CommentFilter'
import { getPostDetail } from '../../modules/topic/action';
import PostForm from './PostForm';

class CommentManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            commentThemeType:"POST",
            commentUserExhibitionId: "",
            commentUserName:"",
            keyword:"",
            commentStatus: "",
            dateRange: "",
            themeName:"",
            gameType:"",
            isShowModal: false,
            postId: null,
            authorExId: null,
        };
    }

    componentWillMount(){
    }

    /**
     * 刷新用户列表事件
     */
     onRefreshUsers = () => {
        const {pageNo, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId} = this.state
        const {getComments} = this.props;
        getComments(pageNo, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId,false);
    }

    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getComments} = this.props;
        let { commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName, themeId} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getComments(current, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName ,themeId,false);
    }

    //条件查找动态方法
    onFilterCommentEvent = async (commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId) => {
        let {getComments} = this.props;
        const {pageSize,commentThemeType} = this.state;
        this.setState({
            commentUserExhibitionId: commentUserExhibitionId,
            commentUserName:commentUserName,
            keyword:keyword,
            commentStatus: commentStatus,
            dateRange: dateRange,
            themeName:themeName,
            pageNo: 1,
            themeId:themeId,
        });
        getComments(1, pageSize, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId,false);
    };

    //屏蔽/解除屏蔽评论的方法
    onDeleteCommentEvent = async (text) => {
        const { shieldComment } = this.props;
        try {
            await shieldComment({
                commentId:text.id,
                operationType:text.commentStatus=="NORMAL"? "SHIELD":"NORMAL"
            });
            notification.success({ message: `${text.commentStatus === "NORMAL" ? "屏蔽" : "恢复"}成功` });

            this.onRefreshUsers();
        } catch (e) {
            console.log(e);
        }
    };

    onShowPostModalEvent = (text, postId) => {
        const { getPostDetail } = this.props;

        getPostDetail(postId);
        this.setState({
            isShowModal: true,
            postId,
            authorExId: text.exhibitionId,
        });
    }

    onClosePostModalEvent = () => {
        this.setState({
            isShowModal: false,
        });
    }


    render() {
        const {
            postData, comments, loading
        } = this.props;
        const {
            pageNo, pageSize, isShowModal
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CommentFilter dataSource={{}} onCommit={this.onFilterCommentEvent}/>
                </Card>

                <Card>
                    <Table

                        columns={
                            [
                                {
                                    title: '评论ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '帖子ID',
                                    dataIndex: 'themId',
                                    key: 'themId',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '帖子内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    width: "10%",
                                    render:(index, text) => {
                                        return <Button onClick={()=> this.onShowPostModalEvent(text, text.themId)} type="primary">查看内容</Button>
                                    }
                                },
                                {
                                    title: '评论内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '话题名',
                                    dataIndex: 'topicName',
                                    key: 'topicName',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '评论状态',
                                    dataIndex: '',
                                    key: '',
                                    align: 'center',
                                    width: "10%",
                                    render(index, data) {
                                        if (data.commentStatus == "NORMAL") {
                                            return "正常";
                                        } else if(data.commentStatus == "SHIELD"){
                                            return "屏蔽";
                                        }
                                    }
                                },
                                {
                                    title: '楼层数',
                                    dataIndex: 'floor',
                                    key: 'floor',
                                    align: 'center',
                                    width: "8%",
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'commentTime',
                                    key: 'commentTime',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                            {text.commentStatus === "NORMAL" ?
                                                <Popconfirm title={`确定要屏蔽这条评论吗?`}
                                                    onConfirm={() => this.onDeleteCommentEvent(text)}
                                                    okText="确认"
                                                    cancelText="取消" style={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#fff'
                                                }}>
                                                    <Button type="danger">{text.commentStatus === "NORMAL" ? "屏蔽" : "取消屏蔽"}</Button>
                                                </Popconfirm> :
                                                <Popconfirm title={`确定要恢复这条评论吗?`}
                                                    onConfirm={() => this.onDeleteCommentEvent(text)}
                                                    okText="确认"
                                                    cancelText="取消" style={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#fff'
                                                }}>
                                                    <Button type="primary">{text.commentStatus === "NORMAL" ? "屏蔽" : "取消屏蔽"}</Button>
                                                </Popconfirm>
                                               }
                                        </div>)

                                    },
                                },
                            ]
                        }
                        dataSource={comments.data ? comments.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: comments ? comments.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={"查看帖子内容"}
                        visible={isShowModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onClosePostModalEvent}
                    >
                        <PostForm {...this.props} {...this.state} {...postData} />
                        {/* <PostForm {...this.props} {...this.state} onSubmitEvent={this.onEditPostEvent}/> */}
                    </Modal>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.Comments,
            ...state.Topics
        }
    }
);

const mapDispatchToProps = {
    getComments,
    shieldComment,
    getPostDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CommentManagement)));
