import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl-context';
import { matchRoutes } from 'react-router-config';
import get from 'lodash/get';
import map from 'lodash/map';
import { filter } from 'lodash/fp';
import head from 'lodash/head';
import Sider from 'components/sider';
import { combineRoutes } from 'app/config/routes';
import getFirstChar from 'utils/getFirstChar';
import LoginChecker from 'hoc/LoginChecker';
import logo from 'assets/logo.svg';
import './BasicLayout.scss';
import { logout, getAuthenticationInfo } from 'modules/authentication/action';
import { getAdmin } from 'modules/admin/action';
import listener from 'utils/listener';
import utils from 'utils/utils';
import Header from './HeaderLayout';
import Footer from './FooterLayout';
import MenuBar from './MenuBarLayout';

const propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userUnReadNotices: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

const defaultProps = {
  prefixCls: 'basicLayout',
  className: '',
};

class BasicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: this.formatMenuData(props.menuData),
      noticeParams: {
        size: 5,
        page: 1,
        noticeIsReadEnum: 'UNREAD',
      },
      messsageKey: null, // 当前选中的消息
      collapsed: false,
    };
  }

  formatMenuData = (menu) => (
    map(menu, (item) => {
      const result = {
        ...item,
        name: this.props.intl.formatMessage({ id: item.name }),
      };

      if (item.children) {
        result.children = this.formatMenuData(item.children);
      }

      return result;
    })
  );

  onGetMessageEvent = () => {
    const { noticeParams } = this.state;
    noticeParams.size += 5;
    this.props.getUserNoticeList(noticeParams);
  }

  onUpdateMessageStatuEvent = async (id) => {
    this.props.setNoticeRead(id);
  }

  componentWillMount() {

  }

  componentWillUnmount() {
    listener.stopListenRemoteNoticeCenter();
  }

  render() {
    const {
      prefixCls,
      className,
      intl,
      location,
      children,
      token,
      logout,
      history,
      route,
      userUnReadNotices,
      menuMap,
      user,
    } = this.props;
    console.log(route)
    console.log(menuMap)

    const { menuData, messsageKey } = this.state;

    const classes = classnames({
      [prefixCls]: true,
      [className]: true,
    });

    return (
      <LoginChecker isLogin={token != ''}>
        <div className={classes}>
          <Sider
            appName={intl.formatMessage({ id: 'appName' })}
            appLogo={logo}
            menuData={menuData}
            pathname={location.pathname}
            collapsed={this.state.collapsed}
          />
          <div className={`${prefixCls}-content`}>
            <Header
              messsageKey={messsageKey}
              logout={logout}
              prefixCls={prefixCls}
              user={user}
              userUnReadNotices={userUnReadNotices}
              history={history}
              intl={intl}
              getFirstChar={getFirstChar}
              onGetMessageEvent={this.onGetMessageEvent}
              onUpdateMessageStatuEvent={this.onUpdateMessageStatuEvent}
              onSetNoticeKey={(id) => {
                this.setState({
                  messsageKey: id,
                });
              }}
            />
            <MenuBar
              route={route}
              prefixCls={prefixCls}
              menuMap={menuMap}
              intl={intl}
              onSiderCollapseEvent={this.onSiderCollapseEvent}
            />
            <div className={`${prefixCls}-mainContent`}>
              {children}
            </div>
            <Footer
              prefixCls={prefixCls}

            />
          </div>
        </div>
      </LoginChecker>
    );
  }

  onSiderCollapseEvent = () => {
    // let {collapsed} = this.state;
    // this.setState({
    //   collapsed:!collapsed
    // });
  }
}

function filterMenus(viewPermissions) {
  if (viewPermissions && viewPermissions.length != 0) {
    return filter((viewPermission) => {
      viewPermission.path = viewPermission.route;
      if (viewPermission.children) {
        viewPermission.children = filterMenus(viewPermission.children);
        if (viewPermission.children.length == 0) {
          delete viewPermission.children;
        }
      }
      return viewPermission.type === 'MENU';
    })(viewPermissions);
  }
}

function getMenuMap(menuData, menuMap) {
  menuData.forEach((menuItem) => {
    menuMap.set(menuItem.route, menuItem);
    if (menuItem.children) {
      getMenuMap(menuItem.children, menuMap);
    }
  });
}

const mapStateToProps = (state) => {
            console.log(state)
  const pathname = get(state, 'router.location.pathname', '');
  const { route } = head((matchRoutes(combineRoutes, pathname)));
  const menuData = filterMenus(utils.mutableCopy(state.authentication.viewPermissionsTree)) || [];
  const menuMap = new Map();
  getMenuMap(menuData, menuMap);

  return {
    user: state.authentication.user,
    userInfo: state.admin.userInfo,
    // TODO
    notification: {},
    pathname,
    route,
    token: state.authentication.token,
    menuData,
    menuMap,
    viewPermissionsTree: state.authentication.viewPermissionsTree,
  };
};

const mapDispatchToProps = {
  logout,
  getAuthenticationInfo,
  getAdmin,
};

BasicLayout.propTypes = propTypes;
BasicLayout.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BasicLayout));
