import React from 'react';
import {Form, Icon, Input, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";
import Button from "antd/es/button/button";
import {operationTypeValue} from 'modules/team/enums';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 20, offset: 4},
    },
};

const defaultProps = {
    visible: false,
    onClose: () => {
    },
};

class SensitivityPlansInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sensitivity: [],
            name: null,
            imageUrl: null

        };
    }

    componentWillMount() {
        const {value, sensitivityPlanInfo} = this.props;
        console.log(value)
        const defaultSensitivity = [{
            "value": "开火灵敏度（仅供参考）",
            "params": [{"key": "推荐使用：", "value": "轻型握把"}, {"key": "开火灵敏度不开镜：", "value": "70%"}, {
                "key": "红点、全息、机瞄准：",
                "value": "32%"
            }, {"key": "2倍镜:", "value": "35%"}, {"key": "3倍镜:", "value": "26%"}, {
                "key": "4倍镜:",
                "value": "27%"
            }, {"key": "6倍镜:", "value": "15%"}, {"key": "8倍镜:", "value": "7%"}]
        }, {
            "value": "陀螺仪灵敏度（仅供参考）",
            "params": [{"key": "不开镜:", "value": "134%"}, {"key": "红点、全息、机瞄准：", "value": "300%"}, {
                "key": "2倍镜:",
                "value": "300%"
            }, {"key": "3倍镜:", "value": "300%"}, {"key": "4倍镜:", "value": "240%"}, {
                "key": "6倍镜:",
                "value": "180%"
            }, {"key": "8倍镜:", "value": "120%"}]
        }]
        console.log(defaultSensitivity)
        this.setState({
            sensitivity: value ? sensitivityPlanInfo.sensitivity : defaultSensitivity,
            name: value ? sensitivityPlanInfo.name : '',
            imageUrl: value ? sensitivityPlanInfo.imageUrl : ''
        })

    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let sensitivity = [];
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                Object.keys(values).map((value, index, arr) => {
                    if (value.startsWith("value")) {
                        console.log(value)
                        values[value].map((k, index) => {
                            let params = [];
                            values.params[index].map((element, e_index) => {
                                params.push(element)
                                return null
                            })
                            sensitivity.push({value: k, params: params})
                            return null
                        })
                    }
                    return null
                });
                values["sensitivity"] = sensitivity;

                onSuccess && onSuccess(values);
            }
        });
    }

    onChangeParamsKey = (index, e_index, value) => {
        const {sensitivity} = this.state;
        sensitivity[index].params[e_index].key = value;
        console.log(sensitivity)
        this.setState({
            sensitivity: sensitivity
        })
    }

    onChangeParamsValue = (index, e_index, value) => {
        const {sensitivity} = this.state;
        sensitivity[index].params[e_index].value = value
        console.log(sensitivity)
        this.setState({
            sensitivity: sensitivity
        })
    }

    onChangeValue = (index, value) => {
        const {sensitivity} = this.state;
        sensitivity[index].value = value
        console.log(sensitivity)
        this.setState({
            sensitivity: sensitivity
        })
    }

    onChangeName = (value) => {
        this.setState({
            name: value
        })
    }

    onChangeImage = (value) => {
        this.setState({
            imageUrl: value
        })
    }

    remove = index => {
        const {resetFields} = this.props.form;
        resetFields();
        const {sensitivity} = this.state;
        sensitivity.splice(index, 1)
        this.setState({
            sensitivity: sensitivity
        })

    };
    removeItem = (index, e_index) => {
        const {resetFields} = this.props.form;
        resetFields();
        const {sensitivity} = this.state;
        console.log(sensitivity)
        sensitivity[index].params.splice(e_index, 1)
        console.log(sensitivity)
        this.setState({
            sensitivity: sensitivity
        })
    }

    add = () => {
        const {sensitivity} = this.state;
        sensitivity.push({value: '', params: []})
        this.setState({
            sensitivity: sensitivity
        })

    };
    addItem = (index) => {
        const {sensitivity} = this.state;
        sensitivity[index].params.push({})
        console.log(sensitivity)
        this.setState({
            sensitivity: sensitivity
        })
    };

    render() {
        const {visible, operationType} = this.props;
        const {sensitivity, name, imageUrl} = this.state;
        const {getFieldDecorator} = this.props.form;
        const keys = sensitivity;
        const formItems = keys.map((k, index) => {
            return (<div>
                <FormItem
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? '灵敏度方案' : ''}
                >
                    <div>
                        <FormItem style={{width: 'calc(40%)'}}>
                            {getFieldDecorator(`value[${index}]`, {
                                initialValue: k.value,
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                    required: true,
                                    message: '方案名称',
                                }],
                            })(
                                <Input placeholder="请输入方案名称"
                                       onChange={(e) => this.onChangeValue(index, e.currentTarget.value)}/>,
                            )}
                        </FormItem>
                        {k.params.map((element, e_index) => (
                            <div>
                                <Form.Item
                                    required={false}
                                    style={{display: 'inline-block', width: 'calc(40%)', marginRight: 8}}
                                >
                                    {getFieldDecorator(`params[${index}][${e_index}].key`, {
                                        initialValue: element.key,
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入属性key.",
                                            },
                                        ],
                                    })(<Input placeholder="key"
                                              onChange={(e) => this.onChangeParamsKey(index, e_index, e.currentTarget.value)}/>)}
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    style={{display: 'inline-block', width: 'calc(40%)', marginRight: 8}}
                                >
                                    {getFieldDecorator(`params[${index}][${e_index}].value`, {
                                        initialValue: element.value,
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入属性value.",
                                            },
                                        ],
                                    })(<Input placeholder="value"
                                              onChange={(e) => this.onChangeParamsValue(index, e_index, e.currentTarget.value)}/>)}
                                </Form.Item>
                                {k.params.length > 1 ? (
                                    <Icon
                                        className="dynamic-delete-button"
                                        type="minus-circle-o"
                                        onClick={() => this.removeItem(index, e_index)}
                                    />
                                ) : null}
                            </div>
                        ))}

                        <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button type="dashed" onClick={() => this.addItem(index)} style={{width: '60%'}}>
                                <Icon type="plus"/> 添加属性
                            </Button>

                            <Button type="dashed" onClick={() => this.remove(index)} style={{width: '60%'}}>
                                <Icon type="delete"/> 删除方案
                            </Button>
                        </Form.Item>
                    </div>
                </FormItem>
            </div>)
        });
        return (
            <Modal
                width={'60%'}
                visible={visible}
                title={operationType === operationTypeValue.INCREASE ? '添加方案' : '修改方案'}
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="名称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('name', {
                            initialValue: name,
                            rules: [{required: true, message: '请填写方案名称!'}],
                        })(
                            <Input placeholder="请填写方案名称" onChange={(e) => this.onChangeName(e.currentTarget.value)}/>,
                        )}
                    </FormItem>

                    <FormItem
                        label="图片"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: imageUrl ? imageUrl+'?x-oss-process=image/resize,w_86' : "",
                            rules: [{required: true, message: '图片未上传!'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                // width={200}
                                // height={200}
                                size={251}
                                onChange={(e) => this.onChangeImage(e)}
                            />
                        )}
                    </FormItem>
                    {/*<SensitivityConfigModel/>*/}

                    {formItems}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button type="dashed" onClick={this.add} style={{width: '60%'}}>
                            <Icon type="plus"/> 添加方案
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.sensitivityPlan
    }
};
const mapDispatchToProps = ({});
SensitivityPlansInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SensitivityPlansInfoModel)));
