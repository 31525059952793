
import createAsyncAction from 'utils/createAsyncAction';
import {
    CREATE_PARTNER_TEACHER,
    UPDATE_PARTNER_TEACHER,
    DELETE_PARTNER_TEACHER,
    UPDATE_PARTNER_TEACHER_STATUS,
    GET_PARTNER_TEACHER,
    GET_PARTNER_TEACHER_LIST,
    CREATE_PARTNER_GOODS,
    UPDATE_PARTNER_GOODS,
    DELETE_PARTNER_GOODS,
    UPDATE_PARTNER_GOODS_STATUS,
    GET_PARTNER_GOODS,
    GET_PARTNER_GOODS_LIST,
} from './actionTypes';
import PartnerTeacherRequest from "../../request/PartnerTeacherRequest";
import PartnerGoodsRequest from "../../request/PartnerGoodsRequest";


//新增导师
export const createPartnerTeacher = (params) => (createAsyncAction(CREATE_PARTNER_TEACHER,
    () => (PartnerTeacherRequest.createPartnerTeacher(params))));
//修改导师
export const updatePartnerTeacher = (id, params) => (createAsyncAction(UPDATE_PARTNER_TEACHER,
    () => (PartnerTeacherRequest.updatePartnerTeacher(id, params))));
//删除导师
export const deletePartnerTeacher = (id) => (createAsyncAction(DELETE_PARTNER_TEACHER,
    () => (PartnerTeacherRequest.deletePartnerTeacher(id))));

//更新导师状态
export const updatePartnerTeacherStatus = (id, params) => (createAsyncAction(UPDATE_PARTNER_TEACHER_STATUS,
    () => (PartnerTeacherRequest.updatePartnerTeacherStatus(id, params))));


//获取详情
export const getPartnerTeacher = (id) => (createAsyncAction(GET_PARTNER_TEACHER,
    () => (PartnerTeacherRequest.getPartnerTeacher(id))));

//查询导师列表
export const queryPartnerTeachers = (params) => (createAsyncAction(GET_PARTNER_TEACHER_LIST,
    () => (PartnerTeacherRequest.queryPartnerTeachers(params))));


//新增陪练商品
export const createPartnerGoods = (params) => (createAsyncAction(CREATE_PARTNER_GOODS,
    () => (PartnerGoodsRequest.createPartnerGoods(params))));
//修改陪练商品
export const updatePartnerGoods = (id, params) => (createAsyncAction(UPDATE_PARTNER_GOODS,
    () => (PartnerGoodsRequest.updatePartnerGoods(id, params))));
//删除陪练商品
export const deletePartnerGoods = (id) => (createAsyncAction(DELETE_PARTNER_GOODS,
    () => (PartnerGoodsRequest.deletePartnerTeacher(id))));

//更新陪练商品状态
export const updatePartnerGoodsStatus = (id, params) => (createAsyncAction(UPDATE_PARTNER_GOODS_STATUS,
    () => (PartnerGoodsRequest.updatePartnerGoodsStatus(id, params))));


//获取商品详情
export const getPartnerGoods = (id) => (createAsyncAction(GET_PARTNER_GOODS,
    () => (PartnerGoodsRequest.getPartnerGoods(id))));

//查询陪练商品列表
export const queryPartnerGoods = (params) => (createAsyncAction(GET_PARTNER_GOODS_LIST,
    () => (PartnerGoodsRequest.queryPartnerGoods(params))));