import React, {Component} from "react";
import {Form, Button, Select} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;


class CircleReportFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }




    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {type,result} = values;

                console.log(values)

                onCommit && onCommit(type,result);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {type,result} = values;

                console.log(values)

                onCommit && onCommit(type,result);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="反馈类型">
                    {getFieldDecorator('type', {
                            initialValue: null
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value="DYNAMIC">动态内容</Option>
                            <Option value="COMMENT">评论内容</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="处理结果">
                    {getFieldDecorator('result', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: 120}}>
                            <Option value={null}>全部</Option>
                            <Option value="INIT">未处理</Option>
                            <Option value="CLOSE">不予处理</Option>
                            <Option value="COMPLETE">已处理</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



export default Form.create()(CircleReportFilter);