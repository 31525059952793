import createAsyncAction from 'utils/createAsyncAction';
import ActivityRequest from '../../request/ActivityRequest' ;
import {
    GET_ACTIVITY,
    GET_ACTIVITY_DETAIL,
    GET_ACTIVITY_TYPE,
    ADD_ACTIVITY,
    EDIT_ACTIVITY,
    DEL_ACTIVITY,
    RECOMMEND_ACTIVITY

} from './actionTypes'

//获取活动
const getActivity= (params) => (
    createAsyncAction(GET_ACTIVITY,() => (ActivityRequest.getActivity(params)))
)
// 获取活动详情
const getActivityDetail = (id) =>(
    createAsyncAction(GET_ACTIVITY_DETAIL,() => (ActivityRequest.getActivityDetail(id)))
)
// 获取活动类型
const getActivityType = (params) =>(
    createAsyncAction(GET_ACTIVITY_TYPE,() => (ActivityRequest.getActivityType(params)))
)
// 新增活动
const addActivity = (params) =>(
    createAsyncAction(ADD_ACTIVITY,() => (ActivityRequest.addActivity(params)))
)

// 编辑活动
const editActivity = (id,params) =>(
    createAsyncAction(EDIT_ACTIVITY,() => (ActivityRequest.editActivity(id,params)))
)

// 删除活动
const delActivity = (id) =>(
    createAsyncAction(DEL_ACTIVITY,() => (ActivityRequest.delActivity(id)))
)

// 置顶活动
const recommendActivity = (params) =>(
    createAsyncAction(RECOMMEND_ACTIVITY,() => (ActivityRequest.recommendActivity(params)))
)

    

export {
    getActivity,
    getActivityDetail,
    getActivityType,
    addActivity,
    editActivity,
    delActivity,
    recommendActivity
}
