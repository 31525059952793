import React from 'react';
import PropTypes from 'prop-types';
import {ConnectedRouter} from 'connected-react-router';
import {MultiIntlProvider} from 'react-intl-context';
import {connect} from 'react-redux';
import AclRouter from 'react-acl-router';
import BasicLayout from 'layouts/BasicLayout';
import NormalLayout from 'layouts/NormalLayout';
import NotFound from 'pages/notfound';
import {buildConfig, messages} from '../config/buildConfig';
import {authorizedRoutes, normalRoutes} from '../config/routes';
import {ConfigProvider} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

const {locale} = buildConfig;

const propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

const Router = ({history, user}) => (

    <ConfigProvider locale={zhCN}>
        <ConnectedRouter history={history}>
            <MultiIntlProvider
                defaultLocale={locale}
                messageMap={messages}
            >
                <AclRouter
                    authorities={user.roleCodes}
                    authorizedRoutes={authorizedRoutes}
                    authorizedLayout={BasicLayout}
                    normalRoutes={normalRoutes}
                    normalLayout={NormalLayout}
                    notFound={NotFound}
                />
            </MultiIntlProvider>
        </ConnectedRouter>
    </ConfigProvider>
);

const mapStateToProps = (state) => ({
    user: state.authentication.user,
});

Router.propTypes = propTypes;
export default connect(mapStateToProps)(Router);
