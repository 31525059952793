import React from 'react';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';

import https from '../../utils/api';

import PropTypes from 'prop-types';
import {message} from 'antd';
import {operationTypeValue} from "../../modules/article/articleEnum";

const Upload_url = '/admin/oss/upload';


export default class EditorDemo extends React.Component {
    static propTypes = {
        style: PropTypes.object,//定义容器的样式
        value: PropTypes.string,//富文本内容
        onChange: PropTypes.func,//富文本改变时调用
        width: PropTypes.number, //图片默认宽度
        height: PropTypes.number, //图片默认高度
        aspectRatio: PropTypes.number, //宽高比(宽高比跟宽度高度同时上传以宽高比为主)
    };
    static defaultProps = {
        style: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            // 创建一个空的editorState作为初始值
            editorState: BraftEditor.createEditorState(props.value || null),
            html: ''
        };
    }

    isSize = file => {
        let {height, width, aspectRatio} = this.props;
        if ((width && height) || aspectRatio) {
            return new Promise((resolve, reject) => {
                // let _URL = window.URL || window.webkitURL;
                let img = new Image();

                img.onload = function () {
                    console.log(img.width, img.height);
                    let valid = aspectRatio
                        ? aspectRatio == img.width / img.height
                        : img.width == width && img.height == height;
                    valid ? resolve() : reject();
                };
                img.src = file;
            }).then(
                () => {
                    return true;
                },
                () => {
                    let ErrorMsg = aspectRatio
                        ? `图片的宽高比是${aspectRatio},请确认上传图片，修改后重新上传`
                        : `图片的宽度为${width},高度为${height},请确认上传图片，修改后重新上传`;
                    message.error(ErrorMsg);
                    return false;
                }
            );
        }
        return true;
    };
    setHtmlContent = (html = '') => {
        const htmlContent = html;
        // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorStat
        this.setState({
            editorState: BraftEditor.createEditorState(htmlContent)
        });
    };

    componentWillReceiveProps(nextProps) {
        this.setHtmlContent(nextProps.value);
    }

    //上传图片资源
    handleUploadImage = (source) => {
        let formData = new FormData();
        formData.append('file', source);
        return https.post(Upload_url, formData);
    };

    submitContent = async () => {
        // 在编辑器获得焦点时按下ctrl+s会执行此方法
        // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
        this.state.editorState.toHTML();
        // const result = await saveEditorContent(htmlContent)
    };
    uploadFn = (param) => {
        let {size} = this.props
        if (size) {
            console.log(size)
            console.log(param.file.size / 1024)
            const isLt2M = param.file.size / 1024 < (size - 1);
            if (!isLt2M) {
                message.error('图片大小超出限制1M，请修改后重新上传', 1.5);
                param.error()
            }
        }
        this.handleUploadImage(param.file)
            .then(async (res) => {
                const status = await this.isSize(res.url);
                status ? param.success({url: res.url+'?x-oss-process=image/resize,w_690'}) : param.error({msg: '格式错误'});
            })
            .catch((err) => {
                param.error({
                    msg: err.toString()
                });
            });
    };


    handleEditorChange = async (editorState) => {
        const html = await editorState.toHTML();
        const {onChange} = this.props;
        onChange(html);

    };
    submitContent = () => {
        console.log('hll');
    };

    render() {
        const {editorState} = this.state;
        const {style} = this.props;
        return (
            <div style={style}>
                <BraftEditor
                    value={editorState}
                    onBlur={this.handleEditorBlur}
                    onSave={this.submitContent}
                    stripPastedStyles={true}
                    media={{uploadFn: this.uploadFn}}
                    contentStyle={{
                        border: '1px solid black'
                    }}
                    colors={
                        [
                            '#2c3e50'
                        ]
                    }
                    onChange={this.handleEditorChange}

                />
            </div>
        );
    }
}