import React, { Component } from 'react';
import {
    Form, Button, InputNumber
} from 'antd';



const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class OperationAccountForm extends Component {


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmit } = this.props;
                onSubmit && onSubmit(values.cashOut);
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form layout="horizontal">
                <Form.Item
                    label="金额"
                    {...formItemLayout}
                >
                    {getFieldDecorator('cashOut', {
                        initialValue: 1,
                    })(
                        <InputNumber min={0}/>,
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create()(OperationAccountForm);
