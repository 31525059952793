import api from '../utils/api';
import {map} from 'lodash/fp';

class ClubRequest {

    /**
     * 获取俱乐部列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
    static getClub(params) {
        return api.get('/admin/clubs', params);
    }

    /**
     * 删除俱乐部试训
     * @param {Number} id
     */
    static async deleteTrain(id){
        return api.delete(`/admin/clubs/delete/${id}`);
    }

    /**
     * 恢复俱乐部试训
     * @param {Number} id
     */
    static async replyTrain(id){
        return api.put(`/admin/clubs/reply/${id}`);
    }

    /**
     * 获取俱乐部详情
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
    static getClubDetail(id) {
        return api.get(`/admin/clubs/${id}`);
    }

    /**
     * 新增俱乐部
     * @returns {*}
     */
    static addClub(params) {
        return api.post('/admin/clubs',params);
    }

    /**
     * 删除俱乐部
     * @returns {*}
     */
    static deleteClub(clubId) {
        return api.delete(`/admin/clubs/${clubId}`);
    }

    /**
     * 删除俱乐部赛事
     * @returns {*}
     */
    static deleteEvent(clubId,raceCourseId) {
        return api.delete(`/admin/clubs/${clubId}/race-courses/${raceCourseId}`);
    }

    /**
     * 修改俱乐部
     * @returns {*}
     */
    static editClub(clubId,params) {
        return api.put(`/admin/clubs/${clubId}`,params);
    }

    /**
     * 查看战队列表
     * @returns {*}
     */
    static getTeam(params) {
        return api.get('/admin/teams',params);
    }

    /**
     * 查看赛事记录
     * @returns {*}
     */
    static getEvent(clubId,page, size) {

        return api.get(`/admin/clubs/${clubId}/race-courses`, { page, size });
    }

    /**
     * 添加俱乐部赛事
     * @returns {*}
     */
    static addEvent(clubId,awardDate,name,grade) {
        console.log(clubId,awardDate,name,grade)
        let params = {awardDate,name,grade};
        return api.post(`/admin/clubs/${clubId}/race-courses`,params);
    }

    /**
     * 修改俱乐部赛事
     * @returns {*}
     */
    static updateEvent(id,awardDate,name,grade) {
        console.log(id,awardDate,name,grade)
        let params = {awardDate,name,grade};
        return api.put(`/admin/clubs/race-courses/${id}`,params);
    }

    /**
     * 绑定俱乐部
     * @returns {*}
     */
    static bindClub(params) {
        // console.log("aaaaaaaaaaaaaaaaaaaaaaaa",params)
        return api.post('/admin/teams/clubs',params);
    }

    /**
     * 解绑战队
     * @returns {*}
     */
    static untyingClub(params) {
        return api.put('/admin/teams/clubs',params);
    }

    /**
     * 查看试训列表
     * @returns {*}
     */
    static getTrial({page, size,userId,clubName,userName,dateRange}) {
        console.log(page,size,dateRange)
        let beginDate = null, endDate = null;
        if (dateRange !== undefined) {
            console.log(dateRange)
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            beginDate = dateRange[0] == undefined ? null : dateRange[0];
            endDate = dateRange[1] == undefined ? null : dateRange[1];
        }
        return api.get('/admin/clubs/trails',{page, size,trailPageTyp:"MANAGER",beginDate,endDate,userId,clubName,userName});
    }


    static publishTrial(id,params) {
        return api.put(`/admin/clubs/${id}/trail-plans`,params);
    }

    static getTrialDetail(id) {
        return api.get(`/admin/clubs/${id}/trail-plans`);
    }


    static trailPlanBrand() {
        return api.get(`/admin/clubs/trail-plans/brand`);
    }



    /**
     * 恢复试训计划
     * */
    static replyTrialPlan(id) {
        return api.put(`/admin/clubs/replyPlan/${id}`);
    }

    static optTrial(id,params) {
        return api.put(`/admin/clubs/trails/${id}/operation`,params);
    }

//审批俱乐部
    static approveClub(clubId,params) {
        console.log(clubId,params);
        return api.post(`/admin/clubs/${clubId}/operation`,params);
    }

    //获取所有游戏
    static getGame() {
        // return api.get('/admin/games');
        return api.get('/admin/configuration/GAME_LIST');
    }

    static async changeRecommend(clubId,recommendNum){
        console.log(recommendNum)
        return api.post(`/admin/clubs/${clubId}/recommend`,recommendNum);
    }

     /**
     * 自荐
     * @returns {*}
     */
    static getSignboard(params) {
        return api.get(`/admin/user/signboard`, params);
    }
    static addSignboard(params) {
        return api.post(`/admin/user/signboard`, params);
    }
    static delSignboard(id) {
        return api.delete(`/admin/user/signboard/${id}`);
    }
    static checkSignboard(id,params) {
        return api.put(`/admin/user/signboard/${id}/check`,params);
    }
    static setSignboardPriority(id,params) {
        return api.put(`/admin/user/signboard/priority/${id}`,params);
    }
    static setSignboardTop(id,params) {
        return api.put(`/admin/user/signboard/top/${id}`,params);
    }
    

    /*试训计划*/
    static getTrailPlanNew(params) {
        return api.get(`/admin/clubs/trail-plans/new`, params);
    }
    static setTrailPlanNewhomePage(id,bool) {
        return api.post(`/admin/clubs/trail/${id}/homePage`, bool);
    }

    
    /**
     * 删除试训计划
     * */
     static deleteTrialPlan(id) {
        return api.delete(`/admin/clubs/deletePlan/${id}`);
    }
}

export default ClubRequest;
