
const Config = require('buildConfig');
const localeMessages = require('i18n/locale.json');

const BUILD_DOMAIN = process.env.BUILD_DOMAIN || 'localhost';
const messages = localeMessages;
const buildConfig = Config[BUILD_DOMAIN];
export {
  messages,
  buildConfig,
};
