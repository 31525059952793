import createReducer from 'utils/createReducer';

import {
    GET_GUESS,
    GET_PREDICTION_DETAIL,
    GET_SCHEDULE, GET_SCHEDULE_DETAIL
} from './actionTypes'

const defaultState = () => ({
    scheduleList:{},//所有行程
    scheduleDetail:{}//行程详情
});

const onGetSchedule = (state,{payload}) => {
    return {
        ...state,
        scheduleList:payload,

    }

};

const onGetGuess = (state,{payload}) => {
    return {
        ...state,
        guessList:payload,

    }

};

const onGetScheduleDetail = (state,{payload}) => {
    return {
        ...state,
        scheduleDetail:payload,

    }

};

const onGetPredictionDetail = (state,{payload}) => {
    return {
        ...state,
        predictionDetail:payload,
    }

};


export default createReducer(defaultState, {
    [`${GET_SCHEDULE}_SUCCESS`]: onGetSchedule,
    [`${GET_GUESS}_SUCCESS`]: onGetGuess,
    [`${GET_SCHEDULE_DETAIL}_SUCCESS`]: onGetScheduleDetail,
    [`${GET_PREDICTION_DETAIL}_SUCCESS`]: onGetPredictionDetail,
});
