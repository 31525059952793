import React, {Component} from "react";
import {injectIntl} from "react-intl-context";
import {Button, Card, Col, message, Modal, Row, Table} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "utils/utils";
import {
    getGame,
    getTrailDetail,
    publishTrial,
    deleteTrialPlan,
    replyTrialPlan, getTrailPlanBrand,
    getTrailPlanNew,
    setTrailPlanNewhomePage,
} from "../../../modules/club/action";
import ChangeTrailPlanModel from "../components/ChangeTrailPlanModel";
import TrialPlanFilter from "../components/TrialPlanFilter";


const {confirm} = Modal;

class ClubPlanManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 15,
            isShowChangePlanModel: false,
            seletionValue: null,
            showId: false, //添加俱乐部
            teamParams: {
                clubId: null,
            },
        };
    }


    componentDidMount() {
        this.onGetData();
    }

    renderClubType = (text) => {
        if (text === 'COMMON') {
            return (
                <div color="blue">普通</div>
            )
        }else {
            return text;
        }
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary" onClick={() => {
                this.onAddPlanEvent()
            }}>新增</Button></Col>
        </Row>
    )


    render() {
        const {trialPlanList, loading,gameList} = this.props;
        console.log("trialPlanList", trialPlanList)
        const {showId, pageNo, pageSize, isShowChangePlanModel, seletionValue} = this.state;
        const defaultValue = seletionValue;

        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrialPlanFilter dataSource={{}} onCommit={this.onFilterClubEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'clubId',
                                    key: 'clubId',
                                    align: 'center',
                                },
                                {
                                    title: '俱乐部名称',
                                    dataIndex: 'clubName',
                                    key: 'clubName',
                                    align: 'center',
                                },
                                {
                                    title: '首页推荐位',
                                    dataIndex: 'homePage',
                                    key: 'homePage',
                                    align: 'center',
                                    render: (bool) => (
                                        <span>
                                            {bool?'是':'否'}
                                        </span>
                                    )
                                },
                                {
                                    title: '推荐优先级',
                                    dataIndex: 'priority',
                                    key: 'priority',
                                    align: 'center',
                                    render: (text) => (
                                        <span>
                                            {text?text:'0'}
                                        </span>
                                    )
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'clubType',
                                    key: 'clubType',
                                    align: 'center',
                                    render: (text) => (
                                        <div>
                                            {this.renderClubType(text)}
                                        </div>
                                    )
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'game',
                                    key: 'game',
                                    align: 'center',
                                    render: (row, text) => (
                                        <span>{gameList.filter((item) => item.code == text.game).length > 0 ? gameList.filter((item) => item.code == text.game)[0].gameName : ""}</span>
                                    ),
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onChangePlanEvent(text.clubId)}
                                                color="blue">修改试训计划</Button>
                                            {text.trainPlanState === 1 ?  <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onReplyTrainPlan(text.clubId)}
                                                  color="blue">恢复试训计划</Button> :
                                            <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteTrialPlanEvent(text.clubId)}
                                                 color="blue">删除试训计划</Button>}
                                            <Button type={text.homePage?"dashed":"primary"} style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                                this.confirmHomePage(text.clubId,{homePage:text.homePage})
                                            }} color="blue">{text.homePage?'已推荐至首页':'是否推荐至首页'}</Button>
                                        </div>

                                    ),
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={trialPlanList.data ? trialPlanList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: trialPlanList ? trialPlanList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            onShowSizeChange: this.onPageOnShowSizeChange,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />

                    <ChangeTrailPlanModel
                        showId={showId}
                        clubId={this.state.teamParams.clubId}
                        defaultValue={defaultValue ? defaultValue : []}
                        trailPlanBrands={this.props.trailPlanBrands}
                        onSuccess={this.onTrailPlanFormSubmit}
                        visible={isShowChangePlanModel}
                        onClose={() => {
                            this.setState({
                                isShowChangePlanModel: false,
                            })
                        }}
                    />
                </Card>
            </div>
        );
    }

    //条件查找俱乐部方法
    onFilterClubEvent = async (clubName) => {
        let {getTrailPlanNew} = this.props;
        let {pageSize} = this.state
        this.setState({
            pageNo: 1,
        }, () => {
            getTrailPlanNew({page: 1, size: pageSize, clubName});
        })
    }

    onGetData = () => {
        const {pageNo, pageSize} = this.state;
        let {getTrailPlanNew,getTrailPlanBrand,getGame} = this.props;
        getTrailPlanNew({page: pageNo, size: pageSize})
        getTrailPlanBrand()
        getGame()
    }
    //操作-删除
    onDeleteTrialPlanEvent = (id) => {
        const {deleteTrialPlan} = this.props;
        confirm({
            title: '删除?',
            content: '是否删除该条数据',
            okType: 'danger',
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                await deleteTrialPlan(id);
                message.success('删除成功');
                this.onGetData();
            },
        });
    }

    // 增加计划
    onAddPlanEvent = () => {
        this.setState({
            seletionValue:null,
            isShowChangePlanModel: true,
        });
    }
    //修改试训计划
    onChangePlanEvent = async (id, seletionValue) => {
        const {getTrailDetail} = this.props;
        let showId = false;
        try {
            seletionValue = await getTrailDetail(id);
            this.setState({
                isShowChangePlanModel: true,
                clubId: id,
            }, () => {
                this.setState({
                    showId: showId,
                    seletionValue
                })
            });
        } catch (e) {
            if (e && e.code === "TRAIL_PLAN_NOT_EXIST") {
                this.setState({
                    isShowChangePlanModel: true,
                    clubId: id,
                }, () => {
                    this.setState({
                        showId: true,
                        seletionValue
                    })
                });
            } else {
                seletionValue = null;
                showId = true;
            }
        }

    }
    //提交试训计划
    onTrailPlanFormSubmit = async (params) => {
        const {publishTrial} = this.props;
        const {clubId, isShowChangePlanModel} = this.state;
        let id = clubId||params.clubId;
        if (params.beginTime && params.endTime) {
            params.beginTime = utils.formatMomentToTimeStr(params.beginTime);
            params.endTime = utils.formatMomentToTimeStr(params.endTime)
        }

        await publishTrial(id, Object.assign(params));
        await this.onGetData();
        message.success('修改成功');
        this.setState({isShowChangePlanModel: !isShowChangePlanModel});
    }
    //恢复试训计划
    onReplyTrainPlan = async (id) => {
        const {replyTrialPlan} = this.props;
        try {
            await replyTrialPlan(id);
            message.success( '恢复成功');
        }catch (e) {
            console.log(e);

        }
        this.onGetData();
    }
    


    //是否推荐首页
    confirmHomePage = (id,params) => {
        let _this = this,homePage;
        if(!params.homePage){
            homePage = true
        }else{
            homePage = false
        }
        confirm({
            content: params.homePage?'不推荐至首页吗?':'确认要推荐至首页吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                
                _this.onSetHomePage(id,homePage)
            },
            onCancel() {
            },

        });
    }

    onSetHomePage = async (id,params)=>{
        let {setTrailPlanNewhomePage} = this.props;
        await setTrailPlanNewhomePage(id,params);
        await this.onGetData();
        message.success('操作成功');
    }

    //翻页
    onPageChangeEvent = (current, pageSize) => {
        const {getTrailPlanNew} = this.props;
        const {name, state, game} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        }, () => {
            getTrailPlanNew({page: current, size: pageSize, name: name, state: state, game: game});
        });
    }

    onPageOnShowSizeChange = (current, pageSize) => {
        const {getTrailPlanNew} = this.props;
        getTrailPlanNew({page: current, size: pageSize});
    }


    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.viewClub,

        }
    }
);

const mapDispatchToProps = {
    getTrailDetail,
    publishTrial,
    getGame,
    deleteTrialPlan,
    replyTrialPlan,
    getTrailPlanBrand,
    getTrailPlanNew,
    setTrailPlanNewhomePage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ClubPlanManagement)));
