import React, { Component } from 'react';
import {
    Form, Button, Select, Switch
} from 'antd';

const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class FirstRecommendModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            showInput : false
        }
    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmit } = this.props;
                const {showInput} = this.state;
                if(showInput){
                    onSubmit && onSubmit(this.props.trainingId,0);
                    return;
                }
                onSubmit && onSubmit(this.props.trainingId,values.recommendNum);
            }
        });
    }

    changeShow = () =>{
        if (this.state.showInput===false){
            this.setState({
                showInput : true
            })
        }
        if (this.state.showInput===true){
            this.setState({
                showInput : false
            })
        }


    }

    componentWillMount() {
        let { isFirstPage } = this.props;
        if (!isFirstPage) {
            this.setState({
                showInput : true
            })
        }
    }


    render() {
        let { isFirstPage } = this.props;
        const { getFieldDecorator } = this.props.form;
        const {showInput} = this.state;
        console.log(this.props)
        return (
            <div>

                <Form layout="horizontal">
                    <Form.Item
                        label="是否推荐"
                        {...formItemLayout}
                    >
                        <Switch checkedChildren="是" unCheckedChildren="否" defaultChecked={!showInput}  onClick={this.changeShow}/>
                    </Form.Item>
                    <Form.Item
                        label="推荐位"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('recommendNum', {
                            initialValue: !showInput?isFirstPage?isFirstPage:1:0,
                        })(
                            <Select style={{width: 120}} disabled={showInput}>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Form.create()(FirstRecommendModal);
