
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_TEAM_CERTIFICATION_INFO,
    GET_TEAM_CERTIFICATION_LIST,
    VERIFY_TEAM_CERTIFICATION,
    GET_TEAM_MEMBERS,
    ADD_TEAM_CERTIFICATION,
    UPDATE_TEAM_CERTIFICATION,
    GET_USER_TEAM_LIST_BY_QQ,
    GET_USER,
    UPDATE_USER_CERTIFICATION, 
    GET_USER_CREDENTIALS, 
    CHECK_CREDENTIALS, 
    GET_REAL_NAME_CREDENTIALS, 
    CHECK_REAL_NAME_CREDENTIALS,
    UPDATE_REAL_NAME_CERTIFICATION,
    GET_PERSON_CERTIFICATION_LIST,
    ADD_PERSON_CERTIFICATION,
    UPDATE_PERSON_CERTIFICATION_UP_OR_DOWN,
    UPDATE_PERSON_USER_SUSPEND,
    DELETE_PERSON_CERTIFICATION
} from './actionTypes';
import CertificationRequest from "../../request/CertificationRequest";

//战队参赛认证列表
export const getTeamCertificationList = (params) => (createAsyncAction(GET_TEAM_CERTIFICATION_LIST, () => (CertificationRequest.getCertifications(params))));

export const getCertificationTeamMembers = (brandId, teamShowId) => (createAsyncAction(GET_TEAM_MEMBERS, () => (CertificationRequest.getCertificationTeamMembers(brandId, teamShowId))));

//获取参赛认证信息
export const getTeamCertificationInfo = (brandId, teamId) => (createAsyncAction(GET_TEAM_CERTIFICATION_INFO, () => (CertificationRequest.getCertification(brandId, teamId))));

//审核参赛认证信息
export const verifyCertifiacation = (params) => (createAsyncAction(VERIFY_TEAM_CERTIFICATION, () => (CertificationRequest.verifyCertifiacation(params))));

//添加参赛认证
export const addTeamCertifiacation = (params) => (createAsyncAction(ADD_TEAM_CERTIFICATION, () => (CertificationRequest.addTeamCertifiacation(params))));

//修改参赛认证
export const updateTeamCertifiacation = (params) => (createAsyncAction(UPDATE_TEAM_CERTIFICATION, () => (CertificationRequest.updateTeamCertifiacation(params))));

//添加参赛认证
export const getUserTeamListByQQ = (params) => (createAsyncAction(GET_USER_TEAM_LIST_BY_QQ, () => (CertificationRequest.getUserTeamListByQQ(params))));

//修改用户认证资料
export const getUser = (userId) => (createAsyncAction(GET_USER, () => (CertificationRequest.getUser(userId))));

//修改用户认证资料
export const updateUserCertification = (userId,params) => (createAsyncAction(UPDATE_USER_CERTIFICATION, () => (CertificationRequest.updateUserCertification(userId,params))));

//用户认证资料列表
export const getUserCredentials = (params) => (createAsyncAction(GET_USER_CREDENTIALS, () => (CertificationRequest.getUserCredentials(params))));

//审核用户认证资料
export const checkCredentials = (id, params) => (createAsyncAction(CHECK_CREDENTIALS, () => (CertificationRequest.checkCredentials(id, params))));

//实名认证资料列表
export const getRealNameCredentials = (params) => (createAsyncAction(GET_REAL_NAME_CREDENTIALS, () => (CertificationRequest.getRealNameCredentials(params))));

//审核用户实名认证
export const checkRealNameCredentials = (userId, params) => (createAsyncAction(CHECK_REAL_NAME_CREDENTIALS, () => (CertificationRequest.checkRealNameCredentials(userId, params))));

//修改用户实名认证
export const updateRealNameCertification = (exhibitionId,params) => (createAsyncAction(UPDATE_REAL_NAME_CERTIFICATION, () => (CertificationRequest.updateRealNameCertification(exhibitionId,params))));

//个人训练赛-获取参赛认证列表
export const getPersonCertificationList = (params) => (createAsyncAction(GET_PERSON_CERTIFICATION_LIST, () => (CertificationRequest.getPersonCertificationList(params))));

//个人训练赛-新增参赛认证
export const addPersonCertification = (params) => (createAsyncAction(ADD_PERSON_CERTIFICATION, () => (CertificationRequest.addPersonCertification(params))));

//个人训练赛-修改用户参赛认证升降级
export const onUpdatePersonCertificationUpOrDown = (params) => (createAsyncAction(UPDATE_PERSON_CERTIFICATION_UP_OR_DOWN, () => (CertificationRequest.onUpdatePersonCertificationUpOrDown(params))));

//个人训练赛-设置用户禁赛
export const onUpdatePersonUserSuspend = (params) => (createAsyncAction(UPDATE_PERSON_USER_SUSPEND, () => (CertificationRequest.onUpdatePersonUserSuspend(params))));

//个人训练赛-删除用户参赛认证
export const onDeletePersonCertification = (certId) => (createAsyncAction(DELETE_PERSON_CERTIFICATION, () => (CertificationRequest.onDeletePersonCertification(certId))));
