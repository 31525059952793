import React from 'react';
import {Form, Table} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getAddTeams} from "../../../modules/training/action";

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingTeamMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            exhibitionId: '',
            username: '',
            memberType: ''
        };
    }

    componentWillMount() {
        this.requestTeam();
    }

    requestTeam = () => {
        let {page, size} = this.state;
        let {trainingId, getAddTeams} = this.props;
        getAddTeams({trainingId, page, size})
    }

    onPageChangeEvent = async (current, pageSize) => {
        this.requestTeam();
        this.setState({
            page: current,
            size: pageSize,
        });
    }

    render() {
        let {teamInfo} = this.props;
        const {page, size} = this.state;
        return (
            <Table
                dataSource={teamInfo.data ? teamInfo.data : []}
                columns={this.getTableColumns()}
                bordered
                pagination={{
                    total: teamInfo.total ? teamInfo.total : 0,
                    current: page,
                    pageSize: size,
                    onChange: this.onPageChangeEvent,
                    showTotal:(total)=>{
                        return `总计 ${total}条数据`;
                    }
                }}
            />
        );
    }

    getTableColumns = () => [
        {
            title: '战队ID',
            dataIndex: 'id',
            align: 'center',
            key: 'id',
        }, {
            title: '战队名',
            dataIndex: 'name',
            align: 'center',
            key: 'name',
        }, {
            title: '战队展示ID',
            dataIndex: 'showId',
            align: 'center',
            key: 'showId',
        }, {
            title: '战队LOGO',
            dataIndex: 'logo',
            align: 'center',
            key: 'logo',
            render: (row, text) => {
                return <img style={{height: 100}} alt={""} src={row}/>
            }
        }, {
            title: '是否属于俱乐部',
            dataIndex: 'belongClub',
            align: 'center',
            key: 'belongClub',
            render: (row, text) => (
                row ? "是" : "否"
            ),
        },]
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getAddTeams
});
TrainingTeamMembers.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingTeamMembers)));
