import createReducer from 'utils/createReducer';
import {GET_QRCODE_LIST} from './actionTypes';

const defaultState = () => ({
    qrcodes: {
        data: [],
        total: 0,
    }, // 二维码url列表
    qrcodeInfo: {}, // 二维码url

});


const onGetQrcodeListSuccess = (state, {payload}) => ({
    ...state,
    qrcodes: payload,
});


export default createReducer(defaultState, {
    [`${GET_QRCODE_LIST}_SUCCESS`]: onGetQrcodeListSuccess,
});
