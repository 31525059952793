import api from '../utils/api';

class BannerRequest {

    /**
     * 获取轮播图列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
    static getBanners(pageNo, pageSize,pageType) {
        return api.get('/admins/banners', {pageSize, pageNo,pageType});
    }

    /**
     * 更新轮播图
     * @param id
     * @param imageUrl
     * @param urlType
     * @param title
     * @param description
     * @param url
     * @param enable
     * @param jumpType
     * @param outerUrl
     * @param innerJumpType
     * @param attach
     * @returns {*}
     */
    static updateBanner(id, {imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside}) {
        return api.put(`/admins/banners/${id}`, {
            imageUrl,
            title,
            description,
            enable,
            ordinal,
            jumpType,
            outerUrl,
            innerJumpType,
            attach,
            pageType,
            jumpOutside
        });
    }

    /**
     * 获取轮播图详情
     * @param id
     * @returns {*}
     */
    static getBanner(id) {
        return api.get(`/admins/banners/${id}`);
    }

    /**
     * 删除轮播图
     * @param id
     * @returns {*}
     */
    static deleteBanner(id) {
        return api.delete(`/admins/banners/${id}`);
    }

    /**
     *
     * @param imageUrl
     * @param urlType
     * @param title
     * @param description
     * @param url
     * @param enable
     * @param jumpType
     * @param outerUrl
     * @param innerJumpType
     * @param attach
     * @returns {*}
     */
    static addBanner({imageUrl, title, description, enable, ordinal,jumpType, outerUrl, innerJumpType, attach,pageType,jumpOutside}) {
        return api.post(`/admins/banners`, {
            imageUrl,
            title,
            description,
            enable,
            ordinal,
            jumpType,
            outerUrl,
            innerJumpType,
            attach,
            pageType,
            jumpOutside
        })
    }

    /**
     * 更改轮播图状态
     * @param id
     * @param enable
     * @returns {*}
     */
    static changeStatus(id, enable) {
        return api.put(`/admins/banners/status/${id}/${enable}`, {id, enable})
    }

    static getInnerJumpType() {
        return api.get("/admins/banners/inner_jump_type");
    }


    /**
     * 添加身价图
     * */
    static getValuation(imageUrl,enable,pageType) {
        console.log(pageType)

        return api.post(`/admins/banners/valuation`, {imageUrl,enable,pageType});
    }

    /**
     * 获取身价图
     * */
    static getImage(pageType) {

        return api.get(`/admins/banners/adminValuation`,{pageType});
    }

    /**
     * 修改身价图
     * */
    static updateValuation(imageUrl,enable,pageType) {
        console.log(pageType)

        return api.post(`/admins/banners/updateImage`, {imageUrl,enable,pageType});
    }


}

export default BannerRequest;