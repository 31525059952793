import createAsyncAction from 'utils/createAsyncAction';
import DynamicRequest from '../../request/DynamicRequest';
import {GET_DYNAMICS,DELETE_DYNAMIC,GET_COMMENTS,UPDATE_DYNAMIC,DELETE_COMMENT,UPDATE_COMMENT,TOP_DYNAMIC

} from "./actionTypes";


const getDynamic = (pageNo, pageSize,circleName,userId,dynamicContent,DynamicStatus,dateRange) => (
    createAsyncAction(GET_DYNAMICS, () => (DynamicRequest.getDynamic(pageNo, pageSize,circleName,userId,dynamicContent,DynamicStatus,dateRange)))
);

const deleteDynamic = (id) => (createAsyncAction(DELETE_DYNAMIC,
    () => (DynamicRequest.deleteDynamic(id))));

const getComment = (pageNo, pageSize,dynamicId,commentType) => (
    createAsyncAction(GET_COMMENTS, () => (DynamicRequest.getComment(pageNo, pageSize,dynamicId,commentType)))
);

const updateDynamicStatus = (dynamicId) => (
    createAsyncAction(UPDATE_DYNAMIC, () => (DynamicRequest.updateDynamicStatus(dynamicId)))
);

const topToDynamic = (dynamicId,allMakeTop,circleMakeTop) => (
    createAsyncAction(TOP_DYNAMIC, () => (DynamicRequest.topToDynamic(dynamicId,allMakeTop,circleMakeTop)))
);

const shieldComment = (params) => (createAsyncAction(DELETE_COMMENT,
    () => (DynamicRequest.shieldComment(params))));

const updateCommentStatus = (id) => (
    createAsyncAction(UPDATE_COMMENT, () => (DynamicRequest.updateCommentStatus(id)))
);

export {
    getDynamic,deleteDynamic,getComment,updateDynamicStatus,shieldComment,updateCommentStatus,topToDynamic
};