import React, {Component} from "react";
import {Form, Button, Select, Input} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;


class GiftFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onCommit } = _self.props;
                let { activityName, gameType } = values;

                console.log(values)

                onCommit && onCommit(activityName, gameType);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {gameList} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="券票名称">
                    {getFieldDecorator('activityName', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="游戏类型:">
                    {getFieldDecorator('gameType', {
                        initialValue:"",
                    })(
                        <Select style={{width: 120}}>
                            <Option value="">全部</Option>
                            {
                                gameList && gameList.length > 0 && gameList.map((val,index)=>{
                                    return(
                                        <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                    )
                                })
                            }

                        </Select>,
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}


export default Form.create()(GiftFilter);
