import React from 'react';
import {Form, Input, message, Modal, Radio, Select} from 'antd';
import {fromJS, is} from 'immutable';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UserSearch from "./UserSearch";
import {getUsers} from "../../modules/user/action";
import {queryPartnerGoods} from "../../modules/partner-training/action";


const {confirm} = Modal;
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddPartnerOrderModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            user: null,
            submitbutton: false,
            game: "HPJY"
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            defaultValue,
        });

        const {queryPartnerGoods, getGameList} = this.props;
        const {game} = this.state;
        getGameList();
        queryPartnerGoods({page: 1, size: 100, game: game, state: true});
    }

    onSubmit = () => {
        let {user} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (user) {
                    values["userId"] = user.id
                }
                console.log(values)
                const {onSuccess} = this.props;
                confirm({
                    content: '确认要发布该订单吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        onSuccess && onSuccess(values);
                    },
                    onCancel() {
                    },
                });

            }
        });
    }

    onChange = async (checked) => {
        const {queryPartnerGoods} = this.props;
        console.log(`switch to ${checked}`);
        console.log(`switch to ${queryPartnerGoods}`);
        await queryPartnerGoods({page: 1, size: 100, game: checked, state: true});
        this.setState({
            game: checked
        })
    }

    //前置条件
    onUserSearchSubmit = async (params) => {
        const {resetFields} = this.props.form;
        resetFields();
        this.addUserToPartnerTeacher(params)
    }

    addUserToPartnerTeacher = (params) => {
        let {users} = this.props
        console.log(users)
        let {getUsers} = this.props;
        getUsers(params.exhibitionId)
            .then(res => {
                if (res.total > 0) {
                    if (res.data[0].state === 'BAN') {
                        message.error("该账号已被冻结")
                        this.setState({
                            user: null
                        })
                    } else {
                        this.setState({
                            user: res.data[0]
                        })
                    }
                } else {
                    message.error("用户不存在")
                    this.setState({
                        user: null
                    })
                }
            })
            .catch(e => {
                console.log(e)
            });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {value, visible, operationType, games, partnerGoodsList} = this.props;
        const {user} = this.state;
        return (
            <div>
                <Modal
                    width={'30%'}
                    visible={visible}
                    title={"添加订单"}
                    onCancel={this.props.onClose}
                    cancelText={"取消"}
                    onOk={this.onSubmit}
                    okText={"确认"}
                >
                    {operationType === operationTypeValue.UPDATE ? null :
                        <UserSearch dataSource={{}} onCommit={this.onUserSearchSubmit}/>}
                    <Form>
                        {operationType === operationTypeValue.UPDATE ? null : <div><FormItem
                            label="学员展示ID"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('exhibitionId', {
                                initialValue: user ? user.exhibitionId : '',
                                rules: [{required: true, message: '学员展示ID不能为空!'}],
                            })(
                                <Input disabled={true}/>,
                            )}
                        </FormItem>
                            <FormItem
                                label="学员昵称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('username', {
                                    initialValue: user ? user.username : '',
                                    rules: [{required: true, message: '学员昵称不能为空!'}],
                                })(
                                    <Input disabled={true}/>,
                                )}
                            </FormItem>

                            <FormItem
                                label="游戏名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('game', {
                                    initialValue: games.filter((item) => item.code === value.game).length > 0 ? games.filter((item) => item.code === value.game)[0].code : "HPJY",
                                    rules: [{required: true, message: '请选择游戏类型!'}],
                                })(
                                    <Select placeholder="请选择游戏类型" defaultValue={"HPJY"} onChange={this.onChange}>
                                        {games.map((item) => <Select.Option value={item.code}
                                                                            key={item.code}>{item.gameName}</Select.Option>)}
                                    </Select>,
                                )}
                            </FormItem></div>
                        }

                        <FormItem label="陪练模式"
                                  {...formItemLayout}>
                            {getFieldDecorator('goodsId', {
                                initialValue: partnerGoodsList.data && partnerGoodsList.data.length > 0 ? partnerGoodsList.data[0].id : undefined,
                                rules: [{required: true, message: '请选择陪练模式!'}],
                            })(
                                <Select placeholder="请选择陪练模式">
                                    {partnerGoodsList && partnerGoodsList.data.map((item) => <Select.Option
                                        value={item.id}
                                        key={item.id}>{item.goodsName}</Select.Option>)}
                                </Select>,
                            )}
                        </FormItem>


                        <FormItem label="游戏区服"
                                  {...formItemLayout}>
                            {getFieldDecorator('area', {
                                    initialValue: 'iOS/微信',
                                    rules: [{required: true, message: '游戏区服不能为空!'}],
                                }
                            )(
                                <Radio.Group defaultValue="iOS/微信" buttonStyle="solid">
                                    <Radio.Button value="iOS/微信">iOS微信</Radio.Button>
                                    <Radio.Button value="iOS/手Q">iOS手Q</Radio.Button>
                                    <Radio.Button value="Android/微信">Android微信</Radio.Button>
                                    <Radio.Button value="Android/手Q">Android手Q</Radio.Button>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem label="指导时长"
                                  {...formItemLayout}>
                            {getFieldDecorator('gameTime', {
                                    initialValue: '0.5',
                                    rules: [{required: true, message: '指导时长不能为空!'}],
                                }
                            )(
                                <Radio.Group defaultValue="0.5" buttonStyle="solid">
                                    <Radio.Button value="0.5">0.5</Radio.Button>
                                    <Radio.Button value="1">1</Radio.Button>
                                    <Radio.Button value="2">2</Radio.Button>
                                    <Radio.Button value="3">3</Radio.Button>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem label="性别要求"
                                  {...formItemLayout}>
                            {getFieldDecorator('gender', {
                                    initialValue: 'UNKNOWN',
                                    rules: [{required: true, message: '性别要求不能为空!'}],
                                }
                            )(
                                <Radio.Group defaultValue="UNKNOWN" buttonStyle="solid">
                                    <Radio.Button value="UNKNOWN">无要求</Radio.Button>
                                    <Radio.Button value="MALE">GG</Radio.Button>
                                    <Radio.Button value="FEMALE">MM</Radio.Button>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem label="截止时间"
                                  {...formItemLayout}>
                            {getFieldDecorator('signUpDurable', {
                                    initialValue: '1',
                                    rules: [{required: true, message: '截止时间不能为空!'}],
                                }
                            )(
                                <Radio.Group defaultValue="1" buttonStyle="solid">
                                    <Radio.Button value="1">1</Radio.Button>
                                </Radio.Group>
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.user
    }
};
const mapDispatchToProps = ({
    getGameList,
    getUsers,
    queryPartnerGoods
});
AddPartnerOrderModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddPartnerOrderModel)));