import React from 'react';
import {Form, Input, Modal,DatePicker,Select, Button,notification,Icon } from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import Map from "./Map"
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isSignIn:this.props.data.needSignIn,
            isShowMap:false,
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const diff1 = moment(values.endTime).diff(moment(values.startTime))
                if(diff1<0){
                    notification.error({message:'结束时间不能早于开始时间，请重新选择时间'})
                    return;
                }
                let val = {
                    ...values,
                    startTime:values.startTime?moment(values.startTime).format('YYYY-MM-DD HH:mm:00'):'',
                    endTime:values.endTime?moment(values.endTime).format('YYYY-MM-DD HH:mm:00'):'',
                    userShowTime:values.userShowTime?moment(values.userShowTime).format('YYYY-MM-DD HH:mm:00'):'',
                    vipShowTime:values.vipShowTime?moment(values.vipShowTime).format('YYYY-MM-DD HH:mm:00'):'',
                  }
                onSuccess && onSuccess(val,this.props.type);
            }
        });
    }
    onChange(value, dateString) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,type,data,gameList} = this.props
        let{isSignIn,isShowMap} = this.state;
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title="添加行程"
                onCancel={this.props.onClose}
                // cancelText="取消"
                // onOk={this.onSubmit}
                // okText={"确定"}
                footer={
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]

            }
            >
                <Form>
                    <FormItem label="行程开始时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('startTime', {
                                rules: [{required: true, message: '请输入行程开始时间'}],
                                initialValue:type!=='ADD'?moment(data.startTime,dateFormat):moment()

                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />

                        )}
                    </FormItem>
                    <FormItem label="行程结束时间"
                        {...formItemLayout}
                        >
                        {getFieldDecorator('endTime', {
                                rules: [{required: true, message: '请输入行程结束时间'}],
                                initialValue:type!=='ADD'?moment(data.endTime,dateFormat):moment()
                            }
                        )(
                            <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                        )}
                    </FormItem>
                    <FormItem
                        label="行程标题"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('activityName', {
                            rules: [{max: 50, message: '行程标题不得超过50个字符!'},{required: true, message: '请输入行程标题'}],
                            initialValue:type!=='ADD'?data.activityName:''
                        })(
                            <Input placeholder="请输入行程标题"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="行程地点"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('activityAddress', {
                            rules: [{max: 50, message: '行程地点不得超过50个字符!'},{required: true, message: '请输入行程地点'}],
                            initialValue:type!=='ADD'?data.activityAddress:''
                        })(
                            <Input placeholder="请输入行程地点"/>
                        )}
                    </FormItem>

                    <FormItem
                        label="战队A名称："
                        {...formItemLayout}

                    >
                        {getFieldDecorator('leftTeamName', {
                            rules: [{required: true, message: '战队A名称'}],
                            initialValue:type!=='ADD'?data.leftTeamName:''
                        })(
                            <Input placeholder="请输入战队A名称"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="战队A图片"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('leftTeamLogo', {
                            initialValue: type!=='ADD'?data.leftTeamLogo:'',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>


                    <FormItem
                        label="战队B名称："
                        {...formItemLayout}

                    >
                        {getFieldDecorator('rightTeamName', {
                            rules: [{required: true, message: '请输入战队B名称'}],
                            initialValue:type!=='ADD'?data.rightTeamName:''
                        })(
                            <Input placeholder="请输入战队B名称"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="战队B图片"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('rightTeamLogo', {
                            initialValue: type!=='ADD'?data.rightTeamLogo:'',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>


                    <FormItem
                        label="战队A分数："
                        {...formItemLayout}

                    >
                        {getFieldDecorator('leftTeamScore', {
                            // rules: [{required: true, message: '请输入战队A分数'}],
                            initialValue:type!=='ADD'?data.leftTeamScore:''
                        })(
                            <Input placeholder="请输入战队A分数"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="战队B分数："
                        {...formItemLayout}

                    >
                        {getFieldDecorator('rightTeamScore', {
                            // rules: [{required: true, message: '请输入战队B分数'}],
                            initialValue:type!=='ADD'?data.rightTeamScore:''
                        })(
                            <Input placeholder="请输入战队B分数"/>
                        )}
                    </FormItem>


                    {
                        <FormItem
                            label="是否需要签到打卡:"
                            {...formItemLayout}
                            style={{display:"flex"}}
                        >
                            {getFieldDecorator('needSignIn', {
                                initialValue:type!=='ADD'?data.needSignIn:false,
                                rules: [{required: true, message: '请选择是否需要签到打卡'}],
                            })(
                                <Select style={{width: 120}} onChange={this.onChangeJumpType}>
                                    <Option value={true} >是</Option>
                                    <Option value={false} >否</Option>
                                </Select>
                            )}
                            <Button disabled={!isSignIn} type="primary" onClick={()=>{this.setState({ isShowMap : true });}} >
                                <Icon type="environment" />
                                设置活动地点经纬度
                            </Button>
                        </FormItem>
                    }
                    {
                        <FormItem
                            label="活动地点经度"
                            {...formItemLayout}

                        >
                            {getFieldDecorator('longitude', {
                                rules: [{required: isSignIn, message: '请设置活动地点经度'}],
                                initialValue:type!=='ADD'?data.longitude:''
                            })(
                                <Input disabled placeholder="活动地点经度"/>
                            )}
                        </FormItem>
                    }
                    {
                        <FormItem
                             label="活动地点纬度"
                            {...formItemLayout}

                        >
                            {getFieldDecorator('latitude', {
                                rules: [{required: isSignIn, message: '请设置活动地点纬度'}],
                                initialValue:type!=='ADD'?data.latitude:''
                            })(
                                <Input disabled placeholder="活动地点纬度"/>
                            )}
                        </FormItem>
                    }
                     {
                        <FormItem
                            label="签到打卡范围"
                            {...formItemLayout}

                        >
                            {getFieldDecorator('distance', {
                                rules: [{required: isSignIn, message: '请设置允许用户定位打卡的距离范围(单位：米)'}],
                                initialValue:type!=='ADD'?data.distance:''
                            })(
                                <Input disabled={!isSignIn} placeholder="允许用户定位打卡的距离范围(单位：米)"/>
                            )}
                        </FormItem>
                    }
                    <FormItem label="游戏类型:"
                        {...formItemLayout}

                    >
                         {getFieldDecorator('gamingDivision', {
                            initialValue:type!=='ADD'?data.gamingDivision:'',
                            rules: [{required: true, message: '请选择游戏类型'}],
                        })(
                            <Select style={{width: 120}}>
                                {
                                    gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="行程配图670*380"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: type!=='ADD'?data.imageUrl:'',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="行程介绍"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('description', {
                            initialValue:type!=='ADD'?data.description:'',
                            rules: [{max: 500, message: '行程介绍不得超过500个字符!'},{required: true, message: '请填写行程介绍'}],
                        })(
                            <TextArea rows={6} placeholder="请填写行程介绍"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="直播链接"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('liveUrl', {
                            initialValue:type!=='ADD'?data.liveUrl:'',
                        })(
                            <Input placeholder="请填写直播链接"/>,
                        )}
                    </FormItem>
                    <FormItem label="是否需要报名:"
                        {...formItemLayout}

                    >
                         {getFieldDecorator('needSignUp', {
                            initialValue:type!=='ADD'?data.needSignUp:false,
                            rules: [{required: true, message: '请选择是否需要报名'}],
                        })(
                            <Select style={{width: 120}}>
                                <Option value={true} >是</Option>
                                <Option value={false} >否</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="普通用户查看时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userShowTime', {
                                rules: [{required: true, message: '请输入普通用户查看时间'}],
                                initialValue:type!=='ADD'?moment(data.userShowTime,dateFormat):moment()

                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />

                        )}
                    </FormItem>
                    <FormItem label="VIP查看时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('vipShowTime', {
                                rules: [{required: true, message: '请输入VIP查看时间'}],
                                initialValue:type!=='ADD'?moment(data.vipShowTime,dateFormat):moment()

                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />

                        )}
                    </FormItem>
                </Form>
                <Map
                    onSuccess={this.onSetLatAndLng}
                    visible={isShowMap}
                    onClose={() => {
                        this.setState({
                          isShowMap: false,
                        });
                    }}
                    lng={type!=='ADD'&&this.props.data.longitude}
                    lat={type!=='ADD'&&this.props.data.latitude}
                ></Map>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
ScheduleModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(ScheduleModal)));
