import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import GiftForm from './GiftForm';
import GiftFilter from "./GiftFilter";
import CDKModal from './CDKModal';
import { operationTypeValue } from "../../modules/gift/giftEnum";
import { getGiftList, createGift, updateGift, deleteGift } from '../../modules/gift/action';

class GiftManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            showEditGiftModal: false,
            giftId: null,
            showCDKModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getGiftList } = this.props;
        getGiftList({page, size});
    }

    onPageChangeEvent = (current, size) => {
        let { getGiftList } = this.props;
        let { giftName, giftType } = this.state;
        this.setState({
            page: current,
            size
        });
        getGiftList({page: current, size, giftName, giftType});
    }

    onFilterGiftEvent = (giftName, giftType) => {
        const { getGiftList } = this.props;
        const { size } = this.state;
        this.setState({
            giftName, 
            giftType,
            page: 1
        });
        getGiftList({page: 1, size, giftName, giftType});
    }

    onOpenEditGiftModalEvent = (text, type) => {              
        if (type === operationTypeValue.UPDATE) {           
            this.setState({
                giftInfo: { ...text, type }
            });
        } else {
            this.setState({
                giftInfo: { type }
            });
        }
        this.setState({
            operationType: type,
            showEditGiftModal: true,
        });
    }

    onCloseEditGiftModalEvent = () => {
        this.setState({
            showEditGiftModal: false
        });
    }
    
    // 添加礼品
    onAddGiftEvent = async (params) => {
        const {page, size} = this.state;
        const {createGift, getGiftList} = this.props;
        try {
            await createGift(params);
            notification.success({message: '添加成功'});
            this.setState({
                showEditGiftModal: false
            });
            getGiftList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑礼品
    onEditGiftEvent = async (params) => {
        const {page, size, giftInfo} = this.state;
        const {updateGift, getGiftList} = this.props;
        try {
            await updateGift(giftInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditGiftModal: false,
            });            
            getGiftList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 删除礼品
    onDeleteGiftEvent = async (giftId) => {
        const {deleteGift, getGiftList} = this.props;
        const {page, size} = this.state;
        try {
            await deleteGift(giftId);
            notification.success({message: '删除成功'});
            getGiftList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onOpenCDKModal = (giftId, type) => {
        this.setState({
            giftId
        }, () => {
            this.setState({
                operationType: type,
                showCDKModal: true
            });
        });
    }

    onCloseCDKModal = () => {
        this.setState({
            operationType: operationTypeValue.INCREASE,
            showCDKModal: false
        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onOpenEditGiftModalEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    render() {
        const {
            giftList, loading
        } = this.props;
        const {
            showEditGiftModal, page, size, operationType, giftInfo, showCDKModal, giftId
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <GiftFilter dataSource={{}} onCommit={this.onFilterGiftEvent}></GiftFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '礼品ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '礼品名',
                                    dataIndex: 'giftName',
                                    key: 'giftName',
                                    align: 'center',
                                },
                                {
                                    title: '礼品类型',
                                    dataIndex: 'giftType',
                                    key: 'giftType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.giftType === 'IN_KIND_GIFT' ? '实物周边' : ''}
                                            {text.giftType === 'VIP' ? 'VIP会员（虚拟礼品）' : ''}
                                            {text.giftType === 'ENERGY' ? '能量值（虚拟礼品）' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '礼品用途',
                                    dataIndex: 'giftPurpose',
                                    key: 'giftPurpose',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.giftPurpose === 'COMMEMORATIVE_CARD' ? '纪念卡' : 'CDK'}</div>
                                    )
                                },
                                {
                                    title: '礼品份数',
                                    dataIndex: 'copies',
                                    key: 'copies',
                                    align: 'center',
                                },
                                {
                                    title: '礼品数量',
                                    dataIndex: 'giftQuantity',
                                    key: 'giftQuantity',
                                    align: 'center',
                                },
                                {
                                    title: '已兑换数量',
                                    dataIndex: 'redeemedQuantity',
                                    key: 'redeemedQuantity',
                                    align: 'center',
                                },
                                {
                                    title: '礼品过期时间',
                                    dataIndex: 'giftExpirationTime',
                                    key: 'giftExpirationTime',
                                    align: 'center',
                                },
                                {
                                    title: '礼品创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                                                                        
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>                                          
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditGiftModalEvent(text, operationTypeValue.UPDATE)}
                                                    color="blue">编辑</Button>
                                            {text.giftPurpose === 'CDK' && <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenCDKModal(text.id, operationTypeValue.SELECT)}
                                                    color="blue">查看CDK</Button>}
                                            <Popconfirm title={`确定要删除该礼品吗？`}
                                                        onConfirm={() => this.onDeleteGiftEvent(text.id)}
                                                        okText="确认" cancelText="取消"><br/>
                                                <Button type="danger" style={{
                                                    marginLeft:10,
                                                    marginBottom:10,
                                                    backgroundColor: '#ff4d4f',
                                                    color: '#fff'
                                                }}>删除</Button>
                                            </Popconfirm>
                                        </div>
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={giftList.data ? giftList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: giftList ? giftList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑礼品" : "添加礼品"}
                        visible={showEditGiftModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditGiftModalEvent}
                        width={"25%"}
                    >
                        {operationType === operationTypeValue.UPDATE ?
                            <GiftForm {...this.props} {...this.state} {...giftInfo}
                                        onSubmitEvent={this.onEditGiftEvent}/> :
                            <GiftForm {...giftInfo} onSubmitEvent={this.onAddGiftEvent}/>}
                    </Modal>            
                </Card>
                {operationType === operationTypeValue.SELECT && <CDKModal
                    visible={showCDKModal}
                    giftId={giftId}
                    onClose={this.onCloseCDKModal}
                />}
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Banner,
            ...state.innerJumpList,
            ...state.images,
            ...state.Gift
        }

    }

);

const mapDispatchToProps = {
    getGiftList,
    createGift, 
    updateGift,
    deleteGift,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(GiftManagement)));
