import React, {Component} from 'react';
import {
    Form, Input, Select, Button, Modal
} from 'antd';
import moment from "moment";
import UpLoad from "../../components/upload";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};
const dateFormat = "YYYY-MM-DD HH:mm:00";


class MedalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillMount(){

    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() {
        let {
            visible, onCancel, onSubmitEvent,
            childMedalId, level, childMedalName, greyMedalPic, colorMedalPic, conditionNum, childMedalDesc, medalType, type
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        let title = childMedalName ? "新增“" + childMedalName + "勋章”" : "新增勋章";

        return (
            <Modal
                title={title}
                visible={visible}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"35%"}
            >
                <Form>
                    {type === 'UPDATE_SUB' && <FormItem
                        label="勋章ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('childMedalId', {
                            rules: [{required: true}],
                        })(
                            <Input   placeholder="请输入勋章ID"/>,
                        )}
                    </FormItem>}
                    <FormItem
                        label="勋章等级"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('level', {
                            rules: [{required: true}],
                        })(
                            <Input placeholder="请输入勋章等级" />,
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('childMedalName', {
                            rules: [{required: true, message: '请输入勋章名，不能包含空格', pattern: /^\S+$/}, {max: 10, message: '勋章名在10字以内'}],
                        })(
                            <Input placeholder="请输入勋章名" />,
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章灰色图标"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('greyMedalPic', {
                            rules: [{required: true, message: '请上传勋章灰色图标'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章获得图标"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('colorMedalPic', {
                            rules: [{required: true, message: '请上传勋章获得图标'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    {medalType !== 'SPECIAL' && <FormItem
                        label="获得条件"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('conditionNum', {
                            rules: [{required: true, message: '请输入勋章获得条件，只能是数字', pattern: /^[0-9]*$/}],
                        })(
                            <Input placeholder={'请输入勋章获得条件'} />,
                        )}
                    </FormItem>}
                    <FormItem
                        label="勋章描述"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('childMedalDesc', {
                            rules: [{max: 50, message: '勋章描述在50字以内'},{required: true, message: '请输入勋章描述'}],
                            initialValue: childMedalDesc || '',
                        })(
                            <TextArea rows={2} placeholder="50字以内">{childMedalDesc || ''}</TextArea>,
                        )}
                    </FormItem>

                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(MedalForm);
