
export const operationTypeValue = {
    INCREASE: 'INCREASE',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    SELECT: 'SELECT',
    INCREASE_POST: 'INCREASE_POST',
    UPDATE_ADDRESS: 'UPDATE_ADDRESS',
    UPDATE_SHIP: 'UPDATE_SHIP'
};
