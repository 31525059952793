import api from 'utils/api';

class LiveRequest {
  /**
     * 查询直播配置列表
     * @param {Object} param 请求参数
     */
  static async getLiveData(param) {
    return api.get(`/admin/live/config`, param);
  }

  /**
   * 查询直播配置详情
   * @param id 
   */
  static async getLiveDetail(id) {
    return api.get(`/admin/live/config/${id}`);
  }

  /**
   * 新增直播配置
   * @param {Object} param 请求参数
   */
  static async addLive(param) {
    return api.post(`/admin/live/config/create`, param);
  }

  /**
   * 编辑直播配置
   * @param {Object} param 请求参数（包含id）
   */
  static async updateLive(param) {
    return api.put(`/admin/live/config/update`, param);
  }

  /**
   * 删除直播
   * @param id 
   */
  static async deleteLive(id) {
    return api.delete(`/admin/live/config/${id}`);
  }

}

export default LiveRequest;
