import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import PlayerDetailForm from './PlayerDetailForm';
import PlayerFilter from "./PlayerFilter";
import PlayerPostForm from "./PlayerPostForm";
import { operationTypeValue } from "../../modules/playerDetail/playerDetailEnum";
import { getPlayerPhotoOrderlList, createPlayerDetail, updatePlayerDetailData,
         deletePlayerDetail, createPlayerPost , updatePhotoOrderAddress, updatePhotoOrderShip} from '../../modules/playerDetail/action';
import PlayerPhotoOrderFilter from "./PlayerPhotoOrderFilter";
import AddressForm from "./AddressForm";
import ShipForm from "./ShipForm";
const {confirm} = Modal;

class PlayerPhotoPrintManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            showEditPlayerDetailModal: false,
            playerDetailData: {},
            showEditPlayerPostModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getPlayerPhotoOrderlList } = this.props;
        getPlayerPhotoOrderlList({page, size});
    }

    onPageChangeEvent = (current, size) => {
        let { getPlayerPhotoOrderlList } = this.props;
        let { giftName, giftType } = this.state;
        this.setState({
            page: current,
            size
        });
        getPlayerPhotoOrderlList({page: current, size, giftName, giftType});
    }

    requestOrderList = () => {
        const {page, size} = this.state;
        let { getPlayerPhotoOrderlList } = this.props;
        getPlayerPhotoOrderlList({page, size});
    }

    onFilterPlayerDetailEvent = ({playerName,userId,status}) => {
        const { getPlayerPhotoOrderlList } = this.props;
        const { size } = this.state;
        this.setState({
            playerName,
            userId,
            status,
            page: 1
        });
        getPlayerPhotoOrderlList({page: 1, size, playerName,userId,status});
    }

    onOpenEditPlayerDetailEvent = (text, type) => {
        if (type === operationTypeValue.UPDATE) {
            this.setState({
                playerDetailData: { ...text, type }
            });
        } else {
            this.setState({
                playerDetailData: { type }
            });
        }
        this.setState({
            operationType: type,
            showEditPlayerDetailModal: true,
        });
    }

    onCloseEditPlayerDetailEvent = () => {
        this.setState({
            showEditPlayerDetailModal: false
        });
    }

    // 新增选手主页
    onAddPlayerDetailEvent = async (params) => {
        const {page, size} = this.state;
        const {createPlayerDetail, getPlayerPhotoOrderlList} = this.props;
        try {
            await createPlayerDetail(params);
            notification.success({message: '添加成功'});
            this.setState({
                showEditPlayerDetailModal: false
            });
            this.requestOrderList();
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑选手主页信息
    onEditPlayerDetailEvent = async (params) => {
        const {page, size, playerDetailData} = this.state;
        const {updatePlayerDetailData, getPlayerPhotoOrderlList} = this.props;
        try {
            await updatePlayerDetailData(playerDetailData.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditPlayerDetailModal: false,
            });
            this.requestOrderList();
        } catch (e) {
            console.log(e);
        }
    }

    onConfirmPlayerDetailDelete = (id) => {
        let _this = this;

        confirm({
            content: '确认要删除该选手主页吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onDeletePlayerDetailEvent(id);
            },
            onCancel() {
            }
        });
    }

    // 删除选手主页
    onDeletePlayerDetailEvent = async (id) => {
        const {deletePlayerDetail, getPlayerPhotoOrderlList} = this.props;
        const {page, size} = this.state;
        try {
            await deletePlayerDetail(id);
            notification.success({message: '删除成功'});
            getPlayerPhotoOrderlList({page, size});
        } catch (e) {
            console.log(e);
        }
    }


    onCloseEditPlayerPostEvent = () => {
        this.setState({
            operationType: operationTypeValue.INCREASE,
            showEditPlayerPostModal: false
        });
    }


    // 编辑物流信息
    onEditShipEvent = async (params) => {
        const {orderInfo} = this.state;
        const {updatePhotoOrderShip} = this.props;
        try {
            await updatePhotoOrderShip(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false
            });
            this.requestOrderList();
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑收货地址
    onEditAddressEvent = async (params) => {
        const {orderInfo} = this.state;
        const {updatePhotoOrderAddress} = this.props;
        try {
            await updatePhotoOrderAddress(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false,
            });
            this.requestOrderList();
        } catch (e) {
            console.log(e);
        }
    }



    onOpenEditOrderModalEvent = (text, type) => {
        let title = '';
        if(type === operationTypeValue.UPDATE_ADDRESS) {
            title = '编辑收货地址';
        } else if(type === operationTypeValue.UPDATE_SHIP) {
            title = text.shippingStatus ? '编辑物流信息' : '发货';
        }
        this.setState({
            modalTitle: title,
            orderInfo: { ...text, orderId: text.id, type }
        }, () => {
            this.setState({
                operationType: type,
                showEditOrderModal: true,
            });
        });
    }
    onCloseEditOrderModalEvent = () => {
        this.setState({
            showEditOrderModal: false
        });
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onOpenEditPlayerDetailEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    changeOrderStatus =(v)=>{
        if(v == 'NOT_SHIPPED') {
            return '未发货';
        }
        return "已发货";
    }


    render() {
        const {
            playerDetailList, loading
        } = this.props;
        const {
            modalTitle, page, size, operationType, showEditOrderModal, orderInfo
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PlayerPhotoOrderFilter dataSource={{}} onCommit={this.onFilterPlayerDetailEvent}></PlayerPhotoOrderFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '订单编号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '选手主页名',
                                    dataIndex: 'playerName',
                                    key: 'playerName',
                                    align: 'center',
                                },
                                {
                                    title: '选手照片',
                                    dataIndex: 'photoUrl',
                                    key: 'photoUrl',
                                    align: 'center',
                                    render: (playerAvatar, record, index) => {
                                        return <img src={playerAvatar || ''} alt={""} style={{width: 80, height: 80}}/>;
                                    }
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                },
                                {
                                    title: '打印兑换时间',
                                    dataIndex: 'printExchangeTime',
                                    key: 'printExchangeTime',
                                    align: 'center',
                                },

                                {
                                    title: '发货状态',
                                    dataIndex: 'status',
                                    key: 'status',
                                    align: 'center',
                                    render: (row, item) => {
                                        return <span>{this.changeOrderStatus(item.status)}</span>;
                                    }

                                },

                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_ADDRESS)}
                                                color="blue">编辑收货地址</Button>
                                            <Button type="danger"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_SHIP)}
                                                color="blue">编辑物流信息</Button>

                                        </div>
                                    }
                                },
                            ]
                        }

                        dataSource={playerDetailList.data ? playerDetailList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: playerDetailList ? playerDetailList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />

                    <Modal
                        title={modalTitle}
                        visible={showEditOrderModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditOrderModalEvent}
                        width={"30%"}
                    >
                        {operationType === operationTypeValue.UPDATE_ADDRESS ?
                            <AddressForm {...orderInfo}
                                         onSubmitEvent={this.onEditAddressEvent}/>
                            : operationType === operationTypeValue.UPDATE_SHIP && <ShipForm {...orderInfo} onSubmitEvent={this.onEditShipEvent}/>}
                    </Modal>
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.PlayerDetail
        }

    }

);

const mapDispatchToProps = {
    getPlayerPhotoOrderlList,
    createPlayerDetail,
    updatePlayerDetailData,
    deletePlayerDetail,
    createPlayerPost,
    updatePhotoOrderAddress,
    updatePhotoOrderShip,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(PlayerPhotoPrintManagement)));
