import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, Popconfirm, Popover, Row, Table, Tabs, Tag} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getStatics} from "../../modules/information/action";
import {getGame} from "../../modules/game/action";
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";
import ActivityModal from "./components/ActivityModal";
import ActivityFilter from "./components/ActivityFilter";
import {map} from 'lodash/fp';
const {confirm} = Modal;
/**
 * 统计
*/
class statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowActivityModel:false,//新增窗口
      modelType:'',//展示弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modelData:{},
      pageNo:1,
      pageSize: 10,
      params:{
          clubId:'',
      },
      id:''

    };
  }

  componentWillMount() {
    this.props.getGame()
    this.onGetData()
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  //条件查找
  onFilterActivityEvent = ( params) => {
    let {getStatics} = this.props;
      let startTime = null, endTime = null;
      let dateRange = params.dateRange;
      if (dateRange !== undefined) {
          dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
          startTime = dateRange[0] == undefined ? '' : dateRange[0];
          endTime = dateRange[1] == undefined ? '' : dateRange[1];
          params.startTime = startTime;
          params.endTime = endTime;
          delete params.dateRange;
      }
      params.clubId = params.gamingDivision;
      delete params.gamingDivision;
      delete params.date;
    this.setState({
        params:{...params}
    }, () => {
        getStatics({ ...params})
    })
  }

  onGetData = async () => {
      const {pageNo, pageSize,params} = this.state;
      let {getStatics} = this.props;
      console.log("state", this.state)
      // await getStatics({page:pageNo, size:pageSize,...params});
  }

  //转换成游戏名字
  changeGameName = (v)=>{
    let {gameList} = this.props;
    let gameName;
    for(let i = 0;i < gameList.length;i++){
      if(gameList[i].code===v){
        gameName = gameList[i].gameName;
        break
      }
    }
    return gameName;
  }





  getAuthorizationHeader = (headers) => {
      try {
          let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
          const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
          if (headers && accessToken) {
              headers.Authorization = accessToken.token;
          }
          headers.ContentType = 'application/json';
          return headers;
      } catch (error) {
          return '';
      }
  };





  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.setState({isShowActivityModel: true,modelType:'ADD'})}>新增</Button>
        </Col>
    </Row>
  )


  render() {
    let{statics,gameList,} = this.props;
    let{isShowActivityModel,pageNo,pageSize,modelType,id} = this.state;
    return (
      <div>
        <Card style={{marginBottom: 30}}>
          <ActivityFilter onCommit={this.onFilterActivityEvent} gameList={gameList}/>
        </Card>
          <Card style={{marginBottom: 30}}>
          <div>
              总资讯数： {statics['totalInfoNum']}
          </div>
          <div>
              总点赞数： {statics['totalThumbNum']}
          </div>
          <div>
              总评论数： {statics['totalCommentNum']}
          </div>
          </Card>
      </div>
    );
  }

//翻页
onPageChangeEvent = (current, pageSize) => {
  const {getActivity} = this.props;
  const { params} = this.state;
  this.setState({
      pageNo: current,
      pageSize: pageSize,
  }, () => {
    getActivity({page: current, size: pageSize, ...params})
  });
}

}

const mapStateToProps = (state) => {
  return {
    ...state.viewInformation,
    ...state.viewGame
  }
};

const mapDispatchToProps = ({
  getStatics,
  getGame,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(statistics));
