import createReducer from 'utils/createReducer';
import {GET_CONFIGS} from "./actionType";

const defaultState = () => ({
    configs: [],
    configsTotal: 0,
    configsLoading: false,
    configsInfo: {},
    adminRoles: [], // 用户所拥有的角色
});


const onGetConfigsRequest = (state, {payload}) => {
    console.log(payload)
    return {
        ...state,
        configs: payload

    }

};

export default createReducer(defaultState, {
    [`${GET_CONFIGS}_SUCCESS`]: onGetConfigsRequest,
});
