import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getPelLeagueDictionaries, getPelTeamRankIntegral} from "../../../modules/training/action";

const FormItem = Form.Item;

class PelDataIntegralFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pelLeagueStage: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                console.log(onCommit)
                let {pelLeagueType, pelLeagueStage, integralType} = values;
                onCommit && onCommit(pelLeagueType, pelLeagueStage, integralType);
            }
        });
    }

    changePelLeagueType = (value) => {
        const {leagueDicList} = this.props;
        let arr = leagueDicList.filter(item => item.value===value);
        if(arr.length>0){
            this.setState({
                pelLeagueStage: arr[0].children
            })
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {leagueDicList, pelTeamRankIntegralList} = this.props;
        const {pelLeagueStage} = this.state;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="赛季">
                    {getFieldDecorator('pelLeagueType', {
                        initialValue: leagueDicList &&  leagueDicList[0].value,
                        }
                    )(
                        <Select style={{width: 120}} onChange={this.changePelLeagueType}>
                            {leagueDicList && leagueDicList.map((item) => <Select.Option value={item.value} >{item.label}</Select.Option>)}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="赛事阶段">
                    {getFieldDecorator('pelLeagueStage', {
                            initialValue: pelLeagueStage ? pelLeagueStage[0].value : leagueDicList ? leagueDicList[0].children[0].value : null,
                        }
                    )(
                        <Select style={{width: 120}} >
                            {pelLeagueStage ? pelLeagueStage.map((item) => <Select.Option value={item.value} >{item.label}</Select.Option>)
                                : leagueDicList ? leagueDicList[0].children.map((item) => <Select.Option value={item.value} >{item.label}</Select.Option>) : null}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="积分类型">
                    {getFieldDecorator('integralType', {initialValue: 'All'}
                    )(
                        <Select style={{width: '100px'}} >
                            <Select.Option value="All">总积分</Select.Option>
                            <Select.Option value="Weekly">周积分</Select.Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getPelLeagueDictionaries,
    getPelTeamRankIntegral
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PelDataIntegralFilter)));