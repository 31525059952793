import React from 'react';
import {Form, Input, Modal,Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TeamPositionModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props
        return (
            <Modal
                visible={visible}
                title="设置场上位置"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form layout="inline">
                    <FormItem label="场上位置">
                        {getFieldDecorator('floorPosition', {
                            initialValue:"",
                            rules: [{required: true, message: '请选择场上位置'}],
                            }
                        )(
                            <Select  style={{width: 250}}>
                                <Option value="">请选择</Option>
                                <Option value="指挥官">指挥官</Option>
                                <Option value="自由人">自由人</Option>
                                <Option value="突击手">突击手</Option>
                                <Option value="狙击手">狙击手</Option>
                                <Option value="全位置">全位置</Option>
                                <Option value="试训者">试训者</Option>
                            </Select>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({});
TeamPositionModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TeamPositionModel)));
