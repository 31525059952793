export const GET_REPORTS = 'GET_REPORTS';// 获取举报列表

export const GET_REPORT = 'GET_REPORT';// 获取举报信息

export const DELETE_REPORT = 'DELETE_REPORT';//删除举报

export const ASSIGN = 'CHANGE_REPORT_STATUS';//修改处理状态

export const GET_FEEDBACK_LIST = 'GET_FEEDBACK_LIST'; //获取用户反馈列表

export const DELETE_FEEDBACK = 'DELETE_FEEDBACK'; //删除用户反馈