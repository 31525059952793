import React, { Component } from 'react';
import {
  Form, Input, Button, Icon, notification,
} from 'antd';

class formeFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMailboxChange: false,
      showTelephoneChange: false,
    };
  }

  renderMailBox = () => {
    let { showMailboxChange } = this.state;
    let { userInfo } = this.props;
    const { getFieldDecorator, validateFields } = this.props.form;
    if (!showMailboxChange) {
      return (
        <div className="formFile-mailbox formFile-item">
          <div className="formFile-Labble">电子邮箱：</div>
          <div className="formFile-Content">
            {userInfo.mailbox}
            <span onClick={() => {
              this.setState(
                {
                  showMailboxChange: true,
                },
              );
            }}
            >
              <Icon type="edit" />
              修改
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="formItem">
        <div style={{ display: 'flex', height: 36, justifyItems: 'center' }}>
          <Form.Item className="formFile-Labble">电子邮箱：</Form.Item>
          <Form.Item style={{ width: 360 }}>
            {getFieldDecorator('mailbox', {
              initialValue: userInfo.mailbox,
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input placeholder="mailbox" />,
            )}
          </Form.Item>
        </div>
        <div style={{ marginTop: 20, marginLeft: 140 }}>
          <Button
            className="sub"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              validateFields((err, values) => {
                if (!err) {
                  this.setState({
                    showMailboxChange: false,
                  });
                  this.onUpdateUserEvent(values);
                }
              });
            }}
          >
            保存
          </Button>
          <Button
            className="canc"
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.setState({
                showMailboxChange: false,
              });
            }}
          >
            取消
          </Button>
        </div>
      </div>
    );
  }

  renderTelephone = () => {
    let { showTelephoneChange } = this.state;
    let { userInfo } = this.props;
    const { getFieldDecorator, validateFields } = this.props.form;
    if (!showTelephoneChange) {
      return (
        <div className="formFile-mailbox formFile-item">
          <div className="formFile-Labble">手机号码：</div>
          <div className="formFile-Content">
            {userInfo.telephone}
            <span onClick={() => {
              this.setState(
                {
                  showTelephoneChange: true,
                },
              );
            }}
            >
              <Icon type="edit" />
              修改
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="formItem">
        <div style={{ display: 'flex', height: 36, justifyItems: 'center' }}>
          <Form.Item className="formFile-Labble">手机号码：</Form.Item>
          <Form.Item style={{ width: 360 }}>
            {getFieldDecorator('telephone', {
              initialValue: userInfo.telephone,
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input placeholder="telephone" />,
            )}
          </Form.Item>
        </div>
        <div style={{ marginTop: 20, marginLeft: 140 }}>
          <Button
            className="sub"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              validateFields((err, values) => {
                if (!err) {
                  this.setState({
                    showTelephoneChange: false,
                  });
                  this.onUpdateUserEvent(values);
                }
              });
            }}
          >
            保存
          </Button>
          <Button
            className="canc"
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.setState({
                showTelephoneChange: false,
              });
            }}
          >
            取消
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderMailBox()}
        {this.renderTelephone()}
      </div>
    );
  }

  onUpdateUserEvent = async (values) => {
    let { updateUser, userId, handleSubmit } = this.props;
    try {
      await updateUser(userId, values);
      await handleSubmit();
      notification.success({ message: '修改成功' });
    } catch (e) {
      notification.success({ message: '修改失败' });
    }
  }

  componentDidMount() {
    this.props.form.validateFields();
  }
}

export default Form.create({ name: 'Forme_File' })(formeFile);
