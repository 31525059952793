export default {
    BAN_USER: {
        label: '封禁',
        value:'BAN_USER',
    },
    UN_BAN_USER:{
        label:'解封',
        value:'UN_BAN_USER'
    },
    OPERATE_COIN: {
        label: '操作浪币',
        value: 'OPERATE_COIN'
    },
    OPEN_VIP_DAYS: {
        label: '开会员天数',
        value: 'OPEN_VIP_DAYS'
    },
    UN_BAN_WX: {
        label: '解绑微信',
        value:'UN_BAN_WX',
    },
    UN_BAN_QQ:{
        label:'解绑QQ',
        value:'UN_BAN_QQ'
    },
    CANCEL_USER:{
        label:'注销',
        value:'CANCEL_USER'
    },
};
