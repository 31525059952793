
export const GET_VIEW_ROLE_INFO = 'GET_VIEW_ROLE_INFO'; // 获取角色详情

export const GET_VIEW_ROLE_LIST = 'GET_VIEW_ROLE_LIST'; // 获取角色列表

export const CREATE_VIEW_ROLE = 'CREATE_VIEW_ROLE';// 创建角色

export const UPDATE_VIEW_ROLE = 'UPDATE_VIEW_ROLE';// 更新角色

export const DELETE_VIEW_ROLE = 'DELETE_VIEW_ROLE';// 删除角色

export const GET_VIEW_ROLE_PERMISSION = 'GET_VIEW_ROLE_PERMISSION'; // 获取角色权限

export const UPDATE_VIEW_ROLE_PERMISSION = 'UPDATE_VIEW_ROLE_PERMISSION'; // 编辑角色权限

export const GET_FUNCION_PERMISSION = 'GET_FUNCION_PERMISSION';
