import createAsyncAction from 'utils/createAsyncAction';
import TicketRequest from 'request/TicketRequest';
import {
    GET_TICKET_LIST,
    GET_TICKET_ORDER_LIST,
    GET_MEDAL_THEME_DATA,
    UPDATE_MEDAL_THEME_DATA,
    UPDATE_MEDAL_THEME_SHELF,
    GET_SUB_MEDAL_LIST,
    GET_SUB_MEDAL_DATA,
    UPDATE_SUB_MEDAL_DATA,
    GET_SUB_MEDAL_LOG_LIST,
    UPDATE_SUB_MEDAL_SEND,
    UPDATE_SUB_MEDAL_RECYCLE, ADD_MEDAL_THEME_DATA, ADD_TICKET_DATA,
} from './actionTypes';

const getTicketList = (params) => (
    createAsyncAction(GET_TICKET_LIST, () => (TicketRequest.getTicketList(params)))
);

const getTicketOrderList = (params) => (
    createAsyncAction(GET_TICKET_ORDER_LIST, () => (TicketRequest.getTicketOrderList(params)))
);

const getMedalThemeData = (themeMedalId) => (
    createAsyncAction(GET_MEDAL_THEME_DATA, () => (TicketRequest.getMedalThemeData(themeMedalId)))
);

const updateMedalThemeData = (themeMedalId, params) => (
    createAsyncAction(UPDATE_MEDAL_THEME_DATA, () => (TicketRequest.updateMedalThemeData(themeMedalId, params)))
);

const addTicketData = (params) => (
    createAsyncAction(ADD_TICKET_DATA, () => (TicketRequest.addTicketData(params)))
);

const updateMedalThemeShelf = (themeMedalId, params) => (
    createAsyncAction(UPDATE_MEDAL_THEME_SHELF, () => (TicketRequest.updateMedalThemeShelf(themeMedalId, params)))
);

const getSubMedalList = (themeMedalId, params) => (
    createAsyncAction(GET_SUB_MEDAL_LIST, () => (TicketRequest.getSubMedalList(themeMedalId, params)))
);

const getSubMedalData = (childMedalId) => (
    createAsyncAction(GET_SUB_MEDAL_DATA, () => (TicketRequest.getSubMedalData(childMedalId)))
);

const updateSubMedalData = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_DATA, () => (TicketRequest.updateSubMedalData(childMedalId, params)))
);

const addSubMedalData = (params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_DATA, () => (TicketRequest.addSubMedalData(params)))
);

const getSubMedalLogList = (params) => (
    createAsyncAction(GET_SUB_MEDAL_LOG_LIST, () => (TicketRequest.getSubMedalLogList(params)))
);

const updateSubMedalSend = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_SEND, () => (TicketRequest.updateSubMedalSend(childMedalId, params)))
);

const updateSubMedalRecycle = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_RECYCLE, () => (TicketRequest.updateSubMedalRecycle(childMedalId, params)))
);

export {
    getTicketList,getTicketOrderList, getMedalThemeData, updateMedalThemeData,addTicketData, updateMedalThemeShelf,
    getSubMedalList, getSubMedalData, updateSubMedalData, addSubMedalData,getSubMedalLogList, updateSubMedalSend,
    updateSubMedalRecycle
};
