import React, {Component} from "react";
import {Form, message} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getVipState} from "../../modules/vip/action";


class CheckVipModel extends Component {

    constructor(props) {
        super(props);
        this.state = {vipState: false}

    }

    componentWillMount() {
        this.onInitData(this.props.userId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.userId !== this.props.userId) {
            this.onInitData(nextProps.userId);
        }
    }

    onInitData = (userId) => {
        this.onGetVipState(userId);
    }

    onGetVipState = async (userId) => {
        const {getVipState} = this.props;
        getVipState(userId).then((data) =>{
            this.setState({vipState: data})
        }).catch(error =>{
            this.setState({vipState: false})
        });

    }

    render() {
        const {vipState} = this.state;
        return (
            <span>{vipState ? '是' : "否"}</span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({
    getVipState,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CheckVipModel)));