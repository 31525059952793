import React from 'react';
import {Button, Card, Col, Form, InputNumber,Input, Modal, Radio, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {
    getPelLeagueDictionaries,
    getPelTeamRankList,
    getPelTeamRankIntegral,
    getPelTeamRankWeeklyIntegral,
    getSortFieldDictionaries,
    updatePelTeamRankIntegral,
    updatePelTeamRankWeeklyIntegral
} from "../../modules/training/action";
import PelDataPlayerFilter from "../pel-data-player-management/components/PelDataPlayerFilter";

const {confirm} = Modal;

class PelDataTeamManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            pelLeagueType: null,
            pelLeagueStage: null,
            dataType: null,
            edition: false,
            updatePelTeamRankDate: null,
            sortField: null,
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const {getPelLeagueDictionaries,  getSortFieldDictionaries, getPelTeamRankList} = props;
        await getPelLeagueDictionaries().then((data1) => {
            if(data1 && data1.length == 0)return;
            getSortFieldDictionaries({
                leagueBrandType: 'PEL',
                rankType: 'TEAM',
                rankDataType: 'TOTAL'
            }).then((data) => {
                getPelTeamRankList({page: 1, size: 10, pelLeagueType: data1[0].value, sort: data[0].value})
                this.setState({
                    pelLeagueType: data1[0].value,
                    sortField: {key: data[0].key, value: data[0].value},
                    dataType: 'TOTAL',
                    updatePelTeamRankDate: null
                })
            })
        }).catch(error => {
        });
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getPelTeamRankList} = this.props;
        const {sortField, pelLeagueType, page, size} = this.state;
        this.setState({
            loading: true,
            updatePelTeamRankDate: null
        })
        getPelTeamRankList({page: page, size: size, pelLeagueType: pelLeagueType, sort:sortField.value})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.pelTeamRankIntegralList !== this.props.pelTeamRankIntegralList) {
            this.setState({
                loading: false
            })
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getPelTeamRankList} = this.props;
        const {pelLeagueType, sortField} = this.state;
        getPelTeamRankList({page: current, size: pageSize, pelLeagueType: pelLeagueType, sort: sortField.value})
        this.setState({
            page: current,
            size: pageSize,
            loading: true,
            edition: false,
            updatePelTeamRankDate: null
        });
    }

    onBtnClick = (type) => {
        if (type === true) {
            this.setState({
                edition: type,
            });
        } else {
            let _this = this;
            confirm({
                content: '确认要保存本次修改吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.onSavePelData(type)
                },
                onCancel() {
                },

            });
        }
    }

    onSavePelData = async (type) => {
        const {updatePelTeamRankDate} = this.state;
        const {updatePelTeamRankIntegral} = this.props;
        await updatePelTeamRankIntegral(updatePelTeamRankDate).then((data) => {
        }).catch(error => {
        })
        await this.onGetTableData()
        await this.setState({
            edition: type,
        });
    }

    onBtnCancelClick = (type) => {
        let _this = this;
        confirm({
            content: '确认要取消编辑吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.setState({
                    edition: type,
                });
            },
            onCancel() {
            },

        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary"
                        onClick={() => this.onBtnClick(!this.state.edition)}>{this.state.edition ? `保存` : `编辑`}</Button>
                {this.state.edition ? <Button type="primary" style={{marginLeft: 20}}
                                              onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> : null}
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterPelDataPlayerEvent = async (pelLeagueType, dataType) => {
        const {size} = this.state;
        const {getSortFieldDictionaries, getPelTeamRankList} = this.props;
        getSortFieldDictionaries({
            leagueBrandType: 'PEL',
            rankType: 'TEAM',
            rankDataType: dataType
        }).then((data) => {
            getPelTeamRankList({page: 1, size: size, pelLeagueType: pelLeagueType, sort: data[0].value})
            this.setState({
                pelLeagueType: pelLeagueType,
                sortField: data[0],
                dataType: dataType,
                page: 1,
                edition: false,
                updatePelTeamRankDate: null
            })
        })
    }

    onChangeRadio = e => {
        const {sortFieldDicList, getPelTeamRankList} = this.props;
        let field = sortFieldDicList.filter(item => item.value === e.target.value);
        getPelTeamRankList({
            page: 1,
            size: this.state.size,
            pelLeagueType: this.state.pelLeagueType,
            sort: e.target.value,
            updatePelTeamRankDate: null
        })
        this.setState({
            sortField: field.length > 0 ? field[0] : this.state.sortField,
            page: 1
        });
    };

    render() {
        const {
            pelTeamRankIntegralList, sortFieldDicList
        } = this.props;
        const {
            page, size, loading, sortField
        } = this.state;
        const radioStyle = {
            display: 'block',
            marginBottom: '5px',
            height: '40px',
            width: '180px',
            lineHeight: '40px',
            textAlign: 'center'
        };
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PelDataPlayerFilter dataSource={{...this.props}} onCommit={this.onFilterPelDataPlayerEvent}/>
                </Card>
                <Card>
                    <Row>
                        <Col span={3}>
                            <Radio.Group onChange={this.onChangeRadio}
                                         value={sortField ? sortField.value : sortFieldDicList ? sortFieldDicList[0].value : null}
                                         buttonStyle="solid">
                                {sortFieldDicList && sortFieldDicList.map((item) => <Radio.Button style={radioStyle}
                                                                                                  value={item.value}>{item.key}</Radio.Button>)}
                            </Radio.Group>
                        </Col>
                        <Col span={21}>
                            <Table
                                dataSource={pelTeamRankIntegralList ? pelTeamRankIntegralList.data : []}
                                title={this.renderTableTitle}
                                columns={this.getAllTableColumns()}
                                bordered
                                pagination={{
                                    total: pelTeamRankIntegralList ? pelTeamRankIntegralList.total : 0,
                                    current: page,
                                    pageSize: size,
                                    onChange: this.onPageChangeEvent,
                                    showTotal: (total) => {
                                        return `总计 ${total}条数据`;
                                    }
                                }}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>)
    }


    onChangeTable = (key, row, value) => {
        const {pelTeamRankIntegralList} = this.props;
        let {updatePelTeamRankDate} = this.state;
        console.log()
        if (!updatePelTeamRankDate) {
            console.log("updatePelTeamRankDate")
            updatePelTeamRankDate = JSON.parse(JSON.stringify(pelTeamRankIntegralList.data));
        }
        updatePelTeamRankDate[row][key] = value.target.value;
        this.setState({updatePelTeamRankDate: updatePelTeamRankDate})
    }

    getAllTableColumns = () => {
        const {getFieldDecorator} = this.props.form;
        const {sortField, dataType} = this.state;
        console.log(sortField)
        let columns = [
            {
                title: '排名',
                dataIndex: 'rank',
                key: 'rank',
                align: 'center',
            },
            {
                title: '战队展示ID',
                dataIndex: 'teamShowId',
                key: 'teamShowId',
                align: 'center',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('teamShowId' + row, {
                                    initialValue: text.teamShowId ? text.teamShowId : null,
                                })(<Input style={{width: 120}}
                                                onChange={(e) => this.onChangeTable('teamShowId', row, e)}/>)}
                            </Form.Item>
                        </div> : text.teamShowId)
                },
            },
            {
                title: '战队',
                dataIndex: 'teamName',
                align: 'center',
                key: 'teamName',
                render: (row, text) => {
                    return (<div><img width={33} height={33} src={text.teamIcon}/><span>{text.teamName}</span></div>)
                }
            }];
        if (sortField) {
            columns.push({
                title: `${sortField.key}`,
                dataIndex: `${sortField.value}`,
                key: `${sortField.value}`,
                align: 'center',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator(`${sortField.value}` + row, {
                                    initialValue: text[`${sortField.value}`],
                                    rules: [
                                        {
                                            required: true,
                                            message: `总积分不能为空.`,
                                        },
                                    ],
                                })(<Input
                                    onChange={(e) => this.onChangeTable(`${sortField.value}`, row, e)}/>)}
                            </Form.Item>
                            {/*</div> : text[`${sortField.value}`])*/}
                        </div> : dataType === 'AVG' ? (sortField.value === 'avgWinCount' || sortField.value === 'avgTop5Count' || sortField.value === 'avgHighHurtRatio' || sortField.value === 'avgHitRatio' ?
                            text[`${sortField.value}`] ? (text[`${sortField.value}`] * 100).toFixed(2) + '%' : '0%' :
                            text[`${sortField.value}`] ? (text[`${sortField.value}`] * 100).toFixed(2) : 0)
                            : text[`${sortField.value}`])
                },
            })
        }
        return (columns)
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
    }
};

const mapDispatchToProps = ({
    getPelTeamRankIntegral,
    getPelTeamRankWeeklyIntegral,
    updatePelTeamRankIntegral,
    updatePelTeamRankWeeklyIntegral,
    getPelLeagueDictionaries,
    getSortFieldDictionaries,
    getPelTeamRankList,
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PelDataTeamManagement)));
