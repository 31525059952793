import createReducer from 'utils/createReducer';
import {GET_GAME, GET_TRAIL_PLANS} from "./actionType";

const defaultState = () => ({
    trailPlans: [],
    trailPlansTotal: 0,
    trailPlansLoading: false,
    trailPlansInfo: {},
    gameList:[],
    adminRoles: [], // 用户所拥有的角色
});


const onGetTrailPlansRequest = (state, {payload}) => {
    console.log(payload)
    return {
        ...state,
        trailPlans: payload

    }

};

const onGetGameRequest = (state,{payload}) => {
    return {
        ...state,
        gameList:eval(payload.confValue),
    }

};

export default createReducer(defaultState, {
    [`${GET_TRAIL_PLANS}_SUCCESS`]: onGetTrailPlansRequest,
    [`${GET_GAME}_SUCCESS`] :onGetGameRequest,
});
