export default {
    REJECT_CASH_OUT: {
        label: '拒绝',
        value:'REJECT_CASH_OUT',
    },
    AGREE_CASH_OUT: {
        label: '同意',
        value: 'AGREE_CASH_OUT'
    },
};
