import React, { Component } from 'react';
import {
  Card,
} from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl-context';
import { getAuthenticationInfo } from 'modules/authentication/action';
import PassWordForm from './components/passWordForm';

class PersonalSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '设置',
    };
  }

  render() {
    let { title } = this.state;
    let { userId } = this.props.user;
    return (
      <div>
        <Card title={title}>
          <Card title="修改密码：" style={{ width: 500, margin: '0 auto' }}>
            <PassWordForm
              userId={userId}
            />
          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

const mapDispatchToProps = ({
  getAuthenticationInfo,
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PersonalSet));
