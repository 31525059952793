import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import PlayerDetailForm from './PlayerDetailForm';
import PlayerFilter from "./PlayerFilter";
import PlayerPostForm from "./PlayerPostForm";
import { operationTypeValue } from "../../modules/playerDetail/playerDetailEnum";
import { getPlayerDetailList, createPlayerDetail, updatePlayerDetailData,
         deletePlayerDetail, createPlayerPost } from '../../modules/playerDetail/action';
import {stateType} from "../../modules/user/enums";
import whetherButtonPermission from "../../modules/authentication/whetherButtonPermission";
import buttonType from "../user-management/buttonType";
const {confirm} = Modal;

class PlayerDetailManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            showEditPlayerDetailModal: false,
            playerDetailData: {},
            showEditPlayerPostModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getPlayerDetailList } = this.props;
        getPlayerDetailList({page, size});
    }

    onPageChangeEvent = (current, size) => {
        let { getPlayerDetailList } = this.props;
        let { giftName, giftType } = this.state;
        this.setState({
            page: current,
            size
        });
        getPlayerDetailList({page: current, size, giftName, giftType});
    }

    onFilterPlayerDetailEvent = ({playerName}) => {
        const { getPlayerDetailList } = this.props;
        const { size } = this.state;
        this.setState({
            playerName,
            page: 1
        });
        getPlayerDetailList({page: 1, size, playerName});
    }

    onOpenEditPlayerDetailEvent = (text, type) => {
        if (type === operationTypeValue.UPDATE) {
            this.setState({
                playerDetailData: { ...text, type }
            });
        } else {
            this.setState({
                playerDetailData: { type }
            });
        }
        this.setState({
            operationType: type,
            showEditPlayerDetailModal: true,
        });
    }

    onCloseEditPlayerDetailEvent = () => {
        this.setState({
            showEditPlayerDetailModal: false
        });
    }

    // 新增选手主页
    onAddPlayerDetailEvent = async (params) => {
        const {page, size} = this.state;
        const {createPlayerDetail, getPlayerDetailList} = this.props;
        try {
            await createPlayerDetail(params);
            notification.success({message: '添加成功'});
            this.setState({
                showEditPlayerDetailModal: false
            });
            getPlayerDetailList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑选手主页信息
    onEditPlayerDetailEvent = async (params) => {
        const {page, size, playerDetailData} = this.state;
        const {updatePlayerDetailData, getPlayerDetailList} = this.props;
        try {
            await updatePlayerDetailData(playerDetailData.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditPlayerDetailModal: false,
            });
            getPlayerDetailList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onConfirmPlayerDetailDelete = (id) => {
        let _this = this;

        confirm({
            content: '确认要删除该选手主页吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onDeletePlayerDetailEvent(id);
            },
            onCancel() {
            }
        });
    }

    // 删除选手主页
    onDeletePlayerDetailEvent = async (id) => {
        const {deletePlayerDetail, getPlayerDetailList} = this.props;
        const {page, size} = this.state;
        try {
            await deletePlayerDetail(id);
            notification.success({message: '删除成功'});
            getPlayerDetailList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onOpenEditPlayerPostEvent = (text, type) => {
        this.setState({
            playerDetailData: text
        }, () => {
            this.setState({
                operationType: type,
                showEditPlayerPostModal: true
            });
        });
    }

    onCloseEditPlayerPostEvent = () => {
        this.setState({
            operationType: operationTypeValue.INCREASE,
            showEditPlayerPostModal: false
        });
    }

    // 发布选手说
    onEditPlayerPostEvent = async (params) => {
        const { createPlayerPost } = this.props;

        try {
            await createPlayerPost(params);
            notification.success({message: '发布成功'});
            this.setState({ showEditPlayerPostModal: false });
        } catch(e) {
            console.log(e);
        }
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onOpenEditPlayerDetailEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    confirmBanUser = (item) => {
        let _this = this;
        confirm({
            content: '确认屏蔽选手上人气榜单吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onBanUser(item)
            },
            onCancel() {
            },

        });
    }

    confirmUnBanUser = (item) => {
        let _this = this;
        confirm({
            content: '确认解除屏蔽选手上人气榜单吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUnBanUser(item)
            },
            onCancel() {
            },

        });
    }

    /**
     * 解封
     * @param userId
     */
    onUnBanUser = async (item) => {
        item["hotRankStatus"] = true
        const {page, size, playerDetailData} = this.state;
        const {updatePlayerDetailData, getPlayerDetailList} = this.props;
        try {
            await updatePlayerDetailData(item.id, item);
            notification.success({message: '更新成功'});
            this.setState({
                showEditPlayerDetailModal: false,
            });
            getPlayerDetailList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * 屏蔽
     * @param userId
     */
    onBanUser = async (item) => {
        item["hotRankStatus"] = false
        const {page, size, playerDetailData} = this.state;
        const {updatePlayerDetailData, getPlayerDetailList} = this.props;
        try {
            await updatePlayerDetailData(item.id, item);
            notification.success({message: '更新成功'});
            this.setState({
                showEditPlayerDetailModal: false,
            });
            getPlayerDetailList({page, size});
        } catch (e) {
            console.log(e);
        }
    }


    render() {
        const {
            playerDetailList, loading
        } = this.props;
        const {
            showEditPlayerDetailModal, page, size, operationType, playerDetailData, showEditPlayerPostModal
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PlayerFilter dataSource={{}} onCommit={this.onFilterPlayerDetailEvent}></PlayerFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '主页ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '选手主页名',
                                    dataIndex: 'playerName',
                                    key: 'playerName',
                                    align: 'center',
                                },
                                {
                                    title: '选手主页头像',
                                    dataIndex: 'playerAvatar',
                                    key: 'playerAvatar',
                                    align: 'center',
                                    render: (playerAvatar, record, index) => {
                                        return <img src={playerAvatar || ''} alt={""} style={{width: 80, height: 80}}/>;
                                    }
                                },
                                {
                                    title: '主页TOP图',
                                    dataIndex: 'playerTopImg',
                                    key: 'playerTopImg',
                                    align: 'center',
                                    render: (playerTopImg, record, index) => {
                                        return <img src={playerTopImg || ''} alt={""} style={{width: 200, height: 100}}/>;
                                    }
                                },
                                {
                                    title: '关联主话题',
                                    dataIndex: 'relatedTopicName',
                                    key: 'relatedTopicName',
                                    align: 'center',
                                },
                                {
                                    title: '主持人ID',
                                    dataIndex: 'moderatorId',
                                    key: 'moderatorId',
                                    align: 'center',
                                    render: (moderatorId) => {
                                        return <div>{moderatorId ? moderatorId.substring(1, moderatorId.length - 1) : ''}</div>
                                    }
                                },
                                {
                                    title: '主页创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },

                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>
                                            {

                                                text.hotRankStatus == true ?
                                                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.confirmBanUser(text)}
                                                            color="blue">屏蔽</Button> :
                                                        <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                                                onClick={() => this.confirmUnBanUser(text)}>解除屏蔽</Button>
                                            }
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditPlayerDetailEvent(text, operationTypeValue.UPDATE)}
                                                color="blue">编辑</Button>
                                            <Button type="primary"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onOpenEditPlayerPostEvent(text, operationTypeValue.INCREASE_POST)}
                                                color="blue">发布选手说</Button>
                                            <Button type="danger"
                                                style={{marginLeft: 10, marginBottom: 10}}
                                                onClick={() => this.onConfirmPlayerDetailDelete(text.id, operationTypeValue.DELETE)}
                                                color="blue">删除</Button>

                                        </div>
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={playerDetailList.data ? playerDetailList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: playerDetailList ? playerDetailList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />

                    {(operationType === 'INCREASE' || operationType === 'UPDATE') &&
                        <PlayerDetailForm
                            title={operationType === 'INCREASE' ? '新增选手主页' : '编辑选手主页信息'}
                            visible={showEditPlayerDetailModal}
                            {...playerDetailData}
                            onSubmitEvent={operationType === 'INCREASE' ? this.onAddPlayerDetailEvent : this.onEditPlayerDetailEvent}
                            onCancel={this.onCloseEditPlayerDetailEvent}
                        />
                    }
                    {operationType === 'INCREASE_POST' &&
                        <PlayerPostForm
                            title={'发布选手说'}
                            visible={showEditPlayerPostModal}
                            {...playerDetailData}
                            onSubmitEvent={this.onEditPlayerPostEvent}
                            onCancel={this.onCloseEditPlayerPostEvent}
                        />
                    }
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.PlayerDetail
        }

    }

);

const mapDispatchToProps = {
    getPlayerDetailList,
    createPlayerDetail,
    updatePlayerDetailData,
    deletePlayerDetail,
    createPlayerPost
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(PlayerDetailManagement)));
