import createReducer from 'utils/createReducer';

import {
    GET_APP_NOTICES,GET_APP_NOTICE
} from './actionTypes';

const defaultState = () => ({
    appNotices: [],
    appNoticesTotal: 0,
    appNoticesLoading: false,
    appNoticeInfo: {},
    adminRoles: [], // 用户所拥有的角色
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        appNotices:payload

    }

};


const ongetAppNoticesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        appNoticeInfo: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_APP_NOTICES}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_APP_NOTICE}_SUCCESS`]: ongetAppNoticesuccess,
});
