import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getModels,getExclusivePlan,getAllPlan,updatePlan} from "../../modules/small/action";

import {operationTypeValue} from "../../modules/report/reportEnum";
import PlanNameModal from "./components/PlanNameModal";
import LookPlanModel from "./components/LookPlanModel";
import SmallModelFilter from "./components/SmallModelFilter";


class SmallModelsManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            imageUrl: [],
            isShowPlanModal: false,
            isShowSinglePlanModal: false,
            currentAdminId: null,
            type: null,
            reports: [],
            modelId:"",
            planName:"",
            destroy:true,
            brand:"",
            brandName:"",
            model:"",
            modelName:"",
            planId:"",
            isPlanId:""
        };
    }

    componentDidMount() {

        let {getModels,getAllPlan} = this.props;
        let {pageNo,pageSize} = this.state;
        getModels(pageNo,pageSize);
        getAllPlan();


    }

    handleCancel = e => {
        this.setState({
            isShowPlanModal: false,
            isShowSinglePlanModal:false,
            destroy : true
        });
    };

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getModels} = this.props;
        let {brand,brandName,model,modelName,planId} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getModels(current, pageSize,brand,brandName,model,modelName,planId);
    }

    //查看方案的方法
    onGetPlanEvent = async (id,planId,planName) =>{
        const {getExclusivePlan} = this.props;
        if (planId!=null) {
            await getExclusivePlan(planId);
            this.setState({
                destroy : false,
                isShowPlanModal : true,
                modelId : id,
                planName: planName
            });
        }
        if (planId==null){
            this.setState({
                destroy : true,
                isShowPlanModal : true,
                modelId : id,
                planName: planName
            })
        }

    };

    //查看专属机型方案的方法
    onLookPlanEvent = async (modelId,id,planName) =>{

        const {getExclusivePlan} = this.props;
        if (id==null){
            await getExclusivePlan(0);
            return ;
        }
        await getExclusivePlan(id);
        this.setState({

            isShowSinglePlanModal : true,
            planName: planName,
            modelId : modelId
        });

    };

    onMound = () =>{
        message.info('点击查看方案详情！',1);
    }

    //更新机型方案的方法
    onUpdateEvent = async (planId,modelId) => {

        const { updatePlan, getModels } = this.props;
        const { pageNo, pageSize,brand,brandName,model,modelName,isPlanId} = this.state;
        console.log(planId,modelId)
        try {
            await updatePlan({planId,modelId});
            notification.success({ message: '更新成功' });
            this.setState({
                isShowPlanModal : false,
                isShowSinglePlanModal : false
            });

            getModels(pageNo, pageSize,brand,brandName,model,modelName,planId,isPlanId);
        } catch (e) {
            console.log(e);
        }
    };


    onFilterReportEvent = async (type, result) => {

    }

    dealAssign = async (id, result) => {

    }

    //条件查找动态方法
    onFilterEvent = async (brand,brandName,model,modelName,planId) => {
        let {getModels} = this.props;
        const {pageSize} = this.state;
        await getModels(
            1, pageSize, brand,brandName,model,modelName,planId);
        this.setState({
            pageNo: 1,
            brand : brand,
            brandName: brandName,
            model : model,
            modelName : modelName,
            isPlanId : planId
        })

    }

    render() {

        const {
            models,loading
        } = this.props;
        const {
            pageNo, pageSize,destroy
        } = this.state;
        console.log(destroy)
        return(
            <div>
                <Card style={{marginBottom: 30, width: 'auto'}}> <SmallModelFilter onCommit={this.onFilterEvent}/></Card>

                <Card title="机型列表">
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '品牌',
                                    dataIndex: 'brand',
                                    key: 'brand',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '机型',
                                    dataIndex: 'model',
                                    key: 'model',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '品牌名',
                                    dataIndex: 'brandName',
                                    key: 'brandName',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '机型名',
                                    dataIndex: 'modelName',
                                    key: 'modelName',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '方案名',
                                    dataIndex: 'planName',
                                    key: 'planName',
                                    align: 'center',
                                    width: "10%",
                                    render : (id,text) =>{
                                        return(
                                            <div>
                                                <span onMouseOver={this.onMound} onClick={(event) => this.onLookPlanEvent(text.id,text.planId,text.planName)}>{text.planName}</span>
                                            </div>
                                        )
                                    }

                                },
                                // {
                                //     title: '查看方案',
                                //     dataIndex: 'look',
                                //     key: 'look',
                                //     align: 'center',
                                //     width: "5%",
                                //     render: (id,text) => {
                                //         return (<div>
                                //             <Button  style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={(event) => this.onLookPlanEvent(text.id,text.planId,text.planName)}>查看方案详情</Button>
                                //         </div>)
                                //     },
                                // },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id,text) => {
                                        return (<div>
                                            <Button  style={{backgroundColor: '#00a854' ,color : '#fff',marginLeft: 10, marginBottom: 10}} onClick={(event) => this.onGetPlanEvent(text.id,text.planId,text.planName)}>配置方案</Button>
                                        </div>)
                                    },
                                },
                            ]
                        }

                        // title={
                        //     this.renderTableTitle
                        // }
                        dataSource={models.data ? models.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: models ? models.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="方案"
                    visible={this.state.isShowPlanModal}
                    // onOk={this.handleOk}
                    footer={null}
                    destroyOnClose={true}
                    cancelText={"取消"}
                    onCancel={this.handleCancel}
                    width={'60%'}
                >
                    <PlanNameModal {...this.props} {...this.state} onCommit={this.onUpdateEvent}/>
                </Modal>
                <Modal
                    title="方案查看"
                    width={'60%'}
                    visible={this.state.isShowSinglePlanModal}
                    // onOk={this.handleOk}
                    footer={null}
                    destroyOnClose={true}
                    cancelText={"取消"}
                    onCancel={this.handleCancel}
                >
                    <LookPlanModel {...this.props} {...this.state} onCommit={this.onUpdateEvent}/>
                </Modal>



            </div>

        )
    }

}
const mapStateToProps = (state => {
        console.log(state.Comments)
        return {
            ...state.models

        }

    }

);

const mapDispatchToProps = {
    getModels,getExclusivePlan,getAllPlan,updatePlan
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(SmallModelsManagement)));