import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Card, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getPayments} from "../../modules/pay/action";
import PayFilter from "./PayFilter";

class PayManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            isShowEditPayModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
            vipInfo: [],
            userId:"",
            status:"COMPLETE",
            dateRange:"",
            attachType : "",
            orderId:"",
            payChannel:""
        };
    }

    // componentDidMount() {
    //
    //     const {pageNo, pageSize} = this.state;
    //     let {getPayments} = this.props;
    //     getPayments(pageNo, pageSize);
    // }

    //条件查找订单方法
    onFilterPayEvent = async (userId,status,dateRange,attachType,orderId,payChannel) => {
        let {getPayments} = this.props;
        console.log(userId,status,dateRange,attachType)
        const {pageSize,} = this.state;
        this.setState({
            userId:userId,
            status:status,
            dateRange:dateRange,
            attachType : attachType,
            orderId : orderId,
            payChannel : payChannel
        });
        getPayments(1, pageSize,userId,status,dateRange,attachType,orderId,payChannel);
    };

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getPayments} = this.props;
        let {userId,status,dateRange,orderId,payChannel,attachType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getPayments(current, pageSize,userId,status,dateRange,attachType,orderId,payChannel);
    }

    render(){

        const {
            payments, loading
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return(
            <div>
                <Card style={{marginBottom: 30}}>
                    <PayFilter dataSource={{}} onCommit={this.onFilterPayEvent} />
                </Card>
                <Card>
                    <Table
                    columns={
                        [
                            {
                                title: '订单ID',
                                dataIndex: 'orderId',
                                key: 'orderId',
                                align: 'center',
                            },
                            {
                                title: '订单金额(元)',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                render(index,data){
                                    return index/100;
                                }
                            },
                            {
                                title: '消费者(用户展示)ID',
                                dataIndex: 'exhibitionId',
                                key: 'exhibitionId',
                                align: 'center',
                            },
                            {
                                title: '订单来源',
                                dataIndex: 'payChannel',
                                key: 'payChannel',
                                align: 'center',
                                render(index,data){
                                    if (data.payChannel == "APPLEPAY"){
                                        return "苹果 App 支付"
                                    }
                                    if (data.payChannel == "ALIPAY"){
                                        return "支付宝 App 支付"
                                    }
                                    if (data.payChannel == "WX"){
                                        return "微信 App 支付"
                                    }
                                    if (data.payChannel == "WX_LITE"){
                                        return "微信小程序支付"
                                    }
                                }
                            },
                            {
                                title: '商品类型',
                                dataIndex: 'attach',
                                key: 'attach',
                                align: 'center',
                                render(index,data){
                                    let a = [];
                                    a = data.attach.split("_");
                                    if (a[0] == "vip"){
                                        return "vip充值包"
                                    }
                                    if (a[0] == "recharge"){
                                        return "浪币充值包"
                                    }
                                    if(a[0] == "gold") {
                                        return data.subject;
                                    }
                                }
                            },
                            {
                                title: '支付状态',
                                dataIndex: 'status',
                                key: 'status',
                                align: 'center',
                                render(index,data){
                                    if (data.status == "INIT"){
                                        return "初始化状态"
                                    }
                                    if (data.status == "UNIFIED"){
                                        return "未支付"
                                    }
                                    if (data.status == "COMPLETE"){
                                        return "完成"
                                    }
                                    if (data.status == "REFUNDED"){
                                        return "已退款"
                                    }
                                    if (data.status == "CLOSE"){
                                        return "关闭"
                                    }
                                }
                            },
                            {
                                title: '创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                            },
                            {
                                title: '订单回调时间',
                                dataIndex: 'callbackTime',
                                key: 'callbackTime',
                                align: 'center',
                            },
                        ]
                    }

                    title={
                        this.renderTableTitle
                    }
                    dataSource={payments.data ? payments.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: payments ? payments.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
            </Card>
            </div>
        );
    }
}



const mapStateToProps = (state => {
        console.log(state.Payments)
        return {
            ...state.Payments
        }

    }

);

const mapDispatchToProps = {
    getPayments
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(PayManagement)));
