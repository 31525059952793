export const GET_TOPIC_REPORTS = 'GET_TOPIC_REPORTS';// 获取帖子举报列表

export const TOPIC_ASSIGN = 'TOPIC_ASSIGN';//修改帖子处理状态

export const GET_COMMENT_REPORTS = 'GET_COMMENT_REPORTS';// 获取评论举报列表

export const COMMENT_ASSIGN = 'COMMENT_ASSIGN';//修改评论处理状态

export const GET_USER_REPORTS = 'GET_USER_REPORTS';// 获取用户举报列表

export const USER_ASSIGN = 'USER_ASSIGN';// 修改用户处理状态
