import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import SearchTeamByQQFilter from "./SearchTeamByQQFilter";
import {getPersonBrand} from "../../../modules/training/action";

const FormItem = Form.Item;
const Option = Select.Option;

class CertificationFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        const {getGameList, getPersonBrand} = props;
        getGameList();
        getPersonBrand({page: 1, size: 100000});

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {personBrandData, games} = this.props;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline">
                    <FormItem label="品牌赛事名">
                        {getFieldDecorator('brandId', {
                                initialValue: ""
                            }
                        )(
                            <Select style={{minWidth: 250}} onChange={e => {
                                console.log(e)
                                this.setState({brandId: e})
                            }}>
                                <Option value="">全部</Option>
                                {personBrandData.data && personBrandData.data.map(item => {
                                    return (
                                        <Option
                                            value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem label="用户名称">
                        {getFieldDecorator('userName', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem label="用户ID">
                        {getFieldDecorator('exhibitionId', {
                                rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                            }
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>                   
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>
                </Form>
                {/* <SearchTeamByQQFilter/> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList, getPersonBrand
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CertificationFilter)));