import api from 'utils/api';

class CertificationRequest {
    /**
     * 查看参赛认证列表
     * @param {Object} params 请求参数
     */
    static async getCertifications(parmas) {
        return api.get('/admin/certifications', parmas);
    }

    /**
     * 获取参赛认证的队员信息
     * @param id
     * @returns {Promise<*>}
     */
    static async getCertification(brandId, teamId) {
        return api.get(`/admin/certifications/brands/${brandId}/teams/${teamId}`)
    }

    /**
     * 获取参赛认证的队员信息
     * @param id
     * @returns {Promise<*>}
     */
    static async getCertificationTeamMembers(brandId, teamShowId) {
        return api.get(`/admin/certifications/brands/${brandId}/teams/show/${teamShowId}`)
    }

    /**
     * 审核参赛认证
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
    static async verifyCertifiacation(parmas) {
        return api.post(`/admin/certifications/verify`, parmas)
    }

    /**
     * 添加参赛认证
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
    static async addTeamCertifiacation(parmas) {
        return api.post(`/admin/certifications`, parmas)
    }

    /**
     * 修改参赛认证
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
    static async updateTeamCertifiacation(parmas) {
        return api.put(`/admin/certifications`, parmas)
    }


    /**
     * QQ查看用户战队列表ID
     * @param params
     * @returns {Promise<*>}
     */
    static async getUserTeamListByQQ(qq){
        return api.get(`/admin/certifications/users/qq/${qq}`)
    }

    /**
     * 获取用户认证资料
     * @param params
     * @returns {Promise<*>}
     */
    static async getUser(userId){
        return api.get(`/admin/user/${userId}/credentials`)
    }

    /**
     * 获取所有用户认证资料
     * @param {Object} params 请求参数
     */
    static async getUserCredentials(parmas) {
        return api.get('/admin/user/credentials', parmas);
    }

    /**
     * 审核用户认证资料
     * @param {Object} params 请求参数
     */
    static async checkCredentials(id, parmas) {
        return api.post(`/admin/user/credentials/${id}/check`, parmas);
    }

    /**
     * 修改用户认证资料
     * @param params
     * @returns {Promise<*>}
     */
    static async updateUserCertification(userId,params){
        console.log(userId)
        return api.post(`/admin/user/credentials/${userId}`,params)
    }

    /**
     * 获取所有实名认证资料
     * @param {Object} params 请求参数
     */
     static async getRealNameCredentials(params) {
        return api.get('/admin/user/real/credentials', params); 
    }

    /**
     * 审核用户实名认证
     * @param {Object} params 请求参数
     */
     static async checkRealNameCredentials(userId, parmas) {
        return api.post(`/admin/user/real/credentials/${userId}/check`, parmas);
    }

    /**
     * 修改用户实名认证
     * @param params
     * @returns {Promise<*>}
     */
     static async updateRealNameCertification(exhibitionId,params){
        return api.post(`/admin/user/real/credentials/${exhibitionId}`,params)
    }

    /**
     * 个人训练赛-获取参赛认证列表
     * @param {Object} params 请求参数
     */
     static async getPersonCertificationList(params) {
        return api.get('/admin/personal/certifications', params);
    }

    /**
     * 个人训练赛-新增参赛认证
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
     static async addPersonCertification(parmas) {
        return api.post(`/admin/personal/certifications`, parmas)
    }

    /**
     * 个人训练赛-修改用户参赛认证升降级
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
     static async onUpdatePersonCertificationUpOrDown(params) {
        return api.post(`/admin/personal/certifications/group`, params);
    }

    /**
     * 个人训练赛-设置用户禁赛
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
     static async onUpdatePersonUserSuspend(params) {
        return api.put(`/admin/personal/certifications/suspend/${params.certId}`, {suspend: params.suspend});
    }

    /**
     * 个人训练赛-删除用户参赛认证
     * @param parmas
     * @returns {Promise<any|IDBRequest<IDBValidKey>|*|Promise<void>>}
     */
     static async onDeletePersonCertification(certId) {
        return api.delete(`/admin/personal/certifications/${certId}`);
    }

}

export default CertificationRequest;
