import React from 'react';
import {Form, Input, Modal, DatePicker, Select, Button, notification, Icon, Radio} from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import Map from "./Map"
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

//预测
class PredictionResultModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowMap:false,
            radioValue: '',
            predictionList:[{key:0,value:''},{key:1,value:''}]
        };
    }

    componentDidMount() {
        // alert(JSON.stringify(this.props.data));
        if(this.props.data != null && this.props.data.itemList != null) {
            let {itemList,rightItemId} = this.props.data;
            let items = [];

            let radioValue = '';
            for(let i=0; i<itemList.length; i++) {
                items.push({key:i,value:itemList[i].content});
                if(itemList[i].id == rightItemId) {
                    radioValue = i;
                }
            }
            this.setState({predictionList:items,radioValue:radioValue});
        }
    }

    onSubmit = () => {
        let {radioValue} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let {itemList} = this.props.data;
                let  guessItemId = itemList[radioValue].id;

                let val = {
                    guessItemId:guessItemId
                  }

                onSuccess && onSuccess(val,this.props.type);
            }
        });
    }
    onChange(value, dateString) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    onOk(value) {

    }

    onRadioChange = e=> {
        console.log('radio checked', e.target.value);
        this.setState({
            radioValue: e.target.value,
        });
    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginTop:'5px',
            marginLeft:'10px',
        };
        const {getFieldDecorator} = this.props.form;
        let {visible,type,data,gameList} = this.props
        let{isSignIn,isShowMap,predictionList} = this.state;
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title="赛事预测结果"
                onCancel={this.props.onClose}
                // cancelText="取消"
                // onOk={this.onSubmit}
                // okText={"确定"}
                footer={
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]

            }
            >
                <Form>
                    <FormItem
                        label="预测标题"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('guessTitle', {
                            initialValue:data.guessTitle
                        })(
                            <Input disabled={true} placeholder="请输入预测标题"/>
                        )}
                    </FormItem>



                    <FormItem
                        label="预测选项"
                        {...formItemLayout}

                    >
                    <Radio.Group onChange={this.onRadioChange} value={this.state.radioValue}>
                        {
                            predictionList.map((val,index)=>{
                                return(
                                    <Radio style={radioStyle} value={index}>
                                        {val.value}
                                    </Radio>
                                )
                            })
                        }
                    </Radio.Group>
                    </FormItem>

                </Form>

            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
PredictionResultModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PredictionResultModal)));
