import React, { Component } from 'react';
import {
  Card,
} from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl-context';
import { getAuthenticationInfo } from 'modules/authentication/action';
import { getAdmin, updateAdmin } from 'modules/admin/action';
import HeadPortrait from './components/headPortrait';
import FormFile from './components/formeFile';

import './index.scss';

class PersonalMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '个人中心',
      editNameShow: false,
    };
  }

  _renderName = () => {
    let { editNameShow } = this.state;
    let {
      username,
    } = this.props.user;
    if (!editNameShow) {
      return (
        <div className="FullnameField-contentHead">
          <div className="FullnameField-title">
            <div className="FullnameField-Name">{username || ' '}</div>
            {/* <div className="FullnameField-Edit" onClick={() => {
                            this.setState({
                                editNameShow: true
                            })
                        }}><Icon type="edit" />修改</div> */}
          </div>
        </div>
      );
    }
    // return this._editName()
  }

  handleSubmit = () => {
    let { getAuthenticationInfo } = this.props;
    getAuthenticationInfo();
  }

  render() {
    const { title } = this.state;
    let { user, updateAdmin } = this.props;
    let { userId } = user;
    return (
      <div className="perM">
        <Card title={title} className="perM-main">
          <div style={
            {
              width: 1000,
              margin: '0 auto',
              border: '1px solid #e6e6e6',
              position: 'relative',
            }
          }
          >
            <div style={
              { position: 'absolute', top: '5px', left: '32px' }
            }
            >
              <HeadPortrait
                userId={userId}
                userInfo={user}
                updateAdmin={updateAdmin}
                handleSubmit={this.handleSubmit}
              />
            </div>
            <div className="perM-content">
              {this._renderName()}
              <FormFile
                updateAdmin={updateAdmin}
                userId={userId}
                userInfo={user}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  componentWillMount() {
    let { getAuthenticationInfo } = this.props;
    getAuthenticationInfo();
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  userInfo: {},
});

const mapDispatchToProps = ({
  getAuthenticationInfo,
  getAdmin,
  updateAdmin,
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PersonalMessage));
