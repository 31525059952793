
import createAsyncAction from 'utils/createAsyncAction';
import {
    ADD_JOIN_TEAMS,
    CHECK_SIGN_UP,
    DELETE_SIGN_UP,
    EDIT_TRAINING,
    GET_ADDTEAM,
    GET_BRAND,
    GET_GRADE,
    GET_JOIN_TEAM,
    GET_TRAINING,
    UPDATE_GRADE,
    TRAINING_NOTICE,
    UPDATE_TRAINING_SIGN_UP_POSITION,
    GET_PERSONAL_GRADE,
    GET_PERSONAL_GRADE_DETAIL,
    GET_GAME_MAPS,
    ADD_VIDEO_CONFIG,
    GET_VIDEO_CONFIG,
    QUERY_VIDEO_LIST,
    UPDATE_VIDEO,
    GET_PLAY_AUTH,
    GET_TRAINING_TEMPLATE,
    DELETE_VIDEO,
    GET_TRAINING_TEMPLATE_DICTIONARIES,
    GET_TRAINING_TEMPLATE_LIST,
    ADD_TRAINING_TEMPLATE,
    MODIFY_TRAINING_TEMPLATE,
    DELETE_TRAINING_TEMPLATE,
    UPDATE_TRAINING_TOP,
    LIFT_GROUP,
    GET_PEL_LEAGUE_DIC,
    GET_PEL_TEAM_INTEGRAL,
    GET_PEL_TEAM_WEEKLY_INTEGRAL,
    UPDATE_PEL_TEAM_INTEGRAL,
    UPDATE_PEL_TEAM_WEEKLY_INTEGRAL,
    GET_SORT_FIELD_DIC,
    GET_PEL_PLAYER_RANK,
    UPDATE_PEL_PLAYER_RANK,
    GET_PEL_TEAM_RANK,
    GET_TRAINING_TEAM_RANK,
    UPDATE_TRAINING_TEAM_INTEGRAL,
    GET_TRAINING_PLAYER_RANK,
    UPDATE_TRAINING_PLAYER_RANK,
    GET_RANK_BASE_DATA_CONFIG,
    CREATE_RAND_DATA_CONFIG,
    GET_RANK_DATA_CONFIG, GET_RANK_DATA_CONFIG_BRANDS,DELETE_RANK_DATA_CONFIG,UPDATE_RAND_DATA_CONFIG,

    GET_PERSON_TRAINING, CREATE_PERSON_TRAINING, EDIT_PERSON_TRAINING, DELETE_PERSON_TRAINING, UPDATE_PERSON_TRAINING_TOP,
    GET_JOIN_PLAYER, ADD_JOIN_PLAYER, GET_JOIN_STATE_PLAYER, CHECK_PERSON_SIGN_UP, DELETE_PERSON_SIGN_UP, PERSON_TRAINING_NOTICE,
    GET_PERSON_TRAINING_GRADE, GET_PERSON_TRAINING_GRADE_DETAIL, ADD_PERSON_VIDEO_CONFIG, QUERY_PERSON_VIDEO_LIST,
    UPDATE_PERSON_VIDEO, DELETE_PERSON_VIDEO, GET_PERSON_BRAND, GET_PERSON_TRAINING_TEMPLATE_LIST, ADD_PERSON_TRAINING_TEMPLATE,
    UPDATE_PERSON_TRAINING_TEMPLATE, DELETE_PERSON_TRAINING_TEMPLATE, GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES, GET_PERSON_TRAINING_TEMPLATE,
    
    UPDATE_KICK_PERMISSIONS,
    UPDATE_LIFT_PERMISSIONS,
    
    GET_PERSONAL_RANK_DATA_CONFIG_BRANDS,
    CREATE_PERSONAL_RAND_DATA_CONFIG,
    GET_PERSONAL_RANK_BASE_DATA_CONFIG,
    GET_PERSONAL_RANK_DATA_CONFIG,
    DELETE_PERSONAL_RANK_DATA_CONFIG,
    UPDATE_PERSONAL_RAND_DATA_CONFIG,

    GET_PERSONAL_SORT_FIELD_DIC,
    GET_PERSONAL_TRAINING_PLAYER_RANK,
    UPDATE_PERSONAL_TRAINING_PLAYER_RANK

} from "./actionTypes";
import TrainingRequest from "../../request/TrainingRequest";

export const getTraining = (params) => (createAsyncAction(GET_TRAINING, () => (TrainingRequest.getTraining(params))));

export const getBrand = (params) => (createAsyncAction(GET_BRAND, () => (TrainingRequest.getBrand(params))));

export const getBrandAll = (params) => (createAsyncAction(GET_BRAND, () => (TrainingRequest.getBrandAll(params))));


export const updateTraining = (params) => (createAsyncAction(EDIT_TRAINING, () => (TrainingRequest.editTraining(params))));

export const createTraining = (params) => (createAsyncAction(EDIT_TRAINING, () => (TrainingRequest.createTraining(params))));

export const deleteTraining = (params) => (createAsyncAction(EDIT_TRAINING, () => (TrainingRequest.deleteTraining(params))));

export const getGrade = (params) => (createAsyncAction(GET_GRADE, () => (TrainingRequest.getGrade(params))));

export const updateGrade = (params) =>(createAsyncAction(UPDATE_GRADE, () => (TrainingRequest.updateGrade(params))));

export const getAddTeams = (params)=>(createAsyncAction(GET_ADDTEAM, () => (TrainingRequest.getAddTeams(params))))

export const checkSignUp = (params)=>(createAsyncAction(CHECK_SIGN_UP, () => (TrainingRequest.checkSignUp(params))))

export const deleteSignUp = (params)=>(createAsyncAction(DELETE_SIGN_UP, () => (TrainingRequest.deleteSignUp(params))))

export const getJoinTeam = (params)=>(createAsyncAction(GET_JOIN_TEAM, () => (TrainingRequest.getAddTeams(params))))

export const addJoinTeam=(params)=>(createAsyncAction(ADD_JOIN_TEAMS, () => (TrainingRequest.addJoinTeam(params))))

export const trainingNotice=(params)=>(createAsyncAction(TRAINING_NOTICE, () => (TrainingRequest.trainingNotice(params))))

export const updateTrainingSignUpPosition=(params)=>(createAsyncAction(UPDATE_TRAINING_SIGN_UP_POSITION, () => (TrainingRequest.updateTrainingSignUpPosition(params))))

export const getPersonalGrade=(params)=>(createAsyncAction(GET_PERSONAL_GRADE, () => (TrainingRequest.getPersonalGrade(params))))

export const getPersonalGradeDetail=(params)=>(createAsyncAction(GET_PERSONAL_GRADE_DETAIL, () => (TrainingRequest.getPersonalGrade(params))))

export const getGameMaps=()=>(createAsyncAction(GET_GAME_MAPS, () => (TrainingRequest.getGameMaps())))

export const addVideoConfig=(trainingId, params)=>(createAsyncAction(ADD_VIDEO_CONFIG, () => (TrainingRequest.addVideoConfig(trainingId, params))))

export const getVideoConfig=(trainingId)=>(createAsyncAction(GET_VIDEO_CONFIG, () => (TrainingRequest.getVideoConfig(trainingId))))

export const queryVideoList=(params)=>(createAsyncAction(QUERY_VIDEO_LIST, () => (TrainingRequest.queryVideoList(params))))

export const updateVideo=(id, params)=>(createAsyncAction(UPDATE_VIDEO, () => (TrainingRequest.updateVideo(id, params))))

export const deleteVideo=(id)=>(createAsyncAction(DELETE_VIDEO, () => (TrainingRequest.deleteVideo(id))))

export const getPlayAuth=(id, params)=>(createAsyncAction(GET_PLAY_AUTH, () => (TrainingRequest.getPlayAuth(id, params))))

export const getTrainingTemplate=(geme, brandId, type)=>(createAsyncAction(GET_TRAINING_TEMPLATE, () => (TrainingRequest.getTrainingTemplate(geme, brandId, type))))

export const getTrainingTemplateList=(params)=>(createAsyncAction(GET_TRAINING_TEMPLATE_LIST, () => (TrainingRequest.getTrainingTemplateList(params))))

export const getTrainingTemplateDictionaries=()=>(createAsyncAction(GET_TRAINING_TEMPLATE_DICTIONARIES, () => (TrainingRequest.getTrainingTemplateDictionaries())))

export const addTrainingTemplate=(params)=>(createAsyncAction(ADD_TRAINING_TEMPLATE, () => (TrainingRequest.addTrainingTemplate(params))))

export const modifyTrainingTemplate=(id, params)=>(createAsyncAction(MODIFY_TRAINING_TEMPLATE, () => (TrainingRequest.modifyTrainingTemplate(id, params))))

export const deleteTrainingTemplate=(id)=>(createAsyncAction(DELETE_TRAINING_TEMPLATE, () => (TrainingRequest.deleteTrainingTemplate(id))))

export const updateTrainingTop=(id)=>(createAsyncAction(UPDATE_TRAINING_TOP, () => (TrainingRequest.updateTrainingTop(id))))

export const liftGroup=(params)=>(createAsyncAction(LIFT_GROUP, () => (TrainingRequest.liftGroup(params))))

export const getPelLeagueDictionaries=()=>(createAsyncAction(GET_PEL_LEAGUE_DIC, () => (TrainingRequest.getPelLeagueDictionaries())))

export const getPelTeamRankIntegral=(params)=>(createAsyncAction(GET_PEL_TEAM_INTEGRAL, () => (TrainingRequest.getPelTeamRankIntegral(params))))

export const updatePelTeamRankIntegral=(params)=>(createAsyncAction(UPDATE_PEL_TEAM_INTEGRAL, () => (TrainingRequest.updatePelTeamRankIntegral(params))))

export const getPelTeamRankWeeklyIntegral=(params)=>(createAsyncAction(GET_PEL_TEAM_WEEKLY_INTEGRAL, () => (TrainingRequest.getPelTeamRankWeeklyIntegral(params))))

export const updatePelTeamRankWeeklyIntegral=(params)=>(createAsyncAction(UPDATE_PEL_TEAM_WEEKLY_INTEGRAL, () => (TrainingRequest.updatePelTeamRankWeeklyIntegral(params))))

export const getSortFieldDictionaries=(params)=>(createAsyncAction(GET_SORT_FIELD_DIC, () => (TrainingRequest.getSortFieldDictionaries(params))))

export const getPelPlayerRankList=(params)=>(createAsyncAction(GET_PEL_PLAYER_RANK, () => (TrainingRequest.getPelPlayerRankList(params))))

export const getPelTeamRankList=(params)=>(createAsyncAction(GET_PEL_TEAM_RANK, () => (TrainingRequest.getPelTeamRankList(params))))

export const updatePelPlayerRank=(params)=>(createAsyncAction(UPDATE_PEL_PLAYER_RANK, () => (TrainingRequest.updatePelPlayerRank(params))))

export const getTrainingTeamRankList=(params)=>(createAsyncAction(GET_TRAINING_TEAM_RANK, () => (TrainingRequest.getTrainingTeamRankList(params))))

export const updateTrainingTeamRankIntegral=(params)=>(createAsyncAction(UPDATE_TRAINING_TEAM_INTEGRAL, () => (TrainingRequest.updateTrainingTeamRankIntegral(params))))

export const getTrainingPlayerRankList=(params)=>(createAsyncAction(GET_TRAINING_PLAYER_RANK, () => (TrainingRequest.getTrainingPlayerRankList(params))))

export const updateTrainingPlayerRank=(params)=>(createAsyncAction(UPDATE_TRAINING_PLAYER_RANK, () => (TrainingRequest.updateTrainingPlayerRank(params))))

export const getRankBaseDataConfig=()=>(createAsyncAction(GET_RANK_BASE_DATA_CONFIG, () => (TrainingRequest.getRankBaseDataConfig())))

export const createRandDataConfig=(params)=>(createAsyncAction(CREATE_RAND_DATA_CONFIG, () => (TrainingRequest.createRandDataConfig(params))))

export const updateRandDataConfig=(params)=>(createAsyncAction(UPDATE_RAND_DATA_CONFIG, () => (TrainingRequest.updateRandDataConfig(params))))

export const getRankDataConfig=(id)=>(createAsyncAction(GET_RANK_DATA_CONFIG, () => (TrainingRequest.getRankDataConfig(id))))

export const getRankDataConfigBrands=()=>(createAsyncAction(GET_RANK_DATA_CONFIG_BRANDS, () => (TrainingRequest.getRankDataConfigBrands())))

export const deleteRankDataConfig =(id)=>(createAsyncAction(DELETE_RANK_DATA_CONFIG, () => (TrainingRequest.deleteRankDataConfig(id))))
export const clearTrainingTemplate = () => ({
    type: 'CLEAR_TRAINING_TEMPLATE',
});


export const getPersonTraining = (params) => (createAsyncAction(GET_PERSON_TRAINING, () => (TrainingRequest.getPersonTraining(params))));

export const createPersonTraining = (params) => (createAsyncAction(CREATE_PERSON_TRAINING, () => (TrainingRequest.createPersonTraining(params))));

export const updatePersonTraining = (params) => (createAsyncAction(EDIT_PERSON_TRAINING, () => (TrainingRequest.updatePersonTraining(params))));

export const deletePersonTraining = (params) => (createAsyncAction(DELETE_PERSON_TRAINING, () => (TrainingRequest.deletePersonTraining(params))));

export const updatePersonTrainingTop=(id)=>(createAsyncAction(UPDATE_PERSON_TRAINING_TOP, () => (TrainingRequest.updatePersonTrainingTop(id))))

export const getJoinPlayer = (params)=>(createAsyncAction(GET_JOIN_PLAYER, () => (TrainingRequest.getJoinPlayer(params))))

export const addJoinPlayer=(params)=>(createAsyncAction(ADD_JOIN_PLAYER, () => (TrainingRequest.addJoinPlayer(params))))

export const getJoinStatePlayer = (params)=>(createAsyncAction(GET_JOIN_STATE_PLAYER, () => (TrainingRequest.getJoinPlayer(params))))

export const checkPersonSignUp = (params)=>(createAsyncAction(CHECK_PERSON_SIGN_UP, () => (TrainingRequest.checkPersonSignUp(params))))

export const deletePersonSignUp = (params)=>(createAsyncAction(DELETE_PERSON_SIGN_UP, () => (TrainingRequest.deletePersonSignUp(params))))

export const personTrainingNotice=(params)=>(createAsyncAction(PERSON_TRAINING_NOTICE, () => (TrainingRequest.personTrainingNotice(params))))

export const getPersonTrainingGrade=(params)=>(createAsyncAction(GET_PERSON_TRAINING_GRADE, () => (TrainingRequest.getPersonTrainingGrade(params))))

export const getPersonTrainingGradeDetail=(params)=>(createAsyncAction(GET_PERSON_TRAINING_GRADE_DETAIL, () => (TrainingRequest.getPersonTrainingGrade(params))))

export const addPersonVideoConfig=(trainingId, params)=>(createAsyncAction(ADD_PERSON_VIDEO_CONFIG, () => (TrainingRequest.addPersonVideoConfig(trainingId, params))))

export const queryPersonVideoList=(params)=>(createAsyncAction(QUERY_PERSON_VIDEO_LIST, () => (TrainingRequest.queryPersonVideoList(params))))

export const updatePersonVideo=(id, params)=>(createAsyncAction(UPDATE_PERSON_VIDEO, () => (TrainingRequest.updatePersonVideo(id, params))))

export const deletePersonVideo=(id)=>(createAsyncAction(DELETE_PERSON_VIDEO, () => (TrainingRequest.deletePersonVideo(id))))

export const getPersonBrand = (params) => (createAsyncAction(GET_PERSON_BRAND, () => (TrainingRequest.getPersonBrand(params))));

export const getPersonTrainingTemplateList=(params)=>(createAsyncAction(GET_PERSON_TRAINING_TEMPLATE_LIST, () => (TrainingRequest.getPersonTrainingTemplateList(params))))

export const addPersonTrainingTemplate=(params)=>(createAsyncAction(ADD_PERSON_TRAINING_TEMPLATE, () => (TrainingRequest.addPersonTrainingTemplate(params))))

export const updatePersonTrainingTemplate=(id, params)=>(createAsyncAction(UPDATE_PERSON_TRAINING_TEMPLATE, () => (TrainingRequest.updatePersonTrainingTemplate(id, params))))

export const deletePersonTrainingTemplate=(id)=>(createAsyncAction(DELETE_PERSON_TRAINING_TEMPLATE, () => (TrainingRequest.deletePersonTrainingTemplate(id))))

export const getPersonTrainingTemplateDictionaries=()=>(createAsyncAction(GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES, () => (TrainingRequest.getPersonTrainingTemplateDictionaries())))

export const getPersonTrainingTemplate=(geme, brandId, type)=>(createAsyncAction(GET_PERSON_TRAINING_TEMPLATE, () => (TrainingRequest.getPersonTrainingTemplate(geme, brandId, type))))


export const updateKickPermissions=(params)=>(createAsyncAction(UPDATE_KICK_PERMISSIONS, () => (TrainingRequest.updateKickPermissions(params))))

export const updateLiftPermissions=(params)=>(createAsyncAction(UPDATE_LIFT_PERMISSIONS, () => (TrainingRequest.updateLiftPermissions(params))))

export const getPersonalRankDataConfigBrands=()=>(createAsyncAction(GET_PERSONAL_RANK_DATA_CONFIG_BRANDS, () => (TrainingRequest.getPersonalRankDataConfigBrands())))

export const createPersonalRandDataConfig=(params)=>(createAsyncAction(CREATE_PERSONAL_RAND_DATA_CONFIG, () => (TrainingRequest.createPersonalRandDataConfig(params))))

export const getPersonalRankBaseDataConfig=()=>(createAsyncAction(GET_PERSONAL_RANK_BASE_DATA_CONFIG, () => (TrainingRequest.getPersonalRankBaseDataConfig())))

export const getPersonalRankDataConfig=(id)=>(createAsyncAction(GET_PERSONAL_RANK_DATA_CONFIG, () => (TrainingRequest.getPersonalRankDataConfig(id))))

export const deletePersonalRankDataConfig =(id)=>(createAsyncAction(DELETE_PERSONAL_RANK_DATA_CONFIG, () => (TrainingRequest.deletePersonalRankDataConfig(id))))

export const updatePersonalRandDataConfig=(params)=>(createAsyncAction(UPDATE_PERSONAL_RAND_DATA_CONFIG, () => (TrainingRequest.updatePersonalRandDataConfig(params))))


export const getPersonalSortFieldDictionaries=(params)=>(createAsyncAction(GET_PERSONAL_SORT_FIELD_DIC, () => (TrainingRequest.getPersonalSortFieldDictionaries(params))))

export const getPersonalTrainingPlayerRankList=(params)=>(createAsyncAction(GET_PERSONAL_TRAINING_PLAYER_RANK, () => (TrainingRequest.getPersonalTrainingPlayerRankList(params))))

export const updatePersonalTrainingPlayerRank=(params)=>(createAsyncAction(UPDATE_PERSONAL_TRAINING_PLAYER_RANK, () => (TrainingRequest.updatePersonalTrainingPlayerRank(params))))