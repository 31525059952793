import createAsyncAction from 'utils/createAsyncAction';
import LotteryRequest from '../../request/LotteryRequest';
import {GET_PRIZES,
        UPDATE_PRIZE,
        GET_LOTTERY_RECORD_LIST,
        GET_LOTTERY_HARVEST,
        UPDATE_PRIZE_ORDER_SHIP,
} from "../lottery/actionTypes";


const getPrizes = () => (
    createAsyncAction(GET_PRIZES, () => (LotteryRequest.getPrizes()))
);

const updatePrize = (prizeId,params) => (
    createAsyncAction(UPDATE_PRIZE,() => (LotteryRequest.updatePrize(prizeId,params)))
);

const getLotteryRecord = (page, size, username, turnTablePrizeType, shippingStatus) => (
    createAsyncAction(GET_LOTTERY_RECORD_LIST, () => (LotteryRequest.getLotteryRecord(page, size, username, turnTablePrizeType, shippingStatus)))
);

const getLotteryHarvest = (awardRewardId) => (
    createAsyncAction(GET_LOTTERY_HARVEST, () => (LotteryRequest.getLotteryHarvest(awardRewardId)))
);

const updatePrizeOrderShip = (recordId, params) => (
    createAsyncAction(UPDATE_PRIZE_ORDER_SHIP, () => (LotteryRequest.updatePrizeOrderShip(recordId, params)))
);


export {
    getPrizes,updatePrize,getLotteryRecord,getLotteryHarvest,updatePrizeOrderShip
};
