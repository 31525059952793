import React from 'react';
import {Button, Card, Col, Form, message, Modal, Row, Table} from 'antd';
import {
    addMember,
    getMemberList,
    quiteMember,
    updateMemberSort,
    updateMemberIntroduction
} from "../../../modules/lineup/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import LineupMemberFilter from "./LineupMemberFilter";
import AddMemberModel from "./AddMemberModel";
import MemberSortModel from "./MemberSortModel";
import MemberIntroductionModel from "./MemberIntroductionModel";

const {confirm} = Modal;

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class MembersInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            exhibitionId: '',
            username: '',
            isShowAddMemberModel: false,
            isShowSetMemberSortModel:false,
            isShowSetMemberIntroduction:false,
            currentMemberId:',',
            selectValue: null
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.lineupMembers !== this.props.lineupMembers) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = async () => {
        const {value, getMemberList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        await getMemberList({page, size,lineupId:value.id});
    }

    onPageChangeEvent = async (current, pageSize) => {
        const {value, getMemberList} = this.props;
        await getMemberList({
            page: current, size: pageSize, exhibitionId: this.state.exhibitionId ? this.state.exhibitionId : null,
            username: this.state.username ? this.state.username : null,
            lineupId:value.id
        });
        this.setState({
            page: current,
            size: pageSize,
        });
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.visible !== this.props.visible) {
    //         console.log(nextProps.value)
    //         this.onGetTableData();
    //     }
    // }

    confirmQuitMember = (id) => {
        let _this = this;
        confirm({
            content: '确认要踢出该成员吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onQuitMemberEvent(id)
            },
            onCancel() {
            },

        });
    }

    onQuitMemberEvent = async (id) => {
        const {value, quiteMember} = this.props;
        await quiteMember(id);
        await this.onGetTableData();
        message.success('踢出成功');
    }

    //条件查找动态方法
    onFilterLineupMemberEvent = async (exhibitionId, username) => {
        const {value, getMemberList} = this.props;
        const {size} = this.state;
        await getMemberList({
            page: 1,
            size: size,
            exhibitionId: exhibitionId ? exhibitionId : null,
            username: username ? username : null,
            lineupId:value.id
        });

        this.setState({
                page: 1,
                exhibitionId: exhibitionId,
                username: username,
            }
        )
    }

    onAddLineupMember = async (exhibitionId)=>{
        const {value, addMember} = this.props;
        const {isShowAddMemberModel} =this.state;
        await addMember({exhibitionId,lineupId:value.id});
        await this.onGetTableData();
        message.success('添加成员成功');
        this.setState({isShowAddMemberModel: !isShowAddMemberModel});
    }

    setTeamPosition=(id,value)=>{
        console.log("setTeamPosition",value);
        this.setState({isShowSetMemberSortModel: true,currentMemberId:id,selectValue: value})
    }

    setMemberIntroduction=(id,value)=>{
        this.setState({isShowSetMemberIntroduction: true,currentMemberId:id,selectValue: value})
    }

    onSetTeamPosition = async (values)=>{
        let params={
            ...values,
            id:this.state.currentMemberId
        }
        const{updateMemberSort}=this.props;
        const {isShowSetMemberSortModel} = this.state;
        await updateMemberSort(params);
        await this.onGetTableData();
        message.success('设置展示顺序成功');
        this.setState({isShowSetMemberSortModel: !isShowSetMemberSortModel})
    }

    onSetMemberIntroduction = async (values)=>{
        let params={
            ...values,
            id:this.state.currentMemberId
        }
        const{updateMemberIntroduction}=this.props;
        const {isShowSetMemberIntroduction} = this.state;
        await updateMemberIntroduction(params);
        await this.onGetTableData();
        message.success('设置成员简介成功');
        this.setState({isShowSetMemberIntroduction: !isShowSetMemberIntroduction})
    }

    renderTableTitle = () => {
        const {value} = this.props;
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button style={{marginRight: 10}} type="primary"
                            onClick={() => this.setState({isShowAddMemberModel: true})}>添加成员</Button>
                </Col>
            </Row>
        )
    }
    

    render() {
        let {visible, lineupMembers} = this.props;
        const {page, size, isShowAddMemberModel,isShowSetMemberSortModel,isShowSetMemberIntroduction,selectValue} = this.state;
        return (
            <div>
                <Modal
                    width={"60%"}
                    visible={visible}
                    title="成员信息"
                    closable={false}
                    footer={<Button onClick={this.props.onClose}>关闭</Button>}
                >

                    <div>
                        <Card style={{marginBottom: 30}}>
                            <LineupMemberFilter dataSource={{}} onCommit={this.onFilterLineupMemberEvent}/>
                        </Card>
                        <Card>
                            <Table
                                title={this.renderTableTitle}
                                dataSource={lineupMembers ? lineupMembers.data : []}
                                columns={this.getTableColumns()}
                                bordered
                                pagination={{
                                    total: lineupMembers ? lineupMembers.total : 0,
                                    current: page,
                                    pageSize: size,
                                    onChange: this.onPageChangeEvent,
                                    showTotal:(total)=>{
                                        return `总计 ${total}条数据`;
                                    }
                                }}
                            />
                        </Card>
                    </div>
                </Modal>
                {isShowAddMemberModel ? <AddMemberModel
                    visible={isShowAddMemberModel}
                    onSuccess={this.onAddLineupMember}
                    onClose={() => {
                        this.setState({
                            isShowAddMemberModel: false,
                        });
                    }}
                />: null}

                {isShowSetMemberSortModel && <MemberSortModel
                    visible={isShowSetMemberSortModel}
                    onSuccess={this.onSetTeamPosition}
                    value={selectValue}
                    onClose={()=>{
                        this.setState({
                            isShowSetMemberSortModel:false,
                        })
                    }}
                />}

                {isShowSetMemberIntroduction && <MemberIntroductionModel
                    visible={isShowSetMemberIntroduction}
                    onSuccess={this.onSetMemberIntroduction}
                    value={selectValue}
                    onClose={()=>{
                        this.setState({
                            isShowSetMemberIntroduction:false,
                        })
                    }}
                />}

                 
            </div>
        );
    }

    getTableColumns = () => [{
        title: '成员ID',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
    },  {
        title: '用户展示ID',
        dataIndex: 'exhibitionId',
        align: 'center',
        key: 'exhibitionId',
    }, {
        title: '用户名',
        dataIndex: 'username',
        align: 'center',
        key: 'username',
    },{
        title: '展示排序',
        dataIndex: 'sort',
        align: 'center',
        key: 'sort',
    }, {
        title: '操作',
        dataIndex: 'details',
        align: 'center',
        key: 'details',
        render: (row, text) => (
            <div>
                <Button style={{marginRight: 5, marginBottom: 5}} type={"primary"}
                        onClick={() => this.setTeamPosition(text.id,text)} >{'设置展示顺序'}</Button>
                <Button style={{marginRight: 5, marginBottom: 5}} type={"primary"}
                        onClick={() => this.setMemberIntroduction(text.id,text)} >{'设置成员简介'}</Button>
                <Button style={{marginRight: 5}} type="danger"
                        onClick={() => this.confirmQuitMember(text.id)}
                        color="red">删除</Button>
            </div>
        ),
    },]
}

const mapStateToProps = (state) => {
    return {
        ...state.Lineup,
    }
};
const mapDispatchToProps = ({
    getMemberList,
    addMember,
    quiteMember,
    updateMemberSort,
    updateMemberIntroduction
});
MembersInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(MembersInfoModel)));
