import React from 'react';
import {Button, Form, Input, message, Modal, Table, Card, notification, Popconfirm} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import { getGiftCDKList, deleteGiftCDK } from '../../modules/gift/action';
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const {confirm} = Modal;

const openNotification = placement => {
    notification.error({
        message: `${placement}`,
        // description:
        //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // placement,
    });
};

class CDKModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        const { getGiftCDKList, giftId } = this.props;
        const {page, size} = this.state;
        getGiftCDKList(giftId, {giftId, page, size});
        this.setState({
            loading: false
        });
    }

    //分页方法
    onPageChangeEvent = (current, size) => {
        let { getGiftCDKList, giftId } = this.props;
        this.setState({
            page: current,
            size: size,
        });
        getGiftCDKList(giftId, {giftId, page: current, size});
    }

    // 删除CDK
    onDeleteCDKEvent = async (giftCdkId) => {
        const {deleteGiftCDK, getGiftCDKList, giftId} = this.props;
        const {page, size} = this.state;
        try {
            await deleteGiftCDK(giftCdkId);
            notification.success({message: '删除成功'});
            getGiftCDKList(giftId, {giftId, page, size});
        } catch (e) {
            console.log(e);
        }
    }

    downFile = (res, type) => {
        console.log(res)
        var blob = new Blob([res], {type: 'application/ms-excel    application/x-excel;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = 'CDK列表.xlsx'; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    };

    getAuthorizationHeader = (headers) => {
        try {
            let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
            const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
            if (headers && accessToken) {
                headers.Authorization = accessToken.token;
            }
            headers.ContentType = 'application/json';
            return headers;
        } catch (error) {
            return '';
        }
    };

    render() {
        let { visible, giftCDKList, onClose, giftId } = this.props;
        const {page, size, loading} = this.state;
        return (
            <Modal
                visible={visible}
                title={"CDK列表"}
                onOk={() => {}}
                onCancel={onClose}
                width={"50%"}               
            >
                <div>                                      
                    <Card style={{width: 'auto'}}>
                        <Table                           
                            columns={
                                [
                                    {
                                        title: 'CDK',
                                        dataIndex: 'cdk',
                                        key: 'cdk',
                                        align: 'center',
                                        width: '40%',                                       
                                    },
                                    {
                                        title: '是否已兑换',
                                        dataIndex: 'exchangeOrderId',
                                        key: 'exchangeOrderId',
                                        align: 'center', 
                                        width: '15%',
                                        render: (row, text) => (
                                            <div>{text.exchangeOrderId ? '是' : '否'}</div>
                                        )                                      
                                    },                                    
                                    {
                                        title: '兑换时间',
                                        dataIndex: 'exchangeTime',
                                        key: 'exchangeTime',
                                        align: 'center', 
                                        width: '15%'                                       
                                    },
                                    {
                                        title: '兑换者ID',
                                        dataIndex: 'exchangerId',
                                        key: 'exchangerId',
                                        align: 'center',
                                        width: '15%'                                       
                                    },
                                    {
                                        title: '操作',
                                        dataIndex: 'operate',
                                        key: 'operate',
                                        align: 'center',
                                        render: (id, text) => {
                                            return !text.exchangeOrderId ? <div>                                          
                                                <Popconfirm title={`确定要删除该CDK吗？`}
                                                            onConfirm={() => this.onDeleteCDKEvent(text.id)}
                                                            okText="确认" cancelText="取消"><br/>
                                                    <Button type="danger" style={{
                                                        marginLeft:10,
                                                        marginBottom:10,
                                                        backgroundColor: '#ff4d4f',
                                                        color: '#fff'
                                                    }}>删除</Button>
                                                </Popconfirm>                                               
                                            </div>
                                            : <div></div>
                                        }
                                    },                                                                      
                                ]
                            }

                            dataSource={giftCDKList.data ? giftCDKList.data : []}
                            bordered
                            loading={loading}
                            scroll={{
                                scrollToFirstRowOnChange: true,
                                y: '350px'
                            }}
                            pagination={{
                                total: giftCDKList ? giftCDKList.total : 0,
                                current: page,
                                size: size,
                                onChange: this.onPageChangeEvent,
                                showTotal:(total)=>{
                                    return `总计 ${total}条数据`;
                                }
                            }}
                        />                                            
                    </Card>
                    <Form.Item>
                        <div style={{ marginTop:20}}>
                            <Button type="primary" onClick={()=>{
                                // 导出指定礼品CDK列表
                                axios({ // 用axios发送post请求
                                    method: 'get',
                                    url: `/api/admin//gift/cdk/list/export/${giftId}`, // 请求地址
                                    data: {}, // 参数
                                    responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
                                    headers: this.getAuthorizationHeader({})
                                }).then(r => {
                                    this.downFile(r.data)
                                }).catch(e=>{
                                    console.log(e)
                                })
                            }} >导出CDK</Button>
                        </div>
                    </Form.Item>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Gift
    }
};
const mapDispatchToProps = ({
    getGiftCDKList, 
    deleteGiftCDK
});
CDKModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CDKModal)));
