import React from 'react';
import {Form, Input, Modal,DatePicker,Select, Button,notification } from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class UpdatePrizesModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                if (values && values.imaegUrl) {
                    values.imaegUrl = values.imaegUrl.split("?")[0];
                }
                console.log("values",values);
                onSuccess && onSuccess(values);
            }
        });
    }
    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,data} = this.props;
        let giftUnit = data.turnTablePrizeType === 'IN_KIND_GIFT' ? '（单位：份）' : data.turnTablePrizeType === 'VIP' ? '（单位：天）' : data.turnTablePrizeType === 'ENERGY' ? '（单位：点）' :data.turnTablePrizeType === 'GOLD' ? '（单位：点）' : '';
        return (
            <Modal
                width={'30%'}
                visible={visible}
                title = {"编辑奖品信息"}
                onCancel={this.props.onClose}
                footer={ 
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]   
            }
            >
                <Form>
                    <FormItem
                        label="奖品名称"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('name', {
                            rules: [{max: 10, message: '奖品名称不得超过10个字符!'},{required: true, message: '请输入奖品名称'}],
                            initialValue:data.name
                        })(
                            <Input placeholder="请输入话题名字"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="奖品类型"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('turnTablePrizeType', {
                            rules: [{required: true, message: '请选择奖品类型'}],
                            initialValue: data.turnTablePrizeType,
                        })(
                            <Select disabled={true} style={{width: '200px'}}>
                                <Option value="IN_KIND_GIFT">实物周边</Option>
                                <Option value="VIP">VIP会员（虚拟礼品）</Option>
                                <Option value="GOLD">紫金（虚拟礼品）</Option>
                                <Option value="ENERGY">能量值（虚拟礼品）</Option>                            
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="奖品图片"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: data.imageUrl,
                            rules: [{required: true, message: '请上传奖品图片'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={1001}
                                maxCount={1}
                            >
                                
                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="奖品份数"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('copies', {
                            rules: [{required: true, message: '请输入奖品份数'+giftUnit}],
                            initialValue:data.copies
                        })(
                            <Input placeholder={"请输入奖品份数"+giftUnit} type="number"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="奖品总量"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('totalStock', {
                            rules: [{required: true, message: '请输入奖品总量'}],
                            initialValue:data.totalStock
                        })(
                            <Input placeholder="请输入奖品总量" type="number"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="中奖概率"
                        {...formItemLayout}
                    
                    >
                        {getFieldDecorator('percent', {
                            rules: [{required: true, message: '请输入中奖概率'}],
                            initialValue:data.percent
                        })(
                            <Input placeholder="请输入中奖概率" type="number"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
UpdatePrizesModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdatePrizesModel)));
