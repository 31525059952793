import isNil from 'lodash/isNil';
import {
  ACCESS_TOKEN, AUTHENTICATION_INFO, USER_VIEW_PERMISSION_TREE, USER_PAGE_BUTTONS,
} from 'modules/authentication/constants';
import { login, setPageButtons } from 'modules/authentication/action';
import Cookie from 'js-cookie';

const initClient = (dispatch) => {
  const userJSON = sessionStorage.getItem(AUTHENTICATION_INFO);
  const viewPermissionJSON = sessionStorage.getItem(USER_VIEW_PERMISSION_TREE);
  const pageButtons = sessionStorage.getItem(USER_PAGE_BUTTONS);
  const accessTokenJSON = Cookie.get(ACCESS_TOKEN);
  const isLogin = !isNil(userJSON) && !isNil(viewPermissionJSON) && !isNil(accessTokenJSON) && !isNil(pageButtons);

  const commonActions = [];

  if (isLogin) {
    commonActions.push(dispatch(login(JSON.parse(accessTokenJSON),
      JSON.parse(userJSON), JSON.parse(viewPermissionJSON))), dispatch(setPageButtons(JSON.parse(pageButtons))));
  }

  return commonActions;
};

export default initClient;
