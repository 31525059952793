import React from 'react';
import {Cascader, Form, Input, InputNumber, Modal, Select, Switch, DatePicker, notification} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../components/upload";
import moment from "moment";
import AreaSelect from '../../components/area';

const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};


class UpdateUserModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: {
                province: '',
                city: '',
            },
            isOfficialCert: this.props.isOfficialCert || false,
        };
    }
    renderAddress = () => {
        const {city,province} = this.props.value;
        //let address = defaultValue.address
        let address = []
        if (city && province) {
            console.log(11111111)
            address.push(province, city)
        }
        console.log(address)
        return address
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {address} = this.state
                const {onSuccess} = this.props;

                if(!values.telephone && !values.mailbox) {
                    notification.error({message: '手机号和邮箱号，至少需要绑定一个'});
                    return;
                }

                confirm({
                    content: '确认要修改用户信息吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        if(values && values.imageUrl){
                            values.imageUrl=values.imageUrl.split("?")[0]
                        }
                        if(values && values.birthday){
                            values.birthday = moment(values.birthday).format('YYYY-MM-DD');
                        }
                        onSuccess && onSuccess(values,address)
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    onChange = (value) => {
        const {address} = this.state;
        address.province = value[0];
        address.city = value[1]
        this.setState({
            address
        })
    }

    onChangeOfficialCert = (value) => {
        this.setState({
            isOfficialCert: value
        });
    }

    render() {
        console.log(this.props)
        const {getFieldDecorator} = this.props.form;
        let {visible,value} = this.props;
        let { isOfficialCert } = this.state;
        return (
            <Modal
                visible={visible}
                title="修改用户信息"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="用户展示ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('exhibitionId', {
                            initialValue : value.exhibitionId,
                            rules: [{required: true, message: '用户展示ID不能为空'}],
                        })(
                            <Input disabled={true} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="用户ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userId', {
                            initialValue : value.id,
                            rules: [{required: true, message: '用户ID不能为空'}],
                        })(
                            <Input disabled={true} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="用户名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('username', {
                            initialValue : value.username,
                            rules: [{required: true, message: '请填写用户名'}, {max: 20, message: '用户名最多20个字'}],
                        })(
                            <Input placeholder="请填写用户名"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="头像"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue : value.imageUrl ? value.imageUrl+'?x-oss-process=image/resize,w_86' : ''
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                // width={200}
                                // height={200}
                                size={101}
                            />
                        )}
                    </FormItem>
                    <FormItem label="性别"
                              {...formItemLayout}>
                        {getFieldDecorator('gender', {
                                initialValue: value.gender ? value.gender !== 'UNKNOWN' ? value.gender : '' : '',
                                rules: [{required: true, message: '性别不能为空!'}],
                            }
                        )(
                            <Select placeholder="请选择性别" style={{width: 150}}>
                                <Select.Option value={'MALE'}>男</Select.Option>
                                <Select.Option value={'FEMALE'}>女</Select.Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="生日"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('birthday', {
                            rules: [{required: true, message: '请填写生日'}],
                            initialValue:value&& value.birthday ? moment(value.birthday, "YYYY-MM-DD") : moment().subtract('0', 'day'),
                        })(
                            <DatePicker format="YYYY-MM-DD"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="城市:"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('address', {
                            rules: [{required: true, message: '请选择地区', pattern: /^\S+$/}],
                            initialValue: this.renderAddress() ,
                        })(
                            <AreaSelect
                                onChange={this.onChange}
                                placeholder="请选择地区"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="绑定手机"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('telephone', {
                            initialValue : value.telephone,
                            // pattern: /^1[3456789]\d{9}$/, message: '手机号码有误，请重填'}
                            rules: [
                                {pattern: /^\d{11}$/, message: '手机号码有误，请重填'}
                            ],
                        })(
                            <Input placeholder="请填写手机号"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="绑定邮箱"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('mailbox', {
                            initialValue : value.mailbox,
                            rules: [
                                {pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g, message: '邮箱号格式有误，请重填'}
                            ],
                        })(
                            <Input placeholder="请填写邮箱号"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="个人签名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('signature', {
                            initialValue: value.signature,
                            rule: [{maxLength: 50, message: '签名不能超过50个字符'}]
                        })(
                            <TextArea disabled={isOfficialCert} placeholder="签名不能超过50个字符" maxLength={50} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="是否官方认证"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('officialCertification', {
                            rules: [{required: false, message: '请选择是否为官方认证用户', pattern: /^\S+$/}],
                            initialValue: value.officialCert,
                        })(
                            <Select placeholder="请选择是否为官方认证用户" style={{width: 100}} onChange={this.onChangeOfficialCert}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="官方认证说明"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('officialIntroduce', {
                            initialValue: value.officialIntroduce,
                            rule: [
                                {maxLength: 100, message: '签名不能超过100个字符'}
                            ]
                        })(
                            <TextArea disabled={!isOfficialCert} placeholder="请输入官方认证说明介绍" maxLength={100}/>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.users,
    }
};
const mapDispatchToProps = ({
});
UpdateUserModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdateUserModel)));
