import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getBanner,
    updateBanner,
    deleteBanner,
    changeStatus,
    getInnerJumpType,
    getValuation,
    getImage,
    updateValuation
} from "../../modules/banner/action";

import {getCommentTemplates,createCommentTemplate,updateCommentTemplate,deleteCommentTemplate} from "../../modules/comment/action"
import {operationTypeValue} from "../../modules/banner/bannerEnum";
import api from "../../utils/api";
import TemplateUpdateForm from "./TemplateUpdateForm";

class CommentTemplateManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            url: "",
            isShowEditAdminModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            isShowUrlModal: false,
            currentAdminId: null,
            innerJumpTypes: [],
            isOuter: true,
            bannerType: "",
            isShowAddValuationModal : false,
            isShowUpdateImageModal : false,
            isOpenModal : false,
            state : "",
            isShowTraining:false
        };
    }

    handleOk = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false,
            isShowTraining : false
        })
    };

    handleCancel = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false,
            isShowTraining : false
        })
    }

    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getCommentTemplates} = this.props;
        getCommentTemplates(pageNo, pageSize);
    }

    showUrlModal = (record) => {
        console.log(record.jumpType);
        let url = "";
        switch (record.jumpType) {
            case "INNER":
                url = `内链${record.attach}`
                break;
            case "OUTER":
                url = record.outerUrl;
                break;
            default:
        }
        this.setState({
            isShowUrlModal: true,
            url: url
        })
    }


    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenEditBannerModalEvent = (text, BannerId, type) => {
        console.log(text, BannerId)

        this.setState({
            operationType:type,
            id:text && text.id,
            content:text && text.content,
            isShowEditAdminModal: true,
        });
    }

    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenValuation = () =>{
        this.setState({
            isOpenModal: true,
        });
    }

    onOpenTraining = () =>{
        this.setState({
            isShowTraining: true,
        });
    }

    //上传身价配置和官方公告图
    onAddValuation = async ({imageUrl,enable,pageType}) => {
        console.log(pageType)
        const {getValuation} = this.props;
        try {
            await getValuation(imageUrl,enable,pageType);
            notification.success({message: '添加成功'});

            this.setState({
                isShowAddValuationModal : false
            })

        }catch (e) {
            console.log(e);

        }
    }

    //修改身价配置和官方公告图
    onCreateOrUpdate = async ({content}) => {
        const {pageNo, pageSize,id} = this.state;
        const {
            operationType
        } = this.state;
        if(operationType == operationTypeValue.INCREASE) {
           await this.onAddBannerEvent({content});
        }else {
            await this.onEditBannerEvent({id:id, content});
        }
    }



    //修改轮播图的方法
    onEditBannerEvent = async ({id,content}) => {
        const {pageNo, pageSize,current} = this.state;
        const {updateCommentTemplate, getCommentTemplates, bannerInfo} = this.props;
        try {
            await updateCommentTemplate(id, content);
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getCommentTemplates(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    openAddEvent = () => {
        this.setState({
            isShowAddValuationModal : true,
            isOpenModal : false,
            state : "add"
        })
    }

    openUpdateEvent = () => {

        this.setState({
            isShowUpdateValuationModal : true,
            isOpenModal : false,
            state :"update"
        })

    }

    //添加轮播图的方法
    onAddBannerEvent = async ({content}) => {
        const {pageNo, pageSize} = this.state;
        const {createCommentTemplate, getCommentTemplates} = this.props;
        try {
            await createCommentTemplate(content);
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getCommentTemplates(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    changeStatus = async (id, enable) => {
        const {pageNo, pageSize} = this.state;
        try {
            const {changeStatus, getCommentTemplates} = this.props;
            await changeStatus(id, enable);
            if (enable == true) {
                notification.success({message: '启用成功'});
            }
            if (enable == false) {
                notification.success({message: '禁用成功'});
            }
            getCommentTemplates(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    //删除轮播图的方法
    onDeleteBannerEvent = async (adminId) => {
        const {deleteCommentTemplate, getCommentTemplates} = this.props;
        const {pageNo, pageSize} = this.state;
        try {
            await deleteCommentTemplate(adminId);
            notification.success({message: '删除成功'});
            console.log(pageNo)

            getCommentTemplates(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getCommentTemplates} = this.props;
        let {pageType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getCommentTemplates(current, pageSize, pageType);
    }

    onFilterBannerEvent = (pageType) => {
        const {getCommentTemplates} = this.props;
        const {pageSize} = this.state;
        this.setState({
            pageType: pageType
        });
        getCommentTemplates(1, pageSize, pageType);
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary"
                         onClick={() => this.onOpenEditBannerModalEvent(null, null, operationTypeValue.INCREASE)}>新增</Button></Col>
            {/* &nbsp;
            <Col><Button type="primary"
                         onClick={() => this.onOpenValuation(true)}>配置图</Button></Col> */}
        </Row>

    )

    render() {
        console.log(this.props.images)
        const {
            commentTemplates, bannerInfo, loading
        } = this.props;
        const {
            isShowEditAdminModal, pageNo, pageSize, operationType
        } = this.state;
        return (
            <div>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                },

                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>
                                            <Popconfirm title={`确定要删除该评论模板吗？`}
                                                        onConfirm={() => this.onDeleteBannerEvent(text.id)}
                                                        okText="确认" cancelText="取消"><br/>
                                                <Button type="danger" style={{
                                                    marginRight:10,
                                                    marginBottom:10,
                                                    backgroundColor: '#ff4d4f',
                                                    color: '#fff'
                                                }}>删除</Button>
                                            </Popconfirm>
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditBannerModalEvent(text, text.id, operationTypeValue.UPDATE)}
                                                    color="blue">修改</Button>
                                        </div>
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={commentTemplates &&commentTemplates.data ? commentTemplates.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: commentTemplates ? commentTemplates.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑评论模板" : "添加评论模板"}
                        visible={isShowEditAdminModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditBannerModalEvent}
                    >
                        <TemplateUpdateForm {...this.state}  onSubmitEvent={this.onCreateOrUpdate} />
                    </Modal>
                </Card>

            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Comments)
        return {
            ...state.Comments,
        }

    }

);

const mapDispatchToProps = {
    getCommentTemplates,
    updateBanner,
    getBanner,
    deleteBanner,
    createCommentTemplate,
    updateCommentTemplate,
    deleteCommentTemplate,
    changeStatus,
    getInnerJumpType,
    getValuation,
    getImage,
    updateValuation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CommentTemplateManagement)));
