import React, {Component} from "react";
import {Form, DatePicker, Button, Select, Input,} from "antd";
import {getGame} from '../../../../src/modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import moment from "moment";
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const { Option } = Select;
const {RangePicker} = DatePicker;
class GuessFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let val = {
                  ...values,
                  date:values.date?moment(values.date).format('YYYY-MM-DD'):''
                }

                if(values.dateRange != null && values.dateRange.length > 0) {
                    val.startTime = moment(values.dateRange[0]).format('YYYY-MM-DD hh:mm:ss')
                    val.endTime = moment(values.dateRange[1]).format('YYYY-MM-DD hh:mm:ss')

                    delete val.dateRange;
                }

                onCommit && onCommit(val);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let {gameList} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="参与用户ID">
                    {getFieldDecorator('userId', {
                            initialValue: ''

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="参与用户名">
                    {getFieldDecorator('userName', {
                            initialValue: ''
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="比赛名称">
                    {getFieldDecorator('activityName', {
                            initialValue: ''
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="预测状态">
                    {getFieldDecorator('guessResultType', {
                            initialValue: ''
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value=" ">全部</Option>
                            <Option value="UN_PUBLISHED">未公布</Option>
                            <Option value="SUCCEED">预测成功</Option>
                            <Option value="FAIL">预测失败</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="参与时间区间">
                    {getFieldDecorator('dateRange', {
                        initialValue: ""
                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (Form.create()(withRouter(injectIntl(GuessFilter))));

