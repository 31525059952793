import React from 'react';
import {Card, Form, Input, Modal, Select, Table,} from 'antd';
import {getTeamCertificationInfo} from "../../../modules/certification/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import defaultImg from "../../../static/images/default.jpg";
import GetVIPModel from "./GetVIPModel";
import Zmage from 'react-zmage'

const FormItem = Form.Item;
const {Option} = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 2},
    wrapperCol: {span: 10},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};
const refuseReasons = ["战队队员游戏内ID与截图不一致","战队队员上传截图不符合规范","战队队员前缀名称不一致","战队前缀名称必须为当前所属战队名","战队图标显示不清","战队图标与战队名称不相符",
    "战队图标/截图上传无关图片","战队图标涉及影响（侮辱）其它战队","战队名称不符合规定"]

class CertificationInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            loading: false,
            checkState: null
        };
    }


    componentWillMount() {
        const {value} = this.props;
        this.props.form.resetFields(null);
        this.onInitData(value);
        this.setState({
            loading: true
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.certificationInfo !== this.props.certificationInfo) {
            this.setState({
                loading: false
            })
        }
    }

    onInitData = async (value) => {
        const {getTeamCertificationInfo} = this.props;
        await getTeamCertificationInfo(value.brandId, value.teamId);
        const {certificationInfo} = this.props;
        this.setState({
            checkState:  certificationInfo && certificationInfo.state && certificationInfo.state !== 'REVIEW' ? certificationInfo.state==='SUCCESS' ? 'ADOPT' : 'REFUSE' : null
        })
    }

    onSubmit = async () => {
        const {onSuccess} = this.props;
        // eslint-disable-next-line react/prop-types
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // eslint-disable-next-line no-unused-expressions
                onSuccess && onSuccess(values);
            }
        });

    }

    handleCancel = () => this.setState({previewVisible: false});

    onChange = (value) => {
        this.setState({checkState: value})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, certificationInfo} = this.props;
        const {loading, checkState} = this.state;
        return (
            <Modal
                width={'80%'}
                visible={visible}
                title={"参赛认证审核"}
                onCancel={this.props.onClose}
                cancelText={"取消"}
                onOk={this.onSubmit}
                okText={"确认"}
            >

                <Card style={{marginBottom: 30}}>
                    <Table
                        dataSource={certificationInfo.items ? certificationInfo.items : []}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={false}
                        loading={loading}
                    />
                </Card>

                {/*{value.state != "SUCCESS" ?*/}
                <Card>
                    <Form>
                        <FormItem
                            label="审核状态"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...{
                                labelCol: {span: 2},
                                wrapperCol: {span: 3},
                            }
                            }
                        >
                            {getFieldDecorator('state', {
                                initialValue: certificationInfo && certificationInfo.state && certificationInfo.state !== 'REVIEW' ? certificationInfo.state==='SUCCESS' ? 'ADOPT' : 'REFUSE' : "",
                                rules: [{required: true, message: '请选择审核状态!'}],
                            })(
                                <Select placeholder="请选择审核状态" onChange={this.onChange}>
                                    <Option value="ADOPT" >通过</Option>
                                    <Option value="REFUSE" >拒绝</Option>
                                </Select>,
                            )}
                        </FormItem>
                        {checkState && checkState === 'REFUSE' ? <FormItem
                            label="拒绝理由"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('remark', {
                                rules: [{required: true, message: '请选择拒绝理由!'}],
                                initialValue: certificationInfo && certificationInfo.failureNote ? certificationInfo.failureNote : [],
                            })(
                                <Select mode="multiple">
                                    {refuseReasons.map(item => {
                                        return (
                                            <Select.Option
                                                value={item}>{item}</Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </FormItem> : null}

                    </Form>
                </Card>
            </Modal>

        );
    }

    getTableColumns = () => [{
        title: '玩家展示ID',
        dataIndex: 'gamerShowId',
        align: 'center',
        key: 'gamerShowId',
        render: (row, text) => (
            <span>{text.gamerShowId ? text.gamerShowId : text.gamerId}</span>
        ),
    }, {
        title: '玩家名称',
        dataIndex: 'gamerName',
        align: 'center',
        key: 'gamerName',
    }, {
        title: '是否是YTC',
        dataIndex: 'YTC',
        align: 'center',
        key: 'YTC',
        render: (row, text) => (
            <GetVIPModel userId={text.gamerId}/>
        ),
    }, {
        title: '参赛昵称',
        dataIndex: 'inGameName',
        align: 'center',
        key: 'inGameName',
    }, {
        title: '成员类型',
        dataIndex: 'memberType',
        align: 'center',
        key: 'memberType',
        render: (row, text) => (
            <span>{text.memberType == "LEADER" ? "队长" : text.memberType == "FORMAL" ? "正式成员" : text.memberType == "ALTERNATE" ? "替补成员" : ""}</span>
        ),
    }, {
        title: '第三方游戏id',
        dataIndex: 'thirdGameId',
        align: 'center',
        key: 'thirdGameId',
    }, {
        title: '参赛QQ号',
        dataIndex: 'qqNum',
        align: 'center',
        key: 'qqNum',
    }, {
        title: '游戏截图证明',
        dataIndex: 'certificationImg',
        align: 'center',
        key: 'certificationImg',
        render: (row, text) => (
            <div>
                <Zmage id="myImage" src={text.certificationImg ? text.certificationImg : defaultImg} height="100"/>
            </div>
        ),
    }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification
    }
};
const mapDispatchToProps = ({
    getTeamCertificationInfo,

});
CertificationInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CertificationInfoModel)));
