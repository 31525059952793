import React from 'react';
import {
    Button,
    Card,
    Col,
    InputNumber,
    message,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Row,
    Table,
    Tabs,
    Tag
} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getGuess,getScheduleDetail,getPredictionDetail,editSchedule,editPrediction,editPredictionResult,addPrediction,addSchedule,delSchedule,recommendSchedule} from "../../modules/schedule/action";
import {getGame} from "../../modules/game/action";

import ScheduleModal from "./components/ScheduleModal";
import ScheduleFilter from "./components/ScheduleFilter";
import PredictionModal from "./components/PredictionModal";
import PredictionResultModal from "./components/PredictionResultModal";
import GuessFilter from "./components/GuessFilter";

const {confirm} = Modal;


/**
 * 预测列表
*/
class guess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowScheduleModel:false,//新增行程窗口
      isShowPredictionModal:false,
      isShowPredictionResultModal:false,
      predictionModelType:'ADD',
      modelType:'',//展示行程弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modelData:{},
      pageNo:1,
      pageSize: 10,
      params:{
        gamingDivision:'',
        date:'',
      },
      id:''

    };
  }

  componentWillMount() {
    this.props.getGame()
    this.onGetData()
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  //条件查找
  onFilterScheduleEvent = ( params) => {
    let {getGuess} = this.props;
    this.setState({
        pageNo: 1,
        pageSize: 10,
        params:{...params}
    }, () => {
      getGuess({page: 1, size: 10, ...params})
    })
  }

  onGetData = async () => {
      const {pageNo, pageSize,params} = this.state;
      let {getGuess} = this.props;
      console.log("state", this.state)
      await getGuess({page:pageNo, size:pageSize,...params});
  }

  //转换成游戏名字
  changeGameName = (v)=>{
    let {gameList} = this.props;
    let gameName;
    for(let i = 0;i < gameList.length;i++){
      if(gameList[i].code===v){
        gameName = gameList[i].gameName;
        break
      }
    }
    return gameName;
  }

  //查看行程详情
  viewSchedule = async(id) =>{
    let {getScheduleDetail} = this.props;
   await getScheduleDetail(id)
   this.setState({isShowScheduleModel: true,modelType:'EDIT',id})
  }

    //查看预测详情
    viewPrediction = async(id) =>{
        let {getPredictionDetail} = this.props;
        await getPredictionDetail(id)
        this.setState({isShowPredictionModal: true,predictionModelType:'EDIT',id})
    }

    //查看预测详情
    viewPredictionResult = async(id) =>{
        let {getPredictionDetail} = this.props;
        await getPredictionDetail(id)
        this.setState({isShowPredictionResultModal: true,id})
    }

  //编辑行程
  editSchedule = async(params) =>{
    let {editSchedule} = this.props;
    let {id} = this.state;
    await editSchedule({id,...params})
    message.success('编辑成功');
    this.onGetData()
  }

  //新增行程
  addSchedule = async(params) =>{
    let {addSchedule} = this.props;
    await addSchedule(params)
    message.success('新增成功');
    this.onGetData()
  }

    //编辑预测
    editPrediction = async(params) =>{
        let {editPrediction} = this.props;
        let {id} = this.state;
        await editPrediction({id,...params})
        message.success('编辑成功');
        this.onGetData()
    }

    //
    editPredictionResult = async(params) =>{
        let {editPredictionResult} = this.props;
        let {id} = this.state;
        let param = {guessId:id,guessItemId:params.guessItemId}
        await editPredictionResult({...param})
        message.success('设置成功');
        this.onGetData()
    }

    //新增预测
    addPrediction = async(params) =>{
        let {addPrediction} = this.props;
        console.log('ssssssssssssss'+JSON.stringify(params));
        await addPrediction(params)
        message.success('新增成功');
        this.onGetData()
    }


    //推荐到首页弹窗
  confirmToIndex = (params) => {
    let _this = this;
    confirm({
      content: params.isRecommend?'确认要推荐到首页吗?':'要取消推荐到首页吗？',
      okText: '确认',
      cancelText: '关闭',
      onOk() {
        _this.toIndex(params)
      },
      onCancel() {
      },

    });
  }
  //推荐到首页
  toIndex = async (params) => {
    let{recommendSchedule} = this.props;
    await recommendSchedule(params);
    if(params.isRecommend){
      message.success('推荐成功');
    }else{
      message.success('取消成功');
    }
    this.onGetData();
  }

  //删除行程弹窗
  confirmDel = (id) => {
    let _this = this;
    confirm({
      content: '  确认要删除这次的行程吗·?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.delSchedule(id)
      },
      onCancel() {
      },

    });
  }
  //删除行程
  delSchedule = async (id) => {
    let{delSchedule,getGuess} = this.props;
    const {pageNo, pageSize} = this.state;
    await delSchedule(id)
    message.success('删除成功');
    this.onGetData();

  }


  //操作行程详情页面
  handleSchedule  = async(v,type)=>{
    this.setState({isShowScheduleModel:false})
    if(type==='EDIT'){
      this.editSchedule({...v})
    }else if(type==='ADD'){
      this.addSchedule(v)
    }
  }

    handlePrediction = async(v,type)=>{
        this.setState({isShowPredictionModal:false})
        if(type==='EDIT'){
            this.editPrediction({...v})
        }else if(type==='ADD'){
            let {id} = this.state;
            v.matchActivityId = id;
            this.addPrediction(v)
        }
    }

    handlePredictionResult = async(v,type)=>{
        this.setState({isShowPredictionResultModal:false})
        this.editPredictionResult({...v})
    }

  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.setState({isShowScheduleModel: true,modelType:'ADD'})}>新增</Button>
        </Col>
    </Row>
  )


  render() {
    let{guessList,gameList,scheduleDetail,predictionDetail} = this.props;
    let{isShowScheduleModel,isShowPredictionModal,isShowPredictionResultModal,pageNo,pageSize,modelType,id,predictionModelType} = this.state;
    console.log(guessList,'guessList')
    return (
      <div>
        <Card style={{marginBottom: 30}}>
          <GuessFilter onCommit={this.onFilterScheduleEvent} gameList={gameList}/>
        </Card>
        <Card>
            <Table
                columns={
                    [
                        {
                            title: '预测ID',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                        },
                        {
                            title: '参与预测用户ID',
                            dataIndex: 'userId',
                            key: 'userId',
                            align: 'center',
                        },
                        {
                            title: '参与预测用户名',
                            dataIndex: 'userName',
                            key: 'userName',
                            align: 'center',
                        },
                        {
                            title: '比赛名称',
                            dataIndex: 'activityName',
                            key: 'activityName',
                            align: 'center',
                        },
                        {
                            title: '队伍1',
                            dataIndex: 'leftTeamName',
                            key: 'leftTeamName',
                            align: 'center',
                        },
                        {
                            title: '队伍2',
                            dataIndex: 'rightTeamName',
                            key: 'rightTeamName',
                            align: 'center',
                        },
                        {
                            title: '预测标题',
                            dataIndex: 'guessTitle',
                            key: 'guessTitle',
                            align: 'center',
                        },
                        {
                            title: '预测结果',
                            dataIndex: 'activityName',
                            key: 'activityName',
                            align: 'center',
                        },
                        {
                            title: '参与时间',
                            dataIndex: 'joinTime',
                            key: 'joinTime',
                            align: 'center',
                        },

                    ]
                }

                // title={this.renderTableTitle}
                dataSource={guessList ? guessList.data : []}
                bordered
                //loading={loading}
                pagination={{
                    total: guessList ? guessList.total : 0,
                    current: pageNo,
                    pageSize: pageSize,
                    onChange: this.onPageChangeEvent,
                    showTotal:(total)=>{
                        return `总计 ${total}条数据`;
                    }
                }}
            />

            {isShowScheduleModel && <ScheduleModal
                onSuccess={this.handleSchedule}
                visible={isShowScheduleModel}
                type={modelType}
                data={scheduleDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                      isShowScheduleModel: false,
                    });
                }}
                />}
            {isShowPredictionModal && <PredictionModal
                onSuccess={this.handlePrediction}
                visible={isShowPredictionModal}
                type={predictionModelType}
                data={predictionDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                        isShowPredictionModal: false,
                    });
                }}
            />}
            {isShowPredictionResultModal && <PredictionResultModal
                onSuccess={this.handlePredictionResult}
                visible={isShowPredictionResultModal}
                type={predictionModelType}
                data={predictionDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                        isShowPredictionResultModal: false,
                    });
                }}
            />}


        </Card>
      </div>
    );
  }

//翻页
onPageChangeEvent = (current, pageSize) => {
  const {getGuess} = this.props;
  const { params} = this.state;
  this.setState({
      pageNo: current,
      pageSize: pageSize,
  }, () => {
    getGuess({page: current, size: pageSize, ...params})
  });
}

}

const mapStateToProps = (state) => {
  return {
    ...state.viewSchedule,
    ...state.viewGame
  }
};

const mapDispatchToProps = ({
  getGuess,
  getScheduleDetail,
  getPredictionDetail,
  editSchedule,
  editPrediction  ,
  editPredictionResult,
  addSchedule,
  addPrediction,
  delSchedule,
  recommendSchedule,
  getGame,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(guess));
