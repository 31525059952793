import createReducer from 'utils/createReducer';
import {
    GET_BRAND_INFO,
    GET_BRAND_LIST,
    GET_PERSON_BRAND_LIST
} from './actionTypes';

const defaultState = () => ({
    brands: {
        data: [],
        total: 0,
    }, // 品牌赛事列表
    brandInfo: {}, // 品牌赛事信息
    personBrands: {
        data: [],
        total: 0,
    }, // 个人训练赛-品牌赛事列表
});

const onGetBrandInfoSuccess = (state, {payload}) => ({
    ...state,
    brandInfo: payload,
});

const onGetBrandListSuccess = (state, {payload}) => ({
    ...state,
    brands: payload,
});

const onGetPersonBrandListSuccess = (state, {payload}) => ({
    ...state,
    personBrands: payload,
});

export default createReducer(defaultState, {
    [`${GET_BRAND_INFO}_SUCCESS`]: onGetBrandInfoSuccess,
    [`${GET_BRAND_LIST}_SUCCESS`]: onGetBrandListSuccess,
    [`${GET_PERSON_BRAND_LIST}_SUCCESS`]: onGetPersonBrandListSuccess,
});
