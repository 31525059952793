import React from 'react';
import {Form, Input, InputNumber, Modal, Switch} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class BanPartnerTeacherModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                confirm({
                    content: '确认要封禁吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        onSuccess && onSuccess(values)
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    onChange =(checked) => {
        console.log(`switch to ${checked}`);
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, userName} = this.props;

        return (
            <Modal
                visible={visible}
                title="封禁"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="导师名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userName', {
                        })(
                            <span>{userName}</span>
                        )}
                    </FormItem>
                    <FormItem
                        label="封禁天数"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('days', {
                            initialValue: 1
                        })(
                            <InputNumber min={1} />
                        )}<span className="ant-form-text">天</span>
                    </FormItem>
                    <FormItem
                        label="永久封禁"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('forever', {
                        })(
                            <Switch onChange={this.onChange} />
                        )}
                    </FormItem>
                    <FormItem
                        label="封禁备注"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('remark', {
                            initialValue: "",
                            rule: [{maxLength: 50, message: '封禁备注不能超过50个字符'}]
                        })(
                            <TextArea placeholder="封禁备注不能超过50个字符" maxLength={50} />,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
});
BanPartnerTeacherModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(BanPartnerTeacherModel)));
