import api from '../utils/api';

class ReportRequestNew {

    /**
     * 获取帖子举报列表
     * @returns {*}
     */
    static getTopicReports(params) {
        return api.get('/admin/topic/postDynamic/report',params);
    }


    /**
     * 处理帖子举报
     * @param id
     * @param processResult处理状态
     * 
     */
    static topicAssign(id,processResult) {
        return api.put(`/admin/topic/postDynamic/report/${id}`,{processResult})
    }

     /**
     * 获取评论举报列表
     * @returns {*}
     */
      static getCommentReports(params) {
        return api.get('/admin/comment/report', params);
    }


    /**
     * 处理评论举报
     * @param id
     * @param processResult处理状态
     * 
     */
    static commentAssign(id,processResult) {
        return api.put(`/admin/comment/report/${id}`,{processResult})
    }

    /**
     * 获取用户举报列表
     * @returns {*}
     */
    static getUserReports(params) {
        return api.get('/admin/report', params);
    }   
    
    /**
     * 处理用户举报
     * @param id
     * @param processResult处理状态
     * 
     */
    static userAssign(id,processResult) {
        return api.put(`/admin/report/${id}`,{processResult})
    }    

}

export default ReportRequestNew;