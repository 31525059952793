import Http from 'request/AdminRequest';
import createAsyncAction from 'utils/createAsyncAction';
import {
  GET_ADMINS,
  GET_ADMIN,
  GET_ADMIN_AVATAR,
  GET_ADMIN_ROLES,
  CREATE_ADMIN,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  ASSIGN_ADMIN_ROLES,
  UPDATE_ADMIN_AVATAR,
  UNLOCK_ADMIN
} from './actionTypes';

const getAdmins = (page, size) => (
  createAsyncAction(GET_ADMINS, () => (Http.getAdmins(page, size)))
);

const getAdmin = (id) => (createAsyncAction(GET_ADMIN, () => (Http.getAdmin(id))));

const getAdminAvatar = (path) => { createAsyncAction(GET_ADMIN_AVATAR, () => { Http.getAdminAvatar(path); }); };

const createAdmin = ({
  username, password, telephone, mailbox,
}) => (createAsyncAction(CREATE_ADMIN,
  () => (Http.createAdmin({
    username, password, telephone, mailbox,
  }))));

const updateAdmin = (id, { mailbox, telephone, imageUrl }) => (createAsyncAction(UPDATE_ADMIN,
  () => (Http.updateAdmin(id, { mailbox, telephone, imageUrl }))));

const updateAdminAvatar = (id, { imageUrl }) => (createAsyncAction(UPDATE_ADMIN_AVATAR,
  () => (Http.updateAdminAvatar(id, { imageUrl }))));

const deleteAdmin = (id) => (createAsyncAction(DELETE_ADMIN,
  () => (Http.deleteAdmin(id))));

const unLockAdmin = (id) => (createAsyncAction(UNLOCK_ADMIN,
    () => (Http.unLockAdmin(id))));

const getAdminRoles = (userId) => (createAsyncAction(GET_ADMIN_ROLES,
  () => (Http.getAdminRoles(userId))));

const assignAdminRoles = (userId, roleCodes) => (createAsyncAction(ASSIGN_ADMIN_ROLES,
  () => (Http.assignAdminRoles(userId, roleCodes))));


export {
  getAdmins, getAdmin, createAdmin, updateAdmin, deleteAdmin,
  unLockAdmin, getAdminRoles, assignAdminRoles, updateAdminAvatar, getAdminAvatar,
};
