import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import Zmage from "react-zmage";
import {
    getAppStartImage,addStartImage,delAppStartImage,updateStartImage
} from "../../modules/startConfig/action";
import {operationTypeValue} from "../../modules/banner/bannerEnum";
import api from "../../utils/api";
import AppStart from "../../modules/startConfig";
import UpdateForm from './UpdateForm';


class AppStartManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            url: "",
            isShowEditAdminModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            isShowUrlModal: false,
            currentAdminId: null,
            innerJumpTypes: [],
            isOuter: true,
            configurationEnum: "START",
            isShowUpdateValuationModal : false,
            isShowUpdateImageModal : false,
            isOpenModal : false,
            state : "",
        };
    }

    handleOk = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false
        })
    };

    handleCancel = e => {
        this.setState({
            isShowUrlModal: false,
            isShowAddValuationModal : false,
            isShowUpdateValuationModal : false,
            isOpenModal : false
        })
    }

    componentDidMount() {

        const {pageNo, pageSize} = this.state;
        let {getAppStartImage} = this.props;
        getAppStartImage();
    }






    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenEditBannerModalEvent = () => {
            this.setState({
                state : "add",
                isShowUpdateValuationModal: true
            })
    }

    onCloseEditBannerModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenValuation = () =>{
        this.setState({
            isOpenModal: true,
        });
    }

    onDeleteCircleEvent = async (id) => {
        const {delAppStartImage,getAppStartImage} = this.props;
        try {
            await delAppStartImage(id);
            notification.success({message: '删除成功'});

            this.setState({
                isShowAddValuationModal : false
            })
            getAppStartImage();

        }catch (e) {
            console.log(e);

        }
    }





    //修改轮播图的方法
    onEditImageEvent = async ({imageUrl}) => {
        const {configurationEnum} = this.state;
        const {getAppStartImage, updateStartImage} = this.props;
        try {
            await updateStartImage(configurationEnum,imageUrl);
            notification.success({message: '更新成功'});
            this.setState({
                isShowUpdateValuationModal: false,
            });

            getAppStartImage();
        } catch (e) {
            console.log(e);
        }
    }

    openAddEvent = () => {
        this.setState({
            isShowAddValuationModal : true,
            isOpenModal : false,
            state : "add"
        })
    }

    openUpdateEvent = () => {

        this.setState({
            isShowUpdateValuationModal : true,
            isOpenModal : false,
            state :"update"
        })

    }


    onAddImageEvent = async ({imageUrl}) => {
        const {configurationEnum} = this.state;
        const {getAppStartImage,addStartImage} = this.props;
        console.log(imageUrl)
        this.setState({
            configurationEnum : "START"
        });
        try {
            await addStartImage(
                configurationEnum,
                imageUrl,
            );
            notification.success({message: '添加成功'});
            this.setState({
                isShowUpdateValuationModal: false,
            });

            getAppStartImage();
        } catch (e) {
            console.log(e);

        }
    }



    //删除轮播图的方法
    onDeleteBannerEvent = async (adminId) => {
        const {deleteBanner, getBanners} = this.props;
        const {pageNo, pageSize} = this.state;
        try {
            await deleteBanner(adminId);
            notification.success({message: '删除成功'});
            console.log(pageNo)

            getBanners(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getBanners} = this.props;
        let {pageType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getBanners(current, pageSize, pageType);
    }

    onOpenEvent = (imageUrl) => {

        this.setState({
            isShowUpdateValuationModal: true,
            url:imageUrl,
            state : "update"
        });
    }





    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary"
                         onClick={() => this.onOpenEditBannerModalEvent()}>新增</Button></Col>
        </Row>

    )

    render() {
        const {
            images, bannerInfo, loading
        } = this.props;
        console.log(this.state)


        const {
            isShowEditAdminModal, pageNo, pageSize, operationType
        } = this.state;
        return (
            <div>

                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    render: (imgUrl, record, index) => {
                                        return  <Zmage id="myImage" src={imgUrl+'?x-oss-process=image/resize,w_690'} width="auto" height="30%" />;
                                        //return <img src={imgUrl} alt={""} style={{width:100,height:100,borderRadius: '32px'}}/>;
                                    }
                                }, {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return(
                                            <div>
                                                <Button  style={{backgroundColor: '#00a854' ,color : '#fff',marginLeft: 10, marginBottom: 10}} onClick={(event) => this.onOpenEvent(text.imageUrl,text.planId,text.planName)}>修改</Button>
                                                &nbsp;&nbsp;
                                                <Popconfirm title={`确定要删除这个启动页吗?`}
                                                            onConfirm={() => this.onDeleteCircleEvent(text.id)}
                                                            okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                    <Button  style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                                </Popconfirm>


                                            </div>
                                            )

                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={images.data ? images.data : []}
                        bordered
                        loading={loading}
                    />

                </Card>
                <Modal
                    title={this.state.state === "add" ? "添加启动配置页" : "修改启动配置页"}
                    visible={this.state.isShowUpdateValuationModal}
                    // onOk={this.handleOk}
                    footer={null}
                    cancelText={"取消"}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={600}
                >{
                    this.state.state === "add" ? <UpdateForm onSubmitEvent={this.onAddImageEvent}/> : <UpdateForm {...this.state} onSubmitEvent={this.onEditImageEvent}/>
                }

                </Modal>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.AppStart
        }

    }

);

const mapDispatchToProps = {
    getAppStartImage,addStartImage,delAppStartImage,updateStartImage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(AppStartManagement)));