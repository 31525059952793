import createAsyncAction from 'utils/createAsyncAction';
import {
    CREATE_INFOMATION_POST, DELETE_INFOMATION_POST,
    GET_INFOMATION_POSTS,
    GET_INFOMATION_STATICS, UPDATE_INFOMATION_POST,

} from './actionTypes'
import InfomationRequest from "../../request/InfomationRequest";
import {DELETE_TOPIC, UPDATE_TOPIC} from "../topic/actionTypes";
import TopicRequest from "../../request/TopicRequest";

//资讯评论点赞统计
const getStatics= (params) => (
    createAsyncAction(GET_INFOMATION_STATICS,() => (InfomationRequest.getStatics(params)))
)

//获取资讯和帖子管理信息
const getInfomationPosts= (params) => (
    createAsyncAction(GET_INFOMATION_POSTS,() => (InfomationRequest.getInfomationPosts(params)))
)

//获取资讯和帖子管理信息
const createInfomationPost= (params) => (
    createAsyncAction(CREATE_INFOMATION_POST,() => (InfomationRequest.createInfomationPost(params)))
)

const updateInfomationPost = (id,params) => (createAsyncAction(UPDATE_INFOMATION_POST,
    () => (InfomationRequest.updateInfomationPost(id,params))));

const deleteInfomationPost= (id) => (createAsyncAction(DELETE_INFOMATION_POST,
    () => (InfomationRequest.deleteInfomationPost(id))));

export {
    getStatics,
    getInfomationPosts,
    createInfomationPost,
    updateInfomationPost,
    deleteInfomationPost,
}
