import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    getPersonBrand,
    getTraining,
    getTrainingTemplate,
    getTrainingTemplateDictionaries
} from "../../../modules/training/action";
import moment from "moment";
import {getGameList} from "../../../modules/team/action";
import TimePicker from "antd/es/time-picker";

const dateFormat = 'HH:mm:00';
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};
const {TextArea} = Input;
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingTemplateInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            gameNo: 1
        };
    }


    componentWillMount() {
        this.getGameList();
        const {getTrainingTemplateDictionaries, getPersonBrand} = this.props;
        getPersonBrand();
        getTrainingTemplateDictionaries();
    }

    getGameList = () => {
        let {getGameList} = this.props;
        getGameList();
    }

    onSubmit = () => {
        console.log("1111")
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                console.log(values)
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

        console.log(value)
    }


    onOk(value) {

        console.log(value)
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, personBrandData, data, games, trainingTemplate, trainingTemplateDictionaries, trainingTemplateInfo} = this.props

        let {gameNo} = this.state;
        return (
            <div>
                <Modal
                    visible={visible}
                    title="模板信息"
                    onCancel={this.props.onClose}
                    cancelText="取消"
                    onOk={this.onSubmit}
                    okText={"确认"}
                    width={1000}
                >
                    <Form>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="赛事名"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameTheme', {
                                        initialValue: trainingTemplateInfo.trainingTheme ?  trainingTemplateInfo.trainingTheme : "",
                                        // rules: [{ required: true, message: '请填写角色code值', pattern: /^\S+$/ }],
                                        rules: [{required: true, message: '请填写赛事名!'}],
                                    })(
                                        <Input placeholder="请填写赛事名"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="赛事类型"  {...formItemLayout}>
                                    {getFieldDecorator('brandId', {
                                            initialValue: trainingTemplateInfo.brandId ? trainingTemplateInfo.brandId : personBrandData.data && personBrandData.data[0].id,
                                            rules: [{required: true, message: '请填写赛事类型!'}],
                                        }
                                    )(
                                        <Select >
                                            {personBrandData.data && personBrandData.data.map(item => {
                                                return (
                                                    <Option
                                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="局数"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameNum', {
                                        initialValue: trainingTemplateInfo.gameNum ? trainingTemplateInfo.gameNum :  1,
                                        rules: [{required: true, message: '请填写正确格式'}],
                                    })(
                                        <InputNumber min={1} precision={0} placeholder="请填写局数" onChange={e => {
                                            this.setState({
                                                gameNo: e
                                            });
                                        }}
                                        />,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="最大报名人数"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('personalTotal', {
                                        initialValue: trainingTemplateInfo.personalTotal ? trainingTemplateInfo.personalTotal :  1,
                                        rules: [{required: true, message: '请填写正确格式'}],
                                    })(
                                        <InputNumber min={1} precision={0} placeholder="请填写最大报名人数"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="模板类型"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('type', {
                                        initialValue: trainingTemplateInfo.type ? trainingTemplateInfo.type :  "",
                                        rules: [{
                                            required: true
                                            , message: '请填写模板类型'
                                        }],
                                    })(
                                        <Input placeholder="请输入模板类型"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="比赛开始时间"  {...formItemLayout}>
                                    {getFieldDecorator('gameStartTime', {
                                            initialValue: trainingTemplateInfo.gameStartTime ? moment(trainingTemplateInfo.gameStartTime, dateFormat) :  moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择比赛开始时间!'}],
                                        }
                                    )(
                                        <TimePicker  showTime={{format: 'HH:mm'}} format="HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem label="报名开始时间"  {...formItemLayout}>
                                    {getFieldDecorator('signUpStartTime', {
                                            initialValue: trainingTemplateInfo.signUpStartTime ? moment(trainingTemplateInfo.signUpStartTime, dateFormat) :  moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择报名开始时间!'}],
                                        }
                                    )(
                                        <TimePicker  showTime={{format: 'HH:mm'}} format="HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}
                                                    defaultValue={moment('00', 'ss')}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="报名结束时间"  {...formItemLayout}>
                                    {getFieldDecorator('signUpEndTime', {
                                            initialValue: trainingTemplateInfo.signUpEndTime ? moment(trainingTemplateInfo.signUpEndTime, dateFormat) :  moment().subtract('0', 'day'),
                                            rules: [{required: true, message: '请选择报名结束时间!'}],

                                        }
                                    )(
                                        <TimePicker  showTime={{format: 'HH:mm'}} format="HH:mm:00"
                                                    onChange={this.onChange} onOk={this.onOk}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="联系方式"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('communication', {
                                        initialValue: trainingTemplateInfo.communication ? trainingTemplateInfo.communication :  "",
                                        rules: [{
                                            required: true
                                            , message: '请填写联系方式'
                                        }],
                                    })(
                                        <Input placeholder="请输入联系方式"/>,
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label="房间号"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('roomNo', {
                                        initialValue: trainingTemplateInfo.roomNo ? trainingTemplateInfo.roomNo : "",
                                        rules: [{required: false, message: '请填写房间号'}],
                                    })(
                                        <Input placeholder="请输入房间号"/>,
                                    )}
                                </FormItem>
                            </Col>                          
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="比赛规则"
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('gameRule', {
                                        initialValue: trainingTemplateInfo.gameRule ? trainingTemplateInfo.gameRule : "",
                                        rules: [{required: true, message: '请填写比赛规则!'}],
                                    })(
                                        <TextArea rows={6} placeholder="请输入比赛规则"/>,
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTraining,
    getGameList,
    getPersonBrand,
    getTrainingTemplate,
    getTrainingTemplateDictionaries
});
TrainingTemplateInfoModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingTemplateInfoModal)));
