import createReducer from 'utils/createReducer';

import {
    GET_ADMIN_OPERTION_LOGS
} from './actionTypes';

const defaultState = () => ({
    adminOperationLogs: [],
    adminOperationLogsTotal: 0,
    adminOperationLogsLoading: false,
});



const onGetAdminOperationLogsRequest = (state,{payload}) => {
    return {
        ...state,
        adminOperationLogsLoading:true
    }

};

const onGetAdminOperationLogsError = (state) => ({
    ...state,
    adminOperationLogsLoading: false,
});

const onGetAdminOperationLogsSuccess = (state, { payload }) => {
    return{
        ...state,
        adminOperationLogs: payload.data,
        adminOperationLogsTotal:payload.total,
        adminOperationLogsLoading: false,
    }

};


export default createReducer(defaultState, {
    [`${GET_ADMIN_OPERTION_LOGS}_REQUEST`]: onGetAdminOperationLogsRequest,
    [`${GET_ADMIN_OPERTION_LOGS}_SUCCESS`]: onGetAdminOperationLogsSuccess,
    [`${GET_ADMIN_OPERTION_LOGS}_ERROR`]: onGetAdminOperationLogsError,
});
