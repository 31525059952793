import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Col, Modal, notification, Popconfirm, Row, Table, Tabs} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getCoins,createCoin,updateCoin,deleteCoins} from "../../modules/coin/action";
import { operationTypeValue } from '../../modules/coin/coinEnum';
import CoinForm from "./CoinForm";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';

class CoinManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            isShowEditCoinModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            coinInfo : [],
            type:"ANDROID"


        };
    }


    componentDidMount() {
        const {getCoins} = this.props;
        getCoins();

    }

    onCloseEditCircleModalEvent = () => {
        this.setState({
            isShowEditCoinModal: false,
        });
    }

    //添加浪币包的方法
    onAddCoinEvent = async (amount,coin,packageDetail,packageName,type,applePayId) => {
        console.log(amount,coin,packageDetail,packageName,type,applePayId)
        const {createCoin, getCoins} = this.props;
        let patten = /^([0-9]*)?$/;
        if(patten.test(amount).valueOf() === false || patten.test(amount)===false){
            notification.error({message:"金额不能输入小数"});return;
        }
        try {
            await createCoin(amount,coin,packageDetail,packageName,type,applePayId);
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditCoinModal: false,
            });

            getCoins();
        } catch (e) {
            console.log(e);
        }
    }

    //修改浪币包的方法
    onEditCoinEvent = async (amount,coin,packageDetail,packageName,type,applePayId) => {
        const {coinInfo} = this.state;
        const {updateCoin, getCoins} = this.props;
        let patten = /^([0-9]*)?$/;
        if(patten.test(amount).valueOf() === false || patten.test(amount)===false){
            notification.error({message:"金额不能输入小数"});return;
        }

        try {
            await updateCoin(coinInfo.id, {
                amount,coin,packageDetail,packageName,type,applePayId
            });
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditCoinModal: false,
            });

            getCoins();
        } catch (e) {
            console.log(e);
        }
    }

    //删除浪币包的方法
    onDeleteCoinsEvent = async (coinsId) => {
        const { deleteCoins, getCoins } = this.props;
        try {
            await deleteCoins(coinsId);
            notification.success({ message: '删除成功' });

            getCoins();
        } catch (e) {
            console.log(e);
        }
    }


    onOpenEditCoinModalEvent = (text,type) => {
        const {getCoins} = this.props;
        if (type===operationTypeValue.UPDATE){
            getCoins();
            this.setState({
                operationType : operationTypeValue.UPDATE,
                coinInfo : text
            })

        }
        if (type===operationTypeValue.INCREASE){
            this.setState({
                operationType : type
            })
        }
        this.setState({
            isShowEditCoinModal: true,
        });
    };
    onType = (key) =>{
        console.log(key)
        if (key==1){
            console.log(11111111)
            this.setState({

                type:"ANDROID"
            })
        }else if (key==2){
            this.setState({

                type:"IOS"
            })
        }
        else {
            this.setState({
                type:"WXLITE"
            })
        }

    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                {
                    whetherButtonPermission(buttonType.CREATE_RECHARGE_PACKAGE.value, this.props.pageButtons) &&
                        <Button type="primary" onClick={() => this.onOpenEditCoinModalEvent(null,operationTypeValue.INCREASE)}>{buttonType.CREATE_RECHARGE_PACKAGE.label}</Button>
                }
            </Col>
        </Row>
    )

    render() {
        const {
            coins, loading
        } = this.props;
        const {
            operationType,isShowEditCoinModal
        } = this.state;


        return( <Tabs defaultActiveKey = "1" onChange={this.onType}>
                <Tabs.TabPane tab="安卓浪币充值包管理" key = "1" >
                <Table
                    columns={
                        [
                            {
                                title: 'ID',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                            },
                            {
                                title: '套餐名称',
                                dataIndex: 'packageName',
                                key: 'packageName',
                                align: 'center',
                            },
                            {
                                title: '套餐详情',
                                dataIndex: 'packageDetail',
                                key: 'packageDetail',
                                align: 'center',
                            },
                            {
                                title: '获取浪币（个）',
                                dataIndex: 'coin',
                                key: 'coin',
                                align: 'center',
                            },
                            {
                                title: '支付金额（元）',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (id, text) => {
                                  return(
                                      <div>
                                          {
                                              whetherButtonPermission(buttonType.MODIFY_RECHARGE_PACKAGE.value, this.props.pageButtons) &&
                                              <Button type="primary" onClick={() => this.onOpenEditCoinModalEvent(text,operationTypeValue.UPDATE)} color="blue">{buttonType.MODIFY_RECHARGE_PACKAGE.label}</Button>
                                          }
                                          &nbsp;
                                          {
                                              whetherButtonPermission(buttonType.DELETE_RECHARGE_PACKAGE.value, this.props.pageButtons) &&
                                              <Popconfirm title={`确定要删除这个浪币包吗?`}
                                                          onConfirm={(event) => this.onDeleteCoinsEvent(text.id)}
                                                          okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                  <Button type="danger"  color="red">{buttonType.DELETE_RECHARGE_PACKAGE.label}</Button>
                                              </Popconfirm>
                                          }
                                    </div>
                                  )
                                },
                            },
                        ]
                    }
                    title={
                        this.renderTableTitle
                    }

                    dataSource={coins ? coins.filter(v=>v.type==="ANDROID") : []}
                    bordered
                    loading={loading}
                    // pagination={{
                    //     total: circles ? circles.total : 0,
                    //     current: pageNo,
                    //     pageSize: pageSize,
                    //     onChange: this.onPageChangeEvent,
                    // }}
                />
                </Tabs.TabPane>
                <Tabs.TabPane tab="IOS浪币充值包管理" key = "2">
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '套餐名称',
                                    dataIndex: 'packageName',
                                    key: 'packageName',
                                    align: 'center',
                                },
                                {
                                    title: '套餐详情',
                                    dataIndex: 'packageDetail',
                                    key: 'packageDetail',
                                    align: 'center',
                                },
                                {
                                    title: '获取浪币（个）',
                                    dataIndex: 'coin',
                                    key: 'coin',
                                    align: 'center',
                                },
                                {
                                    title: '支付金额（元）',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'center',
                                },
                                {
                                    title:'苹果支付套餐ID',
                                    dataIndex: 'applePayId',
                                    key: 'applePayId',
                                    align: 'center'
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return(
                                            <div>
                                                <Button type="primary" onClick={() => this.onOpenEditCoinModalEvent(text,operationTypeValue.UPDATE)} color="blue">修改</Button>
                                                &nbsp;
                                                <Popconfirm title={`确定要删除这个浪币包吗?`}
                                                            onConfirm={(event) => this.onDeleteCoinsEvent(text.id)}
                                                            okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                    <Button type="danger"  color="red">删除</Button>
                                                </Popconfirm>
                                            </div>
                                        )
                                    },
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }

                        dataSource={coins ? coins.filter(v=>v.type==="IOS") : []}
                        bordered
                        loading={loading}
                        // pagination={{
                        //     total: circles ? circles.total : 0,
                        //     current: pageNo,
                        //     pageSize: pageSize,
                        //     onChange: this.onPageChangeEvent,
                        // }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="小程序浪币充值包管理" key = "3">
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '套餐名称',
                                    dataIndex: 'packageName',
                                    key: 'packageName',
                                    align: 'center',
                                },
                                {
                                    title: '套餐详情',
                                    dataIndex: 'packageDetail',
                                    key: 'packageDetail',
                                    align: 'center',
                                },
                                {
                                    title: '获取浪币（个）',
                                    dataIndex: 'coin',
                                    key: 'coin',
                                    align: 'center',
                                },
                                {
                                    title: '支付金额（元）',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return(
                                            <div>
                                                <Button type="primary" onClick={() => this.onOpenEditCoinModalEvent(text,operationTypeValue.UPDATE)} color="blue">修改</Button>
                                                &nbsp;
                                                <Popconfirm title={`确定要删除这个浪币包吗?`}
                                                            onConfirm={(event) => this.onDeleteCoinsEvent(text.id)}
                                                            okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                    <Button type="danger"  color="red">删除</Button>
                                                </Popconfirm>
                                            </div>
                                        )
                                    },
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }

                        dataSource={coins ? coins.filter(v=>v.type==="WXLITE") : []}
                        bordered
                        loading={loading}
                        // pagination={{
                        //     total: circles ? circles.total : 0,
                        //     current: pageNo,
                        //     pageSize: pageSize,
                        //     onChange: this.onPageChangeEvent,
                        // }}
                    />
                </Tabs.TabPane>
                <Modal
                    title = {operationType === operationTypeValue.UPDATE ? "编辑浪币包" : "添加浪币包"}
                    visible={isShowEditCoinModal}
                    footer={null}
                    destroyOnClose
                    onCancel={this.onCloseEditCircleModalEvent}
                >
                    {operationType === operationTypeValue.UPDATE ? <CoinForm {...this.state.type} {...this.state.coinInfo} {...this.props} onSubmitEvent={this.onEditCoinEvent}/> : <CoinForm {...this.state} {...this.props} onSubmitEvent={this.onAddCoinEvent}/>}
                </Modal>
            </Tabs>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.viewCoin,
            pageButtons:state.authentication.pageButtons
        }

    }

);

const mapDispatchToProps = {
    getCoins,
    createCoin,
    updateCoin,
    deleteCoins
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CoinManagement)));
