import createReducer from 'utils/createReducer';

import {
    GET_BANNERS,GET_BANNER,GET_INNER_JUMP_TYPE,GET_VALUATION_IMAGE
} from './actionTypes';

const defaultState = () => ({
    banners: [],
    bannersTotal: 0,
    bannersLoading: false,
    bannerInfo: {},
    adminRoles: [], // 用户所拥有的角色
    innerJumpList:[],
    images : []
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        banners:payload

    }

};

const ongetListRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        innerJumpList:payload

    }

};

const ongetImage = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        images:payload

    }

};


const ongetBannersuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        bannerInfo: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_BANNERS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_BANNER}_SUCCESS`]: ongetBannersuccess,
    [`${GET_INNER_JUMP_TYPE}_SUCCESS`]: ongetListRequest,
    [`${GET_VALUATION_IMAGE}_SUCCESS`]: ongetImage,

});
