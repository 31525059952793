import React, { Component } from 'react';
import {
  Form, Button, Select,Input,InputNumber
} from 'antd';
import {accountCoinOperateType} from 'modules/account/enums'


const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};


class LangBiRechargeForm extends Component {


  onSubmitEvent = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { onSubmitEvent } = this.props;
        onSubmitEvent && onSubmitEvent(values);
      }
    });
  }

  render() {
    let { exhibitionId } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="horizontal">
        <Form.Item
            label="用户ID"
            {...formItemLayout}
        >
          {getFieldDecorator('exhibitionId', {
            initialValue: exhibitionId,
          })(
              <Input disabled />,
          )}
        </Form.Item>
          <Form.Item label='操作类型' {...formItemLayout}>
              {
                  getFieldDecorator('type', {initialValue:accountCoinOperateType.CONSUME.value})(
                      <Select style={{width: '80px'}}>
                          {
                              Object.keys(accountCoinOperateType).map(key => (
                                  <Select.Option value={accountCoinOperateType[key].value} >{accountCoinOperateType[key].label}</Select.Option>
                              ))
                          }
                      </Select>
                  )
              }
          </Form.Item>
        <Form.Item
            label="操作数量"
            {...formItemLayout}
        >
          {getFieldDecorator('coinAmount', {
            initialValue: 1,
          })(
              <InputNumber min={1} />,
          )}
        </Form.Item>
        <Form.Item
            label="操作理由"
            {...formItemLayout}
        >
          {getFieldDecorator('reason', {
            initialValue: "",
            rules:[
                {
                    required:true,
                    message:"请填写操作理由"
                }
            ]
          })(
              <Input.TextArea rows={4}  placeholder="请填写操作理由"   />,
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(LangBiRechargeForm);
