import api from 'utils/api';
import createAsyncAction from 'utils/createAsyncAction';


const createAccessToken = (username, password) => (
  createAsyncAction('CREATE_ACCESS_TOKEN', () => (
    api.post('/admin/access_tokens/admins', { username, password }, true)
  ))
);

const getAuthenticationInfo = () => (
  createAsyncAction('GET_AUTHENTICATION_INFO', () => (
    api.get('/admin/authentication/admin')
  ))
);

const getUserViewPermissionTree = () => (
  createAsyncAction('GET_ADMIN_VIEW_PERMISSIONS_TREE', () => (
    api.get('/admin/authentication/admin/view_permission_tree')
  ))
);

const login = (accessToken, user, viewPermissionsTree) => ({
  type: 'LOGIN',
  payload: {
    ...accessToken,
    user,
    viewPermissionsTree,
  },
});

const setPageButtons = (pageButtons) => ({
  type: 'SET_PAGE_BUTTONS',
  payload: {
    pageButtons,
  },
});


const logout = () => ({
  type: 'LOGOUT',
});


export {
  createAccessToken,
  getAuthenticationInfo,
  getUserViewPermissionTree, login, logout, setPageButtons,
};
