import React, {Component} from "react";
import {Form, Button, Select, Input} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;


class GiftFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onCommit } = _self.props;
                let { username, userId, userTicketStatus} = values;

                console.log(values)

                onCommit && onCommit(username, userId,userTicketStatus);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {gameList} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="用户名称">
                    {getFieldDecorator('username', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="用户ID">
                    {getFieldDecorator('userId', {
                        initialValue: ''
                    }) (
                        <Input style={{width: '200px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="券票状态:">
                    {getFieldDecorator('userTicketStatus', {
                        initialValue:"",
                    })(
                        <Select style={{width: 120}}>
                            <Option value="">全部</Option>
                            <Option value="UNUSED">未使用</Option>
                            <Option value="USED">已使用</Option>
                            <Option value="EXPIRED">已过期</Option>
                        </Select>,
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}


export default Form.create()(GiftFilter);
