import createAsyncAction from 'utils/createAsyncAction';
import CircleRequest from '../../request/CircleRequest';
import {GET_CIRCLES,
        GET_CIRCLE,
        UPDATE_CIRCLE,
        DELETE_CIRCLE,
        CREATE_CIRCLE,
} from "../circle/actionTypes";


const getCircles = (pageNo, pageSize) => (
    createAsyncAction(GET_CIRCLES, () => (CircleRequest.getCircles(pageNo, pageSize)))
);

const getCircle = (id) => (createAsyncAction(GET_CIRCLE, () => (CircleRequest.getCircle(id))));

const updateCircle = (id, { name }) => (createAsyncAction(UPDATE_CIRCLE,
    () => (CircleRequest.updateCircle(id, { name }))));

const deleteCircle = (id) => (createAsyncAction(DELETE_CIRCLE,
    () => (CircleRequest.deleteCircle(id))));

const createCircle = (name) => (createAsyncAction(CREATE_CIRCLE, () => (CircleRequest.addCircle(name))));


export {
    getCircles,updateCircle,getCircle,deleteCircle,createCircle,
};