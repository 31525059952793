import createAsyncAction from 'utils/createAsyncAction';
import CommentRequest from '../../request/CommentRequest';
import {GET_COMMENTS,GET_COMMENT_TYPE_DIC
} from "../comment/actionType";
import {
    CREATE_COMMENT_TEMPLATES,
    DELETE_COMMENT_TEMPLATES,
    GET_COMMENT_TEMPLATES, GET_MAJIA_COMMENTS,
    UPDATE_COMMENT_TEMPLATES
} from "./actionType";
import {CREATE_BANNER} from "../banner/actionTypes";
import BannerRequest from "../../request/BannerRequest";



const getComments = (page, size, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId,vestComment,gameType) => (
    createAsyncAction(GET_COMMENTS, () => (CommentRequest.getComment(page, size, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId,vestComment,gameType)))
);
export const getCommentTypeDictionaries=()=>(createAsyncAction(GET_COMMENT_TYPE_DIC, () => (CommentRequest.getCommentTypeDictionaries())))


const getCommentTemplates = (page, size) => (
    createAsyncAction(GET_COMMENT_TEMPLATES, () => (CommentRequest.getCommentTemplates(page, size)))
);

const createCommentTemplate = (content) => (
    createAsyncAction(CREATE_COMMENT_TEMPLATES,() => (CommentRequest.createCommentTemplate(content)))
)

const updateCommentTemplate = (id,content) => (
    createAsyncAction(UPDATE_COMMENT_TEMPLATES,() => (CommentRequest.updateCommentTemplate(id,content)))
)

const deleteCommentTemplate = (id) => (
    createAsyncAction(DELETE_COMMENT_TEMPLATES,() => (CommentRequest.deleteCommentTemplate(id)))
)

const getMajiaComments = (page, size, dynamicId,userId) => (
    createAsyncAction(GET_MAJIA_COMMENTS, () => (CommentRequest.getMajiaComment(page, size, dynamicId,userId)))
);

export {
    getComments,getCommentTemplates,createCommentTemplate,updateCommentTemplate,deleteCommentTemplate,getMajiaComments
};
