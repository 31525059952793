import React from 'react';
import {Button, Form, Input, Select} from 'antd';
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from 'modules/authentication/constants';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameMaps, getVideoConfig} from "../../../modules/training/action";
import * as axios from "axios";



const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class VideoConfigModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            fileNames: [],
            videoIds: [],
            progressPercentList: [],
            uploadStatusList: []

        }
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmit, value} = this.props;
                let {videoIds, fileNames} = this.state;
                values["videoIds"] = videoIds
                values["fileNames"] = fileNames;
                console.log(values)
                onSubmit && onSubmit(value.id, values);
            }
        });
    }

    componentDidMount() {
        const {videoDataList, value} = this.props
        let array = [];
        for (let i = 0; i < value.gameNum; i++) {
            array[i] = i;
        }
        let fileNames = [];
        let videoIds = [];
        array.map((itemChild) => {
            let videoData = videoDataList.length > 0 ? (videoDataList.filter(video => video.gameNum == (itemChild + 1)).length > 0 ? videoDataList.filter(video => video.gameNum == (itemChild + 1))[0] : '') : '';
            fileNames[itemChild] = videoData ? videoData.fileNeam : null;
            videoIds[itemChild] = videoData ? videoData.url : null
            return null
        })

        this.setState({
            fileNames: fileNames,
            videoIds: videoIds
        })
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        const {progressPercentList, uploadStatusList, videoIds} = this.state;
        let {value, maps, videoDataList} = this.props;
        console.log(videoDataList)
        const {getFieldDecorator} = this.props.form;
        let array = [];
        for (let i = 0; i < value.gameNum; i++) {
            array[i] = i;
        }
        return (
            <div>

                <Form layout="horizontal">
                    <Form.Item
                        label="视频标题"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('videoTheme', {
                            initialValue: videoDataList.length > 0 ? videoDataList[0].videoTheme : value.gameTheme,
                            rules: [{required: true, message: '请输入视频标题!'}],
                        })(
                            <Input placeholder="请输入视频标题"/>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="地图类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('gameMap', {
                            initialValue: videoDataList.length > 0 ? maps.filter((item) => item.code == videoDataList[0].gameMap).length > 0 ? maps.filter((item) => item.code == videoDataList[0].gameMap)[0].code : maps ? maps[0].code : '' : maps ? maps[0].code : '',
                            rules: [{required: true, message: '请选择地图类型!'}],
                        })(
                            <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                                {maps.map((item) => <Select.Option value={item.code}
                                                                   key={item.code}>{item.mapName}</Select.Option>)}
                            </Select>,
                        )}
                    </Form.Item>

                    <Form.Item
                        label="视频格式"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('xxx', {})(
                            <p style={{color: 'red'}}>*仅支持mp4,m3u8,flv视频格式</p>
                        )}
                    </Form.Item>
                    {array.map((itemChild) => {
                            let videoData = videoDataList.length > 0 ? (videoDataList.filter(video => video.gameNum == (itemChild + 1)).length > 0 ? videoDataList.filter(video => video.gameNum == (itemChild + 1))[0] : '') : '';
                            return (
                                <Form.Item
                                    label={"BO" + (itemChild + 1)}
                                    {...formItemLayout}
                                >
                                    {getFieldDecorator('videoId_' + itemChild, {
                                        initialValue: ''
                                    })(
                                        <div>
                                            <Input placeholder="请配置videoId" onChange={(e)=>this.videoIdOnChange(itemChild, e.currentTarget.value)} value={videoIds[itemChild] ? videoIds[itemChild] : videoData.url}/>
                                            <input type="file" id="fileUpload" onChange={(e)=>this.fileChange(e, itemChild)} />
                                            <div><label className="status">上传状态: <span id="status">{uploadStatusList[itemChild] ? uploadStatusList[itemChild] : '无任务'}</span></label></div>
                                            <span className="progress">上传进度: <i id="sts-progress">{progressPercentList[itemChild] ? progressPercentList[itemChild] : '0'}</i> %</span>
                                        </div>
                                    )}
                                </Form.Item>
                                // <UploadVod  gameNum={itemChild + 1}  />
                            )
                        }
                    )
                    }
                    <Form.Item>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    videoIdOnChange(itemChild, value) {
        console.log(value)
        const {videoIds} = this.state
        videoIds[itemChild] = value
        this.setState({
            videoIds: videoIds
        })
    }

    fileChange = (e, id) => {
        console.log(e, id)
        let {uploader} = this.state;
        console.log(uploader)
        let file = e.target.files[0]
        if (!file) {
            alert("请先选择需要上传的文件!")
            return
        }
        let Title = file.name
        let userData = '{"Vod":{}}'
        if (uploader) {
            uploader.stopUpload()
        }
        uploader = this.createUploader(id)
        // 首先调用 uploader.addFile(event.target.files[i], null, null, null, userData)
        console.log(userData)
        console.log(uploader)
        uploader.addFile(file, null, null, null, userData)

        uploader.startUpload()
        const {fileNames} = this.state;
        fileNames[id] = Title
        this.setState({
            fileNames: fileNames
        })
    }


    getAuthorizationHeader = (headers) => {
        try {
            let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
            const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
            if (headers && accessToken) {
                headers.Authorization = accessToken.token;
            }
            headers.ContentType = 'application/json';
            return headers;
        } catch (error) {
            return '';
        }
    };

    createUploader = (id) => {
        let _this = this;
        let uploader = new window.AliyunUpload.Vod({
            timeout: 60000,
            //分片大小默认1M，不能小于100K
            partSize: 1048576,
            //并行上传分片个数，默认5
            parallel: 5,
            //网络原因失败时，重新上传次数，默认为3
            retryCount: 3,
            //网络原因失败时，重新上传间隔时间，默认为2秒
            retryDuration: 2,
            //是否上报上传日志到点播，默认为true
            enableUploadProgress: true,
            // region: null,
            userId: '123',

            // 添加文件成功
            addFileSuccess: function (uploadInfo) {
                console.log('addFileSuccess')
                console.log("addFileSuccess: " + uploadInfo.file.name)
            },
            // 开始上传
            onUploadstarted: function (uploadInfo) {
                axios({ // 用axios发送post请求
                    method: 'get',
                    url: '/api/admin/oss/aliyun/sts', // 请求地址
                    data: {}, // 参数
                    responseType: 'json', // 表明返回服务器返回的数据类型
                    headers: _this.getAuthorizationHeader({})
                }).then(info => {
                    console.log("==============", info)
                    var accessKeyId = info.data.accessKeyId
                    var accessKeySecret = info.data.accessKeySecret
                    var secretToken = info.data.securityToken
                    uploader.setSTSToken(uploadInfo, accessKeyId, accessKeySecret, secretToken)
                    console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                    const {progressPercentList, uploadStatusList} = _this.state;
                    uploadStatusList[id]= "视频开始上传..."
                    progressPercentList[id] = 0
                    _this.setState({
                        uploadStatusList: uploadStatusList,
                        progressPercentList: progressPercentList
                    })
                }).catch(e => {
                    console.log("================",e.response)
                })

                // if(!uploadInfo.videoId){
                //     axios({ // 用axios发送post请求
                //         method: 'get',
                //         url: '/api/admin/vod/aliyun/uploadauth-address?fileName=normal video.mp4', // 请求地址
                //         data: {}, // 参数
                //         responseType: 'json', // 表明返回服务器返回的数据类型
                //         headers: _this.getAuthorizationHeader({})
                //     }).then(info => {
                //         console.log("==============", info)
                //         var videoId = info.videoId
                //         var uploadAddress = info.uploadAddress
                //         var uploadAuth = info.uploadAuth
                //         uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
                //         console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                //
                //         _this.setState({
                //             uploadStatus: "视频开始上传...",
                //             progressPercent: 0
                //         })
                //     }).catch(e => {
                //         console.log("================",e.response)
                //     })
                // }else {
                //     axios({ // 用axios发送post请求
                //         method: 'get',
                //         url: '/api/admin/vod/aliyun/refresh/uploadauth-address?videoId='+uploadInfo.videoId, // 请求地址
                //         data: {}, // 参数
                //         responseType: 'json', // 表明返回服务器返回的数据类型
                //         headers: _this.getAuthorizationHeader({})
                //     }).then(info => {
                //         console.log("==============", info)
                //         var videoId = info.videoId
                //         var uploadAddress = info.uploadAddress
                //         var uploadAuth = info.uploadAuth
                //         uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
                //         console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                //
                //         _this.setState({
                //             uploadStatus: "视频开始上传...",
                //             progressPercent: 0
                //         })
                //     }).catch(e => {
                //         console.log("================",e.response)
                //     })
                // }
            },
            // 文件上传成功
            onUploadSucceed: function (uploadInfo) {
                console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                console.log("====================",uploadInfo)
                const {uploadStatusList, videoIds} = _this.state;
                uploadStatusList[id]= "文件上传成功!"
                videoIds[id] = uploadInfo.videoId
                _this.setState({
                    uploadStatusList: uploadStatusList,
                    videoIds: videoIds
                })
            },
            // 文件上传失败
            onUploadFailed: function (uploadInfo, code, message) {
                console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                const {uploadStatusList} = _this.state;
                uploadStatusList[id]= "文件上传失败!"
                _this.setState({
                    uploadStatusList: uploadStatusList
                })
            },
            // 取消文件上传
            onUploadCanceled: function (uploadInfo, code, message) {
                console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                // $('#status').text('文件上传已暂停!')
            },
            // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
            onUploadProgress: function (uploadInfo, totalSize, progress) {
                console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                var progressPercent = Math.ceil(progress * 100)
                const {progressPercentList, uploadStatusList} = _this.state;
                progressPercentList[id]=progressPercent;
                uploadStatusList[id]= "文件上传中..."
                _this.setState({
                    progressPercentList: progressPercentList,
                    uploadStatusList: uploadStatusList
                })
            },
            // 上传凭证超时
            onUploadTokenExpired: function (uploadInfo) {
                // axios({ // 用axios发送post请求
                //     method: 'get',
                //     url: '/api/admin/oss/aliyun/sts', // 请求地址
                //     data: {}, // 参数
                //     responseType: 'json', // 表明返回服务器返回的数据类型
                //     headers: _this.getAuthorizationHeader({})
                // }).then(info => {
                //     console.log("==============", info)
                //     var accessKeyId = info.data.accessKeyId
                //     var accessKeySecret = info.data.accessKeySecret
                //     var secretToken = info.data.securityToken
                //     var expiration = info.data.Expiration
                //     uploader.resumeUploadWithSTSToken(accessKeyId, accessKeySecret, secretToken, expiration)
                //     console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                // }).catch(e => {
                //     console.log("================",e.response)
                // })

                axios({ // 用axios发送post请求
                    method: 'get',
                    url: '/api/admin/vod/aliyun/refresh/uploadauth-address?videoId='+uploadInfo.videoId, // 请求地址
                    data: {}, // 参数
                    responseType: 'json', // 表明返回服务器返回的数据类型
                    headers: _this.getAuthorizationHeader({})
                }).then(info => {
                    console.log("==============", info)
                    var uploadAuth = info.uploadAuth
                    uploader.resumeUploadWithAuth(uploadAuth)
                    console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                    const {uploadStatusList} = _this.state;
                    uploadStatusList[id]= "视频上传超时!"
                    _this.setState({
                        uploadStatusList: uploadStatusList
                    })
                }).catch(e => {
                    console.log("================",e.response)
                })
            },
            // 全部文件上传结束
            onUploadEnd: function (uploadInfo) {

                const {uploadStatusList} = _this.state;
                uploadStatusList[id]= "文件上传完毕!"
                _this.setState({
                    uploadStatusList: uploadStatusList
                })
            }
        })
        return uploader
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getGameList,
    getVideoConfig,
    getGameMaps,
});
VideoConfigModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(VideoConfigModal)));