import React from 'react';
import {Form, Input, Modal,DatePicker,Select, Button,notification,Icon } from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import Map from "./Map"
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

//预测
class PredictionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowMap:false,
            predictionList:[{key:0,value:''},{key:1,value:''}]
        };
    }

    componentDidMount() {
        if(this.props.data != null && this.props.data.itemList != null) {
            let items = [];
            let itemList = this.props.data.itemList;
            for(let i=0; i<itemList.length; i++) {
                items.push({key:i,value:itemList[i].content});
            }
            this.setState({predictionList:items});
        }
    }

    onSubmit = () => {
        let {predictionList} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const diff1 = moment(values.endTime).diff(moment(values.startTime))
                if(diff1<0){
                    notification.error({message:'结束时间不能早于开始时间，请重新选择时间'})
                    return;
                }
                let val = {
                    ...values,
                    startTime:values.startTime?moment(values.startTime).format('YYYY-MM-DD HH:mm:00'):'',
                    endTime:values.endTime?moment(values.endTime).format('YYYY-MM-DD HH:mm:00'):'',
                  }

                let items = [];
                for(let i=0; i<predictionList.length; i++) {
                    items.push(predictionList[i].value);
                }
                val.items = items;
                onSuccess && onSuccess(val,this.props.type);
            }
        });
    }
    onChange(value, dateString) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,type,data,gameList} = this.props
        let{isSignIn,isShowMap,predictionList} = this.state;
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title="赛事预测"
                onCancel={this.props.onClose}
                // cancelText="取消"
                // onOk={this.onSubmit}
                // okText={"确定"}
                footer={
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]

            }
            >
                <Form>
                    <FormItem
                        label="预测标题"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('guessTitle', {
                            rules: [{max: 50, message: '预测标题不得超过50个字符!'},{required: true, message: '请输入预测标题'}],
                            initialValue:type!=='ADD'?data.guessTitle:''
                        })(
                            <Input placeholder="请输入预测标题"/>
                        )}
                    </FormItem>

                    <FormItem
                        label="预测选项"
                        {...formItemLayout}

                    >
                    {
                        predictionList.map((val,index)=>{
                            return(
                                <Input onInput={(event)=>{
                                    var input = event.target;
                                    var value = input.value;
                                    val.value = value;
                                    this.setState({predictionList:predictionList})
                                }} style={{width:300}} placeholder="请输入预测选项内容" value={val.value}/>
                            )
                        })
                    }
                    </FormItem>
                    <Button type={"primary" }style={{marginLeft: 100, marginBottom: 20}} onClick={() => {
                        let {predictionList} = this.state;
                        predictionList.push({key:predictionList.length+1,value:''});
                        this.setState({predictionList:predictionList});
                    }} color="blue">添加选项</Button>

                    <FormItem
                        label="消耗油桶"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('oilNum', {
                            rules: [{required: true, message: '请输入消耗油桶'}],
                            initialValue:type!=='ADD'?data.oilNum:''
                        })(
                            <Input placeholder="请输入消耗油桶"/>
                        )}
                    </FormItem>

                    {/*<FormItem label="比赛结果:"
                              {...formItemLayout}

                    >
                        {getFieldDecorator('result', {
                            initialValue:type!=='ADD'?data.result:'',
                            rules: [{required: true, message: '请选择比赛结果'}],
                        })(
                            <Select style={{width: 120}}>
                                {
                                    predictionList.map((val,index)=>{
                                        return(
                                            <Option value={val.key} key={val+index}>{val.value}</Option>
                                        )
                                    })
                                }
                            </Select>,
                        )}
                    </FormItem>*/}

                    <FormItem
                        label="奖励油桶"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('rewardOilNum', {
                            rules: [{required: true, message: '请输入奖励油桶'}],
                            initialValue:type!=='ADD'?data.rewardOilNum:''
                        })(
                            <Input placeholder="请输入奖励油桶"/>
                        )}
                    </FormItem>

                    <FormItem label="预测开始时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('startTime', {
                                rules: [{required: true, message: '请输入预测开始时间'}],
                                initialValue:type!=='ADD'?moment(data.startTime,dateFormat):moment()

                            }
                        )(
                                <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />

                        )}
                    </FormItem>
                    <FormItem label="预测结束时间"
                        {...formItemLayout}
                        >
                        {getFieldDecorator('endTime', {
                                rules: [{required: true, message: '请输入预测结束时间'}],
                                initialValue:type!=='ADD'?moment(data.endTime,dateFormat):moment()
                            }
                        )(
                            <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm:00"
                                        />
                        )}
                    </FormItem>





                </Form>

            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
PredictionModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PredictionModal)));
