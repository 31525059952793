import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Row, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getAppNotices
} from "../../modules/appNotice/action";
import AppNoticeFilter from "./AppNoticeFilter";
import {sendStatus} from "../../modules/msgNotice/enums";
import AppNoticePushModal from "./AppNoticePushModal";

class AppNoticeManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            receiverAccid: null,
            exhibitionId: null,
            noticeGroupType: null,
            sendStatus: null,
            pushModalVisible: false
        };
    }

    componentDidMount() {
        const {pageNo, pageSize,receiverAccid, exhibitionId, noticeGroupType,sendStatus} = this.state;
        let {getAppNotices} = this.props;
        getAppNotices(receiverAccid, exhibitionId, noticeGroupType,sendStatus, pageNo, pageSize);
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getAppNotices} = this.props;
        let {receiverAccid, exhibitionId, noticeGroupType, sendStatus} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getAppNotices(receiverAccid, exhibitionId, noticeGroupType,sendStatus, current, pageSize);
    }

    onFilterAppNoticeEvent = (receiverAccid, exhibitionId, noticeGroupType, sendStatus) => {
        const {getAppNotices} = this.props;
        const {pageSize} = this.state;
        this.setState({
            pageNo: 1,
            receiverAccid: receiverAccid,
            exhibitionId: exhibitionId,
            noticeGroupType: noticeGroupType,
            sendStatus
        })
        getAppNotices(receiverAccid, exhibitionId, noticeGroupType, sendStatus,1, pageSize)
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => {this.setState({
                    pushModalVisible: true
                })}}>推送应用通知</Button>
            </Col>
        </Row>
    )

    render() {
        const {
            appNotices, loading
        } = this.props;
        const {
            pageNo, pageSize, pushModalVisible
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <AppNoticeFilter dataSource={{}} onCommit={this.onFilterAppNoticeEvent}></AppNoticeFilter>
                </Card>
                <Card>
                    <Table

                        title={this.state.state === 'true' ? this.renderTableTitle : null}
                        columns={
                            [
                                {
                                    title: '用户ID',
                                    dataIndex: 'receiverAccid',
                                    key: 'receiverAccid',
                                    align: 'center',
                                },{
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '通知分组',
                                    dataIndex: 'noticeGroupTypeStr',
                                    key: 'noticeGroupTypeStr',
                                    align: 'center',
                                    width:'100px',
                                },
                                {
                                    title: '通知类型',
                                    key: 'applicationNoticeContentType',
                                    dataIndex: 'applicationNoticeContentType',
                                    align: 'center',
                                    width:'100px',
                                    render: (applicationNoticeContentType) => {
                                        switch (applicationNoticeContentType) {
                                            case 'NORMAL':
                                                return <div>普通消息</div>;
                                            case 'CARD':
                                                return <div>卡片消息</div>;
                                            default:
                                                return null;
                                        }
                                    }
                                },
                                {
                                    title: '内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                },
                                {
                                    title: "发送时间",
                                    dataIndex: 'sendTime',
                                    key: 'sendTime',
                                    align: 'center'
                                },
                                {
                                    title: "发送状态",
                                    dataIndex: 'sendStatus',
                                    key: 'sendStatus',
                                    align: 'center',
                                    render:(text) => (text ? sendStatus[text].label : null)
                                },
                                {
                                    title: "失败原因",
                                    dataIndex: 'failMsg',
                                    key: 'failMsg',
                                    align: 'center',
                                }
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={appNotices.data ? appNotices.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: appNotices ? appNotices.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                {pushModalVisible ? <AppNoticePushModal visible={pushModalVisible} onClose={() => {
                    this.setState({pushModalVisible: false})
                }} onSuccess={() => {
                    const {pageSize,receiverAccid, exhibitionId, noticeGroupType,sendStatus} = this.state;
                    let {getAppNotices} = this.props;
                    getAppNotices(receiverAccid, exhibitionId, noticeGroupType,sendStatus, 1, pageSize);
                    this.setState({pushModalVisible: false, pageNo: 1})
                }} /> : null}
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.AppNotice)
        return {
            ...state.AppNotice
        }

    }

);

const mapDispatchToProps = {
    getAppNotices
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(AppNoticeManagement)));
