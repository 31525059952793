import React from 'react';
import {Form, Input, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class SetListingWeightModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props
        return (
            <Modal
                visible={visible}
                title="配置推荐权重"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form layout="inline">
                    <FormItem
                        label="自荐推荐权重:"
                    >
                        {getFieldDecorator('priority', {
                            rules: [{pattern: /^\d*$/, message: '请输入大于等于0的整数!'},{required: true, message: '请输入自荐推荐权重'}],
                        })(
                            <Input placeholder="请输入自荐推荐权重"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
SetListingWeightModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SetListingWeightModal)));
