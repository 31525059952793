import React, {Component} from "react";
import {Button, Form, Input} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;

class SmallPlanFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                console.log(values)
                let {onCommit} = _self.props;
                let {name} = values;

                onCommit && onCommit(name);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline">
                    <FormItem label="方案">
                        {getFieldDecorator('name', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>
                </Form>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SmallPlanFilter)));