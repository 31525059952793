import createAsyncAction from 'utils/createAsyncAction';
import LiveRequest from '../../request/LiveRequest' ;
import {
    GET_LIVE_DATA,
    GET_LIVE_DETAIL,
    CREATE_LIVE,
    UPDATE_LIVE,
    DELETE_LIVE
} from './actionTypes'

// 查询直播配置列表
const getLiveData= (param) => (
    createAsyncAction(GET_LIVE_DATA,() => (LiveRequest.getLiveData(param)))
)
// 查询直播配置详情
const getLiveDetail = (id) =>(
    createAsyncAction(GET_LIVE_DETAIL,() => (LiveRequest.getLiveDetail(id)))
)
// 新增直播配置
const addLive = (param) =>(
    createAsyncAction(CREATE_LIVE,() => (LiveRequest.addLive(param)))
)
// 编辑直播配置
const updateLive = (param) =>(
    createAsyncAction(UPDATE_LIVE,() => (LiveRequest.updateLive(param)))
)
// 删除直播
const deleteLive = (id) =>(
    createAsyncAction(DELETE_LIVE,() => (LiveRequest.deleteLive(id)))
)

export {
    getLiveData,
    getLiveDetail,
    addLive,
    updateLive,
    deleteLive
}
