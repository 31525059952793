import React from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import {getBrand, getBrandAll} from "../../../modules/training/action";
const {confirm} = Modal;
const {TextArea} = Input;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class ForbidTeamModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
        let {getGameList,getBrandAll} = this.props;
        getGameList();
        getBrandAll({page: 1, size: 100000});
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                confirm({
                    content: '确认要禁赛吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        onSuccess && onSuccess(values)
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, brandData,  games} = this.props
        return (
            <Modal
                visible={visible}
                title="禁赛"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="禁赛天数"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('days', {
                            initialValue: 3
                        })(
                            <InputNumber min={1} max={365} />
                        )}<span className="ant-form-text">天</span>
                    </FormItem>
                    <FormItem label="封禁赛事类型"  {...formItemLayout}>
                        {getFieldDecorator('bannedBrands', {
                                initialValue: [],
                            }
                        )(
                            <Select mode="multiple" placeholder="不填则默认为禁赛全部赛事">
                                {brandData.data && brandData.data.map(item => {
                                    return (
                                        <Select.Option
                                            value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>
                    {/*<Form.Item label="角色" {...formItemLayout}>*/}
                        {/*{getFieldDecorator('roleCodes', {*/}
                            {/*initialValue: adminRoles ? adminRoles.map(({ code }) => (code)) : [],*/}
                            {/*rules: [*/}
                                {/*{*/}
                                    {/*required: true, message: '角色不能为空',*/}
                                {/*},*/}
                            {/*],*/}
                        {/*})(*/}
                            {/*<Select mode="multiple">*/}
                                {/*{roles.data.map(({ code, name }) => <Option key={code} value={code} >{name}</Option>)}*/}
                            {/*</Select>,*/}
                        {/*)}*/}
                    {/*</Form.Item>*/}
                    <FormItem
                        label="禁赛备注"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('remark', {
                            initialValue: "",
                            rules: [{required: true, message:"请填写禁赛原因"},{maxLength: 50, message: '封禁备注不能超过50个字符'}]
                        })(
                            <TextArea placeholder="封禁备注不能超过50个字符" maxLength={50} />,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
    getBrandAll,
});
ForbidTeamModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(ForbidTeamModel)));
