import api from '../utils/api';


class LotteryRequest {


    /**
     * 获取奖品列表
     * @param pageNo
     * @param pageSize
     * @param topicName
     * @returns {*}
     */
    static getPrizes() {
        return api.get('/admin/lottery');
    }

    /**
     * 修改奖品
     * @param params 请求参数
     */
    static updatePrize(prizeId,params) {
        return api.put(`/admin/lottery`, {prizeId, ...params} );
    }

    /**
     * 查看中奖记录列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
     static getLotteryRecord(page, size, username, turnTablePrizeType, shippingStatus) {
        return api.get('/admin/lottery/record', { page, size, username, turnTablePrizeType, shippingStatus });
    }


    /**
     * 查看收货地址
     * @param awardRewardId
     * @returns {*}
     */
    static getLotteryHarvest(awardRewardId) {
        return api.get(`/admin/lottery/harvest/${awardRewardId}`);
    }


    /**
     * 奖品订单发货
     * @param {Object} params 请求参数
     */
      static updatePrizeOrderShip(recordId, params) {
        return api.put(`/admin/lottery/send`, {recordId, ...params});
    }
}

export default LotteryRequest;
