import React, {Component} from 'react';
import {
    Form, Input, Button, Select,
} from 'antd';
import BraftEditor from '../../components/braftEditor'
import {is, fromJS} from 'immutable'
import UpLoad from '../../components/upload'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class ArticleForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            article:props.article ||'',
            type: props.type
        };
      }
    
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.detail = this.state.article
                let {onSubmitEvent} = this.props;
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }
    componentWillMount() {
        if (this.props.articleInfo!=null){
            this.setState({
                article : this.props.articleInfo.detail
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props), fromJS(nextProps))) {
            this.props.form.resetFields();
        }
    }

    render() {
        let {
            author,
            imageUrl,
            remark,

            title,
            enable,
            top,
            tag,
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        const {article,type} = this.state;


        return (
            <Form>
                <FormItem
                    label="标题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('title', {
                        rules: [{required: true, message: '请输入标题', pattern: /^\S+$/}],
                        initialValue: title,
                    })(
                        <Input placeholder="请输入标题"/>,
                    )}
                </FormItem>
                <FormItem
                    label="作者"
                    {...formItemLayout}
                >
                    {getFieldDecorator('author', {
                        initialValue: author,
                    })(
                        <Input placeholder="请输入作者(选填)"/>,
                    )}
                </FormItem>



                <FormItem
                    label="类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('type', {
                        rules: [{required: true, message: '请选择文章类型', pattern: /^\S+$/}],
                        initialValue: type,
                    })(
                        (
                            <Select style={{width: '150px'}} onChange={e => {
                                console.log(e)
                                this.setState({type: e})
                            }}>
                                <Option value='BULLETIN'>官方公告</Option>
                                <Option value='SELECTED'>本周精选</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                {
                    this.state.type == 'SELECTED' ? <FormItem
                        label="图片(230*164)"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: imageUrl ? imageUrl+'?x-oss-process=image/resize,w_86' : '',
                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem> : this.state.type == 'BULLETIN' ? <FormItem
                        label="图片(355*177)"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: imageUrl ? imageUrl+'?x-oss-process=image/resize,w_86' : '',
                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {355}
                                    // height = {177}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem> : null
                }

                <FormItem
                    label="内容(图片尺寸建议690*354)"
                    {...formItemLayout}
                >
                    <BraftEditor value={article} onChange={(value) => {
                        this.state.article = value
                         // this.setState({
                        //     article: value
                        // })
                    }}
                                 // width = {690}
                                 // height = {354}
                        size={1111}

                    />
                </FormItem>
                <FormItem
                    label="标签"
                    {...formItemLayout}
                >
                    {getFieldDecorator('tag', {
                        initialValue: tag,
                    })(
                        <Input maxLength={"2"} placeholder={"请输入标签（最多2字）"}/>,
                    )}
                </FormItem>

                <FormItem
                    label="备注"
                    {...formItemLayout}
                >
                    {getFieldDecorator('remark', {
                        initialValue: remark,
                    })(
                        <Input placeholder="请输入备注（选填）"/>,
                    )}
                </FormItem>

                <FormItem
                    label="是否置顶"
                    {...formItemLayout}
                >
                    {getFieldDecorator('top', {
                        rules: [{required: true, message: '请选择是否置顶', pattern: /^\S+$/}],
                        initialValue: top,
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value='TOP'>是</Option>
                                <Option value='NOT_TOP'>否</Option>
                            </Select>
                        ),
                    )}
                </FormItem>

                <FormItem
                    label="是否启用"
                    {...formItemLayout}
                >
                    {getFieldDecorator('enable', {
                        initialValue: enable,
                        rules: [{required: true,message:'请选择是否启用'}],
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}


export default Form.create()(ArticleForm);
