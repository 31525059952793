import api from '../utils/api';


class CommemorativeCardRequest {

    /**
     * 获取纪念卡列表
     * @param page
     * @param size
     * @param giftType
     * @returns {*}
     */
    static getCardList(params) {
        return api.get('/admin/commemorativeCard/listTheme', params);
    }

    /**
     * 编辑纪念卡图片列表
     * @returns {*}
     */
     static onUpdateCardPic(themeId,params) {
        return api.put(`/admin/commemorativeCard/updateCard/${themeId}`, params);
    }

    /**
     * 获取纪念卡图片列表
     * @returns {*}
     */
     static getCardPicList(themeId) {
        return api.get(`/admin/commemorativeCard/cardList/${themeId}`);
    }

    /**
     * 根据ID删除纪念卡主题抽奖设置
     * @returns {*}
     */
     static deleteThemeSetting(themeSettingId) {
        return api.delete(`/admin/commemorativeCard/deleteThemeSetting/${themeSettingId}`);
    } 
    
    /**
     * 上架/下架纪念卡主题
     * @returns {*}
     */
     static onAndOffTheShelfTheme(themeId,listingStatus) {
        return api.put(`/admin/commemorativeCard/onAndOffTheShelfTheme/${themeId}/${listingStatus}`);
    }    
    
    /**
     * 添加纪念卡
     * @param {Object} params 请求参数
     */
     static createCard(params) {
        return api.post('/admin/commemorativeCard/addTheme', params);
    }

    /**
     * 编辑纪念卡主题抽奖设置
     * @param {Object} params 请求参数
     */
    static updateThemeSetting(themeId,params) {
        return api.put(`/admin/commemorativeCard/updateThemeSetting/${themeId}`, params);
    }
    
    /**
     * 编辑纪念卡主题
     * @param {Object} params 请求参数
     */
    static updateCommemorativeCard(themeId, params) {
        return api.put(`/admin/commemorativeCard/updateTheme/${themeId}`, params);
    }

    /**
     * 删除纪念卡
     * @param themeId
     * @returns {*}
     */
     static deleteCard(themeId) {
        return api.delete(`/admin/gift/${themeId}`);
    }

    /**
     * 查看纪念卡主题抽奖设置
     * @param themeId
     * @returns {*}
     */
    static getThemeSettingList(themeId) {
        return api.get(`/admin/commemorativeCard/themeSettingList/${themeId}`);
    }

    /**
     * 分页条件查看礼品订单列表
     * @param page
     * @param size
     * @param giftName
     * @param userId
     * @param giftType
     * @returns {*}
     */
    static getGiftOrderList(params) {
        return api.get('/admin/gift/order/list', params);
    }

    /**
     * 编辑礼品订单收货地址
     * @param {Object} params 请求参数
     */
     static updateGiftOrderAddress(orderId, params) {
        return api.put(`/admin/gift/order/editShippingAddress/${orderId}`, {orderId, ...params});
    }    
}

export default CommemorativeCardRequest;
