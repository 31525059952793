import createReducer from 'utils/createReducer';

import {
    GET_COMMEMORATIVE_CARD_LIST,
    GET_THEME_SETTING_LIST,
    GET_CARD_PIC_LIST
} from './actionTypes';

const defaultState = () => ({
    commemorativeCard: [],
    themeSettingList: null,
    cardPicList: null,
});

const onGetCommemorativeCardListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            commemorativeCard: payload
        }

};

const onGetThemeSettingListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            themeSettingList: payload
        }

};

const onGetCardPicListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            cardPicList: payload
        }

};



export default createReducer(defaultState, {
    [`${GET_COMMEMORATIVE_CARD_LIST}_SUCCESS`]: onGetCommemorativeCardListRequest,
    [`${GET_THEME_SETTING_LIST}_SUCCESS`]: onGetThemeSettingListRequest,
    [`${GET_CARD_PIC_LIST}_SUCCESS`]: onGetCardPicListRequest,
});
