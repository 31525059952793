import React from 'react';
import {Form, Input, Modal,DatePicker,Select, notification,Image } from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import {Map, Marker, AutoComplete} from 'react-bmapgl';
import jsonp from "jsonp";
import {message} from 'antd';
import './Map.scss';

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class ActivityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            lng:this.props.lng || 116.404449, // 经度
            lat:this.props.lat || 39.914889, // 纬度
            zoom:14,
        };
    }
    componentDidMount(){}
    
    onChange(value, dateString) {}


    onOk(value) {}

    onSubmit = ()=>{
        const {onSuccess} = this.props;
        onSuccess && onSuccess(this.state.lng, this.state.lat);
    }

    //地址正解析 (jsonp 否则调用百度api产生跨域问题)
    reqWeather = (address)=>{
        const ak = 'OyE9xrZzGmDGD7IG7lj9zomybcqchtgG';
        return new Promise((resolve,reject)=>{
            const url = `https://api.map.baidu.com/geocoding/v3/?address=${address}&output=json&ak=${ak}&callback=showLocation`;
            jsonp(url,{},(err,data)=>{
                if(data.status==0){
                    //请求成功，拿到数据
                    let lng = data.result.location.lng;
                    let lat = data.result.location.lat;
                    this.setState({ lng, lat , zoom: 16 }); //将地址解析结果显示在地图上
                }else{
                    //请求失败
                    message.error("获取地址信息失败!")
                }
            })
        })
    }

    
    render() {
        let {visible} = this.props
        let{lng,lat,zoom} = this.state;
        return (
            <Modal
                width={'50%'}
                visible={visible}
                title="设置活动地点经纬度"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Map
                    style={{height: 400}}
                    center={new window.BMapGL.Point(lng,lat)}
                    zoom={zoom}
                    tilt={40}
                    isLoaded
                    onClick={(e) => {
                        console.log(e)
                        // alert('点击的经纬度：' + e.latlng.lng + ', ' + e.latlng.lat);
                        this.setState({ lng:e.latlng.lng, lat:e.latlng.lat , zoom: 16 }); //将地址解析结果显示在地图上
                    }}
                    enableScrollWheelZoom
                >
                     <Marker
                        position={new window.BMapGL.Point(lng,lat)}
                        enableDragging
                    />
                    <div style={{position:"absolute",top:55,left:24,}}>
                        活动地点:
                        &nbsp;
                        <AutoComplete
                        onConfirm={(e) => {
                            console.log("回车选中某条记录后触发回调函数e",e)
                            let address = e.target._options.input.value;
                            this.reqWeather(address);
                        }}
                        style={{width:300,margin:"0 20 0 20"}}
                        />
                        &nbsp;
                        当前经纬度:
                        {lng},{lat}
                    </div>
                </Map>

                
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
ActivityModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(ActivityModal)));
