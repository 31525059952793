import React from 'react';
import {Button, Form, Input, message, Modal, Switch, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {sendAppNotices} from "../../modules/appNotice/action";
import api from "../../utils/api";

const FormItem = Form.Item;
const Option = Select.Option;

const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 15},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const {confirm} = Modal;

class AppNoticePushModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pushContent: '',
            page: 1,
            size: 10,
            sendAll: false,

            jumpType: 'INNER', 
            outerUrl: '',
            jumpOutside: false,
            innerJumpType: '',
            jumpDescription: '', 
            attach: '',
            isOuter: false,
            innerJumpTypeList: [],
        };
    }

    componentWillMount(){
        this.requestJumpType().then(json => {
            if(json && json.length > 0) {
                this.setState({
                    innerJumpTypeList: json
                }, this.changeDescription);
            } else {
                this.setState({
                    innerJumpTypeList: [],
                    jumpDescription: ''
                });
            }
        }).catch(err => {
            this.setState({
                innerJumpTypeList: [],
                jumpDescription: ''
            });
        });
    }

    requestJumpType = () => {
        return api.get(`/admins/banners/inner_jump_type`);
    }

    onChangeJumpType = (value) => {
        this.setState({
            isOuter: value === 'OUTER' ? true : false
        });
    }

    onChangeJumpOutside = (value) => {
        this.setState({
            jumpOutside: value
        });
    }
    
    changeDescription = (value) => {
        let { innerJumpTypeList, innerJumpType } = this.state;
        value = value || (innerJumpType || innerJumpTypeList[0].value);
        let des = "";
        innerJumpTypeList.map((item) => {
            if (item.value === value) {
                des = item.description;
            }
        });
        this.setState({
            jumpDescription: des,
            innerJumpType: value
        });
    }

    doNotice = async (values) => {
        const {sendAppNotices} = this.props;
        await sendAppNotices(values)
        message.info('消息推送成功')

        const {onSuccess} = this.props;
        await onSuccess && onSuccess(values);
    }

    onSubmit = () => {
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // eslint-disable-next-line no-unused-expressions
                confirm({
                    content: '确认要推送消息吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.doNotice(values);
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    onChange = (checked) => {
        this.setState({
            sendAll: checked
        })
    }


    render() {
        let { visible } = this.props;
        let { sendAll, jumpType, outerUrl, attach, isOuter, jumpOutside, innerJumpTypeList, innerJumpType, jumpDescription } = this.state;
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                visible={visible}
                title="推送"
                width={'30%'}
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="推送内容"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('noticeContent', {
                            rules: [{required: true, message: '请输入要推送的内容'}],
                        })(
                            <TextArea placeholder="请输入要推送的内容" row={3}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="链接类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('jumpType', {
                            rules: [{required: true, message: '请选择链接类型', pattern: /^\S+$/}],
                            initialValue: jumpType || 'INNER'
                        })(
                            (
                                <Select style={{width: '150px'}} onChange={this.onChangeJumpType}>
                                    <Option value='INNER'>内链</Option>
                                    <Option value='OUTER'>外链</Option>
                                </Select>
                            ),
                        )}
                    </FormItem>
                    <FormItem
                        label="外链链接"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('outerUrl', {
                            rules: [{required: isOuter, message: '请输入外链'}],
                            initialValue: outerUrl,
                        })(
                            <Input disabled={!isOuter} placeholder="请输入外链"/>,
                        )}
                    </FormItem>
                    {/* <FormItem
                        label="外链跳外部"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('jumpOutside', {
                            rules: [{required: isOuter, message: '请选择是否外链跳外部'}],
                            initialValue: jumpOutside,
                        })(
                            <Select style={{width: '150px'}} onChange={this.onChangeJumpOutside} disabled={!isOuter}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        )}
                    </FormItem> */}
                    <FormItem
                        label="内链跳转类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('innerJumpType', {
                            rules: [{required: !isOuter, message: '请选择内链跳转类型'}],
                            initialValue: innerJumpType
                        })(
                            (
                                <Select style={{width: '300px'}} disabled={isOuter} onChange={this.changeDescription}>
                                    {innerJumpTypeList.length > 0 && innerJumpTypeList.map((item, index) => {
                                        return (
                                            <Option key={item.name} value={item.value}>{item.name}</Option>
                                        )
                                    })
                                    }
                                </Select>
                            )
                        )}
                    </FormItem>
                    <FormItem
                        label="说明"
                        {...formItemLayout}>
                        {jumpDescription}
                    </FormItem>
                    <FormItem
                        label="Attach"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('attach', {
                            rules: [{required: !isOuter, message: '请输入Attach'}],
                            initialValue: attach,
                        })(
                            <TextArea row={3} disabled={isOuter}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="通知所有用户"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('all', {})(
                            <Switch onChange={this.onChange}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="用户展示ID列表"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('exhibitionIds', {
                            rules: [
                                {
                                    pattern: /^(\d+,)*\d+$/,
                                    message: '格式不正确'
                                }],
                        })(
                            <TextArea row={3} disabled={sendAll} placeholder="请输入用户展示ID列表,以英文逗号分隔"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};
const mapDispatchToProps = ({
    sendAppNotices
});
AppNoticePushModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AppNoticePushModal)));
