
export const sendStatus = {
  SUCCESS: {
    label: '成功',
    value:'SUCCESS',
  },
  FAIL: {
    label: '失败',
    value:'FAIL'
  },
};

