import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import EmojiForm from './EmojiForm'
import EmojiFilter from "./EmojiFilter";
import {operationTypeValue} from "../../modules/topic/topicEnum";
import { getEmojiPostList, getEmojiPostDetail, updateEmojiPost, updateEmojiPostSetting } from '../../modules/topic/action';
import {map} from 'lodash/fp';

class EmojiManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.UPDATE, // 默认操作类型为编辑
            pageNo: 1,
            pageSize: 10,
            isShowEditAdminModal: false,
            emojiId: null,
            startTime: null,
            endTime: null
        };
    }

    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getEmojiPostList} = this.props;        
        getEmojiPostList(pageNo, pageSize);
    }

    // 编辑（或查看）帖子
    onOpenPostModalEvent = (text, emojiId, type) => {
        const { getEmojiPostDetail } = this.props;

        getEmojiPostDetail(emojiId);
        this.setState({
            isShowEditAdminModal: true,
            operationType: type,
            emojiId
        });
    }

    onClosePostModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    // 编辑帖子信息
    onEditPostEvent = ({emojiUrl}) => {
        const {emojiId, pageNo, pageSize, userId, username, playerName, block, startTime, endTime} = this.state;
        const { getEmojiPostList, updateEmojiPost } = this.props;
        try {
            updateEmojiPost(emojiId, {
                emojiUrl: emojiUrl || ''
            }).then(json => {
                notification.success({message: '更新成功'});
                getEmojiPostList(pageNo, pageSize, userId, username, playerName, block, startTime, endTime);
                this.setState({
                    isShowEditAdminModal: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });           
        } catch (e) {
            console.log(e);
        }
    }     

    onPageChangeEvent = (current, pageSize) => {
        let { getEmojiPostList } = this.props;
        let { userId, username, playerName, block, startTime, endTime } = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getEmojiPostList(current, pageSize, userId, username, playerName, block, startTime, endTime);
    }

    //筛选帖子
    onFilterPostEvent = (userId, username, playerName, block, dateRange) => {
        const { getEmojiPostList } = this.props;
        const { pageSize } = this.state;
        let startTime = null, endTime = null;
        // if (dateRange !== undefined) {
        if (dateRange && dateRange.length > 0) {            
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0]+" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1]+" 23:59:59";
        }
        this.setState({
            userId, username, playerName, block, startTime, endTime
        }, () =>{
            getEmojiPostList(1, pageSize, userId, username, playerName, block, startTime, endTime);
        });
    }

    // 管理帖子
    onSetPostEvent = (fieldName, value, id) => {
        const { getEmojiPostList, updateEmojiPostSetting } = this.props;
        const {pageNo, pageSize, userId, username, playerName, block, startTime, endTime} = this.state;
        let type = '';
        switch(fieldName) {
            case 'lock':
                type = value ? 'unlock' : 'lock';
                break;
            case 'top':
                type = value ? 'unsetAsTop' : 'setAsTop';
                break;
            case 'block':
                type = value ? 'unblock' : 'block';
                break;
        }
        try {
            updateEmojiPostSetting(id, type, ).then(json => {
                console.log("setPostResult: ", json);
                getEmojiPostList(pageNo, pageSize, userId, username, playerName, block, startTime, endTime);
                notification.success({message: '操作成功'});
            }).catch(err => {
                console.log("setPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        console.log(this.props.images)
        const {
            emojiPostData, loading, emojiPostList
        } = this.props;
        const {
            isShowEditAdminModal, pageNo, pageSize, operationType
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <EmojiFilter dataSource={{}} onCommit={this.onFilterPostEvent}></EmojiFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '帖子ID',
                                    dataIndex: 'emojiId',
                                    key: 'emojiId',
                                    align: 'center',
                                },
                                {
                                    title: '发帖用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                },
                                {
                                    title: '发帖用户名',
                                    dataIndex: 'username',
                                    key: 'username',
                                    align: 'center',
                                },
                                // {
                                //     title: '帖子标题',
                                //     dataIndex: 'content',
                                //     key: 'content',
                                //     align: 'center',
                                // },
                                {
                                    title: '表情包内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenPostModalEvent(text, text.emojiId, operationTypeValue.SELECT)}
                                                    color="blue">
                                                    查看
                                                </Button>
                                    }
                                },                                                                                                                            
                                {
                                    title: '评论数',
                                    dataIndex: 'comments',
                                    key: 'comments',
                                    align: 'center',
                                },
                                {
                                    title: '点赞数',
                                    dataIndex: 'thumbs',
                                    key: 'thumbs',
                                    align: 'center',
                                },
                                {
                                    title: '帖子状态',
                                    key: 'block',
                                    dataIndex: 'block',
                                    align: 'center',
                                    render: (block) => {
                                        return block ? '屏蔽' : '正常';
                                    }
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'publishTime',
                                    key: 'publishTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return  <div>
                                                    <Button type="primary"
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onOpenPostModalEvent(text, text.emojiId, operationTypeValue.UPDATE)}
                                                            color="blue">
                                                        编辑
                                                    </Button>
                                                    <Popconfirm title={text.lockStatus ? '确定要将该表情包取消锁帖吗？' : '确定要将该表情包锁帖吗？'}
                                                                onConfirm={() => this.onSetPostEvent('lock', text.lockStatus, text.emojiId)}
                                                                okText="确认" cancelText="取消">
                                                        <Button type={text.lockStatus ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.lockStatus ? '已锁帖' : '锁帖'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Popconfirm title={text.top ? '确定要将该表情包取消置顶吗？' : '确定要将该表情包置顶吗？'}
                                                                onConfirm={() => this.onSetPostEvent('top', text.top, text.emojiId)}
                                                                okText="确认" cancelText="取消">
                                                        <Button type={text.top ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.top ? '已置顶' : '置顶'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Popconfirm title={text.block ? '确定要取消屏蔽该帖子吗？' : '确定要屏蔽该帖子吗？'}
                                                                onConfirm={() => this.onSetPostEvent('block', text.block, text.emojiId)}
                                                                okText="确认" cancelText="取消"><br/>
                                                        <Button type={text.block ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.block ? '已屏蔽' : '屏蔽'}
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                    }
                                },
                            ]
                        }

                        // title={
                        //     this.renderTableTitle
                        // }
                        dataSource={emojiPostList.data ? emojiPostList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: emojiPostList ? emojiPostList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑帖子" : "查看帖子内容"}
                        visible={isShowEditAdminModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onClosePostModalEvent}
                    >
                        <EmojiForm {...this.props} {...this.state} {...emojiPostData} onSubmitEvent={this.onEditPostEvent}/>
                    </Modal>
                </Card>                              
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Banner,
            ...state.innerJumpList,
            ...state.images,
            ...state.Topics
        }

    }

);

const mapDispatchToProps = {
    getEmojiPostList,
    getEmojiPostDetail,
    updateEmojiPost, 
    updateEmojiPostSetting
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(EmojiManagement)));