import createAsyncAction from "utils/createAsyncAction";
import ClubRequest from "../../request/ClubRequest";
import {
    ADD_CLUB,
    ADD_EVENT,
    APPROVE_CLUB,
    BIND_CLUB,
    DELETE_CLUB,
    DELETE_EVENT,
    GET_CLUB,
    GET_CLUB_DETAIL,
    GET_EVENT,
    GET_GAME,
    GET_TEAM,
    GET_TRAIL_PLAN,
    GET_TRAILS,
    OPT_TRAILS,
    PUBLISH_TRAIL_PLAN,
    PUT_CLUB,
    UNTYING_CLUB,
    CHANGE_RECOMMEND,
    GET_SIGNBOARD,
    DELETE_TRAIN,
    REPLY_TRAIN,
    DELETE_TRAIL_PLAN,
    REPLY_TRAIL_PLAN,
    ADD_SIGNBOARD,
    DEL_SIGNBOARD,
    CHECK_SIGNBOARD,
    SET_SIGNBOARD_PRIORITY,
    SET_SIGNBOARD_TOP,
    GET_TRIALPLAN_NEW,
    SET_TRIALPLAN_NEW_HOMEPAGE,
} from "../club/actionTypes";
import {GET_TRAIL_PLAN_BRAND, UPDATE_EVENT} from "./actionTypes";
const deleteEvent=(clubId,raceCourseId)=>(
    createAsyncAction(DELETE_EVENT, () => (ClubRequest.deleteEvent(clubId,raceCourseId)))
)

const getClub = (params) => (
    createAsyncAction(GET_CLUB, () => (ClubRequest.getClub(params)))
);

const getClubDetail = (id) => (createAsyncAction(GET_CLUB_DETAIL, () => (ClubRequest.getClubDetail(id))));

//审批俱乐部
const approveClub = (id, params) => (createAsyncAction(APPROVE_CLUB, () => (ClubRequest.approveClub(id, params))));


const addClub = (params) => (
    createAsyncAction(ADD_CLUB, () => (ClubRequest.addClub(params)))
);

const deleteClub = (clubId) => (
    createAsyncAction(DELETE_CLUB, () => (ClubRequest.deleteClub(clubId)))
)

const deleteTrain = (trainId) => (
    createAsyncAction(DELETE_TRAIN, () => (ClubRequest.deleteTrain(trainId)))
)

const replyTrain = (trainId) => (
    createAsyncAction(REPLY_TRAIN, () => (ClubRequest.replyTrain(trainId)))
)


const editClub =(clubId,params)=>(
    createAsyncAction(PUT_CLUB, () => (ClubRequest.editClub(clubId,params)))

)

const publishTrial = (clubId, params) => (
    createAsyncAction(PUBLISH_TRAIL_PLAN, () => (ClubRequest.publishTrial(clubId, params)))
)
const getTrailDetail = (clubId) => (
    createAsyncAction(GET_TRAIL_PLAN, () => (ClubRequest.getTrialDetail(clubId)))
)

const getTrailPlanBrand = () => (
    createAsyncAction(GET_TRAIL_PLAN_BRAND, () => (ClubRequest.trailPlanBrand()))
)
const deleteTrialPlan = (clubId) => (
    createAsyncAction(DELETE_TRAIL_PLAN, () => (ClubRequest.deleteTrialPlan(clubId)))
)
const replyTrialPlan = (clubId) => (
    createAsyncAction(REPLY_TRAIL_PLAN, () => (ClubRequest.replyTrialPlan(clubId)))
)
const getTeam = (params) => (createAsyncAction(GET_TEAM, () => (ClubRequest.getTeam(params))));

const getEvent = (clubId,  page, size ) => (createAsyncAction(GET_EVENT, () => (ClubRequest.getEvent(clubId,page, size))));

const addEvent = (clubId,awardDate,name,grade) => (createAsyncAction(ADD_EVENT, () => (ClubRequest.addEvent(clubId,awardDate,name,grade))));
const updateEvent = (id,awardDate,name,grade) => (createAsyncAction(UPDATE_EVENT, () => (ClubRequest.updateEvent(id,awardDate,name,grade))));

const bindClub = (params) => {
    createAsyncAction(BIND_CLUB, () => (ClubRequest.bindClub(params)))
}

const untyingClub = (params) => {
    createAsyncAction(UNTYING_CLUB, () => (ClubRequest.untyingClub(params)))
}

//试训
const getTrial = (page, size, userId, clubName, userName, dateRange) => (createAsyncAction(GET_TRAILS, () => (ClubRequest.getTrial(page, size, userId, clubName, userName, dateRange))));
const optTrial = (id, {params}) => (createAsyncAction(OPT_TRAILS, () => (ClubRequest.optTrial(id, {params}))));
//游戏
const getGame = () => (createAsyncAction(GET_GAME, () => (ClubRequest.getGame())));

const changeRecommend=(clubId,recommendNum)=>(createAsyncAction(CHANGE_RECOMMEND, () => (ClubRequest.changeRecommend(clubId, recommendNum))))

//自荐
const getSignboard = (params) => (createAsyncAction(GET_SIGNBOARD, () => (ClubRequest.getSignboard(params))));
const addSignboard = (params) => (createAsyncAction(ADD_SIGNBOARD, () => (ClubRequest.addSignboard(params))));
const delSignboard = (id) => (createAsyncAction(DEL_SIGNBOARD, () => (ClubRequest.delSignboard(id))));
const checkSignboard = (id,params) => (createAsyncAction(CHECK_SIGNBOARD, () => (ClubRequest.checkSignboard(id,params))));
const setSignboardPriority = (id,params) => (createAsyncAction(SET_SIGNBOARD_PRIORITY, () => (ClubRequest.setSignboardPriority(id,params))));
const setSignboardTop = (id,params) => (createAsyncAction(SET_SIGNBOARD_TOP, () => (ClubRequest.setSignboardTop(id,params))));

//试训计划
const getTrailPlanNew = (params) => (createAsyncAction(GET_TRIALPLAN_NEW, () => (ClubRequest.getTrailPlanNew(params))));
const setTrailPlanNewhomePage = (trailId,params) => (createAsyncAction(SET_TRIALPLAN_NEW_HOMEPAGE, () => (ClubRequest.setTrailPlanNewhomePage(trailId,params))));



export {
    getClub,
    getClubDetail,
    addClub,
    deleteClub,
    editClub,
    getTeam,
    bindClub,
    untyingClub,
    getTrial,
    getSignboard,
    addSignboard,
    delSignboard,
    checkSignboard,
    setSignboardPriority,
    setSignboardTop,
    publishTrial,
    getTrailDetail,
    approveClub,
    optTrial,
    getGame,
    addEvent,
    updateEvent,
    getEvent,
    deleteEvent,
    changeRecommend,
    deleteTrain,
    replyTrain,
    deleteTrialPlan,
    replyTrialPlan,
    getTrailPlanBrand,
    getTrailPlanNew,
    setTrailPlanNewhomePage,
};
