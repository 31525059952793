import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Card, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    findHistoryLogs
} from "../../modules/vip/action";
import {
    sendStatus
} from "../../modules/msgNotice/enums";
import zhijinFilter from "./zhijinFilter";
import {tradeType} from "../../modules/vip/enums";

class zhijinManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            telephone: null,
            noticeGroupType: null,
            sendStatus:null,
        };
    }

    componentDidMount() {
        const {pageNo, pageSize,telephone,noticeGroupType,sendStatus} = this.state;
        let {findHistoryLogs} = this.props;
        findHistoryLogs(telephone, noticeGroupType,sendStatus, pageNo, pageSize);
    }

    onPageChangeEvent = (current, pageSize) => {
        let {findHistoryLogs} = this.props;
        let {telephone, noticeGroupType, sendStatus} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize
        });
        findHistoryLogs(telephone, noticeGroupType,sendStatus, current, pageSize);
    }

    onFilterMsgNoticeEvent = (telephone, noticeGroupType,sendStatus) => {
        const {findHistoryLogs} = this.props;
        const {pageSize} = this.state;
        this.setState({
            pageNo: 1,
            telephone: telephone,
            noticeGroupType: noticeGroupType,
            sendStatus
        })
        findHistoryLogs(telephone, noticeGroupType,sendStatus,1, pageSize)
    }

    render() {
        const {
            zhijinHistoryLogs, loading
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <zhijinFilter dataSource={{}} onCommit={this.onFilterMsgNoticeEvent}></zhijinFilter>
                </Card>
                <Card>
                    <Table

                        columns={
                            [
                                {
                                    title: '用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                    width:'100px',
                                },
                                {
                                    title: '用户手机号',
                                    dataIndex: 'phone',
                                    key: 'phone',
                                    align: 'center',
                                },
                                {
                                    title: '交易金额',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'center',
                                },
                                {
                                    title: "交易类型",
                                    dataIndex: 'tradeType',
                                    key: 'tradeType',
                                    align: 'center',
                                    render:(text) => (text ? tradeType[text].label : null)
                                },
                                {
                                    title: "交易渠道",
                                    dataIndex: 'tradeChannelCN',
                                    key: 'tradeChannelCN',
                                    align: 'center',
                                },
                                {
                                    title: "交易时间",
                                    dataIndex: 'time',
                                    key: 'time',
                                    align: 'center',
                                },
                                {
                                    title: "紫金余额",
                                    dataIndex: 'balance',
                                    key: 'balance',
                                    align: 'center',
                                }
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={zhijinHistoryLogs && zhijinHistoryLogs.data ? zhijinHistoryLogs.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: zhijinHistoryLogs && zhijinHistoryLogs ? zhijinHistoryLogs.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.vip
        }

    }

);

const mapDispatchToProps = {
    findHistoryLogs

};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(zhijinManagement)));
