export const GET_SCHEDULE = 'GET_SCHEDULE';// 获取所有行程
export const GET_SCHEDULE_DETAIL = 'GET_SCHEDULE_DETAIL';// 获取行程详情
export const GET_PREDICTION_DETAIL = 'GET_PREDICTION_DETAIL';// 获取预测详情
export const ADD_SCHEDULE = 'ADD_SCHEDULE';// 新增行程
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';// 编辑行程
export const DEL_SCHEDULE = 'DEL_SCHEDULE';// 删除行程
export const RECOMMEND_SCHEDULE = 'RECOMMEND_SCHEDULE';// 推荐行程
export const ADD_Prediction = 'ADD_Prediction';
export const EDIT_Prediction = 'EDIT_Prediction';
export const EDIT_Prediction_Result = 'EDIT_Prediction_Result';
export const GET_GUESS = 'GET_GUESS';// 获取所有预测
