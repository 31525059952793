import React from 'react';
import './BasicLayout.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <div className={`${this.props.prefixCls}-footer`}>
                Copyright © 2018
      </div>
    );
  }
}

export default Footer;
