import createReducer from 'utils/createReducer';

import {
    GET_INFOMATION_POSTS,
    GET_INFOMATION_STATICS,
} from './actionTypes'

const defaultState = () => ({
    statics:{},//评论点赞统计
    infomationPosts:{}
});

const onGetInfomationStatics = (state,{payload}) => {
    return {
        ...state,
        statics:payload,
    }

};

const onGetInfomationPosts = (state,{payload}) => {
    return {
        ...state,
        infomationPosts:payload,
    }

};

const onGetActivityType = (state,{payload}) => {
    return {
        ...state,
        activityType:payload,

    }

};



export default createReducer(defaultState, {
    [`${GET_INFOMATION_STATICS}_SUCCESS`]: onGetInfomationStatics,
    [`${GET_INFOMATION_POSTS}_SUCCESS`]: onGetInfomationPosts,

});
