/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import {
  Upload, Icon, message, notification,
} from 'antd';

class headPortrait extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('图片格式必须是jpg/png!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('图片大小必须小于2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // this.getBase64(info.file.originFileObj, imageUrl => {
      // this.setState({
      //     imageUrl,
      //     loading: false,
      // })
      // console.log("info.file.response.url",info.file.response.url)
      //     this.onUpdateUserEvent(info.file.response.url)
      // });
      // notification.success({ message: '文件上传成功' });
      this.onUpdateUserEvent(info.file.response.url);
    }
  }

  onUpdateUserEvent = async (imageUrl) => {
    let { userId, updateUser, handleSubmit } = this.props;
    try {
      await updateUser(userId, { imageUrl });
      await handleSubmit();
      notification.success({ message: '头像更新成功' });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    let { imageUrl } = this.props.userInfo;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'instagram'} />
        <div className="ant-upload-text">修改头像</div>
      </div>
    );
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="/api/file"
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        name="imageFile"
      >
        {imageUrl ? <img src={`/api${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}

export default headPortrait;
