import React, {Component} from "react";
import {Form, DatePicker, Button,Select} from "antd";
import {getGame} from '../../../modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import moment from "moment";
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const { Option } = Select;
class ActivityFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let val = {
                  ...values,
                  date:values.date?moment(values.date).format('YYYY-MM-DD'):''
                }
                onCommit && onCommit(val);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let {gameList} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="资讯分类:">
                    {getFieldDecorator('gamingDivision', {
                        initialValue:1,
                    })(
                        <Select style={{width: 120}}>
                            {
                                gameList.map((val,index)=>{
                                    return(
                                        <Option value={val.id} key={val+index}>{val.gameName}</Option>
                                    )
                                })
                            }

                        </Select>,
                    )}
                </FormItem>

                <FormItem label="发布时间区间">
                    {getFieldDecorator('dateRange', {
                        required:true

                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>


                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (Form.create()(withRouter(injectIntl(ActivityFilter))));

