import createReducer from 'utils/createReducer';

import {
    GET_REPORTS,GET_REPORT,GET_FEEDBACK_LIST
} from './actionTypes';

const defaultState = () => ({
    reports: [],
    reportsTotal: 0,
    reportsLoading: false,
    reportInfo: {},
    adminRoles: [], // 用户所拥有的角色
    feedbackList: [], //用户反馈列表
});


const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        reports:payload

    }

};

const ongetReportsuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        reportInfo: payload,
    }

};

const onGetFeedbackListSuccess = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        feedbackList: payload
    }
};

export default createReducer(defaultState, {
    [`${GET_REPORTS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_REPORT}_SUCCESS`]: ongetReportsuccess,
    [`${GET_FEEDBACK_LIST}_SUCCESS`]: onGetFeedbackListSuccess,
});
