import React from 'react';
import {Form, Input, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};


const email = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

class AppContactInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentWillMount() {
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, value} = this.props;
        console.log(value)
        return (
            <Modal
                visible={visible}
                title="app联系方式配置"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="图标"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: value ? value.imageUrl+'?x-oss-process=image/resize,w_86' : "",
                            rules: [{required: true, message: '图标未上传!'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                // width={200}
                                // height={200}
                                size={251}
                            />
                        )}
                    </FormItem>
                    <FormItem
                        label="官网"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('website', {
                            initialValue: value ? value.website : '',
                            rules: [{ required: true, message:'请输入官网地址'},
                                {
                                    pattern: /[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/,
                                    message: '请输入正确网址'
                                }
                            ],
                        })(
                            <Input placeholder="请填写官网"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="公众号"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('official', {
                            initialValue: value ? value.official : '',
                            rules: [ {
                                required: true, message: '请输入公众号',
                            }],
                        })(
                            <Input placeholder="请填写公众号"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="客服QQ"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('serviceQq', {
                            initialValue: value ? value.serviceQq : '',
                            rules: [ {
                                required: true, message: '请输入客服QQ',
                            }],
                        })(
                            <Input placeholder="请填写客服QQ"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="邮箱"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('email', {
                            initialValue: value ? value.email : '',
                            rules: [ {
                                required: true, message: '请输入邮箱',
                            }, {
                                validator: this.validateEmail,
                            }],
                        })(
                            <Input placeholder="请填写邮箱"/>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
    validateEmail = async (rule, value, callback)=>{
        console.log(value)
        if (value&&!value.match(email)) {
            callback('请输入正确的邮箱');
        }
        callback();
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.AppContact,
    }
};
const mapDispatchToProps = ({
});
AppContactInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AppContactInfoModel)));
