import React, {Component} from 'react';
import {
    Form, Input, Button
} from 'antd';
const FormItem = Form.Item;

class SearchForm extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {exhibitionId,pelPlayerIdName} = values;
                onCommit && onCommit(exhibitionId,pelPlayerIdName);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {exhibitionId,pelPlayerIdName} = values;
                onCommit && onCommit(exhibitionId,pelPlayerIdName);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit} layout='inline'>
            <FormItem label='用户展示ID'>
                {
                    getFieldDecorator('exhibitionId')(<Input/>)
                }
            </FormItem>
            <FormItem label='选手ID名称'>
                {
                    getFieldDecorator('pelPlayerIdName')(<Input/>)
                }
            </FormItem> 
            <FormItem>
                <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                    查询
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(SearchForm);
