import api from 'utils/api';

class InfomationRequest {
  /**
     * 查询活动
     * @param {Object} params 请求参数
     */
  static async getStatics(parmas) {
    return api.get(`/admin/official-information/statisticsOfficialInfo`, parmas);
  }

  /**
   * 获取资讯和帖子管理信息
   * @param {Object} params 请求参数
   */
  static async getInfomationPosts(parmas) {
    return api.get(`/admin/official-information/relations`, parmas);
  }

  static async createInfomationPost(parmas) {
    return api.post(`/admin/official-information/relations`, parmas);
  }

  static async updateInfomationPost(id,parmas) {
    parmas.id = id;
    return api.put(`/admin/official-information/relations`, parmas);
  }

  static async deleteInfomationPost(id,parmas) {
    return api.delete(`/admin/official-information/relations/${id}`, parmas);
  }

}
export default InfomationRequest;
