import createReducer from 'utils/createReducer';
import {GET_PARTNER_GOODS, GET_PARTNER_GOODS_LIST, GET_PARTNER_TEACHER, GET_PARTNER_TEACHER_LIST} from './actionTypes';

const defaultState = () => ({
    partnerTeachers: {
        data: [],
        total: 0,
    }, // 导师列表
    partnerTeacher: {}, // 导师信息
    partnerGoodsList: {
        data: [],
        total: 0,
    }, // 导师列表
    partnerGoods: {}, // 导师信息
});

const onGetPartnerTeacherSuccess = (state, {payload}) => ({
    ...state,
    partnerTeacher: payload,
});

const onQueryPartnerTeachersSuccess = (state, {payload}) => ({
    ...state,
    partnerTeachers: payload,
});

const onGetPartnerGoodsSuccess = (state, {payload}) => ({
    ...state,
    partnerGoods: payload,
});

const onQueryPartnerGoodsSuccess = (state, {payload}) => ({
    ...state,
    partnerGoodsList: payload,
});


export default createReducer(defaultState, {
    [`${GET_PARTNER_TEACHER}_SUCCESS`]: onGetPartnerTeacherSuccess,
    [`${GET_PARTNER_TEACHER_LIST}_SUCCESS`]: onQueryPartnerTeachersSuccess,
    [`${GET_PARTNER_GOODS}_SUCCESS`]: onGetPartnerGoodsSuccess,
    [`${GET_PARTNER_GOODS_LIST}_SUCCESS`]: onQueryPartnerGoodsSuccess,
});
