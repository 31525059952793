import React, { Component } from 'react';
import {
  Form, Button, InputNumber
} from 'antd';



const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};


class LangBiRechargeForm extends Component {


  onSubmitEvent = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { onSubmit } = this.props;
          onSubmit && onSubmit(values.dayCount);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="horizontal">
        <Form.Item
            label="天数"
            {...formItemLayout}
        >
          {getFieldDecorator('dayCount', {
            initialValue: 1,
          })(
              <InputNumber min={1} />,
          )}
        </Form.Item>
        {/*
        <Form.Item
            label="操作理由"
            {...formItemLayout}
        >
          {getFieldDecorator('reason', {
            initialValue: "",
            rules:[
                {
                    required:true,
                    message:"请填写操作理由"
                }
            ]
          })(
              <Input.TextArea rows={4}  placeholder="请填写操作理由"   />,
          )}
        </Form.Item>
        */}
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(LangBiRechargeForm);
