import api from '../utils/api';


class GiftRequest {

    /**
     * 获取礼品列表
     * @param page
     * @param size
     * @param giftName
     * @param giftType
     * @returns {*}
     */
    static getGiftList(params) {
        return api.get('/admin/gift/list', params);
    }

    /**
     * 添加礼品
     * @param {Object} params 请求参数
     */
     static createGift(params) {
        return api.post('/admin/gift/add', params);
    }

    /**
     * 编辑礼品
     * @param {Object} params 请求参数
     */
    static updateGift(giftId, params) {
        return api.put(`/admin/gift/${giftId}`, {giftId, ...params});
    }

    /**
     * 删除礼品
     * @param id
     * @returns {*}
     */
     static deleteGift(giftId) {
        return api.delete(`/admin/gift/${giftId}`);
    }

    /**
     * 分页查看指定礼品CDK列表
     * @param giftId
     * @param page
     * @param size
     * @returns {*}
     */
     static getGiftCDKList(giftId, params) {
        return api.get(`/admin/gift/cdk/list/${giftId}`, params);
    }

    /**
     * 删除礼品CDK
     * @param id
     * @returns {*}
     */
     static deleteGiftCDK(giftCdkId) {
        return api.delete(`/admin/gift/cdk/${giftCdkId}`);
    }

    /**
     * 分页条件查看礼品订单列表
     * @param page
     * @param size
     * @param giftName
     * @param userId
     * @param giftType
     * @returns {*}
     */
     static getGiftOrderList(params) {
        return api.get('/admin/gift/order/list', params);
    }

    /**
     * 编辑礼品订单收货地址
     * @param {Object} params 请求参数
     */
     static updateGiftOrderAddress(orderId, params) {
        return api.put(`/admin/gift/order/editShippingAddress/${orderId}`, {orderId, ...params});
    }

    /**
     * 礼品订单发货
     * @param {Object} params 请求参数
     */
     static updateGiftOrderShip(orderId, params) {
        return api.put(`/admin/gift/order/ship/${orderId}`, {orderId, ...params});
    }

}

export default GiftRequest;
