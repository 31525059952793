import createAsyncAction from 'utils/createAsyncAction';
import {GET_COINS,CREATE_COIN,UPDATE_COIN,DELETE_COIN} from "../coin/antionType";
import CoinRequest from "../../request/CoinRequest";


const getCoins = () => (
    createAsyncAction(GET_COINS, () => (CoinRequest.getCoins()))
);

const createCoin = (amount,coin,packageDetail,packageName,type,applePayId) => (createAsyncAction(CREATE_COIN, () => (CoinRequest.addCoins({amount,coin,packageDetail,packageName,type,applePayId}))));

const updateCoin = (id, { amount,coin,packageDetail,packageName,type,applePayId }) => (createAsyncAction(UPDATE_COIN,
    () => (CoinRequest.updateCoins(id, { amount,coin,packageDetail,packageName,type,applePayId }))));

const deleteCoins = (id) => (
    createAsyncAction(DELETE_COIN, () => (CoinRequest.deleteCoins(id)))
);

export {
    getCoins,createCoin,updateCoin,deleteCoins
};