export const GET_PLAYER_DETAIL_LIST = 'GET_PLAYER_DETAIL_LIST';

export const GET_PLAYER_ORDER_LIST_LIST = 'GET_PLAYER_ORDER_LIST_LIST';

export const CREATE_PLAYER_DETAIL = 'CREATE_PLAYER_DETAIL';

export const UPDATE_PLAYER_DETAIL_DATA = 'UPDATE_PLAYER_DETAIL_DATA';

export const DELETE_PLAYER_DETAIL = 'DELETE_PLAYER_DETAIL';

export const CREATE_PLAYER_POST = 'CREATE_PLAYER_POST';

export const UPDATE_GIFT_ORDER_ADDRESS = 'UPDATE_GIFT_ORDER_ADDRESS';

export const UPDATE_GIFT_ORDER_SHIP = 'UPDATE_GIFT_ORDER_SHIP';
