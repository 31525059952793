import React, {Component} from 'react';
import {
    Form, Input, Button, Select
} from 'antd';
import UpLoad from "../../components/upload";
import api from "../../utils/api";
import {operationTypeValue} from "../../modules/topic/topicEnum";
import TopicSelectModal from './TopicSelectModal';
import topic from 'modules/topic';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class PostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypeList: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            innerJumpType: this.props.innerJumpType,
            showTopicSelectModal: false
        };
    }

    componentWillMount(){

    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                // if(values && values.imageUrl){
                //     values.imageUrl=values.imageUrl.split("?")[0]
                // }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    onConfirmTopic = (topic) => {
        this.props.form.setFieldsValue({
            topicId: topic ? topic.id : null,
            topicName: topic ? topic.topicName : ''
        });
    }

    render() {
        let {
            operationType,
            authorExId,
            topicName,
            imageUrlList,
            content,
            title
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        const {TextArea} = Input;
        let { showTopicSelectModal } = this.state;
        let disabled = operationType === operationTypeValue.SELECT;
        console.log("imageUrlList: ", imageUrlList);
        return (
            <Form>
                <FormItem
                    label="用户展示ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('authorExId', {
                        rules: [{required: true, message: '请输入作者展示ID', pattern: /^[0-9]*$/}],
                        initialValue: authorExId || null
                    })(
                        <Input placeholder="请输入作者" disabled={disabled}/>,
                    )}
                </FormItem>
                <FormItem
                    label="所属话题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('topicName', {
                        rules: [{required: true, message: '请输入所属话题', pattern: /^\S+$/}],
                        initialValue: topicName || ''
                    })(
                        <Input placeholder="请选择所属话题" disabled={true} />
                    )}
                </FormItem>
                {!disabled && <FormItem
                    label="请选择话题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('topicId', {})(
                        <Button
                            type="primary"
                            onClick={() => {
                                this.setState({
                                    showTopicSelectModal: true
                                });
                            }}
                        >
                            选择话题
                        </Button>
                    )}
                </FormItem>}
                <FormItem
                    label="图片(355*177)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrlList', {
                        initialValue: (imageUrlList && imageUrlList.length > 0) ? imageUrlList : []
                        // + '?x-oss-process=image/resize,w_86'
                    })(
                        (<UpLoad
                            double={true}
                            // value={imageUrlList}
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={501}
                            disabled={disabled}
                        />),
                    )}
                </FormItem>
                <FormItem
                    label="帖子标题"
                    {...formItemLayout}

                >
                    {getFieldDecorator('title', {
                        initialValue:title || '',
                    })(
                        <Input placeholder="请输入帖子标题"/>
                    )}
                </FormItem>
                <FormItem
                    label="内容"
                    {...formItemLayout}

                >
                    {getFieldDecorator('content', {
                        initialValue: content || '',
                        rules: [{max: 1000, message: '帖子内容不得超过1000个字符'},{required: true, message: '请输入帖子内容'}],
                    })(
                        <TextArea rows={6} placeholder="请输入帖子内容" disabled={disabled}/>,
                    )}
                </FormItem>
                {!disabled && <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>}
                <TopicSelectModal
                    visible={showTopicSelectModal}
                    onClose={() => {
                        this.setState({
                            showTopicSelectModal: false
                        });
                    }}
                    onConfirmTopic={this.onConfirmTopic}
                />
            </Form>
        );
    }
}

export default Form.create()(PostForm);
