import createReducer from 'utils/createReducer';

import {
    GET_START_IMAGE
} from './actionTypes'

const defaultState = () => ({
    images: [],
    articlesTotal: 0,
    articlesLoading: false,
    planInfo: {},
    adminRoles: [], // 用户所拥有的角色
    plans:[],
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        images:payload

    }

};

const ongetArticlesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        planInfo: payload,
    }

};

const onGetGamesSuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        plans: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_START_IMAGE}_SUCCESS`]: ongetAdminsRequest,



});
