import React from 'react';
import {Button, Form, Input, Modal, Select} from 'antd';
import {fromJS, is} from 'immutable';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";
import TrainingPreconditionModel from "../../training-management/components/TrainingPreconditionModel";
import {getBrand} from "../../../modules/training/action";

const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 9},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class BrandInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            defaultValue: null,
            preconditionValue: null,
            isShowPreconditionModal : false,
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            // eslint-disable-next-line react/prop-types
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            defaultValue,
            preconditionValue: defaultValue.precondition ? defaultValue.precondition : null
        });

        const {getGameList} = props;
        getGameList();
    }

    onSubmit = () => {
        // eslint-disable-next-line react/prop-types
        let {preconditionValue} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(preconditionValue){
                    values["precondition"] = preconditionValue
                }
                if(values && values.logo){
                    values.logo=values.logo.split("?")[0]
                }
                if(values && values.trainingLogo){
                    values.trainingLogo=values.trainingLogo.split("?")[0]
                }
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    //前置条件
    onPreconditionConfig = async (params) => {
        console.log(params)
        this.setState({
            preconditionValue: JSON.stringify(params),
            isShowPreconditionModal: false
        })
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const {getFieldDecorator} = this.props.form;
        const {value, visible, operationType, games, brandData} = this.props;
        const {preconditionValue, isShowPreconditionModal} = this.state;
        const defaultValue = value || {};
        console.log(defaultValue)
        return (
            <div>
                <Modal
                    width={'30%'}
                    visible={visible}
                    title={operationType == operationTypeValue.UPDATE ? "修改品牌" : operationType == operationTypeValue.INCREASE ? "添加品牌" : "添加品牌"}
                    onCancel={this.props.onClose}
                    cancelText={"取消"}
                    onOk={this.onSubmit}
                    okText={operationType == operationTypeValue.UPDATE ? "确认" : operationType == operationTypeValue.INCREASE ? "确认" : "确认"}
                >
                    <Button style={{marginLeft: "10px", marginRight: "10px", marginBottom: "10px"}} type="primary"
                            onClick={() => {
                                this.setState({
                                    isShowPreconditionModal: !isShowPreconditionModal
                                })
                            }}
                            color="blue">前置条件</Button> <span>{preconditionValue ? "已配置" : "未配置"}</span>
                    <Form>
                        <FormItem
                            label="名称"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('name', {
                                rules: [{required: true, message: '请填写品牌赛事名称'}],
                                initialValue: defaultValue.name,
                            })(
                                <Input placeholder="请填写品牌赛事名称"/>,
                            )}
                        </FormItem>
                        <FormItem
                            label="简称"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('aliasName', {
                                rules: [{required: true, message: '请填写品牌赛事简称'}],
                                initialValue: defaultValue.aliasName,
                            })(
                                <Input placeholder="请填写品牌赛事简称"/>,
                            )}
                        </FormItem>
                        <FormItem
                            label="游戏类型"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('game', {
                                initialValue: games.filter((item) => item.code == value.game).length > 0 ? games.filter((item) => item.code == value.game)[0].code : "",
                                rules: [{required: true, message: '请选择游戏类型!'}],
                            })(
                                <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                                    {games.map((item) => <Select.Option value={item.code}
                                                                        key={item.code}>{item.gameName}</Select.Option>)}
                                </Select>,
                            )}
                        </FormItem>
                        <FormItem
                            label="LOGO(750*326)"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('logo', {
                                initialValue: defaultValue.logo ? defaultValue.logo+'?x-oss-process=image/resize,w_86' : "",
                                rules: [{required: true, message: 'LOGO未上传!'}],
                            })(
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width={750}
                                    // height={326}
                                    size={251}
                                />
                            )}
                        </FormItem>
                        <FormItem
                            label="Banner展示LOGO(690*168)"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('trainingLogo', {
                                initialValue: defaultValue.trainingLogo ? defaultValue.trainingLogo+'?x-oss-process=image/resize,w_86' : '',
                                rules: [{required: true, message: '训练赛Banner展示LOGO未上传!'}],
                            })(
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width={690}
                                    // height={168}
                                    size={251}
                                />
                            )}
                        </FormItem>
                        <FormItem
                            label="介绍"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('introduction', {
                                rules: [{required: true, message: '请填写品牌赛事介绍'}],
                                initialValue: defaultValue.introduction,
                            })(
                                <TextArea placeholder="请填写品牌赛事介绍"></TextArea>
                            )}
                        </FormItem>

                        <FormItem
                            label="是否投入使用"

                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('state', {
                                initialValue: value ? value.state ? 'true' : 'false' : 'true',
                            })(
                                <Select placeholder="请选择品牌状态" onChange={this.onChange}>
                                    <Select.Option value='true' key='true'>是</Select.Option>
                                    <Select.Option value='false' key='false'>否</Select.Option>
                                </Select>,
                            )}
                        </FormItem>
                    </Form>
                </Modal>
                {isShowPreconditionModal ? <TrainingPreconditionModel
                    visible={isShowPreconditionModal}
                    precondition={preconditionValue}
                    onSuccess={this.onPreconditionConfig}
                    onClose={() => {
                        this.setState({
                            isShowPreconditionModal: false,
                        });
                    }}
                /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
    getBrand,
});
BrandInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(BrandInfoModel)));