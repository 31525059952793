import React, {Component} from "react";
import {injectIntl} from "react-intl-context";
import {Button, Card, Col, message, Modal, notification, Row, Table} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import utils from "utils/utils";
import {
    addClub,
    addEvent,
    bindClub,
    deleteClub,
    editClub,
    getClub,
    getClubDetail,
    getGame,
    getTeam,
    getTrailDetail,
    getTrial,
    publishTrial,
    untyingClub,
    changeRecommend,
    deleteTrialPlan,
    replyTrialPlan, getTrailPlanBrand
} from "../../modules/club/action";
import AddClubModel from "./components/AddClubModel";
import ChangeTrailPlanModel from "./components/ChangeTrailPlanModel";
import TeamModel from "./components/TeamModel";
import {operationType} from "../../modules/club/clubEnum";
import ClubFilter from "./components/ClubFilter";
import ClubDetailModel from "./components/ClubDetailModel";
import EventRecordModal from "./components/EventRecordModal";
import FirstRecommendModal from "./components/FirstRecommendModal";


const {confirm} = Modal;


class ClubManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 15,
            isShowAddClubModel: false,
            isShowChangePlanModel: false,
            isShowTeamModal: false,
            isShowDetail: false,
            isShowModal: false,
            currentAdminId: null,
            detailId: null,
            optType: operationType.ADD,
            seletionValue: null,
            showId: false, //添加俱乐部
            clueId: "",
            teamParams: {
                clubId: null,
            },
            trialParams: {
                clubId: null,
                trailPageTyp: 'CLUB_MANAGER'
            },
            isShowFirstModal : false,
            isFirstclubId: 0,
            isFirstPage:false
        };
    }


    componentDidMount() {
        this.onGetData();
    }

    renderClubType = (text) => {
        if (text === 'COMMON') {
            return (
                <div color="blue">普通</div>
            )
        }
        if (text === '' || text === null) {
            return (
                <div color="blue">未审核</div>
            )
        } else {
            return (
                <div color="blue">{text}</div>
            )
        }
    }

    renderState = (text) => {

        if (text === 'REJECT') {
            return (
                <div color="blue">已驳回</div>
            )
        } else if (text === 'APPROVED') {
            return (
                <div color="blue">通过</div>
            )
        } else if (text === 'SUBMIT') {
            return (
                <div color="blue">未审核</div>
            )
        }
    }
    isShowModal = (id,isFirstPage) =>{
        console.log(isFirstPage)
        this.setState({
            isShowFirstModal : true,
            isFirstclubId: id,
            isFirstPage:isFirstPage
        })
    }
    renderOpt = (text) => {
        if (text.state === 'SUBMIT') {
            return (
                <div>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                        this.onOpenDetail(text.id)
                    }} color="blue">审核</Button>
                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteClubEvent(text)} color="red">删除</Button>
                </div>

            )
        } else if (text.state === 'APPROVED') {
            return (
                <div>
                    {/* <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onChangePlanEvent(text.id)}
                            color="blue">修改试训计划</Button>
                    {text.trainPlanState === 1 ?  <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onReplyTrainPlan(text.id)}
                                                  color="blue">恢复试训计划</Button> :
                        <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteTrainPlan(text.id)}
                                                 color="blue">删除试训计划</Button>} */}
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onAddEvent(text.id)} color="blue">俱乐部荣誉管理</Button>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onOpenDetail(text.id)} color="blue">详情</Button>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onOptEvent(operationType.EDIT, text)}
                            color="blue">修改</Button>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onOpenTeamModalEvent(text.id)}
                            color="blue">战队管理</Button>
                    {/* <Button style={{marginLeft: 10, marginBottom: 10}} type={!text.recommend?"primary":"dashed"} onClick={() => {
                        this.isShowModal(text.id,text.recommend)
                    }}>{!text.recommend?"是否推荐首页":"已推荐"}</Button> */}
                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteClubEvent(text)} color="red">删除</Button>
                </div>
            )
        } else if (text.state === 'REJECT') {
            return (
                <div>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onOpenDetail(text.id)} color="blue">详情</Button>
                </div>
            )
        }

        return
    }
    onOpenDetail = (id) => {
        let {getClubDetail} = this.props
        this.setState({
            detailId: id,
            isShowDetail: true
        }, () => {
            getClubDetail(id)
        })
    };
    onAddEvent = (id) => {
        this.setState({
            isShowModal: true,
            clueId: id
        }, () => {
        })
    };

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary" onClick={() => {
                this.onOptEvent(operationType.ADD, null)
            }}>新增</Button></Col>
        </Row>
    )

    onOptEvent = (type, seletionValue) => {
        this.setState({
            optType: type,
            isShowAddClubModel: true,
            seletionValue,
        }, () => {
            if (type == operationType.ADD) {
                this.setState({
                    showId: true
                })
            } else if (type == operationType.EDIT) {
                this.setState({
                    showId: false
                })
            }
        });
    }
    onCloseModalEvent = () => {
        this.setState({
            isShowModal: false,
        });
    }

    onCloseStatusModal = () => {
        this.setState({isShowFirstModal: false})
    }

    onChangeFirst = async (trainingId,recommendNum) => {
        console.log(trainingId)
        console.log(recommendNum)

        const {changeRecommend} = this.props;
        await changeRecommend(trainingId,recommendNum);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({isShowFirstModal: false})
        this.onGetData()
    }

    render() {
        const {clubList, loading, teamList, gameList} = this.props;
        console.log("clubList", clubList)
        const {showId, pageNo, pageSize, isShowAddClubModel, isShowChangePlanModel, isShowTeamModal, seletionValue, isShowDetail, detailId, isShowModal,isShowFirstModal} = this.state;
        const defaultValue = seletionValue;
        console.log(isShowModal)
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <ClubFilter dataSource={{}} onCommit={this.onFilterClubEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '名称',
                                    dataIndex: 'name',
                                    key: 'name',
                                    align: 'center',
                                },
                                // {
                                //     title: '首页推荐位',
                                //     dataIndex: 'recommend',
                                //     key: 'recommend',
                                //     align: 'center',
                                //     render: (text) => (
                                //         <div>
                                //             {text?text:"-"}
                                //         </div>
                                //     )
                                // },
                                {
                                    title: '推荐优先级',
                                    dataIndex: 'priority',
                                    key: 'priority',
                                    align: 'center',
                                    render: (text) => (
                                        <div>
                                            {this.renderClubType(text)}
                                        </div>
                                    )
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'clubType',
                                    key: 'clubType',
                                    align: 'center',
                                    render: (text) => (
                                        <div>
                                            {this.renderClubType(text)}
                                        </div>
                                    )
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'game',
                                    key: 'game',
                                    align: 'center',
                                    render: (row, text) => (
                                        <span>{gameList.filter((item) => item.code == text.game).length > 0 ? gameList.filter((item) => item.code == text.game)[0].gameName : ""}</span>
                                    ),
                                },
                                {
                                    title: '状态',
                                    dataIndex: 'state',
                                    key: 'state',
                                    align: 'center',
                                    render: (text, {id}) => (
                                        <div>
                                            {this.renderState(text)}
                                        </div>
                                    )
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {
                                                this.renderOpt(text)
                                            }
                                        </div>
                                    ),
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={clubList.data ? clubList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: clubList ? clubList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            onShowSizeChange: this.onPageOnShowSizeChange,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <AddClubModel
                        showId={showId}
                        defaultValue={defaultValue ? defaultValue : []}
                        onSuccess={this.onFormSubmit}
                        visible={isShowAddClubModel}
                        onClose={() => {
                            this.setState({
                                isShowAddClubModel: false,
                            })
                        }}
                    />
                    <ChangeTrailPlanModel
                        showId={showId}
                        clubId={this.state.teamParams.clubId}
                        defaultValue={defaultValue ? defaultValue : []}
                        trailPlanBrands={this.props.trailPlanBrands}
                        onSuccess={this.onTrailPlanFormSubmit}
                        visible={isShowChangePlanModel}
                        onClose={() => {
                            this.setState({
                                isShowChangePlanModel: false,
                            })
                        }}
                    />
                    <Modal
                        title="荣誉记录列表"
                        visible={isShowModal}
                        width={'50%'}
                        footer={null}
                        destroyOnClose
                        onCancel={this.onCloseModalEvent}
                    >
                        <EventRecordModal {...this.state} />
                    </Modal>

                    <TeamModel
                        clubId={this.state.teamParams.clubId}
                        teamList={teamList ? teamList : []}
                        visible={isShowTeamModal}
                        onClose={() => {
                            this.setState({
                                isShowTeamModal: false,
                            })
                        }}
                    />
                    <ClubDetailModel
                        id={detailId}
                        clubId={this.state.teamParams.clubId}
                        //teamList={teamList?teamList:[]}
                        visible={isShowDetail}
                        onClose={() => {
                            this.setState({
                                isShowDetail: false,
                            })
                        }}
                    />
                    <Modal title="操作训练赛首页推荐" visible={isShowFirstModal} footer={null} destroyOnClose
                           onCancel={this.onCloseStatusModal}>
                        <FirstRecommendModal {...this.state} onSubmit={this.onChangeFirst} />
                    </Modal>
                </Card>
            </div>
        );
    }

    //条件查找俱乐部方法
    onFilterClubEvent = async (name, state, game) => {
        let {getClub} = this.props;
        let {pageSize} = this.state
        this.setState({
            pageNo: 1,
            name: name,
            state: state, game: game
        }, () => {
            getClub({page: 1, size: pageSize, name: name, state: state, game: game});
        })
    }

    onGetData = () => {
        const {pageNo, pageSize} = this.state;
        let {getClub, getGame, getTrailPlanBrand} = this.props;
        getClub({page: pageNo, size: pageSize});
        getTrailPlanBrand()
        getGame();
    }
    //操作-删除
    onDeleteClubEvent = (text) => {
        const {deleteClub} = this.props;
        confirm({
            title: '删除?',
            content: '是否删除该条数据',
            okType: 'danger',
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                await deleteClub(text.id);
                message.success('删除成功');
                this.onGetData();
            },
        });
    }
    onFormSubmit = (values) => {
        const {optType} = this.state;
        switch (optType) {
            case operationType.ADD:
                this.onAddClubEvent(values);
                break;
            case operationType.EDIT:
                this.onEditClubEvent(values);
                break;
            default:
        }
    }
    onTrailPlanFormSubmit = async (params) => {
        const {publishTrial} = this.props;
        const {clubId, isShowChangePlanModel} = this.state;
        if (params.beginTime && params.endTime) {
            params.beginTime = utils.formatMomentToTimeStr(params.beginTime);
            params.endTime = utils.formatMomentToTimeStr(params.endTime)
        }

        await publishTrial(clubId, Object.assign(params));
        await this.onGetData();
        message.success('修改成功');
        this.setState({isShowChangePlanModel: !isShowChangePlanModel});
    }

    // 增加俱乐部信息
    onAddClubEvent = async (params) => {
        console.log("addressssss", params)
        const {addClub} = this.props;
        const {isShowAddClubModel} = this.state;
        await addClub(Object.assign(params, {address: {province: params.address[0], city: params.address[1]}}));
        await this.onGetData();
        message.success('新增俱乐部成功');
        this.setState({isShowAddClubModel: !isShowAddClubModel});
    }

    // 增加赛事信息
    onAddSaiEvent = async (awardDate, name, grade) => {
        console.log(awardDate, name, grade)
        const {addEvent} = this.props;
        const {isShowModal, clueId} = this.state;
        await addEvent(clueId, awardDate, name, grade);
        //await this.onGetData();
        message.success('新增赛事信息成功');
        this.setState({isShowModal: !isShowModal});
    }

    // 编辑俱乐部信息
    onEditClubEvent = async (params) => {
        const {seletionValue, isShowAddClubModel} = this.state;
        const {editClub} = this.props;
        await editClub(seletionValue.id, Object.assign(params, {
            address: {
                province: params.address[0],
                city: params.address[1]
            }
        }));
        await this.onGetData();
        message.success('修改成功');
        this.setState({isShowAddClubModel: !isShowAddClubModel});
    }

    //删除试训计划
    onDeleteTrainPlan = async (id) => {
        const {pageNo, pageSize} = this.state;
        const {getClub,deleteTrialPlan} = this.props;
        try {
            await deleteTrialPlan(id);
            message.success( '删除成功');
        }catch (e) {
            console.log(e);

        }
        getClub({page: pageNo, size: pageSize});
    }

    //恢复试训计划
    onReplyTrainPlan = async (id) => {
        const {pageNo, pageSize} = this.state;
        const {getClub,replyTrialPlan} = this.props;
        try {
            await replyTrialPlan(id);
            message.success( '恢复成功');
        }catch (e) {
            console.log(e);

        }
        getClub({page: pageNo, size: pageSize});
    }

    onChangePlanEvent = async (id, seletionValue) => {
        const {getTrailDetail} = this.props;
        let showId = false;
        try {
            seletionValue = await getTrailDetail(id);
            this.setState({
                isShowChangePlanModel: true,
                clubId: id,
            }, () => {
                this.setState({
                    showId: showId,
                    seletionValue
                })
            });
        } catch (e) {
            if (e && e.code === "TRAIL_PLAN_NOT_EXIST") {
                this.setState({
                    isShowChangePlanModel: true,
                    clubId: id,
                }, () => {
                    this.setState({
                        showId: true,
                        seletionValue
                    })
                });
            } else {
                seletionValue = null;
                showId = true;
            }
        }

    }

    //战队列表
    onOpenTeamModalEvent = (id) => {
        let {getTeam} = this.props
        let {teamParams} = this.state
        teamParams.clubId = id
        this.setState({
            teamParams,
            isShowTeamModal: true,
        }, () => {
            getTeam(teamParams)
        })
    }


    //翻页
    onPageChangeEvent = (current, pageSize) => {
        const {getClub} = this.props;
        const {name, state, game} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        }, () => {
            getClub({page: current, size: pageSize, name: name, state: state, game: game});
        });
    }

    onPageOnShowSizeChange = (current, pageSize) => {
        const {getClub} = this.props;
        getClub({page: current, size: pageSize});
    }


    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.viewClub,

        }
    }
);


const mapDispatchToProps = {
    getClub,
    deleteClub,
    addClub,
    editClub,
    getTeam,
    bindClub,
    untyingClub,
    getTrial,
    getTrailDetail,
    publishTrial,
    getClubDetail,
    getGame,
    addEvent,
    changeRecommend,
    deleteTrialPlan,
    replyTrialPlan,
    getTrailPlanBrand
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ClubManagement)));
