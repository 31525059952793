import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, Table, Row, Col, notification, message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getLotteryRecord,getLotteryHarvest,updatePrizeOrderShip} from "../../modules/lottery/action";
import OrderFilter from '../prizes-order-management/OrderFilter';
import {operationTypeValue} from 'modules/lottery/lotteryEnum';
import AddressForm from './components/AddressForm';
import ShipForm from './components/ShipForm';
const {confirm} = Modal;


class PrizesOrderManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            modalTitle: '',
            loading: false,
            seletionValue: null,
            operationType: operationTypeValue.VIEW, // 默认操作类型为查看
            showEditOrderModal: false,
        };
    }

    componentWillMount(){
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const { getLotteryRecord} = props;
        const {pageNo, pageSize} = this.state;
        this.setState({
            loading: true
          })
        await getLotteryRecord(
            pageNo, pageSize,
        );
    }
    
    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getLotteryRecord} = this.props;
        let {username, turnTablePrizeType, shippingStatus} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getLotteryRecord(current, pageSize,username, turnTablePrizeType, shippingStatus);
        
    }

    //条件查找动态方法
    onFilterOrderEvent = async (username, turnTablePrizeType, shippingStatus) => {
        let {getLotteryRecord} = this.props;
        const {pageSize,} = this.state;
        this.setState({
            username, turnTablePrizeType, shippingStatus,
            pageNo: 1
        });
        getLotteryRecord(1, pageSize, username, turnTablePrizeType, shippingStatus);
    };

    confirm = (data) => {
        let _this = this;
        confirm({
            content: '确认删除吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.deleteTopicEvent(data.id)
            },
            onCancel() {
            },

        });
    }
    
    onOpenEditOrderModalEvent = (text, type) => {
        const { getLotteryHarvest } = this.props;
        getLotteryHarvest(text.awardRecordId);
        let title = '';
        if(type === operationTypeValue.VIEW) {
            title = '查看收货地址';
        } else if(type === operationTypeValue.SEND) {
            title = text.shippingStatus ? '编辑物流信息' : '发货';
        }             
        this.setState({
            modalTitle: title,
            showEditOrderModal: true,
            seletionValue: text,
        },()=>{
            this.setState({
                operationType: type,
            });
        });
    }

    onCloseEditOrderModalEvent = () => {
        this.setState({
            showEditOrderModal: false
        });
    }

    // 编辑物流信息
    onEditShipEvent = async (params) => {
        const {seletionValue} = this.state;
        const {updatePrizeOrderShip} = this.props;
        try {
            await updatePrizeOrderShip(seletionValue.awardRecordId, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false
            });
            this.onInitData();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const {lotteryRecordList, lotteryHarvest, loading} = this.props;
        const {pageNo, pageSize,showEditOrderModal, operationType, modalTitle,seletionValue} = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <OrderFilter dataSource={{}} onCommit={this.onFilterOrderEvent}/>
                </Card>

                <Card style={{width: 'auto'}}>
                    <Table

                        columns={
                            [
                                {
                                    title: '订单编号',
                                    dataIndex: 'awardRecordId',
                                    key: 'awardRecordId',
                                    align: 'center',
                                },
                                {
                                    title: '奖品名称',
                                    dataIndex: 'giftName',
                                    key: 'giftName',
                                    align: 'center',
                                },
                                {
                                    title: '奖品类型',
                                    dataIndex: 'turnTablePrizeType',
                                    key: 'turnTablePrizeType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.turnTablePrizeType === 'IN_KIND_GIFT' ? '实物周边' : ''}
                                            {text.turnTablePrizeType === 'VIP' ? 'VIP会员（虚拟礼品）' : ''}
                                            {text.turnTablePrizeType === 'ENERGY' ? '能量值（虚拟礼品）' : ''}
                                            {text.turnTablePrizeType === 'GOLD' ? '紫金（虚拟礼品）' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                },
                                {
                                    title: '中奖时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '发货状态',
                                    dataIndex: 'shippingStatus',
                                    key: 'shippingStatus',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.shippingStatus ? '已发货' : '未发货'}</div>
                                    )
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        if(text.turnTablePrizeType == 'IN_KIND_GIFT'){
                                            return <div>                                          
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.VIEW)}
                                                        color="blue">查看收货地址</Button>
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.SEND)}
                                                        color="blue">{text.shippingStatus ? '编辑物流信息' : '发货'}</Button>
                                            </div>
                                        }
                                    }
                                },
                            ]
                        }

                        dataSource={lotteryRecordList.data ? lotteryRecordList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: lotteryRecordList ? lotteryRecordList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={modalTitle}
                        visible={showEditOrderModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditOrderModalEvent}
                        width={"30%"}
                    >
                        {operationType === operationTypeValue.VIEW ?
                            <AddressForm {...lotteryHarvest}
                                        onSubmitEvent={this.onEditAddressEvent}/>
                            : operationType === operationTypeValue.SEND && <ShipForm {...seletionValue} onSubmitEvent={this.onEditShipEvent}/>}                    
                    </Modal>               
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.Lottery
        }
    }
);

const mapDispatchToProps = {
    getLotteryRecord,
    getLotteryHarvest,
    updatePrizeOrderShip,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(PrizesOrderManagement)));