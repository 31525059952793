import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import {stateType,} from '../../../modules/partner-training/enums';

const FormItem = Form.Item;

class PartnerTrainingGoodsFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        const {getGameList} = props;
        getGameList();
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                console.log(onCommit)
                let {goodsName, game, goodsModel, state} = values;
                onCommit && onCommit(goodsName, game, goodsModel, state);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {games} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="商品名称">
                    {getFieldDecorator('goodsName', {}
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="游戏类型">
                    {getFieldDecorator('game', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{width: 120}} >
                            <Select.Option value="">全部</Select.Option>
                            {games.map((item) => <Select.Option value={item.code}
                                                         key={item.code}>{item.gameName}</Select.Option>)}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="商品模式">
                    {getFieldDecorator('goodsModel', {initialValue: ''}
                    )(
                        <Select style={{width: '100px'}} >
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="TIME_CHARGE">按小时收费</Select.Option>
                            <Select.Option value="GAME_CHARGE">按局数收费</Select.Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label='状态'>
                    {
                        getFieldDecorator('state', {initialValue: ''})(
                            <Select style={{width: '100px'}} >
                                <Select.Option value="">全部</Select.Option>
                                <Select.Option value="true">上架</Select.Option>
                                <Select.Option value="false">下架</Select.Option>
                            </Select>
                        )
                    }
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PartnerTrainingGoodsFilter)));