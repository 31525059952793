import createReducer from 'utils/createReducer';
import {GET_COINS} from "../coin/antionType";



const defaultState = () => ({
    coins: [],
    coinsTotal: 0,
    coinsLoading: false,
    coinsInfo: {},
    adminRoles: [], // 用户所拥有的角色
});


const onGetCoinsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        coins:payload

    }

};



export default createReducer(defaultState, {
    [`${GET_COINS}_SUCCESS`]: onGetCoinsRequest,
});
