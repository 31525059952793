import React from 'react';
import {Form, Input, Modal, Select, message} from 'antd';
import {fromJS, is} from 'immutable';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UserSearch from "./UserSearch";
import {getUsers} from "../../../modules/user/action";


const {confirm} = Modal;
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 9},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class PartnerTrainingTeacherModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            user: null,
            submitbutton: false
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            defaultValue,
        });

        const {getGameList} = props;
        getGameList();
    }

    onSubmit = () => {
        let {user} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (user) {
                    values["userId"] = user.id
                }
                const {onSuccess, operationType} = this.props;
                if (operationType === operationTypeValue.INCREASE) {
                    confirm({
                        content: '确认要添加用户' + values.exhibitionId + '为导师吗?',
                        okText: '确认',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            onSuccess && onSuccess(values);
                        },
                        onCancel() {
                        },
                    });
                } else {
                    onSuccess && onSuccess(values);
                }

            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    //前置条件
    onUserSearchSubmit = async (params) => {
        const {resetFields} = this.props.form;
        resetFields();
        this.addUserToPartnerTeacher(params)
    }

    addUserToPartnerTeacher = (params) => {
        let {users} = this.props
        console.log(users)
        let {getUsers} = this.props;
        getUsers(params.exhibitionId)
            .then(res => {
                if (res.total > 0) {
                    if (res.data[0].state === 'BAN') {
                        message.error("该账号已被冻结")
                        this.setState({
                            user: null
                        })
                    } else {
                        this.setState({
                            user: res.data[0]
                        })
                    }
                } else {
                    message.error("用户不存在")
                    this.setState({
                        user: null
                    })
                }
            })
            .catch(e => {
                console.log(e)
            });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {value, visible, operationType, games} = this.props;
        const {user} = this.state;
        const defaultValue = value || {};
        console.log(defaultValue)
        return (
            <div>
                <Modal
                    width={'30%'}
                    visible={visible}
                    title={operationType === operationTypeValue.UPDATE ? "修改导师" : operationType === operationTypeValue.INCREASE ? "添加导师" : "添加导师"}
                    onCancel={this.props.onClose}
                    cancelText={"取消"}
                    onOk={this.onSubmit}
                    okText={operationType === operationTypeValue.UPDATE ? "确认" : operationType === operationTypeValue.INCREASE ? "确认" : "确认"}
                >
                    {operationType === operationTypeValue.UPDATE ? null :
                        <UserSearch dataSource={{}} onCommit={this.onUserSearchSubmit}/>}
                    <Form>
                        {operationType === operationTypeValue.UPDATE ? null : <div><FormItem
                            label="导师ID"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('exhibitionId', {
                                initialValue: user ? user.exhibitionId : '',
                                rules: [{required: true, message: '导师ID不能为空!'}],
                            })(
                                <Input placeholder="请选择导师" disabled={true}/>,
                            )}
                        </FormItem>
                            <FormItem
                                label="手机号码"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('telephone', {
                                    initialValue: user ? user.telephone : '',
                                    rules: [{required: true, message: '手机号码不能为空!'}],
                                })(
                                    <Input placeholder="请选择导师" disabled={true}/>,
                                )}
                            </FormItem>

                            <FormItem
                                label="游戏类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('game', {
                                    initialValue: games.filter((item) => item.code === value.game).length > 0 ? games.filter((item) => item.code === value.game)[0].code : undefined,
                                    rules: [{required: true, message: '请选择游戏类型!'}],
                                })(
                                    <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                                        {games.map((item) => <Select.Option value={item.code}
                                                                            key={item.code}>{item.gameName}</Select.Option>)}
                                    </Select>,
                                )}
                            </FormItem></div>
                        }

                        <FormItem label="性别"
                                  {...formItemLayout}>
                            {getFieldDecorator('gender', {
                                    initialValue: defaultValue ? defaultValue.gender : '',
                                    rules: [{required: true, message: '性别不能为空!'}],
                                }
                            )(
                                <Select placeholder="请选择性别" style={{width: 150}}>
                                    <Option value={'MALE'}>GG</Option>
                                    <Option value={'FEMALE'}>MM</Option>
                                </Select>
                            )}
                        </FormItem>


                        <FormItem label="游戏段位"
                                  {...formItemLayout}>
                            {getFieldDecorator('position', {
                                    initialValue: defaultValue ? defaultValue.position : '',
                                    rules: [{required: true, message: '游戏段位不能为空!'}],
                                }
                            )(
                                <Select placeholder="请选择游戏段位" style={{width: 150}}>
                                    <Option value={'不朽星钻'}>不朽星钻</Option>
                                    <Option value={'荣耀皇冠'}>荣耀皇冠</Option>
                                    <Option value={'超级王牌'}>超级王牌</Option>
                                    <Option value={'无敌战神'}>无敌战神</Option>
                                </Select>
                            )}
                        </FormItem>
                        <FormItem label="KD实力"
                                  {...formItemLayout}>
                            {getFieldDecorator('kda', {
                                    initialValue: defaultValue ? defaultValue.kda : '',
                                    rules: [{required: true, message: 'KD实力不能为空!'}],
                                }
                            )(
                                <Select placeholder="请选择KD实力值" style={{width: 150}}>
                                    <Option value={'8.0'}>8.0</Option>
                                    <Option value={'9.0'}>9.0</Option>
                                    <Option value={'10.0'}>10.0</Option>
                                    <Option value={'11.0'}>11.0</Option>
                                    <Option value={'12.0'}>12.0</Option>
                                    <Option value={'13.0'}>13.0</Option>
                                    <Option value={'14.0'}>14.0</Option>
                                    <Option value={'15.0'}>15.0</Option>
                                </Select>
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.user
    }
};
const mapDispatchToProps = ({
    getGameList,
    getUsers
});
PartnerTrainingTeacherModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PartnerTrainingTeacherModel)));