import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, Tag, Popconfirm, message} from 'antd';
import {connect} from 'react-redux';
import ReportFilter from "../ReportFilter";
import '../index.scss';
import {withRouter} from 'react-router-dom';
import {
    getCommentReports,
    CommentAssign
} from "../../../modules/report-new/action";

class ReportManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            params:{},
            imageUrl: [],
            isShowAssignAdminRoleModal: false,
            isShowImageModal: false,
        };
    }

    handleOk = e => {
        this.setState({
            isShowImageModal: false,
        })
    };

    handleCancel = e => {
        this.setState({
            isShowImageModal: false,
        })
    }

    componentDidMount() {

        const {page, size,params} = this.state;
        let {getCommentReports} = this.props;
        getCommentReports({page, size,...params});
    }

    showImageUrl = (imageUrl) => {
        console.log(imageUrl);
        if (imageUrl === null) {
            this.setState({
                isShowImageModal: true,
                imageUrl:[]
            });
        } else {
            this.setState({
                isShowImageModal: true,
                imageUrl: imageUrl
            });
        }
    }

assign = async (id, result1) => {
    const {page, size,params} = this.state;
    try {
        const {CommentAssign, getCommentReports} = this.props;
        await CommentAssign(id, result1);
        message.success('操作成功')
        getCommentReports({page, size,...params});
    } catch (e) {
        message.error(e)
        console.log(e);
    }
}


onFilterReportEvent = async (params) => {
    let {getCommentReports} = this.props;
    const {page, size} = this.state;
    getCommentReports({page:1, size, ...params});
    this.setState({
        page: 1,
        params:{...params}
    })
}

onPageChangeEvent = (current, size) => {
    let {getCommentReports} = this.props;
    let {params} = this.state;
    this.setState({
        page: current,
        size: size,
    });
    getCommentReports({page:current, size,...params});
}


    render() {
        const {
            commentReports, 
            loading
        } = this.props;
        const {
            page, size
        } = this.state;
        return (
            <div>
               <Card style={{marginBottom: 30}}>
                    <ReportFilter dataSource={{}} onCommit={this.onFilterReportEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '举报者ID',
                                    dataIndex: 'prosecutorExId',
                                    key: 'prosecutorExId',
                                    align: 'center',
                                    //width:'100px',
                                },
                                {
                                    title: '举报者用户名',
                                    dataIndex: 'prosecutorName',
                                    key: 'prosecutorName',
                                    align: 'center',
                                    //width:'150px',
                                },
                                {
                                    title: '被举报者ID',
                                    dataIndex: 'violatorExId',
                                    key: 'violatorExId',
                                    align: 'center',
                                    //width:'100px',
                                },
                                {
                                    title: '被举报者用户名',
                                    dataIndex: 'violatorName',
                                    key: 'violatorName',
                                    align: 'center',
                                   // width:'150px',
                                },
                                {
                                    title: '举报类型',
                                    dataIndex: 'violateType',
                                    key: 'violateType',
                                    align: 'center',
                                    //width:'150px',
                                },
                                
                                {
                                    title: '被举报评论内容',
                                    dataIndex: 'violateContent',
                                    key: 'violateContent',
                                    align: 'center',
                                    //width:'250px',
                                },
                                {
                                    title: '举报描述',
                                    dataIndex: 'description',
                                    key: 'description',
                                    align: 'center',
                                    //width:'400px',
                                },
                                {
                                    title: '举报证件截图',
                                    dataIndex: 'imageUrls',
                                    key: 'imageUrls',
                                    align: 'center',
                                    //width:'150px',
                                    render: (imageUrls, record) => {
                                        return (imageUrls && imageUrls.length >0) ? <Button type="primary" color="blue"
                                                       onClick={() => this.showImageUrl(imageUrls)}>查看</Button> : '无';
                                    }
                                },
                                {
                                    title: '举报时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                    //width:'200px',
                                },
                                {
                                    title: '处理情况',
                                    dataIndex: 'processResult',
                                    key: 'processResult',
                                    align: 'center',
                                    //width:'150px',
                                    render: (resultInt, record) => {
                                        if (resultInt === 'INIT') {
                                            return <Tag color={"red"}>未处理</Tag>
                                        } else {
                                            if (resultInt === 'CLOSE') {
                                                return <Tag>不予处理</Tag>
                                            }
                                            if (resultInt === 'COMPLETE') {
                                                return <Tag color={"green"}>已处理</Tag>
                                            }
                                        }
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    //fixed: 'right',
                                    align: 'center',
                                    //width:'200px',
                                    render: (text, record) => {
                                        if (record.processResult ==='INIT') {
                                            return <div><Popconfirm title={`请选择处理结果`}
                                                                    onConfirm={() => this.assign(record.id, "COMPLETE")}
                                                                    onCancel={() => this.assign(record.id, "CLOSE")}
                                                                    okText="已处理" cancelText="不予处理">
                                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} color={"#2db7f5"}>处理</Button>
                                            </Popconfirm></div>;
                                        }else{
                                            return(
                                                <div>已操作</div>
                                            )
                                        }
                                    }
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={commentReports.data ? commentReports.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: commentReports ? commentReports.total : 0,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                        scroll={{ x: 1300 }} 
                    />
                </Card>
                <Modal
                    title="图片查看"
                    visible={this.state.isShowImageModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={"600px"}
                    footer={
                        [] // 设置footer为空，去掉 取消 确定默认按钮
                    }

                >
                    {this.state.imageUrl.map((image) => {
                            return <div style={{marginBottom:20}}><img align={"center"} alt={""} width={"400px"} src={image ? image+'?x-oss-process=image/resize,w_400' : ''}/></div>
                        })}
                </Modal>
            </div>
        )
            ;

    }

// --------页面事件结束---------
}

const mapStateToProps = (state => {
        return {
            ...state.ReportNew
        }

    }

);

const mapDispatchToProps = {
    getCommentReports,
    CommentAssign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ReportManagement)));