import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, Popconfirm, Popover, Row, Table, Tabs, Tag} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/team/enums';
import {
  createTeam,
  disbandTeam,
  getGameList,
  getTeamInfo,
  getTeamList,
  queryTeamsBusiness,
  updateTeam,
  updateTeamGameStatus
} from "../../modules/team/action";
import TeamInfoModel from "./components/TeamInfoModel";
import ForbidTeamModel from "./components/ForbidTeamModel";
import TeamMembersModel from "./components/TeamMembersModel";
import TeamFilter from "./components/TeamFilter";
import {getBrandAll, liftGroup,updateKickPermissions,updateLiftPermissions} from "../../modules/training/action";
import {Select} from "antd/lib/select";

const {confirm} = Modal;
const {TabPane} = Tabs;

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previewVisible: false,
      previewImage: '',
      isShowTeamInfoModel: false, // 战队信息
      isShowForbidTeamModel: false,
      isShowTeamMembersModel: false,
      seletionValue: null,
      operationType: operationTypeValue.INCREASE,
      teamShowId: '',
      clubId: '',
      aliasName:'',
      teamName: '',
      game: '',
      stopGame: '',
      state: 'true',
      page: 1,
      size: 15,
      randIndex: 0,
      indexVisible: false,
    };
  }

  componentWillMount() {
    let {getBrandAll} = this.props;
    getBrandAll({page: 1, size: 100000});
    this.onGetTableData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.teams !== this.props.teams) {
      this.setState({
        loading: false
      })
    }
  }

  confirmReleaseGame = (id, value) => {
    let _this = this;
    confirm({
      content: '确认解除禁赛吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.onReleaseGameStatus(id, value)
      },
      onCancel() {
      },

    });
  }
  //解除战队禁赛
  onReleaseGameStatus = async (id, value) => {
    const {updateTeamGameStatus} = this.props;
    await updateTeamGameStatus(id, value);
    await this.onGetTableData();
    message.success('编辑成功');
  }

  onFormSubmit = (values) => {
    const {operationType} = this.state;
    switch (operationType) {
      case operationTypeValue.INCREASE:
        this.onCreateTeamEvent(values);
        break;
      case operationTypeValue.UPDATE:
        this.onUpdateTeamEvent(values);
        break;
      default:
    }
  }

  // 新增战队信息
  onCreateTeamEvent = async (params) => {
    const {isShowTeamInfoModel} = this.state;
    const {createTeam} = this.props;
    await createTeam(params);
    await this.onGetTableData();
    message.success('新增成功');
    this.setState({isShowTeamInfoModel: !isShowTeamInfoModel});
  }

  // 编辑战队信息
  onUpdateTeamEvent = async (params) => {
    const {seletionValue, isShowTeamInfoModel} = this.state;
    const {updateTeam} = this.props;
    await updateTeam(params, seletionValue.id);
    await this.onGetTableData();
    message.success('编辑成功');
    this.setState({isShowTeamInfoModel: !isShowTeamInfoModel});
  }


  clickRand = async (id) => {
    let {updateTeam} = this.props;
    let {randIndex} = this.state;
    await updateTeam({randIndex}, id);
    await this.onGetTableData();
    message.success('修改成功');
  }

  //编辑战队禁赛信息
  onForbidTeamEvent = async (params) => {
    const {seletionValue, isShowForbidTeamModel} = this.state;
    const {updateTeamGameStatus} = this.props;
    params["stopGame"] = !seletionValue.stopGame;
    await updateTeamGameStatus(seletionValue.id, params);
    await this.onGetTableData();
    message.success('禁赛成功');
    this.setState({isShowForbidTeamModel: !isShowForbidTeamModel});
  }

  //解散战队
  onDisBandTeamEvent = async (id) => {
    const {isShowTeamMembersModel} = this.state;
    const {disbandTeam} = this.props;
    await disbandTeam(id);
    await this.onGetTableData();
    message.success('解散成功');
    this.setState({isShowTeamMembersModel: !isShowTeamMembersModel});
  }

  //刷新界面数据
  onGetTableData = async () => {
    const {getTeamList} = this.props;
    const {page, size} = this.state;
    this.setState({
      loading: true
    })
    await getTeamList({
      page, size,
      teamShowId: this.state.teamShowId ? this.state.teamShowId : null,
      clubId: this.state.clubId ? this.state.clubId : null,
      aliasName: this.state.aliasName ? this.state.aliasName : null,
      teamName: this.state.teamName ? this.state.teamName : null,
      game: this.state.game ? this.state.game : null,
      stopGame: this.state.stopGame ? this.state.stopGame : null,
      state: this.state.state ? this.state.state : null,
      admin: true,
    });
  }

  onPageChangeEvent = async (current, pageSize) => {
    const {getTeamList} = this.props;
    this.setState({
      page: current,
      size: pageSize,
      loading: true
    })
    await getTeamList({
      page: current, size: pageSize,
      teamShowId: this.state.teamShowId ? this.state.teamShowId : null,
      clubId: this.state.clubId ? this.state.clubId : null,
      teamName: this.state.teamName ? this.state.teamName : null,
      game: this.state.game ? this.state.game : null,
      stopGame: this.state.stopGame ? this.state.stopGame : null,
      state: this.state.state ? this.state.state : null,
      admin: true,
    });
  }


  //条件查找动态方法
  onFilterTeamEvent = async (teamShowId, clubId, aliasName, teamName, game, stopGame) => {
    let {getTeamList} = this.props;
    const {size} = this.state;
    this.setState({
      loading: true,
      page: 1,
      teamShowId: teamShowId ? teamShowId : null,
      clubId: clubId ? clubId : null,
      aliasName: aliasName ? aliasName : null,
      teamName: teamName ? teamName : null,
      game: game ? game : null,
      stopGame: stopGame ? stopGame : null,
    })
    await getTeamList({
      page: 1,
      size: size,
      teamShowId: teamShowId ? teamShowId : null,
      clubId: clubId ? clubId : null,
      aliasName: aliasName ? aliasName : null,
      teamName: teamName ? teamName : null,
      game: game ? game : null,
      stopGame: stopGame ? stopGame : null,
      state: this.state.state ? this.state.state : null,
      admin: true,
    });
  }


  changeTabPane = async (key) => {
    console.log(key)
    const {size} = this.state;
    const {getTeamList} = this.props;
    this.setState({
      loading: true,
      state: key ? key : null,
      page: 1,
      teamShowId: null,
      clubId: null,
      teamName: null,
      game: null,
      stopGame: null
    })
    await getTeamList({page: 1, size, state: key, admin: true});
  }


  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>
        </Col>
    </Row>
  )

  renderTabPane = () => {
    const {
      teams, games, brandData
    } = this.props;
    console.log(teams)
    const {
      operationType, isShowTeamInfoModel, isShowForbidTeamModel, isShowTeamMembersModel, seletionValue, page, size, loading, previewVisible, previewImage,
    } = this.state;
    const defaultValue = operationType === operationTypeValue.UPDATE && seletionValue;
    return (
      <div>
          <Card style={{marginBottom: 30, width: 'auto'}}>
              <TeamFilter dataSource={{}} onCommit={this.onFilterTeamEvent}/>
          </Card>
          <Card style={{width: 'auto'}}>
              <Table
                dataSource={teams ? teams.data : []}
                title={this.state.state === 'true' ? this.renderTableTitle : null}
                columns={this.getTableColumns(games, brandData)}
                bordered
                scroll={{ x: 2000, y: 650 }}
                pagination={{
                  // total: teams ? teams.total : 0,
                  total: teams.total,
                  current: page,
                  pageSize: size,
                  onChange: this.onPageChangeEvent,
                  showTotal: (total) => {
                    return `总计 ${total}条数据`;
                  }
                }}
                loading={loading}
              />
            {isShowTeamInfoModel ? <TeamInfoModel
              value={defaultValue}
              operationType={operationType}
              onSuccess={this.onFormSubmit}
              visible={isShowTeamInfoModel}
              onClose={() => {
                this.setState({
                  isShowTeamInfoModel: false,
                });
              }}
            /> : null}
            {isShowForbidTeamModel ? <ForbidTeamModel
              onSuccess={this.onForbidTeamEvent}
              visible={isShowForbidTeamModel}
              onClose={() => {
                this.setState({
                  isShowForbidTeamModel: false,
                });
              }}
            /> : null}
            {isShowTeamMembersModel ? <TeamMembersModel
              visible={isShowTeamMembersModel}
              onSuccess={this.onDisBandTeamEvent}
              value={seletionValue}
              onClose={() => {
                this.setState({
                  isShowTeamMembersModel: false,
                });
              }}
            /> : null}
          </Card>

        {previewVisible ? <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{width: '100%'}} src={previewImage}/>
        </Modal> : null}
      </div>
    )
  }

  render() {
    return (
      <div>
          <Tabs onChange={this.changeTabPane} type="card">
              <TabPane tab="战队列表(正常)" key='true'>
                {this.state.state === 'true' ? this.renderTabPane() : null}
              </TabPane>
              <TabPane tab="战队列表(解散)" key='false'>
                {this.state.state === 'false' ? this.renderTabPane() : null}
              </TabPane>
          </Tabs>
      </div>
    );
  }


  onBtnClick = (type, seletionValue) => {
    this.setState({
      operationType: type,
      isShowTeamInfoModel: true,
      seletionValue: seletionValue,
    });
  }

  onGetTeamInfoEvent = async (value, type) => {
    const {isShowTeamInfoModel} = this.state;
    this.setState({
      operationType: type,
      seletionValue: value,
      isShowTeamInfoModel: !isShowTeamInfoModel,
    });
  }

  onStopGameStatusEvent = async (value) => {
    const {isShowForbidTeamModel} = this.state;
    this.setState({
      seletionValue: value,
      isShowForbidTeamModel: !isShowForbidTeamModel,
    });
  }

  onGetTeamMembersEvent = async (value) => {
    const {isShowTeamMembersModel} = this.state;
    this.setState({
      isShowTeamMembersModel: !isShowTeamMembersModel,
      seletionValue: value
    });
  }

  onKickPermissionsEvent =  async (params) => {
    let _this = this;
    let content = params.status? "开放踢人权限" : "取消踢人权限";
    confirm({
      content: `确认要${content}吗?`,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.onKickPermissions(params)
      },
      onCancel() {
      },
    });
  }

  onKickPermissions = async (params) => {
    const {updateKickPermissions} = this.props;
    await updateKickPermissions(params);
    await this.onGetTableData();
  }

  onLiftPermissionsEvent =  async (params) => {
    let _this = this;
    let content = params.status? "开放选手升降权限":"取消选手升降权限";
    confirm({
      content: `确认要${content}吗?`,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.onLiftPermissions(params)
      },
      onCancel() {
      },
    });
  }

  onLiftPermissions = async (params) => {
    const {updateLiftPermissions} = this.props;
    await updateLiftPermissions(params);
    await this.onGetTableData();
  }



  //升级
  onLiftGroupUpEvent = async (params) => {
    let _this = this;
    confirm({
      content: '确认要升级吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.onLiftGroupUp(params)
      },
      onCancel() {
      },
    });
  }
  onLiftGroupUp = async (params) => {
    const {liftGroup} = this.props;
    await liftGroup(params);
    await this.onGetTableData();
  }

  //降级
  onLiftGroupDownEvent = async (params) => {
    let _this = this;
    confirm({
      content: '确认要降级吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.onLiftGroupDown(params)
      },
      onCancel() {
      },
    });
  }

  onLiftGroupDown = async (params) => {
    const {liftGroup} = this.props;
    await liftGroup(params);
    await this.onGetTableData();
  }

  handleCancel = () => this.setState({previewVisible: false});
  showPreviewImage = (value) => {
    this.setState({previewImage: value, previewVisible: true});
  }

  getTableColumns = (games, brandData) => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 100,
      fixed: 'left',
    },
    // {
    //     title: '排序',
    //     dataIndex: 'randIndex',
    //     key: 'randIndex',
    //     align: 'center',
    //     width: 100
    // },
    {
      title: '训练赛组别',
      dataIndex: 'groupLevel',
      key: 'groupLevel',
      align: 'center',
      width: 100,
      render: (row, text) => {
        return (
          text.groupLevel ?
            brandData.data && brandData.data.filter((brand) => brand.id == text.groupLevel).length > 0 ? brandData.data.filter((brand) => brand.id == text.groupLevel)[0].name : ''
            : null
        )
      },
    },
    {
      title: '展示ID',
      dataIndex: 'showId',
      align: 'center',
      width: 100,
      key: 'showId',
    }, {
      title: '名称',
      dataIndex: 'aliasName',
      align: 'center',
      width: 100,
      key: 'aliasName',
    }, {
      title: '标签',
      dataIndex: 'name',
      align: 'center',
      width: 100,
      key: 'name',
    }, {
      title: '白底LOGO',
      dataIndex: 'whiteLogo',
      align: 'center',
      width: 150,
      key: 'whiteLogo',
      render: (row, text) => (
        <div>
            <img id="myImage" alt={""}
                 src={text.whiteLogo ? text.whiteLogo + '?x-oss-process=image/resize,w_100' : ""} width="100"
                 height="100"
                 onClick={() => this.showPreviewImage(text.whiteLogo)}/>
        </div>
      ),
    }, {
      title: '黑底LOGO',
      dataIndex: 'blackLogo',
      align: 'center',
      width: 150,
      key: 'blackLogo',
      render: (row, text) => {
        return (
          <div>
              <img id="myImage"
                   src={text.blackLogo ? text.blackLogo + '?x-oss-process=image/resize,w_100' : ""} alt={""}
                   width="100" height="100"
                   onClick={() => this.showPreviewImage(text.blackLogo)}/>
          </div>
        )
      }

    }, {
      title: '游戏类型',
      dataIndex: 'game',
      align: 'center',
      key: 'game',
      width: 100,
      render: (row, text) => (
        <span>{games && games.filter((item) => item.code == text.game).length > 0 ? games.filter((item) => item.code == text.game)[0].gameName : ""}</span>
      ),
    },
    //     {
    //     title: '俱乐部ID',
    //     dataIndex: 'clubId',
    //     align: 'center',
    //     width: 100,
    //     key: 'clubId',
    // },
    {
      title: '商业估值',
      dataIndex: 'businessValue',
      align: 'center',
      width: 100,
      key: 'businessValue',
      // render: (row, text) => (
      //     <GetTeamBusinessModel teamId={text.id}/>
      // ),
    }, {
      title: '禁赛',
      dataIndex: 'stopGame',
      align: 'center',
      width: 100,
      key: 'stopGame',
      render: (row, text) => (
        <Tag color={text.stopGame ? "red" : "blue"}>{text.stopGame ? "是" : "否"}</Tag>
      ),
    }, {
      title: '解禁时间',
      dataIndex: 'releaseTime',
      align: 'center',
      width: 150,
      key: 'releaseTime',
    }, {
      title: '封禁品牌赛事',
      dataIndex: 'bannedBrands',
      align: 'center',
      width: 200,
      key: 'bannedBrands',
      render: (row, text) => {
        return (
          text.bannedBrands && text.bannedBrands.length > 0 ? text.bannedBrands.map(item => {
            return <div>{brandData.data && brandData.data.filter((brand) => brand.id == item).length > 0 ? brandData.data.filter((brand) => brand.id == item)[0].name : ''}</div>
          }) : text.stopGame ? "全部禁赛" : null
        )
      },
    }, {
      title: '封禁备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: 100,
      render: (remark, record) => {
        return (
          remark ? <div>
              <Popover content={remark}>
                  <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}>查看</Button>
              </Popover>
          </div> : null
        )
      }
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: 150,
      key: 'createTime'
    }, {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      width: 100,
      key: 'state',
      render: (row, text) => (
        <span>{text.state ? '正常' : '解散'}</span>
      ),
    }, {
      title: '操作',
      dataIndex: 'details',
      key: 'details',
      // fixed: 'right',
      align: 'center',
      fixed: 'right',
      width: 350,
      render: (row, text) => {
        let {indexVisible} = this.state;
        return (
          <div>

            {/*<Popconfirm*/}
            {/*    title={*/}
            {/*        indexVisible ?*/}
            {/*            <div><span style={{color: "red"}}>请输入1-100（越小优先级越高）</span><br/><InputNumber*/}
            {/*                style={{width: 260}} placeholder={"请输入1-100（越小优先级越高）"}*/}
            {/*                defaultValue={text.randIndex}*/}
            {/*                onChange={(e) => {*/}
            {/*                    this.setState({randIndex: e})*/}
            {/*                }}/></div> : null}*/}
            {/*    okType="primary"*/}
            {/*    icon={null}*/}
            {/*    onConfirm={() => {*/}
            {/*        this.clickRand(text.id)*/}
            {/*        this.setState({indexVisible: false})*/}
            {/*    }}*/}
            {/*    onCancel={() => {*/}
            {/*        this.setState({indexVisible: false})*/}
            {/*    }}*/}
            {/*    okText="提交"*/}
            {/*    cancelText="取消"*/}
            {/*>*/}
            {/*    <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {*/}
            {/*        this.setState({indexVisible: true})*/}
            {/*    }}>排序</Button>*/}
            {/*</Popconfirm>*/}


              <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" disabled={text.groupLevel ? false : true}
                      onClick={() => this.onLiftGroupUpEvent({
                        teamId: text.id,
                        brandId: text.groupLevel,
                        type: "UP"
                      })}>升级</Button>

              <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" disabled={text.groupLevel ? false : true}
                      onClick={() => this.onLiftGroupDownEvent({
                        teamId: text.id,
                        brandId: text.groupLevel,
                        type: "DOWN"
                      })}>降级</Button>

            {text.state ? <Button style={{marginLeft: 10, marginBottom: 10}} type="primary"
                                  onClick={() => this.onGetTeamMembersEvent(text)}>查看战队成员</Button> : null}
              <Button style={{marginLeft: 10, marginBottom: 10}} type="primary"
                      onClick={() => this.onGetTeamInfoEvent(text, operationTypeValue.UPDATE)}
                      color="blue">编辑</Button>
            {text.state ? <Button style={{marginLeft: 10, marginBottom: 10}}
                                  type={text.stopGame ? "danger" : "primary"}
                                  onClick={text.stopGame ? () => this.confirmReleaseGame(text.id, {stopGame: !text.stopGame}) : () => this.onStopGameStatusEvent(text)}
                                  color="blue">{text.stopGame ? "解禁" : "禁赛"}</Button> : ""}

              <Button style={{marginLeft: 10, marginBottom: 10}}
                      type={text.kickPermissions ? "danger" : "primary"}
                      onClick={() => this.onKickPermissionsEvent({
                        teamId: text.id,
                        status: !text.kickPermissions
                      })}
                      color="blue">{text.kickPermissions ? "取消踢人权限" : "开放踢人权限"}</Button> 
              <Button style={{marginLeft: 10, marginBottom: 10}}
                      type={text.memberLiftPermissions ? "danger" : "primary"}
                      onClick={() => this.onLiftPermissionsEvent({
                        teamId: text.id,
                        status: !text.memberLiftPermissions
                      })}
                      color="blue">{text.memberLiftPermissions ? "取消选手升降权限" : "开放选手升降权限"}</Button>                       
          </div>
        )
      },
    },
  ]
}

const mapStateToProps = (state) => {
  return {
    ...state.viewTraining,
    ...state.Team,
  }
};

const mapDispatchToProps = ({
  getTeamInfo,
  getTeamList,
  updateTeam,
  createTeam,
  getGameList,
  updateTeamGameStatus,
  disbandTeam,
  queryTeamsBusiness,
  getBrandAll,
  liftGroup,
  updateKickPermissions,
  updateLiftPermissions
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Team));