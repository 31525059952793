import React from 'react';
import {Button, Form, Input, message, Modal, Table, Card, notification} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import TopicFilter from '../topic-management/TopicFilter';
import { getTopics } from '../../modules/topic/action';

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const {confirm} = Modal;

class TestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            topicName: '',
            selectTopic: null,
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const { getTopics } = props;
        const {pageNo, pageSize} = this.state;
        await getTopics(
            pageNo, pageSize,
        );
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectTopic: selectedRows[0]
        });
    };

    onConfirmTopicEvent = () => {
        const { onConfirmTopic, onClose } = this.props;
        let { selectTopic } = this.state;
        if(selectTopic) {
            onConfirmTopic && onConfirmTopic(selectTopic);
            onClose && onClose();
        } else {
            notification.error({message: '请先选择话题'});
        }
    }

    //条件查找动态方法
    onFilterCommentEvent = async (topicName) => {
        let { getTopics } = this.props;
        const { pageSize } = this.state;
        this.setState({
            topicName: topicName,
            pageNo: 1
        });
        getTopics(1, pageSize, topicName);
    };

    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let { getTopics } = this.props;
        let { topicName } = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getTopics(current, pageSize, topicName);
    }


    getTableColumns = () => {
        return (
            [{
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                key: 'id',
            }, {
                title: '用户ID',
                dataIndex: 'exhibitionId',
                align: 'center',
                key: 'exhibitionId',

            }, {
                title: '用户名称',
                dataIndex: 'userName',
                align: 'center',
                key: 'userName',
            }]
        )
    }



    render() {
        let { visible, topcis, loading, onClose } = this.props;
        const {pageNo, pageSize} = this.state;
        const rowSelection = {
            type: 'radio',
            onChange: this.onSelectChange,
        };
        return (
            <Modal
                visible={visible}
                title="选择话题"
                onOk={this.onConfirmTopicEvent}
                onCancel={onClose}
                width={"50%"}               
            >
                <div>                                      
                    <Card style={{width: 'auto'}}>
                        <TopicFilter dataSource={{}} onCommit={this.onFilterCommentEvent}/>
                        <Table
                            style={{marginTop: 20}}
                            columns={
                                [
                                    {
                                        title: '话题ID',
                                        dataIndex: 'id',
                                        key: 'id',
                                        align: 'center',
                                        width: '15%'                                       
                                    },
                                    {
                                        title: '话题名',
                                        dataIndex: 'topicName',
                                        key: 'topicName',
                                        align: 'center', 
                                        width: '40%'                                      
                                    },                                    
                                    {
                                        title: '话题简介',
                                        dataIndex: 'introduction',
                                        key: 'introduction',
                                        align: 'center', 
                                        width: '45%'                                       
                                    }                                                                      
                                ]
                            }

                            dataSource={topcis.data ? topcis.data : []}
                            bordered
                            loading={loading}
                            scroll={{
                                scrollToFirstRowOnChange: true,
                                y: '350px'
                            }}
                            pagination={{
                                total: topcis ? topcis.total : 0,
                                current: pageNo,
                                pageSize: pageSize,
                                onChange: this.onPageChangeEvent,
                                showTotal:(total)=>{
                                    return `总计 ${total}条数据`;
                                }
                            }}
                            rowSelection={rowSelection}
                        />                                            
                    </Card>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Topics
    }
};
const mapDispatchToProps = ({
    getTopics
});
TestModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TestModal)));
