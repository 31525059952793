import {} from "../appNotice/actionTypes";
import AppNoticeRequest from "../../request/AppNoticeRequest";
import {GET_APP_NOTICES, SEND_APP_NOTICES} from "../appNotice/actionTypes";
import createAsyncAction from 'utils/createAsyncAction';

 const getAppNotices = (receiverAccid, exhibitionId, noticeGroupType,sendStatus,pageNo, pageSize) => (
    createAsyncAction(GET_APP_NOTICES,() => (AppNoticeRequest.getAppNotices(receiverAccid, exhibitionId, noticeGroupType,sendStatus,pageNo,pageSize)))
);

 const sendAppNotices = (params) => (
     createAsyncAction(SEND_APP_NOTICES,() => (AppNoticeRequest.sendAppNotices(params)))
);

 export {
     getAppNotices,
     sendAppNotices
 }
