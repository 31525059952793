import api from 'utils/api';


export default class UserRequest {

  /**
   * 获取用户列表
   * @param page
   * @param size
   * @returns {*}
   */
  static getUsers(exhibitionId, username, telephone, state, registerBeginTime, registerEndTime,  page, size, userId,vestStatus) {
      console.log('vestState:' +
          '',vestStatus);
    return api.get('/admin/users', { page, size, exhibitionId,
      userName:username, telephone, state, vestStatus,startTime:registerBeginTime, endTime:registerEndTime,  userId });
  }

  /**
   * 获取用户详情
   * @returns {null}
   */
  static getUser(userId){
    return api.get(`/admin/users/${userId}`);
  }

  /**
   * 操作用户提现金额
   * @returns {null}
   */
  static updateAccount(userId,cashOut){
    return api.post(`/admin/accounts/${userId}/operation`,cashOut);
  }


  /**
   * 更新用户状态
   */
  static updateUserStatus(userId,params){
    return api.put(`/admin/users/${userId}/state`,params);
  }

  /**
   * 添加用户
   */
  static addUser(params){
    return api.post(`/admin/users`,params);
  }

  /**
   * 解绑用户三方登录
   */
  static unBanUserClaim(userId,type){
    return api.delete(`/admin/users/${userId}/claim/${type}`);
  }


  /**
   * 更新用户信息
   */
  static updateUser(userId,params){
    console.log(params)
    return api.put(`/admin/users/${userId}`,params);
  }

  /**
   * 获取用户收货地址列表
   * @returns {*}
   */
  static getUserAddressList(userId) {
    return api.get(`/admin/address/list/${userId}`, { userId });
  }

  /**
   *  导入马甲
   */
  static importMajia(params){
    return api.post(`/admin/vest`,params);
  }
}
