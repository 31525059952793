import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table} from 'antd';
import {connect} from 'react-redux';
import {getTrailPlan, getTrailPlans, updateTrailPlans} from '../../modules/trail-plan/action';
import {withRouter} from 'react-router-dom';
import TrailPlanForm from "../trail-plan-management/TrailPlanForm";
import TrailPlanFilter from "./TrailPlanFilter";

class TrailPlanManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            isShowEditTrailPlanModal: false,
            operationType: null,
            currentAdminId: null,
            trailPlanInfo: [],
            game: null,
            selectionValue: null
        }
        ;
    }

    componentDidMount() {
        const {getTrailPlans} = this.props;
        const {game, page, size} = this.state;
        getTrailPlans(page, size, 'MANAGER', game);
    }

    onCloseEditTrailPlanModalEvent = () => {
        this.setState({
            isShowEditTrailPlanModal: false,
        });
    }

    //修改试训计划的方法
    onEditTrailPlanEvent = async (priority,points) => {
        console.log(priority+" "+points)
        const {updateTrailPlans} = this.props;
        const {selectionValue} = this.state;
        try {
            await updateTrailPlans(selectionValue, {priority,points});
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditTrailPlanModal: false,
            });
            this.onGetData();
        } catch (e) {
            console.log(e);
        }
    }

    //修改模态框
    onOpenEditTrailPlanModalEvent = (id) => {
        console.log(id)
        const {getTrailPlan} = this.props;
        getTrailPlan(id).then((json) => {
            this.setState({
                trailPlanInfo: json || [],
                selectionValue: id
            })
        })
        this.setState({
            isShowEditTrailPlanModal: true,
        });
    };

    //翻页
    onPageChangeEvent = (current, pageSize) => {
        const {getTrailPlans} = this.props;
        const {game} = this.state;
        this.setState({
            page: current,
            size: pageSize,
        }, () => {
            getTrailPlans(current, pageSize, 'MANAGER', game);
        });
    }

    onGetData = () => {
        const {getTrailPlans} = this.props;
        const {game, page, size} = this.state;
        getTrailPlans(page, size, 'MANAGER', game);
    }

    //条件查找试训方法
    onFilterTrialPlanEvent = async (game) => {
        console.log(game)
        const {getTrailPlans} = this.props;
        const {page, size} = this.state;
        getTrailPlans(page, size, 'MANAGER', game);
    }

    render() {
        const {
            trailPlans, loading
        } = this.props;
        const {
            page, size, isShowEditTrailPlanModal
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrailPlanFilter onCommit={this.onFilterTrialPlanEvent}/>
                </Card>
                <Card>

                    <Table
                        columns={
                            [
                                {
                                    title: '俱乐部',
                                    dataIndex: 'clubName',
                                    key: 'clubName',
                                    align: 'center',
                                },
                                {
                                    title: '首页推荐优先级',
                                    dataIndex: 'priority',
                                    key: 'priority',
                                    align: 'center',
                                },
                                {
                                    title: '点赞数量',
                                    dataIndex: 'points',
                                    key: 'points',
                                    align: 'center',
                                },
                                {
                                    title: '试训价格',
                                    dataIndex: 'price',
                                    key: 'price',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, {clubId}) => (
                                        <div>
                                            <Button type="primary"
                                                    onClick={() => this.onOpenEditTrailPlanModalEvent(clubId)}
                                                    color="blue">修改</Button>
                                        </div>
                                    ),
                                },
                            ]
                        }
                        dataSource={trailPlans.data ? trailPlans.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: trailPlans ? trailPlans.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={"编辑试训计划"}
                        visible={isShowEditTrailPlanModal}
                        footer={null}
                        destroyOnClose
                        onCancel={this.onCloseEditTrailPlanModalEvent}
                    >
                        <TrailPlanForm {...this.state} onSubmitEvent={this.onEditTrailPlanEvent}/>
                    </Modal>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state => {
        return {
            ...state.trailPlan
        }
    }
);

const mapDispatchToProps = {
    getTrailPlan, getTrailPlans, updateTrailPlans
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(TrailPlanManagement)));
