import React, { Component } from 'react';
import { injectIntl } from 'react-intl-context';
import { Button, Card, Modal, notification, Table, Tag, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import './index.scss';
import { withRouter } from 'react-router-dom';
import {
    getPELPlayers,
    addPELPlayer,
    editPELPlaye,
    deletePELPlayer,
} from "../../modules/player/action";
import AddPlayerModel from "./components/AddPlayerModel";
import EventRecordModal from "./components/EventRecordModal";
import CareerRecordModal from "./components/CareerRecordModal";
import HonorManageModal from "./components/HonorManageModal";
import { operationType } from "../../modules/player/clubEnum";
import ProfessionalPlayerFilter from "../professional-player-manage/ProfessionalPlayerFilter";
import {
    getPelLeagueDictionaries
  } from "../../modules/training/action";

const {confirm} = Modal;

class ProfessionalPlayerManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            
            optType: operationType.ADD, // 默认操作类型为增加
            seletionValue:null,
            showId:false, //添加职业选手主页
            userId:"",
            isShowAddPlayerModel: false,
            isShowTransferRecordModal:false,
            isShowCareerRecordModal:false,
            isShowHonorManageModal:false,
            exhibitionId: null,
            pelPlayerIdName: null,
            pelLeagueType: null
        };
    }

    componentDidMount() {
        this.onGetData();
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const {getPelLeagueDictionaries} = props;
        await getPelLeagueDictionaries().then((data) => {
                console.log('onInitDatadata',data);
                this.setState({
                    pelLeagueType: data
                })
            }).catch(error => {
        });
    }
    

    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let { getPELPlayers } = this.props;
        let { exhibitionId,pelPlayerIdName } = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getPELPlayers({page: current, size: pageSize,exhibitionId,pelPlayerIdName});
    }


    onFilterPlayersEvent = async (exhibitionId,pelPlayerIdName) => {
        let { getPELPlayers } = this.props;
        const { pageNo, pageSize } = this.state;
        this.setState({
            exhibitionId:pelPlayerIdName?pelPlayerIdName:null,
            pelPlayerIdName:pelPlayerIdName?pelPlayerIdName:null
        });
        getPELPlayers({page: pageNo, size: pageSize,exhibitionId,pelPlayerIdName});
    }

    onOptEvent = (type, seletionValue)=>{
        this.setState({
           optType: type,
           isShowAddPlayerModel: true,
           seletionValue
        },()=>{
           if (type == operationType.ADD) {
               this.setState({
                   showId: true
               })
           } else if (type == operationType.EDIT) {
               this.setState({
                   showId: false
               })
           }
        })
    }
    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary" onClick={() => {
                this.onOptEvent(operationType.ADD, null)
            }}>新增</Button></Col>
        </Row>
    )
    onAddEvent = (userId)=>{
        this.setState({
            isShowTransferRecordModal: true,
            userId
        })
    }
    onCareerManage = (userId)=>{
        this.setState({
            isShowCareerRecordModal: true,
            userId
        })
    }

    onHonorManage = (userId) =>{
        this.setState({ 
            isShowHonorManageModal:true,
            userId
        });
    }
    render() {
        const {
            playerList, loading
        } = this.props;
        console.log('playerList',playerList);
        const {
            pageNo, pageSize,isShowAddPlayerModel,isShowTransferRecordModal,isShowCareerRecordModal,isShowHonorManageModal,showId,seletionValue
        } = this.state;
        const defaultValue = seletionValue;
        console.log('{...this.state}',{...this.state});
        return (
            <div>
                <Card style={{ marginBottom: 30 }}>
                    <ProfessionalPlayerFilter dataSource={{}} onCommit={this.onFilterPlayersEvent} />
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '选手ID名称',
                                    dataIndex: 'pelPlayerIdName',
                                    key: 'pelPlayerIdName',
                                    align: 'center',
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'playerType',
                                    key: 'playerType',
                                    // width:'300px',
                                    align: 'center',
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'game',
                                    key: 'game',
                                    align: 'center',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, record) => {
                                            return <div>
                                                <Button style={{ marginLeft: 10, marginBottom: 10 }} type="primary" onClick={() => this.onOptEvent(operationType.EDIT, record)}>选手详情修改</Button>
                                                <Button style={{ marginLeft: 10, marginBottom: 10 }} type="primary" onClick={() => this.onAddEvent(record.userId)}>转会记录管理</Button>
                                                <Button style={{ marginLeft: 10, marginBottom: 10 }} type="primary" onClick={() => this.onCareerManage(record.userId)}>职业生涯管理</Button>
                                                <Button style={{ marginLeft: 10, marginBottom: 10 }} type="primary" onClick={() => this.onHonorManage(record.userId)}>荣誉记录管理</Button>
                                                <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteClubEvent(record)} color="red">删除</Button>
                                            </div>;
                                    }
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={playerList.data ? playerList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: playerList ? playerList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>

                <AddPlayerModel
                    showId={showId}
                    defaultValue={defaultValue ? defaultValue : []}
                    onSuccess={this.onFormSubmit}
                    visible={isShowAddPlayerModel}
                    onClose={() => {
                        this.setState({
                            isShowAddPlayerModel: false,
                        })
                    }}
                />

                <Modal
                    title="选手转会纪录列表"
                    visible={isShowTransferRecordModal}
                    width={'50%'}
                    footer={null}
                    destroyOnClose
                    onCancel={()=>{
                        this.setState({
                            isShowTransferRecordModal: false,
                        });
                    }}
                >
                    <EventRecordModal {...this.state}  />
                </Modal>

                <Modal
                    title="选手职业生涯列表"
                    visible={isShowCareerRecordModal}
                    width={'50%'}
                    footer={null}
                    destroyOnClose
                    onCancel={()=>{
                        this.setState({
                            isShowCareerRecordModal: false,
                        });
                    }}
                >
                    <CareerRecordModal {...this.state}  />
                </Modal>

                <Modal
                    title="选手荣誉纪录列表"
                    visible={isShowHonorManageModal}
                    width={'50%'}
                    footer={null}
                    destroyOnClose
                    onCancel={()=>{
                        this.setState({
                            isShowHonorManageModal: false,
                        });
                    }}
                >
                    <HonorManageModal {...this.state}  />
                </Modal>
            </div>
        )
    }


    onGetData = () => {
        const {pageNo, pageSize} = this.state;
        let {getPELPlayers} = this.props;
        getPELPlayers({page: pageNo, size: pageSize});
    }
     //操作-删除
     onDeleteClubEvent = (text) => {
        const {deletePELPlayer} = this.props;
        confirm({
            title: '删除?',
            content: '是否删除该条数据',
            okType: 'danger',
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                await deletePELPlayer(text.id);
                message.success('删除成功');
                this.onGetData();
            },
        });
    }

    onFormSubmit = (values) => {
        const {optType} = this.state;
        switch (optType) {
            case operationType.ADD:
                this.onAddClubEvent(values);
                break;
            case operationType.EDIT:
                this.onEditClubEvent(values);
                break;
            default:
        }
    }

    // 增加俱乐部信息
    onAddClubEvent = async (params) => {
        const {addPELPlayer} = this.props;
        const {isShowAddPlayerModel} = this.state;
        await addPELPlayer(Object.assign(params, {address: {province: params.address[0], city: params.address[1]}}));
        await this.onGetData();
        message.success('新增职业选手成功成功');
        this.setState({isShowAddPlayerModel: !isShowAddPlayerModel});
    }

    // 编辑俱乐部信息
    onEditClubEvent = async (params) => {
        const {seletionValue, isShowAddPlayerModel} = this.state;
        const {editPELPlaye} = this.props;
        await editPELPlaye(seletionValue.id, Object.assign(params, {
            address: {
                province: params.address[0],
                city: params.address[1]
            }
        }));
        await this.onGetData();
        message.success('修改成功');
        this.setState({isShowAddPlayerModel: !isShowAddPlayerModel});
    }
}
const mapStateToProps = (state => {
    console.log('state',state);
    return {
        ...state.ProfessionalPlayer
    }
}

);

const mapDispatchToProps = {
    getPELPlayers,
    addPELPlayer,
    editPELPlaye,
    deletePELPlayer,
    getPelLeagueDictionaries
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ProfessionalPlayerManage)));