import api from 'utils/api';


export default class AccountRequest {


    /**
     * 获取账户详情
     * @param userId
     */
    static getAccount(userId){
        return api.get(`/admin/accounts/${userId}`)
    }


    /**
     * 操作浪币
     */
    static operateAccountCoin(userId, type, coinAmount, remark){
        return api.put(`/admin/accounts/${userId}/coin`,{type, coinAmount, remark});
    }

    /**
     * 获取账户变动日志
     * @param userId
     */
    static getAccountLogs(userId,page,size){
        return api.get(`/admin/accounts/${userId}/log`,{page,size})
    }

    /**
     * 获取充值日志(充值浪币、充值会员)
     * @param userId
     * @param page
     * @param size
     */
    static getSpendLogs(userId,page,size){
        return api.get(`/admin/accounts/${userId}/spend-log`,{page,size})
    }


}
