import React from 'react';
import {
  Form, Input, Select, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { is, fromJS } from 'immutable';

const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const propTypes = {
  onSuccess: PropTypes.func,
  value: PropTypes.any,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  rolefunctions: PropTypes.any,
};

const defaultProps = {
  value: {},
  visible: false,
  rolefunctions: [],
  onClose: () => {},
};
class InfoModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      defaultValue: null,
    };
  }


  componentWillMount() {
    this.onInitData();
  }

  componentWillReceiveProps(nextProps) {
    if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
      // eslint-disable-next-line react/prop-types
      this.props.form.resetFields();
      this.onInitData(nextProps);
    }
  }

    onInitData = (props = this.props) => {
      const { value } = props;
      const defaultValue = value || {};
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        defaultValue,
      });
    }

    onSubmit = () => {
      // eslint-disable-next-line react/prop-types
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { onSuccess } = this.props;
          // eslint-disable-next-line no-unused-expressions
          onSuccess && onSuccess(values);
        }
      });
    }

    render() {
      // eslint-disable-next-line react/prop-types
      const { getFieldDecorator } = this.props.form;
      const { value, visible, rolefunctions } = this.props;
      const defaultValue = value || {};
      return (
        <Modal
          visible={visible}
          title="添加角色"
          onCancel={this.props.onClose}
          onOk={this.onSubmit}
        >
          <Form>
            <FormItem
              label="名称"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemLayout}
            >
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请填写角色名称', pattern: /^\S+$/ }],
                initialValue: defaultValue.name,
              })(
                <Input placeholder="请填写角色名称" />,
              )}
            </FormItem>
            <FormItem
              label="code"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemLayout}
            >
              {getFieldDecorator('code', {
                rules: [{ required: true, message: '请填写角色code值', pattern: /^\S+$/ }],
                initialValue: defaultValue.code,
              })(
                <Input placeholder="请填写角色code值" />,
              )}
            </FormItem>
            <FormItem
              label="功能权限"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formItemLayout}
            >
              {getFieldDecorator('functionPermissionTypes', {
                initialValue: defaultValue.functionPermissionTypeList,
              })(
                <Select placeholder="请选择角色功能权限" mode="multiple">
                  {rolefunctions.map((item) => <Option value={item.code} key={item.code}>{item.name}</Option>)}
                </Select>,
              )}
            </FormItem>
            {/* <FormItem
                    >
                    <div style = {{textAlign:'center'}}>
                        <Button onClick = {this.onSubmit} type = 'primary'>提交</Button>
                    </div>
                    </FormItem> */}
          </Form>
        </Modal>

      );
    }
}
InfoModel.propTypes = propTypes;
InfoModel.defaultProps = defaultProps;
export default Form.create()(InfoModel);
