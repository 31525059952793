import React from 'react';
import {Button, Card, Form, Modal, Table} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    addTeamCertifiacation,
    getCertificationTeamMembers,
    getTeamCertificationInfo,
} from "../../../modules/certification/action";
import {getGameList} from "../../../modules/team/action";
import {getBrandList} from "../../../modules/brand/action";
import SearchCertificationForm from "./SearchCertificationForm";

// const formItemLayout = {
//     labelCol: {span: 2},
//     wrapperCol: {span: 10},
// };
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddCertificationModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificationMembers: new Map(),
            dataForm: [],
            isShowDataForm: false
        }
    }


    componentWillMount() {
        this.onInitData();
    }

    onInitData = async () => {
        const {getGameList, getBrandList} = this.props;
        await getBrandList({page: 1, size: 100000});
        await getGameList();
    }


    onSubmit = async () => {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess, certificationTeamMembers} = _self.props;
                const {certificationMembers, brandId} = _self.state;
                let params = {};
                params["teamId"] = certificationTeamMembers.length > 0 ? certificationTeamMembers[0].teamId : null;
                params["brandId"] = brandId;
                params["items"] = [];
                certificationMembers.forEach(function (value, key, map) {
                    params["items"].push(value);
                })
                onSuccess && onSuccess(params);
            }
        });
    }

    changeShowTeamId = () => {
        this.setState({
            dataForm: [],
            isShowDataForm: false,
            certificationMembers: new Map(),
            brandId: null
        })
    }

    getCertificationTeamMembers = async (values) => {
        const {getCertificationTeamMembers} = this.props;
        await getCertificationTeamMembers(values.brandId, values.teamShowId)
        const {certificationTeamMembers} = this.props;
        const {certificationMembers} = this.state
        if (certificationTeamMembers.length > 0) {
            certificationTeamMembers.map(function (value, index, array) {
                certificationMembers.set(value.userId, {
                    gamerId: value.userId,
                    gamerName: value.gamerName,
                    jobType: value.jobType,
                    playerId: value.playerId,
                    playerName: value.playerName,
                    qq: value.qq,
                    imageUrl: value.imageUrl,
                })
                return null
            })
        }
        this.setState({
            certificationMembers: certificationMembers,
            brandId: values.brandId,
        })
        await this.onInitCertificationData();
    }

    onInitCertificationData = async () => {
        const {certificationTeamMembers} = this.props;
        this.setState({
            dataForm: certificationTeamMembers,
            isShowDataForm: true
        })
    }

    onChangeTable = (key, gamerId, value) => {
        const {certificationMembers} = this.state;
        let obj = certificationMembers.get(gamerId);
        obj[key] = value;
        certificationMembers.set(gamerId, obj);
        this.setState({
            certificationMembers: certificationMembers
        })
    }

    render() {
        const {visible, brands, games} = this.props;
        const {loading, dataForm, isShowDataForm} = this.state;
        return (
            <Modal
                width={'80%'}
                visible={visible}
                title={"添加参赛认证"}
                onCancel={this.props.onClose}
                footer={<div><Button onClick={this.props.onClose}>取消</Button> <Button disabled={!isShowDataForm}
                                                                                      onClick={this.onSubmit}>确定</Button>
                </div>}
            >
                <Card style={{marginBottom: 30}}>
                    <SearchCertificationForm brands={brands} games={games} onCommit={this.getCertificationTeamMembers}
                                             onShowTeamIdChange={this.changeShowTeamId}/>
                </Card>


                <Card style={{marginBottom: 30}}>
                    <Table
                        dataSource={isShowDataForm ? (dataForm.length > 0 ? dataForm : []) : []}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={false}
                        loading={loading}
                    />
                </Card>
            </Modal>

        );
    }

    getTableColumns = () => {
        return ([{
            title: '玩家ID',
            dataIndex: 'showGamerId',
            align: 'center',
            key: 'showGamerId',
        }, {
            title: '玩家名称',
            dataIndex: 'gamerName',
            align: 'center',
            key: 'gamerName',
        },
        //     {
        //     title: '参赛昵称',
        //     dataIndex: 'inGameName',
        //     align: 'center',
        //     key: 'playerName',
        //     render: (row, text) => {
        //         return (
        //             <div>
        //                 {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
        //                 <Form.Item style={{margin: 0}}>
        //                     {getFieldDecorator('playerName' + text.userId, {
        //                         initialValue: text.playerName,
        //                         rules: [
        //                             {
        //                                 required: true,
        //                                 message: `参赛昵称不能为空.`,
        //                             },
        //                         ],
        //                     })(<Input
        //                         onChange={(e) => this.onChangeTable('playerName', text.userId, e.currentTarget.value)}/>)}
        //                 </Form.Item>
        //             </div>
        //         )
        //     },
        // },
            {
            title: '成员类型',
            dataIndex: 'jobType',
            align: 'center',
            key: 'jobType',
            render: (row, text) => (
                <span>{text.jobType == "LEADER" ? "队长" : text.jobType == "FORMAL" ? "正式成员" : text.jobType == "ALTERNATE" ? "替补成员" : ""}</span>
            ),
        },
        //     {
        //     title: '第三方游戏id',
        //     dataIndex: 'thirdGameId',
        //     align: 'center',
        //     key: 'thirdGameId',
        //     // render: (row, text) => (
        //     //     <input required onChange={(e) => this.onChangeTable('playerId', text.gamerId, e.currentTarget.value)}/>
        //     // ),
        //     render: (row, text) => {
        //         return (
        //             <div>
        //                 {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
        //                 <Form.Item style={{margin: 0}}>
        //                     {getFieldDecorator('thirdGameId' + text.userId, {
        //                         initialValue: text.playerId,
        //                         rules: [
        //                             {
        //                                 required: true,
        //                                 message: `第三方游戏id不能为空.`,
        //                             },
        //                         ],
        //                     })(<Input
        //                         onChange={(e) => this.onChangeTable('playerId', text.userId, e.currentTarget.value)}/>)}
        //                 </Form.Item>
        //             </div>
        //         )
        //     },
        // }, {
        //     title: '参赛QQ号',
        //     dataIndex: 'qqNum',
        //     align: 'center',
        //     key: 'qqNum',
        //     // render: (row, text) => (
        //     //     <input required onChange={(e) => this.onChangeTable('qq', text.gamerId, e.currentTarget.value)}/>
        //     // ),
        //     render: (row, text) => {
        //         return (
        //             <div>
        //                 {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
        //                 <Form.Item style={{margin: 0}}>
        //                     {getFieldDecorator('qqNum' + text.userId, {
        //                         initialValue: text.qq,
        //                         rules: [
        //                             {
        //                                 required: true,
        //                                 message: `参赛QQ不能为空.`,
        //                             },
        //                         ],
        //                     })(<Input
        //                         onChange={(e) => this.onChangeTable('qq', text.userId, e.currentTarget.value)}/>)}
        //                 </Form.Item>
        //             </div>
        //         )
        //     },
        // }
        // , {
        //     title: '游戏截图',
        //     dataIndex: 'imageUrl',
        //     align: 'center',
        //     key: 'imageUrl',
        //     // render: (row, text) => (
        //     //     <input required onChange={(e) => this.onChangeTable('qq', text.gamerId, e.currentTarget.value)}/>
        //     // ),
        //     render: (row, text) => {
        //         return (
        //             <div>
        //                 {/*<input required onChange={(e)=>this.onChangeTable('playerName', text.gamerId, e.currentTarget.value)}/>*/}
        //                 <Form.Item
        //                     // eslint-disable-next-line react/jsx-props-no-spreading
        //                     style={{margin: 0}}
        //                 >
        //                     {getFieldDecorator('logo', {
        //                         initialValue: text.imageUrl ? text.imageUrl : "",
        //                     })(
        //                         <UpLoad
        //                             double={false}
        //                             width={690}
        //                             height={300}
        //                         />
        //                     )}
        //                 </Form.Item>
        //             </div>
        //         )
        //     },
        // }
        ])
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Brand,
        ...state.Certification,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTeamCertificationInfo,
    getCertificationTeamMembers, addTeamCertifiacation,
    getGameList,
    getBrandList,
});
AddCertificationModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddCertificationModel)));
