import createReducer from 'utils/createReducer';
import {GET_SENSITIVITY_PLAN_LIST, GET_SENSITIVITY_PLAN_INFO} from './actionTypes';

const defaultState = () => ({
    sensitivityPlans: {
        data: [],
        total: 0,
    }, // 灵敏度方案列表
    sensitivityPlanInfo: null, // 灵敏度方案

});

const onGetSensitivityPlanInfoSuccess = (state, {payload}) => ({
    ...state,
    sensitivityPlanInfo: payload,
});


const onGetSensitivityPlanListSuccess = (state, {payload}) => ({
    ...state,
    sensitivityPlans: payload,
});


export default createReducer(defaultState, {
    [`${GET_SENSITIVITY_PLAN_LIST}_SUCCESS`]: onGetSensitivityPlanListSuccess,
    [`${GET_SENSITIVITY_PLAN_INFO}_SUCCESS`]: onGetSensitivityPlanInfoSuccess,
});
