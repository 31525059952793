import api from 'utils/api';


export default class ResumeRequest {

  /**
   * 获取用户履历列表
   * @param userId
   * @returns {*}
   */
  static getUserResumes(userId) {
    return api.get(`/admin/users/valuation/${userId}/information`);
  }
  static changeAdditionSs(userId,game,additionalSocialStatus){
    return api.put(`/admin/rosters/additional_social_status`,{userId,game,additionalSocialStatus});
  }


}
