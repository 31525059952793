import createReducer from 'utils/createReducer';
import {GET_LINEUP_LIST,GET_MEMBER_LIST} from './actionTypes';

const defaultState = () => ({
    lineups: {
        data: [],
        total: 0,
    }, // 战队列表
    lineupMembers: {
        data: [],
        total: 0,
    },
});


const onGetLineupListSuccess = (state, {payload}) => ({
    ...state,
    lineups: payload,
});

const onGetMemberListSuccess = (state, {payload}) => ({
    ...state,
    lineupMembers: payload,
});


export default createReducer(defaultState, {
    [`${GET_LINEUP_LIST}_SUCCESS`]: onGetLineupListSuccess,
    [`${GET_MEMBER_LIST}_SUCCESS`]: onGetMemberListSuccess,
});
