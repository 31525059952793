import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import AddressForm from './AddressForm';
import ShipForm from './ShipForm';
import GiftOrderFilter from "./GiftOrderFilter";
import { operationTypeValue } from "../../modules/gift/giftEnum";
import { getGiftOrderList, updateGiftOrderAddress, updateGiftOrderShip } from '../../modules/gift/action';

class GiftManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            showEditOrderModal: false,
            giftId: null,
            showCDKModal: false,
        };
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getGiftOrderList } = this.props;
        getGiftOrderList({page, size, giftPurpose:"COMMEMORATIVE_CARD"});
    }

    onPageChangeEvent = (current, size) => {
        let { getGiftOrderList } = this.props;
        let { giftName, giftType } = this.state;
        this.setState({
            page: current,
            size
        });
        getGiftOrderList({current, size, giftName, giftType, giftPurpose:"COMMEMORATIVE_CARD"});
    }

    onFilterGiftOrderEvent = (params) => {
        const { getGiftOrderList } = this.props;
        const { size } = this.state;
        this.setState(params);
        getGiftOrderList({page: 1, size, giftPurpose:"COMMEMORATIVE_CARD", ...params});
    }

    onOpenEditOrderModalEvent = (text, type) => {              
        this.setState({
            orderInfo: { ...text, orderId: text.id, type }
        }, () => {
            this.setState({
                operationType: type,
                showEditOrderModal: true,
            });
        });
    }

    onCloseEditOrderModalEvent = () => {
        this.setState({
            showEditOrderModal: false
        });
    }
    
    // 编辑物流信息
    onEditShipEvent = async (params) => {
        const {page, size, orderInfo} = this.state;
        const {updateGiftOrderShip, getGiftOrderList} = this.props;
        try {
            await updateGiftOrderShip(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false
            });
            getGiftOrderList({page, size, giftPurpose:"COMMEMORATIVE_CARD"});
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑收货地址
    onEditAddressEvent = async (params) => {
        const {page, size, orderInfo} = this.state;
        const {updateGiftOrderAddress, getGiftOrderList} = this.props;
        try {
            await updateGiftOrderAddress(orderInfo.id, params);
            notification.success({message: '更新成功'});
            this.setState({
                showEditOrderModal: false,
            });            
            getGiftOrderList({page, size, giftPurpose:"COMMEMORATIVE_CARD"});
        } catch (e) {
            console.log(e);
        }
    }

    onOpenCDKModal = (giftId) => {
        this.setState({
            giftId,
            showCDKModal: true
        });
    }

    onCloseCDKModal = () => {
        this.setState({
            showCDKModal: false
        });
    }

    render() {
        const {
            giftOrderList, loading
        } = this.props;
        const {
            showEditOrderModal, page, size, operationType, orderInfo, showCDKModal, giftId
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <GiftOrderFilter dataSource={{}} onCommit={this.onFilterGiftOrderEvent}></GiftOrderFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '订单编号',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '礼品名称',
                                    dataIndex: 'giftName',
                                    key: 'giftName',
                                    align: 'center',
                                },
                                {
                                    title: '礼品类型',
                                    dataIndex: 'giftType',
                                    key: 'giftType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {text.giftType === 'IN_KIND_GIFT' ? '实物周边' : ''}
                                            {text.giftType === 'VIP' ? 'VIP会员（虚拟礼品）' : ''}
                                            {text.giftType === 'ENERGY' ? '能量值（虚拟礼品）' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '用户ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                    
                                },
                                {
                                    title: '兑换时间',
                                    dataIndex: 'exchangeTime',
                                    key: 'exchangeTime',
                                    align: 'center',
                                },
                                {
                                    title: '发货状态',
                                    dataIndex: 'shippingStatus',
                                    key: 'shippingStatus',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.shippingStatus ? '已发货' : '未发货'}</div>
                                    )
                                },
                                                                                        
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>                                          
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_ADDRESS)}
                                                    color="blue">编辑收货地址</Button>
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditOrderModalEvent(text, operationTypeValue.UPDATE_SHIP)}
                                                    color="blue">发货</Button>                                   
                                        </div>
                                    }
                                },
                            ]
                        }
                        
                        dataSource={giftOrderList.data ? giftOrderList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: giftOrderList ? giftOrderList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE_ADDRESS ? "编辑收货地址" : operationType === operationTypeValue.UPDATE_SHIP ? "编辑物流信息" : ''}
                        visible={showEditOrderModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditOrderModalEvent}
                        width={"30%"}
                    >
                        {operationType === operationTypeValue.UPDATE_ADDRESS ?
                            <AddressForm {...orderInfo}
                                        onSubmitEvent={this.onEditAddressEvent}/>
                            : operationType === operationTypeValue.UPDATE_SHIP && <ShipForm {...orderInfo} onSubmitEvent={this.onEditShipEvent}/>}                    
                    </Modal>            
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Gift
        }

    }

);

const mapDispatchToProps = {
    getGiftOrderList, 
    updateGiftOrderAddress,
    updateGiftOrderShip,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(GiftManagement)));
