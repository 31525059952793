import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_VIP_PACKAGES,
    ADD_VIP_PACKAGE,
    UPDATE_VIP_PACKAGE,
    DELETE_VIP_PACKAGE,
    GET_VIP,
    RECHARGE_VIP_DAYS,
    GET_VIP_ACTIVITY,
    DELETE_VIP_ACTIVITY,
    GET＿VIP＿STATE,
    FIND_HISTORY_LOGS
} from "./actionType";
import VipRequest from "../../request/VipRequest";


const getVipPackages = () => (
    createAsyncAction(GET_VIP_PACKAGES, () => (VipRequest.getVipPackages()))
);

const addVipPackage = (packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity) => (
    createAsyncAction(ADD_VIP_PACKAGE, () => (VipRequest.addVipPackage({packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity})))
);

const updateVipPackage = (id,{packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity}) => (
    createAsyncAction(UPDATE_VIP_PACKAGE, () => (VipRequest.updateVipPackage(id,{packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity})))
);

const deleteVipPackage = (id) => (
    createAsyncAction(DELETE_VIP_PACKAGE, () => (VipRequest.deleteVipPackage(id)))
);


const getVip = (userId) => (
    createAsyncAction(GET_VIP, () => (VipRequest.getVip(userId)), true)
);

const rechargeVipDays = (userId,vipDays) => (
    createAsyncAction(RECHARGE_VIP_DAYS, () => (VipRequest.rechargeVipDays(userId,vipDays)))
);
const getVipState = (userId) => (
    createAsyncAction(GET＿VIP＿STATE, () => (VipRequest.getVipState(userId)))
);

const getVipActivity = (page, size,exhibitionId,phone,realName) => (
    createAsyncAction(GET_VIP_ACTIVITY, () => (VipRequest.getVipActivity(page, size,exhibitionId,phone,realName)))
);

const deleteVipActivity = (activityId) => (
    createAsyncAction(DELETE_VIP_ACTIVITY, () => (VipRequest.deleteVipActivity(activityId)))
);
const findHistoryLogs = (activityId) => (
    createAsyncAction(FIND_HISTORY_LOGS, () => (VipRequest.findHistoryLogs(activityId)))
);



export {
    getVipPackages,addVipPackage,updateVipPackage,deleteVipPackage,getVip,rechargeVipDays,getVipActivity,deleteVipActivity, getVipState,findHistoryLogs
};
