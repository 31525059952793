import createAsyncAction from 'utils/createAsyncAction';
import TopicRequest from '../../request/TopicRequest';
import {GET_TOPICS,
        SET_TOP,
        UPDATE_TOPIC,
        DELETE_TOPIC,
        CREATE_TOPIC,
        GET_POST_LIST,
        GET_POST_DETAIL,
        GET_EMOJI_POST_DETAIL,
        UPDATE_POST,
        UPDATE_POST_SETTING,
        GET_EMOJI_POST_LIST,
        UPDATE_EMOJI_POST_SETTING,
        UPDATE_EMOJI_POST,
} from "../topic/actionTypes";
import {
        GET_POST_LIKE_CONFIG,
        IMPORT_POST_COMMENT,
        MAJIA_CREATE_POST,
        UPDATE_POST_HOT,
        UPDATE_POST_LIKE
} from "./actionTypes";


const getTopics = (pageNo, pageSize, topicName) => (
    createAsyncAction(GET_TOPICS, () => (TopicRequest.getTopics(pageNo, pageSize, topicName)))
);

const setTop = (id,type) => (createAsyncAction(SET_TOP, () => (TopicRequest.setTop(id,type))));

const updateTopic = (id,params) => (createAsyncAction(UPDATE_TOPIC,
    () => (TopicRequest.updateTopic(id,params))));

const deleteTopic = (id) => (createAsyncAction(DELETE_TOPIC,
    () => (TopicRequest.deleteTopic(id))));

const createTopic = (params) => (createAsyncAction(CREATE_TOPIC, () => (TopicRequest.createTopic(params))));

const getPostList = (pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime) => (
    createAsyncAction(GET_POST_LIST, () => (TopicRequest.getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime)))
);

const getEmojiPostList = (page, size, userId, username, playerName, block, publishTimeStart, publishTimeEnd) => (
    createAsyncAction(GET_EMOJI_POST_LIST, () => (TopicRequest.getEmojiPostList(page, size, userId, username, playerName, block, publishTimeStart, publishTimeEnd)))
);

const getPostDetail = (id) => (
    createAsyncAction(GET_POST_DETAIL, () => (TopicRequest.getPostDetail(id)))
);

const getEmojiPostDetail = (id) => (
    createAsyncAction(GET_EMOJI_POST_DETAIL, () => (TopicRequest.getEmojiPostDetail(id)))
);

const updatePost = (id, param) => (
    createAsyncAction(UPDATE_POST, () => (TopicRequest.updatePost(id, param)))
);

const updatePostLike = (param) => (
    createAsyncAction(UPDATE_POST_LIKE, () => (TopicRequest.updatePostLike(param)))
);

const updatePostHot = (param) => (
    createAsyncAction(UPDATE_POST_HOT, () => (TopicRequest.updatePostHot(param)))
);

const majiaCreatePost = (param) => (
    createAsyncAction(MAJIA_CREATE_POST, () => (TopicRequest.majiaCreatePost(param)))
);

const updatePostSetting = (id, param) => (
    createAsyncAction(UPDATE_POST_SETTING, () => (TopicRequest.updatePostSetting(id, param)))
);

const updateEmojiPostSetting = (id, type, param) => (
    createAsyncAction(UPDATE_EMOJI_POST_SETTING, () => (TopicRequest.updateEmojiPostSetting(id, type, param)))
);

const updateEmojiPost = (id, param) => (
    createAsyncAction(UPDATE_EMOJI_POST, () => (TopicRequest.updateEmojiPost(id, param)))
);


const getPostLikeConfig = (id) => (
    createAsyncAction(GET_POST_LIKE_CONFIG, () => (TopicRequest.getPostLikeConfig()))
);

const importPostComments = (param) => (
    createAsyncAction(IMPORT_POST_COMMENT, () => (TopicRequest.importPostComment(param)))
);


export {
    getTopics,getPostLikeConfig,updateTopic,importPostComments,majiaCreatePost,setTop,deleteTopic,createTopic,getPostList,getPostDetail,updatePost,updatePostSetting,getEmojiPostDetail,getEmojiPostList,updateEmojiPostSetting,updateEmojiPost,updatePostLike,updatePostHot
};
