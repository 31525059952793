import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class InformationManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    // 测试环境链接为：https://novaesports-official.test.env.7eid.com/cms/dashboard/auth/login
    // 正式环境为：https://asia.novaesports.com/cms/dashboard/auth/login
    componentDidMount() {
        window.open("https://asia.novaesports.com/cms/dashboard/auth/login")
    }

    render() {
        return (
            <Button type="primary" onClick={() => {
                window.open("https://asia.novaesports.com/cms/dashboard/auth/login")
            }}>{"管理资讯"}</Button>
        )
    }
}

export default connect(

)(withRouter(injectIntl(InformationManagement)));