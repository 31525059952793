export const GET_TICKET_LIST = 'GET_TICKET_LIST';

export const GET_TICKET_ORDER_LIST = 'GET_TICKET_ORDER_LIST';

export const GET_MEDAL_THEME_DATA = 'GET_MEDAL_THEME_DATA';

export const UPDATE_MEDAL_THEME_DATA = 'UPDATE_MEDAL_THEME_DATA';

export const ADD_TICKET_DATA = 'ADD_TICKET_DATA';

export const UPDATE_MEDAL_THEME_SHELF = 'UPDATE_MEDAL_THEME_SHELF';

export const GET_SUB_MEDAL_LIST = 'GET_SUB_MEDAL_LIST';

export const GET_SUB_MEDAL_DATA = 'GET_SUB_MEDAL_DATA';

export const UPDATE_SUB_MEDAL_DATA = 'UPDATE_SUB_MEDAL_DATA';

export const GET_SUB_MEDAL_LOG_LIST = 'GET_SUB_MEDAL_LOG_LIST';

export const UPDATE_SUB_MEDAL_SEND = 'UPDATE_SUB_MEDAL_SEND';

export const UPDATE_SUB_MEDAL_RECYCLE = 'UPDATE_SUB_MEDAL_RECYCLE';
