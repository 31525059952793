import React, {Component} from "react";
import {Form, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getPelLeagueDictionaries, getPelTeamRankIntegral} from "../../../modules/training/action";

const FormItem = Form.Item;

class PelDataIntegralFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataType: 'TOTAL',
            pelLeagueType: null
        }
    }

    componentWillMount() {
        const {getPelLeagueDictionaries} = this.props;
        getPelLeagueDictionaries().then((data) => {
            this.setState({
                pelLeagueType: data[0].value
            })
        }).catch(error => {
        });
    }

    changePelLeagueType = (value) => {
        let {onCommit} = this.props;
        onCommit && onCommit(value, this.state.dataType);
        this.setState({
            pelLeagueType: value
        })
    }
    changeDataType = (value) => {
        let {onCommit} = this.props;
        onCommit && onCommit(this.state.pelLeagueType, value);
        this.setState({
            dataType: value
        })
    }

render()
{
    const {getFieldDecorator} = this.props.form;
    const {leagueDicList} = this.props
    return (
        <Form onSubmit={this.handleSubmit} layout="inline">

            <FormItem label="赛季">
                {getFieldDecorator('pelLeagueType', {
                        initialValue: leagueDicList && leagueDicList[0].value,
                    }
                )(
                    <Select style={{width: 120}} onChange={this.changePelLeagueType}>
                        {leagueDicList && leagueDicList.map((item) => <Select.Option
                            value={item.value}>{item.label}</Select.Option>)}
                    </Select>,
                )}
            </FormItem>
            <FormItem label="数据类型">
                {getFieldDecorator('dataType', {initialValue: 'TOTAL'}
                )(
                    <Select style={{width: '100px'}} onChange={this.changeDataType}>
                        <Select.Option value="TOTAL">总数数据</Select.Option>
                        <Select.Option value="AVG">平均数据</Select.Option>
                    </Select>
                )}
            </FormItem>
            <FormItem>
                {/*<Button type="primary" htmlType="submit" style={{width: '100%'}}>*/}
                {/*    查询*/}
                {/*</Button>*/}
            </FormItem>
        </Form>
    );
}
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getPelLeagueDictionaries,
    getPelTeamRankIntegral
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PelDataIntegralFilter)));