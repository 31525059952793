import api from '../utils/api';

class ArticleRequest {

    static getArticles(pageNo,pageSize,type){
        return api.get('/admin/articles',{pageNo,pageSize,type});
    }
    
    static updateArticle(articleId,{title,author,imageUrl,detail,remark,type,enable,top,tag}){
        return api.put(`/admin/articles/${articleId}`,{title,author,imageUrl,detail,remark,type,enable,top,tag});
    }

    static getArticle(articleId){
        return api.get(`/admin/articles/${articleId}`);
    }

    static deleteArticle(articleId){
        return api.delete(`/admin/articles/${articleId}`);
    }

    static addArticle({title,author,creator,imageUrl,detail,remark,type,enable,top,tag}){
        return api.post(`/admin/articles`,{title,author,creator,imageUrl,detail,remark,type,enable,top,tag})
    }

    static changeStatus(id,enable){
        return api.put(`/admin/articles/${id}/${enable}`,{id,enable})
    }
    static getGames(){
        return api.get(`/admin/games`,)
    }
}
export default ArticleRequest;