import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import { getFeedbackList, deleteFeedback } from "../../modules/report/action";
import {operationTypeValue} from "../../modules/report/reportEnum";
import api from '../../utils/api';

class FeedbackManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            page: 1,
            size: 10,
            imageUrl: [],
            isShowAssignAdminRoleModal: false,
            isShowImageModal: false,
            currentAdminId: null,
            feedbackList: [],
        };
    }

    handleOk = e => {
        this.setState({
            isShowImageModal: false,
        })
    };

    handleCancel = e => {
        this.setState({
            isShowImageModal: false,
        })
    }

    componentDidMount() {
        const {page, size} = this.state;
        let {getFeedbackList} = this.props;
        getFeedbackList({page, size});
    }

    showImageUrl = (imageUrl) => {
        console.log(imageUrl);
        if (imageUrl === null) {
            this.setState({
                isShowImageModal: true,
                imageUrl: []
            });
        } else {
            this.setState({
                isShowImageModal: true,
                imageUrl: imageUrl
            });
        }
    }

    //删除用户反馈
    onDeleteFeedbackEvent = async (feedbackId) => {
        const {getFeedbackList, deleteFeedback} = this.props;
        const {page, size} = this.state;       
        try {
            await deleteFeedback(feedbackId);
            notification.success({message: '删除成功'});
            getFeedbackList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, size) => {
        let {getFeedbackList} = this.props;
        this.setState({
            page: current,
            size: size,
        });
        getFeedbackList({page: current, size});
    }

    render() {
        console.log(this.props.feedbackList)
        const {
            feedbackList, loading
        } = this.props;
        const {
            page, size,
        } = this.state;
        return (
            <div>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '反馈人',
                                    dataIndex: 'username',
                                    key: 'username',
                                    align: 'center',
                                    width: '15%',
                                },
                                {
                                    title: '手机号',
                                    dataIndex: 'userPhone',
                                    key: 'userPhone',                                    
                                    align: 'center',
                                    width: '10%',
                                },
                                {
                                    title: '邮箱号',
                                    dataIndex: 'mailBox',
                                    key: 'mailBox',                                    
                                    align: 'center'
                                },
                                {
                                    title: '反馈内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    width: '35%',
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    render: (text, feedback) => {
                                        let imageList = feedback.imageUrl && feedback.imageUrl.split(',');
                                        return <Button type="primary" color="blue"
                                                       onClick={() => this.showImageUrl(imageList)}>查看</Button>
                                    }
                                },
                                {
                                    title: '反馈时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, feedback) => {
                                        return <div>
                                            <Popconfirm title={`确定要删除该记录吗？`}
                                                        onConfirm={() => this.onDeleteFeedbackEvent(feedback.id)}
                                                        okText="确认" cancelText="取消">
                                                <Button type="danger">删除</Button>
                                            </Popconfirm></div>;

                                    }
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={feedbackList.data ? feedbackList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: feedbackList ? feedbackList.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="图片查看"
                    visible={this.state.isShowImageModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={"600px"}
                    height={"700px"}
                    footer={
                        [] // 设置footer为空，去掉 取消 确定默认按钮
                    }
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                    bodyStyle={{padding: 0}}
                >
                    <div style={{width: 500, height: 600, overflow: 'hidden'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', padding: 24, width: 600, height: 600, overflow: 'scroll'}}>
                            {this.state.imageUrl.map((image) => {
                                return <img style={{marginBottom: 24}} align={"center"} alt={""} width={"400px"} src={image ? image+'?x-oss-process=image/resize,w_400' : ''}/>
                            })}
                        </div>
                    </div>                      
                </Modal>
            </div>
        );

    }

// --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Report)
        return {
            ...state.Report
        }

    }

);

const mapDispatchToProps = {
    getFeedbackList,
    deleteFeedback,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(FeedbackManagement)));