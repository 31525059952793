import React, {Component} from "react";
import {Form, Button, Input} from "antd";

const FormItem = Form.Item;


class UserSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                onCommit && onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="学员展示ID">
                    {getFieldDecorator('exhibitionId', {rules: [{required: true, message: '请填写学员展示ID'}],}
                    )(
                        <Input placeholder="请填写学员展示ID" style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}


export default Form.create()(UserSearch);
