
import createAsyncAction from 'utils/createAsyncAction';
import {
    ADD_SENSITIVITY_PLAN_INFO,
    GET_SENSITIVITY_PLAN_LIST,
    GET_SENSITIVITY_PLAN_INFO,
    UPDATE_SENSITIVITY_PLAN_INFO
} from './actionTypes';
import SamllPlanRequest from "../../request/SamllPlanRequest";


//添加二维码配置
export const addSensitivityPlan= (params) => (createAsyncAction(ADD_SENSITIVITY_PLAN_INFO, () => (SamllPlanRequest.createSensitivityPlan(params))));
//修改二维码配置
export const updateSensitivityPlan= (id, params) => (createAsyncAction(UPDATE_SENSITIVITY_PLAN_INFO, () => (SamllPlanRequest.updateSensitivityPlan(id, params))));
//查询二维码
export const getSensitivityPlanList = (params) => (createAsyncAction(GET_SENSITIVITY_PLAN_LIST, () => (SamllPlanRequest.getSensitivityPlanList(params))));
//查询二维码
export const getSensitivityPlanInfo = (id) => (createAsyncAction(GET_SENSITIVITY_PLAN_INFO, () => (SamllPlanRequest.getSensitivityPlanInfo(id))));
