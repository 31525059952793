import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, notification, Popconfirm, Row, Table, Tabs} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../modules/team/action";
import {getAdmins} from "../../modules/admin/action";
import {authorizationPersonBrand, createPersonBrand, getBrandInfo, getPersonBrandList, updatePersonBrand} from "../../modules/brand/action";
import BrandInfoModel from "./components/BrandInfoModel";
import BrandFilter from "./components/BrandFilter";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';
import AssignBrandForm from './components/AssignBrandForm';
import TeamMembersModel from "../team-management/components/TeamMembersModel";
import {getBrand} from "../../modules/training/action";
import {AUTHENTICATION_INFO} from "../../modules/authentication/constants";


const {TabPane} = Tabs;
const {confirm} = Modal;



class Brand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            previewVisible: false,
            previewImage: '',
            operationType: operationTypeValue.INCREASE,
            isShowBrandInfoModel: false, // 战队信息
            isShowAssignBrandModal: false,
            seletionValue: null,
            name: '',
            game: '',
            page: 1,
            size: 15,
            randIndex: 0,
            indexVisible: false,
            state: 'true'
        };
    }

    componentWillMount() {
        const {getAdmins, getBrand} = this.props;
        let parse = JSON.parse(sessionStorage.getItem(AUTHENTICATION_INFO)) ? JSON.parse(sessionStorage.getItem(AUTHENTICATION_INFO)).roleCodes : [];
        if(parse.includes('SUPER_ADMIN')){
            getAdmins(1, 1000);
        }
        getBrand({page: 1, size: 100000});
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.personBrands !== this.props.personBrands) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getPersonBrandList, getGameList} = this.props;
        const {page, size, state} = this.state;
        this.setState({
            loading: true
        })
        getPersonBrandList({page, size, state});
        getGameList();
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getPersonBrandList} = this.props;
        getPersonBrandList({
            page: current, size: pageSize,
            game: this.state.game ? this.state.game : null,
            name: this.state.name ? this.state.name : null,
            state: this.state.state
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowBrandInfoModel: true,
            seletionValue: seletionValue,
        });
    }


    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreateBrandEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdateBrandEvent(values);
                break;
            default:
        }
    }


    onCreateBrandEvent = async (values) => {
        const {isShowBrandInfoModel} = this.state;
        const {createPersonBrand} = this.props;
        await createPersonBrand(values);
        await this.onGetTableData();
        message.success('编辑成功');
        this.setState({isShowBrandInfoModel: !isShowBrandInfoModel});
    }

    onUpdateBrandEvent = async (values) => {
        const {seletionValue, isShowBrandInfoModel} = this.state;
        const {updatePersonBrand} = this.props;
        await updatePersonBrand(seletionValue.id, values);
        await this.onGetTableData();
        message.success('修改成功');
        this.setState({isShowBrandInfoModel: !isShowBrandInfoModel});
    }

    onUpdateBrandStateConfirm = async (id, values) => {
        let _this = this;
        confirm({
            content: '确认要' + (values === 'true' ? '上架' : '下架') + '该品牌赛事吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onUpdateBrandStateEvent(id, values);
            },
            onCancel() {
            },

        });
    }

    onUpdateBrandStateEvent = async (id, values) => {
        const {updatePersonBrand} = this.props;
        await updatePersonBrand(id, {state: values});
        await this.onGetTableData();
        message.success('修改成功');
    }


    clickRand = async (brandId) => {
        let {updatePersonBrand} = this.props;
        let {randIndex} = this.state;
        await updatePersonBrand(brandId, {randIndex});
        await this.onGetTableData();
        message.success('修改成功');
    }

    renderTableTitle = () => {
        // console.log(this.props.pageButtons);
        return (
            <Row type="flex" justify="end">
                <Col>
                    {whetherButtonPermission(buttonType.CREATE_BRAND.value, this.props.pageButtons) &&
                    <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>}
                </Col>
            </Row>
        )
    }

    //条件查找动态方法
    onFilterBrandEvent = async (name, game) => {
        const {size,} = this.state;
        const {getPersonBrandList} = this.props;
        this.setState({
            name: name ? name : null,
            game: game ? game : null,
            page: 1,
            loading: true
        })
        await getPersonBrandList({
            page: 1,
            size: size,
            name: name ? name : null,
            game: game ? game : null,
            state: this.state.state
        });
    }


    changeTabPane = async (key) => {
        const {size,} = this.state;
        const {getPersonBrandList} = this.props;
        this.setState({
            state: key ? key : null,
            page: 1,
            name: null,
            game: null,
            loading: true
        })
        await getPersonBrandList({
            page: 1, size: size,
            state: key ? key : null
        });
    }

    renderTabPane = () => {
        const {
            personBrands, games, admins
        } = this.props;
        const {
            operationType, isShowBrandInfoModel, seletionValue, page, size, isShowAssignBrandModal, loading, previewVisible, previewImage,
        } = this.state;
        const defaultValue = operationType == operationTypeValue.UPDATE && seletionValue;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <BrandFilter dataSource={{}} onCommit={this.onFilterBrandEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={personBrands ? personBrands.data : []}
                        title={this.state.state === "true" ? this.renderTableTitle : null}
                        columns={this.getTableColumns(games)}
                        bordered
                        pagination={{
                            total: personBrands.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowBrandInfoModel ? <BrandInfoModel
                        value={defaultValue}
                        operationType={operationType}
                        onSuccess={this.onFormSubmit}
                        visible={isShowBrandInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowBrandInfoModel: false,
                            });
                        }}
                    /> : null}
                    {isShowAssignBrandModal ? <Modal
                        title="授权品牌赛事"
                        visible={isShowAssignBrandModal}
                        footer={null}
                        destroyOnClose
                        onCancel={this.onCloseAssignBrandModal}
                    >
                        <AssignBrandForm {...{
                            admins, brand: seletionValue
                        }} onSubmit={this.onAssignBrand}/>
                    </Modal> : null}
                </Card>

                {previewVisible ? <Modal width={'80%'} visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <img alt="example" src={previewImage ? previewImage+'?x-oss-process=image/resize,w_690' : ''}/>
                    </div>
                </Modal> : null}
            </div>
        )
    }

    render() {
        return (
            <div>
                <Tabs onChange={this.changeTabPane} type="card">
                    <TabPane tab="品牌赛事列表(使用中)" key="true">
                        {this.state.state === "true" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="品牌赛事列表(下架)" key="false">
                        {this.state.state === "false" ? this.renderTabPane() : null}
                    </TabPane>
                </Tabs>
            </div>
        );
    }

    onGetBrandInfoEvent = async (value, type) => {
        const {isShowBrandInfoModel} = this.state;
        this.setState({
            operationType: type,
            seletionValue: value,
            isShowBrandInfoModel: !isShowBrandInfoModel,
        });
    }

    /**
     * 授权品牌赛事
     */
    onAssignBrand = async (id, adminIds) => {
        const {authorizationPersonBrand, getPersonBrandList} = this.props;
        await authorizationPersonBrand(id, adminIds);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        await getPersonBrandList({
            page: this.state.page, size: this.state.size,
            game: this.state.game ? this.state.game : null,
            name: this.state.name ? this.state.name : null,
            state: this.state.state
        });
        this.setState({isShowAssignBrandModal: false});
    }

    /**
     * 开启品牌赛事授权模态窗事件监听器
     * @param value
     */
    onOpenAssignBrandModal = (value) => {
        this.setState({
            isShowAssignBrandModal: true,
            seletionValue: value
        });
    }

    /**
     * 关闭品牌赛事授权模态窗事件监听器
     */
    onCloseAssignBrandModal = () => {
        this.setState({
            isShowAssignBrandModal: false
        });
    }


    handleCancel = () => this.setState({previewVisible: false});
    showPreviewImage = (value) => {
        this.setState({previewImage: value, previewVisible: true});
    }

    getTableColumns = (games) => [
        {
            title: '品牌ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100
        },
        {
            title: '排序',
            dataIndex: 'randIndex',
            key: 'randIndex',
            align: 'center',
            width: 100
        },
        {
            title: '品牌赛事名',
            dataIndex: 'name',
            align: 'center',
            key: 'name',
            width: 150
        }, {
            title: '游戏类型',
            dataIndex: 'game',
            align: 'center',
            key: 'game',
            width: 150,
            render: (row, text) => (
                <span>{games.filter((item) => item.code == text.game).length > 0 ? games.filter((item) => item.code == text.game)[0].gameName : ""}</span>
            ),
        }, {
            title: '品牌赛事LOGO',
            dataIndex: 'logo',
            align: 'center',
            key: 'logo',
            width: 200,
            render: (row, text) => (
                <div>
                    <img id="myImage" alt={""} src={text.trainingLogo ? text.trainingLogo+'?x-oss-process=image/resize,h_100' : ''} height="100"
                         onClick={() => this.showPreviewImage(text.logo)} style={{width:"100%",height:'100%'}}/>
                </div>
            ),
        }, {
            title: '品牌赛事介绍',
            dataIndex: 'introduction',
            width: 300,
            align: 'center',
            key: 'introduction',
            textWrap: 'word-break', ellipsis: true,
            render: (row, text) => {
                return text.introduction.length > 50 ? text.introduction.slice(0, 50) + "..." : text.introduction;
            }
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
            width: 200
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (row, text) => {
                let {indexVisible} = this.state;
                return (
                    <div>

                        <Popconfirm
                            title={
                                indexVisible ?
                                    <div><span style={{color: "red"}}>请输入1-100（越小优先级越高）</span><br/><InputNumber
                                        style={{width: 260}} placeholder={"请输入1-100（越小优先级越高）"}
                                        defaultValue={text.randIndex}
                                        onChange={(e) => {
                                            this.setState({randIndex: e})
                                        }}/></div> : null}
                            okType="primary"
                            icon={null}
                            onConfirm={() => {
                                this.clickRand(text.id)
                                this.setState({indexVisible: false})
                            }}
                            onCancel={() => {
                                this.setState({indexVisible: false})
                            }}
                            okText="提交"
                            cancelText="取消"
                        >
                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                this.setState({indexVisible: true})
                            }}>排序</Button>
                        </Popconfirm>

                        <Button style={{marginLeft: "10px"}} type="primary"
                                onClick={() => this.onGetBrandInfoEvent(text, operationTypeValue.UPDATE)}
                                color="blue">编辑</Button>
                        {JSON.parse(sessionStorage.getItem(AUTHENTICATION_INFO)) && JSON.parse(sessionStorage.getItem(AUTHENTICATION_INFO)).roleCodes.includes('SUPER_ADMIN') &&
                        whetherButtonPermission(buttonType.ASSIGN_BRAND.value, this.props.pageButtons) &&
                        <Button style={{marginLeft: "10px"}} type="primary"
                                onClick={() => this.onOpenAssignBrandModal(text)} color="blue">授权</Button>}


                        <Button style={{marginLeft: "10px"}} type="danger"
                                onClick={() => this.onUpdateBrandStateConfirm(text.id, this.state.state === "true" ? 'false' : 'true')}
                                color="blue">{this.state.state === "true" ? '下架' : '上架'}</Button>
                    </div>
                )
            },
        }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
        ...state.Brand,
        pageButtons: state.authentication.pageButtons,
        admins: state.admin.admins
    }
};

const mapDispatchToProps = ({
    getGameList,
    getBrandInfo,
    getPersonBrandList,
    createPersonBrand,
    updatePersonBrand,
    authorizationPersonBrand,
    getAdmins,
    getBrand
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Brand));
