import React, {Component} from "react";
import {Form, Input, Button} from "antd";

import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getBrand} from "../../../modules/training/action";

const FormItem = Form.Item;

class TrainingJoinFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.getBrand();
    }

    getBrand=()=>{
        let {getBrand} = this.props;
        getBrand({page:1,size:100000});
    }

    handleSubmit(e) {
        let {onCommit} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onCommit(values);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
           
                <FormItem label="用户名称">
                    {getFieldDecorator('name', )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户ID">
                    {getFieldDecorator('exhibitionId', {
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getBrand
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingJoinFilter)));