import createAsyncAction from 'utils/createAsyncAction';
import PlayerDetailRequest from 'request/PlayerDetailRequest';
import {
    GET_PLAYER_DETAIL_LIST,
    CREATE_PLAYER_DETAIL,
    UPDATE_PLAYER_DETAIL_DATA,
    DELETE_PLAYER_DETAIL,
    CREATE_PLAYER_POST,
    GET_PLAYER_ORDER_LIST_LIST,
    UPDATE_GIFT_ORDER_ADDRESS,
    UPDATE_GIFT_ORDER_SHIP
} from './actionTypes';

const getPlayerDetailList = (params) => (
    createAsyncAction(GET_PLAYER_DETAIL_LIST, () => (PlayerDetailRequest.getPlayerDetailList(params)))
);

const getPlayerPhotoOrderlList = (params) => (
    createAsyncAction(GET_PLAYER_ORDER_LIST_LIST, () => (PlayerDetailRequest.getPlayerPhotoOrderList(params)))
);

const updatePhotoOrderAddress = (orderId, params) => (
    createAsyncAction(UPDATE_GIFT_ORDER_ADDRESS, () => (PlayerDetailRequest.updatePhotoOrderAddress(orderId, params)))
);

const updatePhotoOrderShip = (orderId, params) => (
    createAsyncAction(UPDATE_GIFT_ORDER_SHIP, () => (PlayerDetailRequest.updatePhotoOrderShip(orderId, params)))
);

const createPlayerDetail = (params) => (
    createAsyncAction(CREATE_PLAYER_DETAIL, () => (PlayerDetailRequest.createPlayerDetail(params)))
);

const updatePlayerDetailData = (id, params) => (
    createAsyncAction(UPDATE_PLAYER_DETAIL_DATA, () => (PlayerDetailRequest.updatePlayerDetailData(id, params)))
);

const deletePlayerDetail = (params) => (
    createAsyncAction(DELETE_PLAYER_DETAIL, () => (PlayerDetailRequest.deletePlayerDetail(params)))
);

const createPlayerPost = (params) => (
    createAsyncAction(CREATE_PLAYER_POST, () => (PlayerDetailRequest.createPlayerPost(params)))
);

export {
    getPlayerDetailList,getPlayerPhotoOrderlList, createPlayerDetail, updatePlayerDetailData, deletePlayerDetail, createPlayerPost,updatePhotoOrderAddress,updatePhotoOrderShip
};
