import React, {Component} from 'react';
import {Button, Form, Select} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class ApproveClubModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            clubType: null
        };
    }

    componentDidMount() {
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, clubType) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(clubType);
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <FormItem
                    label="俱乐部类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('clubType', {
                        rules: [{required: true, message: '请选择俱乐部类型', pattern: /^\S+$/}],
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value='COMMON'>普通</Option>
                                <Option value='PEL'>PEL</Option>
                                <Option value='PEN'>PEN</Option>
                                <Option value='PEC'>PEC</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(ApproveClubModel);
