import React, {Component} from "react";
import {Button, Select, DatePicker, Form, Input} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import moment from "moment";

const FormItem = Form.Item;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class WithdrawFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                console.log(values)
                onCommit && onCommit({...values});
            }
        });
    }

    getCurrentYear = () => {
        var date = new Date();

        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        return y + '/' + m;
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline">

                    <FormItem label="用户展示ID">
                        {getFieldDecorator('id', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <Form.Item label='提现状态'>
                        {
                            getFieldDecorator('state', {initialValue: ''})(
                                <Select style={{width: '80px'}}>
                                    <Select.Option value="">所有</Select.Option>
                                    <Select.Option value="INIT">待提现</Select.Option>
                                    <Select.Option value="COMPLETE">已提现</Select.Option>
                                    <Select.Option value="CLOSE">已拒绝</Select.Option>
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item label='提现时间'>
                        {
                            getFieldDecorator('withdrawTime', {initialValue: null})(
                                <RangePicker
                                    defaultValue={[moment(this.getCurrentYear() + '/01', dateFormat)]}
                                    format={dateFormat}
                                />
                            )
                        }
                    </Form.Item>
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>

                </Form>
            </div>
        );
    }
}


const mapStateToProps = (state => {
        return {
            ...state.withdraw,

        }
    }
);

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(WithdrawFilter))));

