import React, {Component} from 'react';
import {Button, Form, Input} from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class TrailPlanForm extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                let {priority,points} = values;
                onSubmitEvent && onSubmitEvent(priority,points);
            }
        });
    }

    render() {
        const {priority,points} = this.props.trailPlanInfo;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>

                <FormItem label="优先级"{...formItemLayout}>
                    {getFieldDecorator('priority', {
                        rules: [{required: true, message: '首页推荐优先级范围未0-100，0为首页不推荐', pattern: /^([1-9]?\d|100)$/}],
                        initialValue: priority
                    })(
                        <Input placeholder="请填写优先级"/>,
                    )}
                </FormItem>
                <FormItem label="点赞数量"{...formItemLayout}>
                    {getFieldDecorator('points', {
                        rules: [{required: true, message: '点赞数需大于0', pattern: /^\+?[0-9]\d*$/}],
                        initialValue: points
                    })(
                        <Input placeholder="请填写点赞数量"/>,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(TrailPlanForm);
