import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";
import {stateType,} from '../../../modules/partner-training/enums';

const FormItem = Form.Item;

class PartnerTrainingTeacherFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        const {getGameList} = props;
        getGameList();
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                console.log(onCommit)
                let {exhibitionId, userName, telephone, state} = values;
                onCommit && onCommit(exhibitionId, userName, telephone, state);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {games} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">


                <FormItem label="导师ID">
                    {getFieldDecorator('exhibitionId', {}
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="导师名">
                    {getFieldDecorator('userName', {}
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="手机号码">
                    {getFieldDecorator('telephone', {}
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label='用户状态'>
                    {
                        getFieldDecorator('state', {initialValue: ''})(
                            <Select style={{width: '80px'}}>
                                <Select.Option value="">所有</Select.Option>
                                {
                                    Object.keys(stateType).map(key => (
                                        <Select.Option
                                            value={stateType[key].value}>{stateType[key].label}</Select.Option>
                                    ))
                                }
                            </Select>
                        )
                    }
                </FormItem>
                {/*<FormItem label="游戏类型">*/}
                {/*{getFieldDecorator('game', {*/}
                {/*initialValue: ""*/}
                {/*}*/}
                {/*)(*/}
                {/*<Select  style={{width: 120}}>*/}
                {/*<Option value="">全部</Option>*/}
                {/*{games.map((item) => <Option value={item.code}*/}
                {/*key={item.code}>{item.gameName}</Option>)}*/}
                {/*</Select>,*/}
                {/*)}*/}
                {/*</FormItem>*/}
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PartnerTrainingTeacherFilter)));