
export const GET_USERS = 'GET_USERS';// 获取用户信息列表
export const GET_USER = 'GET_USER';// 获取用户详情
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';// 操作用户提现金额
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';// 更新用户状态
export const UNBAN_USER_CLAIM = 'UNBAN_USER_CLAIM';// 解绑用户三方登录
export const ADD_USER = 'ADD_USER';// 添加用户
export const UPDATE_USER = 'UPDATE_USER';// 更新用户信息
export const GET_USER_ADDRESS_LIST = 'GET_USER_ADDRESS_LIST'; //获取用户收货地址列表
export const IMPORT_MAJIA = 'IMPORT_MAJIA';// 导入马甲

