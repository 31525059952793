import api from '../utils/api';


class VipRequest {

    /**
     * 获取vip套餐列表
     * @returns {*}
     */
    static getVipPackages() {
        return api.get('/admin/vips/packages', );
    }

    /**
     * 添加vip套餐
     * @returns {*}
     */
    static addVipPackage({packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity}) {
        return api.post('/admin/vips/packages', {packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity});
    }

    /**
     * 修改vip套餐
     * @returns {*}
     */
    static updateVipPackage(packageId,{packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity}) {
        console.log(packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId)
        return api.put(`/admin/vips/packages/${packageId}`,  {packageName,packageDetail,iosAmount,androidAmount,days,recommend,applePayId,recommendRemark,packageRemark,androidDiscountAmount,iosDiscountAmount,wxLiteAmount,wxLiteDiscountAmount,isActivity} );
    }

    /**
     * 删除vip套餐
     * @param packageId
     * @returns {*}
     */
    static deleteVipPackage(packageId) {
        return api.delete(`/admin/vips/packages/${packageId}`);
    }

    /**
     * 获取会员详情
     * @param userId
     */
    static getVip(userId){
        return api.get(`/admin/vips/${userId}`);
    }

    /**
     * 充值VIP天数
     * @param userId
     * @param days
     * @returns {*}
     */
    static rechargeVipDays(userId,days){
        return api.put(`/admin/vips/${userId}/admin`,{days})
    }

    /**
     * 判断用户是否VIP
     * @param userId
     * @returns {*}
     */
    static getVipState(userId){
        return api.get(`/admin/vips/${userId}/vip_state`)
    }

    /**
     * vip活动信息
     * @param page, size,exhibitionId,phone,realName
     * @param days
     * @returns {*}
     */
    static getVipActivity(page, size,exhibitionId,phone,realName){
        return api.get(`/admin/vips/getInformation`,{page, size,exhibitionId,phone,realName})
    }

    /**
     * 删除vip活动信息
     * */
    static deleteVipActivity(activityId){
        return api.delete(`/admin/vips/${activityId}/information`)
    }

    /**
     * 紫金记录
     * */
    static findHistoryLogs(activityId){
        return api.get(`/admin/gold/findHistoryLogs`)
    }


}
export default VipRequest;
