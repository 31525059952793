import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";

const {TextArea} = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 12},
};

const defaultProps = {
  value: {},
  visible: false,
  onClose: () => {
  },
};

class ModifyLikeModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentWillMount() {
    this.onInitData();
  }

  onInitData = (props = this.props) => {
  }

  onSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {onSuccess} = this.props;
        onSuccess && onSuccess(values);
      }
    });
  }

  onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    let {visible, value} = this.props;
    return (
      <Modal
        visible={visible}
        title={this.props.title}
        onCancel={this.props.onClose}
        cancelText="取消"
        onOk={this.onSubmit}
        okText="确认"
      >
          <Form>
              <FormItem
                label="点赞数量"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formItemLayout}
              >
                {getFieldDecorator('likeNum', {
                  initialValue: "",
                //   rules: [{required: true, message: '请输入展示顺序!'}],
                  rules: [{required: true, pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                })(
                    <Input placeholder="请输入点赞数量"/>,
                )}
              </FormItem>
          </Form>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Lineup,
  }
};
const mapDispatchToProps = ({
  getGameList,
});
ModifyLikeModel.defaultProps = defaultProps;
export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(ModifyLikeModel)));
