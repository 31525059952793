import React from 'react';
import {
  Card, Table, Button, Row, Col, message, Modal,
} from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl-context';
import { operationTypeValue } from 'modules/role/enums';
import {
  getViewRoleList,
  createViewRole,
  deleteViewRole,
  getViewRoleInfo,
  updateViewRole,
  getRolePermissionTree,
  updateViewRolePermission,
  getFuncionPermission,
} from 'modules/role/action';
import {
  getViewPermissionTree,
} from 'modules/view-permission/action';
import Permissions from './components/Permissions';
import InfoModel from './components/InfoModel';

const { confirm } = Modal;
class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
      isShowRoleInfoModel: false, // 用户信息
      isShowMenuModel: false, // 权限模板
      seletionValue: null,
      page: 1,
      size: 10,
    };
  }

  componentWillMount() {
    this.onInitData();
    this.onGetTableData();
  }

    onBtnClick = (type, seletionValue) => {
      this.setState({
        operationType: type,
        isShowRoleInfoModel: true,
        seletionValue,
      });
    }

    onDeleteRoleEvent = (seletionValue) => {
      const { deleteViewRole } = this.props;
      confirm({
        title: '删除?',
        content: '是否删除该条数据',
        okType: 'danger',
        onOk: async () => {
          await deleteViewRole(seletionValue.id);
          this.onGetTableData();
          message.success('删除成功');
        },
      });
    }

    renderTableTitle = () => (
      <Row type="flex" justify="end">
        <Col><Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button></Col>
      </Row>
    )

    onFormSubmit = (values) => {
      const { operationType } = this.state;
      switch (operationType) {
        case operationTypeValue.INCREASE:
          this.onCreateRoleEvent(values);
          break;
        case operationTypeValue.update:
          this.onUpdateRoleEvent(values);
          break;
        default:
      }
    }

    // 增加角色信息
    onCreateRoleEvent = async (params) => {
      const { createViewRole } = this.props;
      const { isShowRoleInfoModel } = this.state;
      await createViewRole(params);
      await this.onGetTableData();
      message.success('新增角色成功');
      this.setState({ isShowRoleInfoModel: !isShowRoleInfoModel });
    }

    // 编辑用户信息
    onUpdateRoleEvent = async (params) => {
      const { seletionValue, isShowRoleInfoModel } = this.state;
      const { updateViewRole } = this.props;
      await updateViewRole(params, seletionValue.id);
      await this.onGetTableData();
      message.success('编辑成功');
      this.setState({ isShowRoleInfoModel: !isShowRoleInfoModel });
    }

    onUpdateRoleMenuEvent = async (keys) => {
      const { seletionValue, isShowMenuModel } = this.state;
      const { updateViewRolePermission } = this.props;
      await updateViewRolePermission(keys, seletionValue.id);
      message.success('编辑成功');
      this.setState({ isShowMenuModel: !isShowMenuModel });
    }

    onGetTableData = () => {
      const { getViewRoleList } = this.props;
      const { page, size } = this.state;
      getViewRoleList({ page, size });
    }

    onInitData = () => {
      const { getViewPermissionTree, getFuncionPermission } = this.props;
      return Promise.all([
        getViewPermissionTree(),
        getFuncionPermission(),
      ]);
    }


    onPageChangeEvent = (current, pageSize) => {
      const { onGetTableData } = this.props;
      onGetTableData({ page: current, size: pageSize });
      this.setState({
        page: current,
        size: pageSize,
      });
    }

    render() {
      const {
        roles, rolePermissions, permissionTree, rolefunctions,
      } = this.props;
      const {
        operationType, isShowRoleInfoModel, seletionValue, isShowMenuModel, page, size,
      } = this.state;
      const defaultValue = operationType == operationTypeValue.update && seletionValue;
      return (
        <Card>
          <Table
            dataSource={roles.data}
            title={this.renderTableTitle}
            columns={this.getTableColumns()}
            bordered
            pagination={{
              total: roles.total,
              current: page,
              pageSize: size,
              onChange: this.onPageChangeEvent,
                showTotal:(total)=>{
                    return `总计 ${total}条数据`;
                }
            }}
          />
          <InfoModel
            value={defaultValue}
            onSuccess={this.onFormSubmit}
            visible={isShowRoleInfoModel}
            rolefunctions={rolefunctions}
            onClose={() => {
              this.setState({
                isShowRoleInfoModel: false,
              });
            }}
          />
          {isShowMenuModel ? (
            <Permissions
              visible={isShowMenuModel}
              value={rolePermissions}
              onSuccess={this.onUpdateRoleMenuEvent}
              tree={permissionTree}
              onClose={() => {
                this.setState({
                  isShowMenuModel: false,
                });
              }}
            />
          ) : null}
        </Card>
      );
    }

    onGetRoleMenuInfoEvent = async (value) => {
      const { isShowMenuModel } = this.state;
      const { getRolePermissionTree } = this.props;
      await getRolePermissionTree(value.id);
      this.setState({
        seletionValue: value,
        isShowMenuModel: !isShowMenuModel,
      });
    }

    getTableColumns = () => [{
      title: '角色名',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    }, {
      title: 'code',
      dataIndex: 'code',
      align: 'center',
      key: 'code',
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      key: 'createTime',
    },

    {
      title: '操作',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (row, text) => (
        <div>
          <Button type="danger" onClick={() => this.onDeleteRoleEvent(text)}  >删除</Button>&nbsp;
          <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.update, text)} color="blue">编辑</Button>&nbsp;
          <Button type="primary" onClick={() => this.onGetRoleMenuInfoEvent(text)} color="blue">菜单授权</Button>
        </div>
      ),
    },
    ]
}

const mapStateToProps = (state) => ({
  ...state.viewRole,
  permissionTree: state.viewPermission.permissionTree,
});

const mapDispatchToProps = ({
  getViewRoleList,
  createViewRole,
  deleteViewRole,
  getViewRoleInfo,
  updateViewRole,
  getRolePermissionTree,
  updateViewRolePermission,
  getViewPermissionTree,
  getFuncionPermission,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(Role));
