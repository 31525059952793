import React from 'react';
import {Button, Card, Col, message, Modal, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../modules/team/action";
import {getBrand} from "../../modules/training/action";
import {goodsModel} from '../../modules/partner-training/enums';

import {
    createPartnerGoods,
    deletePartnerGoods,
    getPartnerGoods,
    queryPartnerGoods, updatePartnerGoods,
    updatePartnerGoodsStatus
} from "../../modules/partner-training/action";
import PartnerTrainingGoodsModel from "./components/PartnerTrainingGoodsModel";
import PartnerTrainingGoodsFilter from "./components/PartnerTrainingGoodsFilter";

const {confirm} = Modal;

class PartnerTrainingTeacherManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            operationType: operationTypeValue.INCREASE,
            isShowPartnerTrainingGoodsModel: false,
            seletionValue: null,
            page: 1,
            size: 10,
            goodsName: null,
            game: null,
            goodsModel: null,
            state: null,
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.partnerGoodsList !== this.props.partnerGoodsList) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {queryPartnerGoods, getGameList} = this.props;
        const {page, size, goodsName, game, goodsModel, state} = this.state;
        this.setState({
            loading: true
        })
        queryPartnerGoods({page, size, goodsName, game, goodsModel, state});
        getGameList();
    }

    onPageChangeEvent = (current, pageSize) => {
        const {queryPartnerGoods} = this.props;
        queryPartnerGoods({
            page: current, size: pageSize,
            goodsName: this.state.goodsName ? this.state.goodsName : null,
            game: this.state.game ? this.state.game : null,
            goodsModel: this.state.goodsModel ? this.state.goodsModel : null,
            state: this.state.state ? this.state.state : null
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowPartnerTrainingGoodsModel: true,
            seletionValue: seletionValue,
        });
    }


    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreatePartnerGoodsEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdatePartnerGoodsEvent(values);
                break;
            default:
        }
    }


    onCreatePartnerGoodsEvent = async (values) => {
        const {isShowPartnerTrainingGoodsModel} = this.state;
        const {createPartnerGoods} = this.props;
        await createPartnerGoods(values);
        await this.onGetTableData();
        message.success('编辑成功');
        this.setState({isShowPartnerTrainingGoodsModel: !isShowPartnerTrainingGoodsModel});
    }

    onUpdatePartnerGoodsEvent = async (values) => {
        const {seletionValue, isShowPartnerTrainingGoodsModel} = this.state;
        const {updatePartnerGoods} = this.props;
        await updatePartnerGoods(seletionValue.id, values);
        await this.onGetTableData();
        message.success('修改成功');
        this.setState({isShowPartnerTrainingGoodsModel: !isShowPartnerTrainingGoodsModel});
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterPartnerGoodsEvent = async (goodsName, game, goodsModel, state) => {
        const {size} = this.state;
        const {queryPartnerGoods} = this.props;
        this.setState({
            goodsName: goodsName ? goodsName : null,
            game: game ? game : null,
            goodsModel: goodsModel ? goodsModel : null,
            state: state ? state : null,
            page: 1,
            loading: true
        })
        await queryPartnerGoods({
            page: 1, size: size,
            goodsName: goodsName ? goodsName : null,
            game: game ? game : null,
            goodsModel: goodsModel ? goodsModel : null,
            state: state ? state : null,
        });
    }

    render() {
        const {
            partnerGoodsList, games
        } = this.props;
        const {operationType, isShowPartnerTrainingGoodsModel, seletionValue, page, size, loading
        } = this.state;
        const defaultValue = operationType === operationTypeValue.UPDATE && seletionValue;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PartnerTrainingGoodsFilter dataSource={{}} onCommit={this.onFilterPartnerGoodsEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={partnerGoodsList ? partnerGoodsList.data : []}
                        title={this.renderTableTitle}
                        columns={this.getTableColumns(games)}
                        bordered
                        pagination={{
                            total: partnerGoodsList.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowPartnerTrainingGoodsModel ? <PartnerTrainingGoodsModel
                        value={defaultValue}
                        operationType={operationType}
                        onSuccess={this.onFormSubmit}
                        visible={isShowPartnerTrainingGoodsModel}
                        onClose={() => {
                            this.setState({
                                isShowPartnerTrainingGoodsModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>
        )
    }

    onGetPartnerTrainingGoodsEvent = async (value, type) => {
        const {isShowPartnerTrainingGoodsModel} = this.state;
        this.setState({
            operationType: type,
            seletionValue: value,
            isShowPartnerTrainingGoodsModel: !isShowPartnerTrainingGoodsModel,
        });
    }

    onUpdatePartnerTrainingGoodsStateConfirm = async (id, values) => {
        let _this = this;
        confirm({
            content: '确认要' + (values === 'true' ? '上架' : '下架') + '该陪练商品吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onUpdatePartnerTrainingGoodsStateEvent(id, values);
            },
            onCancel() {
            },

        });
    }
    onUpdatePartnerTrainingGoodsStateEvent = async (id, values) => {
        const {updatePartnerGoodsStatus} = this.props;
        await updatePartnerGoodsStatus(id, values);
        await this.onGetTableData();
        message.success('修改成功');
    }

    getTableColumns = (games) => [
        {
            title: '陪练商品ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
        },
        {
            title: '游戏名称',
            dataIndex: 'game',
            align: 'center',
            key: 'game',
            render: (row, text) => (
                <span>{games.filter((item) => item.code === text.game).length > 0 ? games.filter((item) => item.code === text.game)[0].gameName : ""}</span>
            ),
        },
        {
            title: '商品类型',
            dataIndex: 'type',
            align: 'center',
            key: 'type',
        },
        {
            title: '商品模式',
            dataIndex: 'goodsModel',
            align: 'center',
            key: 'goodsModel',
            render: (text) => (
                text ? goodsModel[text].label : null
            )
        },{
            title: '商品单价',
            dataIndex: 'price',
            align: 'center',
            key: 'price',
            render: (row, text) => {
                let type = text.goodsModel === goodsModel.TIME_CHARGE.value ? '0.5小时' : '局'
                return (text.price ? (text.price+'浪币/'+type) : text.price)
            }
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            align: 'center',
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (row, text) => {
                console.log()
                return (
                    <div>
                        <Button style={{marginLeft: 10, marginBottom: 10}} type={text.state === true ? 'danger' : 'primary'}
                                onClick={() => this.onUpdatePartnerTrainingGoodsStateConfirm(text.id, text.state === true ? 'false' : 'true')}
                                color="blue">{text.state === true ? '下架' : '上架'}</Button>

                        &nbsp;
                        <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                onClick={() => this.onGetPartnerTrainingGoodsEvent(text, operationTypeValue.UPDATE)}>编辑</Button>
                    </div>
                )
            },
        },
        {
            title: '配置人员',
            dataIndex: 'adminName',
            align: 'center',
            key: 'adminName',
        },
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
    }
};

const mapDispatchToProps = ({
    createPartnerGoods,
    updatePartnerGoods,
    deletePartnerGoods,
    updatePartnerGoodsStatus,
    getPartnerGoods,
    queryPartnerGoods,
    getGameList,
    getBrand
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(PartnerTrainingTeacherManagement));
