import React from 'react';
import { Button, Card, Col, InputNumber, message, Modal, Popconfirm, Popover, Row, Table, Tabs, Tag } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl-context';
import { getLiveData, addLive, updateLive, deleteLive } from "../../modules/live/action";
import { getGame } from "../../modules/game/action";
import * as axios from "axios";
import Cookie from "js-cookie";
import { ACCESS_TOKEN } from "../../modules/authentication/constants";
import LiveEditForm from "./components/LiveEditForm";
import LiveFilter from "./components/LiveFilter";

const { confirm } = Modal;

/**
 * 直播
*/
class LiveManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLiveModal: false, //新增窗口
      modalType: '',//展示弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modalData: {},
      pageNo: 1,
      pageSize: 10,
      param: {
        gamingDivision: '',
        date: '',
      },
      id: ''

    };
  }

  componentWillMount() {
    this.props.getGame();
    this.onGetData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    
  }

  //条件查找
  onFilterActivityEvent = (param) => {
    let { getLiveData } = this.props;
    this.setState({
      pageNo: 1,
      pageSize: 10,
      param: { ...param }
    }, () => {
      let obj = {
        page: 1,
        size: 10,
        gamingDivision: param.gamingDivision
      };
  
      if (param.date) {
        obj['date'] = param.date;
      }

      getLiveData(obj);
    });
  }

  onGetData = async () => {
    const { pageNo, pageSize, param } = this.state;

    let { getLiveData } = this.props;
    let obj = {
      page: pageNo,
      size: pageSize,
      gamingDivision: param.gamingDivision
    };

    if (param.date) {
      obj['date'] = param.date;
    }

    await getLiveData(obj);
  }

  //转换成游戏名字
  changeGameName = (v) => {
    let { gameList } = this.props;
    let gameName;
    for (let i = 0; i < gameList.length; i++) {
      if (gameList[i].code === v) {
        gameName = gameList[i].gameName;
        break
      }
    }
    return gameName;
  }

  //查看直播配置
  viewLiveData = (option) => {
    this.setState({ 
      modalData: option,
      modalType: 'EDIT'
    }, () => {
      this.setState({
        showLiveModal: true
      });
    });
  }

  //编辑直播
  updateLive = async (param) => {
    let { updateLive } = this.props;

    await updateLive(param);

    message.success('编辑成功');
    this.onGetData();
  }

  //新增直播
  addLive = async (param) => {
    let { addLive } = this.props;

    await addLive(param)
    
    message.success('新增成功');
    this.onGetData();
  }

  //删除直播-二次确认
  confirmDel = (id) => {
    let _this = this;

    confirm({
      content: '确定要删除这次直播吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.deleteLive(id)
      },
      onCancel() {}
    });
  }

  //删除直播
  deleteLive = async (id) => {
    let { deleteLive } = this.props;

    await deleteLive(id);

    message.success('删除成功');
    this.onGetData();
  }

  //保存直播配置
  onEditLive = (value) => {
    const { modalType } = this.state;

    this.setState({ 
      showLiveModal: false 
    });
    
    if (modalType === 'EDIT') {
      this.updateLive(value);
    } else if (modalType === 'ADD') {
      this.addLive(value);
    }
  }

  renderTableTitle = () => (
    <Row type="flex" justify="end">
      <Col>
        <Button type="primary" onClick={() => this.setState({ showLiveModal: true, modalType: 'ADD' })}>新增</Button>
      </Col>
    </Row>
  )

  render() {
    let { liveList, gameList } = this.props;
    let { showLiveModal, pageNo, pageSize, modalType, modalData, id } = this.state;

    return (
      <div>
        <Card style={{ marginBottom: 30 }}>
          <LiveFilter onCommit={this.onFilterActivityEvent} gameList={gameList} />
        </Card>
        <Card>
          <Table
            columns={
              [
                {
                  title: '直播ID',
                  dataIndex: 'id',
                  key: 'id',
                  align: 'center',
                },
                {
                  title: '直播开始时间',
                  dataIndex: 'startTime',
                  key: 'startTime',
                  align: 'center',
                },
                {
                  title: '游戏分部',
                  dataIndex: 'gamingDivision',
                  key: 'gamingDivision',
                  align: 'center',
                  render: (row, text) => (
                    <span>{this.changeGameName(text.gamingDivision)}</span>
                  )
                },
                {
                  title: '创建时间',
                  dataIndex: 'createTime',
                  key: 'createTime',
                  align: 'center',
                },
                {
                  title: '操作',
                  dataIndex: 'operate',
                  key: 'operate',
                  align: 'center',
                  render: (row, text) => (
                    <div>
                      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => {
                        this.viewLiveData(text)
                      }} color="blue">编辑</Button>
                      <Button type="danger" style={{ marginLeft: 10, marginBottom: 10 }} onClick={() => {
                        this.confirmDel(text.id)
                      }} color="red">删除</Button>
                    </div>
                  )
                }
              ]
            }

            title={this.renderTableTitle}
            dataSource={liveList ? liveList.data : []}
            bordered
            //loading={loading}
            pagination={{
              total: liveList ? liveList.total : 0,
              current: pageNo,
              pageSize: pageSize,
              onChange: this.onPageChangeEvent,
              showTotal: (total) => {
                return `总计 ${total}条数据`;
              }
            }}
          />

          {showLiveModal && <LiveEditForm
            onSuccess={this.onEditLive}
            visible={showLiveModal}
            type={modalType}
            data={modalData}
            gameList={gameList}
            id={id}
            changeGameName={this.changeGameName}
            onClose={() => {
              this.setState({
                showLiveModal: false,
              });
            }}
          />}
        </Card>
      </div>
    );
  }

  //翻页
  onPageChangeEvent = (current, pageSize) => {
    const { getLiveData } = this.props;
    const { param } = this.state;
    this.setState({
      pageNo: current,
      pageSize: pageSize,
    }, () => {
      getLiveData({ page: current, size: pageSize, ...param })
    });
  }

}

const mapStateToProps = (state) => {
  return {
    ...state.Live,
    ...state.viewGame
  }
};

const mapDispatchToProps = ({
  getLiveData,
  updateLive,
  addLive,
  deleteLive,
  getGame
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LiveManagement));
