import api from '../utils/api';

class ReportRequest {

    /**
     * 获取举报列表
     * @param pageNo
     * @param pageSize
     * @param type
     * @param violator 违规方
     * @param prosecutor 检举方
     * @returns {*}
     */
    static getReports(page, size, type,processResult) {
        return api.get('/admin/users/reports', {page, size, type,processResult});
    }

    /**
     * 获取举报详情
     * @param reportId
     * @returns {*}
     */
    static getReport(reportId) {
        return api.get(`/admin/users/reports/${reportId}`);
    }

    /**
     * 删除举报
     * @param reportId
     * @returns {*}
     */
    static deleteReport(reportId) {
        return api.delete(`/admin/users/reports/${reportId}`);
    }

    /**
     *
     * @param id
     * @returns {Promise<*|{res, name, url}>|Promise<*|{res, name, url}>|*|Promise<AxiosResponse<T>>|void|Observable<AjaxResponse>|IDBRequest<IDBValidKey>|Promise<void>}
     */
    static assign(id,processResult) {
        return api.put(`/admin/users/reports/${id}`,{processResult})
    }

    /**
     * 获取用户反馈列表
     * @param page
     * @param size
     * @returns {*}
     */
     static getFeedbackList(param) {
        return api.get('/admin/feedback/getByPage', param);
    }

    /**
     * 删除用户反馈
     * @param id
     * @returns {*}
     */
     static deleteFeedback(id) {
        return api.delete(`/admin/feedback/${id}`);
    }
}

export default ReportRequest;