import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
class TrialPlanFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {name} = values;

                onCommit && onCommit(name);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="俱乐部名称">
                    {getFieldDecorator('name', {
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);


//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
) (Form.create()(withRouter(injectIntl(TrialPlanFilter))));

