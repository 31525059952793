import React, {Component} from "react";
import {Card, message, Modal, notification, Table} from 'antd';
import {bindClub, getTrial, optTrial, untyingClub,deleteTrain,replyTrain} from '../../../../src/modules/club/action';
import {connect} from "react-redux";
import Button from "antd/lib/button";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import TrialFilter from "../components/TrialFilter";

const {confirm} = Modal;

class TrialManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            clubName: null,
            userName: null,
            userId: null,
            dateRange: null,
            trialParams: {
                clubId: null,
                trailPageTyp: 'MANAGER'
            },
        };
    }


    componentDidMount() {
        this.onGetData();
    }

    renderTrailDate = (text) => {
        if (text === "null null") {
            return (
                "未预约"
            )
        } else {
            return text
        }
    }

    renderState = (text) => {

        if (text === 'REJECT') {
            return (
                <div color="blue">已驳回</div>
            )
        } else if (text === 'APPROVED') {
            return (
                <div color="blue">通过</div>
            )
        } else if (text === 'SUBMIT') {
            return (
                <div color="blue">未审核</div>
            )
        }
        //CONFIRM_RESERVEEND_TRAIL, , ,
        else if (text === 'CREATE') {
            return (
                <div color="blue">已创建</div>
            )
        } else if (text === 'CONFIRM_RESERVE') {
            return (
                <div color="blue">用户确认预约</div>
            )
        } else if (text === 'RESERVE') {
            return (
                <div color="blue">管理员预约试训</div>
            )
        } else if (text === 'START_TRAIL') {
            return (
                <div color="blue">开始试训</div>
            )
        } else if (text === 'CONFIRM_START_TRAIL') {
            return (
                <div color="blue">确认开始试训</div>
            )
        } else if (text === 'END_TRAIL') {
            return (
                <div color="blue">结束试训</div>
            )
        } else if (text === 'CONFIRM_END_TRAIL') {
            return (
                <div color="blue">确认结束试训</div>
            )
        } else if (text === 'COMPLETE') {
            return (
                <div color="blue">试训已完成</div>
            )
        } else if (text === 'CANCEL') {
            return (
                <div color="blue">已取消</div>
            )
        } else if (text === 'REJECT') {
            return (
                <div color="blue">用户拒绝预约</div>
            )
        }
    }

    onReplyTrain = async (id) =>{

        const {replyTrain,getTrial} = this.props;
        const {pageNo, pageSize} = this.state

        try {
            await replyTrain(id);
            notification.success({ message: '恢复成功' });
            this.setState({
                isShowPlanModal : false,
                isShowSinglePlanModal : false
            });

            getTrial(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    }

    renderOpt = (state, id,deleted) => {
        if (state === 'CREATE' || state === 'RESERVE' || state === 'CONFIRM_RESERVE' || state === 'START_TRAIL') {
            return (
                <div>
                <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => this.onCancelEvent(id)}>取消试训</Button>

                    {/*{deleted === false ? <Button style={{marginLeft: 10, marginBottom: 10}} type="danger" onClick={() => this.onDeleteTrain(id)}>删除试训</Button> : <Button style={{backgroundColor: '#00a854' ,color : '#fff',marginLeft: 10, marginBottom: 10}} onClick={() => this.onReplyTrain(id)}>恢复试训</Button>}*/}

                </div>
            )
        } else {
            return (
                <div>--</div>
            )
        }
    }

    onCancelEvent = (id) => {
        const {optTrial, getTrial} = this.props;
        const {pageNo, pageSize} = this.state
        //trialParams.clubId = id
        confirm({
            title: '取消?',
            content: '是否取消该次试训',
            okType: 'danger',
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                await optTrial(id, {cancel: true});
                message.success('取消成功');
                //this.onGetData();
                this.setState({}, () => {
                    getTrial(pageNo, pageSize)
                })

            },
        });
    }


    onDeleteTrain = async (id) =>{
        const {deleteTrain,getTrial} = this.props;
        const {pageNo, pageSize} = this.state

        try {
            await deleteTrain(id);
            notification.success({ message: '删除成功' });
            this.setState({
                isShowPlanModal : false,
                isShowSinglePlanModal : false
            });

            getTrial(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }


    }

    render() {
        const {trialList} = this.props;
        console.log("trialList", trialList)
        const {pageNo, pageSize,} = this.state;

        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrialFilter onCommit={this.onFilterTrialEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'username',
                                    key: 'username',
                                    align: 'center',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '俱乐部',
                                    dataIndex: 'clubName',
                                    key: 'clubName',
                                    align: 'center',
                                },
                                {
                                    title: '试训日期',
                                    dataIndex: 'trailDate',
                                    key: 'trailDate',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {this.renderTrailDate(text.trailDate + " " + text.startTime)}
                                        </div>
                                    )
                                },
                                {
                                    title: '试训状态',
                                    dataIndex: 'state',
                                    key: 'state',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {this.renderState(text.state)}
                                        </div>
                                    )
                                },
                                {
                                    title: '删除状态',
                                    dataIndex: 'deleted',
                                    key: 'deleted',
                                    align: 'center',
                                    render(row, text)  {
                                        if(text.deleted === false){
                                            return "未删除";
                                        }
                                        if (text.deleted === true){
                                            return "已删除";
                                        }
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {this.renderOpt(text.state, text.id,text.deleted)}
                                        </div>
                                    ),
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={trialList ? trialList.data : []}
                        bordered
                        //loading={loading}
                        pagination={{
                            total: trialList ? trialList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        );
    }

    //条件查找试训方法
    onFilterTrialEvent = async (clubName, dateRange, userId, userName) => {
        console.log(clubName, dateRange, userId, userName)
        let {getTrial} = this.props;
        this.setState({
            pageNo: 1,
            pageSize: 10,
            clubName: clubName,
            userName: userName,
            userId: userId,
            dateRange: dateRange,
        }, () => {
            getTrial({page: 1, size: 10, userId, userName, clubName, dateRange})
        })
    }

    onGetData = () => {
        const {pageNo, pageSize} = this.state;
        let {getTrial} = this.props;
        console.log("pageNo", this.state)
        getTrial({page: pageNo, size: pageSize});
    }


    //翻页

    onPageChangeEvent = (current, pageSize) => {
        const {getTrial} = this.props;
        const {clubName, userName, userId, dateRange} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        }, () => {
            getTrial({page: current, size: pageSize, clubName, userName, userId, dateRange})
        });
    }

    onPageOnShowSizeChange = (current, pageSize) => {
        //getClub({ pageNo: current, pageSize: pageSize });
    }

    //

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        //console.log("state",state)
        return {
            ...state.viewClub,

        }
    }
);


const mapDispatchToProps = {
    bindClub,
    untyingClub,
    getTrial,
    optTrial,
    deleteTrain,
    replyTrain
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(TrialManagement)));
