import createAsyncAction from 'utils/createAsyncAction';
import ArticleRequest from '../../request/ArticleRequest' ;
import {
    GET_ARTICLE,
    GET_ARTICLES,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    CREATE_ARTICLE,
    CHANGE_ARTICLE_STATUS,
    GET_GAMES,
} from '../article/actionTypes'

const getArticles = (pageNo,pageSize) => (
    createAsyncAction(GET_ARTICLES,() => (ArticleRequest.getArticles(pageNo,pageSize)))
);

const getArticle = (id) => (
    createAsyncAction(GET_ARTICLE,() => (ArticleRequest.getArticle(id)))
);

const updateArticle = (id,{title, author, imageUrl, detail, remark, type, enable,top,tag}) => (
    createAsyncAction(UPDATE_ARTICLE,() => (ArticleRequest.updateArticle(id,{title, author, imageUrl, detail, remark, type, enable,top,tag})))
);

const deleteArticle = (id) => (
    createAsyncAction(DELETE_ARTICLE,() => (ArticleRequest.deleteArticle(id)))
);

const createArticle = ({title, author, imageUrl, detail, remark, type, enable,creator,top,tag}) => (
    createAsyncAction(CREATE_ARTICLE,() => (ArticleRequest.addArticle({title, author, imageUrl, detail, remark, type, enable,creator,top,tag})))
)

const changeStatus = (id,enable) => (
    createAsyncAction(CHANGE_ARTICLE_STATUS,() => (ArticleRequest.changeStatus(id,enable)))
)

const getGames = () => (
    createAsyncAction(GET_GAMES,() => (ArticleRequest.getGames()))
)

export {
    getArticle,getArticles,createArticle,updateArticle,deleteArticle,changeStatus,getGames
}
