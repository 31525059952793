import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, Table, Row, Col, message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getPrizes,updatePrize} from "../../modules/lottery/action";
import UpdatePrizesModel from "./components/UpdatePrizesModel";

class LotteryPrizesManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            loading: false,
            seletionValue: null,
            isShowUpdatePrizesModel: false,
        };
    }

    componentWillMount(){
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const { getPrizes} = props;
        this.setState({
            loading: true
          })
        await getPrizes();
    }
    
    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getPrizes} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getPrizes(current, pageSize);
    }
    
    onBtnClick = (value) => {
        console.log("text",value);
        this.setState({
          isShowUpdatePrizesModel: true,
          seletionValue: value,
        });
    }

    onFormSubmit = (values) => {
        this.onUpdateTopicEvent(values);
    }

    // 修改
    onUpdateTopicEvent = async (params) => {
        const {isShowUpdatePrizesModel,seletionValue} = this.state;
        const {updatePrize} = this.props;
        await updatePrize(seletionValue.prizeId ,params);
        await this.onInitData();
        message.success('修改成功');
        this.setState({isShowUpdatePrizesModel: !isShowUpdatePrizesModel});
    }   

    render() {
        const {prizes, loading} = this.props;
        const {pageNo, pageSize,isShowUpdatePrizesModel, seletionValue} = this.state;
        return (
            <div>

                <Card style={{width: 'auto'}}>
                    <Table

                        columns={
                            [
                                {
                                    title: '奖品ID',
                                    dataIndex: 'prizeId',
                                    key: 'prizeId',
                                    align: 'center',
                                    width: "7%",
                                },
                                {
                                    title: '奖品名称',
                                    dataIndex: 'name',
                                    key: 'name',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '奖品类型',
                                    dataIndex: 'turnTablePrizeType',
                                    key: 'turnTablePrizeType',
                                    align: 'center',
                                    width: "10%",
                                    render: (row, text) => (
                                        <div>
                                            {text.turnTablePrizeType === 'IN_KIND_GIFT' ? '实物周边' : ''}
                                            {text.turnTablePrizeType === 'VIP' ? 'VIP会员（虚拟礼品）' : ''}
                                            {text.turnTablePrizeType === 'ENERGY' ? '能量值（虚拟礼品）' : ''}
                                            {text.turnTablePrizeType === 'GOLD' ? '紫金（虚拟礼品）' : ''}
                                        </div>
                                    )
                                },
                                {
                                    title: '中奖概率',
                                    dataIndex: 'percent',
                                    key: 'percent',
                                    align: 'center',
                                    width: "10%",
                                    render: (row, text) => (
                                        <div>
                                            {text.percent + '%'}
                                        </div>
                                    )
                                },
                                {
                                    title: '奖品数量',
                                    dataIndex: 'totalStock',
                                    key: 'totalStock',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '已兑换数量',
                                    dataIndex: 'soldStock',
                                    key: 'soldStock',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    width: "10%",
                                    render: (imgUrl, record, index) => {
                                        return <img src={imgUrl ? imgUrl+'?x-oss-process=image/resize,w_200' : ''} alt={""} style={{width: 100, height: 100}}/>;
                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                            this.onBtnClick( text)
                                        }}>编辑</Button>
                                        </div>)

                                    },
                                },
                            ]
                        }
                        dataSource={prizes.length>0 ? prizes : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: prizes ? prizes.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    {isShowUpdatePrizesModel && <UpdatePrizesModel
                        onSuccess={this.onFormSubmit}
                        visible={isShowUpdatePrizesModel}
                        data={seletionValue}
                        onClose={() => {
                            this.setState({
                                isShowUpdatePrizesModel: false,
                            });
                        }}
                    />}                    
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.Lottery
        }
    }
);

const mapDispatchToProps = {
    getPrizes,
    updatePrize,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(LotteryPrizesManagement)));