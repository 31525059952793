import api from '../utils/api';

class AdminOperationLogRequest {

    static getAdminOperationLogs(adminName, userName,userExhibitionId, type, startDate, endDate, page, size){
        return api.get(`/admin/operation-logs`,{adminName, userName,userExhibitionId, type, startDate, endDate, page, size});
    }
}

export default AdminOperationLogRequest;
