import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, Tag, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import PostForm from './PostForm'
import PostFilter from "./PostFilter";
import {operationTypeValue} from "../../modules/topic/topicEnum";
import { getPostList, getPostDetail, updatePost,updatePostLike,updatePostHot,importPostComments,majiaCreatePost, updatePostSetting } from '../../modules/topic/action';
import {map} from 'lodash/fp';
import ModifyLikeModel from "./components/ModifyLikeModel";
import ModifyHotModel from "./components/ModifyHotModel";
import MajiaPostModal from "./components/MajiaPostModal";
import MajiaImportCommentModal from "./components/MajiaImportCommentModal";

class PostManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.UPDATE, // 默认操作类型为编辑
            pageNo: 1,
            pageSize: 10,
            isShowEditAdminModal: false,
            authorExId: null,
            postId: null,
            isShowModifyLikeModel:false,
            isShowModifyHotModel:false,
            isShowMajiaPostModal: false,
            isShowMajiaImportCommentModal: false,
            selectedRowKeys: [],
            selectIds:[],
        };
    }

    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getPostList} = this.props;
        getPostList(pageNo, pageSize);
    }

    // 编辑（或查看）帖子
    onOpenPostModalEvent = (text, postId, type) => {
        const { getPostDetail } = this.props;

        getPostDetail(postId);
        this.setState({
            isShowEditAdminModal: true,
            operationType: type,
            authorExId: text.exhibitionId,
            postId
        });
    }

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        const {
            postList
        } = this.props;
        let data = postList.data;
        let selectIds = [];
        for(let i=0; i<selectedRowKeys.length;i++) {
            selectIds.push(data[selectedRowKeys[i]].id);
        }

        this.setState({ selectedRowKeys,selectIds});
    };

    // 修改点赞
    onModifyLikeEvent = (text, postId) => {
        const { getPostDetail } = this.props;

        this.setState({
            isShowModifyLikeModel: true,
            authorExId: text.exhibitionId,
            postId
        });
    }

    // 修改热度
    onModifyHotEvent = (text, postId) => {
        const { getPostDetail } = this.props;

        this.setState({
            isShowModifyHotModel: true,
            authorExId: text.exhibitionId,
            postId
        });
    }

    onClosePostModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    // 编辑帖子信息
    onEditPostEvent = ({authorExId, topicId, imageUrlList, content,title}) => {
        const {postId, pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        const { getPostList, updatePost } = this.props;
        try {
            updatePost(postId, {
                authorExId, topicId,
                imageUrlList: imageUrlList || [],
                content,
                title
            }).then(json => {
                notification.success({message: '更新成功'});
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                this.setState({
                    isShowEditAdminModal: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    onModifyLikeSubmit = (values) => {
        const {postId, pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        const { getPostList, updatePostLike } = this.props;
        try {
            updatePostLike({
                'dynamicId':postId,
                'likeNum':values.likeNum
            }).then(json => {
                notification.success({message: '更新成功'});
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                this.setState({
                    isShowModifyLikeModel: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    onModifyHotSubmit = (values) => {
        const {postId, pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        const { getPostList, updatePostHot } = this.props;
        try {
            updatePostHot({
                'dynamicId':postId,
                'hotCoefficient':values.hotNum
            }).then(json => {
                notification.success({message: '更新成功'});
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                this.setState({
                    isShowModifyHotModel: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    onMajiaCreatePostBtnClick() {
        this.setState({
            isShowMajiaPostModal: true,
        });
    }

    onMajiaImportCommwnrBtnClick() {
        this.setState({
            isShowMajiaImportCommentModal: true,
        });

    }

    onMajiaImportCommnetSubmit = (values) => {
        const {postId, pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        const { getPostList, importPostComments } = this.props;
        try {
            values['commentThemeType'] = 'POST';
            importPostComments(values).then(json => {
                notification.success({message: '评论导入成功'});
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                this.setState({
                    isShowMajiaImportCommentModal: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    onMajiaPostSubmit = (values) => {
        const {postId, pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        const { getPostList, majiaCreatePost } = this.props;
        try {
            majiaCreatePost(values).then(json => {
                notification.success({message: '发帖成功'});
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                this.setState({
                    isShowMajiaPostModal: false
                });
            }).catch(err => {
                console.log("editPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let { getPostList } = this.props;
        let { exhibitionId, username, keyword, topicName, shield, startTime, endTime } = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getPostList(current, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
    }

    //筛选帖子
    onFilterPostEvent = (exhibitionId, username, keyword, topicName, shield, dateRange) => {
        const { getPostList } = this.props;
        const { pageSize } = this.state;
        let startTime = null, endTime = null;
        if (dateRange !== undefined) {
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0]+" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1]+" 23:59:59";
        }
        this.setState({
            exhibitionId, username, keyword, topicName, shield, startTime, endTime
        });
        getPostList(1, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
    }

    // 管理帖子
    onSetPostEvent = (fieldName, value, id) => {
        const { getPostList, updatePostSetting } = this.props;
        const {pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime} = this.state;
        let type = '';
        switch(fieldName) {
            case 'quintessence':
                type = value ? 'CANCEL_QUINTESSENCE' : 'QUINTESSENCE';
                break;
            case 'top':
                type = value == 'TOP' ? 'CANCEL_TOP' : 'TOP';
                break;
            case 'bottom':
                type = value == 'BOTTOM' ? 'CANCEL_BOTTOM' : 'BOTTOM';
                break;
            case 'shield':
                type = value ? 'CANCEL_SHIELD' : 'SHIELD';
                break;
            case 'hotTop':
                type = value == 'TOP' ? 'CANCEL_HOT_TOP' : 'HOT_TOP';
                break;
            case 'hotBottom':
                type = value == 'BOTTOM' ? 'CANCEL_HOT_BOTTOM' : 'HOT_BOTTOM';
                break;
            case 'lockPost':
                type = value ? 'CANCEL_LOCK_POST' : 'LOCK_POST';
                break;
        }
        try {
            updatePostSetting(id, { type }).then(json => {
                console.log("setPostResult: ", json);
                getPostList(pageNo, pageSize, exhibitionId, username, keyword, topicName, shield, startTime, endTime);
                notification.success({message: '操作成功'});
            }).catch(err => {
                console.log("setPostErr: ", err);
            });
        } catch (e) {
            console.log(e);
        }
    }

    renderTableTitle = () => {
        const {
            postData, loading, postList
        } = this.props;
        return ( <Row type="flex" justify="space-between">
            <Col>
                <div style={{display:'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom:10
                }}>
                    <div style={{marginRight:10}}>
                        总评论数：{postList.totalCommentNum ? postList.totalCommentNum : 0}
                    </div>
                    <div>
                        总点赞数：{postList.totalThumbNum ? postList.totalThumbNum : 0}
                    </div>
                </div>
            </Col>
            <Col>
                <Button style={{marginRight:10}} type="primary" onClick={() => this.onMajiaImportCommwnrBtnClick()}>注入评论</Button>
                <Button type="primary" onClick={() => this.onMajiaCreatePostBtnClick()}>马甲发帖</Button>
            </Col>
        </Row>);
    }





    render() {
        console.log(this.props.images)
        const {
            postData, loading, postList
        } = this.props;
        const {
            selectedRowKeys,selectIds,isShowEditAdminModal, pageNo, pageSize, operationType,isShowModifyLikeModel,isShowModifyHotModel,isShowMajiaPostModal,isShowMajiaImportCommentModal
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <PostFilter dataSource={{}} onCommit={this.onFilterPostEvent}></PostFilter>
                </Card>
                <Card>


                    <Table
                        columns={
                            [
                                {
                                    title: '帖子ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '发帖用户ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '发帖用户名',
                                    dataIndex: 'username',
                                    key: 'username',
                                    align: 'center',
                                },
                                // {
                                //     title: '帖子标题',
                                //     dataIndex: 'content',
                                //     key: 'content',
                                //     align: 'center',
                                // },
                                {
                                    title: '帖子内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenPostModalEvent(text, text.id, operationTypeValue.SELECT)}
                                                    color="blue">
                                                    查看
                                                </Button>
                                    }
                                },
                                {
                                    title: '话题名',
                                    dataIndex: 'topicName',
                                    key: 'topicName',
                                    align: 'center',
                                },
                                {
                                    title: '评论数',
                                    dataIndex: 'commentNum',
                                    key: 'commentNum',
                                    align: 'center',
                                },
                                {
                                    title: '点赞数',
                                    dataIndex: 'thumbNum',
                                    key: 'thumbNum',
                                    align: 'center',
                                },
                                {
                                    title: '热度值',
                                    dataIndex: 'hotCoefficient',
                                    key: 'hotCoefficient',
                                    align: 'center',
                                },
                                {
                                    title: '帖子状态',
                                    key: 'shield',
                                    dataIndex: 'shield',
                                    align: 'center',
                                    render: (shield) => {
                                        return shield ? '屏蔽' : '正常';
                                    }
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return  <div>
                                                    <Button type="primary"
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onOpenPostModalEvent(text, text.id, operationTypeValue.UPDATE)}
                                                            color="blue">
                                                        编辑
                                                    </Button>
                                                    <Button type={text.quintessence ? "dashed" : "primary"}
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onSetPostEvent('quintessence', text.quintessence, text.id)}
                                                            color="blue">
                                                        {text.quintessence ? '精华帖' : '加精'}
                                                    </Button>
                                                    <Popconfirm title={text.lockPost ? '确定要将该帖子取消锁帖吗？' : '确定要将该帖子锁帖吗？'}
                                                                onConfirm={() => this.onSetPostEvent('lockPost', text.lockPost, text.id)}
                                                                okText="确认" cancelText="取消">
                                                        <Button type={text.lockPost ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.lockPost ? '已锁帖' : '锁帖'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Button type={text.top == "TOP" ? "dashed" : "primary"}
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onSetPostEvent('top', text.top, text.id)}
                                                            color="blue">
                                                        {text.top == "TOP" ? '已圈子置顶' : '圈子置顶'}
                                                    </Button>
                                                    <Button type={text.hotTop == "TOP" ? "dashed" : "primary"}
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onSetPostEvent('hotTop', text.hotTop, text.id)}
                                                            color="blue">
                                                        {text.hotTop == "TOP" ? '已热门置顶' : '热门置顶'}
                                                    </Button>
                                                    <Popconfirm title={text.top == "BOTTOM" ? '确定要将该帖子取消沉底吗？' : '确定要将该帖子沉底吗？'}
                                                                onConfirm={() => this.onSetPostEvent('bottom', text.top, text.id)}
                                                                okText="确认" cancelText="取消">
                                                        <Button type={text.top == "BOTTOM" ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.top == "BOTTOM" ? '已圈子沉底' : '圈子沉底'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Popconfirm title={text.hotTop == "BOTTOM" ? '确定要将该帖子取消沉底吗？' : '确定要将该帖子沉底吗？'}
                                                                onConfirm={() => this.onSetPostEvent('hotBottom', text.hotTop, text.id)}
                                                                okText="确认" cancelText="取消">
                                                        <Button type={text.hotTop == "BOTTOM" ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.hotTop == "BOTTOM" ? '已热门沉底' : '热门沉底'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Popconfirm title={text.shield ? '确定要取消屏蔽该帖子吗？' : '确定要屏蔽该帖子吗？'}
                                                                onConfirm={() => this.onSetPostEvent('shield', text.shield, text.id)}
                                                                okText="确认" cancelText="取消"><br/>
                                                        <Button type={text.shield ? "dashed" : "danger"}
                                                                style={{marginLeft: 10, marginBottom: 10}}>
                                                            {text.shield ? '已屏蔽' : '屏蔽'}
                                                        </Button>
                                                    </Popconfirm>
                                                    <Button type="primary"
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onModifyLikeEvent(text, text.id)}
                                                            color="blue">
                                                        修改点赞
                                                    </Button>
                                                    <Button type="primary"
                                                            style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.onModifyHotEvent(text, text.id)}
                                                            color="blue">
                                                        修改热度
                                                    </Button>
                                                </div>
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        rowSelection={rowSelection}
                        dataSource={postList.data ? postList.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: postList ? postList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑帖子" : "查看帖子内容"}
                        visible={isShowEditAdminModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onClosePostModalEvent}
                    >
                        <PostForm {...this.props} {...this.state} {...postData} onSubmitEvent={this.onEditPostEvent}/>
                    </Modal>

                    <MajiaPostModal
                        title="马甲发帖"
                        onSuccess={this.onMajiaPostSubmit}
                        visible={isShowMajiaPostModal}
                        onClose={() => {
                            this.setState({
                                isShowMajiaPostModal: false,
                            });
                        }}
                    />
                    <MajiaImportCommentModal
                        title="马甲注入评论"
                        selectIds={selectIds}
                        onSuccess={this.onMajiaImportCommnetSubmit}
                        visible={isShowMajiaImportCommentModal}
                        onClose={() => {
                            this.setState({
                                isShowMajiaImportCommentModal: false,
                            });
                        }}
                    />
                 <ModifyLikeModel
                        title="编辑帖子点赞数"
                        operationType={operationType}
                        onSuccess={this.onModifyLikeSubmit}
                        visible={isShowModifyLikeModel}
                        onClose={() => {
                            this.setState({
                                isShowModifyLikeModel: false,
                            });
                        }}
                    />
                    <ModifyHotModel
                        title="编辑帖子热度值"
                        operationType={operationType}
                        onSuccess={this.onModifyHotSubmit}
                        visible={isShowModifyHotModel}
                        onClose={() => {
                            this.setState({
                                isShowModifyHotModel: false,
                            });
                        }}
                    />
                </Card>
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Banner,
            ...state.innerJumpList,
            ...state.images,
            ...state.Topics
        }

    }

);

const mapDispatchToProps = {
    getPostList,
    getPostDetail,
    updatePost,
    updatePostSetting,
    updatePostLike,
    updatePostHot,
    importPostComments,
    majiaCreatePost
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(PostManagement)));
