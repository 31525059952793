import React, {Component} from 'react';
import {
    Form, Button, Select
} from 'antd';
import UpLoad from "../../components/upload";
import {

    getImage
} from "../../modules/banner/action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class UpdateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypes: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            jumpType: this.props.isOuter,
            innerJumpType:this.props.innerJumpType,
            state : this.props.state,

        };
    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                console.log(values)
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }


    // componentWillReceiveProps(nextProps, nextContext) {
    //     if (nextProps!=null){
    //         if (this.props.images!=null){
    //             this.setState({
    //                 imageUrl : this.props.images.imageUrl,
    //                 enable : this.props.images.enable,
    //                 pageType : this.props.images.pageType,
    //
    //             })
    //         }
    //     }
    // }
    componentDidMount() {


    }

    onChangeEvent = (value) =>{




    }


    render() {
        console.log(this.props)
        const {getFieldDecorator} = this.props.form;
        const {url} = this.props;
        return (
            <Form>
                <FormItem
                    label="图片(690*240)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrl', {
                        initialValue: url ? url+'?x-oss-process=image/resize,w_86' : "",
                    })(
                        (<UpLoad
                            double={false}
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={251}
                        />),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button type="primary" onClick={this.onSubmitEvent}>提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}
const mapStateToProps = (state => {
        console.log(state.Banner)
        return {

        }

    }

);

const mapDispatchToProps = {



};

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
// )((Form.create(ValuationForm)));
export default Form.create()(UpdateForm);
