import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getUser, updateUserCertification,} from "../../../modules/certification/action";
import UpLoad from "../../../components/upload";

const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class UpdateCertificationModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: ""
        };
    }

    componentDidMount() {
        let {value} = this.props;
        console.log("40:", value)
    }

    onSubmitEvent = () => {
        console.log("40")
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('onSubmitEvent',values)
                let {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange = (checked) => {
        console.log(`switch to ${checked}`);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {value, visible} = this.props;

        return (
            <Modal
                visible={visible}
                title={value ? "修改游戏实力认证" : "新增游戏实力认证"}
                onCancel={this.props.onClose}
                cancelText={"取消"}
                onOk={this.onSubmitEvent}
                okText={"确认"}>
                <Form>
                    {/*<FormItem*/}
                    {/*    label="游戏ID"*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    {getFieldDecorator('playerId', {*/}
                    {/*        initialValue : value && value.playerId ? value.playerId : '',*/}
                    {/*        rules: [{required: true, message: '请填写游戏ID'},{pattern: /^[123456789]{6,13}$/, message: '游戏ID有误，请重填'}],*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="请填写游戏ID"/>,*/}
                    {/*    )}*/}
                    {/*</FormItem>*/}
                    {/*<FormItem*/}
                    {/*    label="游戏年限"*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    {getFieldDecorator('gameYear', {*/}
                    {/*        initialValue : value && value.gameYear ? value.gameYear : '',*/}
                    {/*        rules: [{required: true, message: '请填写游戏年限'},{pattern: /^[0123456789]$/, message: '游戏年限限制只填数字，请重填'}],*/}
                    {/*    })(*/}
                    {/*        <Input  placeholder="请填写游戏年限"/>,*/}
                    {/*    )}*/}
                    {/*</FormItem>*/}
                    {/*<FormItem*/}
                    {/*    label="队伍位置"*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    {getFieldDecorator('position', {*/}
                    {/*        initialValue : value && value.position ? value.position : '',*/}

                    {/*        rules: [{required: true, message: '请填写队伍位置'},{maxLength: 14, message: '不能超过14个字符'}],*/}
                    {/*    })(*/}
                    {/*        <Input placeholder="请填写队伍位置,不能超过14个字符"  maxLength={14}/>,*/}
                    {/*    )}*/}
                    {/*</FormItem>*/}
                    <FormItem label="用户展示ID"
                              {...formItemLayout}>
                        {getFieldDecorator('exhibitionId', {
                                initialValue: value && value.exhibitionId ? value.exhibitionId : '',
                                rules: [
                                    {required: true, message: '用户ID'},
                                    {pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                            }
                        )(
                            <Input disabled={!!(value && value.exhibitionId)} style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem
                        label="游戏角色编号"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('gameCharacterNum', {
                            initialValue: value && value.gameCharacterNum ? value.gameCharacterNum : '',

                            // rules: [{required: true, message: '请填写参赛QQ'}],
                        })(
                            <Input placeholder="请填写游戏角色编号"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="游戏昵称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerName', {
                            initialValue: value && value.playerName ? value.playerName : '',
                            // rules: [{required: true, message: '请填写游戏昵称'}],

                        })(
                            <Input placeholder="请填写游戏昵称"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="备用游戏昵称1"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerName1', {
                            initialValue: value && value.playerName1 ? value.playerName1 : '',
                            // rules: [{required: true, message: '请填写备用游戏昵称1'}],

                        })(
                            <Input placeholder="请填写备用游戏昵称1"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="备用游戏昵称2"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerName2', {
                            initialValue: value && value.playerName2 ? value.playerName2 : '',
                            // rules: [{required: true, message: '请填写备用游戏昵称2'}],

                        })(
                            <Input placeholder="请填写备用游戏昵称2"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="当前段位"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('currentLevel', {
                            initialValue: value && value.currentLevel ? value.currentLevel : '',
                            // rules: [{required: true, message: '请填写当前段位'}],


                        })(
                            <Select style={{width: '100px'}}>
                                {/*<Select.Option value="青铜">青铜</Select.Option>*/}
                                {/*<Select.Option value='白银'>白银</Select.Option>*/}
                                {/*<Select.Option value='黄金'>黄金</Select.Option>*/}
                                {/*<Select.Option value='铂金'>铂金</Select.Option>*/}
                                {/*<Select.Option value='星钻'>星钻</Select.Option>*/}
                                {/*<Select.Option value='皇冠'>皇冠</Select.Option>*/}
                                <Select.Option value='超级王牌'>超级王牌</Select.Option>
                                <Select.Option value='无敌战神'>无敌战神</Select.Option>


                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="当前KDA"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('currentKda', {
                            initialValue: value && value.currentKda ? value.currentKda : '',
                            rules: [
                                // {required: true, message: '请填写当前KDA'},
                                {
                                required: false,
                                pattern: /^([1-9]\d?(\.\d{1,2})?|0\.\d{1,2}|100)$/,
                                message: '请输入正确的KDA'
                            }],

                        })(
                            <Input placeholder="请填写当前KDA"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高段位"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('highestLevel', {
                            initialValue: value && value.highestLevel ? value.highestLevel : '',
                            // rules: [{required: true, message: '请填写最高段位'}],

                        })(
                            <Select style={{width: '100px'}}>
                                {/*<Select.Option value="青铜">青铜</Select.Option>*/}
                                {/*<Select.Option value='白银'>白银</Select.Option>*/}
                                {/*<Select.Option value='黄金'>黄金</Select.Option>*/}
                                {/*<Select.Option value='铂金'>铂金</Select.Option>*/}
                                {/*<Select.Option value='星钻'>星钻</Select.Option>*/}
                                {/*<Select.Option value='皇冠'>皇冠</Select.Option>*/}
                                <Select.Option value='超级王牌'>超级王牌</Select.Option>
                                <Select.Option value='无敌战神'>无敌战神</Select.Option>


                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高KDA"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('highestKda', {
                            initialValue: value && value.highestKda ? value.highestKda : '',
                            rules: [
                                // {required: true, message: '请填写最高KDA'},
                                {
                                required: false,
                                pattern: /^([1-9]\d?(\.\d{1,2})?|0\.\d{1,2}|100)$/,
                                message: '请输入正确的KDA'
                            }],

                        })(
                            <Input placeholder="请填写最高KDA"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="最高段位截图"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('imageUrl', {
                            initialValue: value && value.imageUrl ? value.imageUrl : '',
                            // rules: [{required: true, message: '请上传截图'}],

                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem>

                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification,
    }
};
const mapDispatchToProps = ({
    getUser, updateUserCertification
});
UpdateCertificationModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdateCertificationModel)));
