import createReducer from 'utils/createReducer';

import {
    GET_APP_CONTACT
} from './actionTypes';

const defaultState = () => ({
    appContactInfo: null,
});

const ongetAppContactsuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        appContactInfo: payload,
    }
};

export default createReducer(defaultState, {
    [`${GET_APP_CONTACT}_SUCCESS`]: ongetAppContactsuccess,
});
