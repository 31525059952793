import Login from 'pages/login';
import Unauthorized from 'pages/unauthorized';
import MenuManagement from 'pages/menu-management';
import RoleManagement from 'pages/role-management';
import TeamManagement from 'pages/team-management';
import CertificationManagement from 'pages/certification-management';
import CertificationCenterGameManagement from 'pages/certification-center-game-management';
import CertificationCenterRealManagement from 'pages/certification-center-real-management';
import BrandManagement from 'pages/brand-management';
import UserManagement from 'pages/user-management';
import zhijinManagement from 'pages/user-management/zhijin';
import CircleManagement from 'pages/circle-management';
import MsgNoticeManagement from '../../pages/msg-notice-management'
import VipPackageManagement from 'pages/vip-package-management';
import ArticleManagement from 'pages/article-management';
import InformationManagement from 'pages/information-management';
import InformationStatisticsManagement from 'pages/information-management/statistics';
import ClubManagement from 'pages/club-management';
import TrialManagement from "pages/club-management/trial-management";
import ListingManagement from "pages/club-management/listing-management";
import ClubPlanManagement from "pages/club-management/club-plan-management";
import BannerManagement from 'pages/banner-management'
import FeedbackManagement from 'pages/feedback-management'
import LineupManagement from 'pages/lineup-management'
import DynamicManagement from '../../pages/dynamic-management/index';
import CoinManagement from '../../pages/coin-management/index';
import PayManagement from '../../pages/pay-management';
import AppNoticeManagement from '../../pages/app-notice-management'
import PersonalMessage from 'pages/personal';
import PersonalSet from 'pages/set-personal';
import ErrorPage from 'pages/error';
import TrainingManagement from "../../pages/training-management";
import ConfigManagement from '../../pages/config-management'
import WithdrawManagement from '../../pages/withdraw-management'
import TrailPlanManagement from '../../pages/trail-plan-management'
import TrainingJoinManagement from "../../pages/training-join-management"
import TrainingVideoManagement from "../../pages/training-video-management"
import TrainingTemplateManagement from "../../pages/training-template-management"
import AdminManagement from "../../pages/admin-management";
import PostCommentManagement from "../../pages/post-comment-management"
import MajiaCommentManagement from "../../pages/post-comment-management/majiaComment"
import CommentTemplateManagement from "../../pages/comment-management/template"
import EmojiCommentManagement from "../../pages/emoji-comment-management"
import InformationCommentManagement from "../../pages/information-comment-management"
import MajiaInformationCommentManagement from "../../pages/information-comment-management/majiaComments"
import TripCommentManagement from "../../pages/trip-comment-management"
import ActivityCommentManagement from "../../pages/activity-comment-management"
import LiveCommentManagement from "../../pages/live-comment-management";
import TopicManagement from "../../pages/topic-management"
import PostManagement from "../../pages/post-management";
import EmojiManagement from "../../pages/emoji-management";
import LotteryPrizesManagement from "../../pages/lottery-prizes-management"
import PrizesOrderManagement from "../../pages/prizes-order-management"
import CircleReport from "../../pages/circle-report";
import AdminOperationLog from "../../pages/admin-operation-log";
import QrCodeManagement from "../../pages/qrcode-management";
import AppContactManagement from "../../pages/app-contact-management";
import AppStartManagement from "../../pages/app-start-management";
import VipActivityManagement from "../../pages/vip-activity-management";
import GradeManagement from "../../pages/grade-management";
import PersonalGrade from "../../pages/grade-management/PersonalGrade";
import SmallModelsManagement from "../../pages/small-models-management";
import SmallPlanManagement from "../../pages/small-plan-management";
import Welcome from "../../pages/welcome";
import PartnerTrainingTeacherManagement from "../../pages/partner-training-teacher-management";
import PartnerTrainingGoodsManagement from "../../pages/partner-training-goods-management";
import PartnerTrainingOrderManagement from "../../pages/partner-training-order-management";
import PelDataIntegralManagement from "../../pages/pel-data-integral-management";
import PelDataPlayerManagement from "../../pages/pel-data-player-management";
import PelDataTeamManagement from "../../pages/pel-data-team-management";
import TrainingDataIntegralManagement from "../../pages/training-data-integral-management";
import TrainingDataPlayerManagement from "../../pages/training-data-player-management";
import TrainingDataTeamManagement from "../../pages/training-data-team-management";
import PersonalTrainingDataIntegralManagement from "../../pages/personal-training-data-integral-management";
import PersonalTrainingDataPlayerManagement from "../../pages/personal-training-data-player-management";
import ProfessionalPlayerManage from "../../pages/professional-player-manage";
import BrandPersonManagement from "../../pages/brand-person-management";
import TrainingPersonManagement from "../../pages/training-person-management";
import CertificationPersonManagement from "../../pages/certification-person-management";
import TrainingVideoPersonManagement from "../../pages/training-video-person-management";
import TrainingTemplatePersonManagement from "../../pages/training-template-person-management";
import TrainingJoinPersonManagement from "../../pages/training-join-person-management";
import GradePersonTrainingManagement from "pages/grade-person-training-management/PersonalGrade";
import ForumReportManagement from 'pages/report-management/forum';
import UserReportManagement from 'pages/report-management/user';
import EmojiReportManagement from 'pages/report-management/emoji';
import CommentsReportManagement from 'pages/report-management/comments';
import GiftManagement from "../../pages/gift-management";
import GiftOrderManagement from "../../pages/gift-order-management";
import CommemorativeCardThemeManagement from "../../pages/commemorative-card-theme-management";
import CommemorativeCardGiftOrderManagement from "../../pages/commemorative-card-gift-order-management";
import MedalDataManagement from "../../pages/medal-data-management";
import PlayerDetailManagement from "../../pages/player-detail-management";
import PlayerPhotoPrintManagement from "../../pages/player-detail-management/photo";
import TocletDataManagement from "../../pages/ticket-data-management";
import TicketOrderManagement from "../../pages/ticket-data-management/order";

import scheduleManagement from '../../pages/schedule-management';
import guessListManagement from '../../pages/schedule-management/guess';
import activityManagement from '../../pages/activity-management';
import zhijin from "../../pages/user-management/zhijin";
import LikeConfig from "../../pages/post-management/LikeConfig";
import InfomationPost from '../../pages/information-management/infomationPost';
import LiveManagement from '../../pages/live-manegement';

const authorizedRoutes = [{
    path: '/system/menu_manage',
    exact: true,
    permissions: [],
    component: MenuManagement,
    unauthorized: Unauthorized,
    pageTitle: '菜单管理',
}, {
    path: '/system/role_manage',
    exact: true,
    permissions: [],
    component: RoleManagement,
    unauthorized: Unauthorized,
    pageTitle: '角色管理',
}, {
    path: '/account_manage/user_manage',
    exact: true,
    permissions: [],
    component: UserManagement,
    unauthorized: Unauthorized,
    pageTitle: '用户管理',
},
    {
        path: '/account_manage/zhijin_manage',
        exact: true,
        permissions: [],
        component: zhijinManagement,
        unauthorized: Unauthorized,
        pageTitle: '紫金收支明细管理',
    },
{
    path: '/lineup_manage',
    exact: true,
    permissions: [],
    component: LineupManagement,
    unauthorized: Unauthorized,
    pageTitle: '阵容管理',
}, {
    path: '/circle',
    exact: true,
    permissions: [],
    component: CircleManagement,
    unauthorized: Unauthorized,
    pageTitle: '圈子管理',
}, {
    path: '/circle/topic_manage',
    exact: true,
    permissions: [],
    component: TopicManagement,
    unauthorized: Unauthorized,
    pageTitle: '话题管理',
}, {
    path: '/circle/post_manage',
    exact: true,
    permissions: [],
    component: PostManagement,
    unauthorized: Unauthorized,
    pageTitle: '帖子管理',
}, {
    path: '/circle/emoji_manage',
    exact: true,
    permissions: [],
    component: EmojiManagement,
    unauthorized: Unauthorized,
    pageTitle: '表情包管理',
},
    {
        path: '/system/like_config',
        exact: true,
        permissions: [],
        component: LikeConfig,
        unauthorized: Unauthorized,
        pageTitle: '点赞配置管理',
    },
    {
    path: '/lottery_manage/prizes_manage',
    exact: true,
    permissions: [],
    component: LotteryPrizesManagement,
    unauthorized: Unauthorized,
    pageTitle: '转盘奖品管理',
}, {
    path: '/lottery_manage/order_manage',
    exact: true,
    permissions: [],
    component: PrizesOrderManagement,
    unauthorized: Unauthorized,
    pageTitle: '奖品订单管理',
}, {
    path: '/comment_manage/post_comment_manage',
    exact: true,
    permissions: [],
    component: PostCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '帖子评论管理',
},{
        path: '/majia_manage/majia_comment_manage',
        exact: true,
        permissions: [],
        component: MajiaCommentManagement,
        unauthorized: Unauthorized,
        pageTitle: '马甲评论管理',
    },
    {
        path: '/majia_manage/majia_information_comment_manage',
        exact: true,
        permissions: [],
        component: MajiaInformationCommentManagement,
        unauthorized: Unauthorized,
        pageTitle: '马甲资讯评论管理',
    },

    {
    path: '/comment_manage/emoji_comment_manage',
    exact: true,
    permissions: [],
    component: EmojiCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '表情包评论管理',
},{
    path: '/comment_manage/information_comment_manage',
    exact: true,
    permissions: [],
    component: InformationCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '资讯评论管理',
},{
    path: '/comment_manage/trip_comment_manage',
    exact: true,
    permissions: [],
    component: TripCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '行程评论管理',
},{
    path: '/comment_manage/activity_comment_manage',
    exact: true,
    permissions: [],
    component: ActivityCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '活动评论管理',
},{
    path: '/comment_manage/live_comment_manage',
    exact: true,
    permissions: [],
    component: LiveCommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '直播评论管理',
},
    {
        path: '/majia_manage/comment_template_manage',
        exact: true,
        permissions: [],
        component: CommentTemplateManagement,
        unauthorized: Unauthorized,
        pageTitle: '评论模板管理',
    },
    {
    path: '/app/start_manage',
    exact: true,
    permissions: [],
    component: AppStartManagement,
    unauthorized: Unauthorized,
    pageTitle: 'app启动页配置',
},{
    path: '/app/contact_manage',
    exact: true,
    permissions: [],
    component: AppContactManagement,
    unauthorized: Unauthorized,
    pageTitle: '联系信息配置',
}, {
    path: '/circle/circle_report',
    exact: true,
    permissions: [],
    component: CircleReport,
    unauthorized: Unauthorized,
    pageTitle: '圈子举报',
}, {
    path: '/account_manage/pay_manage',
    exact: true,
    permissions: [],
    component: PayManagement,
    unauthorized: Unauthorized,
    pageTitle: '支付订单管理',
}, {
    path: '/account_manage/coin_manage',
    exact: true,
    permissions: [],
    component: CoinManagement,
    unauthorized: Unauthorized,
    pageTitle: '浪币充值包管理',
}, {
    path: '/account_manage/vip_manage',
    exact: true,
    permissions: [],
    component: VipPackageManagement,
    unauthorized: Unauthorized,
    pageTitle: 'vip充值包管理',
}, {
    path: '/account_manage/activity_management',
    exact: true,
    permissions: [],
    component: VipActivityManagement,
    unauthorized: Unauthorized,
    pageTitle: 'vip活动信息',
}, {
    path: '/banner_manage',
    exact: true,
    permissions: [],
    component: BannerManagement,
    unauthorized: Unauthorized,
    pageTitle: '轮播图管理',
}, {
    path: '/team_manage',
    exact: true,
    permissions: [],
    component: TeamManagement,
    unauthorized: Unauthorized,
    pageTitle: '战队管理',
}, {
    path: '/team_training/training_manage',
    exact: true,
    permissions: [],
    component: TrainingManagement,
    unauthorized: Unauthorized,
    pageTitle: '训练赛管理',
}, {
    path: '/team_training/training_join_manage/:trainingId/:trainingName/:gameStartDate/:brandId/:teamTotal',
    exact: true,
    permissions: [],
    component: TrainingJoinManagement,
    unauthorized: Unauthorized,
    pageTitle: '参与详情',
},
// {
//     path: '/club_manage/trial_plan_manage',
//     exact: true,
//     permissions: [],
//     component: TrailPlanManagement,
//     unauthorized: Unauthorized,
//     pageTitle: '试训计划管理',
// },
{
    path: '/team_training/grade_manage/:trainingId/:gameNum/:trainingName/:page',
    exact: true,
    permissions: [],
    component: GradeManagement,
    unauthorized: Unauthorized,
    pageTitle: '战队战绩管理',
}, {
    path: '/team_training/personal_grade_manage/:trainingId/:gameNum/:trainingName',
    exact: true,
    permissions: [],
    component: PersonalGrade,
    unauthorized: Unauthorized,
    pageTitle: '个人战绩管理',
},
    {
    path: '/team_training/certification_manage',
    exact: true,
    permissions: [],
    component: CertificationManagement,
    unauthorized: Unauthorized,
    pageTitle: '参赛认证管理',
},
    {
    path: '/certification_center_manage/game',
    exact: true,
    permissions: [],
    component: CertificationCenterGameManagement,
    unauthorized: Unauthorized,
    pageTitle: '游戏实力认证管理',
},
    {
    path: '/certification_center_manage/real',
    exact: true,
    permissions: [],
    component: CertificationCenterRealManagement,
    unauthorized: Unauthorized,
    pageTitle: '用户实名认证管理',
},

    {
    path: '/team_training/brand_manage',
    exact: true,
    permissions: [],
    component: BrandManagement,
    unauthorized: Unauthorized,
    pageTitle: '品牌赛事管理',
}, {
    path: '/notice/msg_notice_manage',
    exact: true,
    permissions: [],
    component: MsgNoticeManagement,
    unauthorized: Unauthorized,
    pageTitle: '短信通知',
}
    , {
        path: '/system/config_manage',
        exact: true,
        permissions: [],
        component: ConfigManagement,
        unauthorized: Unauthorized,
        pageTitle: '系统配置管理',
    },

    {
        path: '/account_manage/withdraw_manage',
        exact: true,
        permissions: [],
        component: WithdrawManagement,
        unauthorized: Unauthorized,
        pageTitle: '提现管理',
    },
    {
        path: '/article_manage',
        exact: true,
        permissions: [],
        component: ArticleManagement,
        unauthorized: Unauthorized,
        pageTitle: '文章管理',
    },
    {
        path: '/information_manage/list',
        exact: true,
        permissions: [],
        component: InformationManagement,
        unauthorized: Unauthorized,
        pageTitle: '资讯信息管理',
    },
    {
        path: '/information_manage/statistics',
        exact: true,
        permissions: [],
        component: InformationStatisticsManagement,
        unauthorized: Unauthorized,
        pageTitle: '资讯统计',
    },
    {
        path: '/information_manage/infomation_post',
        exact: true,
        permissions: [],
        component: InfomationPost,
        unauthorized: Unauthorized,
        pageTitle: '资讯帖子管理',
    },
    {
        path: '/report_manage/forum',
        exact: true,
        permissions: [],
        component: ForumReportManagement,
        unauthorized: Unauthorized,
        pageTitle: '帖子举报处理',
    },
    {
        path: '/report_manage/comments',
        exact: true,
        permissions: [],
        component: CommentsReportManagement,
        unauthorized: Unauthorized,
        pageTitle: '评论举报处理',
    },
    {
        path: '/report_manage/user',
        exact: true,
        permissions: [],
        component: UserReportManagement,
        unauthorized: Unauthorized,
        pageTitle: '用户举报处理',
    },
    {
        path: '/report_manage/emoji',
        exact: true,
        permissions: [],
        component: EmojiReportManagement,
        unauthorized: Unauthorized,
        pageTitle: '选手主页表情举报处理',
    },
    {
        path: '/feedback_manage',
        exact: true,
        permissions: [],
        component: FeedbackManagement,
        unauthorized: Unauthorized,
        pageTitle: '用户反馈',
    },
    {
        path: '/notice/app_notice_manage',
        exact: true,
        permissions: [],
        component: AppNoticeManagement,
        unauthorized: Unauthorized,
        pageTitle: '应用通知日志',
    },
    {
        path: '/system/personal_message',
        exact: true,
        permissions: [],
        component: PersonalMessage,
        unauthorized: Unauthorized,
        pageTitle: '个人中心',
    },
    {
        path: '/system/personal_set',
        exact: true,
        permissions: [],
        component: PersonalSet,
        unauthorized: Unauthorized,
        pageTitle: '设置',
    },
    {
        path: '/circle/circle_dynamic',
        exact: true,
        permissions: [],
        component: DynamicManagement,
        unauthorized: Unauthorized,
        pageTitle: '动态管理',
    },
    {
        path: '/small/models_manage',
        exact: true,
        permissions: [],
        component: SmallModelsManagement,
        unauthorized: Unauthorized,
        pageTitle: '机型管理',
    },
    {
        path: '/small/plan_manage',
        exact: true,
        permissions: [],
        component: SmallPlanManagement,
        unauthorized: Unauthorized,
        pageTitle: '方案管理',
    },{
        path: '/club_manage',
        exact: true,
        permissions: [],
        component: ClubManagement,
        unauthorized: Unauthorized,
        pageTitle: '俱乐部管理',
    }, {
        path: '/club_trial/trial_manage',
        exact: true,
        permissions: [],
        component: TrialManagement,
        unauthorized: Unauthorized,
        pageTitle: '试训管理',
    },{
        path: '/club_trial/listing_manage',
        exact: true,
        permissions: [],
        component: ListingManagement,
        unauthorized: Unauthorized,
        pageTitle: '自荐管理',
    },
    {
        path: '/club_trial/club_plan_manage',
        exact: true,
        permissions: [],
        component: ClubPlanManagement,
        unauthorized: Unauthorized,
        pageTitle: '试训计划管理',
    },
    {
        path: '/other/404',
        exact: true,
        permissions: [],
        component: ErrorPage,
        unauthorized: Unauthorized,
        pageTitle: '404',
    },
    {
        path: '/welcome',
        exact: true,
        permissions: [],
        component: Welcome,
        unauthorized: Unauthorized,
        pageTitle: '',
    },
    {
        path: '/system/admin_management',
        exact: true,
        permissions: [],
        component: AdminManagement,
        unauthorized: Unauthorized,
        pageTitle: '管理员管理',
    },
    {
        path: '/admin_operation_log',
        exact: true,
        permissions: [],
        component: AdminOperationLog,
        unauthorized: Unauthorized,
        pageTitle: '管理员操作日志',

    }, {
        path: '/qrcode_management',
        exact: true,
        permissions: [],
        component: QrCodeManagement,
        unauthorized: Unauthorized,
        pageTitle: '二维码配置',

    }, {
        path: '/team_training/training_video_management',
        exact: true,
        permissions: [],
        component: TrainingVideoManagement,
        unauthorized: Unauthorized,
        pageTitle: '回放视频管理',
    }, {
        path: '/team_training/training_template_management',
        exact: true,
        permissions: [],
        component: TrainingTemplateManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛事模板管理',
    }, {
        path: '/partner_training/teacher_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingTeacherManagement,
        unauthorized: Unauthorized,
        pageTitle: '导师管理',
    }, {
        path: '/partner_training/goods_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingGoodsManagement,
        unauthorized: Unauthorized,
        pageTitle: '陪练商品管理',
    }, {
        path: '/partner_training/order_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingOrderManagement,
        unauthorized: Unauthorized,
        pageTitle: '陪练订单管理',
    }, {
        path: '/pel_data_management/integral',
        exact: true,
        permissions: [],
        component: PelDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL积分榜单管理',
    }, {
        path: '/pel_data_management/player',
        exact: true,
        permissions: [],
        component: PelDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL选手榜单管理',
    }, {
        path: '/pel_data_management/team',
        exact: true,
        permissions: [],
        component: PelDataTeamManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL战队榜单管理',
    }, {
        path: '/personal_training_data_management/personalIntegral',
        exact: true,
        permissions: [],
        component: PersonalTrainingDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: '个人训练赛积分榜单管理',
    }, {
        path: '/personal_training_data_management/personalPlayer',
        exact: true,
        permissions: [],
        component: PersonalTrainingDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: '个人训练赛选手榜单管理',
    }, {
        path: '/training_data_management/integral',
        exact: true,
        permissions: [],
        component: TrainingDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛积分榜单管理',
    }, {
        path: '/training_data_management/player',
        exact: true,
        permissions: [],
        component: TrainingDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛选手榜单管理',
    }, {
        path: '/training_data_management/team',
        exact: true,
        permissions: [],
        component: TrainingDataTeamManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛战队榜单管理',
    },
    {
        path: '/professional_player_manage',
        exact: true,
        permissions: [],
        component: ProfessionalPlayerManage,
        unauthorized: Unauthorized,
        pageTitle: '职业选手主页管理',
    },
    {
        path: '/person_training/brand_person_manage',
        exact: true,
        permissions: [],
        component: BrandPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '品牌赛事管理',
    }, {
        path: '/person_training/training_person_manage',
        exact: true,
        permissions: [],
        component: TrainingPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛管理',
    }, {
        path: '/person_training/certification_person_manage',
        exact: true,
        permissions: [],
        component: CertificationPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '参赛认证管理',
    }, {
        path: '/person_training/training_video_person_management',
        exact: true,
        permissions: [],
        component: TrainingVideoPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '回放视频管理',
    }, {
        path: '/person_training/training_template_person_management',
        exact: true,
        permissions: [],
        component: TrainingTemplatePersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛事模板管理',
    }, {
        path: '/person_training/training_join_manage/:trainingId/:trainingName/:gameStartDate/:brandId/:personalTotal',
        exact: true,
        permissions: [],
        component: TrainingJoinPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '参与详情',
    }, {
        path: '/person_training/personal_grade_manage/:trainingId/:gameNum/:trainingName',
        exact: true,
        permissions: [],
        component: GradePersonTrainingManagement,
        unauthorized: Unauthorized,
        pageTitle: '个人战绩管理',
    },


    {
        path: '/schedule/schedule_management',
        exact: true,
        permissions: [],
        component: scheduleManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛程管理',
    },
    {
        path: '/schedule/guess_list_management',
        exact: true,
        permissions: [],
        component: guessListManagement,
        unauthorized: Unauthorized,
        pageTitle: '预测列表',
    },
    {
        path: '/activity_management',
        exact: true,
        permissions: [],
        component: activityManagement,
        unauthorized: Unauthorized,
        pageTitle: '活动管理',
    },
    {
        path: '/live_management',
        exact: true,
        permissions: [],
        component: LiveManagement,
        unauthorized: Unauthorized,
        pageTitle: '直播管理',
    },

    {
        path: '/gift_exchange/gift_management',
        exact: true,
        permissions: [],
        component: GiftManagement,
        unauthorized: Unauthorized,
        pageTitle: '礼品管理',
    },
    {
        path: '/gift_exchange/gift_exchange_order_management',
        exact: true,
        permissions: [],
        component: GiftOrderManagement,
        unauthorized: Unauthorized,
        pageTitle: '订单管理',
    },
    {
        path: '/commemorative_card/theme_management',
        exact: true,
        permissions: [],
        component: CommemorativeCardThemeManagement,
        unauthorized: Unauthorized,
        pageTitle: '纪念卡主题管理',
    },
    {
        path: '/commemorative_card/gift_order_management',
        exact: true,
        permissions: [],
        component: CommemorativeCardGiftOrderManagement,
        unauthorized: Unauthorized,
        pageTitle: '礼品订单管理',
    },
    {
        path: '/medal_data_management',
        exact: true,
        permissions: [],
        component: MedalDataManagement,
        unauthorized: Unauthorized,
        pageTitle: '勋章管理',
    },
    {
        path: '/ticket_data_management/index',
        exact: true,
        permissions: [],
        component: TocletDataManagement,
        unauthorized: Unauthorized,
        pageTitle: '活动券票管理',
    },
    {
        path: '/ticket_data_management/order',
        exact: true,
        permissions: [],
        component: TicketOrderManagement,
        unauthorized: Unauthorized,
        pageTitle: '券票订单管理',
    },
    {
        path: '/player_detail_management/index',
        exact: true,
        permissions: [],
        component: PlayerDetailManagement,
        unauthorized: Unauthorized,
        pageTitle: '选手主页管理',
    },
    {
        path: '/player_detail_management/photo',
        exact: true,
        permissions: [],
        component: PlayerPhotoPrintManagement,
        unauthorized: Unauthorized,
        pageTitle: '选手照片订单管理',
    },
];

const normalRoutes = [{
    path: '/',
    exact: true,
    redirect: '/welcome',
}, {
    path: '/login',
    exact: true,
    component: Login,
}];

const combineRoutes = [
    ...authorizedRoutes,
    ...normalRoutes,
];

export {
    authorizedRoutes,
    normalRoutes,
    combineRoutes,
};
