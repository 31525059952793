import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class LineupMemberFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {exhibitionId,username} = values;
                onCommit && onCommit(exhibitionId,username);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="用户展示ID">
                    {getFieldDecorator('exhibitionId', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户名">
                    {getFieldDecorator('username', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Lineup,
    }
};
const mapDispatchToProps = ({
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(LineupMemberFilter)));