import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, Popconfirm, Popover, Row, Table, Tabs, Tag} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/lineup/enums';
import {
  createLineup,
  editTeamLineup,
  getLineupList,
  deleteTeamLineup
} from "../../modules/lineup/action";
import AddLineupModel from "./components/AddLineupModel";
import MembersInfoModel from "./components/MembersInfoModel";
import LineupFilter from "./components/LineupFilter";

const {confirm} = Modal;
const {TabPane} = Tabs;

class LineupManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isShowAddLineupModel: false,
      isShowMembersInfoModel: false,
      seletionValue: null,
      operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
      gameType: '',
      teamType: '',
      page: 1,
      size: 15,
    };
  }

  componentWillMount() {
    this.onGetTableData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.lineups !== this.props.lineups) {
      this.setState({
        loading: false
      })
    }
  }

  onFormSubmit = (values) => {
    const {operationType} = this.state;
    switch (operationType) {
      case operationTypeValue.INCREASE:
        this.onCreateTeamEvent(values);
        break;
      case operationTypeValue.UPDATE:
        this.onEditTeamEvent(values);
        break;
    }
  }

  onCreateTeamEvent = async (params) => {
    const {isShowAddLineupModel} = this.state;
    const {createLineup} = this.props;
    await createLineup(params);
    await this.onGetTableData();
    message.success('新增成功');
    this.setState({isShowAddLineupModel: !isShowAddLineupModel});
  }

  onEditTeamEvent = async (params) => {
    const {seletionValue,isShowAddLineupModel} = this.state;
    const {editTeamLineup} = this.props;
    params.id = seletionValue.id;
    console.log("params",params);
    await editTeamLineup(params);
    await this.onGetTableData();
    message.success('编辑成功');
    this.setState({isShowAddLineupModel: !isShowAddLineupModel});
  }  

  //刷新界面数据
  onGetTableData = async () => {
    const {getLineupList} = this.props;
    const {page, size} = this.state;
    this.setState({
      loading: true
    })
    await getLineupList({
      page, size,
      gameType: this.state.gameType ? this.state.gameType : null,
      teamType: this.state.teamType ? this.state.teamType : null,
    });
  }

  onPageChangeEvent = async (current, pageSize) => {
    const {getLineupList} = this.props;
    this.setState({
      page: current,
      size: pageSize,
      loading: true
    })
    await getLineupList({
      page: current, size: pageSize,
      gameType: this.state.gameType ? this.state.gameType : null,
      teamType: this.state.teamType ? this.state.teamType : null,
      admin: true,
    });
  }

  //条件查找动态方法
  onFilterLineupEvent = async ( gameType, teamType) => {
    let {getLineupList} = this.props;
    const {size} = this.state;
    this.setState({
      loading: true,
      page: 1,
      gameType: gameType ? gameType : null,
      teamType: teamType ? teamType : null,
    })
    await getLineupList({
      page: 1,
      size: size,
      gameType: gameType ? gameType : null,
      teamType: teamType ? teamType : null,
    });
  }

  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增团队阵容</Button>
        </Col>
    </Row>
  )

  renderTabPane = () => {
    const { lineups } = this.props; 
    console.log("lineups,",lineups)
    const {
      operationType, isShowAddLineupModel, isShowMembersInfoModel, seletionValue, page, size, loading, 
    } = this.state;
    const defaultValue = operationType === operationTypeValue.UPDATE && seletionValue;
    return (
      <div>
          <Card style={{marginBottom: 30, width: 'auto'}}>
              <LineupFilter dataSource={{}} onCommit={this.onFilterLineupEvent}/>
          </Card>
          <Card style={{width: 'auto'}}>
              <Table
                dataSource={lineups ? lineups.data : []}
                title={this.renderTableTitle}
                columns={this.getTableColumns()}
                bordered
                pagination={{
                  total: lineups.total,
                  current: page,
                  pageSize: size,
                  onChange: this.onPageChangeEvent,
                  showTotal: (total) => {
                    return `总计 ${total}条数据`;
                  }
                }}
                loading={loading}
              />
            {isShowAddLineupModel ? <AddLineupModel
              title={operationType === operationTypeValue.UPDATE ? "编辑团队阵容" : "添加阵容"}
              value={defaultValue}
              operationType={operationType}
              onSuccess={this.onFormSubmit}
              visible={isShowAddLineupModel}
              onClose={() => {
                this.setState({
                  isShowAddLineupModel: false,
                });
              }}
            /> : null}
            {isShowMembersInfoModel ? <MembersInfoModel
              visible={isShowMembersInfoModel}
            //   onSuccess={this.onDisBandTeamEvent}
              value={seletionValue}
              onClose={() => {
                this.setState({
                  isShowMembersInfoModel: false,
                });
              }}
            /> : null}
          </Card>
      </div>
    )
  }

  render() {
    return (
      <div>
            {this.renderTabPane()}
      </div>
    );
  }

  onBtnClick = (type, seletionValue) => {
    this.setState({
      operationType: type,
      isShowAddLineupModel: true,
      seletionValue: seletionValue,
    });
  }

  onGetMembersInfoEvent = async (value) => {
    const {isShowMembersInfoModel} = this.state;
    this.setState({
      isShowMembersInfoModel: !isShowMembersInfoModel,
      seletionValue: value
    });
  }

  //删除
  onDeleteEvent = async (id) => {
    const {deleteTeamLineup} = this.props;
    try {
        await deleteTeamLineup(id);
        await this.onGetTableData();
        message.success('删除成功');
    } catch (e) {
        console.log(e);
    }
  }  

  getTableColumns = (games, brandData) => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },{
      title: '团队类型',
      dataIndex: 'teamType',
      align: 'center',
      key: 'teamType',
      render: (row, text) => {
        if (row === "MANAGEMENT_TEAM") {
            return "管理团队"
        } else if (row === "HOME_TEAM_PLAYER") {
            return "主队选手"
        } else if (row === "YOUTH_PLAYERS"){
            return "青训选手"
        }
      }
    }, {
        title: '游戏类型',
        dataIndex: 'gameType',
        align: 'center',
        key: 'gameType',
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      key: 'createTime',
    }, {
      title: '展示顺序',
      dataIndex: 'sort',
      align: 'center',
      key: 'sort',
    }, {
      title: '操作',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (row, text) => {
        return (
          <div>
            <Button style={{marginLeft: 10}} type="primary" onClick={() => this.onGetMembersInfoEvent(text)}>查看团队成员</Button> 
            <Button style={{marginLeft: 10}} type="primary" onClick={() => this.onBtnClick(operationTypeValue.UPDATE,text)}>修改团队阵容</Button> 
            <Popconfirm title={`确定要删除该阵容信息吗?`}
                        onConfirm={() => this.onDeleteEvent(text.id)}
                        okText="确认" onCancel={this.onEvent} cancelText="取消"
                        style={{backgroundColor: '#ff0000', color: '#fff'}}>
                <Button type="danger" style={{marginLeft: 10}}>删除</Button>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
}

const mapStateToProps = (state) => {
  return {
    // ...state.viewTraining,
    ...state.Lineup,
  }
};

const mapDispatchToProps = ({
  getLineupList,
  createLineup,
  editTeamLineup,
  deleteTeamLineup
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(LineupManagement));