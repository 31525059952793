import createReducer from 'utils/createReducer';

import {
    GET_PRIZES,
    GET_LOTTERY_RECORD_LIST,
    GET_LOTTERY_HARVEST
} from './actionTypes';

const defaultState = () => ({
    prizes: [],
    lotteryRecordList: [],
    lotteryHarvest: null,
});

const ongetOrizesRequest = (state,{payload}) => {
    return {
        ...state,
        prizes:payload
    }
};

const ongetLotteryRecordListRequest = (state,{payload}) => {
    return {
        ...state,
        lotteryRecordList:payload
    }
};

const ongetLotteryHarvestRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        lotteryHarvest: payload
    }
};

export default createReducer(defaultState, {
    [`${GET_PRIZES}_SUCCESS`]: ongetOrizesRequest,
    [`${GET_LOTTERY_RECORD_LIST}_SUCCESS`]: ongetLotteryRecordListRequest,
    [`${GET_LOTTERY_HARVEST}_SUCCESS`]: ongetLotteryHarvestRequest,
    
});
