import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import {sendStatus} from "../../modules/appNotice/enums";

const FormItem = Form.Item;
const Option = Select.Option;


class MsgNoticeFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {telephone, noticeGroupType} = values;

                console.log(values)

                onCommit && onCommit(telephone, noticeGroupType);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {telephone, noticeGroupType, sendStatus} = values;

                console.log(values)

                onCommit && onCommit(telephone, noticeGroupType, sendStatus);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource} = this.props;
        const {telephone} = dataSource;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="手机号">
                    {getFieldDecorator('telephone', {
                            initialValue: telephone

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>

                <FormItem label="通知类型">
                    {getFieldDecorator('noticeGroupType', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: 150}}>
                            <Option value={null}>全部</Option>
                            <Option value="SYSTEM">系统通知</Option>
                            <Option value="COMPETITION">赛事通知</Option>
                            <Option value="CIRCLE">圈子动态</Option>
                            <Option value="TRIAL">试训通知</Option>
                            <Option value="OTHER">其他</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发送状态">
                    {getFieldDecorator('sendStatus', {
                            initialValue: null
                        }
                    )(
                        <Select style={{width: 150}}>
                            <Option value={null}>全部</Option>
                            {
                                Object.keys(sendStatus).map(key => (
                                    <Select.Option value={sendStatus[key].value} >{sendStatus[key].label}</Select.Option>
                                ))
                            }
                        </Select>

                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}


export default Form.create()(MsgNoticeFilter);
