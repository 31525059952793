export const GET_START_IMAGE = 'GET_START_IMAGE';// 获取机型列表

export const UPDATE_START_IMAGE = 'UPDATE_START_IMAGE';// 更新机型对应的方案

export const ADD_START_IMAGE = 'ADD_START_IMAGE';// 添加机型对应的方案

export const DELETE_START_IMAGE = 'DELETE_START_IMAGE';// 删除机型对应的方案



