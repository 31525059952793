export default {
    CREATE_RECHARGE_PACKAGE: {
        label: '新增',
        value:'CREATE_RECHARGE_PACKAGE',
    },
    MODIFY_RECHARGE_PACKAGE: {
        label: '修改',
        value: 'MODIFY_RECHARGE_PACKAGE'
    },
    DELETE_RECHARGE_PACKAGE: {
        label: '删除',
        value: 'DELETE_RECHARGE_PACKAGE'
    },
};
