export const GET_GIFT_LIST = 'GET_GIFT_LIST';

export const CREATE_GIFT = 'CREATE_GIFT';

export const UPDATE_GIFT = 'UPDATE_GIFT';

export const DELETE_GIFT = 'DELETE_GIFT';

export const GET_GIFT_CDK_LIST = 'GET_GIFT_CDK_LIST';

export const DELETE_GIFT_CDK = 'DELETE_GIFT_CDK';

export const GET_GIFT_ORDER_LIST = 'GET_GIFT_ORDER_LIST';

export const UPDATE_GIFT_ORDER_ADDRESS = 'UPDATE_GIFT_ORDER_ADDRESS';

export const UPDATE_GIFT_ORDER_SHIP = 'UPDATE_GIFT_ORDER_SHIP';
