import React, {Component} from 'react';
import {
    Form, Button, Select, Input
} from 'antd';
import UpLoad from "../../components/upload";
import {

    getImage
} from "../../modules/banner/action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class TemplateUpdateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypes: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            jumpType: this.props.isOuter,
            innerJumpType:this.props.innerJumpType,
            state : this.props.state,

        };
    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }


    // componentWillReceiveProps(nextProps, nextContext) {
    //     if (nextProps!=null){
    //         if (this.props.images!=null){
    //             this.setState({
    //                 imageUrl : this.props.images.imageUrl,
    //                 enable : this.props.images.enable,
    //                 pageType : this.props.images.pageType,
    //
    //             })
    //         }
    //     }
    // }
    componentDidMount() {
        if (this.state.state==="update"){
            const  {getImage} = this.props;
            let type = "VALUATION";
            getImage(type);
            this.setState({
                imageUrl : this.props.images.imageUrl,
                enable : this.props.images.enable,
                pageType : this.props.images.pageType,

            })
        }

    }

    onChangeEvent = (value) =>{

        const  {getImage} = this.props;
        getImage(value);


    }


    render() {
        console.log(this.props)
        let {content} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>

                <FormItem
                    label="模板内容"
                    {...formItemLayout}
                >
                    {getFieldDecorator('content', {
                        rules: [{required: true, message: '请输入模板内容'}],
                        initialValue: content,
                    })(
                        <textarea style={{width:'300px'}} rows="3"   placeholder="请输入模板内容"/>,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}
const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Banner,
            ...state.innerJumpList,
            ...state.images
        }

    }

);

const mapDispatchToProps = {

    getImage,

};

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
// )((Form.create(ValuationForm)));
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(Form.create()(TemplateUpdateForm))));
