
import { notification } from 'antd';

function createAsyncAction(name, callback,ignoreErrorHandle=false) {
  if (typeof callback !== 'function') {
    throw new Error('[createAsyncAction] callback should be a function');
  }

  return (dispatch) => {
    // 发布Action请求事件
    dispatch({
      type: `${name}_REQUEST`,
    });

    try {
      return callback()
        .then((responseData) => {
          // 发布Action获取事件
          dispatch({
            type: `${name}_SUCCESS`,
            payload: responseData,
          });
          return responseData;
        })
        .catch((err) => {
          let { response } = err;
          const data = response.data || {};
          // 发布Action失败事件
          dispatch({
            type: `${name}_ERROR`,
            payload: data,
            error: true,
          });
          if(!ignoreErrorHandle){
            let { msg } = data;
            notification.error({
              message: '提示',
              description: msg,
            });
          }
          return Promise.reject(data);
        });
    } catch (err) {
      // 发布Action失败事件
      dispatch({
        type: `${name}_ERROR`,
        payload: err,
        error: true,
      });
      notification.error({
        message: '提示',
        description: err.message,
      });
      return Promise.reject(err);
    }
  };
}

export default createAsyncAction;
