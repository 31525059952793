import api from 'utils/api';

class PartnerOrderRequest {


    /**
     * 发布订单
     */
    static async addPartnerOrder(param) {
        return api.post(`/admin/partner-training/order`, param);
    }

    /**
     * 获取订单列表
     */
    static async getPartnerOrder(page, size, userId, userName, teacherId, teacherName, orderState, adminCreate) {
        return api.get(`/admin/partner-training/order/page`, {
            page,
            size,
            userId,
            userName,
            teacherId,
            teacherName,
            orderState,
            adminCreate
        });
    }

    /**
     * 获取单一订单详情
     */
    static async getOrderDetail(orderId) {
        return api.get(`/admin/partner-training/order/${orderId}`,);
    }

    /**
     * 处理申诉
     */
    static async dealWithComplaint(complaintState,orderId) {

        return api.post(`/admin/partner-training/complaint`,{complaintState,orderId})
    }

}

export default PartnerOrderRequest;
