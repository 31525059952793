import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, Popconfirm,  message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getDynamic,deleteDynamic,updateDynamicStatus,topToDynamic} from "../../modules/dynamic/action";
// import util from 'util/util';
import { operationTypeValue } from '../../modules/circle/circleEnum';
import DynamicFilter from "../dynamic-management/DynamicFilter";
import DynamicCommentForm from "./DynamicCommentForm";
const { Meta } = Card;
class DynamicManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            previewVisible: false,
            pageSize: 10,
            previewImage: '',
            userId :"",
            image : [],
            dynamicContent : "",
            circleName : "",
            DynamicStatus : "",
            dateRange : "",
            content : "",
            dynamicId : 0,
            isShowEditAdminModal: false,
            isShowDynamicModal : false,
            isShowCommentModal : false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
        };
    }
    showModal = (text) => {
        console.log(text)
        this.setState({
            isShowDynamicModal: true,
            content : text.dynamicContent,
            image : text.dynamicImage ? text.dynamicImage :null
        });
    };

    // handleOk = e => {
    //     console.log(e);
    //     this.setState({
    //         isShowDynamicModal: false,
    //         isShowCommentModal: false
    //     });
    // };

    handleCancel = e => {
        this.setState({
            isShowDynamicModal: false,
            isShowCommentModal: false,

        });
    };

    onThisCancel = e => {
        this.setState({
            previewVisible: false

        })
    }


    // componentDidMount() {
    //
    //     const {pageNo, pageSize} = this.state;
    //     let {getCircles,getDynamic} = this.props;
    //     //getCircles(pageNo, pageSize);
    //     getDynamic(pageNo, pageSize);
    // }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getDynamic} = this.props;
        let {userId,dynamicContent,circleName,DynamicStatus,dateRange} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getDynamic(current, pageSize,circleName,userId,dynamicContent,DynamicStatus,dateRange);
    }

    //条件查找动态方法
    onFilterDynamicEvent = async (userId,dynamicContent,circleName,DynamicStatus,dateRange) => {
        let {getDynamic} = this.props;
        console.log(dateRange)
        const {pageSize,} = this.state;
        this.setState({
            userId : userId,
            dynamicContent: dynamicContent,
            circleName : circleName,
            DynamicStatus : DynamicStatus,
            dateRange : dateRange,
            pageNo:1
        });
        getDynamic(1, pageSize,circleName,userId,dynamicContent,DynamicStatus,dateRange);

    }

    showImage = (value) => {
        this.setState({previewImage: value, previewVisible: true});
    }

    //删除动态的方法
    onDeleteDynamicEvent = async (e,adminId) => {

        e.stopPropagation();

        const { deleteDynamic, getDynamic } = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        try {
            await deleteDynamic(adminId);
            notification.success({ message: '屏蔽成功' });

            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        } catch (e) {
            console.log(e);
        }
    };

    onEvent = async (e) =>{
        e.stopPropagation();

    }

    //恢复动态的方法
    onRestoreDynamicEvent = async (e,adminId,data,text) => {
        e.stopPropagation();

        const { updateDynamicStatus, getDynamic } = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        console.log(data,text)
        try {
            await updateDynamicStatus(adminId);
            notification.success({ message: '恢复成功' });

            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        } catch (e) {
            console.log(e);
        }
    };

    //查看动态的方法
    onGetCommentEvent = async (e,dynamicId) =>{
        e.stopPropagation();

        console.log(dynamicId)
        this.setState({
            isShowDynamicModal : false,
            isShowCommentModal: true,
            dynamicId : dynamicId
        });
    };

    //置顶所有动态的方法
    onTopDynamic = async  (e,MakeTop,dynamicId,allMakeTop,circleMakeTop) =>{

        e.stopPropagation();
        const {topToDynamic,getDynamic} = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        if (MakeTop===1){
            return message.error("该动态已在所有动态列表置顶");
        }
        try {
            await topToDynamic(dynamicId,allMakeTop,circleMakeTop);
            notification.success({ message: '置顶成功' });
            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        }catch (e) {

        }
    };

    //置顶圈子动态的方法
    onTopCircleDynamic = async  (e,MakeTop,dynamicId,circleMakeTop,allMakeTop) =>{

        e.stopPropagation();
        const {topToDynamic,getDynamic} = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        if (MakeTop===1){
            return message.error("该动态已在圈子列表置顶");

        }
        try {
            await topToDynamic(dynamicId,allMakeTop,circleMakeTop);
            notification.success({ message: '置顶成功' });
            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        }catch (e) {

        }
    };

    //取消置顶所有动态的方法
    onCancelTopDynamic = async  (e,MakeTop,dynamicId,allMakeTop,circleMakeTop) =>{

        e.stopPropagation();
        console.log(MakeTop)
        const {topToDynamic,getDynamic} = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        if (MakeTop===0){
            return message.error("该动态未在所有动态列表置顶");
        }
        try {
            await topToDynamic(dynamicId,allMakeTop,circleMakeTop);
            notification.success({ message: '取消成功' });
            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        }catch (e) {

        }
    };

    //取消置顶圈子动态的方法
    onCancelCircleDynamic = async  (e,MakeTop,dynamicId,circleMakeTop,allMakeTop) =>{

        e.stopPropagation();
        const {topToDynamic,getDynamic} = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        if (MakeTop===0){
            return message.error("该动态未在圈子列表置顶");
        }
        try {
            await topToDynamic(dynamicId,allMakeTop,circleMakeTop);
            notification.success({ message: '置顶成功' });
            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        }catch (e) {

        }
    };

    //全部取消置顶
    onCancelTop = async  (e,dynamicId,allMakeTop,circleMakeTop) =>{

        e.stopPropagation();
        const {topToDynamic,getDynamic} = this.props;
        const { pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange } = this.state;
        try {
            await topToDynamic(dynamicId,allMakeTop,circleMakeTop);
            notification.success({ message: '取消成功' });
            getDynamic(pageNo, pageSize,userId,dynamicContent,circleName,DynamicStatus,dateRange);
        }catch (e) {

        }
    };


    render() {
        let _this = this;
        const {
            dynamics, loading
        } = this.props;
        const {
            pageNo, pageSize,previewImage,previewVisible
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <DynamicFilter dataSource={{}} onCommit={this.onFilterDynamicEvent} />
                </Card>

                <Card title="动态列表">
                    <Table

                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '用户信息',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                    width: "20%",
                                    render(index,data) {
                                        console.log(data.userDetail.imageUrl)
                                        return <Card bordered={false} style={{ width: 250 }}>
                                            <div style={{flexDirection : 'row',justifyContent:'space-between',display: 'flex'}}>
                                                <img style={{width:100,height:100,borderRadius: '32px'}} alt={""} src={data.userDetail.imageUrl===""?"/lALPBE1XYqT6sKxkZA_100_100.png_620x10000q90g.jpg":data.userDetail.imageUrl+'?x-oss-process=image/resize,w_50'}/>
                                                <div style={{color : '#2A0411',fontWeight:'bold'}}>
                                                    <Meta style={{fontSize: 20}} title={"ID:" + data.userDetail.exhibitionId} description={data.userDetail.username} /><br/>
                                                    {data.userDetail.tag}
                                                </div>


                                            </div>
                                        </Card>
                                    }
                                },
                                {
                                    title: '圈子名',
                                    dataIndex: 'circleName',
                                    key: 'circleName',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '内容',
                                    dataIndex: 'dynamicContent',
                                    key: 'dynamicContent',
                                    align: 'center',
                                    width: "20%",
                                    onCellClick(record, event ){
                                        _this.showModal(record)
                                    }
                                },
                                {
                                    title: '评论数',
                                    dataIndex: 'commentNumber',
                                    key: 'commentNumber',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '点赞数',
                                    dataIndex: 'praiseNumber',
                                    key: 'praiseNumber',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '状态',
                                    dataIndex: '',
                                    key: '',
                                    align: 'center',
                                    width: "5%",
                                    render(index,data) {
                                        if (data.dynamicStatus == "0"){
                                            return "正常";
                                        }else {
                                            return "已屏蔽";
                                        }

                                    }
                                },
                                {
                                    title: '动态置顶状态',
                                    dataIndex: '',
                                    key: '',
                                    align: 'center',
                                    width: "5%",
                                    render(index,data) {
                                        console.log(data,index)
                                        if (data.allMakeTop === 1 && data.circleMakeTop === 1){
                                            return "都已置顶";
                                        }if (data.allMakeTop ===1){
                                            return "在全部动态置顶";
                                        }if (data.circleMakeTop === 1) {
                                            return "在圈子动态置顶";
                                        }else {
                                            return "未置顶";
                                        }

                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id,text) => {
                                        return (<div>
                                            <Button  style={{backgroundColor: '#00a854' ,color : '#fff',marginLeft: 10, marginBottom: 10}} onClick={(event) => this.onGetCommentEvent(event,text.id)}>查看评论</Button>
                                            &nbsp;&nbsp;
                                            {text.dynamicStatus === "0" ?  <Popconfirm title={`确定要屏蔽这条动态吗?`}
                                                                                       onConfirm={(event) => this.onDeleteDynamicEvent(event,text.id)}
                                                                                       okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={this.onEvent}>{text.dynamicStatus === "0" ? "屏蔽" : "恢复"}</Button>
                                                </Popconfirm> :
                                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={(event) => {
                                                     this.onRestoreDynamicEvent(event,text.id)
                                                }}>{text.dynamicStatus === "0" ? "屏蔽" : "恢复"}</Button>}
                                            {(text.allMakeTop === 0 || text.circleMakeTop === 0) ?<Popconfirm title={`确定要置顶这条动态吗?`}
                                                        onConfirm={(event)=> this.onTopCircleDynamic(event,text.circleMakeTop,text.id,1)}
                                                        okText="在圈子动态列表置顶" onCancel={(event)=> this.onTopDynamic(event,text.allMakeTop,text.id,1)} cancelText="在全部动态列表置顶" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                <Button style={{marginLeft: 10, marginBottom: 10}} type="danger" onClick={this.onEvent}>置顶动态</Button>
                                            </Popconfirm>:<Popconfirm title={`确定要取消置顶这条动态吗?`}
                                                                      onConfirm={(event)=> this.onCancelCircleDynamic(event,text.circleMakeTop,text.id,0)}
                                                                      okText="取消在圈子动态置顶" onCancel={(event)=> this.onCancelTopDynamic(event,text.allMakeTop,text.id,0)} cancelText="取消在全部动态置顶" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={this.onEvent}>取消单个置顶动态</Button>
                                            </Popconfirm>}
                                            <Button  style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={(event)=>{this.onCancelTop(event,text.id,0,0)}}>
                                                取消全部置顶动态
                                            </Button>
                                        </div>)
                                    },
                                },
                            ]
                        }

                        // title={
                        //     this.renderTableTitle
                        // }
                        dataSource={dynamics.data ? dynamics.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: dynamics ? dynamics.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="动态内容查看"
                    visible={this.state.isShowDynamicModal}
                   // onOk={this.handleOk}
                    footer={null}
                    cancelText={"取消"}
                    onCancel={this.handleCancel}
                    width={600}
                >
                    <div>
                        {this.state.content} <br/>
                        {this.state.image[0]!=="" ? this.state.image.map((value, key)=>{


                            return <img style={{width:400,height:300}} alt={""} src={value} onClick={() => this.showImage(value ? value+'?x-oss-process=image/resize,w_690' : value)}/>;
                        }) : ""}
                    </div>
                </Modal>

                {previewVisible ? <Modal visible={previewVisible} footer={null} onCancel={this.onThisCancel}>
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal> : null}

                {this.state.isShowCommentModal?<Modal
                    title="评论内容查看"
                    visible={this.state.isShowCommentModal}
                    onOk={this.handleOk}
                    footer={null}
                    onCancel={this.handleCancel}
                    width={900}
                >
                    <DynamicCommentForm {...this.state} />
                </Modal>:null}
            </div>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.viewDynamic)
        return {
            ...state.viewDynamic
        }

    }

);

const mapDispatchToProps = {
    getDynamic,
    deleteDynamic,
    updateDynamicStatus,
    topToDynamic
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(DynamicManagement)));
