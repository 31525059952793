import createReducer from 'utils/createReducer';

import {
    GET_ALL_PLAN,
    GET_MODELS,
    GET_SINGLE_PLAN
} from '../small/actionTypes'

const defaultState = () => ({
    models: [],
    articlesTotal: 0,
    articlesLoading: false,
    planInfo: {},
    adminRoles: [], // 用户所拥有的角色
    plans:[],
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        models:payload

    }

};

const ongetArticlesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        planInfo: payload,
    }

};

const onGetGamesSuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        plans: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_MODELS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_ALL_PLAN}_SUCCESS`]: onGetGamesSuccess,
    [`${GET_SINGLE_PLAN}_SUCCESS`]: ongetArticlesuccess,


});
