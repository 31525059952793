import api from 'utils/api';

class GameRequest {
  /**
     * 获取游戏
     * @param {Object} params 请求参数
     */
  static async getGame() {
    // return api.get('/admin/games');
    return api.get('/admin/configuration/GAME_LIST');
  }
}
export default GameRequest;