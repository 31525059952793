
import createAsyncAction from 'utils/createAsyncAction';
import {
    ADD_QRCODE_INFO,
    GET_QRCODE_LIST,
    DELETE_QRCODE,
} from './actionTypes';
import QrCodeRequest from "../../request/QrCodeRequest";


//添加二维码配置
export const addQrcode = (params) => (createAsyncAction(ADD_QRCODE_INFO, () => (QrCodeRequest.createQrcode(params))));
//查询二维码
export const getQrcodeList = (params) => (createAsyncAction(GET_QRCODE_LIST, () => (QrCodeRequest.getQrcodes(params))));
//删除二维码
export const deleteQrcode = (id) => (createAsyncAction(DELETE_QRCODE, () => (QrCodeRequest.deleteQrcode(id))));
