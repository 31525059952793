import createReducer from 'utils/createReducer';

import {
    GET_LIVE_DATA, GET_LIVE_DETAIL
} from './actionTypes'

const defaultState = () => ({
    liveList:{}, // 全部直播
    liveDetail:{}, //直播详情
});

const onGetLive = (state,{payload}) => {
    return {
        ...state,
        liveList:payload,

    }

};
const onGetLiveDetail = (state,{payload}) => {
    return {
        ...state,
        liveDetail:payload,
        
    }

};

export default createReducer(defaultState, {
    [`${GET_LIVE_DATA}_SUCCESS`]: onGetLive,
    [`${GET_LIVE_DETAIL}_SUCCESS`]: onGetLiveDetail
});
