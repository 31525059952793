import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, Modal
} from 'antd';
import UpLoad from "../../components/upload";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class PlayerDetailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillMount(){

    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if(!err) {
                let { onSubmitEvent } = this.props;

                values['moderatorIdList'] = values.moderatorId.split(',').map(item => {
                    return parseInt(item);
                });
                delete values.moderatorId;

                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            title, visible, onCancel, onSubmitEvent,
            id, playerName, playerAvatar, playerTopImg,signPic, playerUserId, relatedTopicId, moderatorId, type
        } = this.props;
        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                title={title || ''}
                visible={visible}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"35%"}
            >
                <Form>
                    {type === 'UPDATE' && <FormItem
                        label="选手主页ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('id', {
                            rules: [{required: true}],
                            initialValue: id || '',
                        })(
                            <Input disabled={true} />,
                        )}
                    </FormItem>}
                    <FormItem
                        label="选手主页名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerName', {
                            rules: [{required: true, message: '请输入选手主页名，不能包含空格', pattern: /^\S+$/}, {max: 20, message: '选手主页名在20字以内'}],
                            initialValue: playerName || '',
                        })(
                            <Input placeholder="请输入选手主页名" />,
                        )}
                    </FormItem>
                    <FormItem
                        label="选手主页头像"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerAvatar', {
                            rules: [{required: true, message: '请上传选手主页头像'}],
                            initialValue: type === 'UPDATE' ? playerAvatar : '',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="主页TOP图"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerTopImg', {
                            rules: [{required: true, message: '请上传主页TOP图'}],
                            initialValue: type === 'UPDATE' ? playerTopImg : '',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="选手签名图"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('signPic', {
                            initialValue: type === 'UPDATE' ? signPic : '',
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="关联选手ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('playerUserId', {
                            rules: [{required: true, message: '请输入关联选手ID', pattern: /^[0-9]*$/}],
                            initialValue: playerUserId || ''
                        })(
                            <Input placeholder={'请输入关联选手ID'} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="关联主话题ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('relatedTopicId', {
                            rules: [{required: true, message: '请输入关联主话题ID', pattern: /^[0-9]*$/}],
                            initialValue: relatedTopicId || ''
                        })(
                            <Input placeholder={'请输入关联主话题ID'} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="主持人ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('moderatorId', {
                            rules: [{required: true, message: '请输入主持人ID，以英文逗号“,”分隔', pattern: /^(\d+,?)+$/}],
                            initialValue: moderatorId ? moderatorId.substring(1, moderatorId.length - 1) : ''
                        })(
                            <Input placeholder={'请输入主持人ID，以英文逗号“,”分隔'} />,
                        )}
                    </FormItem>

                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(PlayerDetailForm);
