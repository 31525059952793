import React from 'react';
import {Button, Card, Col, InputNumber, message, Modal, Popconfirm, Popover, Row, Table, Tabs, Tag} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getActivity,getActivityType,getActivityDetail,editActivity,addActivity,delActivity,recommendActivity} from "../../modules/activity/action";
import {getGame} from "../../modules/game/action";
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../modules/authentication/constants";
import ActivityModal from "./components/ActivityModal";
import ActivityFilter from "./components/ActivityFilter";

const {confirm} = Modal;
/**
 * 活动
*/
class activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowActivityModel:false,//新增窗口
      modelType:'',//展示弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modelData:{},
      pageNo:1,
      pageSize: 10,
      params:{
        gamingDivision:'',
        date:'',
      },
      id:''
      
    };
  }

  componentWillMount() {
    this.props.getGame()
    this.onGetData()
    this.props.getActivityType()
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  //条件查找
  onFilterActivityEvent = ( params) => {
    let {getActivity} = this.props;
    this.setState({
        pageNo: 1,
        pageSize: 10,
        params:{...params}
    }, () => {
      getActivity({page: 1, size: 10, ...params})
    })
  }

  onGetData = async () => {
      const {pageNo, pageSize,params} = this.state;
      let {getActivity} = this.props;
      console.log("state", this.state)
      await getActivity({page:pageNo, size:pageSize,...params});
  }

  //转换成游戏名字
  changeGameName = (v)=>{
    let {gameList} = this.props;
    let gameName;
    for(let i = 0;i < gameList.length;i++){
      if(gameList[i].code===v){
        gameName = gameList[i].gameName;
        break
      }
    }
    return gameName;
  }

  //查看行程详情
  viewActivity = async(id) =>{
    let {getActivityDetail} = this.props;
   await getActivityDetail(id)
   this.setState({isShowActivityModel: true,modelType:'EDIT',id})
  }

  //编辑行程
  editActivity = async(params) =>{
    let {editActivity} = this.props;
    let {id} = this.state;
    await editActivity(id,params)
    message.success('编辑成功');
    this.onGetData()
  }

  //新增行程
  addActivity = async(params) =>{
    let {addActivity} = this.props;
    await addActivity(params)
    message.success('新增成功');
    this.onGetData()
  }

  //导出数据
  exportData =(name,id,time)=>{
    axios({ // 用axios请求
      method: 'get',
      url: '/api/admin/officialActivity/export/export/signUpUsers/'+id, // 请求地址
      data: {}, // 参数
      responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
      headers: this.getAuthorizationHeader({})
      }).then(r => {
          this.downFile(r.data,name+"的报名用户"+time)
      }).catch(e=>{
          console.log(e.response.data.byteLength)
      })
  }

  downFile = (res, type) => {
    console.log(res)
    var blob = new Blob([res], {type: 'application/ms-excel    application/x-excel;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); // 创建下载的链接
    downloadElement.href = href;
    downloadElement.download = type+'.xlsx'; // 下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); // 点击下载
    document.body.removeChild(downloadElement); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
  };

  getAuthorizationHeader = (headers) => {
      try {
          let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
          const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
          if (headers && accessToken) {
              headers.Authorization = accessToken.token;
          }
          headers.ContentType = 'application/json';
          return headers;
      } catch (error) {
          return '';
      }
  };

  //推荐到首页弹窗
  confirmToIndex = (params) => {
    let _this = this;
    confirm({
      content: params.isRecommend?'确认要推荐到首页吗?':'要取消推荐到首页吗？',
      okText: '确认',
      cancelText: '关闭',
      onOk() {
        _this.toIndex(params)
      },
      onCancel() {
      },

    });
  }
  //推荐到首页
  toIndex = async (params) => {
    let{recommendActivity} = this.props;
    await recommendActivity(params);
    if(params.isRecommend){
      message.success('推荐成功');
    }else{
      message.success('取消成功');
    }
    this.onGetData();
  }

  //删除行程弹窗
  confirmDel = (id) => {
    let _this = this;
    confirm({
      content: '  确认要删除这次的行程吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.delActivity(id)
      },
      onCancel() {
      },

    });
  }
  //删除行程
  delActivity = async (id) => {
    let{delActivity} = this.props;
    await delActivity(id)
    message.success('删除成功');
    this.onGetData();
    
  }

 
  //操作行程详情页面
  handleActivity  = (v,type)=>{
    this.setState({isShowActivityModel:false})
    if(type==='EDIT'){
      this.editActivity({...v})
    }else if(type==='ADD'){
      this.addActivity(v)
    }
  }
  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.setState({isShowActivityModel: true,modelType:'ADD'})}>新增</Button>
        </Col>
    </Row>
  )

 
  render() {
    let{activityList,gameList,activityDetail,activityType,exportSignUpUsers} = this.props;
    let{isShowActivityModel,pageNo,pageSize,modelType,id} = this.state;
    console.log(activityList,'activityList')
    return (
      <div>
        <Card style={{marginBottom: 30}}>
          <ActivityFilter onCommit={this.onFilterActivityEvent} gameList={gameList}/>
        </Card>
        <Card>
            <Table
                columns={
                    [
                        {
                            title: '活动ID',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                        },
                        {
                            title: '活动开始时间',
                            dataIndex: 'startTime',
                            key: 'startTime',
                            align: 'center',
                        },
                        {
                            title: '活动结束时间',
                            dataIndex: 'endTime',
                            key: 'endTime',
                            align: 'center',
                        },
                        {
                            title: '活动标题',
                            dataIndex: 'activityTitle',
                            key: 'activityTitle',
                            align: 'center',
                        },
                        {
                            title: '游戏类型',
                            dataIndex: 'gameType',
                            key: 'gameType',
                            align: 'center',
                            render: (row, text) => (
                                <span>{this.changeGameName(text.gameType)}</span>
                            )
                        },
                        {
                          title: '是否需要报名',
                          dataIndex: 'needSignUp',
                          key: 'needSignUp',
                          align: 'center',
                          render: (row, text) => (
                              <span>{text.needSignUp?'是':'否'}</span>
                          )
                        },
                        {
                            title: '创建时间',
                            dataIndex: 'createTime',
                            key: 'createTime',
                            align: 'center',
                        },
                        {
                            title: '操作',
                            dataIndex: 'operate',
                            key: 'operate',
                            align: 'center',
                            render: (row, text) => (
                                <div>
                                    {/* <Button type={"primary" }style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                      this.setState({isShowActivityModel: true,modelType:'VIEW',modelData:text})
                                    }} color="blue">查看</Button> */}
                                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                      this.viewActivity(text.id)
                                    }} color="blue">编辑</Button>
                                    <Button type={text.isRecommend?"dashed":"primary"} style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        this.confirmToIndex({isRecommend:!text.isRecommend, activityId:text.id})
                                    }} color="blue">{text.isRecommend?'已置顶':'置顶'}</Button>
                                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        this.exportData(text.activityTitle,text.id,text.createTime)
                                    }} color="blue">导出报名</Button>
                                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        this.confirmDel(text.id)
                                    }} color="red">删除</Button>
                                </div>

                            ),
                        },
                    ]
                }

                title={this.renderTableTitle}
                dataSource={activityList ? activityList.data : []}
                bordered
                //loading={loading}
                pagination={{
                    total: activityList ? activityList.total : 0,
                    current: pageNo,
                    pageSize: pageSize,
                    onChange: this.onPageChangeEvent,
                    showTotal:(total)=>{
                        return `总计 ${total}条数据`;
                    }
                }}
            />

            {isShowActivityModel && <ActivityModal
                onSuccess={this.handleActivity}
                visible={isShowActivityModel}
                type={modelType}
                data={activityDetail}
                gameList={gameList}
                typeList={activityType}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                      isShowActivityModel: false,
                    });
                }}
                />}
        </Card>
      </div>
    );
  }

//翻页
onPageChangeEvent = (current, pageSize) => {
  const {getActivity} = this.props;
  const { params} = this.state;
  this.setState({
      pageNo: current,
      pageSize: pageSize,
  }, () => {
    getActivity({page: current, size: pageSize, ...params})
  });
}
  
}

const mapStateToProps = (state) => {
  return {
    ...state.viewActivity,
    ...state.viewGame
  }
};

const mapDispatchToProps = ({
  getActivity,
  getActivityDetail,
  getActivityType,
  editActivity,
  addActivity,
  delActivity,
  recommendActivity,
  getGame,
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps,
)(injectIntl(activity));