import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Popconfirm, Table, Tag,} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getReports,deleteReport,
    assign} from "../../modules/report/action";
import {operationTypeValue} from "../../modules/report/reportEnum";
import CircleReportFilter from "../circle-report/CircleReportFilter";
import DynamicManagement from "../dynamic-management/index";
import RepostDetailForm from "./RepostDetailForm";



class CircleReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            imageUrl: [],
            isShowAssignAdminRoleModal: false,
            isShowImageModal: false,
            currentAdminId: null,
            type: null,
            reports: [],
            showDynamic : false,
            userId : "",
            reportType:"CIRCLE",
            showType:""
        };
    }

    componentDidMount() {

        const {pageNo, pageSize, result,reportType} = this.state;
        let {getReports} = this.props;
        getReports(pageNo, pageSize, reportType, result);
    }


    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getReports} = this.props;
        let { result,reportType} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        console.log(reportType)
        getReports(current, pageSize,"CIRCLE", result);
    }


    onFilterReportEvent = async (type, result) => {
        let {getReports} = this.props;
        const {pageNo, pageSize} = this.state;
        this.setState({
            reportType:type ? type : "CIRCLE"
        })
        getReports(pageNo, pageSize, type ? type : "CIRCLE", result);
    }

    dealAssign = async (id, result) => {
        const {pageNo, pageSize,reportType} = this.state;
        try {
            const {assign, getReports} = this.props;
            await assign(id, result);

            getReports(pageNo, pageSize, reportType);
        } catch (e) {
            console.log(e);
        }
    }

    onShowDynamic = (violatorId,type) =>{
        this.setState({
            showDynamic : true,
            userId : violatorId,
            showType:type
        })
    }

//删除举报的方法
    onDeleteReportEvent = async (adminId) => {
        const {deleteReport, getReports} = this.props;
        const {pageNo, pageSize, result,reportType} = this.state;
        try {
            await deleteReport(adminId);
            notification.success({message: '删除成功'});
            console.log(pageNo)

            getReports(pageNo, pageSize, reportType, result);
        } catch (e) {
            console.log(e);
        }
    }


    handleCancel = e => {
        this.setState({
            showDynamic: false,

        });
    };

    render() {

        const {
            reports, loading
        } = this.props;
        const {
            pageNo, pageSize,showDynamic,showType
        } = this.state;
        console.log(this.state)
        return(

        <div>
            <Card style={{marginBottom: 30}}>
                <CircleReportFilter dataSource={{}} onCommit={this.onFilterReportEvent}/>
            </Card>
            <Card>
                <Table
                    columns={
                        [
                            {
                                title: '反馈人',
                                dataIndex: 'prosecutor',
                                key: 'prosecutor',
                                align: 'center',
                            },
                            {
                                title: '类型',
                                dataIndex: 'type',
                                key: 'type',
                                align: 'center',
                            },
                            {
                                title: '被举报人',
                                dataIndex: 'violator',
                                key: 'violator',
                                align: 'center',
                            },
                            {
                                title: '举报内容',
                                dataIndex: 'reportDetail',
                                key: 'reportDetail',
                                width:'300px',
                                align: 'center',
                            },
                            {
                                title: '举报时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                            },
                            {
                                title: '处理情况',
                                dataIndex: 'resultInt',
                                key: 'resultInt',
                                align: 'center',
                                render: (resultInt, record) => {
                                    if (resultInt === 0) {
                                        return <Tag color={"red"}>未处理</Tag>
                                    } else {
                                        if (resultInt === 1) {
                                            return <Tag>不予处理</Tag>
                                        }
                                        if (resultInt === 2) {
                                            return <Tag color={"green"}>已处理</Tag>
                                        }
                                    }
                                }
                            },
                            {
                                title: '处理时间',
                                dataIndex: 'operationDate',
                                key: 'operationDate',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (text, record) => {
                                    if (record.resultInt === 0 && record.typeInt != 2) {
                                        return <div><Popconfirm title={`请选择处理结果`}
                                                                onConfirm={() => this.dealAssign(record.id, "COMPLETE")}
                                                                onCancel={() => this.dealAssign(record.id, "CLOSE")}
                                                                okText="已处理" cancelText="不予处理">
                                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" color={"#2db7f5"}>处理</Button>
                                        </Popconfirm>
                                            <Popconfirm title={`确定要删除该记录吗？`}
                                                        onConfirm={() => this.onDeleteReportEvent(record.id)}
                                                        okText="确认" cancelText="取消">
                                                <Button style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                            </Popconfirm>
                                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() =>this.onShowDynamic(record.violatorId,record.type)}>查看</Button>
                                        </div>;
                                    } else {
                                        return <div>
                                            <Popconfirm title={`确定要删除该记录吗？`}
                                                        onConfirm={() => this.onDeleteReportEvent(record.id)}
                                                        okText="确认" cancelText="取消">
                                                <Button style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                            </Popconfirm></div>;
                                    }
                                }
                            },
                        ]
                    }
                    title={
                        this.renderTableTitle
                    }
                    dataSource={reports.data ? reports.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: reports ? reports.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
            </Card>
            <Modal
                title={showType === "评论内容违规" ? "评论内容" : "动态内容"}
                visible={this.state.showDynamic}
                // onOk={this.handleOk}
                footer={null}
                destroyOnClose={true}
                cancelText={"取消"}
                onCancel={this.handleCancel}
                width={'60%'}
            >
                <RepostDetailForm {...this.props} {...this.state} onCommit={this.onUpdateEvent}/>
            </Modal>

        </div>
        )
    }

}
const mapStateToProps = (state => {
        console.log(state.Comments)
        return {
            ...state.Report

        }

    }

);

const mapDispatchToProps = {
    getReports,
    deleteReport,
    assign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CircleReport)));