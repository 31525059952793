
export const operationTypeValue = {
  INCREASE: 'INCREASE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

export const stateType = {
    NORMAL: {
        label: '正常',
        value:'NORMAL',
    },
    BANNED: {
        label: '封禁',
        value:'BANNED'
    },
};

export const genderType = {
    UNKNOWN: {
        label: '未知',
        value: 'UNKNOWN'
    },
    MALE: {
        label: 'GG',
        value: 'MALE'
    },
    FEMALE: {
        label: 'MM',
        value: 'FEMALE'
    },
}



export const goodsModel = {
    TIME_CHARGE: {
        label: '按小时收费',
        value:'TIME_CHARGE',
    },
    GAME_CHARGE: {
        label: '按局数收费',
        value:'GAME_CHARGE'
    },
};
