import api from 'utils/api';


export default class UserRequest {
  /**
   * 获取用户列表
   * @param page
   * @param size
   * @returns {*}
   */
  static getAdmins(page, size) {
    return api.get('/admin/admins', { page, size });
  }

  /**
   * 获取用户详情
   * @param userId
   * @returns {*}
   */
  static getAdmin(userId) {
    return api.get(`/admin/admins/${userId}`);
  }

  /**
   * 获取用户头像
   * @param path
   * @returns {*}
   */
  static getAdminAvatar(path) {
    return api.get(`/admin/file/image/${path}`);
  }


  /**
   * 创建用户
   * @param username
   * @param password
   * @param telephone
   * @param mailbox
   * @returns {AxiosPromise<any> | * | {headers} | void}
   */
  static createAdmin({
    username, password, telephone, mailbox,
  }) {
    return api.post('/admin/admins', {
      username, password, telephone, mailbox,
    });
  }

  /**
   * 更新用户
   * @param id
   * @param telephone
   * @param mailbox
   * @returns {AxiosPromise<any> | * | {headers} | IDBRequest<IDBValidKey> | Promise<void>}
   */
  static updateAdmin(id, { telephone, mailbox, imageUrl }) {
    return api.put(`/admin/admins/${id}`, { telephone, mailbox, imageUrl });
  }

  /**
    * 修改密码
    * @param id
    * @param password
    * @param confirmPassword
    * @returns {AxiosPromise<any> | * | {headers} | IDBRequest<IDBValidKey> | Promise<void>}
    */
  static updateAdminPassword(id, { oldPassword,password, confirmPassword }) {
    return api.put(`/admin/admins/${id}/password`, { oldPassword,password, confirmPassword });
  }

  /**
   * 删除用户
   * @param id
   * @returns {*}
   */
  static deleteAdmin(id) {
    return api.delete(`/admin/admins/${id}`);
  }

  /**
   * 解除账户锁定
   * @param id
   * @returns {*}
   */
  static unLockAdmin(id) {
    return api.put(`/admin/admins/${id}/unlock`);
  }

  /**
   * 获取用户的角色列表
   * @param id
   * @returns {*}
   */
  static getAdminRoles(id) {
    return api.get(`/admin/admins/${id}/roles`);
  }

  /**
   * 授权用户角色
   * @param id
   * @param roleCodes
   * @returns {AxiosPromise<any> | * | {headers} | void}
   */
  static assignAdminRoles(id, roleCodes) {
    return api.post(`/admin/admins/${id}/roles`, roleCodes);
  }
}
