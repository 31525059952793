import React, {Component} from "react";
import {Button, DatePicker, Form, Input, Select, notification} from "antd";
import moment from 'moment';

import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getBrand, getTraining} from "../../../modules/training/action";
import * as axios from "axios";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../../modules/authentication/constants";
import {map} from 'lodash/fp';
import qs from "qs";

const dateFormat = 'YYYY-MM-DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


const openNotification = placement => {
    notification.error({
        message: `${placement}`,
        // description:
        //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // placement,
    });
};

class TrainingFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trainingName: '',
            brandId: '',
            startTime: '',
            endTime: '',
            gameCount: '',
            dateRange:[]
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.getBrand();
        this.getGameList();
    }

    getBrand = () => {
        let {getBrand} = this.props;
        getBrand({page: 1, size: 100000});
    }

    getGameList = () => {
        let {getGameList} = this.props;
        getGameList();
    }

    handleSubmit(e) {
        let {onCommit} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onCommit(values);
            }
        });
    }

    getCurrentYear = () => {
        var date = new Date();

        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        return y + '/' + m;
    }

    downFile = (res, type) => {
        console.log(res)
        var blob = new Blob([res], {type: 'application/ms-excel    application/x-excel;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = type+'战绩.xlsx'; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    };

    getAuthorizationHeader = (headers) => {
        try {
            let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
            const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
            if (headers && accessToken) {
                headers.Authorization = accessToken.token;
            }
            headers.ContentType = 'application/json';
            return headers;
        } catch (error) {
            return '';
        }
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        let {brandData, games} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline" style={{width: '100%'}}>

                <FormItem label="赛事名">
                    {getFieldDecorator('trainingName', {}
                    )(
                        <Input style={{width: '150px'}} onChange={(e) => {
                            this.setState({trainingName: e.currentTarget.value})
                        }} allowClear/>
                    )}
                </FormItem>
                <FormItem label="赛事类型">
                    {getFieldDecorator('brandId', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{minWidth: 250}} onChange={e => {
                            console.log(e)
                            this.setState({brandId: e})
                        }}>
                            <Option value="">全部</Option>
                            {brandData.data && brandData.data.map(item => {
                                return (
                                    <Option
                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>

                {/* <FormItem label="积分最高场次">
                    {getFieldDecorator('gameCount', {rules: [
                            {
                                pattern: /^[1-9][0-9]*$/,
                                message: '请输入大于0的正整数'
                            }
                        ]}
                    )(
                        <Input style={{width: '150px'}} onChange={(e) => {
                            this.setState({gameCount: e.currentTarget.value})
                        }} allowClear/>
                    )}
                </FormItem> */}
                <FormItem label="赛事周期">

                    {getFieldDecorator('dateRange', {
                            // initialValue: [moment().subtract('7','day'), moment().subtract('0','day')]
                        }
                    )(
                        <RangePicker
                            onChange={(value, dateString)=>{
                                this.setState({dateRange:value})
                            }}
                            defaultValue={[moment(this.getCurrentYear() + '/01', dateFormat)]}
                            format={dateFormat}
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" onClick={() => {
                        let {trainingName, brandId, dateRange, gameCount} = this.state;
                        // window.open(`/api/training/export/score?trainingName=${trainingName}&brandId=${brandId}`)
                        // api.post('/training/export/score', {brandId}, false, {responseType: 'arraybuffer'})
                        dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange)
                        let startTime = "";
                        let endTime = "";
                        if(dateRange.length>0){
                            startTime = dateRange[0]+" 00:00:00";
                            endTime = dateRange[1]+" 23:59:59";
                        }
                        let param={trainingName, brandId, type: 'TEAM', gameCount};
                        if(startTime){
                            param["startTime"] = startTime;
                        }else{
                            delete param.startTime
                        }
                        if(endTime){
                            param["endTime"] = endTime;
                        }else{
                            delete param.endTime
                        }
                        axios({ // 用axios发送post请求
                            method: 'get',
                            url: '/api/admin/training/export/score?'+qs.stringify(param), // 请求地址
                            data: {}, // 参数
                            responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
                            headers: this.getAuthorizationHeader({})
                        }).then(r => {
                            this.downFile(r.data,"战队")
                        }).catch(e=>{
                            console.log(e.response.data.byteLength)
                            if(e.response.data.byteLength==44){
                                openNotification('未知异常')
                            }
                            if(e.response.data.byteLength==72){
                                openNotification('未选择赛事类型')
                            }
                            if(e.response.data.byteLength==39){
                                openNotification('无战绩')
                            }
                        })

                    }}>导出战队战绩</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" onClick={() => {
                        let {trainingName, brandId, dateRange, gameCount} = this.state;
                        // window.open(`/api/training/export/score?trainingName=${trainingName}&brandId=${brandId}`)
                        // api.post('/training/export/score', {brandId}, false, {responseType: 'arraybuffer'})
                        dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange)
                        let startTime = "";
                        let endTime = "";
                        if(dateRange.length>0){
                            startTime = dateRange[0]+" 00:00:00";
                            endTime = dateRange[1]+" 23:59:59";
                        }
                        let param={trainingName, brandId, type: 'GAMER', gameCount};
                        if(startTime){
                            param["startTime"] = startTime;
                        }else{
                            delete param.startTime
                        }
                        if(endTime){
                            param["endTime"] = endTime;
                        }else{
                            delete param.endTime
                        }
                        axios({ // 用axios发送post请求
                            method: 'get',
                            url: '/api/admin/training/export/score?'+qs.stringify(param), // 请求地址
                            data: {}, // 参数
                            responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
                            headers: this.getAuthorizationHeader({})
                        }).then(r => {
                            this.downFile(r.data, "个人")
                        }).catch(e=>{
                            console.log(e.response.data.byteLength)
                            if(e.response.data.byteLength==44){
                                openNotification('未知异常')//可以可以
                            }
                            if(e.response.data.byteLength==72){
                                openNotification('未选择赛事类型')
                            }
                            if(e.response.data.byteLength==39){
                                openNotification('无战绩')
                            }
                        })

                    }}>导出个人战绩</Button>
                </FormItem>
            </Form>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTraining,
    getGameList,
    getBrand
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingFilter)));