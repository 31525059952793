import api from '../utils/api';


class CircleRequest {


    /**
     * 获取圈子列表
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
    static getCircles(page, size) {
        return api.get('/admin/circles', { page, size });
    }

    /**
     * 更新圈子
     * @param circlesId
     * @param name
     * @returns {AxiosPromise<any> | * | {headers} | IDBRequest<IDBValidKey> | Promise<void>}
     */
    static updateCircle(circlesId, { name }) {
        return api.put(`/admin/circles/${circlesId}`,  name );
    }

    /**
     * 获取圈子详情
     * @param circlesId
     * @returns {*}
     */
    static getCircle(circlesId) {
        return api.get(`/admin/circles/${circlesId}`);
    }

    /**
     * 删除圈子
     * @param id
     * @returns {*}
     */
    static deleteCircle(circlesId) {
        return api.delete(`/admin/circles/${circlesId}`);
    }


    /**
     * 增加圈子
     * @param {Object} params 请求参数
     */
    static addCircle(name) {
        return api.post('/admin/circles', name);
    }

}

export default CircleRequest;