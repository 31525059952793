import React, {Component} from 'react';
import {
    Form, Input, Button, Modal
} from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class MedalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentWillMount(){
        
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                let params = { userIds: values.userIds };
                onSubmitEvent && onSubmitEvent(params);
            }
        });
    };

    render() {
        let {
            visible, onCancel, onSubmitEvent, 
            childMedalId, level, childMedalName, greyMedalPic, colorMedalPic, conditionNum, childMedalDesc, medalType, type
        } = this.props;      
        const {getFieldDecorator} = this.props.form;

        let title = '';
        if(type === 'SEND_SUB') {
            title = "赠送“" + childMedalName + "勋章”给用户";
        } else if(type === 'RECYCLE_SUB') {
            title = "回收“" + childMedalName + "勋章”";
        }
        
        return (
            <Modal
                title={title}
                visible={visible}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"35%"}
            >
                <Form>
                    <FormItem
                        label="勋章ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('childMedalId', {
                            initialValue: ''
                        })(
                            <div>{childMedalId || ''}</div>
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('childMedalName', {
                            initialValue: ''
                        })(
                            <div>{childMedalName || ''}</div>
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章图标"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('colorMedalPic', {
                            initialValue: ''
                        })(
                            <img src={colorMedalPic || ''} alt={""} style={{width: 100, height: 100}}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="用户ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userIds', {
                            rules: [{required: true, message: '请输入用户ID，以英文逗号“,”分隔', pattern: /^(\d+,?)+$/}],
                            initialValue: ''
                        })(
                            <Input placeholder="请输入用户ID，以英文逗号“,”分隔" />,
                        )}
                    </FormItem>

                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(MedalForm);
