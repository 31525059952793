export const GET_PLAYERS = 'GET_PLAYERS';// 获取职业选手列表
export const ADD_PLAYER = 'ADD_PLAYER';// 新增职业选手
export const DELETE_PLAYER = 'DELETE_PLAYER';// 删除职业选手
export const PUT_PLAYER = 'PUT_PLAYER'; //修改职业选手

export const GET_TRANSFERS= 'GET_TRANSFERS'; //查询选手转会记录列表
export const ADD_TRANSFER = 'ADD_TRANSFER'; //添加选手转会记录
export const UPDATE_TRANSFER = 'UPDATE_TRANSFER'; //修改选手转会记录
export const DELETE_TRANSFER = 'DELETE_TRANSFER';// 删除选手转会记录

export const GET_HONORS= 'GET_HONORS'; //查询选手荣誉记录列表
export const ADD_HONOR = 'ADD_HONOR'; //添加选手荣誉记录
export const UPDATE_HONOR = 'UPDATE_HONOR'; //修改选手荣誉记录
export const DELETE_HONOR = 'DELETE_HONOR';// 删除选手荣誉记录

export const GET_CAREERS= 'GET_CAREERS'; //查询选手职业生涯列表
export const ADD_CAREER = 'ADD_CAREER'; //添加选手职业生涯列
export const UPDATE_CAREER = 'UPDATE_CAREER'; //修改选手职业生涯
export const DELETE_CAREER = 'DELETE_CAREER';// 删除选手职业生涯