import api from '../utils/api';

class WithdrawRequest {

    static getWithdraws(page, size, id, state, startTime, endTime) {
        return api.get(`/admin/accounts/cash-out-orders`, {page, size, id, state, startTime, endTime});
    }

    static optWithdraw(orderId, params) {
        return api.put(`/admin/accounts/cash-out-orders/${orderId}`, params);
    }

    static getStatisticalWithdrawals(params){
        return api.get(`/admin/accounts/statistical/withdrawals`, params);
    }

}

export default WithdrawRequest;