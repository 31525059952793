import React, {Component} from 'react';
import {
    Form, Input, Button, Select
} from 'antd';
import UpLoad from "../../components/upload";
import api from "../../utils/api";
import {operationTypeValue} from "../../modules/topic/topicEnum";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class PostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
        
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            userId,            
            playerName,
            emojiUrl
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <FormItem
                    label="用户ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('userId', {
                        rules: [{required: true, message: '请输入作者ID', pattern: /^[0-9]*$/}],
                        initialValue: userId || null
                    })(
                        <Input placeholder="请输入作者ID" disabled={true}/>,
                    )}
                </FormItem>
                <FormItem
                    label="所属选手主页"
                    {...formItemLayout}
                >
                    {getFieldDecorator('playerName', {
                        rules: [{required: true, message: '请输入所属选手主页', pattern: /^\S+$/}],
                        initialValue: playerName || ''
                    })(
                        <Input placeholder="请选择所属选手主页" disabled={true} />                                              
                    )}
                </FormItem>
                <FormItem
                    label="图片(355*177)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('emojiUrl', {
                        initialValue: emojiUrl  ? emojiUrl+'?x-oss-process=image/resize,w_86' : '',
                    })(
                        (<UpLoad
                            // value={emojiUrl}                           
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={501}
                            disabled={true}  
                        />),
                    )}
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(PostForm);
