import api from '../utils/api';
import {map} from 'lodash/fp';


class CommentRequest {


    /**
     * 分页查评论管理界面
     * @param page
     * @param size
     * @param commentUserExhibitionId 评论人展示id
     * @param commentUserName 评论人用户名
     * @param keyword 关键字
     * @param themeName 话题名
     * @param commentStatus 状态:NORMAL/SHIELD
     * @param commentThemeType 评论类型:-- OFFICIAL_ACTIVITY OFFICIAL_INFORMATION POST SCHEDULE
     * @param gameType 游戏类型
     * @param dateRange
     * @returns {*}
     * @returns {*}
     */
    static getComment(page, size, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, dateRange, themeName,themeId,vestComment,gameType) {
        let startTime = null, endTime = null;
        if (dateRange !== undefined) {
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0]+" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1]+" 23:59:59";
        }
        return api.get('/admin/comment/page', {page, size, commentThemeType, commentUserExhibitionId, commentUserName, keyword, commentStatus, startTime, endTime, themeName,themeId,vestComment,gameType});
    }

    static getMajiaComment(page, size,dynamicId,userId) {
        return api.get('/admin/vest/comment', {page, size, dynamicId, userId,});
    }

    static getCommentTemplates(page, size) {
        return api.get('/admin/comment/template', {page, size});
    }

    static createCommentTemplate(content) {
        return api.post('/admin/comment/template', {content});
    }

    static updateCommentTemplate(id,content) {
        return api.put('/admin/comment/template', {id,content});
    }

    static deleteCommentTemplate(id) {
        return api.delete(`/admin/comment/template/${id}`);
    }


    /**
     * 评论类型字典
     */
     static async getCommentTypeDictionaries(page, size){
        return api.get(`/admin/comment/template`, {page, size});
    }
}
export default CommentRequest;
