export default {
    CREATE_BRAND: {
        label: '新增',
        value:'CREATE_PERSON_BRAND',
    },
    ASSIGN_BRAND: {
        label: '授权',
        value:'ASSIGN_PERSON_BRAND',
    },
};
