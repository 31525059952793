import React from 'react';
import {Form, Input, Modal,DatePicker,Select, Button,notification } from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import {operationTypeValue} from '../../../modules/topic/topicEnum';
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddInfoPostsModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                console.log("values",values);
                onSuccess && onSuccess(values);
            }
        });
    }
    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,data,operationType} = this.props;
        return (
            <Modal
                width={'30%'}
                visible={visible}
                title = {operationType === operationTypeValue.UPDATE ? "编辑资讯帖子关联" : "添加资讯帖子关联"}
                onCancel={this.props.onClose}
                footer={
                [
                    <Button key="cancel" className="ant-btn-custom-circle" onClick={this.props.onClose}>取消</Button>,
                    <Button key="confirm" className="ant-btn-custom-circle" type="primary" onClick={this.onSubmit}>确认</Button>
                ]

            }
            >
                <Form>
                    <FormItem
                        label="资讯ID"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('thirdInformationId', {
                            rules: [{required: true, message: '请输入资讯ID'}],
                            initialValue:operationType!==operationTypeValue.INCREASE?data.thirdInformationId:null
                        })(
                            <Input disabled={operationType!==operationTypeValue.INCREASE?true:false} placeholder="请输入资讯ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="关联类型"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('infoJumpType', {
                            rules: [{required: true, message: '请选择关联类型!'}],
                            initialValue: operationType!==operationTypeValue.INCREASE?data.infoJumpType:null
                        })(
                            <Select placeholder="请选择关联类型">
                                <Select.Option value="MATCH_ACTIVITY">赛事</Select.Option>
                                <Select.Option value="TOPIC_DYNAMIC">帖子</Select.Option>
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="关联ID"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('relationId', {
                            rules: [{required: true, message: '请输入关联ID'}],
                            initialValue:operationType!==operationTypeValue.INCREASE?data.relationId:null
                        })(
                            <Input placeholder="请输入关联ID"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
AddInfoPostsModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddInfoPostsModel)));
