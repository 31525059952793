import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class SmallModelFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                console.log(values)
                let {onCommit} = _self.props;
                let {brand,brandName,model,modelName,planId} = values;

                onCommit && onCommit(brand,brandName,model,modelName,planId);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline">
                    <FormItem label="品牌">
                        {getFieldDecorator('brand', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem label="品牌名">
                        {getFieldDecorator('brandName', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem label="机型">
                        {getFieldDecorator('model', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem label="机型名">
                        {getFieldDecorator('modelName', {}
                        )(
                            <Input style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem label="方案">
                        {getFieldDecorator('planId', {}
                        )(
                            <Select style={{width: 120}}>
                                <Option value = "" >全部</Option>
                                <Option value = "1" >存在</Option>
                                <Option value = "2">不存在</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>
                </Form>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SmallModelFilter)));