import React, {Component} from "react";
import {Button, DatePicker, Form, Input, Select, notification} from "antd";
import moment from 'moment';

import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getPersonBrand, getTraining} from "../../../modules/training/action";
import {map} from 'lodash/fp';
const dateFormat = 'YYYY-MM-DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


const openNotification = placement => {
    notification.error({
        message: `${placement}`,
        // description:
        //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // placement,
    });
};

class TrainingTemplateFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandId: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.getPersonBrand();
        this.getGameList();
    }

    getPersonBrand = () => {
        let {getPersonBrand} = this.props;
        getPersonBrand({page: 1, size: 100000});
    }

    getGameList = () => {
        let {getGameList} = this.props;
        getGameList();
    }

    handleSubmit(e) {
        let {onCommit} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onCommit(values);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {personBrandData, games} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline" style={{width: '100%'}}>
                <FormItem label="品牌名称">
                    {getFieldDecorator('brandId', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{minWidth: 250}} onChange={e => {
                            console.log(e)
                            this.setState({brandId: e})
                        }}>
                            <Option value="">全部</Option>
                            {personBrandData.data && personBrandData.data.map(item => {
                                return (
                                    <Option
                                        value={item.id}>{(games.filter((game) => game.code == item.game).length > 0 ? games.filter((game) => game.code == item.game)[0].gameName : '') + '-' + item.name}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>
            </Form>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTraining,
    getGameList,
    getPersonBrand
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingTemplateFilter)));