import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Row, Table, message, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import CardForm from './CardForm';
import CardPicForm from './CardPicForm';
import LotteryModal from "./LotteryModal";
import CardFilter from "./CardFilter";
import { operationTypeValue } from "../../modules/gift/giftEnum";
import { getCardList, createCard, updateCommemorativeCard, getThemeSettingList, deleteCard, updateThemeSetting, getCardPicList, onUpdateCardPic, deleteThemeSetting, onAndOffTheShelfTheme } from '../../modules/commemorative-card/action';
import {getGame} from "../../modules/game/action";
const { confirm } = Modal;
class GiftManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, //操作类型：默认为添加
            page: 1,
            size: 10,
            showCardModal: false,
            giftId: null,
            showLotteryModal: false,
            showCardPicModal:false,
            seletionValue:null,
        };
    }

    componentWillMount(){
        this.props.getGame();
    }

    //转换成游戏名字
    changeGameName = (v)=>{
        let {gameList} = this.props;
        let gameName;
        for(let i = 0;i < gameList.length;i++){
            if(gameList[i].code===v){
                gameName = gameList[i].gameName;
                break
            }
        }
        return gameName;
    }

    componentDidMount() {
        const {page, size} = this.state;
        let { getCardList } = this.props;
        getCardList({page, size});
    }

    onPageChangeEvent = (page, size) => {
        let { getCardList } = this.props;
        let { commemorativeCardThemeName, gameType } = this.state;
        this.setState({
            page,
            size
        });
        getCardList({page, size, commemorativeCardThemeName, gameType});
    }

    onFilterGiftEvent = (commemorativeCardThemeName, gameType) => {
        const { getCardList } = this.props;
        const { size } = this.state;
        this.setState({
            commemorativeCardThemeName, 
            gameType
        });
        getCardList({page: 1, size, commemorativeCardThemeName, gameType});
    }

    onOpenEditCardModalEvent = (text, type) => {              
        if (type === operationTypeValue.UPDATE) {           
            this.setState({
                cardInfo: { ...text, type }
            });
        } else {
            this.setState({
                cardInfo: { type }
            });
        }
        this.setState({
            operationType: type,
            showCardModal: true,
        });
    }

    onCloseEditGiftModalEvent = () => {
        this.setState({
            showCardModal: false
        });
    }
    
    onAddGiftEvent = async ({ commemorativeCardQuantity, commemorativeCardThemeCoverPic, commemorativeCardThemeIntroduce, commemorativeCardThemeName, difficultyLevel, energyReward, gameType, lotteryStatus, sort}) => {
        const {page, size} = this.state;
        const {createCard, getCardList} = this.props;
        try {
            await createCard({
                commemorativeCardQuantity, 
                commemorativeCardThemeCoverPic, 
                commemorativeCardThemeIntroduce, 
                commemorativeCardThemeName, 
                difficultyLevel, 
                energyReward, 
                gameType, 
                lotteryStatus, 
                sort
            });
            notification.success({message: '添加成功'});
            this.setState({
                showCardModal: false
            });
            getCardList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onEditGiftEvent = async ({commemorativeCardQuantity, commemorativeCardThemeCoverPic, commemorativeCardThemeIntroduce, commemorativeCardThemeName, difficultyLevel, energyReward, gameType, lotteryStatus, sort}) => {
        const {page, size, cardInfo} = this.state;
        const {updateCommemorativeCard, getCardList} = this.props;
        try {
            await updateCommemorativeCard(cardInfo.id, {
                commemorativeCardQuantity, commemorativeCardThemeCoverPic, commemorativeCardThemeIntroduce, commemorativeCardThemeName, difficultyLevel, energyReward, gameType, lotteryStatus, sort
            });
            notification.success({message: '更新成功'});
            this.setState({
                showCardModal: false,
            });            
            getCardList({page, size});
        } catch (e) {
            console.log(e);
        }
    }

    onOpenLotteryModalEvent = async (seletionValue, type) => {
        const {getThemeSettingList} = this.props;
        await getThemeSettingList(seletionValue.id);
        this.setState({
            seletionValue: seletionValue,
            operationType: type,
            showLotteryModal: true
        });
    }

    onEditCardPicEvent = (values) => {
        this.onUpdateCardPicEvent(values);
    }     

    onUpdateCardPicEvent = async (params) => {
        const {seletionValue, isShowModal} = this.state;
        const {onUpdateCardPic} = this.props;
        await onUpdateCardPic(seletionValue.id,params);
        // await this.onGetPlanData();
        message.success('编辑成功');
        this.setState({isShowModal: !isShowModal});
    }


    onShowPostModalEvent  = async (seletionValue, themeId) => {
        const { getCardPicList } = this.props;

        await getCardPicList(themeId);
        this.setState({
            seletionValue: seletionValue,
            isShowModal: true,
        });
    }

    onClosePostModalEvent = () => {
        this.setState({
            isShowModal: false,
        });
    }

    removeItem = (id) => {
        if(!id) return;
        let {seletionValue} = this.state;
        let {deleteThemeSetting,getThemeSettingList} = this.props;
        confirm({
            title: '删除?',
            content: '是否删除该抽奖设置',
            okType: 'danger',
            onOk: async () => {
              await deleteThemeSetting(id);
              await getThemeSettingList(seletionValue.id);
              this.setState({ 
                showLotteryModal: false
              });
              message.success('删除成功');
            },
          });
        
    }

    onCloseLotteryModal = () => {
        this.setState({
            operationType: operationTypeValue.INCREASE,
            showLotteryModal: false
        });
    }

    onFormSubmit = (values) => {
        console.log(values)
        this.onUpdateThemeSettingEvent(values);
        
    }

    // 编辑抽奖设置
    onUpdateThemeSettingEvent = async (params) => {
        const {seletionValue, showLotteryModal} = this.state;
        const {updateThemeSetting,getThemeSettingList} = this.props;
        await updateThemeSetting(seletionValue.id, params);
        await getThemeSettingList(seletionValue.id);
        message.success('编辑成功');
        this.setState({showLotteryModal: !showLotteryModal});
    }

    confirmUnBanUser = (themeId,listingStatus) => {
        let _this = this;
        confirm({
            content: `确认要${listingStatus?"上架":"下架"}该纪念卡吗?`,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUnBanUser(themeId,listingStatus)
            },
            onCancel() {
            },

        });
    }

    onUnBanUser = async (themeId,listingStatus) => {
        const {getCardList,onAndOffTheShelfTheme} = this.props;
        let {page, size} = this.state;
        await onAndOffTheShelfTheme(themeId, listingStatus);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        getCardList({page, size});
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onOpenEditCardModalEvent(null, operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>

    )

    render() {
        const {
            commemorativeCard, loading, gameList
        } = this.props;
        const {
            showCardModal, page, size, operationType, cardInfo, showLotteryModal, isShowModal
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CardFilter dataSource={{}} onCommit={this.onFilterGiftEvent} gameList={gameList}></CardFilter>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '纪念卡主题ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '纪念卡主题名',
                                    dataIndex: 'commemorativeCardThemeName',
                                    key: 'commemorativeCardThemeName',
                                    align: 'center',
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'gameType',
                                    key: 'gameType',
                                    align: 'center',
                                    render: (row, text) => (
                                        <span>{this.changeGameName(text.gameType)}</span>
                                    )
                                },
                                {
                                    title: '纪念卡数量',
                                    dataIndex: 'commemorativeCardQuantity',
                                    key: 'commemorativeCardQuantity',
                                    align: 'center',
                                },
                                {
                                    title: '是否抽奖',
                                    dataIndex: 'lotteryStatus',
                                    key: 'lotteryStatus',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>{text.lotteryStatus ? '是' : '否'}</div>
                                    )
                                },
                                {
                                    title: '展示序列',
                                    dataIndex: 'sort',
                                    key: 'sort',
                                    align: 'center',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                                                                        
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (id, text) => {
                                        return <div>                                          
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenEditCardModalEvent(text, operationTypeValue.UPDATE)}
                                                    color="blue">编辑</Button>
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onOpenLotteryModalEvent(text, operationTypeValue.SELECT)}
                                                    color="blue">抽奖设置</Button>
                                            <Button type="primary"
                                                    style={{marginLeft: 10, marginBottom: 10}}
                                                    onClick={() => this.onShowPostModalEvent(text,text.id)}
                                                    color="blue">编辑卡片</Button>                                                    
                                            {
                                            text.listingStatus?
                                                <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.confirmUnBanUser(text.id,false)}
                                                        color="blue">下架</Button> :
                                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.confirmUnBanUser(text.id,true)}>上架</Button> 
                                            }
                                        </div>
                                    }
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={commemorativeCard.data ? commemorativeCard.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: commemorativeCard ? commemorativeCard.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    <Modal
                        title={operationType === operationTypeValue.UPDATE ? "编辑纪念卡主题" : "新增纪念卡主题"}
                        visible={showCardModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onCloseEditGiftModalEvent}
                        width={'50%'}
                    >
                        {operationType === operationTypeValue.UPDATE ?
                            <CardForm {...this.props} {...this.state} {...cardInfo}
                                        onSubmitEvent={this.onEditGiftEvent} gameList={gameList} isUpdate={true}/> :
                            <CardForm {...cardInfo} onSubmitEvent={this.onAddGiftEvent} gameList={gameList} isUpdate={false}/>}
                    </Modal>
                    <Modal
                        title={"编辑卡片"}
                        visible={isShowModal}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={this.onClosePostModalEvent}
                        width={'50%'}
                    >
                        <CardPicForm {...this.props} {...this.state} onSubmitEvent={this.onEditCardPicEvent}/>
                    </Modal>
                </Card>
                {operationType === operationTypeValue.SELECT && <LotteryModal
                    visible={showLotteryModal}
                    onSuccess={this.onFormSubmit}
                    onClose={this.onCloseLotteryModal}
                    removeItem={this.removeItem}
                />}
            </div>
        );

    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Banner)
        return {
            ...state.Commemorativecard,
            ...state.viewGame
        }

    }

);

const mapDispatchToProps = {
    getGame,
    getCardList,
    createCard, 
    updateCommemorativeCard,
    deleteCard,
    getThemeSettingList,

    updateThemeSetting,
    getCardPicList,
    onUpdateCardPic,
    deleteThemeSetting,
    onAndOffTheShelfTheme
    
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(GiftManagement)));
