import React, {Component} from "react";
import {Button, Form, Input, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const Option = Select.Option;

class OrderFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
  }

  handleSubmit(e) {
    e.preventDefault();
    let _self = this;
    this.props.form.validateFields((err, values) => {
        if (!err) {
            let {onCommit} = _self.props;
            let {username, turnTablePrizeType, shippingStatus} = values;

            console.log(values)

            onCommit && onCommit(username, turnTablePrizeType, shippingStatus);
        }
    });
}


  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div>
          <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="用户名">
                    {getFieldDecorator('username', {
                        initialValue: ''
                    }) (
                        <Input style={{width: 200}} allowClear/>
                    )}
                </FormItem>
              <FormItem label="奖品类型">
                    {getFieldDecorator('turnTablePrizeType', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: '200px'}}>
                            <Option value={null}>全部</Option>
                            <Option value="IN_KIND_GIFT">实物周边</Option>
                            <Option value="VIP">VIP会员（虚拟礼品）</Option>
                            <Option value="GOLD">紫金（虚拟礼品）</Option>
                            <Option value="ENERGY">能量值（虚拟礼品）</Option>                            
                        </Select>
                    )}
                </FormItem>
              <FormItem label="发货状态">
                    {getFieldDecorator('shippingStatus', {
                            initialValue: null
                        }
                    )(
                        <Select defaultValue={null} style={{width: 150}}>
                            <Option value={true}>已发货</Option>
                            <Option value={false}>未发货</Option>
                        </Select>
                    )}
                </FormItem>
              
              <FormItem>
                  <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                      查询
                  </Button>
              </FormItem>
          </Form>
      </div>

    );
  }
}
export default Form.create()(OrderFilter);