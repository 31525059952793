
export const GET_BRAND_INFO = 'GET_BRAND_INFO'; // 获取品牌赛事详情详情

export const GET_BRAND_LIST = 'GET_BRAND_LIST'; // 获取品牌赛事列表

export const CREATE_BRAND = 'CREATE_BRAND'; // 创建品牌赛事

export const UPDATE_BRAND = 'UPDATE_BRAND'; // 修改品牌赛事

export const AUTHORIZATION_BRAND = 'AUTHORIZATION_BRAND'; // 授权品牌赛事

export const GET_PERSON_BRAND_LIST = 'GET_PERSON_BRAND_LIST'; // 个人训练赛-获取品牌赛事列表

export const CREATE_PERSON_BRAND = 'CREATE_PERSON_BRAND'; // 个人训练赛-创建品牌赛事

export const UPDATE_PERSON_BRAND = 'UPDATE_PERSON_BRAND'; // 个人训练赛-修改品牌赛事

export const AUTHORIZATION_PERSON_BRAND = 'AUTHORIZATION_PERSON_BRAND'; // 个人训练赛-授权品牌赛事
