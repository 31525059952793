import React, { Component } from 'react';
import {
    Button, Table, Modal, Card, notification, message, Popconfirm,
} from 'antd';
import {getEvent, addEvent, deleteEvent, updateEvent} from "../../../modules/club/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import AddEventModal from "./AddEventModal";



class EventRecordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            showAdd:false,
            selectValue:null,
        };
    }
    componentDidMount() {
        const {pageNo, pageSize, selectValue} = this.state;
        let {getEvent,clueId} = this.props;
        getEvent(clueId,pageNo, pageSize);

    }

    // componentWillReceiveProps(nextProps,nextState) {
    //     const {pageNo, pageSize} = this.state;
    //     let {getComment,clubId} = this.props;
    //     console.log(clubId)
    //     if(nextProps.dynamicId!==this.props.dynamicId){
    //         getComment(pageNo, pageSize,nextProps.dynamicId);
    //     }
    // }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getEvent,clueId} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getEvent(clueId,current, pageSize);
    }

    onShowBind =()=>{
        this.setState({
            showAdd:true
        })

    }

    onUpdate =(value)=>{
        console.log("67", value);
        this.setState({
            showAdd:true,
            selectValue:value
        })

    }

    renderTitle=()=>{
        return(
            <Button style={{marginBottom:16}} onClick={this.onShowBind}>添加荣誉</Button>
        )
    }
    handleCancel = e => {
        this.setState({
            showAdd: false,


        });
    };

    // 增加赛事信息
    onAddSaiEvent = async (awardDate,name,grade) => {
        const {addEvent,clueId,getEvent, updateEvent} = this.props;
        const {showAdd, selectValue} = this.state;
        if(selectValue==null){
            await addEvent(clueId,awardDate,name,grade);
        }else{
            await updateEvent(selectValue.id,awardDate,name,grade);
        }
        await getEvent(clueId,1,10);
        message.success('新增赛事信息成功');
        this.setState({showAdd: !showAdd,pageNo:1, selectValue: null});
    }

    //删除赛事信息的方法
    onDeleteEvent = async (adminId) => {
        const { deleteEvent, getEvent,clueId } = this.props;
        const { page, size } = this.state;
        try {
            await deleteEvent(clueId,adminId);
            notification.success({ message: '删除成功' });

            getEvent(clueId,page, size);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const {
            eventList, loading
        } = this.props;
        const {pageSize,pageNo,showAdd, selectValue} = this.state;

        return (
            <div>
            <Card>
                <Table

                    columns={
                        [
                            {
                                title: '比赛时间',
                                dataIndex: 'awardDate',
                                key: 'awardDate',
                                align: 'center',
                            },
                            {
                                title: '赛事名称',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                            },
                            {
                                title: '获奖名次',
                                dataIndex: 'grade',
                                key: 'grade',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                render: (row, text) => (
                                    <div>
                                        <Button type="primary" style={{
                                            marginRight:10,
                                            marginBottom:10
                                        }}  onClick={()=>this.onUpdate(text)} >修改</Button>
                                        <Popconfirm title={`确定要删除这个赛事记录吗?`}
                                                    onConfirm={() => this.onDeleteEvent(text.id)}
                                                    okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                            <Button type="danger" >删除</Button>
                                        </Popconfirm>
                                    </div>
                                ),
                            },
                        ]
                    }
                    title={
                        this.renderTitle
                    }
                    dataSource={eventList.data ? eventList.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: eventList ? eventList.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />

            </Card>
                <Modal
                    title="添加荣誉记录"
                    visible={showAdd}
                    // onOk={this.handleOk}
                    footer={null}
                    //cancelText={"取消"}
                    onCancel={this.handleCancel}
                    destroyOnClose
                    width={600}
                >
                    <AddEventModal values={selectValue} onSubmitEvent={this.onAddSaiEvent}/>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state => {
        return {
            ...state.viewClub
        }

    }

);

const mapDispatchToProps = {
    getEvent,addEvent,deleteEvent, updateEvent
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(EventRecordModal)));
