import createAsyncAction from "utils/createAsyncAction";
import PELPlayerRequest from "../../request/PELPlayerRequest";
import {
    GET_PLAYERS,
    ADD_PLAYER,
    DELETE_PLAYER,
    PUT_PLAYER,
    GET_TRANSFERS,
    ADD_TRANSFER,
    UPDATE_TRANSFER,
    DELETE_TRANSFER,
    ADD_HONOR,
    DELETE_HONOR,
    GET_HONORS,
    UPDATE_HONOR,
    GET_CAREERS,
    ADD_CAREER,
    UPDATE_CAREER,
    DELETE_CAREER
} from "../player/actionTypes";


const getPELPlayers = (params) => (
    createAsyncAction(GET_PLAYERS, () => (PELPlayerRequest.getPELPlayers(params)))
);
const addPELPlayer = (params) => (
    createAsyncAction(ADD_PLAYER, () => (PELPlayerRequest.addPELPlayer(params)))
);
const deletePELPlayer = (id) => (
    createAsyncAction(DELETE_PLAYER, () => (PELPlayerRequest.deletePELPlayer(id)))
)
const editPELPlaye =(id,params)=>(
    createAsyncAction(PUT_PLAYER, () => (PELPlayerRequest.editPELPlaye(id,params)))
)

const getEvent = (userId,  page, size ) => (createAsyncAction(GET_TRANSFERS, () => (PELPlayerRequest.getEvent(userId,page, size))));
const addEvent = (userId,changeDate,changeFee,resourcesClubId,targetClubId) => (createAsyncAction(ADD_TRANSFER, () => (PELPlayerRequest.addEvent(userId,changeDate,changeFee,resourcesClubId,targetClubId))));
const updateEvent = (id,changeDate,changeFee,resourcesClubId,targetClubId) => (createAsyncAction(UPDATE_TRANSFER, () => (PELPlayerRequest.updateEvent(id,changeDate,changeFee,resourcesClubId,targetClubId))));
const deleteEvent=(id)=>(
    createAsyncAction(DELETE_TRANSFER, () => (PELPlayerRequest.deleteEvent(id)))
)

const getHonorManageList = (userId,  page, size ) => (createAsyncAction(GET_HONORS, () => (PELPlayerRequest.getHonorManageList(userId,page, size))));
const addHonorRecord = (userId,awardDate,name,grade) => (createAsyncAction(ADD_HONOR, () => (PELPlayerRequest.addHonorRecord(userId,awardDate,name,grade))));
const updateHonorRecord = (id,awardDate,name,grade) => (createAsyncAction(UPDATE_HONOR, () => (PELPlayerRequest.updateHonorRecord(id,awardDate,name,grade))));
const deleteHonorRecord=(raceCourseId)=>(
    createAsyncAction(DELETE_HONOR, () => (PELPlayerRequest.deleteHonorRecord(raceCourseId)))
)

const getCareerManageList = (userId,  page, size ) => (createAsyncAction(GET_CAREERS, () => (PELPlayerRequest.getCareerManageList(userId,page, size))));
const addCareerRecord = (userId,joinDate,pelLeagueTypes,quirDate,teamShowId) => (createAsyncAction(ADD_CAREER, () => (PELPlayerRequest.addCareerRecord(userId,joinDate,pelLeagueTypes,quirDate,teamShowId))));
const updateCareerRecord = (id,joinDate,pelLeagueTypes,quirDate,teamShowId) => (createAsyncAction(UPDATE_CAREER, () => (PELPlayerRequest.updateCareerRecord(id,joinDate,pelLeagueTypes,quirDate,teamShowId))));
const deleteCareerRecord=(id)=>(
    createAsyncAction(DELETE_CAREER, () => (PELPlayerRequest.deleteCareerRecord(id)))
)


export {
    getPELPlayers,
    addPELPlayer,
    editPELPlaye,
    deletePELPlayer,

    addEvent,
    updateEvent,
    getEvent,
    deleteEvent,

    getHonorManageList,
    addHonorRecord,
    updateHonorRecord,
    deleteHonorRecord,

    getCareerManageList,
    addCareerRecord,
    updateCareerRecord,
    deleteCareerRecord
};
