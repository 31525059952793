import createReducer from 'utils/createReducer';
import {
  GET_USER_RESUMES
} from './actionTypes';

const defaultState = () => ({
  userResumes: [],
});


const onGetUserResumeSuccess = (state, { payload }) => ({
  ...state,
  userResumes:payload
});

export default createReducer(defaultState, {
  [`${GET_USER_RESUMES}_SUCCESS`]: onGetUserResumeSuccess,
});
