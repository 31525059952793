import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tree } from 'antd';
import { is, fromJS } from 'immutable';

// 获取权限keys
const getDefaultCheckedKeys = (data, arr, key) => {
  data.forEach((item) => {
    const id = key ? `${key}-${item.id}` : `${item.id}`;
    if (!item.children) {
      arr.push(id);
    } else {
      getDefaultCheckedKeys(item.children, arr, id);
    }
  });
};
const { TreeNode } = Tree;
const propTypes = {
  onSuccess: PropTypes.func,
  value: PropTypes.any,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  tree: PropTypes.array,
};

const defaultProps = {
  value: [],
  visible: false,
  tree: [],
  onClose: () => {

  },
};

class Permisssions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [],
    };
  }

  componentWillMount() {
    this.onInitData();
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (!is(fromJS(value), fromJS(nextProps.value))) {
      this.onInitData(nextProps);
    }
  }

    renderTreeNodes = (data, id) => {
      if (data.length == 0) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return data.map((item) => {
        const key = id ? `${id}-${item.id}` : `${item.id}`;
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode title={item.name} key={key}>
              {this.renderTreeNodes(item.children, key)}
            </TreeNode>
          );
        }
        return (
          <TreeNode title={item.name} key={key} />
        );
      });
    }


    onInitData = (props = this.props) => {
      const { value } = props;
      const defaultValue = value || [];
      const selectedKeys = [];
      getDefaultCheckedKeys(defaultValue, selectedKeys);
      this.setState({
        selectedKeys,
      });
    }

    onCheckTreeEvent = (selectedKeys) => {
      this.setState({
        selectedKeys,
      });
    }

    onSubmit = () => {
      const { selectedKeys } = this.state;
      const { onSuccess } = this.props;
      const keys = [...new Set(selectedKeys.join('-').split('-'))];
      onSuccess(keys);
    }

    renderTree = () => {
      const { tree } = this.props;
      const { selectedKeys } = this.state;
      return (
        <div>
          {tree.length > 0 ? (
            <Tree
              defaultExpandAll
              checkable
              defaultCheckedKeys={selectedKeys}
              onCheck={this.onCheckTreeEvent}
            >
              {this.renderTreeNodes(tree)}
            </Tree>
          ) : null}
        </div>
      );
    }

    render() {
      const { visible, onClose } = this.props;
      return (
        <Modal
          visible={visible}
          title="权限"
          onCancel={onClose}
          onOk={this.onSubmit}
        >
          {this.renderTree()}
        </Modal>

      );
    }
}


Permisssions.propTypes = propTypes;
Permisssions.defaultProps = defaultProps;
export default Permisssions;
