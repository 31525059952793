import React, { Component } from 'react';
import {
  Form,
  Input,
  Button,
  notification, message,
} from 'antd';
import UserRequest from '../../../request/AdminRequest';
import crypto from "crypto";
import md5 from "md5";


class PassWordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      params: {
        oldPassword :'',
        password: '',
        confirmPassword: '',
      },
    };
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('您输入的两次密码不一致!');
    } else {
      callback();
    }
  };

  handleSubmit = async (userId) => {
    const { form } = this.props;
    let { params } = this.state;
    //let md5 = crypto.createHash("md5");
    params.oldPassword = form.getFieldValue('oldPassword') !== "" ? md5(form.getFieldValue('oldPassword')) : "";
    params.password = form.getFieldValue('password') !== "" ? md5(form.getFieldValue('password')) : "";
    params.confirmPassword = form.getFieldValue('confirmPassword') !== "" ? md5(form.getFieldValue('confirmPassword')) : "";

    try {
      await UserRequest.updateAdminPassword(userId, { ...params });
      notification.success({ message: '更新成功' });
      form.resetFields();
    } catch (e) {
      message.error(e.response.data.msg);
      form.resetFields();
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const { userId } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form {...formItemLayout}>
        <Form.Item label="旧密码" hasFeedback>
          {getFieldDecorator('oldPassword', {
            rules: [
              {
                required: true,
                message: '请输入密码!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="密码" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: '请输入密码!',
              },
              // {
              //   pattern:'^[A-Z]{1}[0-9a-zA-Z_]',
              //   message:'由字母(首位大写),数字,或者下划线'
              // },
              {
                min:8,
                message:'密码最少8位'
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="确认密码" hasFeedback>
          {getFieldDecorator('confirmPassword', {
            rules: [
              {
                required: true,
                message: '请输入确认密码',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input onChange={this.handleConfirmBlur} />)}
        </Form.Item>

        <Button
          type="primary"
          style={{ width: 100, margin: '0 auto' }}
          onClick={() => this.handleSubmit(userId, getFieldDecorator)}
        >
          提交
          </Button>

      </Form>
    );
  }
}

export default Form.create({ name: 'passWord_form' })(PassWordForm);
