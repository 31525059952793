import React, {Component} from 'react';
import {Button, Form, Input, Select,} from 'antd';
import PropTypes from 'prop-types';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

class AddEventModal extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(values.awardDate, values.name, values.grade);
            }
        });
    }

    render() {
        console.log("32" + this.props)
        let {
            awardDate, name, grade, values
        } = this.props;

        console.log("45" + awardDate)
        console.log("46" + name)
        console.log("47" + grade)
        console.log("48" + values)
        const {getFieldDecorator} = this.props.form;

        return (
            <Form>
                <FormItem
                    label="比赛时间"
                    {...formItemLayout}
                >
                    {getFieldDecorator('awardDate', {
                        rules: [{required: true, message: '请填写比赛时间', pattern: /^\S+$/}],
                        initialValue: values && values.awardDate ? values.awardDate : "",
                    })(
                        <Input placeholder="请填写比赛时间 (时间格式例子：2017-12-13)"/>,
                    )}
                </FormItem>
                <FormItem
                    label="赛事名称"
                    {...formItemLayout}
                >
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: '请填写赛事名称', pattern: /^\S+$/}],
                        initialValue: values && values.name ? values.name : "",
                    })(
                        <Input placeholder="请填写赛事名称"/>,
                    )}
                </FormItem>
                <FormItem
                    label="获奖名次"
                    {...formItemLayout}
                >
                    {getFieldDecorator('grade', {
                        rules: [{required: true, message: '请填写获奖名次', pattern: /^\S+$/}],
                        initialValue: values && values.grade ? values.grade : "",
                    })(
                        <Select style={{width: '100px'}}>
                            <Select.Option value="冠军">冠军</Select.Option>
                            <Select.Option value='亚军'>亚军</Select.Option>
                            <Select.Option value='季军'>季军</Select.Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

AddEventModal.propTypes = propTypes;

export default Form.create()(AddEventModal);
