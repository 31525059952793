import createAsyncAction from 'utils/createAsyncAction';
import {GET_WITHDRAW_ORDERS, OPT_WITHDRAW_ORDER, GET_STATISTICAL_WITHDRAWALS} from "../withdraw/actionType";
import WithDrawRequest from "../../request/WithDrawRequest";

const getWithdraws = (page, size, id, state, startTime, endTime) => (
    createAsyncAction(GET_WITHDRAW_ORDERS, () => (WithDrawRequest.getWithdraws(page, size, id, state, startTime, endTime)))
);

const optWithdraw = (orderId, params) => (
    createAsyncAction(OPT_WITHDRAW_ORDER, () => (WithDrawRequest.optWithdraw(orderId, params)))
);

const getStatisticalWithdrawals = (params) => (
    createAsyncAction(GET_STATISTICAL_WITHDRAWALS, () => (WithDrawRequest.getStatisticalWithdrawals( params)))
);

export {
    getWithdraws, optWithdraw, getStatisticalWithdrawals
};