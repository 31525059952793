import createReducer from 'utils/createReducer';
import {GET_WITHDRAW_ORDERS, GET_STATISTICAL_WITHDRAWALS} from "./actionType";

const defaultState = () => ({
    withdraws: [],
    withdrawsTotal: 0,
    withdrawsLoading: false,
    withdrawsStatistical: null
});


const onGetWithdrawRequest = (state, {payload}) => {
    console.log(payload)
    return {
        ...state,
        withdraws: payload
    }
};

const onGetStatisticalWithdrawRequest = (state, {payload}) => {
    console.log(payload)
    return {
        ...state,
        withdrawsStatistical: payload
    }
};

export default createReducer(defaultState, {
    [`${GET_WITHDRAW_ORDERS}_SUCCESS`]: onGetWithdrawRequest,
    [`${GET_STATISTICAL_WITHDRAWALS}_SUCCESS`]: onGetStatisticalWithdrawRequest,
});
