
import api from 'utils/api';

class PartnerGoodsRequest {

    /**
     * 新增陪练商品
     * @param {Object} params 请求参数
     */
    static async createPartnerGoods(params) {
        return api.post(`/admin/partner-training/goods`, params);
    }

    /**
     * 修改陪练商品
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
    static async updatePartnerGoods(id, params) {
        return api.put(`/admin/partner-training/goods/${id}`, params);
    }


    /**
     * 删除陪练商品
     * @param {Object} params 请求参数
     * @param {Number} id 角色id
     */
    static async deletePartnerGoods(id) {
        return api.delete(`/admin/partner-training/goods/${id}`);
    }


    /**
     * 更新陪练商品上下架
     */
    static updatePartnerGoodsStatus(id,params){
        return api.put(`/admin/partner-training/goods/${id}/state`,params);
    }


    /**
     * 查询陪练商品列表
     * @param {Object} params 请求参数
     */
    static async queryPartnerGoods(params) {
        return api.get('/admin/partner-training/goods', params);
    }


    /**
     * 获取详情
     * @param {Number} id
     */
    static async getPartnerGoods(id) {
        return api.get(`/admin/partner-training/goods/${id}`);
    }
}

export default PartnerGoodsRequest;
