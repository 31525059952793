export const GET_ARTICLES = 'GET_ARTICLES';// 获取文章列表

export const GET_ARTICLE = 'GET_ARTICLE';// 获取文章信息

export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';//更新文章

export const DELETE_ARTICLE = 'DELETE_ARTICLE';//删除文章

export const CREATE_ARTICLE = 'CREATE_ARTICLE';// 创建文章

export const CHANGE_ARTICLE_STATUS = 'CHANGE_ARTICLE_STATUS';//修改文章状态

export const GET_GAMES = 'GET_GAMES';//获取所有游戏