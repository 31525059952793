import createReducer from 'utils/createReducer';

import {
    GET_COMMENTS, GET_COMMENT_TYPE_DIC, GET_COMMENT_TEMPLATES, GET_MAJIA_COMMENTS
} from './actionType';

const defaultState = () => ({
    comments: [],
    commentsTotal: 0,
    commentsLoading: false,
    commentsInfo: {},
    adminRoles: [], // 用户所拥有的角色
});




const ongetCommentsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        comments:payload

    }

};

const ongetMajiaCommentsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        majiaComments:payload

    }

};

const ongetCommentTemplatesRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        commentTemplates:payload

    }

};

const ongetCommentTypeDicRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        commentTypeDic:payload
    }
};


export default createReducer(defaultState, {
    [`${GET_COMMENTS}_SUCCESS`]: ongetCommentsRequest,
    [`${GET_COMMENT_TYPE_DIC}_SUCCESS`]: ongetCommentTypeDicRequest,
    [`${GET_COMMENT_TEMPLATES}_SUCCESS`]: ongetCommentTemplatesRequest,
    [`${GET_MAJIA_COMMENTS}_SUCCESS`]: ongetMajiaCommentsRequest,
});
