import React from 'react';
import {Form, Input, Modal, Select, message, InputNumber} from 'antd';
import {fromJS, is} from 'immutable';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getUsers} from "../../../modules/user/action";


const {confirm} = Modal;
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 9},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const byteSize = str => new Blob([str]).size;

class PartnerTrainingGoodsModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            user: null,
            goodsModel: null,
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            defaultValue,
            goodsModel: defaultValue ? defaultValue.goodsModel : null
        });

        const {getGameList} = props;
        getGameList();
    }

    onSubmit = () => {
        let {user} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (user) {
                    values["userId"] = user.id
                }
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChangeEvent = (value) => {
        this.setState({
            goodsModel: value
        })
    }
    validateGoodsName = (rule, value, callback) => {
        if (value) {
            console.log(byteSize(value))
            if (byteSize(value) > 15) {
                callback('商品名称不能大于15个字节')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {value, visible, operationType, games} = this.props;
        const {goodsModel} = this.state;
        const defaultValue = value || {};
        return (
            <div>
                <Modal
                    width={'30%'}
                    visible={visible}
                    title={operationType === operationTypeValue.UPDATE ? "修改陪练商品" : operationType === operationTypeValue.INCREASE ? "添加陪练商品" : "添加陪练商品"}
                    onCancel={this.props.onClose}
                    cancelText={"取消"}
                    onOk={this.onSubmit}
                    okText={operationType === operationTypeValue.UPDATE ? "确认" : operationType === operationTypeValue.INCREASE ? "确认" : "确认"}
                >
                    <Form>
                        <FormItem
                            label="商品名称"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('goodsName', {
                                initialValue: defaultValue ? defaultValue.goodsName : '',
                                rules: [{required: true, message: '请选择商品名称!'},
                                    {validator: this.validateGoodsName}],
                            })(
                                <Input placeholder="请选择商品名称" style={{width: 200}}/>,
                            )}
                        </FormItem>
                        <FormItem
                            label="商品类型"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('type', {
                                initialValue: defaultValue ? defaultValue.type : '',
                                rules: [{required: true, message: '请选择商品类型!'}],
                            })(
                                <Select placeholder="请选择商品类型" style={{width: 150}}>
                                    <Option value={'陪练订单'}>陪练订单</Option>
                                </Select>
                            )}
                        </FormItem>

                        <FormItem
                            label="游戏类型"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('game', {
                                initialValue: games.filter((item) => item.code === defaultValue.game).length > 0 ? games.filter((item) => item.code === defaultValue.game)[0].code : undefined,
                                rules: [{required: true, message: '请选择游戏类型!'}],
                            })(
                                <Select placeholder="请选择游戏类型" style={{width: 150}}>
                                    {games.map((item) => <Option value={item.code}
                                                                 key={item.code}>{item.gameName}</Option>)}
                                </Select>,
                            )}
                        </FormItem>

                        <FormItem label="商品模式"
                                  {...formItemLayout}>
                            {getFieldDecorator('goodsModel', {
                                    initialValue: defaultValue ? defaultValue.goodsModel : '',
                                    rules: [{required: true, message: '请选择商品模式!'}],
                                }
                            )(
                                <Select placeholder="请选择商品模式" style={{width: 150}} onChange={this.onChangeEvent}>
                                    <Option value={'TIME_CHARGE'}>按小时收费</Option>
                                    <Option value={'GAME_CHARGE'}>按局数收费</Option>
                                </Select>
                            )}
                        </FormItem>


                        <FormItem label="商品单价"
                                  {...formItemLayout}>
                            {getFieldDecorator('price', {
                                    initialValue: defaultValue ? defaultValue.price : '',
                                    rules: [{required: true, message: '请输入商品单价!'}, {
                                        message: '只能输入非零开头金额整数',
                                        pattern: /^[1-9][0-9]*$/
                                    }, {max: 10, message: '价格不能大于10位数'}],
                                }
                            )(
                                <Input  placeholder="请选择商品单价" style={{width: 200}}
                                       addonAfter={goodsModel === 'TIME_CHARGE' ? '浪币/0.5小时' : goodsModel === 'GAME_CHARGE' ? '浪币/局' : '浪币'}/>
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.user
    }
};
const mapDispatchToProps = ({
    getGameList,
    getUsers
});
PartnerTrainingGoodsModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PartnerTrainingGoodsModel)));