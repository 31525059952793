import React from "react";
import { Button, Table, Upload, message, Radio } from "antd";
import "./index.scss";
import {
  getGrade,
  getPersonTrainingGrade,
  getPersonTrainingGradeDetail,
  updateGrade,
} from "../../modules/training/action";
import { getGameList } from "../../modules/team/action";
import { connect } from "react-redux";
import { injectIntl } from "react-intl-context";
import Cookie from "js-cookie";
import { ACCESS_TOKEN } from "modules/authentication/constants";
const getAuthorizationHeader = (headers) => {
  try {
    let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
    const accessToken = accesstokenJSONStr
      ? JSON.parse(accesstokenJSONStr)
      : null;
    if (headers && accessToken) {
      headers.Authorization = accessToken.token;
    }
    return headers;
  } catch (error) {
    return "";
  }
};

var color = ["#ffebcd", "#d1eeee", "#bdbdbd", "#5f9ea0", "#9f79ee"];

class PersonalGrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 25,
      editState: false,
      changeObj: {},
      personTrainGrade: {},
      allMember: false,
    };
    this.haveLoad = false;
    this.header = {};
  }

  componentWillMount() {
    this.header = getAuthorizationHeader({});
    let { trainingId } = this.props.match.params;
    let { page, size } = this.state;
    let { getPersonTrainingGrade, getGameList } = this.props;
    getPersonTrainingGrade({ page, size, trainingId: window.atob(trainingId) });
    getGameList();
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //     if(nextProps.editGradeStatus!==this.props.editGradeStatus){
  //         this.requestGrade();
  //         this.showEdit(false)
  //         this.setState({grades:[]})
  //     }
  // }
  onPageChangeEvent = async (page) => {
    await this.setState({ page });
    await this.requestGrade();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.personTrainGrade !== this.props.personTrainGrade) {
      this.setState(
        {
          personTrainGrade: nextProps.personTrainGrade,
        },
        () => {
          if (!this.haveLoad) {
            this.requestGradeDetail();
          }
        }
      );
    }
  }

  requestGradeDetail = () => {
    let { personTrainGrade } = this.state;
    let { trainingId } = this.props.match.params;
    let { getPersonTrainingGradeDetail } = this.props;
    personTrainGrade.data &&
      personTrainGrade.data.map((v) => {
        this.haveLoad = true;
        getPersonTrainingGradeDetail({
          page: 1,
          size: 100,
          trainingId: window.atob(trainingId),
          gamerId: v.gamerId,
        });
        return null;
      });
  };

  requestGrade = () => {
    let { trainingId } = this.props.match.params;
    let { page, size } = this.state;
    let { getPersonTrainingGrade } = this.props;
    getPersonTrainingGrade({ page, size, trainingId: window.atob(trainingId) });
    this.haveLoad = false;
  };

  render() {
    let { personTrainGrade } = this.state;
    let { gameNum } = this.props.match.params;
    let game = [...new Array(parseInt(window.atob(gameNum))).keys()];
    console.log(game);
    let { page, size } = this.state;
    let gradeData = personTrainGrade.data
      ? personTrainGrade.data.map((v, i) => {
          let key = {};
          v.grades &&
            v.grades.map((item, index) => {
              Object.keys(item).map((val) => {
                key[val + item.gameSequenceNo] = item[val];
                return null;
              });
              return null;
            });
          return {
            ...v,
            ...key,
          };
        })
      : [];
      console.log(gradeData,'==============');
    return (
      <div>
        <Table
          columns={this.getTeamColumns().concat(
            ...game.map((v, i) => {
              return this.getBoColumns(i + 1);
            })
          )}
          dataSource={gradeData}
          bordered
          pagination={{
            // total: teams ? teams.total : 0,
            total: personTrainGrade.total,
            current: page,
            pageSize: size,
            onChange: this.onPageChangeEvent,
            showTotal: (total) => {
              return `总计 ${total}条数据`;
            },
          }}
          scroll={{ x: 800 * game.length }}
        />
      </div>
    );
  }

  showEdit = (type) => {
    this.setState({
      edit: type,
    });
  };

  // submitChange=(trainingId)=>{
  //     let {grades}  =this.state;
  //     let {updateGrade} = this.props;
  //     updateGrade({trainingId,teamGradeItemCommands:grades});
  // }
  //
  // changeTotalValue=(e,type,record)=>{
  //     let val = e.currentTarget.value;
  //     let {grades} = this.state;
  //     let curGrades = grades.filter(item=>item.teamId===record.teamId);
  //     if(curGrades.length>0){
  //         curGrades[0][type]=val;
  //     }else{
  //         grades.push({
  //             teamId:record.teamId,
  //             [type]:val
  //         });
  //     }
  //     console.log(grades)
  //     this.setState({grades})
  // }
  //
  // changeValue=(e,num,type,record)=>{
  //     let atr = type.split(num)[0];
  //     let val = e.currentTarget.value;
  //     console.log(val)
  //     let {grades} = this.state;
  //     let curGrades = grades.filter(item=>item.teamId===record.teamId);
  //     if(curGrades.length>0){
  //         let performanceData = curGrades[0].performanceData;
  //         if(performanceData){
  //             let performance = performanceData.filter(v=>v.gameSequenceNo === num);
  //             if(performance.length>0){
  //                 performance[0][atr] = val;
  //             }else{
  //                 curGrades[0].performanceData.push({gameSequenceNo:num,[atr]:val})
  //             }
  //         }else{
  //             curGrades[0]["performanceData"] = [{gameSequenceNo:num,[atr]:val}]
  //         }
  //     }else{
  //         grades.push({
  //             teamId:record.teamId,
  //             performanceData:[
  //                 {
  //                     gameSequenceNo:num,
  //                     [atr]:val
  //                 }
  //             ]
  //         });
  //     }
  //    this.setState({grades});
  // }
  //
  // deleteGrade=(trainingId)=>{
  //     let {deleteGrade} = this.props;
  //     deleteGrade({trainingId,gradeType:"PERSONAL_GRADE"})
  //         .then(r=>{
  //             message.success('清除成功');
  //             this.requestGrade();
  //         })
  // }
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      allMember: e.target.value,
    });
  };

  getTeamColumns = (item) => {
    let { games } = this.props;
    console.log(games);
    let { trainingId, trainingName } = this.props.match.params;
    var paramData = { trainingId: window.atob(trainingId) };
    return [
      {
        title: (
          <div>
            <span className="trainingTitle">
              赛事ID：{window.atob(trainingId)}
            </span>
            <span className="trainingTitle">赛事名称：{trainingName}</span>
            <Radio.Group onChange={this.onChange} value={this.state.allMember}>
              <Radio value={false}>报名成员</Radio>
              <Radio value={true}>全体成员</Radio>
            </Radio.Group>
            {/*<Button type="primary" disabled={this.state.grades.length===0} onClick={()=>{this.submitChange(trainingId)}}>提交</Button>*/}
            <Button
              type="primary"
              style={{ marginLeft: 30 }}
              onClick={() => {
                window.open(
                  `/api/admin/personal/training/export/${window.atob(
                    trainingId
                    )}/personal?allMembers=${this.state.allMember}`
                );
              }}
            >
              下载成绩模板
            </Button>
            <Upload
              action="/api/admin/personal/training/personal/file"
              listType="text"
              headers={this.header}
              name="excelFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              data={paramData}
              onChange={this.handleChange}
            >
              <Button type="primary" style={{ marginLeft: 30 }}>
                上传战绩
              </Button>
            </Upload>
          </div>
        ),
        className: "bo1",
        children: [
          {
            title: "排名",
            dataIndex: "totalRank",
            key: "totalRank",
            width: 100,
          },
          {
            title: "玩家ID",
            dataIndex: "gamerId",
            key: "gamerId",
            width: 100,
          },
          {
            title: "玩家展示ID",
            dataIndex: "gamerShowId",
            key: "gamerShowId",
            width: 100,
          },
          {
            title: "第三方游戏ID",
            dataIndex: "thirdGamerId",
            key: "thirdGamerId",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "用户名",
            dataIndex: "userName",
            key: "userName",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "参赛昵称",
            dataIndex: "gamerNickName",
            key: "gamerNickName",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "备用参赛昵称1",
            dataIndex: "gamerNickName1",
            key: "gamerNickName1",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "备用参赛昵称2",
            dataIndex: "gamerNickName2",
            key: "gamerNickName2",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "场均淘汰",
            dataIndex: "avgKill",
            key: "avgKill",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "参赛场次",
            dataIndex: "signTimes",
            key: "signTimes",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "吃鸡数",
            dataIndex: "chickens",
            key: "chickens",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "总积分",
            dataIndex: "totalIntegral",
            key: "totalIntegral",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
          {
            title: "总淘汰",
            dataIndex: "totalKill",
            key: "totalKill",
            width: 100,
            render: (text, record, index) => {
              return text;
            },
          },
        ],
      },
    ];
  };

  handleChange = (e) => {
    if (e.file.status === "done") {
      console.log(e);
      message.success("上传成功");
      this.showEdit(false);
      this.requestGrade();
    } else if (e.file.status === "error") {
      console.log(e);
      message.error(e.file.response.msg);
    }
  };

  getBoColumns = (num) => {
    return [
      {
        className: "bo1",
        children: [
          {
            title: `第${num}局排名`,
            dataIndex: "position" + num,
            key: "position" + num,
            width: 150,
            render: (text, record, index) => {
              return text;
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
          {
            title: `第${num}局淘汰`,
            dataIndex: "gameKill" + num,
            key: "gameKill" + num,
            width: 150,
            render: (text, record, index) => {
              return text;
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
          {
            title: `第${num}局死亡次数`,
            dataIndex: "dieNums" + num,
            key: "dieNums" + num,
            width: 150,
            render: (text, record, index) => {
              return text;
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
          {
            title: `第${num}局排名分`,
            dataIndex: "rankingIntegral" + num,
            key: "rankingIntegral" + num,
            width: 150,
            render: (text, record, index) => {
              return text;
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
          {
            title: `第${num}局淘汰分`,
            dataIndex: "killIntegral" + num,
            key: "killIntegral" + num,
            width: 150,
            render: (text, record, index) => {
              return text;
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
          {
            title: `第${num}局是否吃鸡`,
            dataIndex: "isEatChicken" + num,
            key: "isEatChicken" + num,
            width: 150,
            render: (text, record, index) => {
              return text ? "是" : "否";
            },
            onCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
            onHeaderCell: () => {
              return {
                style: {
                  backgroundColor: color[num % 5],
                },
              };
            },
          },
        ],
      },
    ];
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.viewTraining,
    ...state.Team,
  };
};

const mapDispatchToProps = {
  getGrade,
  getGameList,
  updateGrade,
  getPersonTrainingGrade,
  getPersonTrainingGradeDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PersonalGrade));
