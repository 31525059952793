
import createAsyncAction from 'utils/createAsyncAction';
import TeamRequest from "../../request/TeamRequest";
import {
    GET_TEAM_INFO,
    GET_TEAM_LIST,
    UPDATE_TEAM,
    CREATE_TEAM,
    UPDATE_TEAM_GAME_STATUS,
    GET_TEAM_MEMBER_LIST,
    GET_GAME_LIST,
    UPDATE_TEAM_MEMBER_TYPE,
    QUITE_TEAM_MEMBER,
    DISBAND_TEAM,UPDATE_TEAM_LEADER,
    ADD_TEAM_MEMBER,
    GET_TEAM_BUSINESS,
    GET_USER_TEAM_LIST,
    UPDATE_TEAM_MEMBER_POSITION
} from './actionTypes';


//创建战队
export const createTeam = (params) => (createAsyncAction(CREATE_TEAM, () => (TeamRequest.createTeam(params))));
//获取战队信息
export const getTeamInfo = (id) => (createAsyncAction(GET_TEAM_INFO, () => (TeamRequest.getTeam(id))));
//获取战队列表
export const getTeamList = (params) => (createAsyncAction(GET_TEAM_LIST, () => (TeamRequest.getTeams(params))));
//修改战队信息
export const updateTeam = (params, id) => (createAsyncAction(UPDATE_TEAM, () => (TeamRequest.updateTeam(params, id))));
//获取所有游戏类型
export const getGameList = () => (createAsyncAction(GET_GAME_LIST, () => (TeamRequest.getGames())));
//解禁战队
export const updateTeamGameStatus = (id, params) => (createAsyncAction(UPDATE_TEAM_GAME_STATUS, () => (TeamRequest.updateTeamGameStatus(id, params))));
//获取战队成员列表
export const getTeamMemberList = (id, params) => (createAsyncAction(GET_TEAM_MEMBER_LIST, () => (TeamRequest.getTeamMembers(id, params))));
//添加战队成员
export const addTeamMember = (id, params) => (createAsyncAction(ADD_TEAM_MEMBER, () => (TeamRequest.addTeamMembers(id, params))));
//请出战队
export const quiteTeamMember = (teamId, memberId) => (createAsyncAction(QUITE_TEAM_MEMBER, () => (TeamRequest.deleteTeamMembers(teamId, memberId))));
//修改成员职务
export const updateTeamMemberType = (id, params) => (createAsyncAction(UPDATE_TEAM_MEMBER_TYPE, () => (TeamRequest.modifyMembersType(id, params))));
//修改战队队长
export const updateTeamLeader = (id) => (createAsyncAction(UPDATE_TEAM_LEADER, () => (TeamRequest.modifyTeamLeader(id))));
//解散战队
export const disbandTeam = (id) => (createAsyncAction(DISBAND_TEAM, () => (TeamRequest.disbandTeam(id))));
//获取战队的商业价值
export const queryTeamsBusiness = (id) => (createAsyncAction(GET_TEAM_BUSINESS, () => (TeamRequest.queryTeamsBusiness(id))));
//查看用户战队列表ID
export const getUserTeamList = (params) => (createAsyncAction(GET_USER_TEAM_LIST, () => (TeamRequest.getUserTeamList(params))));
//设置场上位置
export const updateTeamMemberPosition = (params) => (createAsyncAction(UPDATE_TEAM_MEMBER_POSITION, () => (TeamRequest.updateTeamMemberPosition(params))));
