import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, message, Modal, Row, Table,Upload} from "antd";
import {getOrderList, getOrderDetail, addPartnerOrder} from "../../modules/pratner-order/action";
import OrderFilter from "./OrderFilter";
import PartnerOrderModal from "./PartnerOrderModal";
import AddPartnerOrderModel from "./AddPartnerOrderModel";
// import { InboxOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import { ACCESS_TOKEN } from 'modules/authentication/constants';
import axios from 'axios'
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
const { Dragger } = Upload;
let getAuthorizationHeader = (headers) => {
    try {
        let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
        const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
        if (headers && accessToken) {
            headers.Authorization = accessToken.token;
        }
        return headers;
    } catch (error) {
        return '';
    }
};
const props = {
    name: 'excelFile',
    action: '/api/admin/partner-training/order/order/file',
    headers: getAuthorizationHeader({}),
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`导入成功`);
      } else if (info.file.status === 'error') {
        message.error(`导入失败`);
      }
    },
  };
class PartnerTrainingOrderManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            userId: "",
            userName: "",
            teacherId: "",
            teacherName: "",
            orderState: "",
            adminCreate: "",
            isShowDetailModal: false,
            orderId : "",
            partnerOrder: {},
            isShowAddPartnerOrderModel: false,
            isImportModalVisible:false
        };
    }

    componentWillMount() {
        const {getOrderList} = this.props;
        getOrderList();
    }

    //条件查找订单方法
    onFilterOrderEvent = async (params) => {
        let {getOrderList} = this.props;
        const {pageSize, pageNo} = this.state;
        this.setState({
            userId: params.userId,
            userName: params.userName,
            teacherId: params.teacherId,
            teacherName: params.teacherName,
            orderState: params.orderState,
            adminCreate: params.adminCreate
        });
        getOrderList(1, pageSize, params.userId, params.userName, params.teacherId, params.teacherName, params.orderState, params.adminCreate);
    };

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getOrderList} = this.props;
        let {userId, userName, teacherId, teacherName, orderState, adminCreate} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getOrderList(current, pageSize, userId, userName, teacherId, teacherName, orderState, adminCreate);
    }

    onShowModal = (id) =>{
        const {getOrderDetail} = this.props;

        getOrderDetail(id);
        this.setState({
            isShowDetailModal : true,
            orderId : id,
        })
    }

    handleCancel = e => {
        this.setState({
            isShowDetailModal: false,

        });
    };

    onClickEvent = e =>{
        let {getOrderList} = this.props;

        console.log(e)
        this.setState({
            isShowDetailModal: false,
        });
        getOrderList();
    }

    onSubmitEvent = e =>{
        let {getOrderList} = this.props;

        this.setState({
            isShowDetailModal: false,
        });
        getOrderList();
    }
    getProps = ()=>{
        let {getOrderList} = this.props;
        return {
            name: 'excelFile',
            action: '/api/admin/partner-training/order/order/file',
            headers: getAuthorizationHeader({}),
            onChange:(info)=>{
              if (info.file.status === 'done') {
                message.success(`导入成功`);
                getOrderList()
              } else if (info.file.status === 'error') {
                message.error(`导入失败`);
              }
            }
        }
    }
    onFormSubmit = async (values) => {
        const {isShowAddPartnerOrderModel, userId, userName, teacherId, teacherName, orderState, pageNo, pageSize, adminCreate} = this.state;
        const {addPartnerOrder, getOrderList} = this.props;
        await addPartnerOrder(values);
        getOrderList(pageNo, pageSize, userId, userName, teacherId, teacherName, orderState, adminCreate);
        message.success('新增成功');
        this.setState({isShowAddPartnerOrderModel: !isShowAddPartnerOrderModel});
    }
    onBtnClick = () => {
        this.setState({
            isShowAddPartnerOrderModel: true,
        });
    }

    exportOrder= ()=>{
        axios({ // 用axios发送post请求
            method: 'get',
            url: '/api//admin/partner-training/order/export/template', // 请求地址
            data: {}, // 参数
            responseType: 'arraybuffer', // 表明返回服务器返回的数据类型
            headers: getAuthorizationHeader({})
        }).then(r => {
            this.downFile(r.data,"订单")
        })
    }
    downFile = (res, type) => {
        console.log(res)
        var blob = new Blob([res], {type: 'application/ms-excel    application/x-excel;charset=utf-8'}); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = type+'.xlsx'; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
    };
    renderTableTitle = () => (
        <Row type="flex" justify="end">
            {
                whetherButtonPermission('PARTNER_TRAINING_ORDER_ADD_BUTTON',this.props.pageButtons)?<Col>
                <Button type="primary" onClick={() => this.onBtnClick()}>新增订单</Button>&nbsp;&nbsp;
            </Col>:null
            }
            {
                whetherButtonPermission('PARTNER_TRAINING_ORDER_IMPORT_BUTTON',this.props.pageButtons)?   <Col>
                <Button type="primary" onClick={() => this.setState({isImportModalVisible:!this.state.isImportModalVisible})}>导入订单</Button>
            </Col>:null
            }
            
         
            &nbsp;&nbsp;
            <Col>
                <Button type="primary" onClick={() => this.exportOrder()}>导出订单模板</Button>
            </Col>
        </Row>
    )

    render() {

        const {
            partnerOrders, loading
        } = this.props;
        const {
            pageNo, pageSize, isShowAddPartnerOrderModel,isImportModalVisible
        } = this.state;

        console.log(partnerOrders)
        return (
            <div>

                <Card style={{marginBottom: 30}}>
                    <OrderFilter dataSource={{}} onCommit={this.onFilterOrderEvent}/>
                </Card>
                <Card>
                    <Table
                        title={this.renderTableTitle}
                        columns={
                            [
                                {
                                    title: '陪练订单ID',
                                    dataIndex: 'orderId',
                                    key: 'orderId',
                                    align: 'center',
                                },
                                {
                                    title: '订单金额(浪币)',
                                    dataIndex: 'rancherNumber',
                                    key: 'rancherNumber',
                                    align: 'center',
                                },
                                {
                                    title: '学员展示ID',
                                    dataIndex: 'userId',
                                    key: 'userId',
                                    align: 'center',
                                    render(index, data) {
                                        return data.userDetail.exhibitionId;
                                    }
                                },
                                {
                                    title: '学员名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                    render(index, data) {
                                        return data.userDetail.username;
                                    }
                                },
                                {
                                    title: '导师展示ID',
                                    dataIndex: 'teacherExhibitionId',
                                    key: 'teacherExhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '导师名',
                                    dataIndex: 'teacherUsername',
                                    key: 'teacherUsername',
                                    align: 'center',
                                },
                                {
                                    title: '游戏名称',
                                    dataIndex: 'game',
                                    key: 'game',
                                    align: 'center',
                                    render(index, data) {
                                        if (data.game == "HPJY"){
                                            return "和平精英"
                                        }
                                        if (data.game == "CYHX"){
                                            return "穿越火线手游"
                                        }
                                        if (data.game == "WZRY"){
                                            return "王者荣耀"
                                        }
                                        if (data.game == "QQFC"){
                                            return "QQ飞车手游"
                                        }
                                        if (data.game == "YXLM"){
                                            return "英雄联盟手游"
                                        }
                                        if (data.game == "SMZH"){
                                            return "使命召唤手游"
                                        }
                                    }
                                },
                                {
                                    title: '订单状态',
                                    dataIndex: 'orderState',
                                    key: 'orderState',
                                    align: 'center',
                                    render(index, data) {
                                        if (data.orderState == "RELEASE_SIGN_UP") {
                                            return  "发布报名"
                                        }
                                        
                                        if (data.orderState == "TEACHER_ALREADY_SIGN_UP") {
                                            return  "导师已报名"
                                        }
                                        if (data.orderState == "TEACHING") {
                                            return  "教学进行中"
                                        }
                                        if (data.orderState == "WAIT_TO_ACCOUNT") {
                                            return  "等待到账"
                                        }
                                        if (data.orderState == "COMPLETE_TEACHER") {
                                            return  "完成教学"
                                        }
                                        if (data.orderState == "RELEASE_TIMEOUT") {
                                            return  "报名超时"
                                        }
                                        if (data.orderState == "CHOOSE_TEACHER_TIMEOUT") {
                                            return "超时未选择导师"
                                        }
                                        if (data.orderState == "UNFINISHED_TEACHER") {
                                            return  "未完成教学"
                                        }
                                        if (data.orderState == "NO_CHOOSE_COMPLETE") {
                                            return  "导师超时未选择完成教学"
                                        }
                                        if (data.orderState == "FIRST_COMPLAINT") {
                                            return  "申诉退款待导师处理"
                                        }
                                        if (data.orderState == "TEACHER_AGREE_REFUND") {
                                            return "导师同意退款"
                                        }
                                        if (data.orderState == "TEACHER_TIMEOUT_REFUND") {
                                            return  "导师超时未处理申诉"
                                        }
                                        if (data.orderState == "SECOND_COMPLAINT") {
                                            return  "客服处理申诉"
                                        }
                                        if (data.orderState == "SERVICE_AGREE_COMPLAINT") {
                                            return  "客服同意申诉"
                                        }
                                        if (data.orderState == "SERVICE_REFUSED_COMPLAINT") {
                                            return  "客服拒绝申诉"
                                        }
                                    }
                                },
                                {
                                    title: '订单创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '订单修改时间',
                                    dataIndex: 'updateTime',
                                    key: 'updateTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, record) => {
                                        return (
                                            <div>
                                                <Button type="primary"
                                                        style={{marginLeft: 10, marginBottom: 10}}
                                                        onClick={() => this.onShowModal(record.orderId)}>详情</Button>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    title: '操作者',
                                    dataIndex: 'dealPersonName',
                                    key: 'dealPersonName',
                                    align: 'center',
                                },
                            ]
                        }

                        title={
                            this.renderTableTitle
                        }
                        dataSource={partnerOrders ? partnerOrders.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: partnerOrders ? partnerOrders.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>

                <Modal
                    title="订单详情"
                    visible={this.state.isShowDetailModal}
                    onOk={this.handleOk}
                    footer={null}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    width={900}
                >
                    <PartnerOrderModal {...this.state} {...this.props} onSubmitEvent={this.onClickEvent} onCommitEvent={this.onSubmitEvent} />
                </Modal>

                {isShowAddPartnerOrderModel ? <AddPartnerOrderModel
                    onSuccess={this.onFormSubmit}
                    visible={isShowAddPartnerOrderModel}
                    onClose={() => {
                        this.setState({
                            isShowAddPartnerOrderModel: false,
                        });
                    }}
                /> : null}
                     {isImportModalVisible?<Modal title="导入订单" visible={isImportModalVisible} onOk={()=>this.setState({isImportModalVisible:!isImportModalVisible})} onCancel={()=>this.setState({isImportModalVisible:!isImportModalVisible})}>
                     <Dragger {...this.getProps()}>
                        <p className="ant-upload-text">点击导入订单</p>
                    </Dragger>
                    </Modal>:null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state.PartnerOrder)

    return {
        ...state.PartnerOrder,
        ...state.authentication
    }
};

const mapDispatchToProps = ({
    getOrderList,
    getOrderDetail,
    addPartnerOrder
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(PartnerTrainingOrderManagement));
