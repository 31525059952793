import createReducer from 'utils/createReducer';

const defaultState = () => ({
  token: '',
  refreshToken: '',
  user: {},
  viewPermissionsTree: [],
  pageButtons: [],//所有页面的按钮
});

const onCreateAccessToken = (state, action) => ({
  ...state,
  token: action.payload.token,
  refreshToken: action.payload.refreshToken,
});

const onGetAuthenticationInfo = (state, { payload }) => ({
  ...state,
  user: payload,
});


const onGetUserViewPermissionsTree = (state, { payload }) => ({
  ...state,
  viewPermissionsTree: payload,
});

const onLogin = (state, {
  payload: {
    token, refreshToken, user, viewPermissionsTree,
  },
}) => ({
  ...state,
  token,
  refreshToken,
  user,
  viewPermissionsTree,
});
const onSetPageButtons = (state, {
  payload: {
    pageButtons,
  },
}) => ({
  ...state,
  pageButtons,
});
const onLogout = (state) => ({
  ...state,
  token: '',
  refreshToken: '',
  user: {},
  viewPermissionsTree: [],
});


export default createReducer(defaultState, {
  CREATE_ACCESS_TOKEN_SUCCESS: onCreateAccessToken,
  GET_AUTHENTICATION_INFO_SUCCESS: onGetAuthenticationInfo,
  GET_ADMIN_VIEW_PERMISSIONS_TREE_SUCCESS: onGetUserViewPermissionsTree,
  LOGIN: onLogin,
  SET_PAGE_BUTTONS: onSetPageButtons,
  LOGOUT: onLogout,
});
