import API from "../../request/AdminOperationLogRequest";
import {GET_ADMIN_OPERTION_LOGS} from "./actionTypes";
import createAsyncAction from 'utils/createAsyncAction';

 const getAdminOperationLogs = (adminName, userName,userExhibitionId, type, startDate, endDate, page, size) => (
    createAsyncAction(GET_ADMIN_OPERTION_LOGS,() =>
        (API.getAdminOperationLogs(adminName, userName,userExhibitionId, type, startDate, endDate, page, size)))
);

 export {
     getAdminOperationLogs
 }
