import createAsyncAction from 'utils/createAsyncAction';
import CommemorativeCardRequest from 'request/CommemorativeCardRequest';
import {
    GET_COMMEMORATIVE_CARD_LIST,
    CREATE_COMMEMORATIVE_CARD,
    DELETE_COMMEMORATIVE_CARD,
    GET_THEME_SETTING_LIST,
    UPDATE_COMMEMORATIVE_CARD,
    UPDATE_THEME_SETTING,
    GET_CARD_PIC_LIST,
    UPDATE_CARD_PIC_LIST,
    DELETE_THEMESETTING,
    ONANDOFF_THESHELFTHEME
} from './actionTypes';

const getCardList = (params) => (
    createAsyncAction(GET_COMMEMORATIVE_CARD_LIST, () => (CommemorativeCardRequest.getCardList(params)))
);

const createCard = (params) => (
    createAsyncAction(CREATE_COMMEMORATIVE_CARD, () => (CommemorativeCardRequest.createCard(params)))
);

const updateThemeSetting = (themeId,params) => (
    createAsyncAction(UPDATE_THEME_SETTING, () => (CommemorativeCardRequest.updateThemeSetting(themeId,params)))
);

const updateCommemorativeCard = (themeId, params) => (
    createAsyncAction(UPDATE_COMMEMORATIVE_CARD, () => (CommemorativeCardRequest.updateCommemorativeCard(themeId, params)))
);

const deleteCard = (themeId) => (
    createAsyncAction(DELETE_COMMEMORATIVE_CARD, () => (CommemorativeCardRequest.deleteCard(themeId)))
);

const getThemeSettingList = (themeId) => (
    createAsyncAction(GET_THEME_SETTING_LIST, () => (CommemorativeCardRequest.getThemeSettingList(themeId)))
);

const getCardPicList = (themeId) => (
    createAsyncAction(GET_CARD_PIC_LIST, () => (CommemorativeCardRequest.getCardPicList(themeId)))
);

const onUpdateCardPic = (themeId,params) => (
    createAsyncAction(UPDATE_CARD_PIC_LIST, () => (CommemorativeCardRequest.onUpdateCardPic(themeId,params)))
);

const deleteThemeSetting = (themeSettingId) => (
    createAsyncAction(DELETE_THEMESETTING, () => (CommemorativeCardRequest.deleteThemeSetting(themeSettingId)))
);

const onAndOffTheShelfTheme = (themeId,listingStatus) => (
    createAsyncAction(ONANDOFF_THESHELFTHEME, () => (CommemorativeCardRequest.onAndOffTheShelfTheme(themeId,listingStatus)))
);



export {
    getCardList, createCard, updateThemeSetting, updateCommemorativeCard, deleteCard, getThemeSettingList, getCardPicList, onUpdateCardPic, deleteThemeSetting, onAndOffTheShelfTheme
};
