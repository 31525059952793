import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, notification, Row, Table, Tag} from 'antd';
import {connect} from 'react-redux';
import {getStatisticalWithdrawals, getWithdraws, optWithdraw} from '../../modules/withdraw/action';
import {withRouter} from 'react-router-dom';
import {optType} from "../../modules/withdraw/withdrawEnums";
import WithdrawFilter from "./WithdrawFilter";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';
import {map} from 'lodash/fp';

class WithdrawManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            id: null,
            state: '',
            startTime: null,
            endTime: null
        };
    }

    componentDidMount() {
        this.onGetData()
    }


    //翻页
    onPageChangeEvent = (current, page) => {
        const {getWithdraws} = this.props;
        const {id, state, startTime, endTime} = this.state;
        this.setState({
            page: current,
            size: page,
        }, () => {
            getWithdraws(current, page, id, state, startTime, endTime);
            const {getStatisticalWithdrawals} = this.props;
            getStatisticalWithdrawals({});
        });
    }


    onOptWithdrawOrderEvent = async (id, type) => {
        const {optWithdraw} = this.props;
        if (type === optType.AGREE) {
            await optWithdraw(id, {cashOutApprovalType: optType.AGREE});
            notification.success({message: '操作成功'});
            this.onGetData()
        } else if (type === optType.REJECT) {
            await optWithdraw(id, {cashOutApprovalType: optType.REJECT});
            notification.success({message: '操作成功'});
            this.onGetData()
        }
    }

    renderState = (text) => {
        if (text === 'INIT') {
            return (
                <div color="blue">待提现</div>
            )
        } else if (text === 'CLOSE') {
            return (
                <div color="blue">已拒绝</div>
            )
        } else if (text === 'COMPLETE') {
            return (
                <div color="blue">已提现</div>
            )
        }
    }

    onGetData = () => {
        const {id, page, size, state, startTime, endTime} = this.state;
        const {getWithdraws} = this.props;
        getWithdraws(page, size, id, state, startTime, endTime);
        const {getStatisticalWithdrawals} = this.props;
        getStatisticalWithdrawals({});
    }

    //条件查找方法
    onFilterWithdrawEvent = async ({id, state, withdrawTime}) => {
        console.log(id)
        console.log(state)
        console.log(withdrawTime)
        const {getWithdraws} = this.props;
        let startTime, endTime = null;
        if (withdrawTime && withdrawTime.length > 0) {
            let dateRange = map(date => date.format('YYYY-MM-DD'))(withdrawTime);
            startTime = dateRange[0] ? dateRange[0] + " 00:00:00" : '';
            endTime = dateRange[1] ? dateRange[1] + " 23:59:59" : '';
            console.log(startTime)
            console.log(endTime)
        }
        this.setState({
            id: id,
            state: state,
            startTime: startTime,
            endTime: endTime,
            page: 1,
            size: 10,
        }, () => {
            getWithdraws(1, 10, id, state, startTime, endTime);
            const {getStatisticalWithdrawals} = this.props;
            getStatisticalWithdrawals({});
        });
    }

    //操作column初始化
    renderOpt = (id, state) => {
        const {pageButtons} = this.props;
        if (state === 'INIT') {
            return (
                <div>
                    {
                        whetherButtonPermission(buttonType.AGREE_CASH_OUT.value, pageButtons) &&
                        <Button type="primary" style={{marginLeft: "10px"}}
                                onClick={() => this.onOptWithdrawOrderEvent(id, optType.AGREE)}>{buttonType.AGREE_CASH_OUT.label}</Button>
                    }
                    {
                        whetherButtonPermission(buttonType.REJECT_CASH_OUT.value, pageButtons) &&
                        <Button type="danger" style={{marginLeft: "10px"}}
                                onClick={() => this.onOptWithdrawOrderEvent(id, optType.REJECT)}>{buttonType.REJECT_CASH_OUT.label}</Button>
                    }
                </div>

            )
        } else {
            return (
                <div>--</div>
            )
        }
    }

    render() {
        const {
            withdraws, loading, withdrawsStatistical
        } = this.props;
        const {
            page, size
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <WithdrawFilter onCommit={this.onFilterWithdrawEvent}/>
                    <div style={{marginTop: 30}}>
                        <Tag>当前已提现总额：{withdrawsStatistical && withdrawsStatistical.haveWithdrawalAmount ? withdrawsStatistical.haveWithdrawalAmount : 0.00}元</Tag>
                        <Tag>当前未提现总额：{withdrawsStatistical && withdrawsStatistical.noWithdrawalAmount ? withdrawsStatistical.noWithdrawalAmount : 0.00}元</Tag>
                    </div>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '提现订单ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '支付宝提现账户',
                                    dataIndex: 'withdrawalsAccount',
                                    key: 'withdrawalsAccount',
                                    align: 'center',
                                },
                                {
                                    title: '支付宝提现账户名',
                                    dataIndex: 'withdrawalsName',
                                    key: 'withdrawalsName',
                                    align: 'center',
                                },
                                {
                                    title: '提现到账金额（元）',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'center',
                                },
                                {
                                    title: '实际扣除金额（元）',
                                    dataIndex: 'actualAmount',
                                    key: 'actualAmount',
                                    align: 'center',
                                },
                                {
                                    title: '提现状态',
                                    dataIndex: 'state',
                                    key: 'state',
                                    align: 'center',
                                    render: (text, {state}) => (
                                        <div>
                                            {this.renderState(state)}
                                        </div>
                                    )
                                },
                                {
                                    title: '收入来源',
                                    dataIndex: 'incomeSource',
                                    key: 'incomeSource',
                                    align: 'center',
                                },
                                {
                                    title: '提现时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, {id, state}) => (
                                        <div>
                                            {
                                                this.renderOpt(id, state)
                                            }
                                        </div>
                                    ),
                                },
                            ]
                        }
                        dataSource={withdraws.data ? withdraws.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: withdraws ? withdraws.total : 0,
                            current: page,
                            size: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        );
    }

}

const mapStateToProps = (state => {
        return {
            ...state.withdraw,
            pageButtons: state.authentication.pageButtons
        }
    }
);

const mapDispatchToProps = {
    getWithdraws, optWithdraw, getStatisticalWithdrawals
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(WithdrawManagement)));
