import React from 'react';
import {Button,Form, Input, Modal,DatePicker,Select, notification,Image ,Icon} from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";

const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    data: {},
    visible: false,
    onClose: () => {},
};

class LiveEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    onSubmit = () => {
        let { data } = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const diff1 = moment(values.endTime).diff(moment(values.startTime))
                
                if(diff1 < 0){
                    notification.error({message:'结束时间不能早于开始时间，请重新选择时间'})
                    return;
                }

                let val = {
                    id: data.id,
                    ...values,
                    startTime: moment(values.startTime).format(dateFormat),
                    endTime: moment(values.endTime).format(dateFormat)
                };

               onSuccess && onSuccess(val);
            }
        });
    }

    componentDidMount() {
        
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let { visible, type, data, gameList} = this.props;
        
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title={type === 'ADD' ? '添加直播' : '编辑直播'}
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form>
                    <FormItem label="直播开始时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('startTime', {
                            rules: [{required: true, message: '请选择直播开始时间'}],
                            initialValue: type !== 'ADD' ? moment(data.startTime, dateFormat) : moment()
                        })(
                            <DatePicker showTime={{format: 'HH:mm'}} format={dateFormat} />
                        )}
                    </FormItem>
                    <FormItem label="直播结束时间"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('endTime', {
                            rules: [{required: true, message: '请选择直播结束时间'}],
                            initialValue: type !== 'ADD' ? moment(data.endTime, dateFormat) : moment()
                        })(
                            <DatePicker showTime={{format: 'HH:mm'}} format={dateFormat} />
                        )}
                    </FormItem>
                    <FormItem label="游戏分部:"
                        {...formItemLayout}
                    >
                         {getFieldDecorator('gamingDivision', {
                            initialValue: type !== 'ADD' ? data.gamingDivision : '',
                            rules: [{required: true, message: '请选择游戏类型'}],
                        })(
                            <Select style={{width: 150}}>
                                {
                                    gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="直播嵌入链接"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('liveUrl', {
                            rules: [{required: true, message: '请输入直播推流地址'}],
                            initialValue: type !== 'ADD' ? data.liveUrl : ''
                        })(
                            <Input placeholder="rtmp地址"/>
                        )}
                    </FormItem>
                    <FormItem label="是否会员可看"
                        {...formItemLayout}
                    >
                         {getFieldDecorator('isVipWatch', {
                            initialValue: type !== 'ADD' ? data.isVipWatch : true,
                            rules: [{required: true, message: '请选择是否会员可看'}],
                        })(
                            <Select style={{width: 120}}>
                                <Option value={true} >是</Option>
                                <Option value={false} >否</Option>
                            </Select>
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
LiveEditForm.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(LiveEditForm)));
