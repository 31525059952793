import React from 'react';
import {Button, Card, Col, message, notification, Popconfirm, Row, Table, Tabs} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from '../../modules/qrcode/enums';
import {deleteQrcode, getQrcodeList,addQrcode} from "../../modules/qrcode/action";
import QrcodeInfoModel from "./components/QrcodeInfoModel";

const {TabPane} = Tabs;

class QrCodeManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowQrcodeInfoModel: false,
            seletionValue: null,
            systemType: 'ANDROID',
            page: 1,
            size: 5,
        };
    }

    componentWillMount() {
        this.onGetQrcodeData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.qrcodes !== this.props.qrcodes) {
            this.setState({
                loading: false
            })
        }
    }

    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreateQrcodeEvent(values);
                break;
            default:
        }
    }

    // 新增战队信息
    onCreateQrcodeEvent = async (params) => {
        const {isShowQrcodeInfoModel} = this.state;
        const {addQrcode} = this.props;
        await addQrcode(params);
        await this.onGetQrcodeData();
        message.success('新增成功');
        this.setState({isShowQrcodeInfoModel: !isShowQrcodeInfoModel});
    }

    //刷新界面数据
    onGetQrcodeData = async () => {
        const {getQrcodeList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        await getQrcodeList({
            page, size,
            systemType: this.state.systemType,
        });
    }

    onPageChangeEvent = async (current, pageSize) => {
        const {getQrcodeList} = this.props;
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        })
        await getQrcodeList({
            page: current, size: pageSize,
            systemType: this.state.systemType,
        });
    }

    changeTabPane = async (key) => {
        console.log(key)
        const {size} = this.state;
        const {getQrcodeList} = this.props;
        this.setState({
            loading: true,
            systemType: key ? key : null,
            page: 1,
        })
        await getQrcodeList({page: 1, size, systemType: key});
    }


    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowQrcodeInfoModel: true,
            seletionValue: seletionValue,
        });
    }


    //删除赛事信息的方法
    onDeleteEvent = async (id) => {
        const {deleteQrcode} = this.props;
        try {
            await deleteQrcode(id);
            notification.success({message: '删除成功'});
            this.onGetQrcodeData();
        } catch (e) {
            console.log(e);
        }
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE)}>新增</Button>
            </Col>
        </Row>
    )

    renderTabPane = () => {
        const {
            qrcodes,
        } = this.props;
        console.log(qrcodes)
        const {
            isShowQrcodeInfoModel, page, size, loading,
        } = this.state;
        return (
            <div>
                <Card style={{width: 'auto'}}>
                    <Table
                        dataSource={qrcodes ? qrcodes.data : []}
                        title={this.renderTableTitle}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: qrcodes.total ? qrcodes.total : 0,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowQrcodeInfoModel ? <QrcodeInfoModel
                        onSuccess={this.onFormSubmit}
                        visible={isShowQrcodeInfoModel}
                        systemType={this.state.systemType}
                        onClose={() => {
                            this.setState({
                                isShowQrcodeInfoModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Tabs onChange={this.changeTabPane} type="card">
                    <TabPane tab="ANDROID" key='ANDROID'>
                        {this.state.systemType === 'ANDROID' ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="IOS" key='IOS'>
                        {this.state.systemType === 'IOS' ? this.renderTabPane() : null}
                    </TabPane>
                </Tabs>
            </div>
        );
    }

    getTableColumns = () => [{
        title: 'app下载路径',
        dataIndex: 'url',
        key: 'url',
        align: 'center',
    }, {
        title: '版本类型',
        dataIndex: 'appVersionType',
        align: 'center',
        key: 'appVersionType',
    },
        {
            title: '版本号',
            dataIndex: 'appVersionNum',
            align: 'center',
            key: 'appVersionNum',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            width: 200,
            // fixed: 'right',
            align: 'center',
            render: (row, text) => (
                <div>
                    <Popconfirm title={`确定要删除这个版本下载路径吗?`}
                                onConfirm={() => this.onDeleteEvent(text.id)}
                                okText="确认" onCancel={this.onEvent} cancelText="取消"
                                style={{backgroundColor: '#ff0000', color: '#fff'}}>
                        <Button type="danger" disabled={text.appVersionType === 'NEW' ? true : false}>删除</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.Qrcode,
    }
};

const mapDispatchToProps = ({
    getQrcodeList,
    deleteQrcode,
    addQrcode
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(QrCodeManagement));
