import React from 'react';
import {
    Button,
    Card,
    Col,
    InputNumber,
    message,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Row,
    Table,
    Tabs,
    Tag
} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getSchedule,getScheduleDetail,getPredictionDetail,editSchedule,editPrediction,editPredictionResult,addPrediction,addSchedule,delSchedule,recommendSchedule} from "../../modules/schedule/action";
import {getGame} from "../../modules/game/action";

import ScheduleModal from "./components/ScheduleModal";
import ScheduleFilter from "./components/ScheduleFilter";
import PredictionModal from "./components/PredictionModal";
import PredictionResultModal from "./components/PredictionResultModal";

const {confirm} = Modal;


/**
 * 行程列表
*/
class schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowScheduleModel:false,//新增行程窗口
      isShowPredictionModal:false,
      isShowPredictionResultModal:false,
      predictionModelType:'ADD',
      modelType:'',//展示行程弹窗的类型（VIEW:查看,EDIT:编辑,ADD:新增）
      modelData:{},
      pageNo:1,
      pageSize: 10,
      params:{
        gamingDivision:'',
        date:'',
      },
      id:''

    };
  }

  componentWillMount() {
    this.props.getGame()
    this.onGetData()
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  //条件查找
  onFilterScheduleEvent = ( params) => {
    let {getSchedule} = this.props;
    this.setState({
        pageNo: 1,
        pageSize: 10,
        params:{...params}
    }, () => {
      getSchedule({page: 1, size: 10, ...params})
    })
  }

  onGetData = async () => {
      const {pageNo, pageSize,params} = this.state;
      let {getSchedule} = this.props;
      console.log("state", this.state)
      await getSchedule({page:pageNo, size:pageSize,...params});
  }

  //转换成游戏名字
  changeGameName = (v)=>{
    let {gameList} = this.props;
    let gameName;
    for(let i = 0;i < gameList.length;i++){
      if(gameList[i].code===v){
        gameName = gameList[i].gameName;
        break
      }
    }
    return gameName;
  }

  //查看行程详情
  viewSchedule = async(id) =>{
    let {getScheduleDetail} = this.props;
   await getScheduleDetail(id)
   this.setState({isShowScheduleModel: true,modelType:'EDIT',id})
  }

    //查看预测详情
    viewPrediction = async(id) =>{
        let {getPredictionDetail} = this.props;
        await getPredictionDetail(id)
        this.setState({isShowPredictionModal: true,predictionModelType:'EDIT',id})
    }

    //查看预测详情
    viewPredictionResult = async(id) =>{
        let {getPredictionDetail} = this.props;
        await getPredictionDetail(id)
        this.setState({isShowPredictionResultModal: true,id})
    }

  //编辑行程
  editSchedule = async(params) =>{
    let {editSchedule} = this.props;
    let {id} = this.state;
    await editSchedule({id,...params})
    message.success('编辑成功');
    this.onGetData()
  }

  //新增行程
  addSchedule = async(params) =>{
    let {addSchedule} = this.props;
    await addSchedule(params)
    message.success('新增成功');
    this.onGetData()
  }

    //编辑预测
    editPrediction = async(params) =>{
        let {editPrediction} = this.props;
        let {id} = this.state;
        await editPrediction({id,...params})
        message.success('编辑成功');
        this.onGetData()
    }

    //
    editPredictionResult = async(params) =>{
        let {editPredictionResult} = this.props;
        let {id} = this.state;
        let param = {guessId:id,guessItemId:params.guessItemId}
        await editPredictionResult({...param})
        message.success('设置成功');
        this.onGetData()
    }

    //新增预测
    addPrediction = async(params) =>{
        let {addPrediction} = this.props;
        console.log('ssssssssssssss'+JSON.stringify(params));
        await addPrediction(params)
        message.success('新增成功');
        this.onGetData()
    }


    //推荐到首页弹窗
  confirmToIndex = (params) => {
    let _this = this;
    confirm({
      content: params.isRecommend?'确认要推荐到首页吗?':'要取消推荐到首页吗？',
      okText: '确认',
      cancelText: '关闭',
      onOk() {
        _this.toIndex(params)
      },
      onCancel() {
      },

    });
  }
  //推荐到首页
  toIndex = async (params) => {
    let{recommendSchedule} = this.props;
    await recommendSchedule(params);
    if(params.isRecommend){
      message.success('推荐成功');
    }else{
      message.success('取消成功');
    }
    this.onGetData();
  }

  //删除行程弹窗
  confirmDel = (id) => {
    let _this = this;
    confirm({
      content: '  确认要删除这次的行程吗·?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.delSchedule(id)
      },
      onCancel() {
      },

    });
  }
  //删除行程
  delSchedule = async (id) => {
    let{delSchedule,getSchedule} = this.props;
    const {pageNo, pageSize} = this.state;
    await delSchedule(id)
    message.success('删除成功');
    this.onGetData();

  }


  //操作行程详情页面
  handleSchedule  = async(v,type)=>{
    this.setState({isShowScheduleModel:false})
    if(type==='EDIT'){
      this.editSchedule({...v})
    }else if(type==='ADD'){
      this.addSchedule(v)
    }
  }

    handlePrediction = async(v,type)=>{
        this.setState({isShowPredictionModal:false})
        if(type==='EDIT'){
            this.editPrediction({...v})
        }else if(type==='ADD'){
            let {id} = this.state;
            v.matchActivityId = id;
            this.addPrediction(v)
        }
    }

    handlePredictionResult = async(v,type)=>{
        this.setState({isShowPredictionResultModal:false})
        this.editPredictionResult({...v})
    }

  renderTableTitle = () => (
    <Row type="flex" justify="end">
        <Col>
            <Button type="primary" onClick={() => this.setState({isShowScheduleModel: true,modelType:'ADD'})}>新增</Button>
        </Col>
    </Row>
  )


  render() {
    let{scheduleList,gameList,scheduleDetail,predictionDetail} = this.props;
    let{isShowScheduleModel,isShowPredictionModal,isShowPredictionResultModal,pageNo,pageSize,modelType,id,predictionModelType} = this.state;
    console.log(scheduleList,'scheduleList')
    return (
      <div>
        <Card style={{marginBottom: 30}}>
          <ScheduleFilter onCommit={this.onFilterScheduleEvent} gameList={gameList}/>
        </Card>
        <Card>
            <Table
                columns={
                    [
                        {
                            title: '行程ID',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                        },
                        {
                            title: '行程开始时间',
                            dataIndex: 'startTime',
                            key: 'startTime',
                            align: 'center',
                        },
                        {
                            title: '行程结束时间',
                            dataIndex: 'endTime',
                            key: 'endTime',
                            align: 'center',
                        },
                        {
                            title: '行程标题',
                            dataIndex: 'activityName',
                            key: 'activityName',
                            align: 'center',
                        },
                        {
                            title: '游戏类型',
                            dataIndex: 'gamingDivision',
                            key: 'gamingDivision',
                            align: 'center',
                            render: (row, text) => (
                                <span>{this.changeGameName(text.gamingDivision)}</span>
                            )
                        },
                        {
                            title: '是否需要报名',
                            dataIndex: 'needSignUp',
                            key: 'needSignUp',
                            align: 'center',
                            render: (row, text) => (
                                <span>{text.needSignUp?'是':'否'}</span>
                            )
                          },
                        {
                            title: '创建时间',
                            dataIndex: 'createTime',
                            key: 'createTime',
                            align: 'center',
                        },
                        {
                            title: '操作',
                            dataIndex: 'operate',
                            key: 'operate',
                            align: 'center',
                            render: (row, text) => (
                                <div>
                                     <Button type={"primary" }style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                      this.setState({isShowPredictionModal: true,predictionModelType:'ADD',id:text.id})
                                    }} color="blue">添加预测</Button>
                                    <Button type={"primary" }style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        if(!text.guessId) {
                                            notification.error({
                                                message: '提示',
                                                description: '请先添加赛事预测！',
                                            });
                                            return;
                                        }
                                       this.viewPrediction(text.guessId);
                                    }} color="blue">预测管理</Button>
                                    <Button type={"primary" }style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        if(!text.guessId) {
                                            notification.error({
                                                message: '提示',
                                                description: '请先添加赛事预测！',
                                            });
                                            return;
                                        }
                                        this.viewPredictionResult(text.guessId);
                                    }} color="blue">提交预测结果</Button>
                                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                      this.viewSchedule(text.id)
                                    }} color="blue">编辑</Button>
                                    <Button type={text.isRecommend?"dashed":"primary"} style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        this.confirmToIndex({isRecommend:!text.isRecommend, scheduleId:text.id})
                                    }} color="blue">{text.isRecommend?'已置顶':'置顶'}</Button>
                                    <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                        this.confirmDel(text.id)
                                    }} color="red">删除</Button>
                                </div>

                            ),
                        },
                    ]
                }

                title={this.renderTableTitle}
                dataSource={scheduleList ? scheduleList.data : []}
                bordered
                //loading={loading}
                pagination={{
                    total: scheduleList ? scheduleList.total : 0,
                    current: pageNo,
                    pageSize: pageSize,
                    onChange: this.onPageChangeEvent,
                    showTotal:(total)=>{
                        return `总计 ${total}条数据`;
                    }
                }}
            />

            {isShowScheduleModel && <ScheduleModal
                onSuccess={this.handleSchedule}
                visible={isShowScheduleModel}
                type={modelType}
                data={scheduleDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                      isShowScheduleModel: false,
                    });
                }}
                />}
            {isShowPredictionModal && <PredictionModal
                onSuccess={this.handlePrediction}
                visible={isShowPredictionModal}
                type={predictionModelType}
                data={predictionModelType == 'EDIT' ? predictionDetail : null}
                // data={predictionDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                        isShowPredictionModal: false,
                    });
                }}
            />}
            {isShowPredictionResultModal && <PredictionResultModal
                onSuccess={this.handlePredictionResult}
                visible={isShowPredictionResultModal}
                type={predictionModelType}
                data={predictionDetail}
                gameList={gameList}
                id={id}
                changeGameName={this.changeGameName}
                onClose={() => {
                    this.setState({
                        isShowPredictionResultModal: false,
                    });
                }}
            />}


        </Card>
      </div>
    );
  }

//翻页
onPageChangeEvent = (current, pageSize) => {
  const {getSchedule} = this.props;
  const { params} = this.state;
  this.setState({
      pageNo: current,
      pageSize: pageSize,
  }, () => {
    getSchedule({page: current, size: pageSize, ...params})
  });
}

}

const mapStateToProps = (state) => {
  return {
    ...state.viewSchedule,
    ...state.viewGame
  }
};

const mapDispatchToProps = ({
  getSchedule,
  getScheduleDetail,
  getPredictionDetail,
  editSchedule,
  editPrediction  ,
  editPredictionResult,
  addSchedule,
  addPrediction,
  delSchedule,
  recommendSchedule,
  getGame,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(schedule));
