import React from 'react';
import {Form, Icon, Input, Modal, Col, Row, Select, InputNumber} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import Button from "antd/es/button/button";

const FormItem = Form.Item;
const {Option} = Select;
const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 20, offset: 4},
    },
};

const defaultProps = {
    visible: false,
    onClose: () => {
    },
};

class LotteryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sensitivity: null,
            name: null,
            imageUrl: null

        };
    }

    componentWillMount() {
        const { themeSettingList } = this.props;
        this.setState({
            sensitivity: themeSettingList,
        })
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            let param = [];
            if (!err) {
                const {onSuccess} = this.props;
                Object.keys(values).map(item => {
                    if (values[item]) {
                        param.push(values[item]);
                    }
                    return null;
                });
                onSuccess && onSuccess(param);
            }
        });
    }

    onChangeParamsValue = (index,key, value) => {
        const {sensitivity} = this.state;
        sensitivity[index][key] = parseInt(value)
        this.setState({
            sensitivity: sensitivity
        })
    }

    add = () => {
        const {sensitivity} = this.state;
        sensitivity.push({value: '', params: []})
        this.setState({
            sensitivity: sensitivity
        })

    };

    render() {
        const {visible,themeSettingList} = this.props;
        const {getFieldDecorator} = this.props.form;
        const keys = themeSettingList;
        const formItems = keys.map((k, index) => {
            return (<div>
                <FormItem>
                    <div>
                    <Row>
                        <Col span={4}>
                            <FormItem
                                label=""
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                    {getFieldDecorator(`${index}.requiredPeople`, {
                                    rules: [{required: true, message: '请输入集齐人数', pattern: /^\S+$/}],
                                    initialValue: k.requiredPeople,
                                })(
                                    <Input
                                        disabled={k.rewardUserIds?true:false}
                                        type='number'
                                        onChange={(e) => this.onChangeParamsValue(index, "requiredPeople", e.currentTarget.value)}
                                        placeholder="集齐人数"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem
                                label=""
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator(`${index}.giftQuantity`, {
                                    rules: [{required: true, message: '请输入获奖人数', pattern: /^\S+$/}],
                                    initialValue: k.giftQuantity,
                                })(
                                    <Input 
                                        disabled={k.rewardUserIds?true:false}
                                        type='number'
                                        
                                        onChange={(e) => this.onChangeParamsValue(index, "giftQuantity", e.currentTarget.value)}
                                        placeholder="获奖人数"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem
                                label=""
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator(`${index}.level`, {
                                    rules: [{required: true, message: '请输入正确抽奖等级(1-10)', pattern: /^(?:0|[1-9]?|10)$/}],                                                    
                                    initialValue: k.level,
                                })(
                                    <Input
                                        disabled={k.rewardUserIds?true:false}
                                        type='number'
                                        onChange={(e) => this.onChangeParamsValue(index, "level", e.currentTarget.value)}
                                        placeholder="抽奖等级"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem
                                label=""
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator(`${index}.giftId`, {
                                    rules: [{required: true, message: '请输入礼品ID', pattern: /^\S+$/}],
                                    initialValue: k.giftId,
                                })(
                                    <Input
                                        disabled={k.rewardUserIds?true:false}
                                        onChange={(e) => this.onChangeParamsValue(index, "giftId", e.currentTarget.value)}
                                        placeholder="礼品ID"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={5}>
                            <FormItem
                                label=""
                                {...formItemLayout}
                            >
                                {getFieldDecorator(`${index}.giftType`, {
                                    rules: [{ message: '请选择礼品类型', pattern: /^\S+$/}],
                                    initialValue: k.giftType
                                })(
                                    <Select defaultValue={k.giftType} disabled={true}>
                                        <Option value={null}>全部</Option>
                                        <Option value="IN_KIND_GIFT">实物周边</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        {!k.rewardUserIds ? (
                            <Icon
                                style={{textAlign:"right"}}
                                className="dynamic-delete-button"
                                type="minus-circle-o"
                                onClick={() => this.props.removeItem(k.id)}
                            />
                        ) : null}
                    </Row>
                    </div>
                </FormItem>
            </div>)
        });
        return (
            <Modal
                width={'60%'}
                visible={visible}
                title={"抽奖设置"}
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    {formItems}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button type="dashed" onClick={this.add} style={{width: '60%'}}>
                            <Icon type="plus"/> 添加抽奖设置
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("state.Commemorativecard",state.Commemorativecard);
    return {
        ...state.Commemorativecard
    }
};
const mapDispatchToProps = ({
});
LotteryModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(LotteryModal)));
