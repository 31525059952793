import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getGameList} from "../../../modules/team/action";

const FormItem = Form.Item;
const Option = Select.Option;

class BrandFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        const {getGameList} = props;
        getGameList();
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {name,game} = values;
                onCommit && onCommit(name,game);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {games} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">


                <FormItem label="品牌名称">
                    {getFieldDecorator('name', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="游戏类型">
                    {getFieldDecorator('game', {
                        initialValue: ""
                        }
                    )(
                        <Select  style={{width: 120}}>
                            <Option value="">全部</Option>
                            {games.map((item) => <Option value={item.code}
                                                         key={item.code}>{item.gameName}</Option>)}
                        </Select>,
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(BrandFilter)));