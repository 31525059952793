import createAsyncAction from 'utils/createAsyncAction';
import ScheduleRequest from '../../request/ScheduleRequest' ;
import {
    GET_SCHEDULE,
    GET_SCHEDULE_DETAIL,
    ADD_SCHEDULE,
    EDIT_SCHEDULE,
    DEL_SCHEDULE,
    RECOMMEND_SCHEDULE, ADD_Prediction, EDIT_Prediction, GET_PREDICTION_DETAIL, EDIT_Prediction_Result, GET_GUESS

} from './actionTypes'

//获取行程
const getSchedule= (params) => (
    createAsyncAction(GET_SCHEDULE,() => (ScheduleRequest.getSchedule(params)))
)
//获取预测
const getGuess= (params) => (
    createAsyncAction(GET_GUESS,() => (ScheduleRequest.getGuess(params)))
)

// 获取行程详情
const getScheduleDetail = (id) =>(
    createAsyncAction(GET_SCHEDULE_DETAIL,() => (ScheduleRequest.getScheduleDetail(id)))
)

// 新增行程
const addSchedule = (params) =>(
    createAsyncAction(ADD_SCHEDULE,() => (ScheduleRequest.addSchedule(params)))
)

// 编辑行程
const editSchedule = (params) =>(
    createAsyncAction(EDIT_SCHEDULE,() => (ScheduleRequest.editSchedule(params)))
)

// 新增预测
const addPrediction = (params) =>(
    createAsyncAction(ADD_Prediction,() => (ScheduleRequest.addPrediction(params)))
)

// 编辑预测
const editPrediction = (params) =>(
    createAsyncAction(EDIT_Prediction,() => (ScheduleRequest.editPrediction(params)))
)

// 编辑预测
const editPredictionResult = (params) =>(
    createAsyncAction(EDIT_Prediction_Result,() => (ScheduleRequest.editPredictionResult(params)))
)

const getPredictionDetail = (id) =>(
    createAsyncAction(GET_PREDICTION_DETAIL,() => (ScheduleRequest.getPredictionDetail(id)))
)

// 删除行程
const delSchedule = (id) =>(
    createAsyncAction(DEL_SCHEDULE,() => (ScheduleRequest.delSchedule(id)))
)

// 置顶行程
const recommendSchedule = (params) =>(
    createAsyncAction(RECOMMEND_SCHEDULE,() => (ScheduleRequest.recommendSchedule(params)))
)


export {
    getSchedule,
    getGuess,
    getScheduleDetail,
    getPredictionDetail,
    addSchedule,
    editSchedule,
    delSchedule,
    recommendSchedule,
    addPrediction,
    editPrediction,
    editPredictionResult,
}
