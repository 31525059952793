import React, {Component} from "react";
import {Form, Button, Select, Input, DatePicker} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class EmojiFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {pageType} = values;

                console.log(values)

                onCommit && onCommit(pageType);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId, username, playerName, block, dateRange} = values;
                console.log(values)

                onCommit && onCommit(userId, username, playerName, block, dateRange);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="用户ID">
                    {getFieldDecorator('userId', {
                            initialValue: ''

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户名">
                    {getFieldDecorator('username', {
                            initialValue: ''
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="选手名">
                    {getFieldDecorator('playerName', {
                            initialValue: ''
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="帖子状态">
                    {getFieldDecorator('block', {
                            initialValue: ''
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value=" ">全部</Option>
                            <Option value="false">正常</Option>
                            <Option value="true">屏蔽</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发布时间区间">
                    {getFieldDecorator('dateRange', {
                        initialValue: ""
                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>               
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}


export default Form.create()(EmojiFilter);
