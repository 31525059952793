
import api from 'utils/api';

class QrCodeRequest {
  /**
     * 查询视图战队列表
     * @param {Object} params 请求参数
     */
  static async getQrcodes(parmas) {
    return api.get('/admin/share/qr-code', parmas);
  }

  /**
     * 创建战队
     * @param {Object} params 请求参数
     */
  static async createQrcode(params) {
    return api.post(`/admin/share/qr-code`, params);
  }

  /**
     * 获取详情
     * @param {Number} id
     */
  static async deleteQrcode(id) {
    return api.delete(`/admin/share/qr-code/${id}`);
  }

}

export default QrCodeRequest;
