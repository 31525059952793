import React from 'react';
import {Form, Input, Modal,Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class CheckListingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowReason: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let params = {
                    "remark": [
                      values.reason
                    ],
                    "state": values.state
                  }
                onSuccess && onSuccess(params);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props;
        let{isShowReason} = this.state;
        return (
            <Modal
                visible={visible}
                title="申请自荐审核"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form layout="inline">
                    <FormItem label="审核状态">
                        {getFieldDecorator('state', {
                            initialValue:"",
                            rules: [{required: true, message: '请审核自荐是否通过'}],
                            }
                        )(
                            <Select  style={{width: 250}} onChange={(e)=>{if(e==='FAIL'){this.setState({isShowReason:true})}}}>
                                <Option value="">请选择</Option>
                                <Option value="SUCCESS">通过</Option>
                                <Option value="FAIL">拒绝</Option>
                            </Select>,
                        )}
                    </FormItem>
                    {
                        isShowReason&&
                        <FormItem label="拒绝理由">
                            {getFieldDecorator('reason', {
                                initialValue:"",
                                rules: [{required: true, message: '请选择拒绝理由'}],
                                }
                            )(
                                <Select  style={{width: 250}}>
                                    <Option value="">请选择</Option>
                                    <Option value="处于禁赛期，无法申请自荐">处于禁赛期，无法申请自荐</Option>
                                    <Option value="存在合同类纠纷，无法申请自荐">存在合同类纠纷，无法申请自荐</Option>
                                    <Option value="未获得俱乐部同意，无法申请自荐">未获得俱乐部同意，无法申请自荐</Option>
                                </Select>,
                            )}
                        </FormItem>
                    }
                    
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
CheckListingModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CheckListingModal)));
