import React from 'react';
import {Button, Card, message, Modal, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getGameList} from "../../modules/team/action";
import {authorizationBrand, createBrand, getBrandInfo, getBrandList, updateBrand} from "../../modules/brand/action";
import {deletePersonVideo, getGameMaps, getPlayAuth, queryPersonVideoList, updatePersonVideo} from "../../modules/training/action";
import TrainingVideoInfoModel from "./components/TrainingVideoInfoModel";
import TrainingVideoFilter from "./components/TrainingVideoFilter";
const {confirm} = Modal;


class TrainingVideoManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowTrainingVideoInfoModel: false, // 战队信息
            seletionValue: null,
            gameMap: '',
            brandId: '',
            gameStartTime: '',
            gameEndTime: '',
            videoTheme: '',
            page: 1,
            size: 10,
            indexVisible: false,
            visible: false
        };
    }

    componentWillMount() {
        this.initData();
    }

    initData = async ()=> {
        const {getGameList,getGameMaps} = this.props;
        await getGameList();
        await getGameMaps();
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.personVideoData !== this.props.personVideoData) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {queryPersonVideoList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        queryPersonVideoList({page, size,
            gameMap: this.state.gameMap ? this.state.gameMap : null,
            brandId: this.state.brandId ? this.state.gameMap : null,
            gameStartTime: this.state.gameStartTime ? this.state.gameStartTime : null,
            gameEndTime: this.state.gameEndTime ? this.state.gameEndTime : null,
            videoTheme: this.state.videoTheme ? this.state.videoTheme : null,
        });
    }

    onPageChangeEvent = (current, pageSize) => {
        const {queryPersonVideoList} = this.props;
        queryPersonVideoList({
            page: current, size: pageSize,
            // game: this.state.game ? this.state.game : null,
            // name: this.state.name ? this.state.name : null,
            // state: this.state.state
            gameMap: this.state.gameMap ? this.state.gameMap : null,
            brandId: this.state.brandId ? this.state.gameMap : null,
            gameStartTime: this.state.gameStartTime ? this.state.gameStartTime : null,
            gameEndTime: this.state.gameEndTime ? this.state.gameEndTime : null,
            videoTheme: this.state.videoTheme ? this.state.videoTheme : null,
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onFormSubmit = async (values) => {
        console.log(values)
        const {seletionValue, isShowTrainingVideoInfoModel} = this.state;
        let param = {}
        Object.keys(values).map(item => {
            if (values[item] != undefined) {
                if (item === "gameStartTime") {
                    param[item] = values[item].format('YYYY-MM-DDTHH:mm:ss');
                    return null;
                }
                param[item] = values[item];
            }
            return null;
        });
        const {updatePersonVideo} = this.props;
        await updatePersonVideo(seletionValue.id, param);
        await this.onGetTableData();
        message.success('修改成功');
        this.setState({isShowTrainingVideoInfoModel: !isShowTrainingVideoInfoModel});
    }

    //条件查找动态方法
    onFilterTrainingVideoEvent = async (gameMap, brandId, gameStartTime, gameEndTime,videoTheme) => {
        const {size} = this.state;
        const {queryPersonVideoList} = this.props;
        this.setState({
            gameMap: gameMap,
            brandId: brandId,
            gameStartTime: gameStartTime,
            gameEndTime: gameEndTime,
            videoTheme: videoTheme,
            page: 1,
            loading: true
        })
        await queryPersonVideoList({page: 1, size: size, gameMap: gameMap, brandId: brandId, gameStartTime:gameStartTime, gameEndTime: gameEndTime, videoTheme: videoTheme});
    }

    onAliPlayer = (value)=>{
        this.setState({
            visible: true,
            seletionValue: value
        })
        let {getPlayAuth} = this.props;
        getPlayAuth({videoId: value.url}).then((data) =>{
            console.log(data)
            window.Aliplayer({
                id: 'J_prismPlayer',
                width: '100%',
                autoplay: false,
                //播放方式二：点播用户推荐
                vid : value.url,
                playauth : data.playAuth,
                cover: value.coverUrl,
                encryptType:1, //当播放私有加密流时需要设置。
            },function(player){
                player.play();
            });
        }).catch(error =>{
            console.log(error)
        });

    }

    handleCancel = () => {
        const {visible} = this.state
        this.setState({visible: !visible});
    }


    renderTabPane = () => {
        const {games, gameMaps, personVideoData
        } = this.props;
        console.log(personVideoData)
        const {
            visible, isShowTrainingVideoInfoModel, seletionValue, page, size,loading
        } = this.state;
        console.log(seletionValue)
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrainingVideoFilter onCommit={this.onFilterTrainingVideoEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={personVideoData ? personVideoData.data : []}
                        columns={this.getTableColumns(games, gameMaps)}
                        bordered
                        pagination={{
                            total: personVideoData.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    { isShowTrainingVideoInfoModel ? <TrainingVideoInfoModel
                        onSuccess={this.onFormSubmit}
                        gameMaps = {gameMaps}
                        trainingVideoInfo = {seletionValue}
                        visible={isShowTrainingVideoInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowTrainingVideoInfoModel: false,
                            });
                        }}
                    /> : null}
                </Card>

                {visible ? <Modal
                    visible={visible}
                    title={seletionValue.videoTheme}
                    footer={null}
                    maskClosable={false}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                >   <div>{seletionValue.url}</div>
                    <div className="prism-player" id="J_prismPlayer"></div>
                </Modal> : null}
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderTabPane()}
            </div>
        );
    }

    onGetTrainingVideoInfoEvent = async (value) => {
        const {isShowTrainingVideoInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowTrainingVideoInfoModel: !isShowTrainingVideoInfoModel,
        });
    }

    onDeleteTrainingVideoInfoConfirm = (id) =>{
        let _this = this
        confirm({
            content: '确认要删除该视频吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onDeleteTrainingVideoInfo(id)
            },
            onCancel() {
            },

        });
    }
    onDeleteTrainingVideoInfo = async (id) =>{
        const {deletePersonVideo} = this.props
        await deletePersonVideo(id);
        await this.onGetTableData()
    }

    getTableColumns = (games, gameMaps) => [
        {
            title: '品牌ID',
            dataIndex: 'brandId',
            key: 'brandId',
            align: 'center',
            width: 100
        }, {
            title: '品牌赛事名',
            dataIndex: 'brandName',
            align: 'center',
            key: 'brandName',
            width: 150
        }, {
            title: '训练赛ID',
            dataIndex: 'trainingId',
            key: 'trainingId',
            align: 'center',
            width: 100
        }, {
            title: '训练赛名称',
            dataIndex: 'trainingTheme',
            align: 'center',
            key: 'trainingTheme',
            width: 150
        }, {
            title: '视频名称',
            dataIndex: 'videoTheme',
            align: 'center',
            key: 'videoTheme',
            width: 150
        }, {
            title: '游戏类型',
            dataIndex: 'game',
            align: 'center',
            key: 'game',
            width: 100,
            render: (row, text) => (
                <span>{games.filter((item) => item.code == text.game).length > 0 ? games.filter((item) => item.code == text.game)[0].gameName : ""}</span>
            ),
        }, {
            title: '地图类型',
            dataIndex: 'gameMap',
            align: 'center',
            key: 'gameMap',
            width: 100,
            render: (row, text) => {
                return (
                    <span>{gameMaps.filter((item) => item.code == text.gameMap).length > 0 ? gameMaps.filter((item) => item.code == text.gameMap)[0].mapName : ""}</span>
                )
            }

        }, {
            title: '场次',
            dataIndex: 'gameNum',
            align: 'center',
            key: 'gameNum',
            width: 100,
            render: (row, text) => (
                <span>
                    {"第"+ text.gameNum + "场"}
                </span>
            ),
        }, {
            title: '比赛开始时间',
            dataIndex: 'gameStartTime',
            width: 200,
            align: 'center',
            key: 'gameStartTime',
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            key: 'createTime',
            width: 200
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (row, text) => {
                return (
                    <div>
                        <Button style={{marginLeft: "10px", marginBottom: "10px"}} type="primary" onClick={() => this.onGetTrainingVideoInfoEvent(text)}
                                color="blue">编辑</Button>

                        <Button style={{marginLeft: "10px", marginBottom: "10px"}} type="primary" onClick={() => this.onAliPlayer(text)}
                                color="blue">播放</Button>

                        <Button style={{marginLeft: "10px"}} type="danger" onClick={() => this.onDeleteTrainingVideoInfoConfirm(text.id)}
                                >删除</Button>
                    </div>
                )
            },
        }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
        pageButtons: state.authentication.pageButtons,
        admins: state.admin.admins
    }
};

const mapDispatchToProps = ({
    getGameList,
    getBrandInfo,
    getBrandList,
    createBrand,
    updateBrand,
    authorizationBrand,
    queryPersonVideoList,
    getGameMaps,
    updatePersonVideo,
    getPlayAuth,
    deletePersonVideo
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(TrainingVideoManagement));
