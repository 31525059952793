import createReducer from 'utils/createReducer';
import {
  GET_VIEW_ROLE_INFO,
  GET_VIEW_ROLE_LIST,
  GET_VIEW_ROLE_PERMISSION,
  GET_FUNCION_PERMISSION,
} from './actionTypes';

const defaultState = () => ({
  roles: {
    data: [],
    total: 0,
  }, // 角色列表
  roleInfo: {}, // 角色信息
  rolePermissions: [], // 角色菜单权限
  rolefunctions: [], // 功能权限
});

const onGetViewRoleInfoSuccess = (state, { payload }) => ({
  ...state,
  roleInfo: payload,
});

const onGetViewRolePermissionSuccess = (state, { payload }) => ({
  ...state,
  rolePermissions: payload,
});


const onGetViewRoleListSuccess = (state, { payload }) => ({
  ...state,
  roles: payload,
});

const onGetFuncionPermissionSuccess = (state, { payload }) => ({
  ...state,
  rolefunctions: payload,
});


export default createReducer(defaultState, {
  [`${GET_VIEW_ROLE_INFO}_SUCCESS`]: onGetViewRoleInfoSuccess,
  [`${GET_VIEW_ROLE_LIST}_SUCCESS`]: onGetViewRoleListSuccess,
  [`${GET_VIEW_ROLE_PERMISSION}_SUCCESS`]: onGetViewRolePermissionSuccess,
  [`${GET_FUNCION_PERMISSION}_SUCCESS`]: onGetFuncionPermissionSuccess,
});
