
import api from 'utils/api';

class AppContactRequest {
    /**
     * 获取联系信息
     * @param {Object} params 请求参数
     */
    static async getAppContact() {
        return api.get('/admin/app/config/contact');
    }

    /**
     * 添加联系信息
     * @param {Object} params 请求参数
     */
    static async createAppContact(params) {
        return api.post(`/admin/app/config/contact`, params);
    }

    /**
     * 修改联系信息
     * @param {Object} params 请求参数
     */
    static async updateAppContact(id, params) {
        return api.put(`/admin/app/config/contact/${id}`, params);
    }

    /**
     * 删除联系信息
     * @param {Number} id
     */
    static async deleteAppContact(id) {
        return api.delete(`/admin/app/config/contact/${id}`);
    }

}

export default AppContactRequest;
