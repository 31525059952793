import createReducer from 'utils/createReducer';
import {
    GET_TEAM_CERTIFICATION_INFO,
    GET_TEAM_CERTIFICATION_LIST,
    GET_TEAM_MEMBERS,
    GET_USER, GET_USER_CREDENTIALS,
    GET_USER_TEAM_LIST_BY_QQ,
    GET_REAL_NAME_CREDENTIALS,
    GET_PERSON_CERTIFICATION_LIST
} from './actionTypes';

const defaultState = () => ({
    certifications: {
        data: [],
        total: 0,
    }, // 参赛认证列表
    certificationTeamMembers: [], // 品牌赛事列表
    certificationInfo: {}, // 品牌赛事信息
    userTeamsByqq: [],
    userCertification: [],
    userCredentials: {
        data: [],
        total: 0,
    },
    realNameCredentials: {
        data: [],
        total: 0,
    }, 
    personCertifications: {
        data: [],
        total: 0,
    }, // 个人训练赛-参赛认证列表
});

const onGetTeamCertificationInfoSuccess = (state, {payload}) => ({
    ...state,
    certificationInfo: payload,
});

const onGetTeamCertificationListSuccess = (state, {payload}) => ({
    ...state,
    certifications: payload,
});

const onGetTeamMembersSuccess = (state, {payload}) => ({
    ...state,
    certificationTeamMembers: payload,
});

const onGetUserTeamListByQQSuccess = (state, {payload}) => ({
    ...state,
    userTeamsByqq: payload,
});

const onGetUserSuccess = (state, {payload}) => ({
    ...state,
    userCertification: payload,
});

const onGetUserCredentials = (state, {payload}) => ({
    ...state,
    userCredentials: payload,
});

const onGetRealNameCredentials = (state, {payload}) => ({
    ...state,
    realNameCredentials: payload,
});

const onGetPersonCertificationListSuccess = (state, {payload}) => ({
    ...state,
    personCertifications: payload,
});


export default createReducer(defaultState, {
    [`${GET_TEAM_CERTIFICATION_INFO}_SUCCESS`]: onGetTeamCertificationInfoSuccess,
    [`${GET_TEAM_CERTIFICATION_LIST}_SUCCESS`]: onGetTeamCertificationListSuccess,
    [`${GET_TEAM_MEMBERS}_SUCCESS`]: onGetTeamMembersSuccess,
    [`${GET_USER_TEAM_LIST_BY_QQ}_SUCCESS`]: onGetUserTeamListByQQSuccess,
    [`${GET_USER}_SUCCESS`]: onGetUserSuccess,
    [`${GET_USER_CREDENTIALS}_SUCCESS`]: onGetUserCredentials,
    [`${GET_REAL_NAME_CREDENTIALS}_SUCCESS`]: onGetRealNameCredentials,
    [`${GET_PERSON_CERTIFICATION_LIST}_SUCCESS`]: onGetPersonCertificationListSuccess,

});
