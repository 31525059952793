import createReducer from 'utils/createReducer';

import {
    GET_ARTICLES,GET_ARTICLE,GET_GAMES
} from './actionTypes';

const defaultState = () => ({
    articles: [],
    articlesTotal: 0,
    articlesLoading: false,
    articleInfo: {},
    adminRoles: [], // 用户所拥有的角色
    games:[],
});


const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        articles:payload

    }

};

const ongetArticlesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        articleInfo: payload,
    }

};

const onGetGamesSuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        games: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_ARTICLES}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_ARTICLE}_SUCCESS`]: ongetArticlesuccess,
    [`${GET_GAMES}_SUCCESS`]: onGetGamesSuccess,
});
