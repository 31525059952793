import createReducer from 'utils/createReducer';

import {
    GET_TICKET_LIST,
    GET_MEDAL_THEME_DATA,
    GET_SUB_MEDAL_LIST,
    GET_SUB_MEDAL_DATA,
    GET_SUB_MEDAL_LOG_LIST,
    GET_TICKET_ORDER_LIST
} from './actionTypes';

const defaultState = () => ({
    medalThemeList: [],
    medalThemeData: {},
    subMedalList: [],
    subMedalData: {},
    subMedalLogList: [],
});

const onGetTicketListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            medalThemeList: payload
        }

};
const onGetTicketOrderListRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        medalThemeList: payload
    }

};

const onGetMedalThemeDataRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            medalThemeData: payload
        }

};

const onGetSubMedalListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            subMedalList: payload
        }

};

const onGetSubMedalDataRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            subMedalData: payload
        }

};

const onGetSubMedalLogListRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
            subMedalLogList: payload
        }

};

export default createReducer(defaultState, {
    [`${GET_TICKET_LIST}_SUCCESS`]: onGetTicketListRequest,
    [`${GET_TICKET_ORDER_LIST}_SUCCESS`]: onGetTicketOrderListRequest,
    [`${GET_MEDAL_THEME_DATA}_SUCCESS`]: onGetMedalThemeDataRequest,
    [`${GET_SUB_MEDAL_LIST}_SUCCESS`]: onGetSubMedalListRequest,
    [`${GET_SUB_MEDAL_DATA}_SUCCESS`]: onGetSubMedalDataRequest,
    [`${GET_SUB_MEDAL_LOG_LIST}_SUCCESS`]: onGetSubMedalLogListRequest,
});
