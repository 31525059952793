import React from 'react';
import {Button,Form, Input, Modal,DatePicker,Select, notification,Image ,Icon} from 'antd';
import UpLoad from "../../../components/upload";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
const dateFormat = "YYYY-MM-DD HH:mm:00";
const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class MajiaPostModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                let val = {
                    ...values, 
                }
                if(values.atExUserIds != null && values.atExUserIds.length > 0) {
                    let ids = values.atExUserIds.split(',');
                    if(ids == null || ids.length == 1) {
                        ids = values.atExUserIds.split('，');
                    }
                    val.atExUserIds = ids;
                }else {
                    val.atExUserIds = [];
                }
                val.seeStatus = false;
                val.dynamicType = "GRAPHIC";
               onSuccess && onSuccess(val);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    onChangeJumpType = (value) => {
        this.setState({
            isSignIn: value
        });
    }

    onSetLatAndLng = (lng,lat)=>{
        console.log("onSetLatAndLng",lng,lat);
        const {setFieldsValue} = this.props.form;
        setFieldsValue({
            'longitude':lng,
            'latitude':lat
        })
        this.setState({
            isShowMap: false,
        });
    }

    componentDidMount() {
        let {visible,type,data,gameList,typeList} = this.props
        if(type != 'ADD' && data != null) {
            this.setState({needSignUp:data.needSignUp})
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,type,data,gameList,typeList} = this.props
        let{activityType,isSignIn,isShowMap,needSignUp} = this.state;
        return (
            <Modal
                width={'40%'}
                visible={visible}
                title="马甲发帖"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form>
                    <FormItem
                        label="所属话题ID"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('topicId', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入帖子所属话题ID'}],
                        })(
                            <Input placeholder="请输入帖子所属话题ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="马甲用户展示ID"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('vestExhibitionId', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入马甲用户展示ID'}],
                        })(
                            <Input placeholder="请输入马甲用户展示ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="At用户ID(多个,号隔开)"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('atExUserIds', {
                            initialValue:'',
                        })(
                            <Input placeholder="At用户ID"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="帖子图片"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('imageUrlList', {
                            initialValue:[]
                        })(
                            <UpLoad
                                double={true}
                                //图片格式限制
                                size={501}
                                maxCount={9}
                            >
                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="帖子标题"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('title', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入帖子标题'}],
                        })(
                            <Input placeholder="请输入帖子标题"/>
                        )}
                    </FormItem>
                    <FormItem
                        label="帖子内容"
                        {...formItemLayout}

                    >
                        {getFieldDecorator('content', {
                            initialValue:'',
                            rules: [{required: true, message: '请输入帖子内容'}],
                        })(
                            <TextArea rows={6} placeholder="请输入帖子内容"/>,
                        )}
                    </FormItem>

                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};
const mapDispatchToProps = ({});
MajiaPostModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(MajiaPostModal)));
