import createAsyncAction from 'utils/createAsyncAction';
import GameRequest from '../../request/GameRequest' ;
import {
    GET_GAME,

} from './actionTypes'

//获取游戏列表
const getGame= (params) => (
    createAsyncAction(GET_GAME,() => (GameRequest.getGame(params)))
);


export {
    getGame,
}
