import React, {Component} from "react";
import {Form, DatePicker, Button,Select} from "antd";
import {getGame} from '../../../modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;
class ActivityFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let val = {
                  ...values,
                  date:values.date?moment(values.date).format('YYYY-MM-DD'):''
                }
                onCommit && onCommit(val);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let {gameList} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="活动时间">
                    {getFieldDecorator('date', {
                            
                        }
                    )(
                        <DatePicker format="YYYY-MM-DD"
                                    />
                    )}
                </FormItem>
                
                <FormItem label="游戏类型:">
                         {getFieldDecorator('gamingDivision', {
                            initialValue:"",
                        })(
                            <Select style={{width: 120}}>
                                <Option value="">全部</Option>
                                {
                                    gameList.map((val,index)=>{
                                        return(
                                            <Option value={val.code} key={val+index}>{val.gameName}</Option>
                                        )
                                    })
                                }
                                
                            </Select>,
                        )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (Form.create()(withRouter(injectIntl(ActivityFilter))));

