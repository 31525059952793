import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, Modal
} from 'antd';
import UpLoad from "../../components/upload";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class MedalAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillMount(){

    }

    // 是否不允许修改级数
    isMedalBan = (medalEnum) => {
        let enums = ['LEVEL_TEN', 'LEVEL_TWENTY', 'LEVEL_THIRTY', 'SIGN_FOR_LOVE', 'PERFECT'];

        for(let i = 0; i < enums.length; i++) {
            if(medalEnum === enums[i]) return true;
        }

        return false;
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent, medalLevel, medalType, medalEnum } = this.props;

                if(values.medalLevel != medalLevel && (medalType === 'SPECIAL' || this.isMedalBan(medalEnum))) {
                    notification.error({message: '该勋章主题不可修改勋章级数'});
                    return;
                }

                if(values.medalLevel < medalLevel) {
                    notification.error({message: '勋章级数只可增加，不可减少'});
                    return;
                }

                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    render() {
        let {
            visible, onCancel, onSubmitEvent,
            id, medalName, medalPic, medalFunction, medalType, medalLevel, medalDesc, sort, medalEnum, type
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        let title = medalName ? "新增“" + medalName + "勋章”主题信息" : "新增勋章主题信息";

        return (
            <Modal
                title={title}
                visible={visible}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    onCancel && onCancel();
                }}
                width={"35%"}
            >
                <Form>
                    {/*{type === 'UPDATE' && <FormItem*/}
                    {/*    label="勋章主题ID"*/}
                    {/*    {...formItemLayout}*/}
                    {/*>*/}
                    {/*    {getFieldDecorator('id', {*/}
                    {/*        rules: [{required: true}],*/}
                    {/*    })(*/}
                    {/*        <Input disabled={true} />,*/}
                    {/*    )}*/}
                    {/*</FormItem>}*/}
                    <FormItem
                        label="勋章主题名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalName', {
                            rules: [{required: true, message: '请输入勋章主题名，不能包含空格', pattern: /^\S+$/}, {max: 10, message: '勋章主题名在10字以内'}],
                        })(
                            <Input placeholder="请输入勋章主题名" />,
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章主题图标"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalPic', {
                            rules: [{required: true, message: '请上传勋章主题图标'}],
                        })(
                            <UpLoad
                                double={false}
                                //图片格式限制
                                size={501}
                                maxCount={1}
                            >

                            </UpLoad>
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章功能"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalFunction', {
                            rules: [{required: true, message: '请输入勋章功能'}],
                        })(
                            <Input   placeholder="请输入勋章功能"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalType', {
                            rules: [{required: true, message: '请选择勋章类型', pattern: /^\S+$/}],
                        })(
                            <Select style={{width: '200px'}}>
                                <Option value="GROWTH">成长勋章</Option>
                                <Option value="SPECIAL">特殊勋章</Option>
                                <Option value="CIRCLE">圈子勋章</Option>
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章级数"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalLevel', {
                            rules: [{required: true, message: '请输入勋章级数，只能是数字', pattern: /^[0-9]*$/}],
                            initialValue: 1
                        })(
                            <Input placeholder={'请输入勋章级数'} />,
                        )}
                    </FormItem>
                    <FormItem
                        label="勋章主题描述"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('medalDesc', {
                            rules: [{max: 50, message: '勋章主题描述在50字以内'},{required: true, message: '请输入勋章主题描述'}],
                        })(
                            <TextArea rows={2} placeholder="50字以内">{medalDesc || ''}</TextArea>,
                        )}
                    </FormItem>
                    <FormItem
                        label="排序"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('sort', {
                            rules: [{required: true, message: '请输入序号', pattern: /^[0-9]*$/}],
                            initialValue:1
                        })(
                            <Input placeholder={'请输入序号'} />,
                        )}
                    </FormItem>

                    <FormItem>
                        <div style={{textAlign: 'center'}}>
                            <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(MedalAddForm);
