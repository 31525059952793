import React, {Component} from "react";
import {
    Form,
    Input,
    Button,
    Select,
    Modal,
} from "antd";
import {getExclusivePlan} from "../../../modules/small/action";
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 20, offset: 4},
    },
};
const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

class LookPlanModel extends Component{

    constructor(props) {
        super(props);
        this.state = {
            plans : [],
            sensitivity: [],
            previewVisible: false,
            previewImage: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }





    componentDidMount() {
        let {plans} = this.state;
        for (let i = 0; i < this.props.plans.length; i++) {
            plans.push(<Option key={this.props.plans[i].planId}>{this.props.plans[i].planName}</Option>)
        }
    }

    componentWillMount() {
        const {planInfo} = this.props;
        this.setState({
            sensitivity: planInfo ? planInfo.sensitivity : []
        })

    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {planName} = values;
                let {modelId} = this.props;

                console.log(values)
                console.log(planName)


                onCommit && onCommit(planName,modelId);
            }
        });
    }


    showImage = (value) => {
        this.setState({previewImage: value, previewVisible: true});
    }

    onThisCancel = e => {
        this.setState({
            previewVisible: false

        })
    }

    onChangeValue = e => {
        console.log(e)
        const {getExclusivePlan,planInfo} = this.props;
        getExclusivePlan(e)
        this.setState({
            sensitivity: planInfo ? planInfo.sensitivity : []

        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {plans,sensitivity,previewVisible,previewImage} = this.state;
        const {planInfo,planName} = this.props;
        console.log(this.props)
        const keys = sensitivity;
        const formItems = keys.map((k, index) => {
            return (<div>
                <FormItem
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? '灵敏度方案' : ''}
                >
                    <div>
                        <FormItem style={{width: 'calc(40%)'}}>
                            {getFieldDecorator(`value[${index}]`, {
                                initialValue: k.value,
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: '方案名称',
                                }],
                            })(
                                <Input placeholder="请输入方案名称" disabled/>,
                            )}
                        </FormItem>
                        {k.params.map((element, e_index) => (
                            <div>
                                <Form.Item
                                    required={false}
                                    style={{display: 'inline-block', width: 'calc(40%)', marginRight: 8}}
                                >
                                    {getFieldDecorator(`params[${index}][${e_index}].key`, {
                                        initialValue: element.key,
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "请输入属性key.",
                                            },
                                        ],
                                    })(<Input placeholder="key" disabled/>)}
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    style={{display: 'inline-block', width: 'calc(40%)', marginRight: 8}}
                                >
                                    {getFieldDecorator(`params[${index}][${e_index}].value`, {
                                        initialValue: element.value,
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "请输入属性value.",
                                            },
                                        ],
                                    })(<Input placeholder="value" disabled/>)}
                                </Form.Item>
                                {/*{k.params.length > 1 ? (*/}
                                {/*    <Icon*/}
                                {/*        className="dynamic-delete-button"*/}
                                {/*        type="minus-circle-o"*/}
                                {/*    />*/}
                                {/*) : null}*/}
                            </div>
                        ))}


                    </div>
                </FormItem>
            </div>)
        });

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem label="方案名" {...formItemLayout}>
                    {getFieldDecorator('planName', {
                            initialValue: planName
                        }
                    )(
                        <Select style={{width: 120}} defaultValue={planName}
                                showSearch
                                optionFilterProp="children"
                                onSearch={this.onSearch}
                                onChange={this.onChangeValue}
                        >
                            {plans}
                        </Select>
                    )}
                </FormItem>

                <FormItem
                    label="名称"
                    {...formItemLayout}
                >
                    {getFieldDecorator('name', {
                        initialValue: planInfo.name ? planInfo.name : "",
                        rules: [{required: true, message: '请填写方案名称!'}],
                    })(
                        <Input style={{width: 120}} placeholder="请填写方案名称" disabled/>,
                    )}
                </FormItem>

                <FormItem
                    label="图片"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrl', {
                        initialValue: planInfo.imageUrl ? planInfo.imageUrl : "",
                        rules: [{required: true, message: '图片未上传!'}],
                    })(
                        <img style={{ width: '15%',height :'15%' }} alt={""} src={planInfo.imageUrl} onClick={() => this.showImage(planInfo.imageUrl)}/>
                    )}
                </FormItem>
                {formItems}
                {previewVisible ? <Modal visible={previewVisible} footer={null} onCancel={this.onThisCancel}>
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal> : null}
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '20%'}}>
                        确认
                    </Button>
                </FormItem>


            </Form>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.models
    }
};
const mapDispatchToProps = ({
    getExclusivePlan
});
// LookPlanModel.defaultProps = defaultProps;
export default Form.create()(LookPlanModel);