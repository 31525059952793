import api from '../utils/api';

class AppNoticeRequest {
    /**
     *
     * @param receiverAccid
     * @param noticeGroupType
     * @param page
     * @param size
     * @returns {*}
     */
    static getAppNotices(receiverAccid, exhibitionId, noticeGroupType,sendStatus,page,size){
        return api.get(`/admin/application-notices`,{page,size,receiverAccid, exhibitionId, noticeGroupType,sendStatus});
    }


    static sendAppNotices(params){
        return api.post(`/admin/application-notices`,params);
    }
}

export default AppNoticeRequest;
