import React from 'react';
import {Cascader,Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import PicturesWall from '../../../components/upload';
import moment from "moment";


const FormItem = Form.Item;
const {Option} = Select;

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

const options = [{
    "children": [{"label": '东城区', "value": '东城区'}, {"label": '西城区', "value": '西城区'},
        {"label": '朝阳区', "value": '朝阳区'}, {
            "label": '丰台区',
            "value": '丰台区'
        }, {"label": '石景山区', "value": '石景山区'}, {"label": '海淀区', "value": '海淀区'}, {"label": '门头沟区', "value": '门头沟区'}, {
            "label": '房山区',
            "value": '房山区'
        }, {"label": '通州区', "value": '通州区'}, {"label": '顺义区', "value": '顺义区'}, {"label": '昌平区', "value": '昌平区'}, {
            "label": '大兴区',
            "value": '大兴区'
        }, {"label": '怀柔区', "value": '怀柔区'}, {"label": '平谷区', "value": '平谷区'}, {"label": '密云县', "value": '密云县'}, {
            "label": '延庆县',
            "value": '延庆县'
        }],
    "label": "北京市",
    "value": "北京市"
}, {
    "children": [{"label": '和平区', "value": '和平区'}, {"label": '河东区', "value": '河东区'}, {"label": '河西区', "value": '河西区'}, {
        "label": '南开区',
        "value": '南开区'
    }, {"label": '河北区', "value": '河北区'}, {"label": '红桥区', "value": '红桥区'}, {"label": '东丽区', "value": '东丽区'}, {
        "label": '西青区',
        "value": '西青区'
    }, {"label": '津南区', "value": '津南区'}, {"label": '北辰区', "value": '北辰区'}, {"label": '武清区', "value": '武清区'}, {
        "label": '宝坻区',
        "value": '宝坻区'
    }, {"label": '滨海新区', "value": '滨海新区'}, {"label": '宁河县', "value": '宁河县'}, {"label": '静海县', "value": '静海县'}, {
        "label": '蓟县',
        "value": '蓟县'
    }], "label": "天津市", "value": "天津市"
}, {
    "children": [{
        "label": "石家庄市",
        "value": "石家庄市"
    }, {"label": "唐山市", "value": "唐山市"}, {"label": "秦皇岛市", "value": "秦皇岛市"}, {
        "label": "邯郸市",
        "value": "邯郸市"
    }, {"label": "邢台市", "value": "邢台市"}, {"label": "保定市", "value": "保定市"}, {
        "label": "张家口市",
        "value": "张家口市"
    }, {"label": "承德市", "value": "承德市"}, {"label": "沧州市", "value": "沧州市"}, {
        "label": "廊坊市",
        "value": "廊坊市"
    }, {"label": "衡水市", "value": "衡水市"}], "label": "河北省", "value": "河北省"
}, {
    "children": [{"label": "太原市", "value": "太原市"}, {"label": "大同市", "value": "大同市"}, {
        "label": "阳泉市",
        "value": "阳泉市"
    }, {"label": "长治市", "value": "长治市"}, {"label": "晋城市", "value": "晋城市"}, {
        "label": "朔州市",
        "value": "朔州市"
    }, {"label": "晋中市", "value": "晋中市"}, {"label": "运城市", "value": "运城市"}, {
        "label": "忻州市",
        "value": "忻州市"
    }, {"label": "临汾市", "value": "临汾市"}, {"label": "吕梁市", "value": "吕梁市"}], "label": "山西省", "value": "山西省"
}, {
    "children": [{"label": "呼和浩特市", "value": "呼和浩特市"}, {"label": "包头市", "value": "包头市"}, {
        "label": "乌海市",
        "value": "乌海市"
    }, {"label": "赤峰市", "value": "赤峰市"}, {"label": "通辽市", "value": "通辽市"}, {
        "label": "鄂尔多斯市",
        "value": "鄂尔多斯市"
    }, {"label": "呼伦贝尔市", "value": "呼伦贝尔市"}, {"label": "巴彦淖尔市", "value": "巴彦淖尔市"}, {
        "label": "乌兰察布市",
        "value": "乌兰察布市"
    }, {"label": "兴安盟", "value": "兴安盟"}, {"label": "锡林郭勒盟", "value": "锡林郭勒盟"}, {"label": "阿拉善盟", "value": "阿拉善盟"}],
    "label": "内蒙古自治区",
    "value": "内蒙古自治区"
}, {
    "children": [{"label": "沈阳市", "value": "沈阳市"}, {"label": "大连市", "value": "大连市"}, {
        "label": "鞍山市",
        "value": "鞍山市"
    }, {"label": "抚顺市", "value": "抚顺市"}, {"label": "本溪市", "value": "本溪市"}, {
        "label": "丹东市",
        "value": "丹东市"
    }, {"label": "锦州市", "value": "锦州市"}, {"label": "营口市", "value": "营口市"}, {
        "label": "阜新市",
        "value": "阜新市"
    }, {"label": "辽阳市", "value": "辽阳市"}, {"label": "盘锦市", "value": "盘锦市"}, {
        "label": "铁岭市",
        "value": "铁岭市"
    }, {"label": "朝阳市", "value": "朝阳市"}, {"label": "葫芦岛市", "value": "葫芦岛市"}], "label": "辽宁省", "value": "辽宁省"
}, {
    "children": [{"label": "长春市", "value": "长春市"}, {"label": "吉林市", "value": "吉林市"}, {
        "label": "四平市",
        "value": "四平市"
    }, {"label": "辽源市", "value": "辽源市"}, {"label": "通化市", "value": "通化市"}, {
        "label": "白山市",
        "value": "白山市"
    }, {"label": "松原市", "value": "松原市"}, {"label": "白城市", "value": "白城市"}, {"label": "延边朝鲜族自治州", "value": "延边朝鲜族自治州"}],
    "label": "吉林省",
    "value": "吉林省"
}, {
    "children": [{"label": "哈尔滨市", "value": "哈尔滨市"}, {"label": "齐齐哈尔市", "value": "齐齐哈尔市"}, {
        "label": "鸡西市",
        "value": "鸡西市"
    }, {"label": "鹤岗市", "value": "鹤岗市"}, {"label": "双鸭山市", "value": "双鸭山市"}, {
        "label": "大庆市",
        "value": "大庆市"
    }, {"label": "伊春市", "value": "伊春市"}, {"label": "佳木斯市", "value": "佳木斯市"}, {
        "label": "七台河市",
        "value": "七台河市"
    }, {"label": "牡丹江市", "value": "牡丹江市"}, {"label": "黑河市", "value": "黑河市"}, {
        "label": "绥化市",
        "value": "绥化市"
    }, {"label": "大兴安岭地区", "value": "大兴安岭地区"}], "label": "黑龙江省", "value": "黑龙江省"
}, {
    "children": [{"label": '黄浦区', "value": '黄浦区'}, {"label": '徐汇区', "value": '徐汇区'}, {"label": '长宁区', "value": '长宁区'}, {
        "label": '静安区',
        "value": '静安区'
    }, {"label": '普陀区', "value": '普陀区'}, {"label": '闸北区', "value": '闸北区'}, {"label": '虹口区', "value": '虹口区'}, {
        "label": '杨浦区',
        "value": '杨浦区'
    }, {"label": '闵行区', "value": '闵行区'}, {"label": '宝山区', "value": '宝山区'}, {"label": '嘉定区', "value": '嘉定区'}, {
        "label": '浦东新区',
        "value": '浦东新区'
    }, {"label": '金山区', "value": '金山区'}, {"label": '松江区', "value": '松江区'}, {"label": '青浦区', "value": '青浦区'}, {
        "label": '奉贤区',
        "value": '奉贤区'
    }, {"label": '崇明县', "value": '崇明县'}], "label": "上海市", "value": "上海市"
}, {
    "children": [{
        "label": "南京市",
        "value": "南京市"
    }, {"label": "无锡市", "value": "无锡市"}, {"label": "徐州市", "value": "徐州市"}, {
        "label": "常州市",
        "value": "常州市"
    }, {"label": "苏州市", "value": "苏州市"}, {"label": "南通市", "value": "南通市"}, {
        "label": "连云港市",
        "value": "连云港市"
    }, {"label": "淮安市", "value": "淮安市"}, {"label": "盐城市", "value": "盐城市"}, {
        "label": "扬州市",
        "value": "扬州市"
    }, {"label": "镇江市", "value": "镇江市"}, {"label": "泰州市", "value": "泰州市"}, {"label": "宿迁市", "value": "宿迁市"}],
    "label": "江苏省",
    "value": "江苏省"
}, {
    "children": [{"label": "杭州市", "value": "杭州市"}, {"label": "宁波市", "value": "宁波市"}, {
        "label": "温州市",
        "value": "温州市"
    }, {"label": "嘉兴市", "value": "嘉兴市"}, {"label": "湖州市", "value": "湖州市"}, {
        "label": "绍兴市",
        "value": "绍兴市"
    }, {"label": "金华市", "value": "金华市"}, {"label": "衢州市", "value": "衢州市"}, {
        "label": "舟山市",
        "value": "舟山市"
    }, {"label": "台州市", "value": "台州市"}, {"label": "丽水市", "value": "丽水市"}], "label": "浙江省", "value": "浙江省"
}, {
    "children": [{"label": "合肥市", "value": "合肥市"}, {"label": "芜湖市", "value": "芜湖市"}, {
        "label": "蚌埠市",
        "value": "蚌埠市"
    }, {"label": "淮南市", "value": "淮南市"}, {"label": "马鞍山市", "value": "马鞍山市"}, {
        "label": "淮北市",
        "value": "淮北市"
    }, {"label": "铜陵市", "value": "铜陵市"}, {"label": "安庆市", "value": "安庆市"}, {
        "label": "黄山市",
        "value": "黄山市"
    }, {"label": "滁州市", "value": "滁州市"}, {"label": "阜阳市", "value": "阜阳市"}, {
        "label": "宿州市",
        "value": "宿州市"
    }, {"label": "六安市", "value": "六安市"}, {"label": "亳州市", "value": "亳州市"}, {
        "label": "池州市",
        "value": "池州市"
    }, {"label": "宣城市", "value": "宣城市"}], "label": "安徽省", "value": "安徽省"
}, {
    "children": [{"label": "福州市", "value": "福州市"}, {"label": "厦门市", "value": "厦门市"}, {
        "label": "莆田市",
        "value": "莆田市"
    }, {"label": "三明市", "value": "三明市"}, {"label": "泉州市", "value": "泉州市"}, {
        "label": "漳州市",
        "value": "漳州市"
    }, {"label": "南平市", "value": "南平市"}, {"label": "龙岩市", "value": "龙岩市"}, {"label": "宁德市", "value": "宁德市"}],
    "label": "福建省",
    "value": "福建省"
}, {
    "children": [{"label": "南昌市", "value": "南昌市"}, {"label": "景德镇市", "value": "景德镇市"}, {
        "label": "萍乡市",
        "value": "萍乡市"
    }, {"label": "九江市", "value": "九江市"}, {"label": "新余市", "value": "新余市"}, {
        "label": "鹰潭市",
        "value": "鹰潭市"
    }, {"label": "赣州市", "value": "赣州市"}, {"label": "吉安市", "value": "吉安市"}, {
        "label": "宜春市",
        "value": "宜春市"
    }, {"label": "抚州市", "value": "抚州市"}, {"label": "上饶市", "value": "上饶市"}], "label": "江西省", "value": "江西省"
}, {
    "children": [{"label": "济南市", "value": "济南市"}, {"label": "青岛市", "value": "青岛市"}, {
        "label": "淄博市",
        "value": "淄博市"
    }, {"label": "枣庄市", "value": "枣庄市"}, {"label": "东营市", "value": "东营市"}, {
        "label": "烟台市",
        "value": "烟台市"
    }, {"label": "潍坊市", "value": "潍坊市"}, {"label": "济宁市", "value": "济宁市"}, {
        "label": "泰安市",
        "value": "泰安市"
    }, {"label": "威海市", "value": "威海市"}, {"label": "日照市", "value": "日照市"}, {
        "label": "临沂市",
        "value": "临沂市"
    }, {"label": "德州市", "value": "德州市"}, {"label": "聊城市", "value": "聊城市"}, {
        "label": "滨州市",
        "value": "滨州市"
    }, {"label": "菏泽市", "value": "菏泽市"}], "label": "山东省", "value": "山东省"
}, {
    "children": [{"label": "郑州市", "value": "郑州市"}, {"label": "开封市", "value": "开封市"}, {
        "label": "洛阳市",
        "value": "洛阳市"
    }, {"label": "平顶山市", "value": "平顶山市"}, {"label": "安阳市", "value": "安阳市"}, {
        "label": "鹤壁市",
        "value": "鹤壁市"
    }, {"label": "新乡市", "value": "新乡市"}, {"label": "焦作市", "value": "焦作市"}, {
        "label": "濮阳市",
        "value": "濮阳市"
    }, {"label": "许昌市", "value": "许昌市"}, {"label": "漯河市", "value": "漯河市"}, {
        "label": "三门峡市",
        "value": "三门峡市"
    }, {"label": "南阳市", "value": "南阳市"}, {"label": "商丘市", "value": "商丘市"}, {
        "label": "信阳市",
        "value": "信阳市"
    }, {"label": "周口市", "value": "周口市"}, {"label": "驻马店市", "value": "驻马店市"}], "label": "河南省", "value": "河南省"
}, {
    "children": [{"label": "武汉市", "value": "武汉市"}, {"label": "黄石市", "value": "黄石市"}, {
        "label": "十堰市",
        "value": "十堰市"
    }, {"label": "宜昌市", "value": "宜昌市"}, {"label": "襄阳市", "value": "襄阳市"}, {
        "label": "鄂州市",
        "value": "鄂州市"
    }, {"label": "荆门市", "value": "荆门市"}, {"label": "孝感市", "value": "孝感市"}, {
        "label": "荆州市",
        "value": "荆州市"
    }, {"label": "黄冈市", "value": "黄冈市"}, {"label": "咸宁市", "value": "咸宁市"}, {
        "label": "随州市",
        "value": "随州市"
    }, {"label": "恩施土家族苗族自治州", "value": "恩施土家族苗族自治州"}], "label": "湖北省", "value": "湖北省"
}, {
    "children": [{"label": "长沙市", "value": "长沙市"}, {"label": "株洲市", "value": "株洲市"}, {
        "label": "湘潭市",
        "value": "湘潭市"
    }, {"label": "衡阳市", "value": "衡阳市"}, {"label": "邵阳市", "value": "邵阳市"}, {
        "label": "岳阳市",
        "value": "岳阳市"
    }, {"label": "常德市", "value": "常德市"}, {"label": "张家界市", "value": "张家界市"}, {
        "label": "益阳市",
        "value": "益阳市"
    }, {"label": "郴州市", "value": "郴州市"}, {"label": "永州市", "value": "永州市"}, {
        "label": "怀化市",
        "value": "怀化市"
    }, {"label": "娄底市", "value": "娄底市"}, {"label": "湘西土家族苗族自治州", "value": "湘西土家族苗族自治州"}], "label": "湖南省", "value": "湖南省"
}, {
    "children": [{"label": "广州市", "value": "广州市"}, {"label": "韶关市", "value": "韶关市"}, {
        "label": "深圳市",
        "value": "深圳市"
    }, {"label": "珠海市", "value": "珠海市"}, {"label": "汕头市", "value": "汕头市"}, {
        "label": "佛山市",
        "value": "佛山市"
    }, {"label": "江门市", "value": "江门市"}, {"label": "湛江市", "value": "湛江市"}, {
        "label": "茂名市",
        "value": "茂名市"
    }, {"label": "肇庆市", "value": "肇庆市"}, {"label": "惠州市", "value": "惠州市"}, {
        "label": "梅州市",
        "value": "梅州市"
    }, {"label": "汕尾市", "value": "汕尾市"}, {"label": "河源市", "value": "河源市"}, {
        "label": "阳江市",
        "value": "阳江市"
    }, {"label": "清远市", "value": "清远市"}, {"label": "东莞市", "value": "东莞市"}, {
        "label": "中山市",
        "value": "中山市"
    }, {"label": "潮州市", "value": "潮州市"}, {"label": "揭阳市", "value": "揭阳市"}, {"label": "云浮市", "value": "云浮市"}],
    "label": "广东省",
    "value": "广东省"
}, {
    "children": [{"label": "南宁市", "value": "南宁市"}, {"label": "柳州市", "value": "柳州市"}, {
        "label": "桂林市",
        "value": "桂林市"
    }, {"label": "梧州市", "value": "梧州市"}, {"label": "北海市", "value": "北海市"}, {
        "label": "防城港市",
        "value": "防城港市"
    }, {"label": "钦州市", "value": "钦州市"}, {"label": "贵港市", "value": "贵港市"}, {
        "label": "玉林市",
        "value": "玉林市"
    }, {"label": "百色市", "value": "百色市"}, {"label": "贺州市", "value": "贺州市"}, {
        "label": "河池市",
        "value": "河池市"
    }, {"label": "来宾市", "value": "来宾市"}, {"label": "崇左市", "value": "崇左市"}], "label": "广西壮族自治区", "value": "广西壮族自治区"
}, {
    "children": [{"label": "海口市", "value": "海口市"}, {"label": "三亚市", "value": "三亚市"}, {
        "label": "三沙市",
        "value": "三沙市"
    }, {"label": "儋州市", "value": "儋州市"}], "label": "海南省", "value": "海南省"
}, {
    "children": [{"label": '万州区', "value": '万州区'}, {"label": '涪陵区', "value": '涪陵区'}, {"label": '渝中区', "value": '渝中区'}, {
        "label": '大渡口区',
        "value": '大渡口区'
    }, {"label": '江北区', "value": '江北区'}, {"label": '沙坪坝区', "value": '沙坪坝区'}, {"label": '九龙坡区', "value": '九龙坡区'}, {
        "label": '南岸区',
        "value": '南岸区'
    }, {"label": '北碚区', "value": '北碚区'}, {"label": '綦江区', "value": '綦江区'}, {"label": '大足区', "value": '大足区'}, {
        "label": '渝北区',
        "value": '渝北区'
    }, {"label": '巴南区', "value": '巴南区'}, {"label": '黔江区', "value": '黔江区'}, {"label": '长寿区', "value": '长寿区'}, {
        "label": '江津区',
        "value": '江津区'
    }, {"label": '合川区', "value": '合川区'}, {"label": '永川区', "value": '永川区'}, {"label": '南川区', "value": '南川区'}, {
        "label": '潼南县',
        "value": '潼南县'
    }, {"label": '铜梁区', "value": '铜梁区'}, {"label": '荣昌县', "value": '荣昌县'}, {"label": '璧山区', "value": '璧山区'}, {
        "label": '梁平县',
        "value": '梁平县'
    }, {"label": '城口县', "value": '城口县'}, {"label": '丰都县', "value": '丰都县'}, {"label": '垫江县', "value": '垫江县'}, {
        "label": '武隆县',
        "value": '武隆县'
    }, {"label": '忠县', "value": '忠县'}, {"label": '开县', "value": '开县'}, {"label": '云阳县', "value": '云阳县'}, {
        "label": '奉节县',
        "value": '奉节县'
    }, {"label": '巫山县', "value": '巫山县'}, {"label": '巫溪县', "value": '巫溪县'}, {"label": '石柱土家族自治县', "value": '石柱土家族自治县'}, {
        "label": '秀山土家族苗族自治县',
        "value": '秀山土家族苗族自治县'
    }, {"label": '酉阳土家族苗族自治县', "value": '酉阳土家族苗族自治县'}, {"label": '彭水苗族土家族自治县', "value": '彭水苗族土家族自治县'}, 
       {"label": '两江新区', "value": '两江新区'}],
    "label": "重庆市",
    "value": "重庆市"
}, {
    "children": [{
        "label": "成都市",
        "value": "成都市"
    }, {"label": "自贡市", "value": "自贡市"}, {"label": "攀枝花市", "value": "攀枝花市"}, {
        "label": "泸州市",
        "value": "泸州市"
    }, {"label": "德阳市", "value": "德阳市"}, {"label": "绵阳市", "value": "绵阳市"}, {
        "label": "广元市",
        "value": "广元市"
    }, {"label": "遂宁市", "value": "遂宁市"}, {"label": "内江市", "value": "内江市"}, {
        "label": "乐山市",
        "value": "乐山市"
    }, {"label": "南充市", "value": "南充市"}, {"label": "眉山市", "value": "眉山市"}, {
        "label": "宜宾市",
        "value": "宜宾市"
    }, {"label": "广安市", "value": "广安市"}, {"label": "达州市", "value": "达州市"}, {
        "label": "雅安市",
        "value": "雅安市"
    }, {"label": "巴中市", "value": "巴中市"}, {"label": "资阳市", "value": "资阳市"}, {
        "label": "阿坝藏族羌族自治州",
        "value": "阿坝藏族羌族自治州"
    }, {"label": "甘孜藏族自治州", "value": "甘孜藏族自治州"}, {"label": "凉山彝族自治州", "value": "凉山彝族自治州"}],
    "label": "四川省",
    "value": "四川省"
}, {
    "children": [{"label": "贵阳市", "value": "贵阳市"}, {"label": "六盘水市", "value": "六盘水市"}, {
        "label": "遵义市",
        "value": "遵义市"
    }, {"label": "安顺市", "value": "安顺市"}, {"label": "毕节市", "value": "毕节市"}, {
        "label": "铜仁市",
        "value": "铜仁市"
    }, {"label": "黔西南布依族苗族自治州", "value": "黔西南布依族苗族自治州"}, {
        "label": "黔东南苗族侗族自治州",
        "value": "黔东南苗族侗族自治州"
    }, {"label": "黔南布依族苗族自治州", "value": "黔南布依族苗族自治州"}], "label": "贵州省", "value": "贵州省"
}, {
    "children": [{"label": "昆明市", "value": "昆明市"}, {"label": "曲靖市", "value": "曲靖市"}, {
        "label": "玉溪市",
        "value": "玉溪市"
    }, {"label": "保山市", "value": "保山市"}, {"label": "昭通市", "value": "昭通市"}, {
        "label": "丽江市",
        "value": "丽江市"
    }, {"label": "普洱市", "value": "普洱市"}, {"label": "临沧市", "value": "临沧市"}, {
        "label": "楚雄彝族自治州",
        "value": "楚雄彝族自治州"
    }, {"label": "红河哈尼族彝族自治州", "value": "红河哈尼族彝族自治州"}, {
        "label": "文山壮族苗族自治州",
        "value": "文山壮族苗族自治州"
    }, {"label": "西双版纳傣族自治州", "value": "西双版纳傣族自治州"}, {"label": "大理白族自治州", "value": "大理白族自治州"}, {
        "label": "德宏傣族景颇族自治州",
        "value": "德宏傣族景颇族自治州"
    }, {"label": "怒江傈僳族自治州", "value": "怒江傈僳族自治州"}, {"label": "迪庆藏族自治州", "value": "迪庆藏族自治州"}],
    "label": "云南省",
    "value": "云南省"
}, {
    "children": [{"label": "拉萨市", "value": "拉萨市"}, {"label": "日喀则市", "value": "日喀则市"}, {
        "label": "昌都市",
        "value": "昌都市"
    }, {"label": "林芝市", "value": "林芝市"}, {"label": "山南市", "value": "山南市"}, {
        "label": "那曲市",
        "value": "那曲市"
    }, {"label": "阿里地区", "value": "阿里地区"}], "label": "西藏自治区", "value": "西藏自治区"
}, {
    "children": [{"label": "西安市", "value": "西安市"}, {"label": "铜川市", "value": "铜川市"}, {
        "label": "宝鸡市",
        "value": "宝鸡市"
    }, {"label": "咸阳市", "value": "咸阳市"}, {"label": "渭南市", "value": "渭南市"}, {
        "label": "延安市",
        "value": "延安市"
    }, {"label": "汉中市", "value": "汉中市"}, {"label": "榆林市", "value": "榆林市"}, {
        "label": "安康市",
        "value": "安康市"
    }, {"label": "商洛市", "value": "商洛市"}], "label": "陕西省", "value": "陕西省"
}, {
    "children": [{"label": "兰州市", "value": "兰州市"}, {"label": "嘉峪关市", "value": "嘉峪关市"}, {
        "label": "金昌市",
        "value": "金昌市"
    }, {"label": "白银市", "value": "白银市"}, {"label": "天水市", "value": "天水市"}, {
        "label": "武威市",
        "value": "武威市"
    }, {"label": "张掖市", "value": "张掖市"}, {"label": "平凉市", "value": "平凉市"}, {
        "label": "酒泉市",
        "value": "酒泉市"
    }, {"label": "庆阳市", "value": "庆阳市"}, {"label": "定西市", "value": "定西市"}, {
        "label": "陇南市",
        "value": "陇南市"
    }, {"label": "临夏回族自治州", "value": "临夏回族自治州"}, {"label": "甘南藏族自治州", "value": "甘南藏族自治州"}],
    "label": "甘肃省",
    "value": "甘肃省"
}, {
    "children": [{"label": "西宁市", "value": "西宁市"}, {"label": "海东市", "value": "海东市"}, {
        "label": "海北藏族自治州",
        "value": "海北藏族自治州"
    }, {"label": "黄南藏族自治州", "value": "黄南藏族自治州"}, {"label": "海南藏族自治州", "value": "海南藏族自治州"}, {
        "label": "果洛藏族自治州",
        "value": "果洛藏族自治州"
    }, {"label": "玉树藏族自治州", "value": "玉树藏族自治州"}, {"label": "海西蒙古族藏族自治州", "value": "海西蒙古族藏族自治州"}],
    "label": "青海省",
    "value": "青海省"
}, {
    "children": [{"label": "银川市", "value": "银川市"}, {"label": "石嘴山市", "value": "石嘴山市"}, {
        "label": "吴忠市",
        "value": "吴忠市"
    }, {"label": "固原市", "value": "固原市"}, {"label": "中卫市", "value": "中卫市"}], "label": "宁夏回族自治区", "value": "宁夏回族自治区"
}, {
    "children": [{"label": "乌鲁木齐市", "value": "乌鲁木齐市"}, {"label": "克拉玛依市", "value": "克拉玛依市"}, {
        "label": "吐鲁番市",
        "value": "吐鲁番市"
    }, {"label": "哈密市", "value": "哈密市"}, {"label": "昌吉回族自治州", "value": "昌吉回族自治州"}, {
        "label": "博尔塔拉蒙古自治州",
        "value": "博尔塔拉蒙古自治州"
    }, {"label": "巴音郭楞蒙古自治州", "value": "巴音郭楞蒙古自治州"}, {"label": "阿克苏地区", "value": "阿克苏地区"}, {
        "label": "克孜勒苏柯尔克孜自治州",
        "value": "克孜勒苏柯尔克孜自治州"
    }, {"label": "喀什地区", "value": "喀什地区"}, {"label": "和田地区", "value": "和田地区"}, {
        "label": "伊犁哈萨克自治州",
        "value": "伊犁哈萨克自治州"
    }, {"label": "塔城地区", "value": "塔城地区"}, {"label": "阿勒泰地区", "value": "阿勒泰地区"}],
    "label": "新疆维吾尔自治区",
    "value": "新疆维吾尔自治区"
},
    // {"children": [], "label": "台湾省", "value": "台湾省"},
    {
        "children": [{"label": '中西區', "value": '中西區'}, {"label": '灣仔區', "value": '灣仔區'}, {
            "label": '東區',
            "value": '東區'
        }, {
            "label": '南區',
            "value": '南區'
        }, {"label": '油尖旺區', "value": '油尖旺區'}, {"label": '深水埗區', "value": '深水埗區'}, {"label": '九龍城區', "value": '九龍城區'}, {
            "label": '黃大仙區',
            "value": '黃大仙區'
        }, {"label": '觀塘區', "value": '觀塘區'}, {"label": '荃灣區', "value": '荃灣區'}, {"label": '屯門區', "value": '屯門區'}, {
            "label": '元朗區',
            "value": '元朗區'
        }, {"label": '北區', "value": '北區'}, {"label": '大埔區', "value": '大埔區'}, {"label": '西貢區', "value": '西貢區'}, {
            "label": '沙田區',
            "value": '沙田區'
        }, {"label": '葵青區', "value": '葵青區'}, {"label": '離島區', "value": '離島區'}],
        "label": "香港特别行政区",
        "value": "香港特别行政区"
    }, {
        "children": [{"label": '花地瑪堂區', "value": '花地瑪堂區'}, {"label": '花王堂區', "value": '花王堂區'}, {
            "label": '望德堂區',
            "value": '望德堂區'
        }, {
            "label": '大堂區',
            "value": '大堂區'
        }, {"label": '風順堂區', "value": '風順堂區'}, {"label": '嘉模堂區', "value": '嘉模堂區'}, {"label": '路氹城', "value": '路氹城'}, {
            "label": '聖方濟各堂區',
            "value": '聖方濟各堂區'
        }], "label": "澳门特别行政区", "value": "澳门特别行政区"
    }];

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};
const uploadBox = {
    display: 'inline-block',
    border: '1px solid #eee',
    width: '60px',
    height: '60px',
}

class AddPlayerModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            address: {
                province: '',
                city: '',
            }
        };
    }


    componentWillMount() {}

    componentDidMount() {}

    renderTitle = () => {
        const {showId} = this.props;
        if (showId) {
            return (
                <div>添加职业选手主页</div>
            )
        } else return (
            <div>编辑职业选手主页</div>
        )
    }
    onChange = (value) => {
        console.log("value", value)
        console.log("vauew111", value[0], value[1])
        const {address} = this.state;
        address.province = value[0];
        address.city = value[1]
        this.setState({
            address
        })
    }
    renderAddress = () => {
        const {defaultValue} = this.props;
        //let address = defaultValue.address
        let address = []
        if (defaultValue.censusRegister) {
            address.push(defaultValue.censusRegister.province, defaultValue.censusRegister.city)
        }
        return address
    }
 
    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const {address} = this.state
                if(values && values.backgroundUrl){
                    values.backgroundUrl=values.backgroundUrl.split("?")[0]
                }
                if(values && values.gunUrl1){
                    values.gunUrl1=values.gunUrl1.split("?")[0]
                }
                if(values && values.gunUrl2){
                    values.gunUrl2=values.gunUrl2.split("?")[0]
                }
                if(values && values.fingeringKeysUrl){
                    values.fingeringKeysUrl=values.fingeringKeysUrl.split("?")[0]
                }
                onSuccess && onSuccess(values,address);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, defaultValue} = this.props;
        console.log('defaultValue',defaultValue)
        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                onCancel={this.props.onClose}
                destroyOnClose={true}
                onOk={this.onSubmit}
                okText={"确认"}
                cancelText={"取消"}
                width={750}
            >
                <Form>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="用户展示ID"
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('exhibitionId', {
                                    rules: [{required: true, message: '请填写管理员展示ID', pattern: /^\S+$/}],
                                    initialValue: defaultValue.exhibitionId,
                                })(
                                    <Input placeholder="请填写用户展示ID"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('playerType', {
                                    rules: [{required: true, message: '请选择选手类型', pattern: /^\S+$/}],
                                    initialValue: defaultValue.playerType,
                                })(
                                    (
                                        <Select style={{width: '150px'}}>
                                            <Option value='COMMON'>普通</Option>
                                            <Option value='PEL'>PEL</Option>
                                            <Option value='PEN'>PEN</Option>
                                            <Option value='PEC'>PEC</Option>
                                        </Select>
                                    ),
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手ID名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('pelPlayerIdName', {
                                    rules: [{required: true, message: '请填写选手ID名称', pattern: /^\S+$/}],
                                    initialValue: defaultValue.pelPlayerIdName,
                                })(
                                    <Input placeholder="请填写选手ID名称"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手姓名"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('playerName', {
                                    rules: [{required: true, message: '请填写选手姓名', pattern: /^\S+$/}],
                                    initialValue: defaultValue.playerName,
                                })(
                                    <Input placeholder="请填写选手姓名"/>,
                                )}
                            </FormItem>
                        </Col>

                    </Row>


                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="所属战队ID"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('teamShowId', {
                                    rules: [{required: true, message: '请填写所属战队ID', pattern: /^\S+$/}],
                                    initialValue: defaultValue.teamShowId,
                                })(
                                    <Input placeholder="请填写所属战队ID"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手生日"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('birthday', {
                                    rules: [{required: true, message: '请填写选手生日'}],
                                    initialValue: defaultValue.birthday ? moment(defaultValue.birthday, "YYYY-MM-DD") : moment().subtract('0', 'day'),
                                })(
                                    <DatePicker format="YYYY-MM-DD"/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <FormItem
                                label="选手身价"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('worth', {
                                    rules: [{required: true, message: '请填写选手身价', pattern: /^\S+$/}],
                                    initialValue: defaultValue.worth,
                                })(
                                    <Input placeholder="请填写选手身价" type="number"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="合同到期"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('contractDue', {
                                    rules: [{required: true, message: '请选择合同到期时间'}],
                                    initialValue: defaultValue.contractDue ? moment(defaultValue.contractDue, "YYYY-MM-DD") : moment().subtract('0', 'day'),
                                })(
                                    <DatePicker format="YYYY-MM-DD"/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手户籍"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('address', {
                                    rules: [{required: true, message: '请选择选手户籍',pattern: /^\S+$/}],
                                    initialValue: this.renderAddress(),
                                })(
                                    <Cascader
                                    options={options}
                                    onChange={this.onChange}
                                    placeholder="请选择选手户籍"/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="场上位置"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('floorPosition', {
                                    rules: [{required: true, message: '请选择场上位置'}],
                                    initialValue:defaultValue.floorPosition
                                })(
                                    (
                                        <Select style={{width: '150px'}}>
                                             <Option value="指挥官">指挥官</Option>
                                             <Option value="自由人">自由人</Option>
                                             <Option value="突击手">突击手</Option>
                                             <Option value="狙击手">狙击手</Option>
                                        </Select>
                                    ),
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手背景图"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('backgroundUrl', {
                                    rules: [{required: true, message: '请上传选手背景图', pattern: /^\S+$/}],
                                    initialValue: defaultValue.backgroundUrl ? defaultValue.backgroundUrl+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={200}
                                        //           height={200}
                                                  size={501}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="枪械搭配图1"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunUrl1', {
                                    rules: [{required: true, message: '请上传枪械搭配图1', pattern: /^\S+$/}],
                                    initialValue: defaultValue.gunUrl1 ? defaultValue.gunUrl1+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={690}
                                        //           height={400}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="枪械搭配图2"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunUrl2', {
                                    rules: [{required: true, message: '请上传枪械搭配图2', pattern: /^\S+$/}],
                                    initialValue: defaultValue.gunUrl2 ? defaultValue.gunUrl2+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={690}
                                        //           height={400}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="枪械名称1"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunName1', {
                                    rules: [{required: true, message: '请填写枪械名称1'}],
                                    initialValue: defaultValue.gunName1
                                })(
                                    <Input placeholder="请填写枪械名称1"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="枪械名称2"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunName2', {
                                    rules: [{required: true, message: '请填写枪械名称2'}],
                                    initialValue:defaultValue.gunName2
                                })(
                                    <Input placeholder="请填写枪械名称2"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label="指法键位图"
                                labelCol={{span: 5}}
                                wrapperCol={{span: 19}}
                            >
                                {getFieldDecorator('fingeringKeysUrl', {
                                    rules: [{required: true, message: '请上传指法键位图', pattern: /^\S+$/}],
                                    // initialValue: defaultValue.imageUrlList || [],
                                    initialValue: defaultValue.fingeringKeysUrl ? defaultValue.fingeringKeysUrl+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    // double={true}
                                    <PicturesWall style={uploadBox} 
                                        //图片格式限制
                                        //           width={690}
                                        //           height={220}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="指法指数"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('fingeringKeys', {
                                    rules: [{required: true, message: '请填写指法指数'}],
                                    initialValue:defaultValue.fingeringKeys
                                })(
                                    <Input placeholder="请填写指法指数"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        );
    }
}

AddPlayerModel.propTypes = propTypes;
AddPlayerModel.defaultProps = defaultProps;
const mapStateToProps = (state => {
        return {
            ...state.ProfessionalPlayer
        }
    }
);

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(AddPlayerModel))));

