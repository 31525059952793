export const NoticeGroupType = {
    SYSTEM: {
        label: '系统通知',
        value: 'SYSTEM'
    },
    COMPETITION: {
        label: '赛事通知',
        value: 'COMPETITION'
    }, CIRCLE: {
        label: '圈子动态',
        value: 'CIRCLE'
    }, TRIAL: {
        label: '试训通知',
        value: 'TRIAL'
    }, OTHER: {
        label: '其他',
        value: 'OTHER'
    }
};

export const sendStatus = {
    SUCCESS: {
        label: '成功',
        value:'SUCCESS',
    },
    FAIL: {
        label: '失败',
        value:'FAIL'
    },
};


