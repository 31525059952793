import React, { Component } from 'react';
import {
    Form, Input, Button,
} from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class CircleForm extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    render() {
        console.log(this.props.name)
        let {
            name
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <FormItem
                    label="圈子名"
                    {...formItemLayout}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请填写圈子名', pattern: /^\S+$/ }],
                        initialValue: name,
                    })(
                        <Input placeholder="请填写圈子名" />,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(CircleForm);
