import React, { Component } from 'react';
import {
    Form, Button, Select,InputNumber
} from 'antd';


const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class AdditionSsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            games:[],
        };
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmit } = this.props;
                onSubmit && onSubmit(values.additionalSocialStatus,values.game);
            }
        });
    }

    componentDidMount() {
        if (this.props.userResumes.length!==0){
            this.setState({
                additionalSocialStatus:this.props.userResumes.socialStatus
            })
        }

    }

    render() {
        let {additionalSocialStatus } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Form layout="horizontal">
                <Form.Item
                    label="自定义身价"
                    {...formItemLayout}
                >
                    {getFieldDecorator('additionalSocialStatus', {
                        initialValue: additionalSocialStatus,
                    })(
                        <InputNumber/>,
                    )}
                </Form.Item>
                <Form.Item
                    label="游戏类型"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...formItemLayout}
                >
                    {getFieldDecorator('game', {
                       // initialValue: games.filter((item) => item.code == value.game).length > 0 ? games.filter((item) => item.code == value.game)[0].code : "",
                        rules: [{required: true, message: '请选择游戏类型!'}],
                    })(
                        <Select placeholder="请选择游戏类型">
                            <Option value="HPJY">和平精英</Option>
                            <Option value="WZRY">王者荣耀</Option>
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create()(AdditionSsForm);
