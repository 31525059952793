import createAsyncAction from 'utils/createAsyncAction';
import MedalRequest from 'request/MedalRequest';
import {
    GET_MEDAL_THEME_LIST,
    GET_MEDAL_THEME_DATA,
    UPDATE_MEDAL_THEME_DATA,
    UPDATE_MEDAL_THEME_SHELF,
    GET_SUB_MEDAL_LIST,
    GET_SUB_MEDAL_DATA,
    UPDATE_SUB_MEDAL_DATA,
    GET_SUB_MEDAL_LOG_LIST,
    UPDATE_SUB_MEDAL_SEND,
    UPDATE_SUB_MEDAL_RECYCLE, ADD_MEDAL_THEME_DATA,
} from './actionTypes';

const getMedalThemeList = (params) => (
    createAsyncAction(GET_MEDAL_THEME_LIST, () => (MedalRequest.getMedalThemeList(params)))
);

const getMedalThemeData = (themeMedalId) => (
    createAsyncAction(GET_MEDAL_THEME_DATA, () => (MedalRequest.getMedalThemeData(themeMedalId)))
);

const updateMedalThemeData = (themeMedalId, params) => (
    createAsyncAction(UPDATE_MEDAL_THEME_DATA, () => (MedalRequest.updateMedalThemeData(themeMedalId, params)))
);

const addMedalThemeData = (themeMedalId, params) => (
    createAsyncAction(ADD_MEDAL_THEME_DATA, () => (MedalRequest.addMedalThemeData(params)))
);

const updateMedalThemeShelf = (themeMedalId, params) => (
    createAsyncAction(UPDATE_MEDAL_THEME_SHELF, () => (MedalRequest.updateMedalThemeShelf(themeMedalId, params)))
);

const getSubMedalList = (themeMedalId, params) => (
    createAsyncAction(GET_SUB_MEDAL_LIST, () => (MedalRequest.getSubMedalList(themeMedalId, params)))
);

const getSubMedalData = (childMedalId) => (
    createAsyncAction(GET_SUB_MEDAL_DATA, () => (MedalRequest.getSubMedalData(childMedalId)))
);

const updateSubMedalData = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_DATA, () => (MedalRequest.updateSubMedalData(childMedalId, params)))
);

const addSubMedalData = (params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_DATA, () => (MedalRequest.addSubMedalData(params)))
);

const getSubMedalLogList = (params) => (
    createAsyncAction(GET_SUB_MEDAL_LOG_LIST, () => (MedalRequest.getSubMedalLogList(params)))
);

const updateSubMedalSend = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_SEND, () => (MedalRequest.updateSubMedalSend(childMedalId, params)))
);

const updateSubMedalRecycle = (childMedalId, params) => (
    createAsyncAction(UPDATE_SUB_MEDAL_RECYCLE, () => (MedalRequest.updateSubMedalRecycle(childMedalId, params)))
);

export {
    getMedalThemeList, getMedalThemeData, updateMedalThemeData,addMedalThemeData, updateMedalThemeShelf,
    getSubMedalList, getSubMedalData, updateSubMedalData, addSubMedalData,getSubMedalLogList, updateSubMedalSend,
    updateSubMedalRecycle
};
