import React, {Component} from "react";
import {Card,Row,Col, message, Modal, notification, Table} from 'antd';
import { getSignboard,addSignboard,delSignboard,checkSignboard,setSignboardPriority,setSignboardTop} from '../../../../src/modules/club/action';
import {connect} from "react-redux";
import Button from "antd/lib/button";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import ListingFilter from "../components/ListingFilter";
import AddListingModal from "../components/AddListingModal";
import CheckListingModal from "../components/CheckListingModal";
import SetListingWeightModal from "../components/SetListingWeightModal";

const {confirm} = Modal;

class ListingManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddListingMemberModel:false,
            isShowCheckListingModel:false,
            isShowSetListingWeightModel:false,
            pageNo: 1,
            pageSize: 10,
            //clubName: null,
            userName: null,
            userId: null,
            state: null,
            currentId:'',
        };
    }


    componentDidMount() {
        this.onGetData();
    }


    renderState = (text) => {

        if (text === 'FAIL') {
            return (
                <div color="blue">已驳回</div>
            )
        } else if (text === 'SUCCESS') {
            return (
                <div color="blue">通过</div>
            )
        } else if (text === 'REVIEW') {
            return (
                <div color="blue">未审核</div>
            )
        }
    }

    addListingMember = async (params)=>{
        const {isShowAddListingMemberModel} =this.state;
        let {addSignboard} = this.props;
        await addSignboard({...params,game:'HPJY'});
        await this.onGetData();
        message.success('新增自荐成功');
        this.setState({isShowAddListingMemberModel:!isShowAddListingMemberModel})
    }

    onCheckListing = (id)=>{
        this.setState({isShowCheckListingModel:true,currentId:id})
    }

    checkListing = async (params)=>{
        const {isShowCheckListingModel,currentId} =this.state;
        let {checkSignboard} = this.props;
        await checkSignboard(currentId,params);
        await this.onGetData();
        message.success('审核成功');
        this.setState({isShowCheckListingModel:!isShowCheckListingModel})
    }

    setListingWeight = async (params)=>{
        const {isShowSetListingWeightModel,currentId} =this.state;
        let {setSignboardPriority} = this.props;
        await setSignboardPriority(currentId,params);
        await this.onGetData();
        message.success('推荐成功');
        this.setState({isShowSetListingWeightModel:!isShowSetListingWeightModel})
    }

    confirmSetTop = (id,params) => {
        let _this = this;
        confirm({
            content: params.top?'确认不要置顶自荐页吗?':'确认要置顶自荐页吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onSetTop(id,{top:!params.top})
            },
            onCancel() {
            },

        });
    }

    onSetTop = async (id,params)=>{
        let {setSignboardTop} = this.props;
        await setSignboardTop(id,params);
        await this.onGetData();
        message.success('操作成功');
    }

    confirmDel = (id) => {
        let _this = this;
        confirm({
            content: '确认要删除该选手的自荐计划吗？',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onDel(id)
            },
            onCancel() {
            },

        });
    }

    onDel = async (id)=>{
        let {delSignboard} = this.props;
        await delSignboard(id)
        await this.onGetData();
        message.success('删除成功');
    }

    renderTableTitle = () => {
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button type="primary" onClick={() => this.setState({isShowAddListingMemberModel: true})}>新增</Button>
                </Col>
            </Row>
        )
    }

    render() {
        const {signboardList,gameList} = this.props;
        console.log("signboardList", signboardList)
        const {pageNo, pageSize,isShowAddListingMemberModel,isShowCheckListingModel,isShowSetListingWeightModel} = this.state;

        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <ListingFilter onCommit={this.onFilterListingEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'userName',
                                    key: 'userName',
                                    align: 'center',
                                },
                                {
                                    title: '推荐优先级',
                                    dataIndex: 'priority',
                                    key: 'priority',
                                    align: 'center',
                                },
                                {
                                    title: '俱乐部',
                                    dataIndex: 'clubName',
                                    key: 'clubName',
                                    align: 'center',
                                },
                                {
                                    title: '游戏类型',
                                    dataIndex: 'game',
                                    key: 'game',
                                    align: 'center',
                                    render: (row, text) => (
                                        <span>{gameList.filter((item) => item.code == text.game).length > 0 ? gameList.filter((item) => item.code == text.game)[0].gameName : ""}</span>
                                    )
                                },
                                {
                                    title: '状态',
                                    dataIndex: 'state',
                                    key: 'state',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            {this.renderState(text.state)}
                                        </div>
                                    )
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (row, text) => (
                                        <div>
                                            <Button type={"primary" }style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                                this.onCheckListing(text.id)
                                            }} color="blue">审核</Button>
                                            <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                                this.setState({isShowSetListingWeightModel:true,currentId:text.id})
                                            }} color="blue">配置推荐权重</Button>
                                            <Button type={text.top?"dashed":"primary"} style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                                this.confirmSetTop(text.id,{top:text.top})
                                            }} color="blue">{text.top?'已置顶自荐页':'是否置顶自荐页'}</Button>
                                            <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                                this.confirmDel(text.id)
                                            }} color="red">删除</Button>
                                        </div>

                                    ),
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={signboardList ? signboardList.data : []}
                        bordered
                        //loading={loading}
                        pagination={{
                            total: signboardList ? signboardList.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />

                    {isShowAddListingMemberModel && <AddListingModal
                        onSuccess={this.addListingMember}
                        visible={isShowAddListingMemberModel}
                        onClose={() => {
                            this.setState({
                                isShowAddListingMemberModel: false,
                            });
                        }}
                        />}
                    
                    {isShowCheckListingModel && <CheckListingModal
                        onSuccess={this.checkListing}
                        visible={isShowCheckListingModel}
                        onClose={() => {
                            this.setState({
                                isShowCheckListingModel: false,
                            });
                        }}
                        />}
                    
                    {isShowSetListingWeightModel && <SetListingWeightModal
                        onSuccess={this.setListingWeight}
                        visible={isShowSetListingWeightModel}
                        onClose={() => {
                            this.setState({
                                isShowSetListingWeightModel: false,
                            });
                        }}
                        />}
                </Card>
            </div>
        );
    }

    //条件查找试训方法
    onFilterListingEvent = async ( params) => {
        let {getSignboard} = this.props;
        this.setState({
            pageNo: 1,
            pageSize: 10,
            ...params
        }, () => {
            getSignboard({page: 1, size: 10, ...params})
        })
    }

    onGetData = () => {
        const {pageNo, pageSize} = this.state;
        let {getSignboard} = this.props;
        console.log("pageNo", this.state)
        getSignboard({page:pageNo, size:pageSize});
    }


    //翻页
    onPageChangeEvent = (current, pageSize) => {
        const {getSignboard} = this.props;
        const { userName, userId, state} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        }, () => {
            getSignboard({page: current, size: pageSize, userName, userId, state})
        });
    }

    onPageOnShowSizeChange = (current, pageSize) => {
    }

    //

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        //console.log("state",state)
        return {
            ...state.viewClub,

        }
    }
);


const mapDispatchToProps = {
    getSignboard,
    addSignboard,
    delSignboard,
    checkSignboard,
    setSignboardPriority,
    setSignboardTop
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ListingManagement)));
