export default {
    CREATE_ADMIN: {
        label: '创建管理员',
        value:'CREATE_ADMIN',
    },
    UNLOCK_ADMIN: {
        label: '解除锁定',
        value: 'UNLOCK_ADMIN'
    },
};
