export const GET_BANNERS = 'GET_BANNERS';// 获取轮播图列表

export const GET_BANNER = 'GET_BANNER';// 获取轮播图信息

export const UPDATE_BANNER = 'UPDATE_BANNER';//更新轮播图

export const DELETE_BANNER = 'DELETE_BANNER';//删除轮播图

export const CREATE_BANNER = 'CREATE_BANNER';// 创建轮播图

export const CHANGE_BANNER_STATUS = 'CHANGE_BANNER_STATUS';//修改轮播图状态

export const GET_INNER_JUMP_TYPE = 'GET_INNER_JUMP_TYPE';//获取所有内部跳转类型

export const ADD_VALUATION_IMAGE = 'ADD_VALUATION_IMAGE';//

export const GET_VALUATION_IMAGE = 'GET_VALUATION_IMAGE';

export const UPDATE_VALUATION_IMAGE = 'UPDATE_VALUATION_IMAGE';

