import React, {Component} from "react";
import {Button, Form, Input,message} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getUserTeamListByQQ} from "../../../modules/certification/action";
const FormItem = Form.Item;

class SearchTeamByQQFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {userTeamsData:[],isShowUserTeamsData: false}
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(values.qq){
                    _self.getUserTeamListByQQ(values.qq);
                }else{
                    message.warning('请输入用户展示ID');
                }
            }
        });
    }

    getUserTeamListByQQ = async (values) =>{
        const {getUserTeamListByQQ} = this.props;
        await getUserTeamListByQQ(values);
        await this.setUserTeamsData();

    }

    setUserTeamsData = () =>{
        const {userTeamsByqq} = this.props;
        this.setState({
            userTeamsData: userTeamsByqq.map(function (v) {
                return v.gameType+"-"+v.teamShowId
            }).join(','),
            isShowUserTeamsData: true
        })
    }

    onChangeGamerShowId = () => {
        this.setState({
            userTeamsData: [],
            isShowUserTeamsData: false
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {isShowUserTeamsData,userTeamsData} = this.state;
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline" style={{display: 'inline'}}>
                    <FormItem label="QQ">
                        {getFieldDecorator('qq', {
                                rules: [{pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}],
                            }
                        )(
                            <Input style={{width: '150px'}} allowClear onChange={this.onChangeGamerShowId}/>
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            查询
                        </Button>
                    </FormItem>
                </Form>
                {isShowUserTeamsData ? <div style={{display: 'inline', lineHeight: '41px'}}>战队展示ID : {userTeamsData.length>0 ? userTeamsData : '无'}</div> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification,
    }
};
const mapDispatchToProps = ({getUserTeamListByQQ});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(SearchTeamByQQFilter)));