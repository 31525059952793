import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import { operateType } from "../../modules/admin-operation-log/enums";

const FormItem = Form.Item;
const {RangePicker} = DatePicker;



class AdminOperationLogFilter extends Component {

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="管理员名称">
                    {getFieldDecorator('adminName', {
                            initialValue: ''
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="用户名">
                    {getFieldDecorator('userName', {
                            initialValue: ''

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="用户展示ID">
                    {getFieldDecorator('userExhibitionId', {
                            initialValue: ''

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear/>
                    )}
                </FormItem>
                <FormItem label="操作类型">
                    {
                        getFieldDecorator('type', {initialValue:'ALL'})(
                            <Select style={{width: '120px'}}>
                                <Select.Option value="ALL" >所有</Select.Option>
                                {
                                    Object.keys(operateType).map(key => (
                                        <Select.Option value={operateType[key].value} >{operateType[key].label}</Select.Option>
                                    ))
                                }
                            </Select>
                        )
                    }
                </FormItem>
                <Form.Item label='操作时间' >
                    {
                        getFieldDecorator('operateTime', {initialValue:null})(
                            <RangePicker/>
                        )
                    }
                </Form.Item>
                <Button
                    type='primary'
                    icon="search"
                    onClick={this.onSearch}
                >
                    搜索
                </Button>

            </Form>
        );
    }

    /**
     * 搜索事件
     */
    onSearch = ()=>{
        this.props.form.validateFields((err, values) => {
            if(!err){
                const { onSearch } = this.props;
                onSearch && onSearch({...values,
                    type:values.type == 'ALL' ? null : values.type});
            }
        })
    }
}


export default Form.create()(AdminOperationLogFilter);
