// import React from 'react';
import ReactDOM from 'react-dom';
// import App from 'App';


import historyInstance from 'app/init/historyInstance';

import 'antd/dist/antd.css';
import 'index.css';
import { createStore, createApp, initClient } from 'app/index';

const { store, history } = createStore(historyInstance, {});
const application = createApp(store, history);
initClient(store.dispatch);

ReactDOM.render(application, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
