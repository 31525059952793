import createAsyncAction from 'utils/createAsyncAction';
import GiftRequest from 'request/GiftRequest';
import {
    GET_GIFT_LIST,
    CREATE_GIFT,
    UPDATE_GIFT,
    DELETE_GIFT,
    GET_GIFT_CDK_LIST,
    DELETE_GIFT_CDK,
    GET_GIFT_ORDER_LIST,
    UPDATE_GIFT_ORDER_ADDRESS,
    UPDATE_GIFT_ORDER_SHIP
} from './actionTypes';

const getGiftList = (params) => (
    createAsyncAction(GET_GIFT_LIST, () => (GiftRequest.getGiftList(params)))
);

const createGift = (params) => (
    createAsyncAction(CREATE_GIFT, () => (GiftRequest.createGift(params)))
);

const updateGift = (giftId, params) => (
    createAsyncAction(UPDATE_GIFT, () => (GiftRequest.updateGift(giftId, params)))
);

const deleteGift = (giftId) => (
    createAsyncAction(DELETE_GIFT, () => (GiftRequest.deleteGift(giftId)))
);

const getGiftCDKList = (giftId, params) => (
    createAsyncAction(GET_GIFT_CDK_LIST, () => (GiftRequest.getGiftCDKList(giftId, params)))
);

const deleteGiftCDK = (giftCdkId) => (
    createAsyncAction(DELETE_GIFT_CDK, () => (GiftRequest.deleteGiftCDK(giftCdkId)))
);

const getGiftOrderList = (params) => (
    createAsyncAction(GET_GIFT_ORDER_LIST, () => (GiftRequest.getGiftOrderList(params)))
);

const updateGiftOrderAddress = (orderId, params) => (
    createAsyncAction(UPDATE_GIFT_ORDER_ADDRESS, () => (GiftRequest.updateGiftOrderAddress(orderId, params)))
);

const updateGiftOrderShip = (orderId, params) => (
    createAsyncAction(UPDATE_GIFT_ORDER_SHIP, () => (GiftRequest.updateGiftOrderShip(orderId, params)))
);

export {
    getGiftList, createGift, updateGift, deleteGift, getGiftCDKList, deleteGiftCDK, getGiftOrderList, updateGiftOrderAddress, updateGiftOrderShip
};
