import api from '../utils/api';

class ConfigRequest {

    static getConfigs(page, size) {
        console.log(page, size)
        return api.get('/admin/configuration', {page, size});
    }

    static updateConfig(key, {confValueType, confValue, confDesc, security}) {
        return api.put(`/admin/configuration/${key}`, {confValueType, confValue, confDesc, security});
    }

    static getConfig(key) {
        return api.get(`/admin/configuration/${key}`);
    }

    static deleteConfig(key) {
        return api.delete(`/admin/configuration/${key}`);
    }

    static addConfig({confKey, confValueType, confValue, confDesc, security}) {
        return api.post(`/admin/configuration`, {confKey, confValueType, confValue, confDesc, security})
    }

}

export default ConfigRequest;