
export const GET_VIEW_PERMISSION_TREE = 'GET_VIEW_PERMISSION_TREE';// 获取权限树

export const UPDATE_VIEW_PERMISSION_TREE = 'UPDATE_VIEW_PERMISSION_TREE'; // 更新权限树

export const GET_VIEW_PERMISSION_INFO = 'GET_VIEW_PERMISSION_INFO'; // 获取权限详情

export const GET_VIEW_PERMISSION_LIST = 'GET_VIEW_PERMISSION_LIST'; // 获取权限列表

export const CREATE_VIEW_PERMISSION = 'CREATE_VIEW_PERMISSION';// 创建权限

export const UPDATE_VIEW_PERMISSION = 'UPDATE_VIEW_PERMISSION';// 更新权限

export const DELETE_VIEW_PERMISSION = 'DELETE_VIEW_PERMISSION';// 删除权限
