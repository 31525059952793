import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Popconfirm, Row, Table} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getCircle, getCircles, updateCircle,deleteCircle,createCircle} from '../../modules/circle/action';
// import util from 'util/util';
import { operationTypeValue } from '../../modules/circle/circleEnum';
import CircleForm from "./CircleForm";

class CircleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            isShowEditAdminModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
            currentAdminId: null,
        };
    }


    componentDidMount() {

        const {pageNo, pageSize} = this.state;
        let {getCircles} = this.props;
        getCircles(pageNo, pageSize);
    }

    onCloseEditCircleModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }

    onOpenEditCircleModalEvent = (circleId,type) => {
        const {getCircle} = this.props;
        if (type===operationTypeValue.UPDATE){
            getCircle(circleId);
            this.setState({
                operationType : operationTypeValue.UPDATE
            })

        }
        if (type===operationTypeValue.INCREASE){
            this.setState({
                operationType : type
            })
        }
        this.setState({
            isShowEditAdminModal: true,
        });
    };


    onCloseEditCircleModalEvent = () => {
        this.setState({
            isShowEditAdminModal: false,
        });
    }


    //修改圈子名的方法
    onEditCircleEvent = async ({name}) => {
        const {page, size} = this.state;
        const {updateCircle, getCircles, circleInfo} = this.props;
        const {id} = circleInfo;
        try {
            await updateCircle(id, {
                name
            });
            notification.success({message: '更新成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getCircles(page, size);
        } catch (e) {
            console.log(e);
        }
    }

    //添加圈子名的方法
    onAddCircleEvent = async ({name}) => {
        const {page, size} = this.state;
        const {createCircle, getCircles} = this.props;
        try {
            await createCircle(name);
            notification.success({message: '添加成功'});
            this.setState({
                isShowEditAdminModal: false,
            });

            getCircles(page, size);
        } catch (e) {
            console.log(e);
        }
    }

    //删除圈子的方法
    onDeleteCircleEvent = async (adminId) => {
        const { deleteCircle, getCircles } = this.props;
        const { page, size } = this.state;
        try {
            await deleteCircle(adminId);
            notification.success({ message: '删除成功' });

            getCircles(page, size);
        } catch (e) {
            console.log(e);
        }
    }


    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col><Button type="primary" onClick={() => this.onOpenEditCircleModalEvent(null,operationTypeValue.INCREASE)}>新增</Button></Col>
        </Row>
    )


    render() {
        const {
            circles, circleInfo, loading
        } = this.props;
        const {
            isShowEditAdminModal, pageNo, pageSize,operationType
        } = this.state;
        return (
            <Card>
                <Table
                    columns={
                        [
                            {
                                title: '圈子名',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                            },
                            {
                                title: '圈子创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                            },
                            {
                                title: '圈子更新时间',
                                dataIndex: 'updateTime',
                                key: 'updateTime',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                key: 'operate',
                                align: 'center',
                                render: (text, {id}) => (
                                    <div>
                                        <Button type="primary" onClick={() => this.onOpenEditCircleModalEvent(id,operationTypeValue.UPDATE)} color="blue">修改</Button>
                                        &nbsp;
                                        <Popconfirm title={`确定要删除这个圈子吗?`}
                                                    onConfirm={() => this.onDeleteCircleEvent(id)}
                                                    okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                            <Button type="danger" >删除</Button>
                                        </Popconfirm>
                                    </div>
                                ),
                            },
                        ]
                    }

                    title={
                        this.renderTableTitle
                    }
                    dataSource={circles.data ? circles.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: circles ? circles.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
                <Modal
                    title = {operationType === operationTypeValue.UPDATE ? "编辑圈子" : "添加圈子"}
                    visible={isShowEditAdminModal}
                    footer={null}
                    destroyOnClose
                    onCancel={this.onCloseEditCircleModalEvent}
                >
                    {operationType === operationTypeValue.UPDATE ? <CircleForm {...circleInfo} onSubmitEvent={this.onEditCircleEvent}/> : <CircleForm onSubmitEvent={this.onAddCircleEvent}/>}
                </Modal>
            </Card>
        );
    }

    // --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.viewCircle)
        return {
            ...state.viewCircle
        }

    }

);

const mapDispatchToProps = {
    getCircles,
    updateCircle,
    getCircle,
    deleteCircle,
    createCircle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CircleManagement)));
