import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Icon,
    InputNumber,
    Menu,
    message,
    Modal,
    Popconfirm,
    Row,
    Table
} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    addJoinTeam,
    checkSignUp,
    deleteSignUp,
    getAddTeams,
    getJoinTeam,
    updateTrainingSignUpPosition
} from "../../modules/training/action";
import TrainingJoinFilter from "./components/TrainingJoinFilter";
import "./index.scss"
import TrainingJoinModal from "./components/TrainingJoinModal";
import TrainingPushModal from "./components/TrainingPushModal";

const {confirm} = Modal;

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingJoinManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 25,
            exhibitionId: '',
            username: '',
            memberType: '',
            param: {},
            modalVisible: false,
            teamIdArr: [],
            pushModalVisible: false,
            currentCheckItem: [],
            disabled: false,
            indexVisible: false,
            positionNum: ''
        };
    }

    componentWillMount() {
        this.requestTeam();
        this.requestJoinTeam();
    }

    onSubmit = (values) => {
        let param = {}
        let {page, size} = this.state;
        Object.keys(values).map(item => {
            if (values[item]) {
                param[item] = values[item];
            }
            return null;
        });
        this.setState({param, loading: true})
        param["page"] = page;
        param["size"] = size;
        this.requestTeam(param)
    }

    requestTeam = (param) => {
        let {page, size} = this.state;
        let {getAddTeams} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        console.log(trainingId)
        console.log(id)
        if (param) {
            getAddTeams({trainingId: id, page, size, ...param})
        } else {
            getAddTeams({trainingId: id, page, size})
        }
    }

    confirm = (signUpId, type) => {
        let _this = this;
        let {currentCheckItem} = this.state;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        if (type === 0) {
            confirm({
                content: '确认删除吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.deleteSignUp([signUpId])
                },
                onCancel() {
                }
            });
        } else {
            confirm({
                content: `确认批量删除${currentCheckItem.length}条记录吗?`,
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    let signUpIds = currentCheckItem.map(e => e + "_" + id);
                    _this.deleteSignUp(signUpIds);
                },
                onCancel() {
                },

            });
        }
    }

    onPageChangeEvent = async (current, pageSize) => {
        this.state.page = current;
        this.requestTeam();
        this.setState({
            page: current,
            size: pageSize,
        });
    }

    requestJoinTeam = () => {
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        let {getJoinTeam} = this.props;
        getJoinTeam({trainingId: id, state: "PASS"});
    }

    commitJoinTeam = (values) => {
        let {addJoinTeam} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        let teamIds = values.teamIds.replace(/，/g, ',');
        addJoinTeam({trainingId: id, teamIds: teamIds.split(",")})
            .then(res => {
                message.success("操作成功")
                this.setState({modalVisible: false})
                this.requestTeam()
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleClick = (e, trainingId) => {
        let {currentCheckItem} = this.state;
        if (e.key == 1) {
            this.checkSignUp(trainingId, {teamId: currentCheckItem, type: "PASS"});
        } else if (e.key == 2) {
            this.checkSignUp(trainingId, {teamId: currentCheckItem, type: "FAIL"})
        } else {
            this.confirm("", 1)
        }
    }


    renderTableTitle = () => {
        let {trainingId, trainingName, brandId, teamTotal} = this.props.match.params;
        let {joinTeam, brandData} = this.props;
        let data = brandData.data && brandData.data.filter(item => item.id == window.atob(brandId));
        let brandName = data && data.length > 0 ? data[0].name : ""
        return (
            <Row>
                <Col span={18}>
                    <div style={{width: "100%", height: "40px", lineHeight: "40px", marginTop: "10px"}}>
                        赛事名：<span className="titleValue">{trainingName}</span>
                        赛事ID：<span className="titleValue">{window.atob(trainingId)}</span>
                        赛事类型：<span className="titleValue">{brandName}</span>
                        已参与战队数：<span className="titleValue">{joinTeam.total}</span>
                        {teamTotal < joinTeam.total ? <span className={"titleValue"}>提示:  参赛战队数超出预定报名数</span> : null }
                    </div>
                </Col>
                <Col span={6}>
                    <div style={{
                        width: "100%",
                        height: "40px",
                        lineHeight: "40px",
                        marginTop: "10px",
                        textAlign: "right"
                    }}>
                        <Button type="primary" htmlType="submit" style={{marginRight: "30px"}} onClick={() => {
                            this.setState({modalVisible: true})
                        }}>
                            添加新战队
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={() => {
                            console.log(this.props)
                            let {trainingId} = this.props.match.params;
                            let {getJoinTeam} = this.props;
                            getJoinTeam({trainingId: window.atob(trainingId),state:"PASS", size:10000, page:1});
                            this.setState({pushModalVisible: true})
                        }}>
                            推送
                        </Button>
                    </div>
                </Col>
            </Row>

        )
    }

    render() {
        let {teamInfo, joinTeam} = this.props;
        const {page, size, modalVisible, pushModalVisible} = this.state;
        return (
            <div>
                <Card style={{marginBottom: '30px'}}>
                    <TrainingJoinFilter onCommit={this.onSubmit}/>
                </Card>

                <Card>
                    <Table
                        title={this.renderTableTitle}
                        dataSource={teamInfo.data ? teamInfo.data : []}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={{
                            total: teamInfo.total ? teamInfo.total : 0,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                        }}
                    />
                </Card>
                {modalVisible ?
                    <TrainingJoinModal {...this.props} visible={modalVisible} onSuccess={this.commitJoinTeam} onClose={() => {
                        this.setState({modalVisible: false})
                    }}/> : null}
                {pushModalVisible ? <TrainingPushModal {...this.props} data={joinTeam.data ? joinTeam.data : []} visible={pushModalVisible} onClose={() => {
                    this.setState({pushModalVisible: false})
                }}/> : null}

            </div>
        );
    }

    getMenu = (trainingId) => {
        let {currentCheckItem, disabled} = this.state;
        return (
            <Menu onClick={(e) => this.handleClick(e, trainingId)}>
                <Menu.Item key="1">
                    <Button type="primary" disabled={currentCheckItem.length === 0 || disabled}>
                        <Icon type="check"/>
                        通过
                    </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="dashed" disabled={currentCheckItem.length === 0 || disabled}>
                        <Icon type="close"/>
                        替补
                    </Button>
                </Menu.Item>
                {/*<Menu.Item key="3">*/}
                    {/*<Button type="danger" disabled={currentCheckItem.length === 0 || disabled}>*/}
                        {/*<Icon type="delete"/>*/}
                        {/*移除*/}
                    {/*</Button>*/}
                {/*</Menu.Item>*/}
            </Menu>
        );
    }

    checkSignUp = async (trainingId, data) => {
        let {checkSignUp} = this.props;
        this.setState({
            disabled: true
        });
        checkSignUp({trainingId, teamIds: data.teamId, signUpStatus: data.type})
            .then(res => {
                message.success("操作成功")
                this.setState({currentCheckItem: []})
                let {param} = this.state;
                this.requestTeam(param)
                this.requestJoinTeam();
                this.setState({
                    disabled: false
                });
            })
            .catch(e => {
                this.setState({
                    disabled: false
                });
            });
    }

    deleteSignUp = async (signUpId) => {
        console.log(signUpId)
        let {deleteSignUp} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        this.setState({
            disabled: true
        });
        deleteSignUp({signUpIds: signUpId, trainingId: id})
            .then(res => {
                message.success("操作成功")
                let {param} = this.state;
                this.setState({
                    currentCheckItem: []
                })
                this.requestTeam(param)
                this.requestJoinTeam();
                this.setState({
                    disabled: false
                });
            })
            .catch(e => {
                this.setState({
                    disabled: false
                });
            });
    }

    onChangeCheck = (e) => {
        let {teamInfo} = this.props;
        let {currentCheckItem} = this.state;
        if (e.target.checked) {
            currentCheckItem = teamInfo.data.map(v => v.id.split("_")[0]);
        } else {
            currentCheckItem = []
        }
        this.setState({currentCheckItem})
    }

    onCheckValue = (e, text) => {
        let {currentCheckItem} = this.state;
        let index = currentCheckItem.indexOf(text.id.split("_")[0])
        if (e.target.checked && index === -1) {
            currentCheckItem.push(text.id.split("_")[0]);
        } else {
            currentCheckItem.splice(index, 1);
        }
        this.setState({
            currentCheckItem
        });
    }

    onChange = (id, e) => {
        let {teamIdArr} = this.state;
        let index = teamIdArr.indexOf(id);
        if (index !== -1 && e.target.checked) {
            teamIdArr.push(id);
        } else {
            teamIdArr.splice(index, 1);
        }
        this.setState({teamIdArr});
    }


    clickPosition = async (id) => {
        if (!this.state.positionNum) {
            message.error("位置不能为空！！！")
        } else {
            let {updateTrainingSignUpPosition} = this.props;
            console.log(this.state.positionNum)
            await updateTrainingSignUpPosition({
                teamId: id.split("_")[0],
                trainingId: id.split("_")[1],
                position: parseInt(this.state.positionNum)
            });
            await this.requestTeam();
            message.success('修改成功');
        }
    }

    getTableColumns = () => {
        let date = this.props.match.params.gameStartDate;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        let {currentCheckItem, disabled, indexVisible} = this.state;
        let {teamInfo} = this.props;
        return (
            [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    key: 'id',
                }, {
                title: '战队ID/名',
                dataIndex: 'name',
                align: 'center',
                key: 'name',
                render: (row, text) => {
                    return text.showId + "/" + text.name
                }

            },{
                title: '次序',
                dataIndex: 'randIndex',
                align: 'center',
                key: 'randIndex'

            }, {
                title: '比赛日期',
                dataIndex: 'date',
                align: 'center',
                key: 'date',
                render: (row, text) => {
                    return window.atob(date)
                }

            }, {
                title: '类型',
                dataIndex: 'state',
                align: 'center',
                key: 'state',
                render: (row, text) => {
                    if (row === "UNAUDITED") {
                        return "未审核"
                    } else if (row === "PASS") {
                        return "通过"
                    } else {
                        return "替补"
                    }
                }
            }, {
                title: '参与时间',
                dataIndex: 'signUpTime',
                align: 'center',
                key: 'signUpTime'
            }, {
                title: '操作时间',
                dataIndex: 'updateTime',
                align: 'center',
                key: 'updateTime'
            }, {
                title: '位置',
                dataIndex: 'position',
                align: 'center',
                key: 'position',
                render: (row, text) => {
                    // if(row){
                    //     return row+"号位"
                    // }
                    // return ""
                    if (row) {
                        return <Popconfirm
                            title={
                                indexVisible ?
                                    <div><span style={{color: "red"}}>请输入号位</span><br/><InputNumber
                                        style={{width: 260}} placeholder={"请输入号位"}
                                        onChange={(e) => {
                                            this.setState({
                                                positionNum: e
                                            })
                                        }}/></div> : null}
                            okType="primary"
                            icon={null}
                            onConfirm={() => {
                                this.clickPosition(text.id)
                                this.setState({indexVisible: false})
                            }}
                            onCancel={() => {
                                this.setState({indexVisible: false})
                            }}
                            okText="提交"
                            cancelText="取消"
                        >
                            <Button style={{marginLeft: 10, marginBottom: 10}} onClick={() => {
                                this.setState({indexVisible: true})
                            }}>{row + '号位'}</Button>
                        </Popconfirm>
                    }
                    return ""

                }
            },
                {
                    title: () => <div><span style={{marginRight: "20px"}}>操作</span><Dropdown
                        overlay={this.getMenu(id)}><Button>批量处理<Icon type="down"/></Button></Dropdown>
                        <span style={{marginLeft: "20px"}}>已选中：<span
                            style={{color: "#FF0000"}}>{currentCheckItem.length}</span>个</span>
                        <span style={{marginLeft: "10px"}}>（<Checkbox
                            checked={currentCheckItem.length === (teamInfo.data && teamInfo.data.length) && currentCheckItem.length > 0}
                            onChange={this.onChangeCheck}>全选</Checkbox>）</span>
                    </div>,
                    key: '',
                    dataIndex: "",
                    fixed: 'right',
                    width: 460,
                    render: (text, index) => {
                        return (
                            <div key={text.showId}>
                                <Button disabled={index.state === "PASS" || disabled}
                                        style={{marginLeft: 10, marginBottom: 10}}
                                        type="primary" onClick={() => {
                                    this.checkSignUp(id, {teamId: [parseInt(index.id.split("_")[0])], type: "PASS"});
                                }}>通过</Button>
                                <Button disabled={index.state === "FAIL" || disabled}
                                        style={{marginLeft: 10, marginBottom: 10}}
                                        type={"dashed"} onClick={() => {
                                    this.checkSignUp(id, {teamId: [parseInt(index.id.split("_")[0])], type: "FAIL"});
                                }}>替补</Button>
                                <Button style={{marginLeft: 10, marginBottom: 10}}
                                        disabled={ disabled} type="danger"
                                        onClick={() => {
                                            this.confirm(index.id, 0)
                                        }}>移除</Button>
                                <Checkbox style={{marginLeft: 10}}
                                          checked={currentCheckItem.indexOf(text.id.split("_")[0]) !== -1}
                                          onChange={(e) => this.onCheckValue(e, text)}></Checkbox>
                            </div>
                        );

                    }
                }]
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getAddTeams,
    checkSignUp,
    deleteSignUp,
    getJoinTeam,
    addJoinTeam,
    updateTrainingSignUpPosition
});
TrainingJoinManagement.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingJoinManagement)));
