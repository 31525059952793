import React, { Component } from 'react';
import {
    Form, Input, Button, notification,
} from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 17 },
};


class CoinForm extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (values.amount==0){
                notification.error({message:"金额不能输入0"});return;
            }
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values.amount,values.coin,values.packageDetail,values.packageName,this.props.type,values.applePayId);
            }
        });
    }

    render() {
        console.log(this.props.coinInfo)
        // let {
        //     packageName,packageDetail,coin,amount
        // } = this.props;
        let {
            packageName,packageDetail,coin,amount,applePayId
        } = this.props;
        console.log(this.props)
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <FormItem
                    label="套餐名称"
                    {...formItemLayout}
                >
                    {getFieldDecorator('packageName', {
                        rules: [{ required: true, message: '请填写套餐名称', pattern: /^\S+$/ }],
                        initialValue: packageName,
                    })(
                        <Input placeholder="请填写套餐名称" />,
                    )}
                </FormItem>
                <FormItem
                    label="套餐详情"
                    {...formItemLayout}
                >
                    {getFieldDecorator('packageDetail', {
                        rules: [{ required: true, message: '请填写套餐详情', pattern: /^\S+$/ }],
                        initialValue: packageDetail,
                    })(
                        <Input placeholder="请填写套餐详情" />,
                    )}
                </FormItem>
                <FormItem
                    label="获取浪币"
                    {...formItemLayout}
                >
                    {getFieldDecorator('coin', {
                        rules: [{ required: true, message: '请填写获取浪币的数量', pattern: /^\S+$/ }],
                        initialValue: coin,
                    })(
                        <Input placeholder="请填写获取浪币的数量" />,
                    )}
                </FormItem>
                <FormItem
                    label="支付金额"
                    {...formItemLayout}
                >
                    {getFieldDecorator('amount', {
                        rules: [{ required: true, message: '请填写支付金额', pattern: /^\S+$/ }],
                        initialValue: amount,
                    })(
                        <Input placeholder="请填写支付金额" />,
                    )}
                </FormItem>
                {
                    this.props.type === "IOS" ? <FormItem
                        label="苹果支付套餐ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('applePayId', {
                            initialValue: applePayId,
                        })(
                            <Input placeholder="苹果支付套餐ID" />,
                        )}
                    </FormItem> : ""
                }

                <FormItem>
                    <div style={{ textAlign: 'center' }}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(CoinForm);
