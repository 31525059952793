import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {getUser, updateUserCertification,} from "../../../modules/certification/action";
import UpLoad from "../../../components/upload";

const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class UpdateCertificationModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: ""
        };
    }

    componentDidMount() {
        let {value} = this.props;
        console.log("40:", value)
    }

    onSubmitEvent = () => {
        console.log("40")
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values)
                let {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange = (checked) => {
        console.log(`switch to ${checked}`);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {value, visible} = this.props;

        return (
            <Modal
                visible={visible}
                title={value ? "修改实名认证" : "新增实名认证"}
                onCancel={this.props.onClose}
                cancelText={"取消"}
                onOk={this.onSubmitEvent}
                okText={"确认"}>
                <Form>                  
                    <FormItem label="用户展示ID"
                              {...formItemLayout}>
                        {getFieldDecorator('exhibitionId', {
                                initialValue: value && value.exhibitionId ? value.exhibitionId : '',
                                rules: [
                                    {required: true, message: '用户ID'},
                                    {pattern: /^[1-9][0-9]*$/, message: '请输入正确格式!'}
                                ],
                            }
                        )(
                            <Input disabled={!!(value && value.exhibitionId)} style={{width: '150px'}} allowClear/>
                        )}
                    </FormItem>
                    <FormItem
                        label="真实姓名"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('realName', {
                            initialValue: value && value.realName ? value.realName : '',
                            rules: [
                                {required: true, message: '请填写真实姓名'},
                            ],
                        })(
                            <Input placeholder="请填写真实姓名"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="证件类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('certificateType', {
                            initialValue: value && value.certificateType ? value.certificateType : '',
                            rules: [
                                {required: true, message: '请选择证件类型'},
                            ],
                        })(
                            <Select style={{width: '100px'}}>                               
                                <Select.Option value='身份证'>身份证</Select.Option>                               
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem
                        label="证件号码"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('certificateId', {
                            initialValue: value && value.certificateId ? value.certificateId : '',
                            rules: [
                                {required: true, message: '请填写证件号码'},
                                {pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确格式!'}
                            ],
                        })(
                            <Input placeholder="请填写证件号码"/>,
                        )}
                    </FormItem>                   
                    <FormItem
                        label="证件照正面"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('positiveImageUrl', {
                            initialValue: value && value.positiveImageUrl ? value.positiveImageUrl : '',
                            // rules: [{required: true, message: '请上传截图'}],

                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem>
                    <FormItem
                        label="证件照反面"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('reverseImageUrl', {
                            initialValue: value && value.reverseImageUrl ? value.reverseImageUrl : '',
                            // rules: [{required: true, message: '请上传截图'}],

                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem> 
                    <FormItem
                        label="手持证件照"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('handImageUrl', {
                            initialValue: value && value.handImageUrl ? value.handImageUrl : '',
                            // rules: [{required: true, message: '请上传截图'}],

                        })(
                            (
                                <UpLoad
                                    double={false}
                                    //图片格式限制
                                    // width = {230}
                                    // height = {164}
                                    size={251}
                                />
                                // <Button>修改</Button>
                            ),
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification,
    }
};
const mapDispatchToProps = ({
    getUser, updateUserCertification
});
UpdateCertificationModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(UpdateCertificationModel)));
