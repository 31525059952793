import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, Table, Row, Col, message} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getTopics,createTopic,deleteTopic,updateTopic,setTop} from "../../modules/topic/action";
import TopicFilter from '../topic-management/TopicFilter';
import {operationTypeValue} from 'modules/topic/topicEnum';
import AddTopicModel from "./components/AddTopicModel";

const {confirm} = Modal;

class TopicManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            topicName: "",
            loading: false,
            seletionValue: null,
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            isShowAddTopicModel: false,
        };
    }

    componentWillMount(){
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const { getTopics} = props;
        const {pageNo, pageSize} = this.state;
        this.setState({
            loading: true
          })
        await getTopics(
            pageNo, pageSize,
        );
    }
    
    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getTopics} = this.props;
        let { topicName} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getTopics(current, pageSize, topicName);
    }

    //条件查找动态方法
    onFilterCommentEvent = async (topicName) => {
        console.log("topicName",topicName);
        let {getTopics} = this.props;
        const {pageSize,} = this.state;
        this.setState({
            topicName: topicName,
            pageNo: 1
        });
        getTopics(1, pageSize, topicName);
    };

    confirm = (data) => {
        let _this = this;
        confirm({
            content: '确认删除吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.deleteTopicEvent(data.id)
            },
            onCancel() {
            },

        });
    }
    
    onBtnClick = (type, value) => {
        console.log("text",value);
        this.setState({
          operationType: type,
          isShowAddTopicModel: true,
          seletionValue: value,
        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE,null)}>新增</Button>
            </Col>
        </Row>
    )

    onFormSubmit = (values) => {
        const {operationType} = this.state;
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onAddTopicEvent(values);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdateTopicEvent(values);
                break;
            default:
        }
    }

    // 创建
    onAddTopicEvent = async (params) => {
        const {isShowAddTopicModel} = this.state;
        const {createTopic} = this.props;
        console.log("params",params);
        await createTopic(params);
        await this.onInitData();
        message.success('新增成功');
        this.setState({isShowAddTopicModel: !isShowAddTopicModel});
    }

    // 修改
    onUpdateTopicEvent = async (params) => {
        const {isShowAddTopicModel,seletionValue} = this.state;
        const {updateTopic} = this.props;
        await updateTopic(seletionValue.id ,params);
        await this.onInitData();
        message.success('修改成功');
        this.setState({isShowAddTopicModel: !isShowAddTopicModel});
    }   
    
    top = async (id,type) => {
        let {setTop} = this.props;
        await setTop(id,type)
        message.success('配置成功');
        await this.onInitData();
    }

    deleteTopicEvent = async (id) => {
        let {deleteTopic} = this.props;
        this.setState({
            loading: true
        })
        await deleteTopic(id)
        message.success('删除话题成功');
        await this.onInitData();
    }


    render() {
        const {topcis, loading} = this.props;
        const {pageNo, pageSize,isShowAddTopicModel, operationType, seletionValue} = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TopicFilter dataSource={{}} onCommit={this.onFilterCommentEvent}/>
                </Card>

                <Card style={{width: 'auto'}}>
                    <Table

                        columns={
                            [
                                {
                                    title: '话题ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "7%",
                                },
                                {
                                    title: '话题名',
                                    dataIndex: 'topicName',
                                    key: 'topicName',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '话题头像',
                                    dataIndex: 'topicIcon',
                                    key: 'topicIcon',
                                    align: 'center',
                                    width: "10%",
                                    render: (imgUrl, record, index) => {
                                        return <img src={imgUrl ? imgUrl+'?x-oss-process=image/resize,w_200' : ''} alt={""} style={{width: 100, height: 100}}/>;
                                    }
                                },
                                {
                                    title: '话题TOP图',
                                    dataIndex: 'topicTopImage',
                                    key: 'topicTopImage',
                                    align: 'center',
                                    width: "10%",
                                    render: (imgUrl, record, index) => {
                                        return <img src={imgUrl ? imgUrl+'?x-oss-process=image/resize,w_200' : ''} alt={""} style={{width: 100, height: 100}}/>;
                                    }
                                },
                                {
                                    title: '话题简介',
                                    dataIndex: 'introduction',
                                    key: 'introduction',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '主持人展示ID',
                                    dataIndex: 'topicHost',
                                    key: 'topicHost',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '小主持人展示ID',
                                    dataIndex: 'topicLittleHost',
                                    key: 'topicLittleHost',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '话题创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                            this.onBtnClick(operationTypeValue.UPDATE, text)
                                        }}>编辑</Button>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                            this.top(text.id,text.top == "TOP"  ? 'CANCEL_TOP' : 'TOP')
                                        }}>{ text.top == "TOP"  ? '已置顶' : '置顶'}</Button>
                                        <Button style={{marginLeft: 10, marginBottom: 10}} type="danger"  onClick={() => {
                                            this.confirm(text)
                                        }}>删除</Button>
                                        </div>)

                                    },
                                },
                            ]
                        }

                        title={this.renderTableTitle}
                        dataSource={topcis.data ? topcis.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: topcis ? topcis.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                    {isShowAddTopicModel && <AddTopicModel
                        onSuccess={this.onFormSubmit}
                        visible={isShowAddTopicModel}
                        operationType={operationType}
                        data={seletionValue}
                        onClose={() => {
                            this.setState({
                            isShowAddTopicModel: false,
                            });
                        }}
                    />}                    
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        return {
            ...state.Topics
        }
    }
);

const mapDispatchToProps = {
    getTopics,
    deleteTopic,
    updateTopic,
    createTopic,
    setTop
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(TopicManagement)));