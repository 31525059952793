import React, {Component} from 'react';
import {Button, Form, Input, InputNumber, Radio, Switch} from 'antd';
import {operationTypeValue} from "../../modules/config/configEnum";

const FormItem = Form.Item;
const {TextArea} = Input;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class ConfigForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueType: "STRING",
        };
    }


    componentWillMount() {
        const {confKey, confValueType, confDesc, security} = this.props.configInfo;
        if(confValueType){
            this.setState({
                valueType: confValueType,
            })
        }
    }
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                onSubmitEvent && onSubmitEvent(values.confKey, values.confValueType, values.confValue, values.confDesc, values.security);
            }
        });
    }

    onChangeConfValueType = (e) =>{
        console.log(e.target.value)
        this.setState({
            valueType: e.target.value
        })
    }
    validateConfValue = (rule, value, callback) => {
        console.log(this.state.valueType)
        if (this.state.valueType==="JSON") {
            try{
                var obj=JSON.parse(value);
                if(typeof obj == 'object' && obj ){
                    callback()
                }else{
                    callback("JSON格式不正确")
                }
            }catch (e) {
                callback("JSON格式不正确")
            }
        } else {
            callback()
        }
    }

    render() {
        const {confKey, confValueType, confValue, confDesc, security} = this.props.configInfo;
        const {getFieldDecorator} = this.props.form;
        const {operationType} = this.props;
        const {valueType} = this.state;
        return (
            <Form>

                <FormItem label="配置名"{...formItemLayout}>
                    {getFieldDecorator('confKey', {
                        rules: [{required: true, message: '请填写配置名', pattern: /^\S+$/}],
                        initialValue: confKey
                    })(
                        <Input placeholder="请填写配置名"
                               disabled={operationType == operationTypeValue.UPDATE ? true : false}/>,
                    )}
                </FormItem>
                <FormItem label="配置值类型"{...formItemLayout}>
                    {getFieldDecorator('confValueType', {
                        rules: [{required: true, message: '请填写配置值', pattern: /^\S+$/}],
                        initialValue: confValueType ? confValueType : valueType,
                    })(
                        <Radio.Group buttonStyle="solid" onChange={this.onChangeConfValueType}>
                            <Radio.Button value="STRING" disabled={operationType == operationTypeValue.UPDATE && confValueType ? true : false}>STRING</Radio.Button>
                            <Radio.Button value="NUMBER" disabled={operationType == operationTypeValue.UPDATE && confValueType ? true : false}>NUMBER</Radio.Button>
                            <Radio.Button value="JSON" disabled={operationType == operationTypeValue.UPDATE && confValueType ? true : false}>JSON</Radio.Button>
                            <Radio.Button value="BOOLEAN" disabled={operationType == operationTypeValue.UPDATE && confValueType ? true : false}>BOOLEAN</Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem label="配置值"{...formItemLayout}>
                    {getFieldDecorator('confValue', {
                        rules: [{required: true, message: '请填写配置值'},
                            {validator: this.validateConfValue}],
                        initialValue: valueType==="JSON" && confValue ? JSON.stringify(JSON.parse(confValue), null, 4) : confValue,
                    })(
                        valueType==="NUMBER" ? <InputNumber /> : valueType==="BOOLEAN" ? <Radio.Group onChange={this.onChange} value={confValue}>
                            <Radio value={"true"}>true</Radio>
                            <Radio value={"false"}>false</Radio>
                        </Radio.Group> : valueType==="JSON" ?  <TextArea rows={8} style={{width: 580}}/> : <TextArea rows={8} style={{width: 580}}/>
                    )}
                </FormItem>
                <FormItem label="是否可查询"{...formItemLayout}>
                    {getFieldDecorator('security', {
                        rules: [{required: true, message: '请填写配置值', pattern: /^\S+$/}],
                        initialValue: security,
                    })(
                        <Switch checkedChildren="开" unCheckedChildren="关"
                                defaultChecked={security}/>
                    )}
                </FormItem>
                <FormItem label="备注"{...formItemLayout}>
                    {getFieldDecorator('confDesc', {
                        rules: [{required: false, message: '请填写备注', pattern: /^\S+$/}],
                        initialValue: confDesc,
                    })(
                        <Input placeholder="请填写备注"/>,
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(ConfigForm);
