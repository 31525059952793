import React from 'react';
import {Form, Input, Modal, Select} from 'antd';
import {fromJS, is} from 'immutable';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import Cookie from "js-cookie";
import {ACCESS_TOKEN} from "../../../modules/authentication/constants";
import * as axios from "axios";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {span: 9},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingVideoInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            defaultValue: null,
            uploader: null,
            progressPercent: 0,
            uploadStatus: '无任务',
            videoId: null,
            fileName: null
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            // eslint-disable-next-line react/prop-types
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value,trainingVideoInfo} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            defaultValue,
            videoId: trainingVideoInfo.url
        });

        const {getGameList} = props;
        getGameList();
    }

    onSubmit = () => {
        // eslint-disable-next-line react/prop-types
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const {videoId, fileName} = this.state;
                console.log(values)
                // eslint-disable-next-line no-unused-expressions
                let param = {}
                Object.keys(values).map(item => {
                    if (values[item] != undefined) {
                        if (item === "xxx") {
                            return null;
                        }
                        if (item === "url") {
                            console.log(values[item])
                            if(videoId){
                                console.log(videoId)
                                param['url'] = videoId;
                            }
                            if(fileName){
                                param['fileName'] = fileName;
                            }
                            return null
                        }
                        // param[item] = values[item];
                    }
                    return null
                });
                onSuccess && onSuccess(param);
            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    onOk(value) {
        console.log(value)
    }
    videoIdOnChange(value) {
        console.log(value)
        this.setState({
            videoId: value
        })
    }


    render() {
        // eslint-disable-next-line react/prop-types
        let {progressPercent, uploadStatus, videoId} = this.state;
        const {getFieldDecorator} = this.props.form;
        const {visible, trainingVideoInfo, gameMaps} = this.props;
        let uploadValue = [];
        if (trainingVideoInfo) {
            uploadValue.push(trainingVideoInfo.fileName)
            uploadValue.push(trainingVideoInfo.url)
        }
        return (
            <Modal
                width={'30%'}
                visible={visible}
                title={"添加视频配置"}
                onCancel={this.props.onClose}
                cancelText={"取消"}
                onOk={this.onSubmit}
                okText={"修改"}
            >
                <Form>
                    <FormItem
                        label="视频名称"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('videoTheme', {
                            rules: [{required: true, message: '请填写视频名称'}],
                            initialValue: trainingVideoInfo ? trainingVideoInfo.videoTheme : '',
                        })(
                            <Input placeholder="请填写视频名称"/>,
                        )}
                    </FormItem>

                    <FormItem
                        label="地图类型"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('gameMap', {
                            initialValue: trainingVideoInfo ? trainingVideoInfo.gameMap : '',
                            rules: [{required: true, message: '请选择地图类型!'}],
                        })(
                            <Select placeholder="请选择游戏类型" onChange={this.onChange}>
                                {gameMaps && gameMaps.map((item) => <Select.Option value={item.code}
                                                                                   key={item.code}>{item.mapName}</Select.Option>)}
                            </Select>,
                        )}
                    </FormItem>
                    {/*<FormItem label="比赛开始时间"  {...formItemLayout}>*/}
                    {/*{getFieldDecorator('gameStartTime', {*/}
                    {/*initialValue: trainingVideoInfo ? trainingVideoInfo.gameStartTime ? moment(trainingVideoInfo.gameStartTime, dateFormat) : moment().subtract('0', 'day') : '',*/}
                    {/*rules: [{required: true, message: '请选择比赛开始时间!'}],*/}
                    {/*}*/}
                    {/*)(*/}
                    {/*<DatePicker showTime onChange={this.onChange} onOk={this.onOk}/>*/}
                    {/*)}*/}
                    {/*</FormItem>*/}


                    <Form.Item
                        label="视频格式"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('xxx', {})(
                            <p style={{color: 'red'}}>*仅支持mp4,m3u8,flv视频格式</p>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={"BO" + (trainingVideoInfo ? trainingVideoInfo.gameNum : '')}
                        {...formItemLayout}
                    >
                        {getFieldDecorator('url', {
                            initialValue: trainingVideoInfo ? trainingVideoInfo.url : '',
                        })(
                            <div>
                                    <Input placeholder="请配置videoId" onChange={(e)=>this.videoIdOnChange(e.currentTarget.value)} value={videoId}/>
                                    <input type="file" id="fileUpload" onChange={(e)=>this.fileChange(e, trainingVideoInfo ? trainingVideoInfo.gameNum : '')} />
                                    <div><label className="status">上传状态: <span id="status">{uploadStatus}</span></label></div>
                                    <span className="progress">上传进度: <i id="sts-progress">{progressPercent}</i> %</span>
                            </div>
                        )}
                    </Form.Item>
                    {/*<UploadVod gameNum={trainingVideoInfo ? trainingVideoInfo.gameNum : ''} />*/}
                </Form>
            </Modal>

        );
    }

    fileChange = (e, id) => {
        console.log(e, id)
        let {uploader} = this.state;
        console.log(uploader)
        let file = e.target.files[0]
        if (!file) {
            alert("请先选择需要上传的文件!")
            return
        }
        let Title = file.name
        let userData = '{"Vod":{}}'
        if (uploader) {
            uploader.stopUpload()
        }
        uploader = this.createUploader(id)
        // 首先调用 uploader.addFile(event.target.files[i], null, null, null, userData)
        console.log(userData)
        console.log(uploader)
        uploader.addFile(file, null, null, null, userData)

        uploader.startUpload()
        this.setState({
            fileName: Title
        })
        // $('#stsUpload').attr('disabled', false)
        // $('#pauseUpload').attr('disabled', true)
        // $('#resumeUpload').attr('disabled', true)
    }


    getAuthorizationHeader = (headers) => {
        try {
            let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
            const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
            if (headers && accessToken) {
                headers.Authorization = accessToken.token;
            }
            headers.ContentType = 'application/json';
            return headers;
        } catch (error) {
            return '';
        }
    };

    createUploader = (id) => {
        let _this = this;
        console.log(window.AliyunUpload)
        let uploader = new window.AliyunUpload.Vod({
            timeout: 60000,
            //分片大小默认1M，不能小于100K
            partSize: 1048576,
            //并行上传分片个数，默认5
            parallel: 5,
            //网络原因失败时，重新上传次数，默认为3
            retryCount: 3,
            //网络原因失败时，重新上传间隔时间，默认为2秒
            retryDuration: 2,
            //是否上报上传日志到点播，默认为true
            enableUploadProgress: true,
            // region: null,
            userId: '123',

            // 添加文件成功
            addFileSuccess: function (uploadInfo) {
                console.log('addFileSuccess')
                console.log("addFileSuccess: " + uploadInfo.file.name)
            },
            // 开始上传
            onUploadstarted: function (uploadInfo) {
                axios({ // 用axios发送post请求
                    method: 'get',
                    url: '/api/admin/oss/aliyun/sts', // 请求地址
                    data: {}, // 参数
                    responseType: 'json', // 表明返回服务器返回的数据类型
                    headers: _this.getAuthorizationHeader({})
                }).then(info => {
                    console.log("==============", info)
                    var accessKeyId = info.data.accessKeyId
                    var accessKeySecret = info.data.accessKeySecret
                    var secretToken = info.data.securityToken
                    uploader.setSTSToken(uploadInfo, accessKeyId, accessKeySecret, secretToken)
                    console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                    _this.setState({
                        uploadStatus: "视频开始上传...",
                        progressPercent: 0
                    })
                }).catch(e => {
                    console.log("================",e.response)
                })

                // if(!uploadInfo.videoId){
                //     axios({ // 用axios发送post请求
                //         method: 'get',
                //         url: '/api/admin/vod/aliyun/uploadauth-address?fileName=normal video.mp4', // 请求地址
                //         data: {}, // 参数
                //         responseType: 'json', // 表明返回服务器返回的数据类型
                //         headers: _this.getAuthorizationHeader({})
                //     }).then(info => {
                //         console.log("==============", info)
                //         var videoId = info.videoId
                //         var uploadAddress = info.uploadAddress
                //         var uploadAuth = info.uploadAuth
                //         uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
                //         console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                //
                //         _this.setState({
                //             uploadStatus: "视频开始上传...",
                //             progressPercent: 0
                //         })
                //     }).catch(e => {
                //         console.log("================",e.response)
                //     })
                // }else {
                //     axios({ // 用axios发送post请求
                //         method: 'get',
                //         url: '/api/admin/vod/aliyun/refresh/uploadauth-address?videoId='+uploadInfo.videoId, // 请求地址
                //         data: {}, // 参数
                //         responseType: 'json', // 表明返回服务器返回的数据类型
                //         headers: _this.getAuthorizationHeader({})
                //     }).then(info => {
                //         console.log("==============", info)
                //         var videoId = info.videoId
                //         var uploadAddress = info.uploadAddress
                //         var uploadAuth = info.uploadAuth
                //         uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
                //         console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                //
                //         _this.setState({
                //             uploadStatus: "视频开始上传...",
                //             progressPercent: 0
                //         })
                //     }).catch(e => {
                //         console.log("================",e.response)
                //     })
                // }
            },
            // 文件上传成功
            onUploadSucceed: function (uploadInfo) {
                console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                _this.setState({
                    uploadStatus: "文件上传成功!",
                    videoId: uploadInfo.videoId
                })
                console.log("====================",uploadInfo)
            },
            // 文件上传失败
            onUploadFailed: function (uploadInfo, code, message) {
                console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                _this.setState({
                    uploadStatus: "文件上传失败!"
                })
            },
            // 取消文件上传
            onUploadCanceled: function (uploadInfo, code, message) {
                console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
                // $('#status').text('文件上传已暂停!')
            },
            // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
            onUploadProgress: function (uploadInfo, totalSize, progress) {
                console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                var progressPercent = Math.ceil(progress * 100)
                _this.setState({
                    progressPercent: progressPercent,
                    uploadStatus: "文件上传中..."
                })
            },
            // 上传凭证超时
            onUploadTokenExpired: function (uploadInfo) {
                // axios({ // 用axios发送post请求
                //     method: 'get',
                //     url: '/api/admin/oss/aliyun/sts', // 请求地址
                //     data: {}, // 参数
                //     responseType: 'json', // 表明返回服务器返回的数据类型
                //     headers: _this.getAuthorizationHeader({})
                // }).then(info => {
                //     console.log("==============", info)
                //     var accessKeyId = info.data.accessKeyId
                //     var accessKeySecret = info.data.accessKeySecret
                //     var secretToken = info.data.securityToken
                //     var expiration = info.data.Expiration
                //     uploader.resumeUploadWithSTSToken(accessKeyId, accessKeySecret, secretToken, expiration)
                //     console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                // }).catch(e => {
                //     console.log("================",e.response)
                // })

                axios({ // 用axios发送post请求
                    method: 'get',
                    url: '/api/admin/vod/aliyun/refresh/uploadauth-address?videoId='+uploadInfo.videoId, // 请求地址
                    data: {}, // 参数
                    responseType: 'json', // 表明返回服务器返回的数据类型
                    headers: _this.getAuthorizationHeader({})
                }).then(info => {
                    var uploadAuth = info.uploadAuth
                    uploader.resumeUploadWithAuth(uploadAuth)
                    console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                    _this.setState({
                        uploadStatus: "视频开始上传...",
                        progressPercent: 0
                    })
                }).catch(e => {
                    console.log("================",e.response)
                })
            },
            // 全部文件上传结束
            onUploadEnd: function (uploadInfo) {
                _this.setState({
                    uploadStatus: "文件上传完毕!"
                })
            }
        })
        return uploader
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getGameList,
});
TrainingVideoInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingVideoInfoModel)));