import createReducer from 'utils/createReducer';

import {
    GET_TOPIC_REPORTS,GET_COMMENT_REPORTS,GET_USER_REPORTS
} from './actionTypes';

const defaultState = () => ({
    topicReports: {},
    commentReports: {},
    userReports: {},    
});


const ongetTopicReportsRequest = (state,{payload}) => {
    return {
        ...state,
        topicReports:payload

    }

};

const ongetCommentReportsRequest = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        commentReports: payload,
    }

};

const ongetUserReportsRequest = (state, { payload }) => {
    console.log("ongetUserReportsRequest",payload)
    return{
        ...state,
        userReports: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_TOPIC_REPORTS}_SUCCESS`]: ongetTopicReportsRequest,
    [`${GET_COMMENT_REPORTS}_SUCCESS`]: ongetCommentReportsRequest,
    [`${GET_USER_REPORTS}_SUCCESS`]: ongetUserReportsRequest,
});
