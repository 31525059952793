import createAsyncAction from 'utils/createAsyncAction';
import SmallProgramRequest from '../../request/SmallProgramRequest' ;
import {
    GET_MODELS,GET_SINGLE_PLAN,GET_ALL_PLAN,UPDATE_PLAN

} from './actionTypes'

const getModels = (pageNo,pageSize,brand,brandName,model,modelName,planId) => (
    createAsyncAction(GET_MODELS,() => (SmallProgramRequest.getModels(pageNo,pageSize,brand,brandName,model,modelName,planId)))
);
const getExclusivePlan = (planId) => (
    createAsyncAction(GET_SINGLE_PLAN,() => (SmallProgramRequest.getExclusivePlan(planId)))
);
const getAllPlan = () => (
    createAsyncAction(GET_ALL_PLAN,() => (SmallProgramRequest.getAllPlan()))
);
const updatePlan = ({planId,modelId}) => (
    createAsyncAction(UPDATE_PLAN,() => (SmallProgramRequest.updatePlan({planId,modelId})))
);


export {
    getModels,getExclusivePlan,getAllPlan,updatePlan
}
