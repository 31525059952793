import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, DatePicker
} from 'antd';
import moment from "moment";
import AreaSelect from '../../../components/area';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 15},
};
const dateFormat = "YYYY-MM-DD HH:mm:00";


class AddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentWillMount(){
        
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent, type, createTime } = this.props;
                let val = {
                    province: values.area.length > 0 ? values.area[0] : '',
                    city: values.area.length > 0 ? values.area[1] : '',
                    receiver: values.receiver,
                    receiverTel: values.receiverTel,
                    receiverAddress: values.receiverAddress
                };                               
                onSubmitEvent && onSubmitEvent(val);
            }
        });
    };

    renderAddress = () => {
        const {city, province} = this.props;
        let address = [];
        if (city && province) {           
            address.push(province, city)
        }
        console.log(address);
        return address
    }

    render() {
        let {
            awardRecordId, userId, receiver, receiverTel, province, city, county, receiverAddress
        } = this.props;        
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <FormItem
                    label="订单编号"
                    {...formItemLayout}
                >
                    {getFieldDecorator('awardRecordId', {
                        initialValue: awardRecordId,
                    })(
                        <Input disabled={true} />
                    )}
                </FormItem>
                <FormItem
                    label="用户ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('userId', {
                        initialValue: userId,
                    })(
                        <Input disabled={true} />
                    )}
                </FormItem>
                <FormItem
                    label="收货人"
                    {...formItemLayout}
                >
                    {getFieldDecorator('receiver', {
                        rules: [{required: true, message: '请输入收货人名字', pattern: /^\S+$/}],
                        initialValue: receiver,
                    })(
                        <Input placeholder="请输入收货人名字"/>
                    )}
                </FormItem>
                <FormItem
                    label="收货人手机号"
                    {...formItemLayout}
                >
                    {getFieldDecorator('receiverTel', {
                        rules: [{required: true, message: '请输入正确手机号', pattern: /^1[0-9]{10}$/}],
                        initialValue: receiverTel,
                    })(
                        <Input placeholder="请输入收货人手机号"/>
                    )}
                </FormItem>
                <FormItem
                    label="收货人所在地区"
                    {...formItemLayout}
                >
                    {getFieldDecorator('area', {
                        rules: [{required: true, message: '请选择地区', pattern: /^\S+$/}],
                        initialValue: this.renderAddress()
                    })(                        
                        <AreaSelect                                                      
                            placeholder="请选择地区"
                        />
                    )}
                </FormItem>
                <FormItem
                    label="收货人详细地址"
                    {...formItemLayout}
                >
                    {getFieldDecorator('receiverAddress', {
                        rules: [{required: true, message: '请输入收货人详细地址', pattern: /^\S+$/}],
                        initialValue: receiverAddress,
                    })(
                        <Input placeholder="请输入收货人详细地址"/>
                    )}
                </FormItem>                                                             

                {/* <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem> */}
            </Form>
        );
    }
}

export default Form.create()(AddressForm);
