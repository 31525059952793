import React from 'react';
import {Card, Form, Input, Modal, Select, Table,} from 'antd';
import {getTeamCertificationInfo} from "../../../modules/certification/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import defaultImg from "../../../static/images/default.jpg";
import GetVIPModel from "./GetVIPModel";
import Zmage from 'react-zmage'

const FormItem = Form.Item;
const {Option} = Select;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};
const refuseReasons = ["证件照正面图模糊","证件照反面图模糊","手持证件照图模糊","真实姓名与证件照不符","证件号码与证件照不符","涉嫌使用虚假证件照","手持证件照与示例图要求不符"]

class CertificationInfoModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            loading: false,
            checkState: null
        };
    }


    componentWillMount() {
        const {value} = this.props;
        console.log("39",value);
        this.setState({
            loading: true,
            checkState: value && value.state && value.state !== 'REVIEW' ? value.state==='SUCCESS' ? 'SUCCESS' : 'FAIL' : null
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    onSubmit = async () => {
        const {onSuccess} = this.props;
        // eslint-disable-next-line react/prop-types
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // eslint-disable-next-line no-unused-expressions
                onSuccess && onSuccess(values);
            }
        });

    }

    handleCancel = () => this.setState({previewVisible: false});

    onChange = (value) => {
        this.setState({checkState: value})
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, certificationInfo, value} = this.props;
        const {loading, checkState} = this.state;
        return (
            <Modal
                visible={visible}
                title={"用户实名认证审核"}
                onCancel={this.props.onClose}
                cancelText={"取消"}
                onOk={this.onSubmit}
                okText={"确认"}
            >

                    <Form>
                        <FormItem
                            label="审核状态"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout
                            }
                        >
                            {getFieldDecorator('state', {
                                initialValue: checkState,
                                rules: [{required: true, message: '请选择审核状态!'}],
                            })(
                                <Select placeholder="请选择审核状态" onChange={this.onChange}>
                                    <Option value="SUCCESS" >通过</Option>
                                    <Option value="FAIL" >拒绝</Option>
                                </Select>,
                            )}
                        </FormItem>
                        {checkState && checkState === 'FAIL' ? <FormItem
                            label="拒绝理由"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('remark', {
                                rules: [{required: true, message: '请选择拒绝理由!'}],
                                initialValue: value && value.failureNote ? value.failureNote : [],
                            })(
                                <Select mode="multiple">
                                    {refuseReasons.map(item => {
                                        return (
                                            <Select.Option
                                                value={item}>{item}</Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </FormItem> : null}

                    </Form>
            </Modal>

        );
    }

    getTableColumns = () => [{
        title: '玩家展示ID',
        dataIndex: 'gamerShowId',
        align: 'center',
        key: 'gamerShowId',
        render: (row, text) => (
            <span>{text.gamerShowId ? text.gamerShowId : text.gamerId}</span>
        ),
    }, {
        title: '玩家名称',
        dataIndex: 'gamerName',
        align: 'center',
        key: 'gamerName',
    }, {
        title: '是否是YTC',
        dataIndex: 'YTC',
        align: 'center',
        key: 'YTC',
        render: (row, text) => (
            <GetVIPModel userId={text.gamerId}/>
        ),
    }, {
        title: '参赛昵称',
        dataIndex: 'inGameName',
        align: 'center',
        key: 'inGameName',
    }, {
        title: '成员类型',
        dataIndex: 'memberType',
        align: 'center',
        key: 'memberType',
        render: (row, text) => (
            <span>{text.memberType == "LEADER" ? "队长" : text.memberType == "FORMAL" ? "正式成员" : text.memberType == "ALTERNATE" ? "替补成员" : ""}</span>
        ),
    }, {
        title: '第三方游戏id',
        dataIndex: 'thirdGameId',
        align: 'center',
        key: 'thirdGameId',
    }, {
        title: '参赛QQ号',
        dataIndex: 'qqNum',
        align: 'center',
        key: 'qqNum',
    }, {
        title: '游戏截图证明',
        dataIndex: 'certificationImg',
        align: 'center',
        key: 'certificationImg',
        render: (row, text) => (
            <div>
                <Zmage id="myImage" src={text.certificationImg ? text.certificationImg : defaultImg} height="100"/>
            </div>
        ),
    }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.Certification
    }
};
const mapDispatchToProps = ({
    getTeamCertificationInfo,

});
CertificationInfoModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(CertificationInfoModel)));
