import React, { Component } from 'react';
import {
    Button, Table, notification, message, Popconfirm,
} from 'antd';
import {getEvent, addEvent, deleteEvent, updateEvent} from "../../../modules/player/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import AddEventModal from "./AddEventModal";



class EventRecordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            showAdd:false,
            selectValue:null,
            addFlag:false,
        };
    }
    componentDidMount() {
        const {pageNo, pageSize, selectValue} = this.state;
        let {getEvent,userId} = this.props;
        getEvent(userId,pageNo, pageSize);

    }

    // componentWillReceiveProps(nextProps,nextState) {
    //     const {pageNo, pageSize} = this.state;
    //     let {getComment,clubId} = this.props;
    //     console.log(clubId)
    //     if(nextProps.dynamicId!==this.props.dynamicId){
    //         getComment(pageNo, pageSize,nextProps.dynamicId);
    //     }
    // }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getEvent,userId} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getEvent(userId,current, pageSize);
    }

    onShowBind =()=>{
        this.setState({
            showAdd:true,
            addFlag:true,
            selectValue:null
        })
    }

    onUpdate =(value)=>{
        this.setState({
            showAdd:true,
            selectValue:value,
            addFlag:false
        })

    }

    renderTitle=()=>{
        return(
            <Button style={{marginBottom:16}} onClick={this.onShowBind}>添加转会</Button>
        )
    }
    handleCancel = e => {
        this.setState({
            showAdd: false,


        });
    };

    // 增加赛事信息
    onAddSaiEvent = async (changeDate,changeFee,resourcesClubId,targetClubId) => {
        const {addEvent,userId,getEvent, updateEvent} = this.props;
        const {showAdd, selectValue} = this.state;
        console.log('selectValue',selectValue);
        if(selectValue==null){
            await addEvent(userId,changeDate,changeFee,resourcesClubId,targetClubId);
            message.success('添加转会纪录成功');
        }else{
            await updateEvent(selectValue.id,changeDate,changeFee,resourcesClubId,targetClubId);
            message.success('修改转会纪录成功');
        }
        await getEvent(userId,1,10);
        // message.success('新增赛事信息成功');
        this.setState({showAdd: !showAdd,pageNo:1, selectValue: null});
    }

    //删除赛事信息的方法
    onDeleteEvent = async (id) => {
        const { deleteEvent, getEvent,userId } = this.props;
        const { page, size } = this.state;
        try {
            await deleteEvent(id);
            notification.success({ message: '删除成功' });
            getEvent(userId,page, size);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const {
            transferList, loading
        } = this.props;
        const {pageSize,pageNo,showAdd, selectValue,addFlag} = this.state;

        return (
            <div>
                <Table
                    columns={
                        [
                            {
                                title: '转会时间',
                                dataIndex: 'changeDate',
                                key: 'changeDate',
                                align: 'center',
                            },
                            {
                                title: '转会费',
                                dataIndex: 'changeFee',
                                key: 'changeFee',
                                align: 'center',
                            },
                            {
                                title: '原俱乐部ID',
                                dataIndex: 'resourcesClubId',
                                key: 'resourcesClubId',
                                align: 'center',
                            },
                            {
                                title: '新俱乐部ID',
                                dataIndex: 'targetClubId',
                                key: 'targetClubId',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                render: (row, text) => (
                                    <div>
                                        <Button type="primary" style={{
                                            marginRight:10,
                                            marginBottom:10
                                        }}  onClick={()=>this.onUpdate(text)} >修改</Button>
                                        <Popconfirm title={`确定要删除这条转会纪录吗?`}
                                                    onConfirm={() => this.onDeleteEvent(text.id)}
                                                    okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                            <Button type="danger" >删除</Button>
                                        </Popconfirm>
                                    </div>
                                ),
                            },
                        ]
                    }
                    title={
                        this.renderTitle
                    }
                    dataSource={transferList.data ? transferList.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: transferList ? transferList.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />
                <AddEventModal 
                    addFlag={addFlag}
                    values={selectValue} 
                    visible={showAdd}
                    onSubmitEvent={this.onAddSaiEvent}
                    onCancel={this.handleCancel}
                />
            </div>

        );
    }
}

const mapStateToProps = (state => {
        console.log('mapStateToPropsstate',state);
        return {
            ...state.ProfessionalPlayer
        }
    }

);

const mapDispatchToProps = {
    getEvent,addEvent,deleteEvent, updateEvent
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(EventRecordModal)));
