import React from "react";
import {Button, Card, Col, InputNumber, message, Modal, Popconfirm, Row, Table, Tag, Tooltip} from "antd";
import {connect} from "react-redux";
import {injectIntl} from "react-intl-context";
import {
    createPersonTraining, deletePersonTraining, getPersonTraining, updatePersonTraining,
    getGameMaps, addPersonVideoConfig, getVideoConfig, onClearTrainingTemplate, clearTrainingTemplate, updatePersonTrainingTop,
    getPersonBrand
} from "../../modules/training/action";
import TrainingFilter from "./components/TrainingFilter";
import {map} from 'lodash/fp';
import TrainingInfoModal from "./components/TrainingInfoModal";
import {Link} from "react-router-dom";
import TeamInfoModal from "./components/TeamInfoModal";
import FirstRecommendModal from "../training-management/components/FirstRecommendModal"
import VideoConfigModal from "./components/VideoConfigModal";


const {confirm} = Modal;

class TrainingManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 15,
            param: {},
            modalVisible: false,
            data: {},
            randIndex: 0,
            indexVisible: false,
            loading: false,
            overText: "",
            teamVisible: false,
            trainingId: 0,
            isShowFirstModal : false,
            isShowVideoConfigModal : false,
            isFirstPage : 0,
            seletionValue: null,
            refreshConfig:false
        }
    }


    componentWillMount() {
        let {page, size} = this.state;
        this.setState({
            loading: true
        })
        let {getGameMaps, gameMaps} =  this.props
        console.log(gameMaps)
        console.log(!gameMaps)
        if(!gameMaps){
            getGameMaps();
        }
        this.requestTraiing({page, size});
    }

    confirm = (data) => {
        let _this = this;
        confirm({
            content: '确认删除吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.deletePersonTraining(data.id)
            },
            onCancel() {
            },

        });
    }

    isShowModal = (id,isFirstPage) =>{
        console.log(isFirstPage)
        this.setState({
            isShowFirstModal : true,
            trainingId : id,
            isFirstPage:isFirstPage
        })
    }
    showVideoConfigModal = async (index) =>{
        const {getVideoConfig} = this.props
        await getVideoConfig(index.id)
        this.setState({isShowVideoConfigModal: true,
            seletionValue : index,})
    }

    requestTraiing = (params) => {
        let {getPersonTraining} = this.props;
        params["isManager"] = true;
        getPersonTraining(params)
            .then(res => {
                this.setState({
                    loading: false
                })
            })
            .catch(e => {
                this.setState({
                    loading: false
                })
            });
    }

    onPageChangeEvent = (page) => {
        let {size,param} = this.state;
        this.requestTraiing(Object.assign({page, size},param));
        this.setState({page})
    }

    onSubmit = (values) => {
        let param = {}
        let {size} = this.state;
        let {getPersonTraining} = this.props;
        Object.keys(values).map(item => {
            if (values[item] != undefined) {
                if (item === "dateRange" && values[item].length > 0) {
                    let dateRange = map(date => date.format('YYYY-MM-DD'))(values.dateRange);
                    let startTime = dateRange[0] ? dateRange[0]+" 00:00:00" : '';
                    let endTime = dateRange[1] ? dateRange[1]+" 23:59:59" : '';
                    param["startTime"] = startTime;
                    param["endTime"] = endTime;
                    return null;
                }
                param[item] = values[item];
            }
            return null;
        });
        this.setState({param, loading: true, page: 1})
        param["size"] = size;
        param["isManager"] = true;
        getPersonTraining(param)
            .then(r => {
                this.setState({
                    loading: false
                })
            })
            .catch(e => {
                this.setState({
                    loading: false
                })
            });
    }

    onSuccess = async (values) => {
        let param = {}
        let {createPersonTraining, updatePersonTraining, personBrandData,clearTrainingTemplate} = this.props;
        Object.keys(values).map(item => {
            if (values[item] != undefined) {
                if (item === "signUpStartTime" || item === "signUpEndTime" || item === "gameStartTime") {
                    param[item] = values[item].format('YYYY-MM-DDTHH:mm:00');
                    return null;
                }
                param[item] = values[item];
            }
            return null;
        });
        if (param.trainingId) {
            await updatePersonTraining(param);
            message.success('修改成功');
            clearTrainingTemplate && clearTrainingTemplate()
            this.setState({modalVisible: false});
        } else {
            let brand = personBrandData.data && personBrandData.data.filter(v => v.id == values.brandId);
            param["game"] = brand && brand.length > 0 ? brand[0].game : ""
            await createPersonTraining(param)
            message.success('添加训练赛成功');
            clearTrainingTemplate && clearTrainingTemplate()
            this.setState({modalVisible: false});

        }
        let {size} = this.state;
        this.setState({
            page: 1
        })
        this.setState({
            loading: true
        })
        this.requestTraiing({page: 1, size});
    }

    sign(trainingId, type) {
        let {updatePersonTraining} = this.props;
        updatePersonTraining({trainingId, singUpQualifications: type});
    }

    showTraining(trainingId, type) {
        let {updatePersonTraining} = this.props;
        updatePersonTraining({trainingId, isShow: type});
    }



    endTrainingConfirm = (trainingId, type) => {
        let _this = this;
        confirm({
            content: '确定要'+(type ? '完成' : '重启')+'该场比赛吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.endTraining(trainingId, type)
            },
            onCancel() {
            },

        });
    }
    endTraining = async (trainingId, type) => {
        console.log(type)
        let {updatePersonTraining} = this.props;
        let {page, size} = this.state;
        await updatePersonTraining({trainingId, isEnd: type});
        await this.requestTraiing({page, size});
    }

    clickRand = async (trainingId) => {
        let {updatePersonTraining} = this.props;
        let {randIndex, page, size} = this.state;
        this.setState({
            loading: true
        })
        await updatePersonTraining({trainingId, randIndex});
        message.success('修改成功');
        await this.requestTraiing({page, size});
    }

    top = async (trainingId) => {
        let {updatePersonTrainingTop} = this.props;
        let {page, size} = this.state;
        await updatePersonTrainingTop(trainingId)
        message.success('配置成功');
        await this.requestTraiing({page, size});
    }

    deletePersonTraining = async (id) => {
        let {deletePersonTraining} = this.props;
        this.setState({
            loading: true
        })
        await deletePersonTraining({trainingId: id})
        message.success('删除训练赛成功');
        let {page, size} = this.state;
        await this.requestTraiing({page, size});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.personEditStatus !== this.props.personEditStatus) {
            let {page, size, param} = this.state;
            this.requestTraiing({page, size, ...param})
            this.showModal(false)
        }
        if (nextProps.personTrainingData !== this.props.personTrainingData) {
            this.setState({
                loading: false
            })
        }
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => {
                    this.showModal(true, {})
                }}>新增赛事</Button>
            </Col>
        </Row>
    )

    render() {
        let {personTrainingData, gameMaps,} = this.props;
        let {page, size, modalVisible, data, loading, teamVisible, trainingId,isShowFirstModal, isShowVideoConfigModal} = this.state;
        console.log("index personTrainingData: ");
        console.log(personTrainingData);
        return (
            <div>
                <Card style={{marginBottom: '30px'}}>
                    <TrainingFilter onCommit={this.onSubmit} showModal={this.showModal}/>
                </Card>
                <Card>
                    <Table
                        title={this.renderTableTitle}
                        columns={this.getColumns()}
                        dataSource={personTrainingData.data ? personTrainingData.data : []}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: personTrainingData.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                        scroll={{x: 2756}}
                    />
                </Card>
                {modalVisible ?
                    <TrainingInfoModal onSuccess={this.onSuccess} data={data} visible={modalVisible} onClose={() => {
                        const {clearTrainingTemplate} = this.props;
                        clearTrainingTemplate && clearTrainingTemplate()
                        this.showModal(false)

                    }}/> : null}
                {teamVisible ? <TeamInfoModal visible={teamVisible} onClose={() => {
                    this.setState({
                        teamVisible: false
                    });
                }} trainingId={trainingId}/> : null}

                <Modal title="操作训练赛首页推荐" visible={isShowFirstModal} footer={null} destroyOnClose
                       onCancel={this.onCloseStatusModal}>
                    <FirstRecommendModal {...this.state} onSubmit={this.onChangeFirst} />
                </Modal>

                {isShowVideoConfigModal ?<Modal title="配置回放视频" visible={isShowVideoConfigModal} footer={null} destroyOnClose
                       onCancel={this.onCloseVideoConfigModal}>
                    <VideoConfigModal value={this.state.seletionValue} maps={gameMaps} onSubmit={this.onAddVideoConfig}/>
                </Modal> : null}
            </div>
        )
    }

    onAddVideoConfig = async (trainingId,values) => {
        let {addPersonVideoConfig} = this.props;
        console.log(values)
        let param = {}
        Object.keys(values).map(item => {
            if (values[item] != undefined) {
                if (item === "xxx") {
                    return null;
                }
                param[item] = values[item];
            }
            return null;
        });
        await addPersonVideoConfig(trainingId, param);
        message.success('操作成功');
        this.setState({isShowVideoConfigModal: false,refreshConfig:!this.state.refreshConfig})
        let {page, size} = this.state;
        await this.requestTraiing({page, size});
    }

    onCloseStatusModal = () => {
        this.setState({isShowFirstModal: false})
    }

    onCloseVideoConfigModal = () => {
        this.setState({isShowVideoConfigModal: false})
    }

    showModal = (type, data) => {
        this.setState({modalVisible: type});
        if (type) {
            this.setState({
                data
            })
        }
    }

    getColumns = () => {
        let {personBrandData} = this.props;
        let {page} = this.state;
        return ([{
            title: 'ID',
            dataIndex: "id",
            key: 'id',
            align: 'center',
            width: 70
        },
        //     {
        //     title: '排序',
        //     dataIndex: 'randIndex',
        //     key: 'randIndex',
        //     align: 'center',
        //     width: 100
        // },
        //     {
        //     title: '是否推荐首页',
        //     dataIndex: 'isFirstPage',
        //     key: 'isFirstPage',
        //     align: 'center',
        //     width: 100,
        //     render: (text, index) => {
        //         return text?text:"否"
        //     }
        // },
            {
                title: '赛事名称',
                dataIndex: "gameTheme",
                key: 'gameTheme',
                align: 'center',
                width: 200
            },
            {
                title: '品牌训练赛名',
                dataIndex: 'brandId',
                key: 'brandId',
                align: 'center',
                width: 150,
                render: (text, index) => {
                    let data = personBrandData.data && personBrandData.data.filter(item => item.id === text);
                    return data && data.length > 0 ? data[0].name : ""
                }
            },
            {
                title: '局数',
                key: 'gameNum',
                dataIndex: 'gameNum',
                align: 'center',
                width: 50,
                render: (text, index) => {
                    return "BO" + text;
                }
            },
            {
                title: '报名开始',
                key: 'signUpStartTime',
                dataIndex: "signUpStartTime",
                align: 'center',
                width: 150,
            },
            {
                title: '报名结束',
                key: 'signUpEndTime',
                dataIndex: "signUpEndTime",
                align: 'center',
                width: 150,
            },
            {
                title: '比赛时间',
                key: 'gameStartTime',
                dataIndex: "gameStartTime",
                align: 'center',
                width: 150,
            },
            {
                title: '报名人数',
                key: 'personalTotal',
                dataIndex: "personalTotal",
                align: 'center',
                width: 100,
            },
            {
                title: '直播平台',
                key: 'livePlatform',
                dataIndex: "livePlatform",
                align: 'center',
                width: 200,
            },
            {
                title: '直播路径',
                key: 'livePathUrl',
                dataIndex: "livePathUrl",
                align: 'center',
                width: 230,
            },
            {
                title: '房间密码',
                key: 'gamePwd',
                dataIndex: "gamePwd",
                align: 'center',
                width: 100,
            },
            {
                title: '视频回放地址',
                key: 'replayUrl',
                dataIndex: "replayUrl",
                align: 'center',
                width: 230,
            },
            {
                title: '创建时间',
                key: 'createTime',
                dataIndex: "createTime",
                align: 'center',
                width: 150,
            },
            {
                title: '是否完成',
                key: 'trainningStatus',
                dataIndex: "trainningStatus",
                align: 'center',
                width: 100,
                render: (text, row) => {
                    if (text === "END") {
                        return (
                            <Tooltip title="点击重启">
                                <Button type="danger" color="red" onClick={() => {
                                    this.endTrainingConfirm(row.id, false)
                                }}>重启</Button>
                            </Tooltip>
                        );
                    }
                    return (<Tooltip title="点击完成">
                        <Button type="primary" onClick={() => {
                            this.endTrainingConfirm(row.id, true)
                        }}>完成</Button>
                    </Tooltip>)
                        ;
                    // return <Tag color={text==="END" ? "red" : "blue"}>{text==="END" ? "是" : "否"}</Tag>
                }
            },
            {
                title: '用户是否可报名',
                key: 'singUpQualifications',
                dataIndex: "singUpQualifications",
                align: 'center',
                width: 100,
                render: (text, index) => {
                    return <Tag
                        color={text === "InTime_SIGNUP" ? "red" : "blue"}>{text === "InTime_SIGNUP" ? "是" : "否"}</Tag>
                }
            },
            {
                title: '是否展示',
                key: 'isShow',
                dataIndex: "isShow",
                align: 'center',
                width: 100,
                render: (text, index) => {
                    return <Tag color={text ? "red" : "blue"}>{text ? "是" : "否"}</Tag>
                }
            },
            {
                title: '联系方式',
                key: 'communication',
                dataIndex: "communication",
                align: 'center',
                width: 150,
            },
            {
                title: '操作',
                key: '',
                dataIndex: "",
                fixed: 'right',
                align: 'center',
                width: 'auto',
                render: (text, index) => {
                    let {indexVisible} = this.state;
                    let startDate = new Date(index.gameStartTime).getFullYear() + "-" + ((new Date(index.gameStartTime).getMonth() + 1 < 10 ? "0" + (new Date(index.gameStartTime).getMonth() + 1) : new Date(index.gameStartTime).getMonth() + 1)) + "-" + (new Date(index.gameStartTime).getDate() < 10 ? "0" + new Date(index.gameStartTime).getDate() : new Date(index.gameStartTime).getDate());
                    return (
                        <div style={{
                            width: 300,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Link
                                to={{pathname: 'training_join_manage/' + window.btoa(index.id) + "/" + index.gameTheme + "/" + window.btoa(startDate) + "/" + window.btoa(index.brandId) + "/" + index.personalTotal}}>
                                <Button style={{marginLeft: 10, marginBottom: 10}} type="primary">参与详情</Button>
                            </Link>                          
                            <Link
                                to={{pathname: 'personal_grade_manage/' + window.btoa(index.id) + "/" + window.btoa(index.gameNum) + "/" + index.gameTheme}}>
                                <Button style={{marginLeft: 10, marginBottom: 10}} type="primary">个人战绩</Button>
                            </Link>
                            {/*<Popconfirm*/}
                                {/*title={*/}
                                    {/*indexVisible ? <div><span style={{color:"red"}}>请输入0-100（0为不设置优先级,越大优先级越高）</span><br/><InputNumber style={{width: 260}} placeholder={personBrandData.data.randIndex}*/}
                                                                                                                              {/*defaultValue={index.randIndex}*/}
                                                                                                                              {/*onChange={(e) => {*/}
                                                                                                                                  {/*this.setState({randIndex: e})*/}
                                                                                                                              {/*}}/></div> : null}*/}
                                {/*okType="primary"*/}
                                {/*icon={null}*/}
                                {/*onConfirm={() => {*/}
                                    {/*this.clickRand(index.id)*/}
                                    {/*this.setState({indexVisible: false})*/}
                                {/*}}*/}
                                {/*onCancel={() => {*/}
                                    {/*this.setState({indexVisible: false})*/}
                                {/*}}*/}
                                {/*okText="提交"*/}
                                {/*cancelText="取消"*/}
                            {/*>*/}
                                {/*<Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {*/}
                                    {/*this.setState({indexVisible: true})*/}
                                {/*}}>排序</Button>*/}
                            {/*</Popconfirm>*/}

                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={() => {
                                this.top(index.id)
                            }}>{index.top ? '已置顶' : '置顶'}</Button>
                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary"
                                    onClick={() => this.showModal(true, index)}>编辑</Button>
                            <Button style={{marginLeft: 10, marginBottom: 10}} type="danger" onClick={() => {
                                this.confirm(index)
                            }}>删除</Button>
                            {/*<Button style={{marginLeft: 10, marginBottom: 10}} type={index.isFirstPage===0?"primary":"dashed"} onClick={() => {*/}
                                {/*this.isShowModal(index.id,index.isFirstPage)*/}
                            {/*}}>{index.isFirstPage===0?"是否推荐首页":"已推荐"}</Button>*/}

                            <Button style={{marginLeft: 10, marginBottom: 10}} type="primary" onClick={()=>this.showVideoConfigModal(index)}>配置回放视频</Button>
                        </div>
                    );

                }
            }
        ])
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};

const mapDispatchToProps = ({
    getPersonTraining,
    updatePersonTraining,
    createPersonTraining,
    deletePersonTraining,
    getGameMaps,
    addPersonVideoConfig,
    getVideoConfig,
    clearTrainingTemplate,
    updatePersonTrainingTop,
    getPersonBrand
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(TrainingManagement));
