import React, {Component} from "react";
import {DatePicker, Form, Select} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    getPelLeagueDictionaries,
    getPelTeamRankIntegral,
    getRankDataConfigBrands
} from "../../../modules/training/action";
import moment, {now} from "moment";

const FormItem = Form.Item;

const {WeekPicker} = DatePicker;
const Option = Select.Option;

class PelDataIntegralFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataType: 'TOTAL',
            yqlBrandType: null,
            cycle: null,
        }
    }

    componentWillMount() {
        const {getRankDataConfigBrands} = this.props
        getRankDataConfigBrands().then((data) => {
            const startDate = moment(now()).day(1).format('YYYYMMDD'); // 周一日期
            const endDate = moment(now()).day(7).format('YYYYMMDD'); // 周日日期
            this.setState({
                cycle: `${startDate}-${endDate}`,
                yqlBrandType: data[0].id
            })
        }).catch(error => {
        });
    }

    onChangeWeek = (weekData, dateString) => {
        // console.log(weekData, dateString);
        // const startDate = moment(weekData).day(1).format('YYYY/MM/DD (dddd)'); // 周一日期
        // const endDate = moment(weekData).day(7).format('YYYY/MM/DD (dddd)'); // 周日日期
        const startDate = moment(weekData).day(1).format('YYYYMMDD'); // 周一日期
        const endDate = moment(weekData).day(7).format('YYYYMMDD'); // 周日日期
        let cycle = `${startDate}-${endDate}`
        this.setState({
            cycle: cycle
        })
        let {onCommit} = this.props;
        onCommit && onCommit(cycle, this.state.yqlBrandType, this.state.dataType);
    }

    onChangeBrandType = e => {
        console.log(e)
        this.setState({
            yqlBrandType: e
        })
        let {onCommit} = this.props;
        onCommit && onCommit(this.state.cycle, e, this.state.dataType);
    }

    changeDataType = e => {
        console.log(e)
        this.setState({
            dataType: e
        })
        let {onCommit} = this.props;
        onCommit && onCommit(this.state.cycle, this.state.yqlBrandType, e);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {cycle} = this.state;
        const {rankDataConfigBrands} = this.props;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">
                <FormItem label="赛事阶段">
                    {getFieldDecorator('year', {
                            initialValue: moment(),
                        }
                    )(
                        <div>
                            <WeekPicker defaultValue={moment()} onChange={this.onChangeWeek}/>
                            <span>{cycle}</span>
                        </div>
                    )}
                </FormItem>
                <FormItem label="训练赛类型">
                    {getFieldDecorator('yqlBrandType', {initialValue: rankDataConfigBrands && rankDataConfigBrands.length>0 ? rankDataConfigBrands[0]['id'] : ''}
                    )(
                        <Select style={{width: '100px'}} onChange={this.onChangeBrandType}>
                            {rankDataConfigBrands && rankDataConfigBrands.map(item => {
                                return (
                                    <Option
                                        value={item.id}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="数据类型">
                    {getFieldDecorator('dataType', {initialValue: 'TOTAL'}
                    )(
                        <Select style={{width: '100px'}} onChange={this.changeDataType}>
                            <Select.Option value="TOTAL">总数数据</Select.Option>
                            <Select.Option value="AVG">平均数据</Select.Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    {/*<Button type="primary" htmlType="submit" style={{width: '100%'}}>*/}
                    {/*    查询*/}
                    {/*</Button>*/}
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getPelLeagueDictionaries,
    getPelTeamRankIntegral,
    getRankDataConfigBrands
});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PelDataIntegralFilter)));