import React, {Component} from "react";
import {Form, Input, Button, Select} from "antd";
import {getGame} from '../../../../src/modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";

const FormItem = Form.Item;
const { Option } = Select;

class ClubFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        let {getGame} = this.props
        getGame()
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {name,state,game} = values;

                onCommit && onCommit(name,state,game);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {gameList,} = this.props;
        const gameSelect = gameList ? gameList :[]
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="俱乐部名称">
                    {getFieldDecorator('name', {
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="俱乐部状态">
                    {getFieldDecorator('state', {
                        initialValue:""

                        }
                    )(
                        <Select style={{width: '150px'}}>
                            <Option value="">全部</Option>
                            <Option value="SUBMIT">未审核</Option>
                            <Option value="APPROVED">通过</Option>
                            <Option value="REJECT">已驳回</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="游戏类型">
                    {getFieldDecorator('game', {
                        initialValue: ""
                        }
                    )(
                        <Select style={{width: '150px'}} placeholder="请选择游戏类型" >
                            <Option value="">
                                全部
                            </Option>
                            {gameSelect.map((item) =>
                                <Option value={item.code} key={item.code}>
                                {item.gameName}
                            </Option>)}
                        </Select>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (Form.create()(withRouter(injectIntl(ClubFilter))));

