import Http from 'request/ResumeRequest';
import createAsyncAction from 'utils/createAsyncAction';
import {
    CHANGE_ADDITIONAL_S_S,
    GET_USER_RESUMES
} from './actionTypes';

const getUserResumes = (userId) => (
  createAsyncAction(GET_USER_RESUMES, () => (Http.getUserResumes(userId)))
);

const changeAdditionSs = (userId,game,additionalSocialStatus)=>(
    createAsyncAction(CHANGE_ADDITIONAL_S_S,()=>(Http.changeAdditionSs(userId,game,additionalSocialStatus)))
);


export {
    getUserResumes,changeAdditionSs
};
