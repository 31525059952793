import React from 'react';
import {Form, Input, Modal,Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class MemberIntroductionModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            console.log("values",values);
            if (!err) {
                const {onSuccess} = this.props;
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(value, dateString) {

    }


    onOk(value) {

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible,value} = this.props;
        return (
            <Modal
                visible={visible}
                title="设置成员简介"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText={"确定"}
            >
                <Form layout="inline">
                    <FormItem label="成员简介">
                        {getFieldDecorator('introduction', {
                          initialValue: value.memberIntroduction ? value.memberIntroduction : "",
                          rules: [{max: 200, message: '成员简介不得超过200个字符!'},{required: true, message: '请输入成员简介'}],
                        })(
                          <TextArea placeholder="请填写成员简介"/>,
                        )}                    
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.Lineup,
    }
};
const mapDispatchToProps = ({});
MemberIntroductionModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(MemberIntroductionModel)));
