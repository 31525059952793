
export default class listener {
  /**
     * 连接远程消息
     * @param duration
     * @param receiveCallBack
     * @param failCallBack
     */
  static startListenRemoteNoticeCenter(Function, type = 'message', duration = 600000) {
    // eslint-disable-next-line no-new-func
    Function();
    this[type] = setInterval(Function, duration);
  }

  /**
     * 注销远程消息
     */
  static stopListenRemoteNoticeCenter(type = 'message') {
    let timer = this[type];
    if (timer) {
      clearInterval(timer);
      this[type] = null;
    }
  }
}
