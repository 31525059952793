import React from 'react';
import './BasicLayout.scss';
import isEmpty from 'lodash/isEmpty';
import {
  Row, Col, Icon, Breadcrumb,
} from 'antd';

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

    renderPageHeader = () => {
      const {
        prefixCls, route: { pageTitle }, onSiderCollapseEvent,
      } = this.props;
      if (isEmpty(pageTitle)) {
        return null;
      }
      return (
        <div className={`${prefixCls}-pageHeader`}>
          <Row type="flex" gutter={8}>
            <Col>
              <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} onClick={onSiderCollapseEvent} />
            </Col>
            <Col>
              {this.renderBreadcrumb()}
            </Col>
          </Row>
        </div>
      );
    }

    renderBreadcrumb = () => {
      const {
        route: { path }, intl, prefixCls, menuMap,
      } = this.props;
      let routes = path.substring(1).split('/');

      return (
        <Breadcrumb className={`${prefixCls}-breadcrumb`}>
          {
              routes.filter((route) => menuMap.has(route)).map((route) => {
                let { name } = menuMap.get(route);
                return (
                  <Breadcrumb.Item key={name}>{intl.formatMessage({ id: name })}</Breadcrumb.Item>
                );
              })
            }
        </Breadcrumb>
      );
    }

    render() {
      return (
        <div>
          {this.renderPageHeader()}
        </div>
      );
    }
}

export default MenuBar;
