import createReducer from 'utils/createReducer';

import {
    GET_CIRCLES,GET_CIRCLE
} from './actionTypes';

const defaultState = () => ({
    circles: [],
    circlesTotal: 0,
    circlesLoading: false,
    circleInfo: {},
    adminRoles: [], // 用户所拥有的角色
});

const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
            ...state,
        circles:payload

        }

};


const ongetCirclesuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        circleInfo: payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_CIRCLES}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_CIRCLE}_SUCCESS`]: ongetCirclesuccess,
});
